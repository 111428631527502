import { ReactElement, RefObject, useEffect } from "react";
import styled from "styled-components";
import CommonInputText, {
    InputTextDataType,
} from "../../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import {
    AdRegisterAdjustDaySaleInfoKey,
    AdRegisterAdjustDaySaleInfoValueContainer,
    AdRegisterAdjustDaySaleInfoValueStartTimeInputContainer,
} from "../AdRegisterAdjustDaySaleSwitch";
import { removeComma } from "../../../../../../utils/Utils";

export const AdRegisterAdjustDaySalePriceStartTimeRowContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media ${(props) => props.theme.mobileL} {
        flex-direction: column;
        gap: 10px;
    }
`;

type Props = {
    adDaySaleStartTime: InputTextDataType;
    setAdDaySaleStartTime: (value: InputTextDataType) => void;
    adDaySaleStartTimeContainerRef: RefObject<HTMLDivElement>;
};

const AdRegisterAdjustDaySalePriceStartTimeRow = (
    props: Props,
): ReactElement | null => {
    const min = 1;
    const max = 24;
    const inputTime = removeComma(props.adDaySaleStartTime.value) || 0;

    useEffect(() => {
        if (inputTime < min || inputTime > max) {
            props.setAdDaySaleStartTime({
                ...props.adDaySaleStartTime,
                isWarning: true,
                warningMsg: "1~24 의 숫자를 입력해주세요.",
            });
        }
    }, [props.adDaySaleStartTime.value]);

    return (
        <AdRegisterAdjustDaySalePriceStartTimeRowContainer
            ref={props.adDaySaleStartTimeContainerRef}
        >
            <AdRegisterAdjustDaySaleInfoKey>
                가격 할인 시작 시점
            </AdRegisterAdjustDaySaleInfoKey>
            <AdRegisterAdjustDaySaleInfoValueContainer>
                광고게재 종료시간이
                <AdRegisterAdjustDaySaleInfoValueStartTimeInputContainer>
                    <CommonInputText
                        inputText={props.adDaySaleStartTime}
                        setInputText={props.setAdDaySaleStartTime}
                        minLength={-1}
                        maxLength={-1}
                        isOnlyNumber={true}
                        isRightWrite={true}
                        isDescRight={true}
                    />
                </AdRegisterAdjustDaySaleInfoValueStartTimeInputContainer>
                시간 남은 시점부터
            </AdRegisterAdjustDaySaleInfoValueContainer>
        </AdRegisterAdjustDaySalePriceStartTimeRowContainer>
    );
};

export default AdRegisterAdjustDaySalePriceStartTimeRow;
