import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../../constants/UrlConstants";

export function useGoLoginPage() {
    const navigate = useNavigate();
    const isLoginClickedRef = useRef(false);
    useEffect(() => {
        isLoginClickedRef.current = false;
    }, [location]);

    return {
        goLogin: (beforeMoveCallback?: () => void, isReplace?: boolean) => {
            // 광클 로그인 방지를 위해 넣어둠
            // 로그인에 이벤트가 많은데 페이지 이동이라 async / await 처리가 불가능.
            if (!isLoginClickedRef.current) {
                isLoginClickedRef.current = true;
                if (beforeMoveCallback) {
                    beforeMoveCallback();
                }

                if (typeof isReplace === "undefined" && isReplace === false) {
                    location.href = `${URL_CONSTANTS.API_URL}/auth/login`;
                } else {
                    location.replace(`${URL_CONSTANTS.API_URL}/auth/login`);
                }
            }
        },
    };
}
