import { ReactElement } from "react";
import styled from "styled-components";
import CalendarSelectedMobileCellRow from "./CalendarSelectedMobileCellRow";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";

export const CalendarCellMobileKeyPrimaryRowContainer = styled.div``;

type Props = {
    keyMsg: string;
    valueMsg: string;
    hasIcon?: boolean;
};

const CalendarSelectedMobileCelleKeyPrimaryRow = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarSelectedMobileCellRow
            {...props}
            keyStyle={{
                color: "#FA54FA",
            }}
            iconPath={
                props.hasIcon
                    ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-checked.svg`
                    : undefined
            }
        />
    );
};

export default CalendarSelectedMobileCelleKeyPrimaryRow;
CalendarSelectedMobileCelleKeyPrimaryRow.defaultProps = {
    hasIcon: true,
};
