import { ReactElement } from "react";
import styled from "styled-components";
import Carousel, {
    RenderComponentDataType,
} from "../../../../../../../common/commonUnitComponent/carousel/Carousel";
import TemplatePreview from "../../../../../../../common/commonUiComponent/templatePreview/TemplatePreview";
import {
    AdBidingSelectTemplateCarouselButton,
    AdBidingSelectTemplateCarouselIndicator,
} from "../../../AdBidingSelectTemplate";
import { format } from "date-fns";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../../common/commonStyle/CommonButtonStyle";
import { AdBidingTemplateSelectResolveType } from "../AdBidingSelectTemplateModal";
import { TemplateListItemType } from "../../../../../../../../types/ad/templateInfo/TemplateListItemType";
import { useOpenCommonAlertModal } from "../../../../../../../../hooks/modal/openModal/useOpenCommonAlertModal";

export const AdBidingSelectTemplateModalTemplateListItem = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    gap: 20px;

    @media ${(props) => props.theme.mobileL} {
        flex-wrap: wrap;
        margin-bottom: 40px;
        gap: 14px;
    }
`;

export const AdBidingSelectTemplateModalTemplateListItemCarouselContainer = styled.div`
    position: relative;
    width: 286px;
    height: 100%;
    flex-shrink: 0;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const AdBidingSelectTemplateModalTemplatePreviewContainer = styled.div`
    position: relative;
    width: 286px;
    height: 164px;
    background: #f5f5f7;
    border-radius: 8px;
    overflow: hidden;
`;

export const AdBidingSelectTemplateModalTemplateListItemStatusBadge = styled.div<{
    status: string;
}>`
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;
    background: ${({ status }) => {
        switch (status) {
            case "1":
                return "#B4B4B4";
            case "2":
                return "#0cd28b";
            case "3":
                return "#FF4671";
        }
    }};
    color: #ffffff;
    border-radius: 16px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
`;

export const AdBidingSelectTemplateModalTemplateListItemInfoContainer = styled.div`
    width: 100%;
    flex-shrink: 1;

    @media ${(props) => props.theme.mobileL} {
        display: flex;
        justify-content: space-between;
    }
`;

export const AdBidingSelectTemplateModalBodyListItemInfoTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #000000;
    @media ${(props) => props.theme.mobileL} {
        font-size: 20px;
        line-height: 100%;
    }
`;
export const AdBidingSelectTemplateModalBodyListItemInfoLink = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #999999;
    margin-top: 10px;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;
export const AdBidingSelectTemplateModalBodyListItemInfoRegisterDate = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #999999;
    margin-top: 8px;
`;
export const AdBidingSelectTemplateModalBodyListItemInfoUseButton = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    width: calc(100% - 22px);
    border: 1px solid #fa54fa;
    font-size: 14px;
    margin-top: 20px;
    padding: 10px 0;
    height: auto;
    text-align: center;
    cursor: pointer;

    @media ${(props) => props.theme.mobileL} {
        padding: 0 16px;
        height: 34px;
        width: auto;
        display: inline-box;
        flex-shrink: 0;
        margin-top: 0;
    }
`;

export const AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyBold = styled.div`
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

type Props = {
    templateListItem: TemplateListItemType;
    setSelectedTemplateIdx: (value: string) => void;
    setTemplateType?: (value: string) => void;
    resolve: (value: AdBidingTemplateSelectResolveType) => void;
};

const AdBidingSelectTemplateListItem = (props: Props): ReactElement | null => {
    const { openCommonAlertModal } = useOpenCommonAlertModal();
    const spaceList = props.templateListItem.templateInfo.template_space
        .map((spaceList) =>
            spaceList.template_space_element.filter(
                (space) => space.presv_campaign_space_element_type === "1",
            ),
        )
        .flat();

    return (
        <AdBidingSelectTemplateModalTemplateListItem>
            <AdBidingSelectTemplateModalTemplateListItemCarouselContainer>
                <AdBidingSelectTemplateModalTemplateListItemStatusBadge
                    status={props.templateListItem.templateInfo.template_status}
                >
                    {props.templateListItem.templateInfo.template_status ===
                        "1" && "검토중"}
                    {props.templateListItem.templateInfo.template_status ===
                        "2" && "사용가능"}
                    {props.templateListItem.templateInfo.template_status ===
                        "3" && "반려"}
                </AdBidingSelectTemplateModalTemplateListItemStatusBadge>
                <Carousel
                    isShowPagination={true}
                    responsiveDisplayLength={[1, 1, 1, 1, 1, 1]}
                    dataList={spaceList}
                    displayLength={1}
                    renderComponent={(data: RenderComponentDataType) => {
                        return (
                            <AdBidingSelectTemplateModalTemplatePreviewContainer
                                key={data.idx}
                            >
                                <TemplatePreview
                                    imgPath={
                                        data.listItem
                                            .template_space_element_img_path
                                    }
                                />
                            </AdBidingSelectTemplateModalTemplatePreviewContainer>
                        );
                    }}
                    renderArrow={(data) => (
                        <AdBidingSelectTemplateCarouselButton
                            direction={data.type}
                        />
                    )}
                    renderPagination={(data) => (
                        <AdBidingSelectTemplateCarouselIndicator>
                            {data.currentIndex + 1}/{data.length}
                        </AdBidingSelectTemplateCarouselIndicator>
                    )}
                />
            </AdBidingSelectTemplateModalTemplateListItemCarouselContainer>
            <AdBidingSelectTemplateModalTemplateListItemInfoContainer>
                <div>
                    <AdBidingSelectTemplateModalBodyListItemInfoTitle>
                        {props.templateListItem.templateInfo.template_name}
                    </AdBidingSelectTemplateModalBodyListItemInfoTitle>
                    <AdBidingSelectTemplateModalBodyListItemInfoLink>
                        · {props.templateListItem.templateInfo.endPoint}
                    </AdBidingSelectTemplateModalBodyListItemInfoLink>
                    <AdBidingSelectTemplateModalBodyListItemInfoRegisterDate>
                        · 등록일 :{" "}
                        {format(
                            new Date(
                                props.templateListItem.templateInfo.updated_at,
                            ).getTime(),
                            "yyyy.MM.dd",
                        )}
                    </AdBidingSelectTemplateModalBodyListItemInfoRegisterDate>
                </div>
                {props.templateListItem.templateInfo.template_status ===
                    "2" && (
                    <AdBidingSelectTemplateModalBodyListItemInfoUseButton
                        btnType={"outlined-primary"}
                        onClick={async () => {
                            props.setSelectedTemplateIdx(
                                props.templateListItem.templateInfo
                                    .template_idx,
                            );

                            if (props.setTemplateType) {
                                props.setTemplateType(
                                    props.templateListItem.templateInfo
                                        .template_type,
                                );

                                if (
                                    props.templateListItem.templateInfo
                                        .template_type === "2"
                                ) {
                                    await openCommonAlertModal({
                                        hasCloseBtn: false,
                                        title: "타임세일 제한 안내",
                                        btnType: "filled-secondary",
                                        desc: (
                                            <AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyContainer>
                                                타임세일 적용 대상이 아닌
                                                템플릿입니다. 유저 콘텐츠형 광고
                                                템플릿만 타임세일이 적용됩니다.
                                                <AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyBold>
                                                    구매 금액을 반드시
                                                    확인해주세요.
                                                </AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyBold>
                                            </AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyContainer>
                                        ),
                                    });
                                }
                            }

                            props.resolve({
                                isOnlyClose: false,
                                isAddTemplate: false,
                                isAddTemplateHasGuide: false,
                                isTemplateSelect: true,
                            });
                        }}
                    >
                        사용
                    </AdBidingSelectTemplateModalBodyListItemInfoUseButton>
                )}
            </AdBidingSelectTemplateModalTemplateListItemInfoContainer>
        </AdBidingSelectTemplateModalTemplateListItem>
    );
};

export default AdBidingSelectTemplateListItem;
