import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";

export const MarketPointOrderCountInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const MarketPointOrderCountButton = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(13, 13, 26, 0.06);
    border-radius: 8px;
    font-size: 30px;
    font-weight: 700;
    cursor: pointer;
`;

export const MarketPointOrderCountInputBox = styled.input<{
    isWarning?: boolean;
}>`
    padding: 15px 10px;
    height: 8px;
    width: auto;
    background: #ffffff;
    border: ${({ isWarning }) =>
        isWarning ? "1px solid #FF4671" : "1px solid #d9d9d9"};
    border-radius: 8px;
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;

    &:focus {
        outline: none;
    }
`;

type Props = {
    count: number;
    maxCount: number;
    setCount: (value: number) => void;
    isWarning?: boolean;
};

const OrderCountInput = (props: Props): ReactElement | null => {
    const isLogin = getIsLogin();
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const addComma = (price: number) => {
        return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const onChangePoints = (e: any) => {
        const { value } = e.target;
        let regex = /[^0-9]/g; // 숫자가 아닌 문자열을 매칭하는 정규식
        let result = value.replace(regex, "");

        let str = result.replaceAll(",", "");
        props.setCount(Number(str));
    };

    useEffect(() => {
        if (props.count < 0) {
            props.setCount(0);
        }
        if (props.count >= props.maxCount) {
            props.setCount(props.maxCount);
        }
    }, [props.count]);

    return (
        <MarketPointOrderCountInputContainer>
            <MarketPointOrderCountButton
                onClick={async () => {
                    if (!isLogin) {
                        await openLoginConfirmModal();
                    } else {
                        if (props.count > 0) {
                            props.setCount(props.count - 1);
                        }
                    }
                }}
            >
                -
            </MarketPointOrderCountButton>
            <MarketPointOrderCountInputBox
                value={props.count === 0 ? "0" : addComma(props.count)}
                type={"text"}
                size={8}
                isWarning={props.isWarning}
                onChange={(e) => {
                    onChangePoints(e);
                }}
            />
            <MarketPointOrderCountButton
                onClick={async () => {
                    if (!isLogin) {
                        await openLoginConfirmModal();
                    } else {
                        if (props.maxCount > props.count)
                            props.setCount(props.count + 1);
                    }
                }}
            >
                +
            </MarketPointOrderCountButton>
        </MarketPointOrderCountInputContainer>
    );
};

export default OrderCountInput;
