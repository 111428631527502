import styled from "styled-components";

export const OrderReceiptModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OrderReceiptModalDesc = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    margin-top: 16px;
    color: #777777;
`;

export const OrderReceiptModalWarning = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    display: flex;
    align-items: center;
    text-align: right;
    color: #ff4671;
    margin-top: 6px;
    justify-content: right;
`;
