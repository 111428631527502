import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import SubTab, {
    SubTabType,
} from "../../../../common/commonUiComponent/subTab/SubTab";
import { getSelectedSubTabKey } from "../../../../../utils/Utils";
import { DateRange } from "react-day-picker";
import { addDays } from "date-fns";
import { useGetUserProfileQuery } from "../../../../../query/user/useGetUserProfileQuery";
import { useLocation } from "react-router-dom";
import {
    COLLECTION_DEFAULT_VIEW_TYPE,
    getViewTypeSwitchTabList,
    getWalletCollectionSubTab,
    WalletCollectionFilters,
} from "./etc/WalletCollectionItemListConsntants";
import WalletCollectionHubHeader from "./components/walletCollectionHubHeader/WalletCollectionHubHeader";
import {
    ChipType,
    SortingItemType,
} from "../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { useLocalStorage } from "usehooks-ts";
import { SwitchTabType } from "../../../../common/commonUiComponent/switchTab/SwitchTab";
import WalletCollectionHubBody from "./components/walletCollectionHubBody/WalletCollectionHubBody";
import { FilterType } from "../../../../common/commonUiComponent/searchFilter/SearchFilter";
import { useIsMe } from "../../../../../hooks/common/useIsMe";
import {
    spreadItemInventoryGroupListPages,
    useGetItemInventoryGroupList,
} from "../../../../../query/itemInventory/useGetItemInventoryGroupList";
import { useInitSortingList } from "./useInitSortingList";

export const WalletCollectionHubWrapper = styled.div`
    margin-top: 120px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 80px;
    }
`;

export const WalletCollectionHubTitle = styled.div`
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    color: #222222;

    @media ${(props) => props.theme.mobileL} {
        font-size: 20px;
    }
`;

export const WalletCollectionHubSubTabContainer = styled.div`
    margin-top: 16px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 10px;
    }
`;

export const COLLECTION_OWNED_TAB = "owned";
export const COLLECTION_LIKED_TAB = "liked";
export const COLLECTION_CREATED_TAB = "created";
export const COLLECTION_PRIVATE_TAB = "private";
export const COLLECTION_COMMUNITY_TAB = "community";
export const COLLECTION_HISTORY_TAB = "history";

type Props = {
    userIdx: string;
};

const WalletCollectionHub = (props: Props): ReactElement | null => {
    const location = useLocation();
    const isMe = useIsMe();
    const today = new Date();
    const defaultSelected: DateRange = {
        from: addDays(today, -7),
        to: addDays(today, +1),
    };
    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRange>(defaultSelected);

    const { data: rawUserProfileData, isSuccess: isUserProfileDataSuccess } =
        useGetUserProfileQuery(props.userIdx);

    const [sortingList, setSortingList] = useState<SortingItemType[]>([]);

    const [filters, setFilters] = useState<FilterType[]>(
        WalletCollectionFilters,
    );

    const [searchInput, setSearchInput] = useState("");

    const [subTabList, setSubTabList] = useState<SubTabType[]>([]);
    const selectedSubTabKey = getSelectedSubTabKey(subTabList);

    const [itemListViewType] = useLocalStorage(
        "collectionItemListViewType",
        COLLECTION_DEFAULT_VIEW_TYPE,
    );
    const [viewTypeSwitchTabList, setViewTypeSwitchTabList] = useState<
        SwitchTabType[]
    >(getViewTypeSwitchTabList(itemListViewType));
    const selectedViewType =
        viewTypeSwitchTabList.find((tab) => tab.isSelected)?.key ||
        COLLECTION_DEFAULT_VIEW_TYPE;

    useEffect(() => {
        if (location.state && location.state.selectedSubTabIndex) {
            setSubTabList(
                subTabList.map((subTab, idx) => {
                    return {
                        ...subTab,
                        isSelected:
                            idx === Number(location.state.selectedSubTabIndex),
                    };
                }),
            );
        }
    }, []);

    const [groupChips, setGroupChips] = useState<ChipType[]>([]);
    const [isOnlyShowNotGrouping, setIsOnlyShowNotGrouping] = useState(false);

    const {
        isSuccess: isItemInventoryGroupListSuccess,
        data: rawItemInventoryGroupList,
    } = useGetItemInventoryGroupList({
        user_idx: props.userIdx,
        limit: 20,
        page_no: 1,
    });

    useEffect(() => {
        if (isItemInventoryGroupListSuccess) {
            const newGroupList = spreadItemInventoryGroupListPages(
                rawItemInventoryGroupList.pages,
            );

            const isSelectedGroupRemoved = !newGroupList.some(
                (newGroupChip) =>
                    newGroupChip.item_inventory_group_idx ===
                    groupChips.find((chip) => chip.isSelected)?.key,
            );

            const prevSelectedGroupIdx =
                groupChips.find((groupChip) => groupChip.isSelected)?.key || "";

            setGroupChips(
                newGroupList.map((newGroup, newGroupIdx) => {
                    return {
                        title: newGroup.item_inventory_group_name,
                        key: newGroup.item_inventory_group_idx,
                        isSelected:
                            // 초기 init
                            groupChips.length === 0
                                ? newGroupIdx === 0
                                : // 삭제한 경우
                                groupChips.length > newGroupList.length
                                ? // 선택된게 삭제된 경우
                                  isSelectedGroupRemoved
                                    ? false // 전체로 변경
                                    : groupChips.find(
                                          (groupChip) =>
                                              groupChip.key ===
                                              newGroup.item_inventory_group_idx,
                                      )?.isSelected || false
                                : // 추가한 경우
                                newGroupIdx > groupChips.length - 1
                                ? false
                                : //순서이동인 경우 === length 같은 경우
                                  newGroup.item_inventory_group_idx ===
                                  prevSelectedGroupIdx,
                    };
                }),
            );
        }
    }, [rawItemInventoryGroupList, isItemInventoryGroupListSuccess]);

    // sortingList 초기화
    useInitSortingList(
        sortingList,
        setSortingList,
        selectedSubTabKey,
        groupChips,
        isItemInventoryGroupListSuccess,
    );

    // 유저 정보 받아서 탭 타이틀 세팅
    useEffect(() => {
        // 초기 세팅인 경우
        if (subTabList.length === 0) {
            setSubTabList(
                getWalletCollectionSubTab(
                    rawUserProfileData,
                    isMe(rawUserProfileData.userInfo.user_idx),
                ),
            );
        } else {
            setSubTabList(
                getWalletCollectionSubTab(
                    rawUserProfileData,
                    isMe(rawUserProfileData.userInfo.user_idx),
                ).map((subTab, subTabIdx) => {
                    return {
                        ...subTab,
                        isSelected: selectedSubTabKey === subTab.key,
                    };
                }),
            );
        }
    }, [isUserProfileDataSuccess, rawUserProfileData]);

    return (
        <WalletCollectionHubWrapper>
            <WalletCollectionHubTitle>아이템</WalletCollectionHubTitle>
            <WalletCollectionHubSubTabContainer>
                <SubTab subTabList={subTabList} setSubTabList={setSubTabList} />
            </WalletCollectionHubSubTabContainer>

            <WalletCollectionHubHeader
                viewTypeSwitchTabList={viewTypeSwitchTabList}
                setViewTypeSwitchTabList={setViewTypeSwitchTabList}
                selectedSubTabKey={selectedSubTabKey}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                filters={filters}
                setFilters={setFilters}
                sortingList={sortingList}
                setSortingList={setSortingList}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                subTabList={subTabList}
                groupChips={groupChips}
                setGroupChips={setGroupChips}
                isOnlyShowNotGrouping={isOnlyShowNotGrouping}
                setIsOnlyShowNotGrouping={setIsOnlyShowNotGrouping}
                userIdx={props.userIdx}
            />

            <WalletCollectionHubBody
                userIdx={props.userIdx}
                selectedSubTabKey={selectedSubTabKey}
                selectedDateRange={selectedDateRange}
                selectedViewType={selectedViewType}
                sortingList={sortingList}
                filters={filters}
                searchInput={searchInput}
                groupChips={groupChips}
                isOnlyShowNotGrouping={isOnlyShowNotGrouping}
            />
        </WalletCollectionHubWrapper>
    );
};

export default WalletCollectionHub;
