import { ReactElement } from "react";
import styled from "styled-components";
import ApiResBoundary from "../../../../../../common/apiResBoundary/ApiResBoundary";
import RightSideBarInfo from "../../../../../../common/commonUiComponent/doubleSideBar/rightSideBar/rightSideBarInfo/RightSideBarInfo";
import { convertAnalysisInfoKeyList } from "../../../../../../common/commonUiComponent/doubleSideBar/rightSideBar/RightSideBar";
import { ItemAnalysisList } from "../../../../MarketItemContents";
import { useItemDataQuery } from "../../../../../../../query/item/useItemDataQuery";
import { useParams } from "react-router-dom";

export const MarketItemMobileCreatorInfoContainer = styled.div``;

type Props = {};

const MarketItemMobileCreatorInfo = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const {
        data: rawItemData,
        error: itemDataError,
        isLoading: isItemDataLoading,
        refetch: refetchItemData,
    } = useItemDataQuery(itemIdx);

    return (
        <MarketItemMobileCreatorInfoContainer>
            <ApiResBoundary
                isNoData={false}
                isLoading={isItemDataLoading}
                error={itemDataError}
            >
                <RightSideBarInfo
                    creatorInfo={rawItemData.creatorInfo}
                    analysisData={convertAnalysisInfoKeyList(
                        rawItemData.analysisInfo,
                        ItemAnalysisList,
                    )}
                    refetchGetCreatorInfo={refetchItemData}
                />
            </ApiResBoundary>
        </MarketItemMobileCreatorInfoContainer>
    );
};

export default MarketItemMobileCreatorInfo;
