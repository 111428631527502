import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { SearchUserInfoType } from "../query/user/useSearchUserInfoQuery";
import { getAuthKey } from "../store/auth/authKeyStore";
import { UserProfileType } from "../query/user/useGetUserProfileQuery";
import { UserIdxInfoType } from "../query/user/useGetUserIdxQuery";
import {
    GetUserFollowDataParamType,
    UserFollowDataApiResultType,
} from "../query/user/follow/useGetUserFollowDataQuery";
import { UserInfoType } from "../store/userInfo/userInfoAtom";

export const UserApi = {
    get: async (authKey: string): Promise<UserInfoType> => {
        return new Promise((resolve, reject) => {
            if (authKey === "") reject("no authKey");
            else {
                axios
                    .get(URL_CONSTANTS.API_URL + "/user/status", {
                        headers: { Authorization: "Bearer " + authKey },
                    })
                    .then((res: any) => {
                        // api call 은 성공했지만(200), 내부 에러로 값이 안내려오는 케이스 처리를 이중으로 해야함.
                        resolve(res.data.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    },

    search: async (): Promise<SearchUserInfoType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/user/info/search")
                .then((res: any) => {
                    // api call 은 성공했지만(200), 내부 에러로 값이 안내려오는 케이스 처리를 이중으로 해야함.
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getUserIdx: async (userId: string): Promise<UserIdxInfoType> => {
        return new Promise((resolve, reject) => {
            if (userId === "") reject("no userId");
            axios
                .get(URL_CONSTANTS.API_URL + "/user/" + userId)
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getUserProfile: async (userIdx: string): Promise<UserProfileType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/user/" + userIdx + "/profile", {
                    headers: { Authorization: "Bearer " + getAuthKey() },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getFollowList: async (
        userIdx: string,
        params: GetUserFollowDataParamType,
    ): Promise<UserFollowDataApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/user/" + userIdx + "/follow", {
                    params: params,
                    headers: { Authorization: "Bearer " + getAuthKey() },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    follow: async (userIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/user/" + userIdx + "/follow",
                    {},
                    {
                        headers: { Authorization: "Bearer " + getAuthKey() },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    unfollow: async (userIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    URL_CONSTANTS.API_URL + "/user/" + userIdx + "/follow",
                    {
                        headers: { Authorization: "Bearer " + getAuthKey() },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
