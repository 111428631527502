import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { extractNumbers } from "../../../../../../../../../../utils/Utils";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import { HiddenKeyManage } from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarCellMobileBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarCellMobileBodyOnBid = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const {
        targetDateInfo: cellInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdMyDailyDateData(props);

    return !isAdStop ? (
        isTodaySale ? (
            typeof cellInfo.offerSuccessInfo === "undefined" ? (
                cellInfo.highestOfferInfo ? (
                    <>
                        <CalendarCellRow
                            msg={`${extractNumbers(
                                cellInfo.highestOfferInfo.offer_price + "",
                            )}`}
                            type={"primary-weak"}
                        />
                        <HiddenKeyManage
                            id={
                                "AD_ON_BID__notAdStop__dailySale__offerSuccessInfo__mobile"
                            }
                        />
                    </>
                ) : (
                    <>
                        <CalendarCellRow msg={"입찰중"} type={"green"} />
                        <HiddenKeyManage
                            id={
                                "AD_ON_BID__notAdStop__dailySale__highestOfferInfo__mobile"
                            }
                        />
                    </>
                )
            ) : (
                <>
                    <CalendarCellRow msg={"낙찰완료"} type={"normal"} />
                    <HiddenKeyManage
                        id={"AD_ON_BID__notAdStop__dailySale__noOffer__mobile"}
                    />
                </>
            )
        ) : (
            <>
                <CalendarCellRow msg={"판매중단"} type={"danger"} />
                <HiddenKeyManage
                    id={"AD_ON_BID__notAdStop__notDailySale__noOffer__mobile"}
                />
            </>
        )
    ) : (
        <>
            <HiddenKeyManage id={"AD_ON_BID__AdStop__mobile"} />
        </>
    );
};

export default AdSaleManageCalendarCellMobileBodyOnBid;
