import { hasPathAfterResolve, useOpenModal } from "../useOpenModal";
import React from "react";
import { MyItemOrderListItemType } from "../../../query/my/myItem/useGetMyItemOrderListQuery";
import OrderItemReceiptModal, {
    OrderItemReceiptInfoType,
} from "../../../components/common/commonUiComponent/orderReceiptModal/orderItem/OrderItemReceiptModal";
import { useNavigate } from "react-router-dom";

export function useOpenItemReceiptModal() {
    const navigate = useNavigate();
    const { openModalWithReturnValue } = useOpenModal();

    return {
        openItemReceiptModal: async (
            orderPointReceiptInfo: OrderItemReceiptInfoType,
        ) => {
            return await openModalWithReturnValue(
                "point-history-state",
                "bottomSheet",
                (resolve) => {
                    return (
                        <OrderItemReceiptModal
                            receiptInfo={orderPointReceiptInfo}
                            resolve={resolve}
                        />
                    );
                },
            );
        },
    };
}

export function getOrderItemReceiptInfo(
    myOrderListItem: MyItemOrderListItemType,
): OrderItemReceiptInfoType {
    return {
        order_idx: myOrderListItem.order.order_idx,
        product_idx: myOrderListItem.product_idx,
        order_unit_price: myOrderListItem.order_sub_unit_price,
        order_quantity: myOrderListItem.order_sub_quantity,
        order_type: myOrderListItem.order.order_type,
        order_status: myOrderListItem.order.order_status,
        ordered_at: myOrderListItem.order.ordered_at,
        settled_quantity_sum: myOrderListItem.settled_quantity_sum,
        project_idx: myOrderListItem.itemInfo.project_idx,
        unit_creator_royalty: myOrderListItem.order.unit_creator_royalty || 0,
        unit_brand_royalty: myOrderListItem.order.unit_brand_royalty || 0,
        item_brand_name: myOrderListItem.brandInfo.brand_name,
        currency_point_name: myOrderListItem.currencyInfo.point_symbol,
        order_valid_until: myOrderListItem.order_valid_until,
        latest_settlement_at: myOrderListItem.latest_settlement_at,
        updated_at: myOrderListItem.updated_at,
        created_at: myOrderListItem.created_at,
        cancelled_at: myOrderListItem.order.cancelled_at,
    };
}
