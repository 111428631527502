import React, { ReactElement } from "react";
import styled from "styled-components";
import {
    checkUserAgent,
    onErrorImg,
    vibrate,
} from "../../../../../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../../../../../constants/UrlConstants";
import { Link, useNavigate } from "react-router-dom";
import DndListItemTrigger from "../../../../../../../../common/commonUnitComponent/dndList/DndListItemTrigger";
import ThreeDotButton from "../../../../../../../../common/commonUnitComponent/threeDotButton/ThreeDotButton";
import { useOpenCommonConfirmModal } from "../../../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { useAdTemplateDeleteMutation } from "../../../../../../../../../query/ad/template/useAdTemplateDeleteMutation";
import { format } from "date-fns";
import { TemplateListItemType } from "../../../../../../../../../types/ad/templateInfo/TemplateListItemType";
import isScrollLockAtom from "../../../../../../../../../store/scroll/isScrollLockAtom";
import { useSetAtom } from "jotai/react";

export const AdManageMyBidTemplateAreaBodyListItemContainer = styled(Link)`
    width: 100%;
    height: 314px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 12px;

    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    display: block;
`;

export const AdManageMyBidTemplateAreaBodyListItemThumbnailContainer = styled.div<{
    backgroundColor: string;
}>`
    position: relative;
    width: 100%;
    height: 201px;
    background: #f5f5f7;
    border-radius: 11px 11px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AdManageMyBidTemplateAreaBodyListItemThumbnailMoveIcon = styled.img<{
    isMobileDevice: boolean;
    isOrderChanging: boolean;
}>`
    position: absolute;
    width: 40px;
    height: 40px;
    left: 10px;
    top: 10px;

    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    transition: 300ms opacity ease;
    display: none;

    ${AdManageMyBidTemplateAreaBodyListItemContainer}:hover & {
        display: block;

        @media ${(props) => props.theme.tabletM} {
            display: ${({ isMobileDevice }) =>
                !isMobileDevice ? "block" : "none"};
        }
    }

    @media ${(props) => props.theme.tabletM} {
        display: ${({ isOrderChanging, isMobileDevice }) =>
            isOrderChanging && isMobileDevice ? "block" : "none"};
    }
`;

export const AdManageMyBidTemplateAreaBodyListItemThumbnailStatusBadge = styled.div<{
    status: string;
}>`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    right: 10px;
    top: 10px;

    background: ${({ status }) => {
        {
            switch (status) {
                case "1":
                    return "#B4B4B4";
                case "2":
                    return "#0cd28b";
                case "3":
                    return "#FF4671";
            }
        }
    }};
    border-radius: 16px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;

    color: #ffffff;
`;

export const AdManageMyBidTemplateAreaBodyListItemThumbnail = styled.img`
    width: auto;
    height: auto;
    max-width: 95%;
    max-height: 95%;
    object-fit: fill;
`;

export const AdManageMyBidTemplateAreaBodyListItemInfoContainer = styled.div`
    padding: 15px 17px;
    position: relative;
`;

export const AdManageMyBidTemplateAreaBodyListItemInfoThreeDotContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const AdManageMyBidTemplateAreaBodyListItemInfoTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 11px;
`;

export const AdManageMyBidTemplateAreaBodyListItemInfoDescContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 8px;
`;

export const AdManageMyBidTemplateAreaBodyListItemInfoDesc = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height, or 125% */

    width: 100%;

    color: #999999;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;

type Props = {
    campaignIdx: string;
    templateListItem: TemplateListItemType;
    boxPerRow: number;
    background: string;
    isMobileOrderChanging: boolean;
};

const AdManageMyTemplateListBodyTemplateListItem = (
    props: Props,
): ReactElement | null => {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { mutateAsync: deleteTemplate } = useAdTemplateDeleteMutation(
        props.templateListItem.templateInfo.template_idx,
    );

    const navigate = useNavigate();
    const threeDotConfig = [
        {
            title: "수정하기",
            clickHandler: () => {
                navigate(
                    "/ad/template-adjust/" +
                        props.templateListItem.templateInfo.template_idx,
                );
            },
            isGoOutIcon: false,
            isShow: props.templateListItem.templateInfo.template_status !== "2",
        },
        {
            title: "복제하기",
            clickHandler: () => {
                navigate(
                    "/ad/template-copy/" +
                        props.templateListItem.templateInfo.template_idx,
                );
            },
            isGoOutIcon: false,
            isShow: props.templateListItem.templateInfo.template_status === "2",
        },
        {
            title: "삭제하기",
            clickHandler: async () => {
                const confirmResult = await openCommonConfirmModal({
                    title: "템플릿 삭제",
                    desc: "정말로 삭제 하시겠습니까?",
                });
                if (confirmResult) {
                    await deleteTemplate();
                }
            },
            isGoOutIcon: false,
        },
    ];

    const spaceList = props.templateListItem.templateInfo.template_space
        .map((spaceList) =>
            spaceList.template_space_element.filter(
                (space) => space.presv_campaign_space_element_type === "1",
            ),
        )
        .flat();
    const setIsScrollLock = useSetAtom(isScrollLockAtom);

    return (
        <AdManageMyBidTemplateAreaBodyListItemContainer
            to={
                props.templateListItem.templateInfo.template_status === "2"
                    ? `/ad/template/${props.templateListItem.templateInfo.template_idx}`
                    : `/ad/template-adjust/${props.templateListItem.templateInfo.template_idx}`
            }
        >
            <AdManageMyBidTemplateAreaBodyListItemThumbnailContainer
                backgroundColor={props.background}
            >
                <DndListItemTrigger
                    renderTrigger={() => (
                        <AdManageMyBidTemplateAreaBodyListItemThumbnailMoveIcon
                            onClick={() => {
                                vibrate(100);
                            }}
                            onTouchStart={() => {
                                document.body.addEventListener(
                                    "touchmove",
                                    (e) => {
                                        e.preventDefault();
                                    },
                                );
                            }}
                            onTouchEnd={() => {
                                document.body.removeEventListener(
                                    "touchmove",
                                    (e) => {
                                        e.preventDefault();
                                    },
                                );
                            }}
                            isMobileDevice={checkUserAgent.isMobileDevice()}
                            isOrderChanging={props.isMobileOrderChanging}
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-move.svg`}
                        />
                    )}
                />
                <AdManageMyBidTemplateAreaBodyListItemThumbnailStatusBadge
                    status={props.templateListItem.templateInfo.template_status}
                >
                    {props.templateListItem.templateInfo.template_status ===
                        "1" && "검토중"}
                    {props.templateListItem.templateInfo.template_status ===
                        "2" && "사용가능"}
                    {props.templateListItem.templateInfo.template_status ===
                        "3" && "반려"}
                </AdManageMyBidTemplateAreaBodyListItemThumbnailStatusBadge>
                <AdManageMyBidTemplateAreaBodyListItemThumbnail
                    onError={onErrorImg}
                    src={
                        spaceList[
                            spaceList.reduce(
                                (maxIndex, obj, currentIndex) =>
                                    spaceList[maxIndex]
                                        .presv_campaign_space_element_img_width +
                                        spaceList[maxIndex]
                                            .presv_campaign_space_element_img_height <
                                    obj.presv_campaign_space_element_img_width +
                                        obj.presv_campaign_space_element_img_height
                                        ? currentIndex
                                        : maxIndex,
                                0,
                            )
                        ].template_space_element_img_path
                    }
                />
            </AdManageMyBidTemplateAreaBodyListItemThumbnailContainer>
            <AdManageMyBidTemplateAreaBodyListItemInfoContainer>
                <AdManageMyBidTemplateAreaBodyListItemInfoThreeDotContainer>
                    <ThreeDotButton config={threeDotConfig} hasBorder={false} />
                </AdManageMyBidTemplateAreaBodyListItemInfoThreeDotContainer>

                <AdManageMyBidTemplateAreaBodyListItemInfoTitle>
                    {props.templateListItem.templateInfo.template_name}
                </AdManageMyBidTemplateAreaBodyListItemInfoTitle>
                <AdManageMyBidTemplateAreaBodyListItemInfoDescContainer>
                    <AdManageMyBidTemplateAreaBodyListItemInfoDesc>
                        · {props.templateListItem.templateInfo.endPoint}
                    </AdManageMyBidTemplateAreaBodyListItemInfoDesc>
                    <AdManageMyBidTemplateAreaBodyListItemInfoDesc>
                        ·{" "}
                        {format(
                            new Date(
                                props.templateListItem.templateInfo.updated_at,
                            ),
                            "yyyy.MM.dd",
                        )}
                    </AdManageMyBidTemplateAreaBodyListItemInfoDesc>
                </AdManageMyBidTemplateAreaBodyListItemInfoDescContainer>
            </AdManageMyBidTemplateAreaBodyListItemInfoContainer>
        </AdManageMyBidTemplateAreaBodyListItemContainer>
    );
};

export default AdManageMyTemplateListBodyTemplateListItem;
