import { InputTextDataType } from "../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import {
    DefaultExceptionUiHandlerType,
    ExceptionUiHandlerType,
} from "../../../../../apis/ErrorHandler/callApiExcpetionHandler";
import { useRef, useState } from "react";

export function useGetAdDaySaleStartTime(initInputData?: InputTextDataType) {
    const [adDaySaleStartTime, setAdDaySaleStartTime] =
        useState<InputTextDataType>(
            initInputData || {
                value: "12",
                isWarning: false,
                warningMsg: "",
            },
        );

    const adDaySaleStartTimeContainerRef = useRef<HTMLDivElement>(null);

    const exceptionUiHandler: ExceptionUiHandlerType = {
        ...DefaultExceptionUiHandlerType,
        inputText: adDaySaleStartTime,
        setInputText: setAdDaySaleStartTime,
        errorTarget: {
            targetName: "AdMinPrice",
        },
        targetContainerRef: adDaySaleStartTimeContainerRef,
        isDuplicateCheck: false,
    };

    return {
        adDaySaleStartTimeState: {
            adDaySaleStartTime: adDaySaleStartTime,
            setAdDaySaleStartTime: setAdDaySaleStartTime,
            adDaySaleStartTimeContainerRef: adDaySaleStartTimeContainerRef,
        },
        adDaySaleStartTimeExceptionUiHandler: exceptionUiHandler,
    };
}
