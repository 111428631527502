import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";

export const BrandApi = {
    follow: async (userIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/brand/" + userIdx + "/follow",
                    {},
                    {
                        headers: { Authorization: "Bearer " + getAuthKey() },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    unfollow: async (userIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    URL_CONSTANTS.API_URL + "/brand/" + userIdx + "/follow",
                    {
                        headers: { Authorization: "Bearer " + getAuthKey() },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
