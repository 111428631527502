import { ReactElement } from "react";
import styled from "styled-components";
import Scrollbar from "../../../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../../../../../common/commonUiComponent/scrollbar/ScrollbarType";
import WalletCollectionGroupMoveNoList from "./WalletCollectionGroupMoveNoList";
import WalletCollectionGroupMoveCheckListItem from "./WalletCollectionGroupMoveCheckListItem";
import {
    WalletCollectionGroupMoveModalGroupCheckListContainer,
    WalletCollectionGroupMoveModalGroupCheckListWrapper,
} from "../WalletCollectionGroupMoveModal";
import { ItemInventoryBelongingGroupListItemType } from "../../../../../../../../../types/itemInventory/ItemInventoryBelongingGroupListItemType";

export const WalletCollectionGroupMoveCheckListContainer = styled.div``;

type Props = {
    itemInventoryGroupCheckList: ItemInventoryBelongingGroupListItemType[];
    setItemInventoryGroupCheckList: (
        value: ItemInventoryBelongingGroupListItemType[],
    ) => void;
};

const WalletCollectionGroupMoveCheckList = (
    props: Props,
): ReactElement | null => {
    const changeCheckListItem = (groupIdx: number) => {
        props.setItemInventoryGroupCheckList(
            props.itemInventoryGroupCheckList.map(
                (checkListItem, checkListItemIdx) => {
                    return {
                        ...checkListItem,
                        item_inventory_group_sub_exist:
                            checkListItemIdx === groupIdx
                                ? !checkListItem.item_inventory_group_sub_exist
                                : checkListItem.item_inventory_group_sub_exist,
                    };
                },
            ),
        );
    };

    return (
        <WalletCollectionGroupMoveModalGroupCheckListWrapper>
            <Scrollbar
                type={SCROLL_BAR_NORMAL_TYPE}
                id={"wallet-collection-group-move-modal"}
            >
                {props.itemInventoryGroupCheckList.length === 0 ? (
                    <WalletCollectionGroupMoveNoList />
                ) : (
                    <WalletCollectionGroupMoveModalGroupCheckListContainer>
                        {props.itemInventoryGroupCheckList.map(
                            (group, groupIdx) => {
                                return (
                                    <WalletCollectionGroupMoveCheckListItem
                                        key={group.item_inventory_group_idx}
                                        itemInventoryGroupCheckListItem={group}
                                        changeCheckListItem={() => {
                                            changeCheckListItem(groupIdx);
                                        }}
                                    />
                                );
                            },
                        )}
                    </WalletCollectionGroupMoveModalGroupCheckListContainer>
                )}
            </Scrollbar>
        </WalletCollectionGroupMoveModalGroupCheckListWrapper>
    );
};

export default WalletCollectionGroupMoveCheckList;
