import { RefObject, useEffect, useRef, useState } from "react";

export function useScrollXSlideHooks(
    ref: RefObject<any>,
    additionalEndEvent?: () => void,
) {
    const [isDrag, _setIsDrag] = useState(false);
    const isDragRef = useRef(isDrag);
    const setIsDrag = function (value: boolean) {
        isDragRef.current = value;
        _setIsDrag(value);
    };

    const [dragStartPoint, _setDragStartPoint] = useState(0);
    const dragStartPointRef = useRef(dragStartPoint);
    const setDragStartPoint = function (value: number) {
        dragStartPointRef.current = value;
        _setDragStartPoint(value);
    };

    const startEvent = (clientX: number) => {
        if (ref.current) {
            setIsDrag(true);
            setDragStartPoint(ref.current.scrollLeft + clientX);
        }
    };
    const moveEvent = (e: MouseEvent | TouchEvent) => {
        const clientX = "clientX" in e ? e.clientX : e.touches[0].clientX;
        if (isDragRef.current && ref.current) {
            ref.current.scrollLeft = dragStartPointRef.current - clientX;
        }
    };
    const endEvent = () => {
        setIsDrag(false);
        if (additionalEndEvent) additionalEndEvent();
        // setDragStartPoint(0);
    };

    useEffect(() => {
        window.addEventListener("mousemove", moveEvent);
        window.addEventListener("touchmove", moveEvent);
        window.addEventListener("mouseup", endEvent);
        window.addEventListener("touchend", endEvent);
        return () => {
            window.removeEventListener("mousemove", moveEvent);
            window.removeEventListener("touchmove", moveEvent);
            window.removeEventListener("mouseup", endEvent);
            window.removeEventListener("touchend", endEvent);
        };
    }, []);

    return {
        dragStartPointRef,
        setDragStartPoint,
        isDragRef,
        setIsDrag,
        startEvent,
    };
}
