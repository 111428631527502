import { useAtom } from "jotai";

import { useMutation } from "react-query";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { AdOfferApi } from "../../../apis/adApi/AdOfferApi";

export type AcceptAdOfferApiParam = {
    ad_sales_date: string;
};

export const useAcceptAdOfferMutation = (
    adIdx: string,
    adOfferIdx: string,
    params: AcceptAdOfferApiParam,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => AdOfferApi.acceptOffer(adIdx, adOfferIdx, params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "성공적으로 낙찰되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
