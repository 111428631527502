import { createGlobalStyle } from "styled-components";
import { URL_CONSTANTS } from "../constants/UrlConstants";

const GlobalFontStyle = createGlobalStyle`
    @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 400;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/NotoSans/NotoSansKR-Regular.woff2`}) format("woff2");
    }

    @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 500;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/NotoSans/NotoSansKR-Medium.woff2`}) format("woff2");
    }

    @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 700;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/NotoSans/NotoSansKR-Bold.woff2`}) format("woff2");
    }

    @font-face {
        font-family: "Noto Sans KR";
        font-style: normal;
        font-weight: 900;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/NotoSans/NotoSansKR-Black.woff2`}) format("woff2");
    }

    @font-face {
        font-family: "esamanru";
        font-weight: 400;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/esamanru/esamanru-OTF-Medium.woff`}) format("woff");
    }

    @font-face {
        font-family: "esamanru-light";
        font-weight: 400;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/esamanru/esamanru-OTF-light.woff`}) format("woff");
    }

    @font-face {
        font-family: "esamanru-bold";
        font-weight: 400;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/esamanru/esamanru-OTF-Bold.woff`}) format("woff");
    }

    @font-face {
        font-family: "Gmarket Sans";
        font-style: normal;
        font-weight: 400;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/GmarketSans/GmarketSansTTFMedium.woff`}) format("woff");
    }

    @font-face {
        font-family: "Gmarket Sans";
        font-style: normal;
        font-weight: 500;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/GmarketSans/GmarketSansTTFBold.woff`}) format("woff");
    }

    @font-face {
        font-family: "CookieRun";
        font-style: normal;
        font-weight: 700;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/CookieRun/CookieRun_Bold.woff`}) format("woff");
    }

    @font-face {
        font-family: "Ghanachocolate";
        font-style: normal;
        font-weight: 400;
        src: url(${`${URL_CONSTANTS.ASSET_URL}/fonts/Ghanachocolate/Ghanachocolate.woff`}) format("woff");
    }

`;

export default GlobalFontStyle;
