import { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalDesc,
    ModalTitle,
} from "../../../../../common/commonUnitComponent/modal/ModalStyle";
import ModalCloseIcon from "../../../../../common/modal/modalCloseIcon/ModalCloseIcon";

export const AdTemplateRegisterStepConfirmModalContainer = styled.div``;

export type AdTemplateRegisterStepConfirmResolveType = {
    isOnlyClose: boolean;
    hasGuide: boolean;
};

type Props = {
    resolve: (value: AdTemplateRegisterStepConfirmResolveType) => void;
};

const AdTemplateRegisterStepConfirmModal = (
    props: Props,
): ReactElement | null => {
    return (
        <ModalContainer isNoPadding={false}>
            <ModalCloseIcon
                resolve={(value: boolean) => {
                    props.resolve({
                        isOnlyClose: true,
                        hasGuide: false,
                    });
                }}
            />

            <ModalTitle
                style={{
                    lineHeight: 1.4,
                }}
            >
                템플릿 등록이 처음이신가요?
            </ModalTitle>
            <ModalDesc
                style={{
                    marginTop: "16px",
                }}
            >
                초보자용 가이드와 함께 <br />
                템플릿 등록 안내를 받아보시겠습니까?
            </ModalDesc>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.resolve({
                            isOnlyClose: false,
                            hasGuide: false,
                        });
                    }}
                >
                    아니오
                </ModalButton>
                <ModalButton
                    btnType={"filled-primary"}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.resolve({
                            isOnlyClose: false,
                            hasGuide: true,
                        });
                    }}
                >
                    가이드 받기
                </ModalButton>
            </ModalButtonContainer>
        </ModalContainer>
    );
};

export default AdTemplateRegisterStepConfirmModal;
