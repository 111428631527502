import { ReactElement, useEffect } from "react";
import styled, {
    FlattenInterpolation,
    FlattenSimpleInterpolation,
    ThemeProps,
} from "styled-components";
import CalendarMonthSelector from "./components/calendarMonthPicker/CalendarMonthPicker";
import CalendarHeader from "./components/CalendarHeader";
import CalendarDateBody, {
    RenderCalendarCellParamType,
} from "./components/CalendarDateBody";

export const CalendarWrapper = styled.div`
    width: 100%;
`;

export const CalendarContainer = styled.div<{
    calendarContainerStyle?: FlattenSimpleInterpolation;
}>`
    ${({ calendarContainerStyle }) => calendarContainerStyle};
    margin-top: 24px;
`;

type Props = {
    initDate: Date;
    calendarContainerStyle?: FlattenSimpleInterpolation;
    headerContainerStyle?: FlattenInterpolation<ThemeProps<any>>;
    bodyContainerStyle?: FlattenSimpleInterpolation;
    mobileSelectedCellBgColor?: string;
    mobileSelectedCellBorderColor?: string;

    renderCellComponent: (value: RenderCalendarCellParamType) => ReactElement;
    renderMobileSelectedCellComponent?: () => ReactElement;
    isShowOnlyWeek: boolean;

    displayingDate: Date;
    setDisplayingDate: (value: Date) => void;
    selectedDate: Date;
    setSelectedDate: (value: Date) => void;
};

const Calendar = (props: Props): ReactElement | null => {
    useEffect(() => {
        if (props.isShowOnlyWeek) {
            props.setDisplayingDate(props.selectedDate);
        }
    }, [props.isShowOnlyWeek]);
    return (
        <CalendarWrapper>
            <CalendarMonthSelector
                selectedDate={props.selectedDate}
                setSelectedDate={props.setSelectedDate}
                displayingDate={props.displayingDate}
                setDisplayingDate={props.setDisplayingDate}
                isShowOnlyWeek={props.isShowOnlyWeek}
            />
            <CalendarContainer
                calendarContainerStyle={props.calendarContainerStyle}
            >
                <CalendarHeader
                    headerContainerStyle={props.headerContainerStyle}
                />
                <CalendarDateBody
                    bodyContainerStyle={props.bodyContainerStyle}
                    renderCellComponent={props.renderCellComponent}
                    renderMobileSelectedCellComponent={
                        props.renderMobileSelectedCellComponent
                    }
                    isShowOnlyWeek={props.isShowOnlyWeek}
                    selectedDate={props.selectedDate}
                    displayingDate={props.displayingDate}
                    mobileSelectedCellBgColor={props.mobileSelectedCellBgColor}
                    mobileSelectedCellBorderColor={
                        props.mobileSelectedCellBorderColor
                    }
                />
            </CalendarContainer>
        </CalendarWrapper>
    );
};

export default Calendar;
Calendar.defaultProps = {
    isShowOnlyWeek: false,
};
