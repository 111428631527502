import { ReactElement } from "react";
import styled from "styled-components";
import { useWindowSize } from "usehooks-ts";
import { FooterCompanyInfo } from "../../../../../constants/FooterDatas";
import { ResponsiveWidth } from "../../../../../styles/Theme";

export const FooterInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */

    @media ${(props) => props.theme.tabletM} {
        align-items: center;
    }

    @media ${(props) => props.theme.mobileM} {
        font-size: 11px;
    }
`;
export const FooterInfoRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
`;

export const FooterInfoRow = styled.div``;
type Props = {};

const FooterCompanyInfoList = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();

    return (
        <FooterInfoContainer>
            {FooterCompanyInfo.map((info, idx) => {
                if (
                    info.isMobileSeparate &&
                    width !== 0 &&
                    width < ResponsiveWidth.mobileM
                ) {
                    const splitTitle = info.title.split("|");
                    return (
                        <FooterInfoRowContainer key={idx}>
                            <FooterInfoRow>{splitTitle[0]}</FooterInfoRow>
                            <FooterInfoRow>{splitTitle[1]}</FooterInfoRow>
                        </FooterInfoRowContainer>
                    );
                } else {
                    return (
                        <FooterInfoRow key={idx}>{info.title}</FooterInfoRow>
                    );
                }
            })}
        </FooterInfoContainer>
    );
};

export default FooterCompanyInfoList;
