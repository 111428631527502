import { ReactElement, useEffect } from "react";
import { FilterType } from "../SearchFilter";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../../../store/scroll/viewHeightAtom";
import SearchFilterContents, {
    ModalSearchFilterLayer,
    SearchFilterLayerMobileHeader,
    SearchFilterLayerMobileTitle,
    SearchFilterLayerMobileTitleContainer,
} from "./SearchFilterContents/SearchFilterContents";
import styled from "styled-components";
import { ResponsiveWidth } from "../../../../../styles/Theme";
import { useWindowSize } from "usehooks-ts";

export const ModalTypeSearchFilterContainer = styled.div`
    height: auto;
`;

type Props = {
    resolve: (value: boolean) => void;
    filters: FilterType[];
    setFilters: (value: FilterType[]) => void;
    filterItemWidth: string;
    title: string;
    id: string;
    isSearchFilterOpen: boolean;
    setIsSearchFilterOpen: (value: boolean) => void;
};

const ModalTypeSearchFilter = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const id = props.id + "-modal";

    const { width } = useWindowSize();

    useEffect(() => {
        if (width !== 0 && width > ResponsiveWidth.tabletM) {
            props.resolve(false);
            props.setIsSearchFilterOpen(false);
        }
    }, [width]);

    return (
        <ModalTypeSearchFilterContainer>
            <SearchFilterLayerMobileHeader>
                <SearchFilterLayerMobileTitleContainer>
                    <SearchFilterLayerMobileTitle>
                        {props.title}
                    </SearchFilterLayerMobileTitle>
                </SearchFilterLayerMobileTitleContainer>
            </SearchFilterLayerMobileHeader>

            <ModalSearchFilterLayer vh={vh}>
                <SearchFilterContents
                    id={id}
                    isFullModal={false}
                    filterItemWidth={props.filterItemWidth}
                    setFilters={props.setFilters}
                    close={() => {
                        props.resolve(false);
                        props.setIsSearchFilterOpen(false);
                    }}
                    filters={props.filters}
                    isSearchFilterOpen={props.isSearchFilterOpen}
                />
            </ModalSearchFilterLayer>
        </ModalTypeSearchFilterContainer>
    );
};

export default ModalTypeSearchFilter;
