import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";
import SearchResultRecentList from "./components/SearchResultRecentList";
import { useAtom, useSetAtom } from "jotai";
import SearchResultHeader from "./components/SearchResultHeader";
import SearchResultList from "./components/SearchResultList/SearchResultList";
import { GlobalSearchResultTabIDsType } from "../../../../constants/menus/headerMenus";
import {
    AD_HOME_PAGE_PATHNAME,
    ITEM_PAGE_REG_EXP,
    PROJECT_PAGE_REG_EXP,
    WALLET_POINT_AND_TOKEN_PAGE_PATHNAME,
} from "../../../../constants/PageRegExp";
import { useLocation } from "react-router-dom";
import globalSearchInputAtom from "../../../../store/globalSearch/globalSearchInputAtom";
import { useLocalStorage, useOnClickOutside, useWindowSize } from "usehooks-ts";
import { checkUserAgent } from "../../../../utils/Utils";
import isScrollLockAtom from "../../../../store/scroll/isScrollLockAtom";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";
import { GLOBAL_USER_PROFILE_MODAL } from "../../../../hooks/modal/openModal/useOpenUserProfileModal";
import { GLOBAL_SEARCH_RESULT_MODAL } from "../../../../hooks/modal/openModal/useOpenSearchResultModal";

export const SearchResultWrapper = styled.div<{
    isSearching: boolean;
    vh: number;
}>`
    position: fixed;
    width: 360px;
    top: 72px;
    left: 260px;

    background: white;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    z-index: 20;
    transition: all 300ms ease;
    overflow: hidden;

    @media ${(props) => props.theme.tabletL} {
        top: 60px;
        left: 0;
        width: 100%;
        background: white;
        height: ${({ vh }) => 100 * vh + "px"};

        box-shadow: none;
        line-height: 1;
        border-radius: 0;
    }
`;

export const SearchResultContainer = styled.div`
    transition: all 300ms ease;

    @media ${(props) => props.theme.tabletL} {
        max-height: none;
    }
`;

type Props = {
    resolve: (value: boolean | number | PathAfterResolveType) => void;
};

const SearchResultModal = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const { width } = useWindowSize();
    const location = useLocation();
    const searchResultWrapperRef = useRef<HTMLDivElement>(null);
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const userProfileModalComponentInfo = modalComponentInfoList.find(
        (info) => info.id === GLOBAL_SEARCH_RESULT_MODAL,
    );
    const wrapperRef =
        userProfileModalComponentInfo?.nodeRef || searchResultWrapperRef;

    const [globalSearchInput, setGlobalSearchInput] = useAtom(
        globalSearchInputAtom,
    );
    const [isBackBtnClicked] = useLocalStorage("isBackBtnClicked", false);
    const setIsScrollLock = useSetAtom(isScrollLockAtom);
    const [selectedSearchMenuId, setSelectedSearchMenuId] =
        useState<GlobalSearchResultTabIDsType>("total");

    useEffect(() => {
        // ad... - [광고] 탭
        if (location.pathname.startsWith(AD_HOME_PAGE_PATHNAME)) {
            setSelectedSearchMenuId("ad");
        } else if (
            location.pathname.startsWith(WALLET_POINT_AND_TOKEN_PAGE_PATHNAME)
        ) {
            setSelectedSearchMenuId("collector");
        } else if (PROJECT_PAGE_REG_EXP.test(location.pathname)) {
            setSelectedSearchMenuId("project");
        } else if (ITEM_PAGE_REG_EXP.test(location.pathname)) {
            setSelectedSearchMenuId("item");
        }
    }, [location.pathname]);

    useOnClickOutside(wrapperRef, (e: any) => {
        if (e.target.id !== "header-search-input") props.resolve(-1);
    });

    useEffect(() => {
        // props.resolve(-1);
    }, [width]);

    return (
        <SearchResultWrapper
            isSearching={true}
            vh={vh}
            ref={wrapperRef}
            style={
                checkUserAgent.isIOS() && isBackBtnClicked
                    ? { transition: "none" }
                    : {}
            }
        >
            <SearchResultContainer>
                {/*입력창에 타이핑한게 없는 케이스*/}
                {globalSearchInput === "" ? (
                    <SearchResultRecentList />
                ) : (
                    /* 정상 검색 케이스*/
                    <>
                        <SearchResultHeader
                            selectedSearchMenuId={selectedSearchMenuId}
                            setSelectedSearchMenuId={setSelectedSearchMenuId}
                        />
                        <SearchResultList
                            resolve={props.resolve}
                            selectedSearchMenuId={selectedSearchMenuId}
                            setSelectedSearchMenuId={setSelectedSearchMenuId}
                        />
                    </>
                )}
            </SearchResultContainer>
        </SearchResultWrapper>
    );
};

export default SearchResultModal;
