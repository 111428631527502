import React, { ReactElement } from "react";
import styled from "styled-components";
import NoTabNoHeaderBox from "../../../../../../../../../../common/commonUnitComponent/box/NoTabNoHeaderBox";
import { URL_CONSTANTS } from "../../../../../../../../../../../constants/UrlConstants";
import { Link, useParams } from "react-router-dom";

export const MarketItemOrderExceptionBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const MarketItemOrderExceptionIconImg = styled.img`
    width: 72px;
    height: 72px;
    margin-top: 16px;
`;

export const MarketItemOrderExceptionTitle = styled.div`
    margin-top: 20px;
    color: #222;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4; /* 16px */
`;

export const MarketItemOrderExceptionTitleBold = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MarketItemOrderExceptionButton = styled(Link)`
    width: 100%;
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 100% */
    border-radius: 8px;
    background: #fa54fa;
    margin-top: 32px;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 0.7;
        }
    }

    transition: all 300ms ease;
`;

type Props = {
    title: string | ReactElement;
    btnTitle?: string;
    btnLinkPath?: string;
};

const MarketItemOrderExceptionBox = (props: Props): ReactElement | null => {
    const params = useParams();

    return (
        <NoTabNoHeaderBox isGuestAddOpacityEffect={true}>
            <MarketItemOrderExceptionBoxContainer>
                <MarketItemOrderExceptionIconImg
                    src={
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-warning-circle.svg"
                    }
                />
                <MarketItemOrderExceptionTitle>
                    {props.title}
                </MarketItemOrderExceptionTitle>
                {props.btnTitle && props.btnLinkPath && (
                    <MarketItemOrderExceptionButton to={props.btnLinkPath}>
                        {props.btnTitle}
                    </MarketItemOrderExceptionButton>
                )}
            </MarketItemOrderExceptionBoxContainer>
        </NoTabNoHeaderBox>
    );
};

export default MarketItemOrderExceptionBox;
