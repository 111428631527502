import { useAtom } from "jotai";
import { ReactElement, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import isShowMobileBottomButtonAtom from "../../../../store/ui/isShowMobileBottomButtonAtom";
import "../../modal/modalProvider/transition/modal-transition.css";
import { WALLET_PAGE_REG_EXP } from "../../../../constants/PageRegExp";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";

export const MobileBottomButtonsWrapper = styled.div<{
    isShow: boolean;
    vh: number;
}>`
    display: none;
    width: 100%;
    position: fixed;
    z-index: 2;
    bottom: calc(env(safe-area-inset-bottom) - 40px);
    height: 90px;
    overflow: hidden;
    border-top: 1px #f0f0f0 solid;
    background-color: white;

    transform: translateY(${({ isShow }) => (isShow ? 0 : "100%")});
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    transition: all 300ms ease;

    @media ${(props) => props.theme.tabletL} {
        display: block;
    }
`;

export const MobileBottomButtonsContainer = styled.div`
    display: flex;
`;

export const MobileBottomButtonLink = styled(Link)`
    width: 20%;
    height: 49px;
    padding-bottom: 21px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
`;

export const MobileBottomButtonIcon = styled.img`
    width: 24px;
`;

export const MobileBottomButtonTitle = styled.div<{
    isSelected: boolean;
}>`
    color: ${({ isSelected }) => (isSelected ? "#222" : "#666")};
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 10px */
`;

type Props = {};

const MobileBottomButtons = (props: Props): ReactElement | null => {
    const location = useLocation();
    const [vh] = useAtom(viewHeightAtom);
    const iconPrefix = URL_CONSTANTS.ASSET_URL + "/icon/";
    const buttons = [
        {
            iconPath: "mobile-bottom-home",
            linkPath: "/",
            title: "홈",
        },
        {
            iconPath: "mobile-bottom-ad",
            linkPath: "/ad",
            title: "광고",
        },
        {
            iconPath: "mobile-bottom-market",
            linkPath: "/market",
            title: "플리마켓",
        },
        {
            iconPath: "mobile-bottom-wallet",
            linkPath: "/wallet",
            title: "지갑",
        },
        {
            iconPath: "mobile-bottom-collection",
            linkPath: "/my-wallet",
            title: "컬렉션",
        },
    ];

    const [isShowMobileBottomButton] = useAtom(isShowMobileBottomButtonAtom);

    const getIsSelected = (linkPath: string | object) => {
        return linkPath === selectedPathType;
    };

    const [selectedPathType, setSelectedPathType] = useState("");

    useEffect(() => {
        const splitPathname = location.pathname.split("/");
        if (splitPathname.length > 0) {
            const firstPath = location.pathname.split("/")[1];
            setSelectedPathType("/" + firstPath);

            if (
                splitPathname.length > 2 &&
                splitPathname[2] !== "merge" &&
                WALLET_PAGE_REG_EXP.test(location.pathname)
            ) {
                setSelectedPathType("/my-wallet");
            }
        } else {
            setSelectedPathType("/");
        }
    }, [location.pathname]);

    return (
        <MobileBottomButtonsWrapper isShow={isShowMobileBottomButton} vh={vh}>
            <MobileBottomButtonsContainer>
                {buttons.map((btn, idx) => (
                    <MobileBottomButtonLink key={idx} to={btn.linkPath}>
                        <MobileBottomButtonIcon
                            src={
                                iconPrefix +
                                btn.iconPath +
                                (getIsSelected(btn.linkPath) ? "-black" : "") +
                                ".svg"
                            }
                        />
                        <MobileBottomButtonTitle
                            isSelected={getIsSelected(btn.linkPath)}
                        >
                            {btn.title}
                        </MobileBottomButtonTitle>
                    </MobileBottomButtonLink>
                ))}
            </MobileBottomButtonsContainer>
        </MobileBottomButtonsWrapper>
    );
};

export default MobileBottomButtons;
