import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import {
    UserProfileModalA2HSButtonAppContainer,
    UserProfileModalA2HSButtonAppIcon,
    UserProfileModalA2HSButtonContainer,
    UserProfileModalA2HSButtonDownloadIcon,
} from "./UserProfileModalAppInstallBtn";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import hasChannelTalkAccessAtom from "../../../../../../store/ui/hasChannelTalkAccessAtom";
import { useAtom } from "jotai";

export const UserProfileModalChatBtnContainer = styled.div`
    background: white;
    display: none;

    @media ${(props) => props.theme.tabletL} {
        display: block;
    }
`;

type Props = {};

const UserProfileModalChatBtn = (props: Props): ReactElement | null => {
    const [hasChannelTalkAccess] = useAtom(hasChannelTalkAccessAtom);

    useEffect(() => {
        const test = ChannelService;
    }, []);

    return hasChannelTalkAccess ? (
        <UserProfileModalChatBtnContainer>
            <UserProfileModalA2HSButtonContainer
                onClick={async () => {
                    ChannelService.openChat();
                }}
            >
                <UserProfileModalA2HSButtonAppContainer>
                    <UserProfileModalA2HSButtonAppIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-channel-talk.svg`}
                    />
                    채팅 문의
                </UserProfileModalA2HSButtonAppContainer>
                <UserProfileModalA2HSButtonDownloadIcon
                    mr={"8px"}
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-right-arrow.svg`}
                />
            </UserProfileModalA2HSButtonContainer>
        </UserProfileModalChatBtnContainer>
    ) : null;
};

export default UserProfileModalChatBtn;
