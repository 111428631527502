import { ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";
import { checkUserAgent } from "../../../../utils/Utils";
import { useBackgroundScrollFix } from "../../../../hooks/ui/useBackgroundScrollFix";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useAtom, useSetAtom } from "jotai";
import UserProfileModalContents from "./components/UserProfileModalContents";
import { useLocalStorage, useOnClickOutside } from "usehooks-ts";
import isShowMobileBottomButtonAtom from "../../../../store/ui/isShowMobileBottomButtonAtom";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";
import { GLOBAL_USER_PROFILE_MODAL } from "../../../../hooks/modal/openModal/useOpenUserProfileModal";

export const UserProfileModalWrapper = styled.div<{
    vh: number;
    isShowMobileBottomButton: boolean;
}>`
    color: black;
    width: 340px;
    position: fixed;
    top: 70px;
    right: 16px;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    background: white;
    overflow: hidden;
    z-index: 20;
    height: auto;
    max-height: auto;

    @media ${(props) => props.theme.tabletL} {
        top: 0;
        left: 0;
        right: auto;
        opacity: 1;
        border-radius: 0;

        width: 100vw;
        max-height: ${({ vh }) => 100 * vh + "px"};
        height: ${({ vh, isShowMobileBottomButton }) =>
            `${100 * vh - (isShowMobileBottomButton ? 50 : 0)}px`};

        box-shadow: none;
        background: #f5f5f5;
    }
`;

export const UserProfileModalMobileCloseBtnContainer = styled.div`
    width: 100%;
    height: 60px;
    text-align: right;
    display: none;

    @media ${(props) => props.theme.tabletL} {
        background: white;
        display: flex;
        align-content: center;
        flex-direction: row-reverse;
    }
`;

export const UserProfileModalMobileCloseIconImg = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
`;

type Props = {
    resolve: (value: boolean | number | PathAfterResolveType) => void;
};

const UserProfileModal = (props: Props): ReactElement | null => {
    const [isShowMobileBottomButton] = useAtom(isShowMobileBottomButtonAtom);
    const [vh] = useAtom(viewHeightAtom);
    const userProfileMenuWrapperRef = useRef<HTMLDivElement>(null);
    const [isBackBtnClicked] = useLocalStorage("isBackBtnClicked", false);
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const userProfileModalComponentInfo = modalComponentInfoList.find(
        (info) => info.id === GLOBAL_USER_PROFILE_MODAL,
    );
    const wrapperRef =
        userProfileModalComponentInfo?.nodeRef || userProfileMenuWrapperRef;

    useBackgroundScrollFix(true);

    useOnClickOutside(wrapperRef, (e: any) => {
        props.resolve(-1);
    });
    const setIsShowMobileBottomButton = useSetAtom(
        isShowMobileBottomButtonAtom,
    );

    useEffect(() => {
        // timeout 안걸면 slide 효과가 사라짐..
        setTimeout(() => {
            setIsShowMobileBottomButton(false);
        }, 300);

        return () => {
            setIsShowMobileBottomButton(true);
        };
    }, []);

    return (
        <UserProfileModalWrapper
            ref={wrapperRef}
            vh={vh}
            isShowMobileBottomButton={isShowMobileBottomButton}
            style={
                checkUserAgent.isIOS() && isBackBtnClicked
                    ? { transition: "none" }
                    : {}
            }
        >
            {/*닫기*/}
            <UserProfileModalMobileCloseBtnContainer>
                <UserProfileModalMobileCloseIconImg
                    onClick={() => {
                        props.resolve(-1);
                    }}
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-menu-close.png`}
                />
            </UserProfileModalMobileCloseBtnContainer>

            <UserProfileModalContents resolve={props.resolve} />
        </UserProfileModalWrapper>
    );
};

export default UserProfileModal;
