import { ReactElement } from "react";
import styled from "styled-components";
import { format } from "date-fns";

export const AdManageCommonHistoryColumnDateContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #555555;
`;

type Props = {
    date: any;
    format: string;
};

const AdManageCommonHistoryColumnDate = (props: Props): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnDateContainer>
            {props.date.getTime() !== 0
                ? format(props.date, props.format)
                : "-"}
        </AdManageCommonHistoryColumnDateContainer>
    );
};

export default AdManageCommonHistoryColumnDate;
