import { ReactElement } from "react";
import styled from "styled-components";
import { getStyle } from "./CalendarCellBackgroundRow";

export const CalendarCellRowContainer = styled.div<{
    opacity?: number;
    hasNoMt?: boolean;
    isMobileHide?: boolean;
}>`
    color: #555;
    padding: 0 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    opacity: ${({ opacity }) => opacity || 1};
    margin-top: ${({ hasNoMt }) => (hasNoMt ? "-10px" : "0")};

    @media ${(props) => props.theme.mobileL} {
        display: ${({ isMobileHide }) =>
            isMobileHide ? "none" : "inline-flex"};
        font-size: 10px;
    }
`;

export const CalendarCellPcRow = styled.span<{
    hasCancelLine?: boolean;
}>`
    display: inline-block;
    word-break: keep-all;
    line-height: 1.3;

    ${({ hasCancelLine }) =>
        hasCancelLine &&
        `
        text-decoration: line-through;
    `}
`;

export const CalendarCellMobileRow = styled.span`
    display: none;

    @media ${(props) => props.theme.mobileL} {
        display: inline-block;
    }
`;

type Props = {
    type: string;
    msg: string;
    opacity?: number;
    hasNoMt?: boolean;
    isMobileHide?: boolean;
    hasCancelLine?: boolean;
};

const CalendarCellRow = (props: Props): ReactElement | null => {
    return (
        <CalendarCellRowContainer
            opacity={props.opacity}
            hasNoMt={props.hasNoMt}
            isMobileHide={props.isMobileHide}
            style={{
                color: getStyle(props.type).color,
            }}
        >
            <CalendarCellPcRow hasCancelLine={props.hasCancelLine}>
                {props.msg}
            </CalendarCellPcRow>
        </CalendarCellRowContainer>
    );
};

export default CalendarCellRow;
