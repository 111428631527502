import { ReactElement } from "react";
import styled from "styled-components";
import {
    CalendarCellRowContainerStyle,
    CalendarCellRowIcon,
} from "./CaldendarCellRowStyle";

export const CalendarCellBackgroundRowContainer = styled.div<{
    opacity?: number;
}>`
    ${CalendarCellRowContainerStyle};
    display: flex;
    gap: 4px;
    align-items: center;
    opacity: ${({ opacity }) => opacity || 1};
`;

export const CalendarCellBackgroundRowIconContainer = styled.div`
    display: block;
    @media ${(props) => props.theme.tabletL} {
        display: none;
    }
`;

type Props = {
    type: string;
    msg: string;
    iconPath?: string;
    opacity?: number;
};

export const getStyle = (type: string) => {
    switch (type) {
        case "danger":
            return {
                color: "#ff4671",
                background: "rgba(255, 70, 113, 0.1)",
            };

        case "green":
            return {
                color: "#00b574",
                background: "rgba(12, 210, 139, 0.1)",
            };

        case "normal":
            return {
                color: "#000",
                background: "white",
            };

        case "primary":
            return {
                fontWeight: "700",
                color: "#fff",
                background: "#fa54fa",
            };

        case "primary-weak":
            return {
                color: "#FA54FA",
                background: "rgba(250, 84, 250, 0.08)",
            };

        case "secondary":
            return {
                color: "#555",
                background: "#f0f0f2",
            };

        case "blue":
            return {
                color: "#2e8eff",
                background: "rgba(46, 142, 255, 0.1)",
            };

        default:
            return {};
    }
};

const CalendarCellBackgroundRow = (props: Props): ReactElement | null => {
    return (
        <CalendarCellBackgroundRowContainer
            style={getStyle(props.type)}
            opacity={props.opacity}
        >
            {props.iconPath && (
                <CalendarCellBackgroundRowIconContainer>
                    <CalendarCellRowIcon src={props.iconPath} />
                </CalendarCellBackgroundRowIconContainer>
            )}
            {props.msg}
        </CalendarCellBackgroundRowContainer>
    );
};

export default CalendarCellBackgroundRow;
