import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { numberToStringWithComma } from "../../../../../../../../../../utils/Utils";
import { useAdInfoQuery } from "../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellBackgroundRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import AdSaleManageScheduleCalendarCellBodyHighestOfferRow from "../../adSaleManageCalendarCellBody/components/adSaleManageScheduleCalendarCellBodyRow/AdSaleManageScheduleCalendarCellBodyHighestOfferRow";
import CalendarSelectedMobileCellNormalRow from "../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellNormalRow";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";
import { HiddenKeyManage } from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarSelectedMobileCellBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarSelectedMobileCellInReview = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);
    const adInfo = rawAdData.advertisementInfo;
    const adSaleInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;

    const {
        targetDateInfo: cellInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdMyDailyDateData(props);

    return !isAdStop ? (
        isTodaySale ? (
            typeof cellInfo.offerSuccessInfo === "undefined" ? (
                cellInfo.highestOfferInfo ? (
                    <>
                        <CalendarCellBackgroundRow
                            msg={"시스템 준비중"}
                            type={"secondary"}
                        />
                        <AdSaleManageScheduleCalendarCellBodyHighestOfferRow
                            price={cellInfo.highestOfferInfo.offer_price}
                            nickname={cellInfo.highestOfferInfo.user_nick}
                        />
                        <CalendarSelectedMobileCellNormalRow
                            keyMsg={"즉시구매가"}
                            valueMsg={`${numberToStringWithComma(
                                adSaleInfo.ad_reserve_price * 1.1,
                            )} KRW`}
                        />
                        <HiddenKeyManage
                            id={
                                "AD_IN_REVIEW__notAdStop__dailySale__offerSuccessInfo__ms"
                            }
                        />
                    </>
                ) : (
                    <>
                        <CalendarCellBackgroundRow
                            msg={"시스템 준비중"}
                            type={"secondary"}
                        />
                        <CalendarSelectedMobileCellNormalRow
                            keyMsg={"최소입찰가"}
                            valueMsg={`${numberToStringWithComma(
                                cellInfo.adSaleInfo.ad_minimum_bid_price * 1.1,
                            )} KRW`}
                        />
                        <CalendarSelectedMobileCellNormalRow
                            keyMsg={"즉시구매가"}
                            valueMsg={`${numberToStringWithComma(
                                adSaleInfo.ad_reserve_price * 1.1,
                            )} KRW`}
                        />
                        <HiddenKeyManage
                            id={
                                "AD_IN_REVIEW__notAdStop__dailySale__highestOfferInfo__ms"
                            }
                        />
                    </>
                )
            ) : (
                <>
                    <CalendarCellBackgroundRow
                        msg={"낙찰완료"}
                        type={"secondary"}
                    />
                    <CalendarSelectedMobileCellNormalRow
                        keyMsg={`${cellInfo.offerSuccessInfo.offer_success_user_nick}`}
                        valueMsg={`${numberToStringWithComma(
                            cellInfo.offerSuccessInfo.offer_price,
                        )} KRW`}
                    />
                    <HiddenKeyManage
                        id={"AD_IN_REVIEW__notAdStop__dailySale__noOffer__ms"}
                    />
                </>
            )
        ) : (
            <>
                <CalendarCellBackgroundRow msg={"판매 중단"} type={"danger"} />
                <HiddenKeyManage
                    id={"AD_IN_REVIEW__notAdStop__notDailySale__noOffer__ms"}
                />
            </>
        )
    ) : (
        <>
            <CalendarCellBackgroundRow msg={"판매 중단"} type={"normal"} />
            <HiddenKeyManage id={"AD_IN_REVIEW__AdStop__ms"} />
        </>
    );
};

export default AdSaleManageCalendarSelectedMobileCellInReview;
