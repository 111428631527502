import React, { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";

export const AdManageCommonHistoryColumnBrandContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AdManageCommonHistoryColumnBrandThumbnail = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 6px;
`;

export const AdManageCommonHistoryColumnBrandTitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #555555;
`;

type Props = {
    brandName: string;
    brandId: string;
};

const AdManageCommonHistoryColumnBrand = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnBrandContainer>
            <AdManageCommonHistoryColumnBrandThumbnail
                src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${props.brandId}-thumbnail.svg`}
            />
            <AdManageCommonHistoryColumnBrandTitle>
                {props.brandName}
            </AdManageCommonHistoryColumnBrandTitle>
        </AdManageCommonHistoryColumnBrandContainer>
    );
};

export default AdManageCommonHistoryColumnBrand;
