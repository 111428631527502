import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";
import axios from "axios";
import {
    ItemInventoryGroupListResultType,
    GetGroupListParams,
} from "../query/itemInventory/useGetItemInventoryGroupList";
import { AddGroupParams } from "../query/itemInventory/useAddItemInventoryGroupMutation";
import { OrderChangeItemInventoryGroupParams } from "../query/itemInventory/useOrderChangeItemInventoryGroupMutation";
import { AdjustTitleItemInventoryGroupParams } from "../query/itemInventory/useAdjustTitleItemInventoryGroupMutation";
import {
    ItemInventoryBelongingGroupListApiResultType,
    ItemInventoryBelongingGroupListParamsType,
} from "../query/itemInventory/useGetItemInventoryBelongingGroupList";
import { SetItemInventoryBelongingGroupListParams } from "../query/itemInventory/useSetItemInventoryBelongingGroupMutation";

export const ItemInventoryApi = {
    getGroupList: async (
        params: GetGroupListParams,
    ): Promise<ItemInventoryGroupListResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/inventory/group/list", {
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    addGroup: async (params: AddGroupParams): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(URL_CONSTANTS.API_URL + "/item/inventory/group", params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    orderChangeGroup: async (
        params: OrderChangeItemInventoryGroupParams,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    URL_CONSTANTS.API_URL + "/item/inventory/group/order",
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteGroup: async (item_inventory_group_idx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    `${URL_CONSTANTS.API_URL}/item/inventory/group/${item_inventory_group_idx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    adjustGroupTitle: async (
        item_inventory_group_idx: string,
        params: AdjustTitleItemInventoryGroupParams,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${URL_CONSTANTS.API_URL}/item/inventory/group/${item_inventory_group_idx}`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getItemBelongingGroupList: async (
        item_idx: string,
    ): Promise<ItemInventoryBelongingGroupListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    URL_CONSTANTS.API_URL +
                        `/item/inventory/${item_idx}/group/list`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    setItemBelongingGroupList: async (
        item_idx: string,
        params: SetItemInventoryBelongingGroupListParams,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    URL_CONSTANTS.API_URL +
                        `/item/inventory/${item_idx}/group/sub/set`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
