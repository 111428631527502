import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import hasHeaderAtom from "../store/ui/hasHeaderAtom";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import tempAuthKeyAtom from "../store/auth/tempAuthKeyAtom";
import { setCookie } from "../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useTempIsLogin } from "../hooks/useTempIsLogin";

export const TempLoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    width: 100%;
    height: 100vh;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 40px);
        padding: 0 20px;
    }
`;

export const TempLoginContainer = styled.div`
    max-width: 440px;
    width: calc(100% - 80px);
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 40px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media ${(props) => props.theme.mobileL} {
        padding: 20px 20px;
        width: calc(100% - 40px);
    }
`;

export const TempLoginLogo = styled.img`
    margin-top: 80px;
`;

export const TempLoginTitle = styled.div`
    font-size: 30px;
    color: #333;
    font-weight: 800;
    line-height: 1;
`;

export const TempLoginDesc = styled.div`
    margin-top: 20px;
    font-size: 16px;
    color: #666;
    line-height: 1.5;
`;

export const TempLoginInput = styled.input`
    font-size: 15px;
    color: #555;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: 0 0;
    margin-top: 20px;
    outline: none;
    border: none;
    border-bottom: 2px solid #adadad;
`;

export const TempLoginButton = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px;
    gap: 10px;
    font-weight: 800;

    height: 43px;

    background: #f0f0f2;
    border-radius: 8px;

    transition: all 300ms ease;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #fa54fa;
            color: white;
        }
    }
`;
type Props = {};

const TempLogin = (props: Props): ReactElement | null => {
    const [hasHeader, setHasHeader] = useAtom(hasHeaderAtom);
    const [tempAuthKey, setTempAuthKey] = useAtom(tempAuthKeyAtom);
    const navigate = useNavigate();
    const isTempLogin = useTempIsLogin();

    useEffect(() => {
        setHasHeader(false);
        return () => {
            setHasHeader(true);
        };
    }, []);
    const [tempPassword, setTempPassword] = useState("");

    return (
        <TempLoginWrapper>
            <TempLoginContainer>
                <TempLoginTitle>Access Restricted Page</TempLoginTitle>
                <TempLoginDesc>Only authorized users can access</TempLoginDesc>
                <TempLoginInput
                    type={"password"}
                    placeholder={"password"}
                    value={tempPassword}
                    onChange={(e) => {
                        setTempPassword(e.target.value);
                    }}
                />
                <TempLoginButton
                    onClick={() => {
                        setTempAuthKey(tempPassword);
                        setCookie(
                            "tempAuthKey",
                            tempPassword,
                            3,
                            "/",
                            ".saybuzz.io",
                        );

                        if (isTempLogin) {
                            navigate("/");
                        }
                    }}
                >
                    ACCESS
                </TempLoginButton>
                <TempLoginLogo
                    src={URL_CONSTANTS.ASSET_URL + "/logo/logo-saybuzz.svg"}
                />
            </TempLoginContainer>
        </TempLoginWrapper>
    );
};

export default TempLogin;
