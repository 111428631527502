import { AuthApi } from "../../apis/AuthApi";
import { useResetAtom } from "jotai/utils";
import UserInfoAtom from "../../store/userInfo/userInfoAtom";
import { getAuthKey, resetAuthKey } from "../../store/auth/authKeyStore";
import { queryClient } from "../../App";
import { sendLog, setCookie } from "../../utils/Utils";
import { useLocalStorage } from "usehooks-ts";
import { useGetPushInfo } from "./useGetPushInfo";

export const useLogout = () => {
    const authKey = getAuthKey();
    const resetUserInfo = useResetAtom(UserInfoAtom);
    const [, setPathAfterLogin] = useLocalStorage("pathAfterLogin", "/");
    const { deactivateWebPushSubscription } = useGetPushInfo();
    return async () => {
        // 로그아웃 시 deactivate 시켜야하는데, AuthKey 가 필요해서 logout 전 호출
        await deactivateWebPushSubscription();

        return new Promise((resolve) => {
            setPathAfterLogin("/");
            AuthApi.logout(authKey).finally(async () => {
                // authKey 는 초기값을 쿠키값 기준으로 하기때문에 빈값을 명시한다. setCookie 와 비동기로 처리될 가능성 배제
                console.info("logout api done");
                sendLog({
                    event: "logout",
                });

                resetAuthKey();
                resetUserInfo();
                setCookie("authKey", "", -1, "/", ".saybuzz.io");
                setCookie("EID", "", -1, "/", ".saybuzz.io");
                await queryClient.resetQueries();
                await queryClient.refetchQueries();
                resolve(null);
            });
        });
    };
};
