import { ReactElement } from "react";
import styled from "styled-components";

type Props = {
    isShowPagination: boolean;
    renderPagination?: (value: any) => ReactElement;
    dataLength: number;
    currentIndex: number;
};

export const CarouselIndicatorContainer = styled.div``;

const CarouselPagination = (props: Props): ReactElement | null => {
    return (
        <CarouselIndicatorContainer>
            {props.isShowPagination &&
                typeof props.renderPagination !== "undefined" &&
                props.renderPagination({
                    length: props.dataLength,
                    currentIndex: props.currentIndex,
                })}
        </CarouselIndicatorContainer>
    );
};

export default CarouselPagination;
