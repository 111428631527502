import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { ItemDetailDataType } from "../query/item/useItemDataQuery";
import {
    ItemListApiParamsType,
    ItemListApiResultType,
} from "../query/item/useItemListQuery";
import {
    BestItemListApiParamsType,
    BestItemListApiResultType,
} from "../query/item/useBestItemListQuery";
import { getAuthKey } from "../store/auth/authKeyStore";
import { TransferItemParamType } from "../query/item/transfer/useTransferItemMutation";
import { ItemHideUnHideMutationParams } from "../query/item/hide/useItemHideUnHideMutation";
import { convertFormData } from "../utils/Utils";
import { AddItemParams } from "../query/item/useAddItemMutation";
import { AdjustItemParams } from "../query/item/useAdjustItemMutation";
import {
    UserFollowRankListApiParamsType,
    UserFollowRankListApiResultType,
} from "../query/user/useGetUserFollowRankQuery";
import { OwnItemCntType } from "../query/item/useGetOwnItemCnt";
import { UseItemParams } from "../query/item/use/useUseItemMutation";

export const ItemApi = {
    getList: async (
        params: ItemListApiParamsType,
    ): Promise<ItemListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getDetailData: async (itemIdx: string): Promise<ItemDetailDataType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/" + itemIdx, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getBestItemList: async (
        params: BestItemListApiParamsType, //page_no만 있으면 댐
    ): Promise<BestItemListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/best/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    transfer: async (
        itemIdx: string,
        params: TransferItemParamType,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/item/${itemIdx}/transfer`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    hideUnHide: async (
        itemIdx: string,
        params: ItemHideUnHideMutationParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${URL_CONSTANTS.API_URL}/item/${itemIdx}/private`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    checkDuplicateItemColumn: async (
        columnKey: string,
        value: string,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/item/${columnKey}/check`, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                    params: {
                        [columnKey]: value,
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    addItem: async (params: AddItemParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/item",
                    convertFormData(params),
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    adjustItem: async (
        itemIdx: string,
        params: AdjustItemParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    URL_CONSTANTS.API_URL + "/item/" + itemIdx,
                    convertFormData(params),
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    examineItem: async (itemIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/item/" + itemIdx + "/examine",
                    {},
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    cancelExamineItem: async (itemIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    URL_CONSTANTS.API_URL + "/item/" + itemIdx + "/examine",
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deleteItem: async (itemIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(URL_CONSTANTS.API_URL + "/item/" + itemIdx + "", {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    extractUrlMetaData: async (url: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/item/favicon/url`, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                    params: {
                        url: url,
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getItemCreatorList: async (
        params: UserFollowRankListApiParamsType,
    ): Promise<UserFollowRankListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/creator/list", {
                    params: params,
                    headers: { Authorization: "Bearer " + getAuthKey() },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOwnItemCnt: async (itemIdx: string): Promise<OwnItemCntType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    URL_CONSTANTS.API_URL +
                        "/item/" +
                        itemIdx +
                        "/own_item_cnt",
                    {
                        headers: { Authorization: "Bearer " + getAuthKey() },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    useItem: async (itemIdx: string, params: UseItemParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/item/" + itemIdx + "/use",
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
