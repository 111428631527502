import { ReactElement } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../../../../../common/commonStyle/CommonButtonStyle";
import AdSaleManageScheduleCalendarCellBodySetStatusBtn from "./components/AdSaleManageScheduleCalendarCellBodySetStatusBtn";
import AdSaleManageScheduleCalendarCellBodyAcceptOfferBtn from "./components/AdSaleManageScheduleCalendarCellBodyAcceptOfferBtn";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import {
    AD_BID_CLOSED,
    AD_IN_REVIEW,
    AD_ON_BID,
    AD_ON_SCHEDULE,
    AD_PREPARING,
} from "../../../../../../../../../../../types/ad/adDaily/AdDailyListItemType";
import { MyAdDailyListItemType } from "../../../../../../../../../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";

export const AdSaleManageScheduleCalendarCellBodyBtnsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 24px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        flex-direction: row;
        margin-top: 14px;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    font-size: 14px;
    padding: 10px 0;
    height: 34px;
`;

type Props = {
    adIdx: string;
    cellDate: Date;
    myAdDailyList: MyAdDailyListItemType[];
};

const AdSaleManageScheduleCalendarCellBodyBtns = (
    props: Props,
): ReactElement | null => {
    const {
        targetDateInfo: cellInfo,
        isTodaySale,
        hasOfferSuccess,
        hasHighestOffer,
    } = useGetAdMyDailyDateData({
        adIdx: props.adIdx,
        cellDate: props.cellDate,
        myAdDailyList: props.myAdDailyList,
    });

    const isShowAcceptBtn =
        (cellInfo.ad_daily_status_code === AD_ON_BID ||
            cellInfo.ad_daily_status_code === AD_IN_REVIEW ||
            cellInfo.ad_daily_status_code === AD_BID_CLOSED ||
            cellInfo.ad_daily_status_code === AD_PREPARING ||
            cellInfo.ad_daily_status_code === AD_ON_SCHEDULE) &&
        isTodaySale &&
        (hasOfferSuccess || hasHighestOffer);

    const isShowAdStopRestartBtn =
        (cellInfo.ad_daily_status_code === AD_ON_BID ||
            cellInfo.ad_daily_status_code === AD_IN_REVIEW ||
            cellInfo.ad_daily_status_code === AD_BID_CLOSED ||
            cellInfo.ad_daily_status_code === AD_PREPARING ||
            cellInfo.ad_daily_status_code === AD_ON_SCHEDULE) &&
        !hasOfferSuccess;

    return isShowAcceptBtn || isShowAdStopRestartBtn ? (
        <AdSaleManageScheduleCalendarCellBodyBtnsContainer>
            {/*낙찰하기 버튼 : 입찰 템플릿 보기 버튼 */}
            {isShowAcceptBtn && (
                <AdSaleManageScheduleCalendarCellBodyAcceptOfferBtn
                    adIdx={props.adIdx}
                    cellDate={props.cellDate}
                    hasOfferSuccess={hasOfferSuccess}
                    adDailyStatus={cellInfo.ad_daily_status_code}
                />
            )}

            {/*판매 중단 버튼 / 판매 중단 해제 버튼*/}
            {isShowAdStopRestartBtn && (
                <AdSaleManageScheduleCalendarCellBodySetStatusBtn
                    {...props}
                    isTodaySale={isTodaySale}
                />
            )}
        </AdSaleManageScheduleCalendarCellBodyBtnsContainer>
    ) : null;
};

export default AdSaleManageScheduleCalendarCellBodyBtns;
