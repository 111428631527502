import React, { ReactElement } from "react";
import styled from "styled-components";
import { ChipType } from "../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import Chips from "../../../../common/commonUnitComponent/chips/Chips";
import CommonDropdownBtn, {
    DropdownListItemType,
} from "../../../../common/commonUnitComponent/commonDropdownBtn/CommonDropdownBtn";

export const AdManageContentsHeaderContainer = styled.div`
    position: relative;
    overflow: visible;
    display: flex;
    align-items: center;
    margin-top: 24px;

    ::-webkit-scrollbar {
        display: none;
        width: 0; /* Remove scrollbar space */
        height: 0;
        background: transparent; /* Optional: just make scrollbar invisible */
        -webkit-appearance: none;
    }

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% + 16px);
    }
`;

export const AdManageContentsHeaderDropdownContainer = styled.div`
    width: 120px;
    flex-shrink: 0;
`;

export const AdManageContentsHeaderChipsWrapper = styled.div`
    position: relative;
    overflow-x: visible;
`;

export const AdManageContentsHeaderVerticalLine = styled.div`
    border-right: 1px solid #e5e5e5;
    margin-left: 16px;
    margin-right: 1px;
    height: 24px;
`;

type Props = {
    brandDropdownList: DropdownListItemType[];
    setBrandDropdownList: (value: DropdownListItemType[]) => void;
    templateChips: ChipType[];
    setTemplateChips: (value: ChipType[]) => void;
    isChipsAllShow: boolean;
};

const AdManageContentsHeader = (props: Props): ReactElement | null => {
    return (
        <AdManageContentsHeaderContainer>
            <AdManageContentsHeaderDropdownContainer>
                <CommonDropdownBtn
                    dropdownList={props.brandDropdownList}
                    setDropdownList={props.setBrandDropdownList}
                    isCircleType={true}
                />
            </AdManageContentsHeaderDropdownContainer>

            <AdManageContentsHeaderVerticalLine />
            <AdManageContentsHeaderChipsWrapper>
                <Chips
                    isAllShow={props.isChipsAllShow}
                    isOnlyOneSelected={true}
                    chips={props.templateChips}
                    setChips={props.setTemplateChips}
                />
            </AdManageContentsHeaderChipsWrapper>
        </AdManageContentsHeaderContainer>
    );
};

export default AdManageContentsHeader;
