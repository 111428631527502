import React, { ReactElement } from "react";
import styled from "styled-components";
import NotiListAllDeleteModal from "./components/NotiListAllDeleteModal";
import { useOpenModal } from "../../../../../../hooks/modal/useOpenModal";
import Checkbox from "../../../../commonUnitComponent/checkbox/Checkbox";
import { ChipType } from "../../../../commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { useDeleteAllNotificationMutation } from "../../../../../../query/notification/useDeleteAllNotificationMutation";
import { getSelectedChip } from "../../../../../../utils/Utils";
import { useReadAllNotificationMutation } from "../../../../../../query/notification/useReadAllNotificationMutation";

export const NotiListSubHeaderContainer = styled.div`
    padding: 0 16px;
    display: flex;
    height: 34px;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
`;

export const NotiListSubHeaderCheckboxContainer = styled.div``;

export const NotiListSubHeaderCheckboxLabel = styled.div`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    margin-left: 8px;
`;

export const NotiListSubHeaderButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const NotiListSubHeaderButton = styled.div`
    height: 34px;
    display: flex;
    align-items: center;
    color: #fa54fa;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    cursor: pointer;
`;

type Props = {
    notiKeywordChips: ChipType[];
    isOnlyNotRead: boolean;
    setIsOnlyNotRead: (value: boolean) => void;
};

const NotiListSubHeader = (props: Props): ReactElement | null => {
    const { openModal } = useOpenModal();
    const openNotiDeleteModal = async () => {
        return await openModal("noti-all-delete-modal", "modal", (resolve) => {
            return <NotiListAllDeleteModal resolve={resolve} />;
        });
    };

    const { mutateAsync: readAllNoti } = useReadAllNotificationMutation({
        notification_type: getSelectedChip(props.notiKeywordChips),
    });

    const { mutateAsync: deleteAllNoti } = useDeleteAllNotificationMutation({
        notification_type: getSelectedChip(props.notiKeywordChips),
    });

    return (
        <NotiListSubHeaderContainer>
            <NotiListSubHeaderCheckboxContainer>
                <Checkbox
                    id={"noti-only-nor-read-check-box"}
                    checked={props.isOnlyNotRead}
                    onChange={() => {
                        props.setIsOnlyNotRead(!props.isOnlyNotRead);
                    }}
                    labelComponent={
                        <NotiListSubHeaderCheckboxLabel>
                            안읽은 알림만 보기
                        </NotiListSubHeaderCheckboxLabel>
                    }
                />
            </NotiListSubHeaderCheckboxContainer>
            <NotiListSubHeaderButtonContainer>
                <NotiListSubHeaderButton
                    onClick={async () => {
                        await readAllNoti();
                    }}
                >
                    모두 읽음
                </NotiListSubHeaderButton>
                <NotiListSubHeaderButton
                    onClick={async () => {
                        if (await openNotiDeleteModal()) {
                            await deleteAllNoti();
                        }
                    }}
                >
                    전체 삭제
                </NotiListSubHeaderButton>
            </NotiListSubHeaderButtonContainer>
        </NotiListSubHeaderContainer>
    );
};

export default NotiListSubHeader;
