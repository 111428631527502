import React, { ReactElement, useRef } from "react";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import IncludeIconButton from "../../../commonUnitComponent/includeIconButton/IncludeIconButton";
import { numberToStringWithComma } from "../../../../../utils/Utils";
import { UserProfileType } from "../../../../../query/user/useGetUserProfileQuery";
import styled from "styled-components";
import { useFollowUnFollow } from "../../../../../hooks/common/useFollowUnFollow";
import { useIsMe } from "../../../../../hooks/common/useIsMe";
import Tooltip from "../../../commonUnitComponent/tooltip/Tooltip";
import { useHover } from "usehooks-ts";

export const MiniProfileModalUserContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const MiniProfileModalUserInfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const MiniProfileModalUserInfoThumbnail = styled.img`
    width: 60px;
    height: 60px;
    margin-right: 12px;
    border-radius: 50%;
`;
export const MiniProfileModalUserInfoTitleContainer = styled.div``;
export const MiniProfileModalUserInfoTitleNickname = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
    display: flex;
    align-items: center;
`;

export const MiniProfileModalUserInfoTitleNicknameVerifiedIconContainer = styled.div`
    margin-left: 4px;
    position: relative;
`;

export const MiniProfileModalUserInfoTitleNicknameVerifiedIcon = styled.img`
    width: 16px;
    height: 16px;
`;

export const MiniProfileModalUserInfoTitleUserId = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #888888;
    margin-top: 8px;
`;

export const MiniProfileModalFollowIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const MiniProfileModalCountContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;
`;

export const MiniProfileModalCountBox = styled.div`
    width: 25%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const MiniProfileModalCountBoxTitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #888888;
`;

export const MiniProfileModalCountBoxCount = styled.div`
    font-weight: 700;
    font-size: 15px;
    line-height: 100%;
    color: #222222;
    margin-top: 5px;
`;

type Props = {
    data: UserProfileType;
    refetchUserProfileData: () => any;
};

const MiniProfileModalUserInfo = (props: Props): ReactElement | null => {
    const isMe = useIsMe();
    const { followUnFollow } = useFollowUnFollow(
        props.data.userInfo.user_idx,
        props.data.userInfo.follow_bool,
        props.refetchUserProfileData,
    );
    const verifiedIconRef = useRef<HTMLImageElement>(null);
    const isHover = useHover(verifiedIconRef);

    return (
        <>
            <MiniProfileModalUserContainer>
                <MiniProfileModalUserInfoContainer>
                    <MiniProfileModalUserInfoThumbnail
                        src={props.data.userInfo.user_thumbnail_path}
                    />
                    <MiniProfileModalUserInfoTitleContainer>
                        <MiniProfileModalUserInfoTitleNickname>
                            {props.data.userInfo.user_nick}
                            {props.data.userInfo.user_influencer_bool && (
                                <MiniProfileModalUserInfoTitleNicknameVerifiedIconContainer>
                                    <MiniProfileModalUserInfoTitleNicknameVerifiedIcon
                                        ref={verifiedIconRef}
                                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-verified.svg`}
                                    />
                                    <Tooltip
                                        isHover={isHover}
                                        position={"bottom"}
                                    >
                                        인증됨
                                    </Tooltip>
                                </MiniProfileModalUserInfoTitleNicknameVerifiedIconContainer>
                            )}
                        </MiniProfileModalUserInfoTitleNickname>
                        <MiniProfileModalUserInfoTitleUserId>
                            {props.data.userInfo.user_id}
                        </MiniProfileModalUserInfoTitleUserId>
                    </MiniProfileModalUserInfoTitleContainer>
                </MiniProfileModalUserInfoContainer>
                <MiniProfileModalFollowIconContainer>
                    {!isMe(props.data.userInfo.user_idx) && (
                        <IncludeIconButton
                            isActive={props.data.userInfo.follow_bool}
                            clickEventHandler={async (e) => {
                                await followUnFollow();
                            }}
                            activeIconColor={"#FA54FA"}
                            icon={
                                URL_CONSTANTS.ASSET_URL +
                                "/icon/icon-follow.svg"
                            }
                            title={
                                props.data.userInfo.follow_bool
                                    ? "팔로잉"
                                    : "팔로우"
                            }
                        />
                    )}
                </MiniProfileModalFollowIconContainer>
            </MiniProfileModalUserContainer>

            <MiniProfileModalCountContainer>
                <MiniProfileModalCountBox>
                    <MiniProfileModalCountBoxTitle>
                        제작 아이템
                    </MiniProfileModalCountBoxTitle>
                    <MiniProfileModalCountBoxCount>
                        {numberToStringWithComma(
                            props.data.userInfo.created_item_cnt,
                        )}
                    </MiniProfileModalCountBoxCount>
                </MiniProfileModalCountBox>
                <MiniProfileModalCountBox>
                    <MiniProfileModalCountBoxTitle>
                        보유 아이템
                    </MiniProfileModalCountBoxTitle>
                    <MiniProfileModalCountBoxCount>
                        {numberToStringWithComma(
                            props.data.userInfo.own_item_cnt,
                        )}
                    </MiniProfileModalCountBoxCount>
                </MiniProfileModalCountBox>
                <MiniProfileModalCountBox>
                    <MiniProfileModalCountBoxTitle>
                        팔로워
                    </MiniProfileModalCountBoxTitle>
                    <MiniProfileModalCountBoxCount>
                        {numberToStringWithComma(
                            props.data.userInfo.follow_cnt,
                        )}
                    </MiniProfileModalCountBoxCount>
                </MiniProfileModalCountBox>
                <MiniProfileModalCountBox>
                    <MiniProfileModalCountBoxTitle>
                        팔로우
                    </MiniProfileModalCountBoxTitle>
                    <MiniProfileModalCountBoxCount>
                        {numberToStringWithComma(
                            props.data.userInfo.following_cnt,
                        )}
                    </MiniProfileModalCountBoxCount>
                </MiniProfileModalCountBox>
            </MiniProfileModalCountContainer>
        </>
    );
};

export default MiniProfileModalUserInfo;
