import { ReactElement } from "react";
import styled from "styled-components";

export const SwitchTabContainer = styled.div`
    padding: 4px;
    background: rgba(13, 13, 26, 0.06);
    border-radius: 8px;
    width: inherit;
    box-sizing: border-box;
    display: flex;
`;

export const SwitchTabBox = styled.div<{
    isSelected: boolean;
    hasIcon: boolean;
}>`
    padding: ${({ hasIcon }) => (hasIcon ? "0" : "9px 0")};
    width: 50%;
    display: flex;
    justify-content: center;

    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    background: ${({ isSelected }) =>
        isSelected
            ? "linear-gradient(0deg, #FFF 0%, #FFF 100%), rgba(13, 13, 26, 0.06)"
            : "transparent"};
    border-radius: 6px;
    color: ${({ isSelected }) => (isSelected ? "#000000" : "#555555")};

    ${({ isSelected }) => {
        if (isSelected) {
            return "box-shadow:0px 2px 4px 0px rgba(0, 0, 0, 0.10)";
        }
    }};

    transition: background 300ms ease;
    cursor: pointer;
`;

export const SwitchTabBoxIcon = styled.img`
    width: 32px;
    height: 32px;
`;

export type SwitchTabType = {
    key: string;
    title: string;
    iconPath?: string;
    isSelected: boolean;
    additionalClickHandler?: (value?: any) => void;
};

type Props = {
    switchTabList: SwitchTabType[];
    setSwitchTabList: (value: SwitchTabType[]) => void;
};

const SwitchTab = (props: Props): ReactElement | null => {
    return (
        <SwitchTabContainer>
            {props.switchTabList.map((tab, switchTabIdx) => {
                return (
                    <SwitchTabBox
                        key={switchTabIdx}
                        hasIcon={tab.title === ""}
                        onClick={() => {
                            props.setSwitchTabList(
                                props.switchTabList.map(
                                    (innerTab, innerSwitchTabIdx) => {
                                        if (
                                            innerSwitchTabIdx === switchTabIdx
                                        ) {
                                            return {
                                                ...innerTab,
                                                isSelected: true,
                                            };
                                        } else {
                                            return {
                                                ...innerTab,
                                                isSelected: false,
                                            };
                                        }
                                    },
                                ),
                            );

                            if (tab.additionalClickHandler) {
                                tab.additionalClickHandler();
                            }
                        }}
                        isSelected={tab.isSelected}
                    >
                        {tab.title !== "" ? (
                            tab.title
                        ) : (
                            <SwitchTabBoxIcon src={tab.iconPath} />
                        )}
                    </SwitchTabBox>
                );
            })}
        </SwitchTabContainer>
    );
};

export default SwitchTab;

SwitchTab.defaultProps = {};
