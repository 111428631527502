import { useInfiniteQuery } from "react-query";
import { ItemInventoryApi } from "../../apis/ItemInventoryApi";
import { ItemInventoryGroupListItemType } from "../../types/itemInventory/ItemInventoryGroupListItemType";

export type ItemInventoryGroupListResultType = {
    groupList: ItemInventoryGroupListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type GetGroupListParams = {
    limit: number;
    page_no: number;
    user_idx: string;
};

export const useGetItemInventoryGroupList = (params: GetGroupListParams) => {
    const queryResult = useInfiniteQuery<
        ItemInventoryGroupListResultType,
        Error
    >({
        queryKey: [
            "item-inventory-group-list-" + JSON.stringify(params),
            "item-inventory-group-list",
        ],
        queryFn: ({ pageParam = 1 }) =>
            ItemInventoryApi.getGroupList({
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: ItemInventoryGroupListResultType[] = [
        {
            groupList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadItemInventoryGroupListPages(
    pages: ItemInventoryGroupListResultType[],
) {
    return pages
        .map((result) => result.groupList)
        .reduce((prev, next) => prev.concat(next));
}
