import { ReactElement, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { RenderCalendarCellParamType } from "../../../../../../../common/commonUiComponent/calendar/components/CalendarDateBody";
import CalendarCell from "../../../../../../../common/commonUiComponent/calendar/components/CalendarCell";
import Calendar from "../../../../../../../common/commonUiComponent/calendar/Calendar";
import CalendarFoldBtn from "../../../../../../../common/commonUiComponent/calendar/calendarFoldBtn/CalendarFoldBtn";
import AdBidCalendarSelectedMobileCell from "./components/adBidCalendarSelectedMobileCell/AdBidCalendarSelectedMobileCell";
import {
    convertAdDateToString,
    convertAdStringToDate,
    getMonthStartEnd,
} from "../../../../../../../../utils/Utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AdBidCalendarCellBody from "./components/adBidCalendarCellBody/AdBidCalendarCellBody";
import { useGetAdDailyListQuery } from "../../../../../../../../query/ad/adDaily/useGetAdDailyListQuery";
import { format } from "date-fns";

export const AdBidCalendarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
`;

export const AdCalendarContainerStyle = css`
    border-radius: 16px;
    border: 1px solid #ebebeb;

    width: 100%;
`;

export const AdCalendarHeaderContainerStyle = css`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    border-bottom: 1px solid #ebebeb;

    &:last-child {
        border-right: none;
    }

    @media ${(props) => props.theme.mobileL} {
        border-right: none;
        border-bottom: none;
        height: 36px;
        color: #888;
    }
`;

export const AdBidCalendarBodyContainerStyle = css``;

type Props = {};

const AdBidCalendar = (props: Props): ReactElement | null => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const adIdx = params.ad_id ? params.ad_id : "0";
    // 여기서 날짜 포맷이 안맞는 경우 오늘 날짜로 포맷 변경 시킴
    const selectedDateString = params.selected_date
        ? params.selected_date
        : convertAdDateToString(new Date());
    const selectedDateInParams = convertAdStringToDate(selectedDateString);

    const [displayingDate, setDisplayingDate] = useState(selectedDateInParams);
    const [isShowOnlyWeek, setIsShowOnlyWeek] = useState(true);
    const [selectedDate, setSelectedDate] = useState(selectedDateInParams);

    useEffect(() => {
        setSelectedDate(selectedDateInParams);
    }, [location]);

    useEffect(() => {
        const splitPathname = location.pathname.split("/");
        if (
            splitPathname.length > 4 &&
            splitPathname[4] !== convertAdDateToString(selectedDate)
        ) {
            navigate(
                `/ad/${adIdx}/date/${convertAdDateToString(selectedDate)}`,
            );
        }
    }, [selectedDate]);

    const monthStartEnd = getMonthStartEnd(displayingDate);

    const {
        data: rawAdDailyList,
        refetch: refetchAdDailyList,
        error: errorAdDailyList,
        isLoading: isAdDailyListLoading,
    } = useGetAdDailyListQuery(adIdx, true, {
        ad_sales_date_from: format(monthStartEnd.startDate, "yyyy-MM-dd"),
        ad_sales_date_to: format(monthStartEnd.endDate, "yyyy-MM-dd"),
    });

    return (
        <AdBidCalendarWrapper>
            <Calendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                displayingDate={displayingDate}
                setDisplayingDate={setDisplayingDate}
                initDate={selectedDateInParams}
                calendarContainerStyle={AdCalendarContainerStyle}
                headerContainerStyle={AdCalendarHeaderContainerStyle}
                bodyContainerStyle={AdBidCalendarBodyContainerStyle}
                isShowOnlyWeek={isShowOnlyWeek}
                renderCellComponent={(params: RenderCalendarCellParamType) => (
                    <CalendarCell
                        key={params.cellDate.getTime()}
                        {...params}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        displayingDate={displayingDate}
                    >
                        <AdBidCalendarCellBody
                            adDailyList={rawAdDailyList.advertisement_daily}
                            cellDate={params.cellDate}
                            displayingDate={displayingDate}
                            isShowOnlyWeek={isShowOnlyWeek}
                        />
                    </CalendarCell>
                )}
                renderMobileSelectedCellComponent={() => (
                    <AdBidCalendarSelectedMobileCell
                        key={"today-info-container"}
                        selectedDate={selectedDate}
                        adDailyList={rawAdDailyList.advertisement_daily}
                        displayingDate={displayingDate}
                    />
                )}
            />
            <CalendarFoldBtn
                isShowOnlyWeek={isShowOnlyWeek}
                setIsShowOnlyWeek={setIsShowOnlyWeek}
            />
        </AdBidCalendarWrapper>
    );
};

export default AdBidCalendar;
