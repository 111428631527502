import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";

export const DefaultNoDataComponentContainer = styled.div<{
    height?: string;
    minHeight?: string;
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: ${({ height }) => (height ? height : "inherit")};
    min-height: ${({ minHeight }) => (minHeight ? minHeight : "inherit")};
    flex-direction: column;
    padding: 160px 0;
`;

export const DefaultNoDataComponentImg = styled.img``;

export const DefaultNoDataComponentMsg = styled.div`
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-top: 24px;
`;
type Props = {
    msg: string;
    height?: string;
    minHeight?: string;
};

const DefaultNoDataComponent = (props: Props): ReactElement | null => {
    return (
        <DefaultNoDataComponentContainer
            minHeight={props.minHeight}
            height={props.height}
        >
            <DefaultNoDataComponentImg
                src={URL_CONSTANTS.ASSET_URL + "/icon/icon-default-empty.svg"}
            />
            <DefaultNoDataComponentMsg>{props.msg}</DefaultNoDataComponentMsg>
        </DefaultNoDataComponentContainer>
    );
};

export default DefaultNoDataComponent;
