import React, { ReactElement, useRef } from "react";
import styled, { FlattenInterpolation, ThemeProps } from "styled-components";
import TableHeader from "./components/TableHeader/TableHeader";
import TableBody, {
    TableBodyLoadingContainer,
} from "./components/TableBody/TableBody";
import { CommonNoDataContainer } from "./components/CommonTableStyle";
import Spinner from "../spinner/Spinner";
import ApiResBoundary from "../../apiResBoundary/ApiResBoundary";
import { infiniteScrollCallbackType } from "../../commonUiComponent/scrollbar/components/customScrollbar/CustomScrollbar";

export const CommonTableContainer = styled.div`
    position: relative;
`;

export type TableHeaderDataType = {
    key: string;
    component: ReactElement | string;
    isHide?: boolean;
    questionMarkHandler?: () => Promise<void>;
};

type Props = {
    id: string;
    tableHeaderData: TableHeaderDataType[];
    tableBodyData: any[];
    // boxBodyHeight - headerHeight + boxPaddingBottom // 모바일은 padding 8px 차이나므로 -8 추가
    tableBodyHeight: string;
    tableBodyRowPadding: string;
    tableHeaderMarginBottom: string;
    hasScrollX: boolean;
    hasScrollY?: boolean;
    noDataMsg: string;
    noDataContainerStyle: FlattenInterpolation<ThemeProps<any>>;
    noDataContainerHeight: string;
    infiniteScrollCallback?: infiniteScrollCallbackType;
    hasParentPaddingBottom: boolean;
    rowHoverColor?: string;
    onClickHandler?: (idx: number) => void;
    error?: any;
    isLoading?: boolean;
    headerStyle?: FlattenInterpolation<any | ThemeProps<any>>;
};

const CommonTable = (props: Props): ReactElement | null => {
    const tableHeaderRef = useRef<HTMLDivElement>(null);
    return (
        <CommonTableContainer>
            <ApiResBoundary
                error={props.error || null}
                isNoData={false}
                isLoading={props.isLoading || false}
                isAbsoluteLoading={true}
                loadingFallback={
                    <TableBodyLoadingContainer
                        bodyHeight={
                            props.noDataContainerHeight || props.tableBodyHeight
                        }
                    >
                        <Spinner />
                    </TableBodyLoadingContainer>
                }
            >
                {!props.isLoading && props.tableBodyData.length === 0 ? (
                    <CommonNoDataContainer
                        noDataContainerHeight={props.noDataContainerHeight}
                        noDataContainerStyle={props.noDataContainerStyle}
                    >
                        {props.noDataMsg}
                    </CommonNoDataContainer>
                ) : (
                    <>
                        <TableHeader
                            tableHeaderRef={tableHeaderRef}
                            tableHeaderData={props.tableHeaderData}
                            tableHeaderMarginBottom={
                                props.tableHeaderMarginBottom
                            }
                            hasScrollX={props.hasScrollX}
                            headerStyle={props.headerStyle}
                        />
                        <TableBody {...props} tableHeaderRef={tableHeaderRef} />
                    </>
                )}
            </ApiResBoundary>
        </CommonTableContainer>
    );
};

export default CommonTable;

CommonTable.defaultProps = {
    tableBodyHeight: "100%",
    tableHeaderMarginBottom: "6px",
    hasScrollX: false,
    tableBodyRowPadding: "0",
    noDataContainerStyle: {},
    noDataMsg: "데이터 없음",
    hasParentPaddingBottom: true,
    noDataContainerHeight: "80px",
};
