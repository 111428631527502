import {useLocalStorage} from "usehooks-ts";

export const useGlobalSearchRecentStore = () => {
    const DEFAULT_GET_KEYWORDS = 3;
    const MAX_STORED_KEYWORDS = 15;
    const [recentSearch, setRecentSearch] = useLocalStorage(
        "recentSearches",
        "",
    );
    const get = (count: number = DEFAULT_GET_KEYWORDS): string[] => {
        try {
            const converted = JSON.parse(recentSearch || "");
            if (!Array.isArray(converted)) {
                setRecentSearch("");
                return [];
            }
            return converted.slice(0, count);
        } catch (e) {
            setRecentSearch("");
            return [];
        }
    };
    const set = (keyword: string) => {
        const recentSearches = get(MAX_STORED_KEYWORDS).filter(
                  (x: string) => x !== keyword,
              );
        recentSearches.unshift(keyword);
        const trimmedSearches = recentSearches.slice(0, MAX_STORED_KEYWORDS);
        setRecentSearch(JSON.stringify(trimmedSearches));
    };
    /**
     * remove specified keyword from list and return refreshed list
     * @param {string} keyword 
     * @returns removed list
     */
    const remove = (keyword: string) => {
        const recentSearches = get(MAX_STORED_KEYWORDS);
        setRecentSearch(
            JSON.stringify(
                recentSearches.filter((str: string) => str !== keyword),
            ),
        );
        return recentSearches.filter((str: string) => str !== keyword).slice(0, DEFAULT_GET_KEYWORDS);
    };
    return { get, set, remove };
};
