import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { useLogout } from "../../../../../hooks/common/useLogout";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";
import { useTranslation } from "react-i18next";
import UserProfileModalUserInfo from "./components/userProfileModalUserInfo/UserProfileModalUserInfo";
import UserProfileModalAppInstallBtn from "./components/UserProfileModalAppInstallBtn";
import UserProfileModalMyMenus from "./components/userProfileModalMyMenus/UserProfileModalMyMenus";
import UserProfileModalPageMenus from "./components/userProfileModalPageMenus/UserProfileModalPageMenus";
import viewHeightAtom from "../../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import UserProfileModalLoginBtn from "./components/UserProfileModalLoginBtn";
import Scrollbar from "../../../commonUiComponent/scrollbar/Scrollbar";
import UserProfileModalChatBtn from "./components/UserProfileModalChatBtn";
import isShowMobileBottomButtonAtom from "../../../../../store/ui/isShowMobileBottomButtonAtom";
import ListItemTypeSelfAd from "../../../../selfAd/listItemTypeSelfAd/ListItemTypeSelfAd";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../commonUiComponent/scrollbar/ScrollbarType";
import { PathAfterResolveType } from "../../../../../hooks/modal/useOpenModal";

export const UserProfileModalContentsWrapper = styled.div<{
    vh: number;
    isShowMobileBottomButton: boolean;
}>`
    position: relative;

    @media ${(props) => props.theme.tabletL} {
        height: ${({ vh, isShowMobileBottomButton }) =>
            `${100 * vh - (isShowMobileBottomButton ? 110 : 60)}px`};
    }
`;

export const UserProfileModalContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    gap: 10px;

    @media ${(props) => props.theme.tabletL} {
        background: #f5f5f5;
    }
`;

export const UserProfileModalContentsUserInfoMyMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    gap: 10px;

    @media ${(props) => props.theme.tabletL} {
        background: #f5f5f5;
        gap: 0;
    }
`;

export const UserProfileModalMenuUnderLineContainer = styled.div`
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${(props) => props.theme.tabletL} {
        display: none;
    }
`;

export const UserProfileModalMenuUnderLine = styled.div`
    background: white;
    width: calc(100% - 40px);
    border-bottom: #ebebeb 1px solid;
    box-sizing: border-box;
`;

export const UserProfileListTypeSelfAdContainer = styled.div`
    margin: 0 10px;

    @media ${(props) => props.theme.tabletL} {
        display: none;
    }
`;

export const UserProfileModalLogoutButton = styled.div<{ vh: number }>`
    border: none;
    cursor: pointer;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #222222;
    height: 48px;
    margin: 0 10px 10px 10px;
    width: calc(100% - 20px);

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: background ease 300ms;
    border-radius: 6px;

    &:hover {
        background: rgba(13, 19, 26, 0.05);
    }

    @media ${(props) => props.theme.tabletL} {
        margin: -10px 0 0;
        width: 100%;
        background: transparent;
        height: ${({ vh }) => `${100 * vh - 1015}px`};
        min-height: 92px;
    }
`;

export const UserProfileModalLogoutIconImg = styled.img`
    width: 24px;
    height: 24px;

    margin-right: 6px;
`;

type Props = {
    resolve: (value: boolean | number | PathAfterResolveType) => void;
};

const UserProfileModalContents = (props: Props): ReactElement | null => {
    const { t } = useTranslation();
    const [vh] = useAtom(viewHeightAtom);
    const logout = useLogout();
    const isLogin = getIsLogin();
    const [isShowMobileBottomButton] = useAtom(isShowMobileBottomButtonAtom);
    // PWA 설치 관련

    return (
        <UserProfileModalContentsWrapper
            vh={vh}
            isShowMobileBottomButton={isShowMobileBottomButton}
        >
            <Scrollbar
                type={SCROLL_BAR_NORMAL_TYPE}
                isHide={true}
                id={"user-profile-modal-contents"}
            >
                <UserProfileModalContentsContainer>
                    {isLogin ? (
                        <UserProfileModalContentsUserInfoMyMenuContainer>
                            {/*유저 프로필*/}
                            <UserProfileModalUserInfo resolve={props.resolve} />

                            {/*내 메뉴*/}
                            <UserProfileModalMyMenus resolve={props.resolve} />
                        </UserProfileModalContentsUserInfoMyMenuContainer>
                    ) : (
                        <UserProfileModalLoginBtn />
                    )}

                    <UserProfileModalMenuUnderLineContainer>
                        <UserProfileModalMenuUnderLine />
                    </UserProfileModalMenuUnderLineContainer>

                    {/*페이지 메뉴*/}
                    <UserProfileModalPageMenus resolve={props.resolve} />

                    <UserProfileModalChatBtn />

                    {/*앱 다운로드 */}
                    <UserProfileModalAppInstallBtn resolve={props.resolve} />

                    <UserProfileListTypeSelfAdContainer>
                        <ListItemTypeSelfAd
                            spaceId={"saybuzz_my_list"}
                            isEnabled={true}
                        />
                    </UserProfileListTypeSelfAdContainer>

                    {/*로그아웃*/}
                    {isLogin && (
                        <UserProfileModalLogoutButton
                            vh={vh}
                            onClick={async () => {
                                props.resolve(-1);
                                await logout();
                            }}
                        >
                            <UserProfileModalLogoutIconImg
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-logout.png`}
                            />
                            {t("로그아웃")}
                        </UserProfileModalLogoutButton>
                    )}
                </UserProfileModalContentsContainer>
            </Scrollbar>
        </UserProfileModalContentsWrapper>
    );
};

export default UserProfileModalContents;
