import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import { useWindowSize } from "usehooks-ts";
import { useLocation } from "react-router-dom";
import {
    getFullPagePositionTop,
    infiniteScrollCallbackType,
} from "../customScrollbar/CustomScrollbar";
import hasHeaderAtom from "../../../../../../store/ui/hasHeaderAtom";
import isScrollLockAtom from "../../../../../../store/scroll/isScrollLockAtom";
import {
    SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS,
    SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_LEFT_SIDE_BAR,
    SCROLL_BAR_ROOT_TYPE,
} from "../../ScrollbarType";
import { useSetNoCustomScrollEvent } from "./useSetNoCustomScrollEvent";
import { useSetNoCustomScrollbarLocationChangeHook } from "./useSetNoCustomScrollbarLocationChangeHook";

export const NoCustomScrollbarContainer = styled.div<{
    isScrollLock: boolean;
    isHide: boolean;
    isFull: boolean;
    fullPagePositionTop: number;
}>`
    overflow-y: scroll;
    position: static;
    height: 100%;
    width: 100%;

    margin-top: ${({ isFull, fullPagePositionTop }) =>
        isFull ? fullPagePositionTop + "px" : "0"};

    transition: margin-top 300ms ease;
    ${({ isHide }) => {
        if (isHide) {
            return `&::-webkit-scrollbar {
                         display: none;
                    } 
                    scrollbar-width: none;
                    -ms-overflow-style: none;`;
        }
    }}
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
`;

type Props = {
    id: string;
    children: ReactElement;
    type: string;
    infiniteScrollCallback?: infiniteScrollCallbackType;
    isHide: boolean;
    isSaveScrollTop?: boolean;
};

const NoCustomScrollbar = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();
    const location = useLocation();

    const [isScrollLock] = useAtom(isScrollLockAtom);
    const [hasHeader] = useAtom(hasHeaderAtom);
    const noCustomScrollContainerRef = useRef<HTMLDivElement>(null);

    const [scrollTop, _setScrollTop] = useState(0);
    const scrollTopRef = useRef(0);
    const setScrollTop = (data: number) => {
        scrollTopRef.current = data;
        _setScrollTop(data);
    };
    const isRoot = props.type === SCROLL_BAR_ROOT_TYPE;
    const isDoubleSideBarLayoutLeftSideBarType =
        props.type === SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_LEFT_SIDE_BAR;
    const isDoubleSideBarLayoutContentsType =
        props.type === SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS;

    const fullPagePositionTop = getFullPagePositionTop(
        width,
        isRoot,
        hasHeader,
        location.pathname,
    );

    const init = () => {
        window.scrollTo(0, 0);
        document.documentElement.scrollTop = 0;
        setScrollTop(0);

        // isScrollLock 이 true 인 경우
        const body = document.querySelector("body");
        if (body && isScrollLock) {
            body.style.top = `0px`;
            body.setAttribute("scrollY", "0");
        }
    };

    const { isHideByLocationPathname } =
        useSetNoCustomScrollbarLocationChangeHook(
            isRoot,
            isDoubleSideBarLayoutLeftSideBarType,
            isDoubleSideBarLayoutContentsType,
            props.isHide,
            props.isSaveScrollTop || false,
            props.id,
            init,
        );

    useSetNoCustomScrollEvent(
        props.id,
        isRoot,
        scrollTopRef,
        setScrollTop,
        init,
    );

    useEffect(() => {
        // 모바일 스크롤락은 여기서 통제한다.
        // PC 스크롤락과 다르다.
        const body = document.querySelector("body");
        if (body !== null && isRoot) {
            if (isScrollLock) {
                const pageY = window.pageYOffset;
                body.setAttribute("scrollY", pageY.toString());
                body.style.overflow = "hidden";
                body.style.position = "fixed";
                body.style.top = `-${pageY + 1}px`;
                body.style.left = "0px";
                body.style.right = "0px";
                body.style.bottom = "0px";
            } else {
                body.style.removeProperty("overflow");
                body.style.removeProperty("position");
                body.style.removeProperty("top");
                body.style.removeProperty("left");
                body.style.removeProperty("right");
                body.style.removeProperty("bottom");
                window.scrollTo(0, Number(body.getAttribute("scrollY")));
                body.removeAttribute("scrollY");
            }
        }
    }, [isScrollLock]);

    return (
        <NoCustomScrollbarContainer
            isScrollLock={isScrollLock}
            isHide={isHideByLocationPathname}
            isFull={isRoot}
            fullPagePositionTop={fullPagePositionTop}
            ref={noCustomScrollContainerRef}
            id={`${props.id}__no-custom-scrollbar-container`}
        >
            {props.children}
        </NoCustomScrollbarContainer>
    );
};

export default NoCustomScrollbar;
