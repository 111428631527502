import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useLocalStorage } from "usehooks-ts";
import { useGetVersionQuery } from "../../../../query/common/useGetVersionQuery";

export const VersionCheckerContainer = styled.div``;

type Props = {};

const VersionChecker = (props: Props): ReactElement | null => {
    const location = useLocation();
    const {
        data: rawVersionData,
        refetch: refetchVersionData,
        isSuccess: isVersionDataSuccess,
    } = useGetVersionQuery();

    const [version, setVersion] = useLocalStorage("VERSION", "");

    useEffect(() => {
        refetchVersionData();
    }, [location]);

    useEffect(() => {
        if (isVersionDataSuccess) {
            if (version === "") {
                setVersion(rawVersionData.version);
            }
            //
            else {
                if (version !== rawVersionData.version) {
                    setVersion(rawVersionData.version);
                    window.location.reload();
                }
            }
        }
    }, [isVersionDataSuccess, rawVersionData]);

    return null;
};

export default VersionChecker;
