import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";

export const WebPushApi = {
    reInitWebPushSubscription: async (body: PushSubscription): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/web-push/reinit-subscription`,
                    body,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    checkWebPushSubscription: async (body: PushSubscription): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/web-push/check-subscription`,
                    body,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    registerWebPushSubscription: async (
        body: PushSubscription,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/web-push/register-subscription`,
                    body,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    unRegisterWebPushSubscription: async (
        body: PushSubscription,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/web-push/unregister-subscription`,
                    body,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deactivateWebPushSubscription: async (
        body: PushSubscription,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/web-push/deactivate-subscription`,
                    body,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
