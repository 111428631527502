import { useAtom } from "jotai";

import { useMutation } from "react-query";

import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { queryClient } from "../../../App";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { AdOfferApi } from "../../../apis/adApi/AdOfferApi";

export const useAdCancelOfferMutation = (adIdx: string, adOfferIdx: string) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => AdOfferApi.cancelOffer(adIdx, adOfferIdx),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "입찰이 정상적으로 취소되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });

                await queryClient.refetchQueries({
                    predicate: (query) =>
                        query.queryKey.includes("all-ad-my-buy-offer-list") ||
                        query.queryKey.includes("ad-daily-list-" + adIdx) ||
                        query.queryKey.includes(`ad-bid-refresh-target`),
                });
            },
        },
    );
};
