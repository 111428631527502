import { useQuery } from "react-query";
import { AdApi } from "../../../apis/adApi/AdApi";
import { format } from "date-fns";
import { AdDailyListItemType } from "../../../types/ad/adDaily/AdDailyListItemType";

export interface AdDailyListApiResultType {
    advertisement_daily: AdDailyListItemType[];
}

export type AdDailyListDateRangeType = {
    ad_sales_date_from: string;
    ad_sales_date_to: string;
};

export const useGetAdDailyListQuery = (
    ad_idx: string,
    isEnabled = true,
    dateRange: AdDailyListDateRangeType = {
        ad_sales_date_from: format(new Date(), "yyyy-MM-dd"),
        ad_sales_date_to: format(new Date(), "yyyy-MM-dd"),
    },
) => {
    const queryResult = useQuery<AdDailyListApiResultType, Error>({
        queryKey: [
            `ad-daily-list-${ad_idx}-${dateRange.ad_sales_date_from}-${dateRange.ad_sales_date_to}`,
            "ad-bid-refresh-target",
        ],
        queryFn: () => AdApi.getAdDailyList(ad_idx, dateRange),
        suspense: false,
        onError: () => {},
        enabled: isEnabled,
        cacheTime: 0, // calendar cell 에서 렌더링 되는 시간때문에
        staleTime: 0,
    });

    return {
        ...queryResult,
        data: queryResult.data || {
            advertisement_daily: [],
        },
    };
};

export const spreadAdDailyList = function (pages: AdDailyListApiResultType[]) {
    return pages
        .map((result) => result.advertisement_daily)
        .reduce((prev, next) => prev.concat(next));
};
