import React, { ReactElement } from "react";
import CustomScrollbar, {
    infiniteScrollCallbackType,
} from "./components/customScrollbar/CustomScrollbar";
import { checkUserAgent } from "../../../../utils/Utils";
import NoCustomScrollbar from "./components/noCustomScrollbar/NoCustomScrollbar";
import {
    SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS,
    SCROLL_BAR_ROOT_TYPE,
    ScrollbarType,
} from "./ScrollbarType";

type Props = {
    children: ReactElement;
    type: ScrollbarType;
    infiniteScrollCallback?: infiniteScrollCallbackType;
    isHide?: boolean;
    isThin?: boolean;
    isSaveScrollTop?: boolean;
    id: string;
};

const Scrollbar = (props: Props): ReactElement | null => {
    // 특정 window 버전에서 터치가 가능한걸로 인식하는 케이스가 있음.
    // 데스크탑 환경 && 사파리면서 && 터치 디바이스인 경우 === ipad 데스크탑모드 로 인식
    const isMobileAndTouchDevice =
        checkUserAgent.isMobileDevice() ||
        (!checkUserAgent.isMobileDevice() &&
            checkUserAgent.isSafari() &&
            checkUserAgent.isTouchDevice());

    // 모바일일 경우 커스텀 스크롤 삭제
    // 사이드바 있는 구조는 메인컨텐츠가 바디가 되어야하기 때문에, no custom으로 만들어야한다.
    // no custom 으로 height: 100%로 감싸는 UI만 변형이지 scroll 이벤트는 full에서만 동작.
    return isMobileAndTouchDevice &&
        (props.type === SCROLL_BAR_ROOT_TYPE ||
            props.type === SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS) ? (
        <NoCustomScrollbar
            id={props.id}
            infiniteScrollCallback={props.infiniteScrollCallback}
            type={props.type}
            isHide={typeof props.isHide !== "undefined" ? props.isHide : false}
            isSaveScrollTop={props.isSaveScrollTop}
        >
            {props.children}
        </NoCustomScrollbar>
    ) : (
        <CustomScrollbar
            infiniteScrollCallback={props.infiniteScrollCallback}
            type={props.type}
            children={props.children}
            isHide={typeof props.isHide !== "undefined" ? props.isHide : false}
            isThin={props.isThin !== undefined ? props.isThin : false}
            isSaveScrollTop={props.isSaveScrollTop}
            id={props.id}
        />
    );
};

export default Scrollbar;
Scrollbar.defaultProps = {
    isHide: false,
};
