import { ReactElement } from "react";
import styled from "styled-components";
import SearchFilterLayerListItemCheckbox from "./SearchFilterLayerListItemCheckbox";
import RadioButton from "../../../../../commonUnitComponent/radioButton/RadioButton";
import { FilterType } from "../../../SearchFilter";
import { FilterItemType } from "../../../../doubleSideBar/leftSideBar/LeftSideBar";
import { useOpenCommonErrorModal } from "../../../../../../../hooks/modal/openModal/useOpenCommonErrorModal";

export const SearchFilterLayerListItemContainerLabel = styled.label<{
    width: string;
}>`
    width: ${({ width }) => width};
    padding: 10px 12px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #222222;
    border-radius: 6px;
    transition: background-color 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f0f2;
        }
    }

    display: flex;
    align-items: center;
    cursor: pointer;

    @media ${(props) => props.theme.tabletM} {
        width: auto;
    }
`;

type Props = {
    filterItemWidth: string;
    filterIdx: number;
    filterItem: FilterItemType;
    filterItemIdx: number;
    tempFilters: FilterType[];
    setTempFilters: (value: FilterType[]) => void;
    additionalExceptionOnChangeHandler?: (
        value: boolean,
        tempFilters: FilterType[],
    ) => FilterType[];
    id: string;
};

const SearchFilterLayerListItem = (props: Props): ReactElement | null => {
    const isAllCheckbox = props.filterItemIdx === -1;
    const { openCommonErrorModal } = useOpenCommonErrorModal();

    function getId() {
        return props.id + "-" + props.filterIdx + "-" + props.filterItemIdx;
    }

    return (
        <SearchFilterLayerListItemContainerLabel
            width={props.filterItemWidth}
            key={getId()}
            htmlFor={getId()}
        >
            {props.tempFilters[props.filterIdx].isCheckbox && (
                <SearchFilterLayerListItemCheckbox
                    id={getId()}
                    onChangeEventHandler={async () => {
                        if (!isAllCheckbox) {
                            props.setTempFilters(
                                (props.additionalExceptionOnChangeHandler
                                    ? props.additionalExceptionOnChangeHandler(
                                          !props.filterItem.isSelected,
                                          props.tempFilters,
                                      )
                                    : props.tempFilters
                                ).map((filter, filterIdx) => {
                                    return {
                                        ...filter,
                                        filterList: filter.filterList.map(
                                            (filterItem, filterItemIdx) => {
                                                if (
                                                    filterIdx ===
                                                        props.filterIdx &&
                                                    filterItemIdx ===
                                                        props.filterItemIdx
                                                ) {
                                                    return {
                                                        ...filterItem,
                                                        isSelected:
                                                            !filterItem.isSelected,
                                                    };
                                                }
                                                return filterItem;
                                            },
                                        ),
                                    };
                                }),
                            );
                        } else {
                            if (props.filterItem.isSelected) {
                                await openCommonErrorModal({
                                    title: "최소 하나 이상의 필터조건을 선택해야합니다.",
                                });
                            } else {
                                props.setTempFilters(
                                    (props.additionalExceptionOnChangeHandler
                                        ? props.additionalExceptionOnChangeHandler(
                                              !props.filterItem.isSelected,
                                              props.tempFilters,
                                          )
                                        : props.tempFilters
                                    ).map((filter, filterIdx) => {
                                        if (filterIdx === props.filterIdx) {
                                            return {
                                                ...filter,
                                                filterList:
                                                    filter.filterList.map(
                                                        (
                                                            filterItem,
                                                            filterItemIdx,
                                                        ) => {
                                                            return {
                                                                ...filterItem,
                                                                isSelected:
                                                                    false,
                                                            };
                                                        },
                                                    ),
                                            };
                                        } else {
                                            return filter;
                                        }
                                    }),
                                );
                            }
                        }
                    }}
                    isSelected={props.filterItem.isSelected}
                    title={props.filterItem.title}
                />
            )}
            {!props.tempFilters[props.filterIdx].isCheckbox && (
                <RadioButton
                    id={getId()}
                    onChange={() => {
                        if (!isAllCheckbox) {
                            props.setTempFilters(
                                (props.additionalExceptionOnChangeHandler
                                    ? props.additionalExceptionOnChangeHandler(
                                          !props.filterItem.isSelected,
                                          props.tempFilters,
                                      )
                                    : props.tempFilters
                                ).map((filter, filterIdx) => {
                                    if (props.filterIdx === filterIdx) {
                                        return {
                                            ...filter,
                                            filterList: filter.filterList.map(
                                                (filterItem, filterItemIdx) => {
                                                    return {
                                                        ...filterItem,
                                                        isSelected:
                                                            filterItemIdx ===
                                                            props.filterItemIdx,
                                                    };
                                                },
                                            ),
                                        };
                                    } else {
                                        return filter;
                                    }
                                }),
                            );
                        } else {
                            props.setTempFilters(
                                (props.additionalExceptionOnChangeHandler
                                    ? props.additionalExceptionOnChangeHandler(
                                          !props.filterItem.isSelected,
                                          props.tempFilters,
                                      )
                                    : props.tempFilters
                                ).map((filter, filterIdx) => {
                                    if (filterIdx === props.filterIdx) {
                                        return {
                                            ...filter,
                                            filterList: filter.filterList.map(
                                                (filterItem, filterItemIdx) => {
                                                    return {
                                                        ...filterItem,
                                                        isSelected: false,
                                                    };
                                                },
                                            ),
                                        };
                                    }
                                    return filter;
                                }),
                            );
                        }
                    }}
                    isSelected={props.filterItem.isSelected}
                    labelComponent={props.filterItem.title}
                />
            )}
        </SearchFilterLayerListItemContainerLabel>
    );
};

export default SearchFilterLayerListItem;
