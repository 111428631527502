import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { getItemDisplayType } from "../MarketItemOrderBox";
import { useItemDataQuery } from "../../../../../../../../../../../query/item/useItemDataQuery";
import { useParams } from "react-router-dom";
import { useIsMe } from "../../../../../../../../../../../hooks/common/useIsMe";
import ItemOrderSellTab from "../../../../../../../../../../common/commonUiComponent/orderBoxContents/orderItem/ItemOrderSellTab";
import ItemOrderBuyTab from "../../../../../../../../../../common/commonUiComponent/orderBoxContents/orderItem/ItemOrderBuyTab";
import ItemOrderHistoryTab from "../../../../../../../../../../common/commonUiComponent/orderBoxContents/orderItem/ItemOrderHistoryTab";
import CommonTabBox from "../../../../../../../../../../common/commonUnitComponent/commonTabBox/CommonTabBox";
import { getAuthKey } from "../../../../../../../../../../../store/auth/authKeyStore";

export const MarketItemOrderAbleBoxContainer = styled.div``;

type Props = {};

const MarketItemOrderAbleBox = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const [selectedTabIdx, setSelectedTabIdx] = useState(0);

    const isMe = useIsMe();
    const { data: rawItemData } = useItemDataQuery(itemIdx);
    const itemDisplayType = getItemDisplayType(
        rawItemData.itemInfo.transaction_bool,
        rawItemData.itemInfo.countable_bool,
    );
    const isCreatorIsMe = isMe(rawItemData.creatorInfo.creator_idx);

    const tabList =
        itemDisplayType === "1" || itemDisplayType === "2"
            ? isCreatorIsMe
                ? [
                      { title: "판매", isNeedLogin: false },
                      { title: "주문내역", isNeedLogin: true },
                  ]
                : [
                      { title: "구매", isNeedLogin: false },
                      { title: "주문내역", isNeedLogin: true },
                  ]
            : [
                  { title: "구매", isNeedLogin: false },
                  { title: "판매", isNeedLogin: false },
                  { title: "주문내역", isNeedLogin: true },
              ];

    const authKey = getAuthKey();
    useEffect(() => {
        setSelectedTabIdx(0);
    }, [authKey]);

    return (
        <MarketItemOrderAbleBoxContainer>
            <CommonTabBox
                iconUrl={"/icon/icon-sign.svg"}
                isOpenSupport={false}
                tabList={tabList}
                selectedTabIdx={selectedTabIdx}
                setSelectedTabIdx={setSelectedTabIdx}
                hasPaddingBottom={selectedTabIdx !== 2}
                isGuestAddOpacityEffect={true}
            >
                <>
                    {itemDisplayType === "1" || itemDisplayType === "2" ? (
                        isCreatorIsMe ? (
                            selectedTabIdx === 0 ? (
                                <ItemOrderSellTab
                                    displayType={itemDisplayType}
                                />
                            ) : (
                                <ItemOrderHistoryTab />
                            )
                        ) : selectedTabIdx === 0 ? (
                            <ItemOrderBuyTab />
                        ) : (
                            <ItemOrderHistoryTab />
                        )
                    ) : (
                        <>
                            {selectedTabIdx === 0 && <ItemOrderBuyTab />}

                            {selectedTabIdx === 1 && (
                                <ItemOrderSellTab
                                    displayType={itemDisplayType}
                                />
                            )}
                            {selectedTabIdx === 2 && <ItemOrderHistoryTab />}
                        </>
                    )}
                </>
            </CommonTabBox>
        </MarketItemOrderAbleBoxContainer>
    );
};

export default MarketItemOrderAbleBox;
