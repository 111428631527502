import { ReactElement, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { numberToStringWithComma } from "../../../../../../../../../utils/Utils";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../../../../../common/commonUnitComponent/commonTable/CommonTable";
import {
    CommonTC,
    CommonTCBold,
    CommonTCContainer,
    CommonTCSmall,
} from "../../../../../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import { format } from "date-fns";
import { useDeviceWidth } from "../../../../../../../../../hooks/ui/useDeviceWidth";
import { useParams } from "react-router-dom";
import ApiResBoundary from "../../../../../../../../common/apiResBoundary/ApiResBoundary";
import {
    MarketSaleListItemType,
    spreadMarketSaleList,
    useGetMarketSaleListQuery,
} from "../../../../../../../../../query/market/common/useGetMarketSaleListQuery";
import CommonTabBox from "../../../../../../../../common/commonUnitComponent/commonTabBox/CommonTabBox";
import { getItemDisplayType } from "./marketItemOrderBox/MarketItemOrderBox";
import { useItemDataQuery } from "../../../../../../../../../query/item/useItemDataQuery";
import { getIsTradeAvailableItem } from "../../../../../../../../wallet/walletCollection/components/walletCollectionItemListItem/WalletCollectionItem";

export const MarketItemSalesBoxContainer = styled.div`
    position: relative;
`;

const DateColumnWidth = css`
    width: 68px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

const SellerColumnWidth = css`
    width: 80px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

const CountColumnWidth = css`
    width: 40px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

const PriceColumnWidth = css`
    width: 85px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

export const ItemSalesDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${DateColumnWidth};
`;

export const ItemSalesSellerColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    white-space: nowrap;
    ${SellerColumnWidth};
`;

export const ItemSalesCountColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${CountColumnWidth};
`;

export const ItemSalesPriceColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${PriceColumnWidth};
`;

type Props = {};

const MarketItemSalesBox = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const {
        data: rawMarketItemSaleList,
        isLoading: isMarketItemSaleListLoading,
        error: errorMarketItemSaleList,
        hasNextPage: _hasMarketItemSaleListNextPage,
        fetchNextPage: fetchMarketItemSaleListNextPage,
    } = useGetMarketSaleListQuery("item", itemIdx, {
        limit: 20,
        page_no: 1,
    });
    const marketItemSaleList = spreadMarketSaleList(
        rawMarketItemSaleList.pages,
    );

    const hasMarketItemSaleListNextPageRef = useRef(
        _hasMarketItemSaleListNextPage,
    );
    useEffect(() => {
        hasMarketItemSaleListNextPageRef.current =
            _hasMarketItemSaleListNextPage;
    }, [_hasMarketItemSaleListNextPage]);

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "dateColumn",
            component: (
                <ItemSalesDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    판매기한
                </ItemSalesDateColumnContainer>
            ),
        },
        {
            key: "sellerColumn",
            component: (
                <ItemSalesSellerColumnContainer
                    textAlign={"left"}
                    key={"sellerColumn"}
                >
                    판매자
                </ItemSalesSellerColumnContainer>
            ),
        },
        {
            key: "countColumn",
            component: (
                <ItemSalesCountColumnContainer
                    textAlign={"center"}
                    key={"countColumn"}
                >
                    수량
                </ItemSalesCountColumnContainer>
            ),
        },
        {
            key: "priceColumn",
            component: (
                <ItemSalesPriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    단가(ST)
                </ItemSalesPriceColumnContainer>
            ),
        },
    ];

    const data = marketItemSaleList.map((data: MarketSaleListItemType) => {
        return {
            dateColumn: (
                <ItemSalesDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    {data.order_valid_until ? (
                        <CommonTCContainer>
                            <CommonTCBold textAlign={"center"}>
                                {format(
                                    new Date(data.order_valid_until),
                                    "HH:mm:ss",
                                )}
                            </CommonTCBold>
                            <CommonTCSmall textAlign={"center"}>
                                {format(
                                    new Date(data.order_valid_until),
                                    "yyyy-MM-dd",
                                )}
                            </CommonTCSmall>
                        </CommonTCContainer>
                    ) : (
                        <CommonTC textAlign={"center"}>-</CommonTC>
                    )}
                </ItemSalesDateColumnContainer>
            ),
            sellerColumn: (
                <ItemSalesSellerColumnContainer
                    textAlign={"left"}
                    key={"sellerColumn"}
                >
                    {data.user_nick}
                </ItemSalesSellerColumnContainer>
            ),
            countColumn: (
                <ItemSalesCountColumnContainer
                    textAlign={"center"}
                    key={"countColumn"}
                >
                    {numberToStringWithComma(
                        data.order_sub_quantity - data.settled_quantity_sum,
                    )}
                </ItemSalesCountColumnContainer>
            ),
            priceColumn: (
                <ItemSalesPriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <CommonTCBold textAlign={"right"}>
                        {numberToStringWithComma(data.order_sub_unit_price)}
                    </CommonTCBold>
                </ItemSalesPriceColumnContainer>
            ),
        };
    });
    const { isMobileWidth } = useDeviceWidth();
    const { data: rawItemData } = useItemDataQuery(itemIdx);
    const itemDisplayType = getItemDisplayType(
        rawItemData.itemInfo.transaction_bool,
        rawItemData.itemInfo.countable_bool,
    );

    const isTradAble = getIsTradeAvailableItem(
        rawItemData.itemInfo.item_status,
    );

    return isTradAble && itemDisplayType !== "1" ? (
        <MarketItemSalesBoxContainer>
            <CommonTabBox
                iconUrl={"/icon/icon-sign.svg"}
                tabList={[{ title: "판매호가" }]}
                isOpenSupport={false}
                isGuestAddOpacityEffect={true}
                hasPaddingBottom={false}
            >
                <ApiResBoundary
                    error={errorMarketItemSaleList}
                    isNoData={false}
                    isLoading={isMarketItemSaleListLoading}
                >
                    <CommonTable
                        hasParentPaddingBottom={false}
                        id="market-item-sales"
                        tableHeaderData={tableHeaderData}
                        tableBodyData={data}
                        noDataMsg={"판매호가가 없습니다."}
                        tableBodyHeight={isMobileWidth ? "265px" : "297px"}
                        infiniteScrollCallback={async (
                            showSpinner: () => void,
                        ) => {
                            if (hasMarketItemSaleListNextPageRef.current) {
                                showSpinner();
                                await fetchMarketItemSaleListNextPage();
                            }
                        }}
                    />
                </ApiResBoundary>
            </CommonTabBox>
        </MarketItemSalesBoxContainer>
    ) : null;
};

export default MarketItemSalesBox;
