import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { numberToStringWithComma } from "../../../../../../../../../../utils/Utils";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellBackgroundRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import CalendarCellRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import AdSaleManageScheduleCalendarCellBodyHighestOfferRow from "../../adSaleManageCalendarCellBody/components/adSaleManageScheduleCalendarCellBodyRow/AdSaleManageScheduleCalendarCellBodyHighestOfferRow";
import {
    AdSaleManageScheduleCalendarCellBodyBlankContainer,
    HiddenKeyManage,
} from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarCellPcBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarCellPcBodyInReview = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const {
        targetDateInfo: cellInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdMyDailyDateData(props);

    return !isAdStop ? (
        isTodaySale ? (
            cellInfo.offerSuccessInfo ? (
                <>
                    <CalendarCellBackgroundRow
                        msg={"낙찰 완료"}
                        type={"primary-weak"}
                    />
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            cellInfo.offerSuccessInfo.offer_price,
                        )} KRW`}
                        type={"secondary"}
                    />
                    <CalendarCellRow
                        msg={cellInfo.offerSuccessInfo.offer_success_user_nick}
                        type={"secondary"}
                        hasNoMt={true}
                    />
                    <HiddenKeyManage
                        id={
                            "AD_IN_REVIEW__notAdStop__dailySale__offerSuccessInfo"
                        }
                    />
                </>
            ) : cellInfo.highestOfferInfo ? (
                <>
                    <CalendarCellBackgroundRow
                        msg={"시스템 준비중"}
                        type={"secondary"}
                    />
                    <AdSaleManageScheduleCalendarCellBodyHighestOfferRow
                        price={cellInfo.highestOfferInfo.offer_price}
                        nickname={cellInfo.highestOfferInfo.user_nick}
                    />
                    <CalendarCellRow msg={"즉시구매가"} type={"secondary"} />
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            cellInfo.adSaleInfo.ad_reserve_price * 1.1,
                        )} KRW`}
                        type={"secondary"}
                        hasNoMt={true}
                    />
                    <HiddenKeyManage
                        id={
                            "AD_IN_REVIEW__notAdStop__dailySale__highestOfferInfo"
                        }
                    />
                </>
            ) : (
                <>
                    <CalendarCellBackgroundRow
                        msg={"시스템 준비중"}
                        type={"secondary"}
                    />
                    <CalendarCellRow msg={"최소입찰가"} type={"secondary"} />
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            cellInfo.adSaleInfo.ad_minimum_bid_price * 1.1,
                        )} KRW`}
                        type={"secondary"}
                        hasNoMt={true}
                    />
                    <CalendarCellRow msg={"즉시구매가"} type={"secondary"} />
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            cellInfo.adSaleInfo.ad_reserve_price * 1.1,
                        )} KRW`}
                        type={"secondary"}
                        hasNoMt={true}
                    />
                    <HiddenKeyManage
                        id={"AD_IN_REVIEW__notAdStop__dailySale__noOffer"}
                    />
                </>
            )
        ) : (
            <>
                <CalendarCellBackgroundRow msg={"판매 중단"} type={"danger"} />
                <AdSaleManageScheduleCalendarCellBodyBlankContainer />
                <HiddenKeyManage
                    id={"AD_IN_REVIEW__notAdStop__notDailySale__noOffer"}
                />
            </>
        )
    ) : (
        <>
            <HiddenKeyManage id={"AD_IN_REVIEW__AdStop"} />
        </>
    );
};

export default AdSaleManageCalendarCellPcBodyInReview;
