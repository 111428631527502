import React, {ReactElement} from "react";
import {useLocation} from "react-router-dom";
import "./Transition.css";

type Props = {
    children: ReactElement;
};

const RouteTransition = (props: Props): ReactElement | null => {
    const location = useLocation();
    let CSSTransitionOption: any = {};
    let className = "";
    switch (location.pathname) {
        // case "/":
        // case "/ad":
        //     CSSTransitionOption = {
        //         key: location.pathname,
        //         timeout: 500,
        //         classNames: "slide",
        //         // mountOnEnter: false,
        //         unmountOnExit: false,
        //     };
        //     className = "slide";
        //     break;

        default:
            CSSTransitionOption = {
                key: location.pathname,
                classNames: "fade",
                timeout: 300,
            };
            className = "fade";
            break;
    }
    return (
        // <TransitionGroup className="transition-group">
        //     <CSSTransition key={location.pathname} timeout={500} classNames={"pageSlider"}>
        <>{props.children}</>
        //     </CSSTransition>
        // </TransitionGroup>
    );
};

export default RouteTransition;
