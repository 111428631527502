import { forwardRef, ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useStringLengthCheck } from "../../../../hooks/common/useStringLengthCheck";
import {
    checkUserAgent,
    numberToStringWithComma,
} from "../../../../utils/Utils";
import {
    ExceptionUiHandlerErrorTargetType,
    ExceptionUiHandlerType,
} from "../../../../apis/ErrorHandler/callApiExcpetionHandler";
import isMobileKeyboardOpenAtom from "../../../../store/ui/isMobileKeyboardOpenAtom";
import { useSetAtom } from "jotai";

export const CommonInputTextContainer = styled.div`
    width: 100%;
`;

export const CommonInputTextInputContainer = styled.div<{
    isWarning: boolean;
    isDisabled: boolean;
    disabledColor?: string;
}>`
    width: calc(100% - 2px);
    display: flex;
    align-items: center;
    border: ${({ isWarning, isDisabled, disabledColor }) => {
        if (!isDisabled) {
            return isWarning ? "1px #FF4671 solid" : "1px #f5f5f7 solid";
        } else {
            return `1px solid ${disabledColor ? disabledColor : "white"}`;
        }
    }};
    border-radius: 8px;
    background: #f5f5f7;
    transition: all 200ms ease;
`;

export const CommonInputFixedValue = styled.div<{
    isDisabled: boolean;
    disabledColor?: string;
}>`
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 38px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    background: ${({ isDisabled, disabledColor }) =>
        isDisabled ? (disabledColor ? disabledColor : "white") : "#f5f5f7"};
    color: ${({ isDisabled }) => (isDisabled ? "#000" : "rgba(0, 0, 0, 0.4)")};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 15px */
`;

export const CommonInputTextInput = styled.input<{
    isWarning: boolean;
    disabledColor?: string;
    hasFixedValue: boolean;
    isRightWrite: boolean;
}>`
    display: flex;
    width: calc(100% - 20px);
    height: 38px;
    padding: ${({ hasFixedValue }) =>
        hasFixedValue ? "0 10px 0 0" : "0 10px"};
    align-items: center;
    align-self: stretch;
    border-radius: ${({ hasFixedValue }) => (hasFixedValue ? "0" : "8px")};
    ${({ hasFixedValue }) => {
        if (hasFixedValue)
            return `
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            `;
    }}
    border: ${({ isWarning }) =>
        isWarning ? "1px #FF4671 solid" : "1px #f5f5f7 solid"};
    border: none;
    background: #f5f5f7;
    outline: none;

    color: #000;
    font-family: Noto Sans KR, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    ::placeholder {
        color: rgba(0, 0, 0, 0.3);
        font-family: Noto Sans KR, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
    }

    ${({ isRightWrite }) => {
        if (isRightWrite) {
            return `
                text-align: right; 
            `;
        }
    }}
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    :disabled {
        background: ${({ disabledColor }) =>
            disabledColor ? disabledColor : "white"};
        border: none;
        padding: 0;
        width: 100%;
    }

    transition: all 300ms ease;
`;

export const CommonInputWarningMsg = styled.div<{
    isWarning: boolean;
    isDisabled: boolean;
    isRightAlign?: boolean;
}>`
    position: relative;
    right: 0;
    display: flex;
    margin-top: ${({ isWarning, isDisabled }) =>
        isWarning && !isDisabled ? "6px" : "0"};
    color: #ff4671;
    font-family: Noto Sans KR, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    overflow: visible;
    white-space: nowrap;
    height: ${({ isWarning, isDisabled }) =>
        isWarning && !isDisabled ? "12px" : "0"};
    transition: all 200ms ease;
    justify-content: ${({ isRightAlign }) =>
        isRightAlign ? "flex-end" : "flex-start"};
`;

export const firstEndBlankCheckValidation = function (
    inputText: InputTextDataType,
    setInputText: (value: InputTextDataType) => void,
) {
    const hasBlank =
        inputText.value.length > 0
            ? inputText.value[0] === " " ||
              inputText.value[inputText.value.length - 1] === " "
            : false;

    if (hasBlank) {
        setInputText({
            value: inputText.value,
            isWarning: true,
            warningMsg: "처음과 끝은 공백을 입력하실 수 없습니다.",
        });
        return false;
    }
    return true;
};

export const blankCheckValidation = function (
    inputText: InputTextDataType,
    setInputText: (value: InputTextDataType) => void,
) {
    const hasBlank = / /g.test(inputText.value);
    if (hasBlank) {
        setInputText({
            value: inputText.value,
            isWarning: true,
            warningMsg: "공백은 입력하실 수 없습니다.",
        });
        return false;
    }
    return true;
};

export const specialCharCheckValidation = function (
    InputText: InputTextDataType,
    setInputText: (value: InputTextDataType) => void,
) {
    const hasSpecialChar = /[^-a-zA-Z0-9가-힣\s]/g.test(InputText.value);
    if (hasSpecialChar) {
        setInputText({
            value: InputText.value,
            isWarning: true,
            warningMsg: "'-'을 제외한 특수 문자는 사용할 수 없습니다.",
        });
        return false;
    }
    return true;
};

export const specialCharCheckValidationIncludeUnderBar = function (
    InputText: InputTextDataType,
    setInputText: (value: InputTextDataType) => void,
) {
    const hasSpecialChar = /[^a-zA-Z0-9가-힣\-_\s]/.test(InputText.value);
    if (hasSpecialChar) {
        setInputText({
            value: InputText.value,
            isWarning: true,
            warningMsg: "'-', '_'외 특수문자는 사용할 수 없습니다.",
        });
        return false;
    }

    return true;
};

// 일단 금칙어 나오는 곳 예외처리해둔다.
export const exceptionUiHandleByErrorCode = function (
    handler: ExceptionUiHandlerType,
    error: any,
    errorTarget: ExceptionUiHandlerErrorTargetType,
) {
    try {
        if (
            error.response.data.data.errorCode.indexOf(
                errorTarget.targetName,
            ) !== -1
        ) {
            // reference 가 있는 경우는 reference 와 errorTarget 검증도 필요
            if (error.response.data.data.reference) {
                if (
                    error.response.data.data.reference.indexOf(
                        `${errorTarget.targetColumn} - ${errorTarget.targetIdx}`,
                    ) !== -1
                ) {
                    if (handler.inputText && handler.setInputText) {
                        handler.setInputText({
                            ...handler.inputText,
                            isWarning: true,
                            warningMsg: error.response.data.message,
                        });
                    }
                } else {
                    // reference 와 errorTarget 이 맞지 않는 경우
                    return true;
                }
            } else {
                if (handler.inputText && handler.setInputText) {
                    handler.setInputText({
                        ...handler.inputText,
                        isWarning: true,
                        warningMsg: error.response.data.message,
                    });
                }
            }

            // 스크롤 이동 이거 밴워드 클릭 이벤트에 겹쳐져서 중간에 멈추는 문제 있어서 timeout 강제로줌
            if (handler.targetContainerRef) {
                setTimeout(() => {
                    if (handler.targetContainerRef) {
                        handler.targetContainerRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                }, 300);
            }
            return false;
        }
    } catch (e) {
        console.info("banword - export - error");
        return false;
    }
    return true;
};

export type InputTextDataType = {
    value: string;
    isWarning: boolean;
    warningMsg: string;
};

export const initialInputTextData = {
    value: "",
    isWarning: false,
    warningMsg: "",
};

export type CommonInputTextProps = {
    inputText: InputTextDataType;
    setInputText: (value: InputTextDataType) => void;
    placeholder?: string;
    minLength: number;
    maxLength: number;
    checkAdditionalValidation?: (value?: string) => boolean;
    fixedValue?: string;
    isRightWrite?: boolean;
    isDescRight?: boolean;
    type?: string;
    isAddComma?: boolean;
    isOnlyEnglish?: boolean;
    isOnlyNumber?: boolean;
    disabled?: boolean;
    disabledColor?: string;
    focusOutEventHandler?: () => Promise<void>;
    focusOnEventHandler?: () => Promise<void>;
    nanExceptionValue?: string;
};

const CommonInputText = forwardRef<HTMLInputElement, CommonInputTextProps>(
    (props, ref): ReactElement | null => {
        const setIsMobileKeyboardOpenAtom = useSetAtom(
            isMobileKeyboardOpenAtom,
        );

        useStringLengthCheck(
            props.minLength,
            props.maxLength,
            props.inputText,
            props.setInputText,
            props.checkAdditionalValidation,
        );

        const inputRef =
            typeof ref !== "undefined" ? ref : useRef<HTMLInputElement>(null);

        const [selectionStart, setSelectionStart] = useState(0);
        useEffect(() => {
            if (
                props.isAddComma &&
                inputRef !== null &&
                "current" in inputRef &&
                inputRef.current
            ) {
                const valueLength = numberToStringWithComma(
                    props.inputText.value,
                ).length;

                if (valueLength >= 4) {
                    inputRef.current.setSelectionRange(
                        valueLength % 4 === 1 && valueLength !== 1
                            ? selectionStart + 1
                            : selectionStart,
                        valueLength % 4 === 1 && valueLength !== 1
                            ? selectionStart + 1
                            : selectionStart,
                    );
                }
            }
        }, [props.inputText.value]);

        return (
            <CommonInputTextContainer>
                <CommonInputTextInputContainer
                    isWarning={props.inputText.isWarning}
                    isDisabled={props.disabled ? props.disabled : false}
                    disabledColor={props.disabledColor}
                >
                    {props.fixedValue && (
                        <CommonInputFixedValue
                            isDisabled={props.disabled || false}
                            disabledColor={props.disabledColor}
                        >
                            {props.fixedValue}
                        </CommonInputFixedValue>
                    )}
                    <CommonInputTextInput
                        ref={inputRef}
                        type={props.type}
                        isRightWrite={props.isRightWrite || false}
                        isWarning={props.inputText.isWarning}
                        placeholder={props.placeholder}
                        value={
                            props.isAddComma
                                ? numberToStringWithComma(props.inputText.value)
                                : props.inputText.value
                        }
                        onChange={(e) => {
                            if (props.isAddComma || props.isOnlyNumber) {
                                const count = parseInt(
                                    e.target.value.replace(/,/g, ""),
                                );
                                if (!isNaN(count) && count >= 0) {
                                    props.setInputText({
                                        ...props.inputText,
                                        value: count + "",
                                    });
                                } else {
                                    if (
                                        typeof props.nanExceptionValue !==
                                        "undefined"
                                    ) {
                                        props.setInputText({
                                            ...props.inputText,
                                            value: props.nanExceptionValue + "",
                                        });
                                    } else {
                                        props.setInputText({
                                            ...props.inputText,
                                            value: "",
                                        });
                                    }
                                }
                                setSelectionStart(e.target.selectionStart || 0);
                            } else {
                                props.setInputText({
                                    ...props.inputText,
                                    value: props.isOnlyEnglish
                                        ? e.target.value.replace(
                                              /[^A-Za-z]/gi,
                                              "",
                                          )
                                        : e.target.value,
                                });
                            }
                        }}
                        hasFixedValue={typeof props.fixedValue !== "undefined"}
                        disabled={props.disabled}
                        disabledColor={props.disabledColor}
                        onFocusCapture={async () => {
                            // 모바일일 경우, 포커싱시 키보드 올라옴
                            if (
                                checkUserAgent.isMobileDevice() &&
                                checkUserAgent.isTouchDevice()
                            ) {
                                setIsMobileKeyboardOpenAtom(true);
                            }

                            if (props.focusOnEventHandler) {
                                await props.focusOnEventHandler();
                            }
                        }}
                        onBlurCapture={async () => {
                            setIsMobileKeyboardOpenAtom(false);

                            if (props.focusOutEventHandler) {
                                await props.focusOutEventHandler();
                            }
                        }}
                    />
                </CommonInputTextInputContainer>
                <CommonInputWarningMsg
                    isWarning={props.inputText.isWarning}
                    isDisabled={props.disabled ? props.disabled : false}
                    isRightAlign={props.isDescRight}
                >
                    {props.inputText.warningMsg}
                </CommonInputWarningMsg>
            </CommonInputTextContainer>
        );
    },
);

CommonInputText.defaultProps = {
    ref: undefined,
    isRightWrite: false,
    type: "text",
    isAddComma: false,
    isOnlyEnglish: false,
    disabled: false,
};

export default CommonInputText;
