import {
    AdMyOfferListApiResultType,
    AdMyOfferListParamsType,
} from "../../query/ad/adBidOffer/useAdMyOfferListQuery";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import {
    AdOfferSuccessListApiResultType,
    AdOfferSuccessListParamsType,
} from "../../query/ad/adBidOffer/useAdOfferSuccessListQuery";
import {
    AllAdOfferSuccessListApiParams,
    AllAdOfferSuccessListApiResultType,
} from "../../query/ad/adBidOffer/useAllAdOfferSuccessListQuery";
import axios from "axios";
import { AdOfferInfoApiResultType } from "../../query/ad/adBidOffer/useGetAdOfferInfoQuery";
import { GetAdBuyWindowParams } from "../../query/ad/adBuy/useGetAdBuyWindowMutation";
import { AdBuyWithCardParamsType } from "../../query/ad/adBuy/useAdBuyWithCard";
import { AdOfferParams } from "../../query/ad/adBidOffer/useAdOfferMutation";
import {
    AdAllOfferListApiResultType,
    AdAllOfferListParamsType,
} from "../../query/ad/adBidOffer/useAdAllOfferListQuery";
import { AcceptAdOfferApiParam } from "../../query/ad/adBidOffer/useAcceptAdOfferMutation";
import { CancelAdOfferSuccessBySellerApiParam } from "../../query/ad/adBidOffer/useCancelAdOfferSuccessBySellerMutation";
import { CancelAdOfferSuccessByBuyerApiParam } from "../../query/ad/adBidOffer/useCancelAdOfferSuccessByBuyerMutation";

export const AdOfferApi = {
    getAdAllOfferList: async (
        adIdx: string,
        params: AdAllOfferListParamsType,
    ): Promise<AdAllOfferListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/list`, {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdMyOfferList: async (
        adIdx: string,
        params: AdMyOfferListParamsType,
    ): Promise<AdMyOfferListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/my-list`, {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdOfferInfo: async (
        adIdx: string,
        adOfferIdx: string,
    ): Promise<AdOfferInfoApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/${adOfferIdx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdOfferSuccessList: async (
        adIdx: string,
        params: AdOfferSuccessListParamsType,
    ): Promise<AdOfferSuccessListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/success/list`,
                    {
                        params: params,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAllAdOfferSuccessList: async (
        params: AllAdOfferSuccessListApiParams,
    ): Promise<AllAdOfferSuccessListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/offer/success/list`, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    offer: async (adIdx: string, params: AdOfferParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer`, params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    acceptOffer: async (
        adIdx: string,
        adOfferIdx: string,
        params: AcceptAdOfferApiParam,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/${adOfferIdx}/accept`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    cancelOffer: async (adIdx: string, adOfferIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/${adOfferIdx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    cancelOfferSuccessBySeller: async (
        adIdx: string,
        params: CancelAdOfferSuccessBySellerApiParam,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/daily`, {
                    data: {
                        ad_sales_date: params.ad_sales_date,
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    cancelOfferSuccessByBuyer: async (
        adIdx: string,
        params: CancelAdOfferSuccessByBuyerApiParam,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/daily/refund`,
                    {
                        data: {
                            ad_sales_date: params.ad_sales_date,
                        },
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    buy: async (adIdx: string, params: GetAdBuyWindowParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/buy`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    buyWithCard: async (
        adIdx: string,
        params: AdBuyWithCardParamsType,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/offer/buy-with-card`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
