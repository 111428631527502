import { useAtom } from "jotai";
import { useMemo } from "react";
import isFullScrollOverHasLnbInSubHeaderAtom from "../../store/scroll/isFullScrollOverHasLnbInSubHeaderAtom";
import isFullScrollOverNoLnbInSubHeaderAtom from "../../store/scroll/isFullScrollOverNoLnbInSubHeaderAtom";
import isScrollUpAtom from "../../store/scroll/isScrollUpAtom";

export function useGetIsShowHeaderGroup(isMountedShow: boolean) {
    const [isScrollUp] = useAtom(isScrollUpAtom);
    const [isFullScrollOverHasLnbInSubHeader] = useAtom(
        isFullScrollOverHasLnbInSubHeaderAtom,
    );
    const [isFullScrollOverNoLnbInSubHeader] = useAtom(
        isFullScrollOverNoLnbInSubHeaderAtom,
    );

    // LNB 가 있으면 firstShow false
    // LNB 메뉴가 없으면 진입부터 서브메뉴 노출 / 본문 스크롤 subheader 높이 까지는 노출 / subHeader 높이 초과부터는 스크롤다운시 비노출 스크롤 업시 노출
    // LNB 메뉴가 있으면 진입시에 서브메뉴 비노출 /  본문 스크롤 subheader 높이 까지는 비노출 / subHeader 높이 초과부터는 스크롤다운시 비노출 스크롤 업시 노출
    const getIsShow: boolean = useMemo(() => {
        // LNB 가 없으면
        if (isMountedShow) {
            if (isFullScrollOverNoLnbInSubHeader) {
                // 헷갈리니깐 건들지말자
                return isScrollUp ? true : false;
            } else {
                return true;
            }
        }
        // LNB 가 있으면
        else {
            if (isFullScrollOverHasLnbInSubHeader) {
                return false;
            } else {
                return isScrollUp ? true : false;
            }
        }
    }, [
        isFullScrollOverHasLnbInSubHeader,
        isFullScrollOverNoLnbInSubHeader,
        isScrollUp,
        isMountedShow,
    ]);

    const getIsShowMaxWidthTabletM = useMemo(() => {
        if (isFullScrollOverNoLnbInSubHeader) {
            return isScrollUp ? true : false;
        } else {
            return true;
        }
    }, [isScrollUp, isFullScrollOverNoLnbInSubHeader]);

    return {
        getIsShow: getIsShow,
        getIsShowMaxWidthTabletM: getIsShowMaxWidthTabletM,
    };
}
