import { useOpenModal } from "../useOpenModal";
import React from "react";
import WalletCollectionGroupEditModal from "../../../components/wallet/walletCollection/components/walletCollectionHub/components/walletCollectionGroupModal/walletCollectionGroupEditModal/WalletCollectionGroupEditModal";

export function useOpenCollectionGroupEditModal() {
    const { openModal } = useOpenModal();

    return {
        openCollectionGroupEditModal: async (
            userIdx: string,
        ): Promise<boolean> => {
            return await openModal(
                "wallet-collection-group-edit-modal",
                "bottomSheet",
                (resolve) => {
                    return (
                        <WalletCollectionGroupEditModal
                            resolve={resolve}
                            targetUserIdx={userIdx}
                        />
                    );
                },
            );
        },
    };
}
