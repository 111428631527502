import { useQuery } from "react-query";
import { CommonApi } from "../../apis/CommonApi";

export type VersionDataType = {
    name: string;
    remark: string;
    version: string;
};

export const useGetVersionQuery = () => {
    const queryResult = useQuery<VersionDataType, Error>({
        queryKey: ["version"],
        queryFn: () => CommonApi.getVersion(),
        suspense: false,
        onError: () => {
            return [];
        },
    });

    const defaultData: VersionDataType = {
        name: "",
        remark: "",
        version: "",
    };

    return {
        ...queryResult,
        data: queryResult.data || defaultData,
    };
};
