import axios from "axios";
import {URL_CONSTANTS} from "../constants/UrlConstants";
import {userSearchApiResultType, userSearchParamsType,} from "../query/search/getUserSearchQuery";

export const SearchApi = {
    searchUser: async (
        params: userSearchParamsType,
    ): Promise<userSearchApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/user/info/search", {
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
