import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";
import {
    GetNotificationApiResultType,
    GetNotificationParamsType,
} from "../query/notification/useGetNotificationListQuery";
import { ReadNotificationParamsType } from "../query/notification/useReadNotificationMutation";
import { ReadAllNotificationParams } from "../query/notification/useReadAllNotificationMutation";
import { DeleteAllNotificationParams } from "../query/notification/useDeleteAllNotificationMutation";
import { NotificationStatusResultType } from "../query/notification/useGetNotificationStatusQuery";

export const NotificationApi = {
    getList: async (
        params: GetNotificationParamsType,
    ): Promise<GetNotificationApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/notification/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    readNoti: async (
        notificationIdx: string,
        params: ReadNotificationParamsType,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/notification/${notificationIdx}/read`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    readAllNoti: async (params: ReadAllNotificationParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/notification/read-all",
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteNoti: async (
        notificationIdx: string,
        params: ReadNotificationParamsType,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL +
                        "/notification/" +
                        notificationIdx +
                        "/delete",
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteAllNoti: async (
        params: DeleteAllNotificationParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/notification/delete-all",
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getStatus: async (): Promise<NotificationStatusResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/notification/status", {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
