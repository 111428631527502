import { ReactElement } from "react";
import styled from "styled-components";

import {
    MarketProjectTitleCreator,
    MarketProjectTitleCreatorContainer,
    MarketProjectTitleHeartContainer,
    MarketProjectTitleHeartIcon,
} from "../MarketProjectTitle";

import CssFilterConverter from "css-filter-converter";
import { ProjectDetailDataType } from "../../../../../../query/project/useProjectDataQuery";
import { useShare } from "../../../../../../hooks/common/useShare";
import { useOpenMiniProfileModal } from "../../../../../../hooks/modal/openModal/useOpenMiniProfileModal";
import Nickname from "../../../../../common/commonUnitComponent/nickname/Nickname";
import IncludeIconButton from "../../../../../common/commonUnitComponent/includeIconButton/IncludeIconButton";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { numberToStringWithComma } from "../../../../../../utils/Utils";

export const MarketProjectTitleSubInfoContainer = styled.div`
    display: flex;
    gap: 10px;
`;

type Props = {
    isMobile?: boolean;
    projectData: ProjectDetailDataType;
};

const MarketProjectTitleSubInfo = (props: Props): ReactElement | null => {
    const share = useShare();
    const { openMiniProfileModal } = useOpenMiniProfileModal();

    return (
        <MarketProjectTitleSubInfoContainer>
            <MarketProjectTitleCreatorContainer
                onClick={async () => {
                    await openMiniProfileModal(props.projectData.user_idx);
                }}
            >
                크리에이터
                <Nickname
                    userIdx={props.projectData.user_idx}
                    nickname={props.projectData.user_nick}
                    nicknameStyledComponent={MarketProjectTitleCreator}
                    isShowVerifiedIcon={true}
                    isVerified={props.projectData.user_influencer_bool}
                />
            </MarketProjectTitleCreatorContainer>
            <IncludeIconButton
                isActive={false}
                clickEventHandler={(e) => {
                    share({
                        title: props.projectData.project_name,
                        text: props.projectData.project_description,
                        url: location.href,
                    });
                }}
                activeIconColor={"#000000"}
                icon={`${URL_CONSTANTS.ASSET_URL}/icon/icon-link-copy.svg`}
                marginRight={"2px"}
                title={"링크복사"}
            />
            {typeof props.isMobile !== "undefined" && !props.isMobile && (
                <MarketProjectTitleHeartContainer>
                    <MarketProjectTitleHeartIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-heart.svg`}
                        style={{
                            filter:
                                CssFilterConverter.hexToFilter("#FF4671")
                                    .color + "",
                        }}
                    />
                    총 {numberToStringWithComma(props.projectData.like_cnt)}
                </MarketProjectTitleHeartContainer>
            )}
        </MarketProjectTitleSubInfoContainer>
    );
};

export default MarketProjectTitleSubInfo;
