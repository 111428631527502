import { ReactElement } from "react";
import styled from "styled-components";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../../common/commonUnitComponent/commonTable/CommonTable";
import {
    AdHistoryTableBodyColumn,
    AdHistoryTableHeaderColumn,
} from "../../adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";
import { MyAdAnalysisListItemType } from "../../../../../../query/my/myAd/useGetMyAdAnalysisListQuery";
import AdManageCommonHistoryColumnDate from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnDate";
import AdManageCommonHistoryColumnName from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnName";
import AdManageCommonHistoryColumnBrand from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBrand";
import AdManageCommonHistoryColumnPrice from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPrice";
import AdManageCommonHistoryColumnClickExposeCount from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnClickExposeCount";
import AdManageCommonHistoryColumnAdBtn from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnAdBtn";
import { getMyAnalysisModalInfo } from "./adManageCommonAnalysisTableModal/AdManageCommonAnalysisTableModal";
import {
    AdManageCommonAnalysisTableBrandColumnContainer,
    AdManageCommonAnalysisTableButtonColumnContainer,
    AdManageCommonAnalysisTableDateColumnContainer,
    AdManageCommonAnalysisTablePriceColumnContainer,
    AdManageCommonAnalysisTableRateColumnContainer,
    AdManageCommonAnalysisTableTitleColumnContainer,
} from "../AdManageCommonAnalysisTable";
import { useOpenAdManageCommonAnalysisTableModal } from "../../../../../../hooks/modal/openModal/useOpenAdManageCommonAnalysisTableModal";
import { AD_SALE_ANALYSIS } from "../../../_adManageLayout/AdManageTableContentsLayout";
import { convertSafariAdSalesDate } from "../../../../../../utils/Utils";

export const AdManageCommonAnalysisPcTableContainer = styled.div``;

type Props = {
    type: string;
    adAnalysisList: MyAdAnalysisListItemType[];
};

const AdManageCommonAnalysisPcTable = (props: Props): ReactElement | null => {
    const { openAdHistoryAnalysisModal } =
        useOpenAdManageCommonAnalysisTableModal(props.type);

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "dateColumn",
            component: (
                <AdManageCommonAnalysisTableDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고 적용일
                    </AdHistoryTableHeaderColumn>
                </AdManageCommonAnalysisTableDateColumnContainer>
            ),
        },
        {
            key: "titleColumn",
            component: (
                <AdManageCommonAnalysisTableTitleColumnContainer
                    textAlign={"center"}
                    key={"titleColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고 상품명
                    </AdHistoryTableHeaderColumn>
                </AdManageCommonAnalysisTableTitleColumnContainer>
            ),
        },
        {
            key: "brandColumn",
            component: (
                <AdManageCommonAnalysisTableBrandColumnContainer
                    textAlign={"center"}
                    key={"brandColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        브랜드
                    </AdHistoryTableHeaderColumn>
                </AdManageCommonAnalysisTableBrandColumnContainer>
            ),
        },
        {
            key: "priceColumn",
            component: (
                <AdManageCommonAnalysisTablePriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        낙찰가 (KRW)
                    </AdHistoryTableHeaderColumn>
                </AdManageCommonAnalysisTablePriceColumnContainer>
            ),
        },
        {
            key: "rateColumn",
            component: (
                <AdManageCommonAnalysisTableRateColumnContainer
                    textAlign={"center"}
                    key={"adStartDateColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        클릭 / 노출수 (비율)
                    </AdHistoryTableHeaderColumn>
                </AdManageCommonAnalysisTableRateColumnContainer>
            ),
        },
        {
            key: "buttonColumn",
            component: (
                <AdManageCommonAnalysisTableButtonColumnContainer
                    textAlign={"center"}
                    key={"buttonColumn"}
                />
            ),
        },
    ];

    const data = props.adAnalysisList.map((item: MyAdAnalysisListItemType) => {
        return {
            dateColumn: (
                <AdManageCommonAnalysisTableDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnDate
                            date={
                                new Date(
                                    convertSafariAdSalesDate(
                                        item.advertisementDailyInfo
                                            .ad_sales_date,
                                    ),
                                )
                            }
                            format={"yyyy.MM.dd."}
                        />
                    </AdHistoryTableBodyColumn>
                </AdManageCommonAnalysisTableDateColumnContainer>
            ),
            titleColumn: (
                <AdManageCommonAnalysisTableTitleColumnContainer
                    textAlign={"left"}
                    key={"titleColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnName
                            isOnlyProductName={props.type === AD_SALE_ANALYSIS}
                            adThumbnail={
                                item.advertisementInfo.ad_thumbnail_path
                            }
                            adProductName={
                                props.type === AD_SALE_ANALYSIS
                                    ? item.campaignInfo.campaign_name
                                    : item.advertisementInfo.ad_product_name
                            }
                            adProductSubName={
                                item.advertisementInfo.ad_product_sub_name
                            }
                        />
                    </AdHistoryTableBodyColumn>
                </AdManageCommonAnalysisTableTitleColumnContainer>
            ),
            brandColumn: (
                <AdManageCommonAnalysisTableBrandColumnContainer
                    textAlign={"center"}
                    key={"brandColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnBrand
                            brandId={item.brandInfo.brand_id}
                            brandName={item.brandInfo.brand_name}
                        />
                    </AdHistoryTableBodyColumn>
                </AdManageCommonAnalysisTableBrandColumnContainer>
            ),
            priceColumn: (
                <AdManageCommonAnalysisTablePriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnPrice
                            offerPrice={
                                item.advertisementDailyInfo
                                    .otherOfferSuccessInfo?.offer_price || "-"
                            }
                        />
                    </AdHistoryTableBodyColumn>
                </AdManageCommonAnalysisTablePriceColumnContainer>
            ),
            rateColumn: (
                <AdManageCommonAnalysisTableRateColumnContainer
                    textAlign={"center"}
                    key={"rateColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnClickExposeCount
                            clickCount={item.total_click_count}
                            exposeCount={item.total_impression_count}
                        />
                    </AdHistoryTableBodyColumn>
                </AdManageCommonAnalysisTableRateColumnContainer>
            ),
            buttonColumn: (
                <AdManageCommonAnalysisTableButtonColumnContainer
                    textAlign={"center"}
                    key={"buttonColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnAdBtn
                            type={props.type}
                            clickHandler={async () => {
                                return openAdHistoryAnalysisModal(
                                    getMyAnalysisModalInfo(item),
                                );
                            }}
                        />
                    </AdHistoryTableBodyColumn>
                </AdManageCommonAnalysisTableButtonColumnContainer>
            ),
        };
    });

    return (
        <CommonTable
            id={props.type}
            tableHeaderData={tableHeaderData}
            tableBodyData={data}
            tableBodyHeight={"100%"}
        />
    );
};

export default AdManageCommonAnalysisPcTable;
