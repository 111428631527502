import { atom } from "jotai";
import { RefObject } from "react";

export type CoachMarkInfoType = {
    isHideForever: boolean;
    coachMarkList: CoachMarkListItemType[];
};

export type CoachMarkListItemType = {
    [key: string]: any;
    id: string;
    desc: string;
    containerRef: RefObject<HTMLDivElement>;
    padding: {
        top: number;
        bottom: number;
        left: number;
        right: number;
    };
    direction?: string;
    resolve?: (value: any) => Promise<void> | void;
};

export const CoachMarkInfoAtom = atom<CoachMarkInfoType>({
    isHideForever: false,
    coachMarkList: [],
});
