// 상품등록 전
export const AD_NOT_OPENED_YET = "notOpenedYet";
// 입찰기간
export const AD_ON_BID = "onBid";
// 검수기간
export const AD_IN_REVIEW = "inReview";
// 광고 경매기간 마감
export const AD_BID_CLOSED = "bidClosed";
// 시스템 준비기간
export const AD_PREPARING = "preparing";
// 광고 게재기간(무조건 오늘)
export const AD_ON_SCHEDULE = "onSchedule";
// 광고 종료
export const AD_EXPIRED = "expired";
// 미상
export const AD_UNKNOWN = "unknown";

export type AdDailyListItemType = {
    ad_daily_status_code: AdDailyListItemStatusCodeType;
    ad_daily_status_name: string;
    ad_price: number;
    ad_sales_date: string;
    buyer_nick: string;
    campaign_name?: string;
    buy_now_bool: boolean;
    available_offer_price: number;
    template_idx: string;
    ad_sales_daily_status: string;

    myOfferInfo?: AdDailyListItemMyOfferInfoType;
    myOfferSuccessInfo?: AdDailyListItemMyOfferSuccessInfoType;
    otherOfferInfo?: AdDailyListItemOtherOfferInfoType;
    otherOfferSuccessInfo?: AdDailyListItemOtherOfferSuccessInfoType;
    dailyClosedTimeSaleInfo?: AdDailyTimeSaleInfo;
};

export type AdDailyListItemMyOfferInfoType = {
    buy_now_available_bool: boolean;
    highest_price_bool: boolean;
    offer_price: number;
};

export type AdDailyListItemMyOfferSuccessInfoType = {
    user_idx: string;
    user_id: string;
    user_influencer_bool: boolean;
    offer_price: number;
    refundable_price?: number;
    refund_ratio?: number;
    refund_ratio_in_percent?: number;
    days_on_air?: number;
};

export type AdDailyListItemOtherOfferInfoType = {
    user_nick: string;
    offer_price: number;
    buy_now_available_bool: boolean;
};

export type AdDailyListItemOtherOfferSuccessInfoType = {
    offer_idx: string;
    offer_price: number;
    offer_success_user_nick: string;
    offer_success_user_idx: string;
    offer_success_user_id: string;
};

export type AdDailyTimeSaleInfo = {
    buy_now_closetime_sale_before: number;
    buy_now_closetime_sale_bool: boolean;
    buy_now_closetime_sale_price: number;
};

export type AdDailyListItemStatusCodeType =
    | "notOpenedYet"
    | "onBid"
    | "inReview"
    | "bidClosed"
    | "preparing"
    | "onSchedule"
    | "expired"
    | "unknown"
    | "";

export const DEFAULT_ADVERTISEMENT_DAILY_LIST_ITEM_TYPE_DATA: AdDailyListItemType =
    {
        ad_sales_daily_status: "",
        template_idx: "",
        available_offer_price: 0,
        ad_daily_status_code: "",
        ad_daily_status_name: "",
        ad_price: 0,
        ad_sales_date: "",
        buyer_nick: "",
        buy_now_bool: false,
    };
