import { ReactElement, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";

export const OrderHistoryTabFilterHeaderItemContainer = styled.div`
    position: relative;
`;

export const OrderHistoryTabFilterHeaderItemButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #222222;
`;

export const MarketPointOrderHistoryTabHeaderColumnHasFilterIcon = styled.img`
    width: 8.5px;
    height: 12.5px;
    margin-left: 6px;
`;

export const OrderHistoryTabFilterHeaderItemMoreLayer = styled.div<{
    isOpen: boolean;
}>`
    position: absolute;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    top: 140%;
    max-height: ${({ isOpen }) => (isOpen ? "150px" : "0")};
    transition: max-height 300ms ease;

    left: -20px;
    z-index: 2;
    overflow: hidden;
`;

export const OrderHistoryTabFilterHeaderItemMoreLayerList = styled.div`
    padding: 10px;
`;

export const OrderHistoryTabFilterHeaderItemMoreLayerListItem = styled.div<{
    isSelected: boolean;
}>`
    font-weight: ${({ isSelected }) => (isSelected ? "700" : "500")};
    font-size: 16px;
    line-height: 16px;
    padding: 14px 10px;
    width: 132px;
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#222222")};
    cursor: pointer;
    text-align: left;
    border-radius: 6px;
    transition: background-color 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f0f2;
        }
    }
`;
type Props = {
    filterList: any[];
    selectedIdx: number;
    setSelectedIdx: (value: number) => void;
};

const OrderHistoryTabFilterHeaderItem = (props: Props): ReactElement | null => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <OrderHistoryTabFilterHeaderItemContainer>
            <OrderHistoryTabFilterHeaderItemButton
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                {props.filterList[props.selectedIdx].title}
                <MarketPointOrderHistoryTabHeaderColumnHasFilterIcon
                    src={URL_CONSTANTS.ASSET_URL + "/icon/icon-more.svg"}
                />
            </OrderHistoryTabFilterHeaderItemButton>
            <OrderHistoryTabFilterHeaderItemMoreLayer isOpen={isOpen}>
                <OrderHistoryTabFilterHeaderItemMoreLayerList>
                    {props.filterList.map((filter, filterIdx) => {
                        return (
                            <OrderHistoryTabFilterHeaderItemMoreLayerListItem
                                key={filterIdx}
                                isSelected={props.selectedIdx === filterIdx}
                                onClick={() => {
                                    setIsOpen(false);
                                    props.setSelectedIdx(filterIdx);
                                }}
                            >
                                {filter.title}
                            </OrderHistoryTabFilterHeaderItemMoreLayerListItem>
                        );
                    })}
                </OrderHistoryTabFilterHeaderItemMoreLayerList>
            </OrderHistoryTabFilterHeaderItemMoreLayer>
        </OrderHistoryTabFilterHeaderItemContainer>
    );
};

export default OrderHistoryTabFilterHeaderItem;
