import { ReactElement } from "react";
import styled from "styled-components";
import {
    TutorialInfoListType,
    TutorialSubCategoryInfoType,
} from "../../../TutorialModal";

export const TutorialSubCategoryContainer = styled.div<{
    isSelected: boolean;
}>`
    cursor: pointer;
    padding: 7px 20px;
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#555")};
    font-size: 15px;
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? "500" : "400")};
    line-height: 140%; /* 21px */
    transition: all 300ms ease;
    border-radius: 8px;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isSelected }) =>
                isSelected ? "transparent" : "rgba(13, 13, 26, 0.05);"};
        }
    }
`;

type Props = {
    subCategory: TutorialSubCategoryInfoType;
    selectSubCategory: (value: string) => void;
    setIsSelectOnMobile: (value: boolean) => void;
};

const TutorialSubCategory = (props: Props): ReactElement | null => {
    return (
        <TutorialSubCategoryContainer
            isSelected={props.subCategory.isSelected}
            onClick={() => {
                props.selectSubCategory(props.subCategory.key);
                props.setIsSelectOnMobile(true);
            }}
        >
            {props.subCategory.subCategoryTitle}
        </TutorialSubCategoryContainer>
    );
};

export default TutorialSubCategory;
