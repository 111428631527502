import { useInfiniteQuery } from "react-query";
import { TemplateListItemType } from "../../../types/ad/templateInfo/TemplateListItemType";
import { AdTemplateApi } from "../../../apis/adApi/AdTemplateApi";

export type AdTemplateListApiResultType = {
    templateList: TemplateListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type AdTemplateListApiParamsType = {
    [key: string]: any;
    page_no?: number;
    limit?: number;
    campaign_idx: string;
    template_status?: string;
};

export const useAdTemplateListQuery = (
    params: AdTemplateListApiParamsType,
    isEnabled = true,
) => {
    const queryResult = useInfiniteQuery<AdTemplateListApiResultType, Error>({
        queryKey: [
            "ad-template-list-" + JSON.stringify(params),
            "ad-template-list",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return AdTemplateApi.getTemplateList({
                ...params,
                page_no: pageParam,
            });
        },
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        onError: (err) => {},
        enabled: isEnabled,
    });
    const defaultData: AdTemplateListApiResultType[] = [
        { templateList: [], totalCount: 0 },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadAdTemplateListPages(
    pages: AdTemplateListApiResultType[],
) {
    return pages
        .map((result) => result.templateList)
        .reduce((prev, next) => prev.concat(next));
}
