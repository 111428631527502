import { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalInfoContainer,
} from "../../../../commonUnitComponent/modal/ModalStyle";
import {
    InfoTableColumnBoldValue,
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
    InfoTableSubRow,
} from "../../../../../../styles/InfoTableStyle";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import { useItemDataQuery } from "../../../../../../query/item/useItemDataQuery";
import { OrderItemReceiptInfoType } from "../OrderItemReceiptModal";

export const OrderItemReceiptBuyCompleteInfoContainer = styled.div`
    width: 100%;
`;

type Props = {
    receiptInfo: OrderItemReceiptInfoType;
    resolve: (value: boolean) => void;
};

const OrderItemReceiptBuyCompleteInfo = (props: Props): ReactElement | null => {
    const { data: rawItemData } = useItemDataQuery(
        props.receiptInfo.product_idx,
    );

    const itemBuyPriceAmount =
        props.receiptInfo.order_quantity * props.receiptInfo.order_unit_price;
    const itemBuyRoyalty =
        (props.receiptInfo.unit_brand_royalty +
            props.receiptInfo.unit_creator_royalty) *
        props.receiptInfo.order_quantity;

    return (
        <OrderItemReceiptBuyCompleteInfoContainer>
            <ModalInfoContainer width={"280px"} mt={"13px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        구매 수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_quantity,
                            )}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        구매 가격
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(itemBuyPriceAmount)}
                            <InfoTableColumnUnit>
                                {rawItemData.itemInfo.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        구매 로열티
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(itemBuyRoyalty)}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        <div></div>
                        *구매 로열티{" "}
                        {rawItemData.itemInfo.brand_royalty +
                            rawItemData.itemInfo.creator_royalty}
                        % 포함
                    </InfoTableSubRow>
                </InfoTableRowContainer>
            </ModalInfoContainer>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </ModalButton>
            </ModalButtonContainer>
        </OrderItemReceiptBuyCompleteInfoContainer>
    );
};

export default OrderItemReceiptBuyCompleteInfo;
