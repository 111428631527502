import { useAtom } from "jotai";
import hasHeaderAtom from "../../store/ui/hasHeaderAtom";

import { useEffect } from "react";
import { useSetStateOnWindowWidth } from "../common/useSetStateOnWindowWidth";

export function useMobileTopnavHide(isMobileTopnavShow = false) {
    const [, setHasHeader] = useAtom(hasHeaderAtom);

    useSetStateOnWindowWidth(
        (value: boolean) => {
            if (!isMobileTopnavShow) {
                setHasHeader(value);
                // window.scrollTo(0, 0);
            }
        },
        [true, true, true, true, true, false, false],
    );

    useEffect(() => {
        return () => {
            setHasHeader(true);
        };
    }, []);
}
