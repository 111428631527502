import { URL_CONSTANTS } from "../constants/UrlConstants";
import {
    TGlobalSearchInternalQueryParams,
    TGlobalSearchQueryParams,
} from "../query/search/getGlobalSearchQuery";
import { GlobalSearchResultType } from "../types/GlobalSearchResultDataType";
import axios from "axios";

/**
 * 2023/09/14 통합검색 관련 API
 */

export const GlobalSearchApi = {
    search: async (
        params: TGlobalSearchInternalQueryParams,
    ): Promise<GlobalSearchResultType> => {
        switch (params.tab) {
            case "ad":
                return GlobalSearchApi.searchAdProducts({
                    keyword: params.keyword,
                });

            case "collector":
                return GlobalSearchApi.searchCollectors({
                    keyword: params.keyword,
                });

            case "item":
                return GlobalSearchApi.searchItems({ keyword: params.keyword });

            case "project":
                return GlobalSearchApi.searchProjects({
                    keyword: params.keyword,
                });

            case "total":
            default:
                return GlobalSearchApi.searchAll({ keyword: params.keyword });
        }
    },

    // [전체] 탭 검색
    searchAll: async (
        params: TGlobalSearchQueryParams,
    ): Promise<GlobalSearchResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/global-search/all", {
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // [광고상품] 탭 검색
    searchAdProducts: async (
        params: TGlobalSearchQueryParams,
    ): Promise<GlobalSearchResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/global-search/ads/products", {
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // [프로젝트] 탭 검색
    searchProjects: async (
        params: TGlobalSearchQueryParams,
    ): Promise<GlobalSearchResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/global-search/projects", {
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // [아이템] 탭 검색
    searchItems: async (
        params: TGlobalSearchQueryParams,
    ): Promise<GlobalSearchResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/global-search/items", {
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // [컬렉터] 탭 검색
    searchCollectors: async (
        params: TGlobalSearchQueryParams,
    ): Promise<GlobalSearchResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/global-search/collectors", {
                    params: params,
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
