import { Query, useMutation } from "react-query";
import { ItemApi } from "../../../apis/ItemApi";
import { useAtom } from "jotai";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { queryClient } from "../../../App";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";

export function useCancelExamineItemMutation(itemIdx: string) {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(() => ItemApi.cancelExamineItem(itemIdx), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
        onSuccess: async () => {
            pushSnackBarListItem({
                title: "",
                desc: "아이템 출시 신청이 정상적으로 취소되었습니다.",
                iconUrl:
                    URL_CONSTANTS.ASSET_URL +
                    "/icon/icon-checked-in-circle.svg",
                confirmBtnTitle: "",
            });
            // 기존 project-list query 초기화
            await queryClient.resetQueries({
                predicate: (query: Query) =>
                    query.queryKey.includes("project-list") ||
                    query.queryKey.includes("item-data-" + itemIdx) ||
                    query.queryKey.includes("item-list"),
            });
        },
    });
}
