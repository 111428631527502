import { ReactElement } from "react";
import styled from "styled-components";
import SquareCheckBox from "../../../../../../../common/commonUnitComponent/checkbox/SquareCheckBox";
import { WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxLabel } from "../WalletCollectionHubHeader";
import { ChipType } from "../../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { getSelectedChip } from "../../../../../../../../utils/Utils";
import { useIsMe } from "../../../../../../../../hooks/common/useIsMe";

export const WalletCollectionIsOnlyShowGroupingCheckBoxContainer = styled.div``;

type Props = {
    isOnlyShowNotGrouping: boolean;
    setIsOnlyShowNotGrouping: (value: boolean) => void;
    groupChips: ChipType[];
    id: string;
    userIdx: string;
};

const WalletCollectionIsOnlyShowGroupingCheckBox = (
    props: Props,
): ReactElement | null => {
    const isMe = useIsMe();

    return getSelectedChip(props.groupChips) === "all" &&
        isMe(props.userIdx) ? (
        <SquareCheckBox
            id={"wallet-collection-not-grouping-show-check-box"}
            checked={props.isOnlyShowNotGrouping}
            onChange={() => {
                props.setIsOnlyShowNotGrouping(!props.isOnlyShowNotGrouping);
            }}
            labelComponent={
                <WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxLabel>
                    미분류 아이템만 보기
                </WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxLabel>
            }
        />
    ) : null;
};

export default WalletCollectionIsOnlyShowGroupingCheckBox;
