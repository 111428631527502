import React, { ReactElement } from "react";
import styled from "styled-components";
import Nickname from "../../commonUnitComponent/nickname/Nickname";
import { numberToStringWithComma } from "../../../../utils/Utils";
import IncludeIconButton from "../../commonUnitComponent/includeIconButton/IncludeIconButton";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useIsMe } from "../../../../hooks/common/useIsMe";
import { useFollowUnFollow } from "../../../../hooks/common/useFollowUnFollow";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";

export const HolderListItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 6px;
    border-radius: 6px;
    transition: background-color 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f0f2;
        }
    }

    cursor: pointer;
`;

export const HolderListItemUserInfoContainer = styled.div`
    display: flex;
`;

export const HolderListItemThumbnail = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
`;

export const HolderListItemInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const HolderListItemNickname = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    align-items: center;
    color: #222222;
`;

export const HolderListItemUserId = styled.div`
    font-weight: 400;
    font-size: 11px;
    line-height: 100%;
    color: #999999;
    align-items: center;
`;
export const HolderListItemCountTitle = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;

    color: #777777;
    margin-right: 4px;
`;
export const HolderListItemCount = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;

    display: flex;
    align-items: center;
    color: #222222;
`;

export const HolderListFollowButton = styled.div``;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    data: any;
    idx: number;
    refetchHolderList: () => void;
    followAfterAction?: () => Promise<void>;
};

const HolderListItem = (props: Props): ReactElement | null => {
    const isMe = useIsMe();
    const { followUnFollow } = useFollowUnFollow(
        props.data.user_idx,
        props.data.follow_bool,
        props.refetchHolderList,
        props.followAfterAction,
    );

    const title =
        "own_cnt" in props.data
            ? "아이템"
            : "follow_cnt" in props.data
            ? "팔로워"
            : "item_cnt" in props.data
            ? "아이템"
            : null;

    const data =
        "own_cnt" in props.data
            ? props.data.own_cnt
            : "follow_cnt" in props.data
            ? props.data.follow_cnt
            : "item_cnt" in props.data
            ? props.data.item_cnt
            : null;

    const unit =
        "own_cnt" in props.data
            ? "개"
            : "follow_cnt" in props.data
            ? "명"
            : "item_cnt" in props.data
            ? "종"
            : null;

    return (
        <HolderListItemContainer key={props.idx}>
            <HolderListItemUserInfoContainer>
                <HolderListItemThumbnail src={props.data.user_thumbnail_path} />
                <HolderListItemInfoContainer>
                    <Nickname
                        userIdx={props.data.user_idx}
                        nickname={props.data.user_nick}
                        nicknameStyledComponent={HolderListItemNickname}
                        isShowVerifiedIcon={true}
                        isVerified={
                            props.data.user_influencer_bool
                                ? props.data.user_influencer_bool
                                : false
                        }
                        tooltipPosition={props.idx === 0 ? "bottom" : "top"}
                        prevModalResolve={props.resolve}
                    />
                    <HolderListItemUserId>
                        {props.data.user_id}
                    </HolderListItemUserId>
                    <HolderListItemCount>
                        <HolderListItemCountTitle>
                            {data !== null
                                ? `${title} ${numberToStringWithComma(
                                      data,
                                  )}${unit}`
                                : null}
                        </HolderListItemCountTitle>
                    </HolderListItemCount>
                </HolderListItemInfoContainer>
            </HolderListItemUserInfoContainer>

            <HolderListFollowButton>
                {!isMe(props.data.user_idx) && (
                    <IncludeIconButton
                        isActive={props.data.follow_bool}
                        clickEventHandler={async (e) => {
                            await followUnFollow();
                        }}
                        activeIconColor={"#FA54FA"}
                        icon={URL_CONSTANTS.ASSET_URL + "/icon/icon-follow.svg"}
                        title={props.data.follow_bool ? "팔로잉" : "팔로우"}
                    />
                )}
            </HolderListFollowButton>
        </HolderListItemContainer>
    );
};

export default HolderListItem;
