import { ReactElement, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import Spinner from "../spinner/Spinner";

export const MoreButtonContainer = styled.div<{
    hasBorder: boolean;
    isShow: boolean;
    hasHoverEvent: boolean;
}>`
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #777777;
    height: 34px;
    width: 100%;
    border: ${({ hasBorder }) => (hasBorder ? "1px solid #ebebeb" : "none")};
    border-radius: 6px;
    display: ${({ isShow }) => (isShow ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    transition: all ease 300ms;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ hasHoverEvent }) =>
                hasHoverEvent ? "#F5F5F5" : "transparent"};
        }
    }
`;

export const MoreButtonIcon = styled.img`
    width: 8.5px;
    height: 12.5px;
    margin-left: 6px;
`;
type Props = {
    isShow: boolean;
    isLoading?: boolean;
    onClick: (value?: any) => void;
    isSupportFold?: boolean;
    hasHoverEvent?: boolean;
    hasBorder: boolean;
};

const MoreButton = (props: Props): ReactElement | null => {
    const [isFold, setIsFold] = useState(true);

    return (
        <MoreButtonContainer
            hasBorder={props.hasBorder}
            hasHoverEvent={props.hasHoverEvent ? props.hasHoverEvent : true}
            isShow={props.isShow}
            onClick={
                !props.isSupportFold
                    ? props.onClick
                    : () => {
                          props.onClick();
                          setIsFold(!isFold);
                      }
            }
        >
            {props.isLoading ? (
                <Spinner width={20} height={20} />
            ) : isFold ? (
                "더 보기"
            ) : (
                "접기"
            )}
            {!props.isLoading && (
                <MoreButtonIcon
                    src={
                        !props.isSupportFold
                            ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-more.svg`
                            : isFold
                            ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-more.svg`
                            : `${URL_CONSTANTS.ASSET_URL}/icon/icon-more-reverse.svg`
                    }
                />
            )}
        </MoreButtonContainer>
    );
};

export default MoreButton;

MoreButton.defaultProps = {
    isShow: true,
    isSupportFold: false,
    hasHoverEvent: true,
    hasBorder: true,
    onClick: () => {
        //
    },
};
