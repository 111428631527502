import styled, { css, keyframes } from "styled-components";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";

export const CommonPageContentContainer = styled.div<{
    hasSubHeader?: boolean;
}>`
    box-sizing: border-box;
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    min-height: ${({ hasSubHeader }) =>
        hasSubHeader ? "calc(100vh - 544px)" : "calc(100vh - 498px)"};

    @media ${(props) => props.theme.PCM} {
        width: calc(100% - 64px);
        margin-left: 32px;
        margin-right: 32px;
    }

    @media ${(props) => props.theme.tabletL} {
        width: calc(100% - 64px);
        margin-left: 32px;
        margin-right: 32px;
    }

    @media ${(props) => props.theme.tabletM} {
        width: calc(100% - 64px);
        margin-left: 32px;
        margin-right: 32px;
        min-height: ${({ hasSubHeader }) =>
            hasSubHeader ? "calc(100vh - 767px)" : "calc(100vh - 721px)"};
    }

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        min-height: ${({ hasSubHeader }) =>
            hasSubHeader ? "calc(100vh - 787px)" : "calc(100vh - 741px)"};
    }

    @media ${(props) => props.theme.mobileM} {
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        min-height: ${({ hasSubHeader }) =>
            hasSubHeader ? "calc(100vh - 926px)" : "calc(100vh - 880px)"};
    }
`;

export const CommonBoxHoverEffect = css`
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            transform: translateY(-8px);
        }
    }
    transition: transform 150ms ease-in-out;
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const CommonImgLoadingSpinner = css<{
    isLoading: boolean;
}>`
    animation: ${spin} 1s ease-in-out infinite;
    ${({ isLoading }) => (!isLoading ? "animation: none" : "")};
    content: transparent
        url(${URL_CONSTANTS.ASSET_URL + "/icon/icon-spinner.svg"}) no-repeat
        scroll center center !important;
`;
