import { addMonths, addWeeks } from "date-fns";
import { ReactElement, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import CalendarMonthPickerLayer from "./CalendarMonthPickerLayer";

export const CalendarMonthPickerWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;

    @media ${(props) => props.theme.mobileL} {
        justify-content: flex-start;
    }
`;

export const CalendarMonthPickerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 34px;
    padding: 0 10px;
    border-radius: 8px;
    transition: all 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 13, 26, 0.06);
        }
    }
`;

export const CalendarMonthPickerDateYear = styled.div`
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 22px */
    display: flex;
    gap: 8px;

    @media ${(props) => props.theme.mobileL} {
        font-size: 18px;
    }
`;

export const CalendarMonthPickerMonth = styled.div`
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 22px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 18px;
    }
`;

export const CalendarMonthPickerDateMoreBtn = styled.img`
    width: 10px;
    height: 16px;
`;

export const CalendarMonthPickerArrowContainer = styled.div`
    width: 36px;
    height: 36px;
    border: #e5e5e5 1px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: all 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 13, 26, 0.06);
        }
    }

    @media ${(props) => props.theme.mobileL} {
        width: 32px;
        height: 32px;
    }
`;

export const CalendarMonthPickerArrowIcon = styled.img`
    width: 8px;
    height: 12px;
`;

export const CalendarMonthPickerTodayBtnContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const CalendarMonthPickerTodayBtn = styled.div`
    height: 34px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 17px;
    border: 1px solid #ebebeb;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
    cursor: pointer;

    transition: all 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 13, 26, 0.06);
        }
    }

    @media ${(props) => props.theme.mobileL} {
        font-size: 13px;
        height: 32px;
    }
`;

type Props = {
    selectedDate: Date;
    setSelectedDate: (value: Date) => void;
    displayingDate: Date;
    setDisplayingDate: (value: Date) => void;
    isShowOnlyWeek: boolean;
};

const CalendarMonthPicker = (props: Props): ReactElement | null => {
    const changeMonth = (value: number) => {
        if (props.isShowOnlyWeek) {
            props.setDisplayingDate(addWeeks(props.displayingDate, value));
        } else {
            props.setDisplayingDate(addMonths(props.displayingDate, value));
        }
    };
    const [isShowSelectLayer, setIsShowSelectLayer] = useState(false);

    return (
        <CalendarMonthPickerWrapper>
            <CalendarMonthPickerContainer
                onClick={() => {
                    setIsShowSelectLayer(true);
                }}
            >
                <CalendarMonthPickerDateYear>
                    {props.displayingDate.getFullYear()}년
                    <CalendarMonthPickerMonth>
                        {props.displayingDate.getMonth() + 1}월
                    </CalendarMonthPickerMonth>
                </CalendarMonthPickerDateYear>

                <CalendarMonthPickerDateMoreBtn
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-more.svg`}
                />
            </CalendarMonthPickerContainer>

            <CalendarMonthPickerLayer
                displayingDate={props.displayingDate}
                setDisplayingDate={props.setDisplayingDate}
                isShowSelectLayer={isShowSelectLayer}
                setIsShowSelectLayer={setIsShowSelectLayer}
            />

            <CalendarMonthPickerTodayBtnContainer>
                <CalendarMonthPickerTodayBtn
                    onClick={() => {
                        props.setDisplayingDate(new Date());
                        props.setSelectedDate(new Date());
                    }}
                >
                    오늘
                </CalendarMonthPickerTodayBtn>

                <CalendarMonthPickerArrowContainer
                    onClick={() => {
                        changeMonth(-1);
                    }}
                >
                    <CalendarMonthPickerArrowIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-right.svg`}
                        style={{
                            transform: "rotate(180deg)",
                        }}
                    />
                </CalendarMonthPickerArrowContainer>

                <CalendarMonthPickerArrowContainer
                    onClick={() => {
                        changeMonth(1);
                    }}
                >
                    <CalendarMonthPickerArrowIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-right.svg`}
                    />
                </CalendarMonthPickerArrowContainer>
            </CalendarMonthPickerTodayBtnContainer>
        </CalendarMonthPickerWrapper>
    );
};

export default CalendarMonthPicker;
