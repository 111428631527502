import { useLayoutEffect, useState } from "react";
import { ResponsiveWidth } from "../../styles/Theme";
import { useWindowSize } from "usehooks-ts";

/**
 * 윈도우 사이즈 별로 state set 하기
 * 0: 1920 이상
 * 1: 1440 이상
 * 2: 1280 이상
 * 3: 1024 이상
 * 4: 720 이상
 * 5: 500 이상
 * 6: 500 미만
 * @param setState
 * @param values
 */
export function useSetStateOnWindowWidth(
    setState: (value: any) => void,
    values: any[],
) {
    const { width } = useWindowSize();
    const [isSetDone, setIsSetDone] = useState(false);
    useLayoutEffect(() => {
        if (width !== 0) {
            if (
                width >= ResponsiveWidth.PCL &&
                typeof values[0] !== "undefined"
            ) {
                setState(values[0]);
            }
            if (
                width < ResponsiveWidth.PCL &&
                width >= ResponsiveWidth.PCM &&
                typeof values[1] !== "undefined"
            ) {
                setState(values[1]);
            }
            if (
                width < ResponsiveWidth.PCM &&
                width >= ResponsiveWidth.tabletL &&
                typeof values[2] !== "undefined"
            ) {
                setState(values[2]);
            }
            if (
                width < ResponsiveWidth.tabletL &&
                width >= ResponsiveWidth.tabletM &&
                typeof values[3] !== "undefined"
            ) {
                setState(values[3]);
            }
            if (
                width < ResponsiveWidth.tabletM &&
                width >= ResponsiveWidth.mobileL &&
                typeof values[4] !== "undefined"
            ) {
                setState(values[4]);
            }
            if (
                width < ResponsiveWidth.mobileL &&
                width >= ResponsiveWidth.mobileM &&
                typeof values[5] !== "undefined"
            ) {
                setState(values[5]);
            }
            if (
                width < ResponsiveWidth.mobileM &&
                typeof values[6] !== "undefined"
            ) {
                setState(values[6]);
            }
            setIsSetDone(true);
        }
    }, [width]);

    return { isOnMountedSetDone: isSetDone };
}
