import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FooterPolicy } from "../../../../../constants/FooterDatas";

export const FooterPolicyListContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media ${(props) => props.theme.tabletM} {
        gap: 10px;
    }
`;

export const FooterPolicyListItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media ${(props) => props.theme.tabletM} {
        gap: 10px;
    }
`;

export const FooterPolicyListItemLink = styled(Link)`
    color: #333;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */

    @media ${(props) => props.theme.mobileM} {
        font-size: 11px;
    }
`;

export const FooterPolicyListItemBorder = styled.div`
    height: 12px;
    width: 1px;
    background: #d9d9d9;
`;

type Props = {};

const FooterPolicyList = (props: Props): ReactElement | null => {
    return (
        <FooterPolicyListContainer>
            {FooterPolicy.map((data, idx) => {
                return (
                    <FooterPolicyListItemContainer key={idx}>
                        <FooterPolicyListItemLink
                            target={"_blank"}
                            rel="noopener noreferrer"
                            to={data.path}
                        >
                            {data.title}
                        </FooterPolicyListItemLink>
                        {idx !== FooterPolicy.length - 1 && (
                            <FooterPolicyListItemBorder />
                        )}
                    </FooterPolicyListItemContainer>
                );
            })}
        </FooterPolicyListContainer>
    );
};

export default FooterPolicyList;
