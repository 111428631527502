import React, { RefObject } from "react";
import { toFit } from "../../../utils/Utils";
import { useLocation } from "react-router-dom";

const createScrollStopListener = (
    elementRef: RefObject<HTMLElement>,
    onScrollCallback: (e: any) => void,
    onScrollEndCallback: (e: any) => void,
    timeout: number,
) => {
    let removed = false;
    let handle: ReturnType<typeof setTimeout> | null = null;

    const onScroll = (e: Event) => {
        toFit(() => {
            onScrollCallback(e);
        })();
        if (handle) {
            clearTimeout(handle);
        }
        handle = setTimeout(() => {
            onScrollEndCallback(e);
        }, timeout || 200); // default 200 ms
    };
    if (elementRef.current) {
        elementRef.current.addEventListener("scroll", onScroll);
    }
    return () => {
        if (removed) {
            return;
        }
        removed = true;
        if (handle) {
            clearTimeout(handle);
        }
        if (elementRef.current) {
            elementRef.current.removeEventListener("scroll", onScroll);
        }
    };
};

export const useScrollEvents = (
    elementRef: RefObject<HTMLElement>,
    onScrollCallback: (e: any) => void,
    onScrollEndCallback: (e: any) => void,
    timeout: number,
) => {
    const location = useLocation();
    React.useEffect(() => {
        const destroyListener = createScrollStopListener(
            elementRef,
            onScrollCallback,
            onScrollEndCallback,
            timeout,
        );
        return () => destroyListener();
    }, [location]);
};
