import { ReactElement } from "react";
import styled from "styled-components";
import Checkbox from "../checkbox/Checkbox";
import { Link } from "react-router-dom";
import { isAllChecked } from "../../../../utils/Utils";

export const TermsContainer = styled.div``;

export const TermsAllAgreeRow = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    height: 40px;
    display: flex;
    align-items: center;
    /* identical to box height, or 88% */

    color: #333333;
`;

export const TermsAllAgreeLabel = styled.div`
    margin-left: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    /* identical to box height, or 88% */

    display: flex;
    align-items: center;

    color: #333333;
`;

export const TermsAllAgreeUnderLine = styled.div`
    border-bottom: 1px solid #ebebeb;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const TermsAgreeList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TermsAgreeRowContainer = styled.div``;

export const TermsAgreeRow = styled.div`
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ChildrenTermsAgreeRow = styled.div`
    margin-left: 40px;
    padding: 4px 0;
`;

export const TermsAgreeLabel = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 12px;
    color: #333333;
    padding: 6px 0;
`;

export const ChildrenTermsAgreeLabel = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    margin-left: 2px;
    color: #666666;
`;

export const LinkOutTerm = styled(Link)`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    border-bottom: #888888 1px solid;
    color: #888888;
    flex-shrink: 0;
    margin-left: 4px;
`;

export const LinkInTerm = styled(Link)`
    color: #333333;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        color: #333333;
        text-decoration: underline;
    }
`;

export const TermDescContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 44px;
    margin-top: -5px;
`;

export const TermDesc = styled.div`
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
`;

export type TermType = {
    label: string | ReactElement;
    isMust: boolean;
    state: boolean;
    link?: string;
    childrenTerm?: TermType[];
    id?: string;
    additionalChangeHandler?: (isSelectedAfterClick: boolean) => void;
    descList?: string[];
};

type Props = {
    termsList: TermType[];
    setTermsList: (value: TermType[]) => void;
    allAgreeMsg?: string;
};

const Terms = (props: Props): ReactElement | null => {
    return (
        <TermsContainer>
            <TermsAllAgreeRow>
                <Checkbox
                    id={"bid__term__all__check-box"}
                    checked={isAllChecked(props.termsList, false)}
                    onChange={() => {
                        const tempIsAllChecked = isAllChecked(
                            props.termsList,
                            false,
                        );
                        props.setTermsList(
                            props.termsList.map((term, idx) => {
                                if (term.childrenTerm) {
                                    return {
                                        ...term,
                                        state: !tempIsAllChecked,
                                        childrenTerm: term.childrenTerm.map(
                                            (subTerm) => {
                                                return {
                                                    ...subTerm,
                                                    state: !subTerm.state,
                                                };
                                            },
                                        ),
                                    };
                                }
                                return {
                                    ...term,
                                    state: !tempIsAllChecked,
                                };
                            }),
                        );
                    }}
                    labelComponent={
                        <TermsAllAgreeLabel>
                            {props.allAgreeMsg
                                ? props.allAgreeMsg
                                : "모두 동의합니다."}
                        </TermsAllAgreeLabel>
                    }
                />
            </TermsAllAgreeRow>
            <TermsAllAgreeUnderLine />
            <TermsAgreeList>
                {props.termsList.map((term, idx) => {
                    return (
                        <TermsAgreeRowContainer key={idx}>
                            <TermsAgreeRow>
                                <Checkbox
                                    id={"bid__term__check-box__" + idx}
                                    checked={
                                        term.childrenTerm
                                            ? isAllChecked(
                                                  term.childrenTerm,
                                                  false,
                                              )
                                            : term.state
                                    }
                                    onChange={() => {
                                        props.setTermsList(
                                            props.termsList.map(
                                                (term, innerIdx) => {
                                                    if (idx === innerIdx) {
                                                        if (term.childrenTerm) {
                                                            return {
                                                                ...term,
                                                                state: !term.state,
                                                                childrenTerm:
                                                                    term.childrenTerm.map(
                                                                        (
                                                                            subTerm,
                                                                        ) => {
                                                                            if (
                                                                                subTerm.additionalChangeHandler
                                                                            ) {
                                                                                subTerm.additionalChangeHandler(
                                                                                    !term.state,
                                                                                );
                                                                            }

                                                                            return {
                                                                                ...subTerm,
                                                                                state: !term.state,
                                                                            };
                                                                        },
                                                                    ),
                                                            };
                                                        }
                                                        return {
                                                            ...term,
                                                            state: !term.state,
                                                        };
                                                    }

                                                    return term;
                                                },
                                            ),
                                        );
                                    }}
                                    labelComponent={
                                        <TermsAgreeLabel>
                                            {term.isMust ? "[필수] " : ""}
                                            {term.label}
                                        </TermsAgreeLabel>
                                    }
                                />
                                {term.link && (
                                    <LinkOutTerm
                                        rel="noopener noreferrer"
                                        to={term.link}
                                        target={"_blank"}
                                    >
                                        내용 보기
                                    </LinkOutTerm>
                                )}
                            </TermsAgreeRow>
                            {term.childrenTerm &&
                                term.childrenTerm.map((subTerm, subTermIdx) => {
                                    return (
                                        <ChildrenTermsAgreeRow
                                            key={`${idx}-${subTermIdx}`}
                                        >
                                            <Checkbox
                                                id={`bid__sub-term__check-box__${idx}__${subTermIdx}`}
                                                isBorder={false}
                                                checked={subTerm.state}
                                                onChange={() => {
                                                    props.setTermsList(
                                                        props.termsList.map(
                                                            (
                                                                term,
                                                                innerIdx,
                                                            ) => {
                                                                if (
                                                                    idx ===
                                                                        innerIdx &&
                                                                    term.childrenTerm
                                                                ) {
                                                                    return {
                                                                        ...term,
                                                                        childrenTerm:
                                                                            term.childrenTerm.map(
                                                                                (
                                                                                    childrenTerm,
                                                                                    childrenIdx,
                                                                                ) => {
                                                                                    if (
                                                                                        childrenIdx ===
                                                                                        subTermIdx
                                                                                    ) {
                                                                                        if (
                                                                                            childrenTerm.additionalChangeHandler
                                                                                        ) {
                                                                                            childrenTerm.additionalChangeHandler(
                                                                                                !childrenTerm.state,
                                                                                            );
                                                                                        }

                                                                                        return {
                                                                                            ...childrenTerm,
                                                                                            state:
                                                                                                childrenIdx ===
                                                                                                subTermIdx
                                                                                                    ? !childrenTerm.state
                                                                                                    : childrenTerm.state,
                                                                                        };
                                                                                    }

                                                                                    return {
                                                                                        ...childrenTerm,
                                                                                        state:
                                                                                            childrenIdx ===
                                                                                            subTermIdx
                                                                                                ? !childrenTerm.state
                                                                                                : childrenTerm.state,
                                                                                    };
                                                                                },
                                                                            ),
                                                                        state: isAllChecked(
                                                                            term.childrenTerm,
                                                                            false,
                                                                        ),
                                                                    };
                                                                }
                                                                return term;
                                                            },
                                                        ),
                                                    );
                                                }}
                                                labelComponent={
                                                    <ChildrenTermsAgreeLabel>
                                                        {term.isMust
                                                            ? "[필수] "
                                                            : ""}
                                                        {subTerm.label}
                                                    </ChildrenTermsAgreeLabel>
                                                }
                                            />
                                        </ChildrenTermsAgreeRow>
                                    );
                                })}
                            {term.descList && (
                                <TermDescContainer>
                                    {term.descList.map((desc, descIdx) => {
                                        return (
                                            <TermDesc key={descIdx}>
                                                {desc}
                                            </TermDesc>
                                        );
                                    })}
                                </TermDescContainer>
                            )}
                        </TermsAgreeRowContainer>
                    );
                })}
            </TermsAgreeList>
        </TermsContainer>
    );
};

export default Terms;
