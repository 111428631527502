import React, { ReactElement, useEffect, useState } from "react";
import ScrollBars from "../../common/commonUiComponent/scrollbar/Scrollbar";
import styled, { css } from "styled-components";
import DoubleSideBarMainMixedContentsLayout from "./DoubleSideBarMainMixedContentsLayout";
import Footer from "../../common/commonUiComponent/footer/Footer";
import { CommonPageContentContainer } from "../../common/commonStyle/CommonStyle";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../store/scroll/viewHeightAtom";
import { checkUserAgent } from "../../../utils/Utils";
import MobileLeftSideBar from "../../common/commonUiComponent/doubleSideBar/leftSideBar/components/MobileLeftSideBar";
import { ResponsiveWidth } from "../../../styles/Theme";
import { useSetIsOverWindowWidthTarget } from "../../../hooks/ui/useSetIsOverWindowWidthTarget";
import { SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS } from "../../common/commonUiComponent/scrollbar/ScrollbarType";

export const OuterWrapper = styled.div`
    width: 100%;
    font-size: 20px;
    overflow: hidden;
`;

export const OuterContainer = styled.div`
    width: 1920px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    background: white;

    @media ${(props) => props.theme.PCL} {
        width: 100%;
    }
`;

const SideBar = styled.div`
    position: absolute;
    max-width: 320px;
    width: 16.67%;
    height: 100%;
    top: 0;
    -webkit-transform: translate3d(0, 0, 0); // safari scroll 잔상 이슈

    border-left: 1px #ebebeb solid;
    border-right: 1px #ebebeb solid;
    background: #ffffff;

    @media ${(props) => props.theme.PCL} {
        min-width: 280px;
    }

    @media ${(props) => props.theme.PCM} {
        transition: all 300ms ease;
        width: 22.24%;
    }

    @media ${(props) => props.theme.tabletL} {
        height: 100%;
        position: fixed;
        top: 60px;
        z-index: 2;
    }

    @media ${(props) => props.theme.tabletM} {
        display: none;
    }

    box-sizing: border-box;
`;
export const LeftSideBarSection = styled(SideBar)`
    z-index: 2;
`;

export const RightSideBarSection = styled(SideBar)`
    right: 0;
    @media ${(props) => props.theme.PCM} {
        display: none;
    }
`;

export const ContentsSection = styled.section<{
    isOnlyLeft: boolean;
    vh: number;
    isMobileDevice: boolean;
}>`
    margin-left: ${({ isOnlyLeft }) => (isOnlyLeft ? "0" : "auto")};
    margin-right: ${({ isOnlyLeft }) => (isOnlyLeft ? "0" : "auto")};
    float: ${({ isOnlyLeft }) => (isOnlyLeft ? "right" : "none")};
    width: ${({ isOnlyLeft }) => (isOnlyLeft ? "83.33%" : "66.66%")};
    height: ${({ vh }) => `calc(${100 * vh}px - 80px)`};
    position: relative;
    background: white;

    @media ${(props) => props.theme.PCL} {
        max-width: ${({ isOnlyLeft }) =>
            isOnlyLeft ? "calc(100% - 280px)" : "calc(100% - 560px)"};
    }

    @media ${(props) => props.theme.PCM} {
        width: 77.75%;
        margin: 0;
        ${({ isMobileDevice }) => {
            if (isMobileDevice) return `height: 100%`;
        }};
        max-width: calc(100% - 280px);
        float: right;
    }

    @media ${(props) => props.theme.tabletL} {
        ${({ isMobileDevice, vh }) => {
            if (isMobileDevice) return `height: 100%`;
            else return `height: calc(${100 * vh}px - 60px)`;
        }};
        max-width: 100%;
        width: calc(100% - 280px);
    }

    @media ${(props) => props.theme.tabletM} {
        width: 100%;
        // mobile left sidebar button 보다 아래에 뜨게 해야함 z-index 지정해야함
        z-index: 0;
    }
`;

export const NormalContentsContainer = css`
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 48px;
    padding-bottom: 121px;

    @media ${(props) => props.theme.PCL} {
        margin-left: 5%;
        margin-right: 5%;
        max-width: none;
        width: 90%;
    }

    @media ${(props) => props.theme.PCM} {
        margin-left: 32px;
        margin-right: 32px;
        width: auto;
    }

    @media ${(props) => props.theme.tabletM} {
        padding-top: 80px;
        padding-bottom: 64px;
    }

    @media ${(props) => props.theme.mobileL} {
        margin-left: 16px;
        margin-right: 16px;
        padding-bottom: 80px;
        padding-top: 0;
    }
`;

const FullContentsContainer = css``;

export const ContentsContainer = styled.div<{ isFull: boolean }>`
    -webkit-transform: translate3d(0, 0, 0); // safari scroll 잔상 이슈
    ${({ isFull }) => {
        if (isFull) return FullContentsContainer;
        else return NormalContentsContainer;
    }};
`;

export const FooterWrapper = styled.div<{ hasBottomFixedButton: boolean }>`
    background-color: #fafafa;
    width: 100%;

    @media ${(props) => props.theme.tabletM} {
        padding-bottom: ${({ hasBottomFixedButton }) =>
            hasBottomFixedButton ? "30px" : 0};
    }
`;
export const FooterContainer = styled(CommonPageContentContainer)`
    padding-top: 40px;
    max-width: 1280px;
    @media ${(props) => props.theme.PCL} {
        max-width: 1080px;
    }
`;

export const MobileBottomFixedButtonContainer = styled.div`
    position: relative;
    z-index: 1;
`;

export const MobileBottomFixedLeftSideBar = styled.div`
    position: relative;
`;

export const MainNormalContentsContainer = styled.div``;

type Props = {
    renderLeftSideBarComponent: () => ReactElement;
    renderRightSideBarComponent?: () => ReactElement;
    // main contents 한개 인 경우
    renderMainNormalContentsComponent?: () => ReactElement;
    // main contents 나눠지는 경우
    renderMainStickyContentsComponent?: () => ReactElement;
    renderMainScrolledContentsComponent?: () => ReactElement;
    renderSubContentsComponent?: () => ReactElement;
    renderSubTabComponent?: () => ReactElement;
    // 모바일쪽 위해서 따로 렌더필요한 컴포넌트
    renderMobileBottomFixedButton?: () => ReactElement;
    isRenderMobileBottomFixedLeftSideBar: boolean;
    sideBarTitle: string;
    type: string;
};
const DoubleSideBarLayout = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const isMobileAndTouchDevice =
        checkUserAgent.isMobileDevice() || checkUserAgent.isTouchDevice();

    const [isOverWindowWidthTabletM, setIsOverWindowWidthTabletM] =
        useState(true);

    useSetIsOverWindowWidthTarget(
        setIsOverWindowWidthTabletM,
        ResponsiveWidth.tabletM,
    );

    useEffect(() => {
        const handleResize = () => {
            window.innerWidth;
            if (window.innerWidth >= ResponsiveWidth.tabletM) {
                setIsOverWindowWidthTabletM(true);
            } else {
                setIsOverWindowWidthTabletM(false);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <OuterWrapper id={props.type}>
            <OuterContainer>
                <ContentsSection
                    vh={vh}
                    isOnlyLeft={props.type.indexOf("only-left") !== -1}
                    isMobileDevice={
                        checkUserAgent.isMobileDevice() ||
                        checkUserAgent.isTouchDevice()
                    }
                >
                    <ScrollBars
                        type={SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS}
                        id={"double-side-bar-layout-contents"}
                        isSaveScrollTop={true}
                    >
                        <>
                            <ContentsContainer
                                isFull={props.type === "main-normal-only-left"}
                            >
                                {props.type === "main-mixed" &&
                                    typeof props.renderMainStickyContentsComponent !==
                                        "undefined" &&
                                    typeof props.renderMainScrolledContentsComponent !==
                                        "undefined" &&
                                    typeof props.renderSubContentsComponent !==
                                        "undefined" &&
                                    typeof props.renderSubTabComponent !==
                                        "undefined" && (
                                        <DoubleSideBarMainMixedContentsLayout
                                            renderMainStickyContentsComponent={
                                                props.renderMainStickyContentsComponent
                                            }
                                            renderSubTabComponent={
                                                props.renderSubTabComponent
                                            }
                                            renderMainScrolledContentsComponent={
                                                props.renderMainScrolledContentsComponent
                                            }
                                            renderSubContentsComponent={
                                                props.renderSubContentsComponent
                                            }
                                        />
                                    )}
                                {props.type.indexOf("main-normal") !== -1 &&
                                    props.renderMainNormalContentsComponent && (
                                        <MainNormalContentsContainer>
                                            {props.renderMainNormalContentsComponent()}
                                        </MainNormalContentsContainer>
                                    )}
                            </ContentsContainer>
                            <FooterWrapper
                                hasBottomFixedButton={
                                    typeof props.renderMobileBottomFixedButton !==
                                    "undefined"
                                }
                            >
                                <Footer type={"doubleSideBar"} />
                            </FooterWrapper>
                        </>
                    </ScrollBars>
                </ContentsSection>
                {isOverWindowWidthTabletM && (
                    <LeftSideBarSection>
                        {props.renderLeftSideBarComponent()}
                    </LeftSideBarSection>
                )}
                {typeof props.renderRightSideBarComponent !== "undefined" && (
                    <RightSideBarSection>
                        {props.renderRightSideBarComponent()}
                    </RightSideBarSection>
                )}
            </OuterContainer>
            {props.isRenderMobileBottomFixedLeftSideBar && (
                <MobileBottomFixedLeftSideBar>
                    <MobileLeftSideBar
                        renderLeftSideBarComponent={
                            props.renderLeftSideBarComponent
                        }
                        title={props.sideBarTitle}
                    />
                </MobileBottomFixedLeftSideBar>
            )}

            {typeof props.renderMobileBottomFixedButton !== "undefined" && (
                <MobileBottomFixedButtonContainer>
                    {props.renderMobileBottomFixedButton()}
                </MobileBottomFixedButtonContainer>
            )}
        </OuterWrapper>
    );
};

export default DoubleSideBarLayout;

DoubleSideBarLayout.defaultProps = {
    renderLeftSideBarComponent: (value: any) => <>test</>,
    renderMainStickyContentsComponent: (value: any) => <>test</>,
    renderMainScrolledContentsComponent: (value: any) => <>test</>,
    renderSubContentsComponent: (value: any) => <>test</>,
    renderSubTabComponent: (value: any) => <>test</>,
    renderMobileBottomFixedLeftSideBar: (value: any) => <>test</>,
    type: "main-normal",
};
