import { useInfiniteQuery } from "react-query";
import { MarketItemApi } from "../../../apis/market/MarketItemApi";

export type ProjectExchangeListApiResultType = {
    exchangeList: ProjectExchangeListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type ProjectExchangeListItemType = {
    item_idx: string;
    item_name: string;
    token_id: number;
    thumbnail_full_path: string;
    exchange_type: string;
    exchange_type_description: string;
    exchange_type_detail: string;
    from_user_idx: string;
    to_user_idx: string;
    settled_unit_price: number;
    settled_quantity: string;
    created_at: string;
    settlement_idx: string;
    receive_user_nick: string;
    send_user_nick: string;
};

export type ProjectExchangeListParamsType = {
    limit: number;
    page_no: number;
    filter_type: string;
    sort_type: string;
    exchanged_at_to: string;
    exchanged_at_from: string;
};

export const useGetProjectExchangeList = (
    projectIdx: string,
    params: ProjectExchangeListParamsType,
) => {
    const queryResult = useInfiniteQuery<
        ProjectExchangeListApiResultType,
        Error
    >({
        queryKey: [
            "project-exchange-list-" + projectIdx + JSON.stringify(params),
        ],
        queryFn: ({ pageParam = 1 }) =>
            MarketItemApi.getProjectExchangeList(projectIdx, {
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        refetchOnWindowFocus: false,
        cacheTime: 60000,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: ProjectExchangeListApiResultType[] = [
        {
            exchangeList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadProjectExchangeList(
    pages: ProjectExchangeListApiResultType[],
) {
    return pages
        .map((result) => result.exchangeList)
        .reduce((prev, next) => prev.concat(next));
}
