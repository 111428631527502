import { ReactElement } from "react";
import styled from "styled-components";
import SquareCheckBox from "../../../../../../../../common/commonUnitComponent/checkbox/SquareCheckBox";
import { ItemInventoryBelongingGroupListItemType } from "../../../../../../../../../types/itemInventory/ItemInventoryBelongingGroupListItemType";

export const WalletCollectionGroupMoveCheckListItemContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
    cursor: pointer;
`;

export const WalletCollectionGroupMoveCheckListItemLabel = styled.div<{
    isChecked: boolean;
}>`
    width: 100%;
    margin-left: 8px;
    color: ${({ isChecked }) => (isChecked ? "#FA54FA" : "#555")};
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ isChecked }) => (isChecked ? "700" : "500")};
    line-height: 100%; /* 16px */
`;

type Props = {
    itemInventoryGroupCheckListItem: ItemInventoryBelongingGroupListItemType;
    changeCheckListItem: () => void;
};

const WalletCollectionGroupMoveCheckListItem = (
    props: Props,
): ReactElement | null => {
    return (
        <WalletCollectionGroupMoveCheckListItemContainer
            onClick={(e) => {
                props.changeCheckListItem();
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <SquareCheckBox
                checked={
                    props.itemInventoryGroupCheckListItem
                        .item_inventory_group_sub_exist
                }
                id={`item-inventory-group-check-list-item-${props.itemInventoryGroupCheckListItem.item_inventory_group_idx}`}
                labelComponent={
                    <WalletCollectionGroupMoveCheckListItemLabel
                        isChecked={
                            props.itemInventoryGroupCheckListItem
                                .item_inventory_group_sub_exist
                        }
                    >
                        {
                            props.itemInventoryGroupCheckListItem
                                .item_inventory_group_name
                        }
                    </WalletCollectionGroupMoveCheckListItemLabel>
                }
                onChange={() => {}}
            />
        </WalletCollectionGroupMoveCheckListItemContainer>
    );
};

export default WalletCollectionGroupMoveCheckListItem;
