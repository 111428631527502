import { getIsLogin } from "../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../modal/openModal/useOpenLoginConfirmModal";
import { useLikeMutation } from "../../query/like/useLikeMutation";
import { useUnLikeMutation } from "../../query/like/useUnLikeMutation";
import { useAtom } from "jotai";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";
import { queryClient } from "../../App";
import { Query } from "react-query";

export function useLikeUnLike(
    type: string,
    targetIdx: string,
    isLike: boolean,
) {
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const { mutateAsync: useLike, isLoading: isLiking } = useLikeMutation({
        type: type,
        targetIdx: targetIdx,
    });
    const { mutateAsync: useUnLike, isLoading: isUnLiking } = useUnLikeMutation(
        {
            type: type,
            targetIdx: targetIdx,
        },
    );

    const showSnackbar = (isLike: boolean) => {
        pushSnackBarListItem({
            title: "",
            desc: isLike ? "좋아요 취소 처리중입니다" : "좋아요 처리중입니다",
            iconUrl: "",
            confirmBtnTitle: "",
        });
    };

    const likeUnLike = async () => {
        if (getIsLogin()) {
            try {
                if (isLike) {
                    if (!isUnLiking) {
                        await useUnLike();
                    } else {
                        showSnackbar(isLike);
                    }
                } else {
                    if (!isLiking) {
                        await useLike();
                    } else {
                        showSnackbar(isLike);
                    }
                }
                const convertedType = type === "item" ? "project" : type;
                await queryClient.resetQueries({
                    predicate: (query: Query) =>
                        query.queryKey.includes(
                            `${convertedType}-list-liked`,
                        ) ||
                        query.queryKey.includes(`${convertedType}-list-like`),
                });
            } catch (e) {
                console.info(e);
            }
        } else {
            await openLoginConfirmModal();
        }
    };

    return {
        likeUnLike,
    };
}
