import { ReactElement } from "react";
import styled from "styled-components";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import WhiteBoxTitleLayout from "../../../common/commonUnitComponent/box/WhiteBoxTitleLayout";
import { REFUND_INFO_TEXTS } from "../../adBiding/components/AdBidingRefundInfo";

export const AdRegisterAdjustRefundInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const AdRegisterAdjustRefundText = styled.div`
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
`;

type Props = {};

const AdRegisterAdjustRefundInfo = (props: Props): ReactElement | null => {
    return (
        <WhiteBox>
            <WhiteBoxTitleLayout title={"취소 및 환불 규정"} bodyMt={"24px"}>
                <AdRegisterAdjustRefundInfoContainer>
                    {REFUND_INFO_TEXTS.map((text, idx) => (
                        <AdRegisterAdjustRefundText key={idx}>
                            {text}
                        </AdRegisterAdjustRefundText>
                    ))}
                </AdRegisterAdjustRefundInfoContainer>
            </WhiteBoxTitleLayout>
        </WhiteBox>
    );
};

export default AdRegisterAdjustRefundInfo;
