import React, { ReactElement } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetAdBidCalendarCellComponentByCode } from "../useGetAdBidCalendarCellComponentByCode";
import {
    AD_NOT_OPENED_YET,
    AdDailyListItemType,
} from "../../../../../../../../../../types/ad/adDaily/AdDailyListItemType";
import { CalendarSelectedDateInfoContainer } from "../../../../../../../../../common/commonUiComponent/calendar/components/CalendarDateBody";
import { differenceInDays } from "date-fns";
import { getMonthStartEnd } from "../../../../../../../../../../utils/Utils";

export const AdBidCalendarSelectedMobileCellContainer = styled.div`
    padding: 12px 16px;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
`;

type Props = {
    selectedDate: Date;
    adDailyList: AdDailyListItemType[];
    displayingDate: Date;
};

const AdBidCalendarSelectedMobileCell = (props: Props): ReactElement | null => {
    const params = useParams();
    const adIdx = params.ad_id ? params.ad_id : "0";
    const monthStartEnd = getMonthStartEnd(props.displayingDate);
    const selectedDateIndex = differenceInDays(
        props.selectedDate,
        monthStartEnd.startDate,
    );

    const { cellMobileSelectedCell, adDailyStatusCode } =
        useGetAdBidCalendarCellComponentByCode({
            cellDate: props.selectedDate,
            adDailyList: props.adDailyList,
            adIdx: adIdx,
        });

    return adDailyStatusCode !== AD_NOT_OPENED_YET ? (
        <CalendarSelectedDateInfoContainer
            selectedDateInfoContainerIndex={selectedDateIndex}
        >
            <AdBidCalendarSelectedMobileCellContainer>
                {cellMobileSelectedCell}
            </AdBidCalendarSelectedMobileCellContainer>
        </CalendarSelectedDateInfoContainer>
    ) : null;
};

export default AdBidCalendarSelectedMobileCell;
