export type AdInfoAnalysisInfoType = {
    avg_bid_cnt_10rounds: number;
    avg_bid_price_10rounds: number;
    avg_click_cnt_10rounds: number;
    avg_expose_cnt_10rounds: number;
    bid_cnt_last_round: number;
    conclusion_price_last_round: number;
    highest_price_52weeks: number;
    lowest_price_52weeks: number;
};

export const DEFAULT_ADVERTISEMENT_INFO_ANALYSIS_TYPE_DATA: AdInfoAnalysisInfoType =
    {
        avg_bid_cnt_10rounds: 0,
        avg_bid_price_10rounds: 0,
        avg_click_cnt_10rounds: 0,
        avg_expose_cnt_10rounds: 0,
        bid_cnt_last_round: 0,
        conclusion_price_last_round: 0,
        highest_price_52weeks: 0,
        lowest_price_52weeks: 0,
    };
