/* eslint-disable no-undef */
const isPwaServiceWorkerSupported =
    "serviceWorker" in navigator &&
    "Notification" in window &&
    "PushManager" in window;

export const registerPwaServiceWorker = async (isDev) => {
    if (!isPwaServiceWorkerSupported) {
        return;
    }

    try {
        // 클라이언트에 등록되어 있는 ServiceWorkerRegistration 을 가져온다.
        let registration = await navigator.serviceWorker.getRegistration();

        // 클라이언트에 등록된 ServiceWorkerRegistration 이 없으면 새로 등록한다.
        if (!registration) {
            registration = await navigator.serviceWorker.register(
                isDev
                    ? "https://dev.saybuzz.io/service-worker.js"
                    : "https://saybuzz.io/service-worker.js",
            );
        }
    } catch (e) {
        console.info(e);
    }
};
