import { useAtom } from "jotai";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useMutation } from "react-query";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { ItemInventoryApi } from "../../apis/ItemInventoryApi";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { queryClient } from "../../App";
import CollectionCommonStateAtom from "../../store/commonState/CollectionCommonStateAtom";

export type SetItemInventoryBelongingGroupListParams = {
    groupList: SetItemInventoryBelongingGroupListParamsItemType[];
};

export type SetItemInventoryBelongingGroupListParamsItemType = {
    item_inventory_group_idx: string;
    item_inventory_group_sub_idx: string | null;
    insert_flag: boolean;
    delete_flag: boolean;
};

export const useSetItemInventoryBelongingGroupMutation = (
    itemIdx: string,
    params: SetItemInventoryBelongingGroupListParams,
) => {
    const [collectionCommonState] = useAtom(CollectionCommonStateAtom);
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => ItemInventoryApi.setItemBelongingGroupList(itemIdx, params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                await queryClient.refetchQueries({
                    queryKey: collectionCommonState.collectionItemListQueryKey,
                    refetchPage: () => true,
                });

                pushSnackBarListItem({
                    title: "",
                    desc: "그룹 이동이 완료되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
