import { useQuery } from "react-query";
import { AdApi } from "../../../apis/adApi/AdApi";
import {
    AdInfoType,
    DEFAULT_ADVERTISEMENT_INFO,
} from "../../../types/ad/advertisementInfo/AdInfoType";
import {
    CampaignInfoType,
    DEFAULT_CAMPAIGN_INFO_TYPE_DATA,
} from "../../../types/ad/camapaignInfo/CampaignInfoType";
import {
    AdInfoAnalysisInfoType,
    DEFAULT_ADVERTISEMENT_INFO_ANALYSIS_TYPE_DATA,
} from "../../../types/ad/advertisementInfo/AdInfoAnalysisInfoType";
import {
    BrandInfoType,
    DEFAULT_BRAND_INFO_TYPE_DATA,
} from "../../../types/brand/BrandInfoType";
import { AdInfoCampaignInfoImgType } from "../../../types/ad/advertisementInfo/AdInfoCampaignInfoImgType";
import { AdInfoCampaignPreviewImgType } from "../../../types/ad/advertisementInfo/AdInfoCampaignPreviewImgType";
import {
    CreatorInfoType,
    DEFAULT_CREATOR_INFO_TYPE_DATA,
} from "../../../types/creator/CreatorInfoType";
import { AdInfoCampaignAdSellPreviewImgItemType } from "../../../types/ad/advertisementInfo/AdInfoCampaignAdSellPreviewImgItemType";

export interface AdInfoApiResultType {
    advertisementInfo: AdInfoType;
    analysisInfo: AdInfoAnalysisInfoType;
    brandInfo: BrandInfoType;
    campaignInfo: CampaignInfoType;
    campaignInfoImgList: AdInfoCampaignInfoImgType[];
    campaignOfferPreviewImgList: AdInfoCampaignPreviewImgType[];
    campaignAdSellPreviewImgList: AdInfoCampaignAdSellPreviewImgItemType[];
    creatorInfo: CreatorInfoType;
}

const DefaultAdDetailData: AdInfoApiResultType = {
    advertisementInfo: DEFAULT_ADVERTISEMENT_INFO,
    analysisInfo: DEFAULT_ADVERTISEMENT_INFO_ANALYSIS_TYPE_DATA,
    brandInfo: DEFAULT_BRAND_INFO_TYPE_DATA,
    campaignInfo: DEFAULT_CAMPAIGN_INFO_TYPE_DATA,
    campaignInfoImgList: [],
    campaignOfferPreviewImgList: [],
    creatorInfo: DEFAULT_CREATOR_INFO_TYPE_DATA,
    campaignAdSellPreviewImgList: [],
};

export const useAdInfoQuery = (ad_idx: string, isEnabled = true) => {
    const queryResult = useQuery<AdInfoApiResultType, Error>({
        queryKey: [`ad-data-${ad_idx}`, "ad-data"],
        queryFn: () => AdApi.getAdInfo(ad_idx),
        suspense: false,
        onError: () => {},
        enabled: isEnabled,
    });

    return {
        ...queryResult,
        data: queryResult.data || DefaultAdDetailData,
    };
};
