import { ReactElement } from "react";
import styled from "styled-components";

export const WhiteBoxWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;

    @media ${(props) => props.theme.mobileL} {
        border-radius: 0;
    }
`;

export const WhiteBoxContainer = styled.div<{ padding: string }>`
    padding: ${({ padding }) => padding};

    @media ${(props) => props.theme.mobileL} {
        padding: 20px 16px;
    }
`;
type Props = {
    children: ReactElement;
    padding: string;
    isOnlyContents: boolean;
};

const WhiteBox = (props: Props): ReactElement | null => {
    return props.isOnlyContents ? (
        props.children
    ) : (
        <WhiteBoxWrapper>
            <WhiteBoxContainer padding={props.padding}>
                {props.children}
            </WhiteBoxContainer>
        </WhiteBoxWrapper>
    );
};

export default WhiteBox;

WhiteBox.defaultProps = {
    padding: "32px",
    isOnlyContents: false,
};
