import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { Link } from "react-router-dom";
import {
    COLLECTION_CREATED_TAB,
    COLLECTION_LIKED_TAB,
    COLLECTION_OWNED_TAB,
} from "../WalletCollectionHub";

export const WalletCollectionNoItemListContainer = styled.div`
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 60px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 60px;
        margin-bottom: 60px;
    }
`;

export const WalletCollectionNoItemListImg = styled.img`
    width: 108.571px;
    height: 120px;
`;

export const WalletCollectionNoItemListTitle = styled.div`
    color: #333;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 100%;
    margin-top: 24px;

    @media ${(props) => props.theme.mobileL} {
        font-size: 16px;
    }
`;

export const WalletCollectionNoItemListBuyButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;
export const WalletCollectionNoItemListBuyLink = styled(Link)``;

export const WalletCollectionNoItemListBuyButton = styled.div`
    padding: 13px 20px;
    border-radius: 8px;
    background: #fa54fa;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    margin-top: 32px;
    cursor: pointer;
`;

export const WalletCollectionNoItemListSelfAdContainer = styled.div`
    margin-top: 60px;
    width: 100%;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 24px;
    }
`;

type Props = {
    selectedSubTabKey: string;
};

const WalletCollectionNoItemList = (props: Props): ReactElement | null => {
    const [title, setTitle] = useState("");
    const [btnTitle, setBtnTitle] = useState("");
    const [link, setLink] = useState("");
    useEffect(() => {
        switch (props.selectedSubTabKey) {
            case COLLECTION_LIKED_TAB:
                setTitle("관심있는 아이템에 좋아요를 눌러보세요.");
                setBtnTitle("아이템 보러가기");
                setLink("/market/root");
                break;

            case COLLECTION_CREATED_TAB:
                setTitle("아이템을 만들어보세요.");
                setBtnTitle("아이템 등록하기");
                setLink("/market/add-item");
                break;

            case COLLECTION_OWNED_TAB:
            default:
                setTitle("아이템을 구매해보세요.");
                setBtnTitle("아이템 구매하러 가기");
                setLink("/market/root");
                break;
        }
    }, []);

    return (
        <WalletCollectionNoItemListContainer>
            <WalletCollectionNoItemListImg
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-empty-item.svg`}
            />
            <WalletCollectionNoItemListTitle>
                {title}
            </WalletCollectionNoItemListTitle>
            <WalletCollectionNoItemListBuyButtonContainer>
                <WalletCollectionNoItemListBuyLink to={link}>
                    <WalletCollectionNoItemListBuyButton>
                        {btnTitle}
                    </WalletCollectionNoItemListBuyButton>
                </WalletCollectionNoItemListBuyLink>
            </WalletCollectionNoItemListBuyButtonContainer>
        </WalletCollectionNoItemListContainer>
    );
};

export default WalletCollectionNoItemList;
