import { useOpenModal } from "../useOpenModal";
import AdSaleManageAcceptOfferSuccessModal from "../../../components/ad/adManageNew/adSaleManage/adSaleManageOfferListModal/components/adSaleManageOfferListModalPreview/components/AdSaleManageAcceptOfferSuccessModal";
import { MyAdOfferInfoType } from "../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoType";

export function useOpenAdSaleManageAcceptOfferSuccessModal(
    selectedOfferInfo: MyAdOfferInfoType,
) {
    const { openModal } = useOpenModal();
    const openAdSaleManageAcceptOfferSuccessModal = async () => {
        return await openModal(
            "ad-sale-manage-accept-offer-success-modal",
            "modal",
            (resolve) => {
                return (
                    <AdSaleManageAcceptOfferSuccessModal
                        resolve={resolve}
                        selectedOfferInfo={selectedOfferInfo}
                    />
                );
            },
        );
    };

    return { openAdSaleManageAcceptOfferSuccessModal };
}
