import {
    AD_BID_CLOSED,
    AD_EXPIRED,
    AD_IN_REVIEW,
    AD_NOT_OPENED_YET,
    AD_ON_BID,
    AD_ON_SCHEDULE,
    AD_PREPARING,
    AD_UNKNOWN,
    AdDailyListItemStatusCodeType,
} from "../../types/ad/adDaily/AdDailyListItemType";

export const getIsTodaySale = function (adSalesDailyStatus?: string) {
    return (
        typeof adSalesDailyStatus === "undefined" || adSalesDailyStatus === "0"
    );
};

export function staticConvertAdStatusKR(
    code: AdDailyListItemStatusCodeType,
    isTodaySale: boolean,
    isAdStop: boolean,
) {
    if (!isTodaySale || isAdStop) {
        return "판매 중단";
    }
    switch (code) {
        case AD_NOT_OPENED_YET:
            return "등록전";
        case AD_ON_BID:
            return "입찰 기간";
        case AD_IN_REVIEW:
            return "최종 검수기간";
        case AD_BID_CLOSED:
            return "최종 검수기간"; // ? 최종 검수기간?
        case AD_PREPARING:
            return "시스템 준비기간";
        case AD_ON_SCHEDULE:
            return "광고 게재기간";
        case AD_EXPIRED:
            return "광고 종료";
        case AD_UNKNOWN:
            return "광고 중단";
        default:
            return "dailyAdStatus - error";
    }
}
