import React, { ReactElement } from "react";
import styled, { css } from "styled-components";

import { format } from "date-fns";
import MarketProjectHistoryMobileTableItem from "./MarketProjectHistoryMobileTableItem";
import { useParams } from "react-router-dom";
import { DateRange } from "react-day-picker";
import { CommonTC } from "../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import { FilterType } from "../../../../common/commonUiComponent/searchFilter/SearchFilter";
import {
    ProjectExchangeListItemType,
    spreadProjectExchangeList,
    useGetProjectExchangeList,
} from "../../../../../query/market/item/useGetProjectExchangeList";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../common/commonUnitComponent/commonTable/CommonTable";
import MarketHistoryTableBodyColumnTitle from "../../../marketHistory/components/marketHistoryTableComponent/MarketHistoryTableBodyColumnTitle";
import { numberToStringWithComma } from "../../../../../utils/Utils";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";
import DefaultNoDataComponent from "../../../../common/apiResBoundary/DefaultNoDataComponent";
import MoreButton from "../../../../common/commonUnitComponent/moreButton/MoreButton";

export const MarketProjectHistoryTableWrapper = styled.div``;

export const MarketProjectHistoryTablePcContainer = styled.div`
    display: block;
    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const MarketProjectHistoryTableMobileContainer = styled.div`
    display: none;
    @media ${(props) => props.theme.mobileL} {
        display: block;
    }
`;

export const MarketProjectHistoryTableHeaderColumn = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 10px 0;
    @media ${(props) => props.theme.mobileL} {
        color: #777;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        padding: 0;
    }
`;

export const MarketProjectHistoryTableBodyColumn = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #555555;
    padding: 13px 0;
    gap: 3px;

    @media ${(props) => props.theme.mobileL} {
        color: #555;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        padding: 0;
        flex-direction: row;
    }
`;

const ItemNameColumnWidth = css`
    width: 320px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;
const HistoryColumnWidth = css`
    width: 60px;
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        width: 100%;
    }
`;
const SenderColumnWidth = css`
    width: 108px;
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        width: 100%;
    }
`;
const ReceiverColumnWidth = css`
    width: 108px;
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        width: 100%;
    }
`;
const PriceColumnWidth = css`
    width: 84px;
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        width: 100%;
    }
`;
const CountColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        width: 100%;
    }
`;
const DateColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        width: 100%;
    }
`;
const OrderIdColumnWidth = css`
    word-break: break-all;
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        width: 100%;
    }
`;

export const MarketProjectHistoryItemNameColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${ItemNameColumnWidth};
`;

export const MarketProjectHistoryHistoryColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${HistoryColumnWidth};
`;

export const MarketProjectHistorySenderColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${SenderColumnWidth};
`;

export const MarketProjectHistoryReceiverColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${ReceiverColumnWidth};
`;

export const MarketProjectHistoryPriceColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${PriceColumnWidth};
`;

export const MarketProjectHistoryCountColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${CountColumnWidth};
`;

export const MarketProjectHistoryDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${DateColumnWidth};
`;

export const MarketProjectHistoryOrderIdColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${OrderIdColumnWidth};
`;

export const MarketProjectHistoryMoreButtonContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: center;

    @media ${(props) => props.theme.tabletM} {
        margin-top: 32px;
    }
`;

export const MarketProjectHistoryMoreButtonWidth = styled.div`
    width: 160px;
`;

type Props = {
    selectedDateRange: DateRange;
    filters: FilterType[];
};
const MarketProjectHistoryTable = (props: Props): ReactElement | null => {
    const params = useParams();
    const projectIdx = params.project_id ? params.project_id : "0";
    const {
        data: rawProjectExchangeList,
        isLoading: isProjectExchangeListLoading,
        error: errorProjectExchangeList,
        hasNextPage: hasProjectExchangeListNextPage,
        fetchNextPage: fetchProjectExchangeListNextPage,
    } = useGetProjectExchangeList(projectIdx, {
        limit: 20,
        page_no: 1,
        filter_type: "all",
        sort_type: "created_at_desc",
        exchanged_at_from: format(
            props.selectedDateRange.from ?? new Date(),
            "yyyy-MM-dd",
        ),
        exchanged_at_to: format(
            props.selectedDateRange.to ?? new Date(),
            "yyyy-MM-dd",
        ),
    });
    const projectExchangeList = spreadProjectExchangeList(
        rawProjectExchangeList.pages,
    );

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "itemNameColumn",
            component: (
                <MarketProjectHistoryItemNameColumnContainer
                    textAlign={"center"}
                    key={"itemNameColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        아이템
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistoryItemNameColumnContainer>
            ),
        },
        {
            key: "HistoryColumn",
            component: (
                <MarketProjectHistoryHistoryColumnContainer
                    textAlign={"center"}
                    key={"HistoryColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        내역
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistoryHistoryColumnContainer>
            ),
        },
        {
            key: "SenderColumn",
            component: (
                <MarketProjectHistorySenderColumnContainer
                    textAlign={"center"}
                    key={"SenderColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        전송자
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistorySenderColumnContainer>
            ),
        },
        {
            key: "ReceiverColumn",
            component: (
                <MarketProjectHistoryReceiverColumnContainer
                    textAlign={"center"}
                    key={"ReceiverColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        수신자
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistoryReceiverColumnContainer>
            ),
        },
        {
            key: "PriceColumn",
            component: (
                <MarketProjectHistoryPriceColumnContainer
                    textAlign={"center"}
                    key={"PriceColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        거래가(ST)
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistoryPriceColumnContainer>
            ),
        },
        {
            key: "CountColumn",
            component: (
                <MarketProjectHistoryCountColumnContainer
                    textAlign={"center"}
                    key={"CountColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        수량
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistoryCountColumnContainer>
            ),
        },
        {
            key: "DateColumn",
            component: (
                <MarketProjectHistoryDateColumnContainer
                    textAlign={"center"}
                    key={"DateColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        일시
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistoryDateColumnContainer>
            ),
        },
        {
            key: "OrderIdColumn",
            component: (
                <MarketProjectHistoryOrderIdColumnContainer
                    textAlign={"center"}
                    key={"OrderIdColumn"}
                >
                    <MarketProjectHistoryTableHeaderColumn>
                        체결번호
                    </MarketProjectHistoryTableHeaderColumn>
                </MarketProjectHistoryOrderIdColumnContainer>
            ),
        },
    ];

    const data = projectExchangeList.map(
        (item: ProjectExchangeListItemType) => {
            return {
                itemNameColumn: (
                    <MarketProjectHistoryItemNameColumnContainer
                        textAlign={"left"}
                        key={"itemNameColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            <MarketHistoryTableBodyColumnTitle
                                imgSrc={item.thumbnail_full_path}
                                title={item.item_name}
                                desc={""}
                                targetType={"item"}
                                targetIdx={projectIdx + "/" + item.item_idx}
                            />
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistoryItemNameColumnContainer>
                ),
                HistoryColumn: (
                    <MarketProjectHistoryHistoryColumnContainer
                        textAlign={"center"}
                        key={"HistoryColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            {item.exchange_type_detail}
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistoryHistoryColumnContainer>
                ),
                SenderColumn: (
                    <MarketProjectHistorySenderColumnContainer
                        textAlign={"center"}
                        key={"SenderColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            {item.send_user_nick ? item.send_user_nick : "-"}
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistorySenderColumnContainer>
                ),
                ReceiverColumn: (
                    <MarketProjectHistoryReceiverColumnContainer
                        textAlign={"center"}
                        key={"ReceiverColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            {item.receive_user_nick
                                ? item.receive_user_nick
                                : "-"}
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistoryReceiverColumnContainer>
                ),
                PriceColumn: (
                    <MarketProjectHistoryPriceColumnContainer
                        textAlign={"center"}
                        key={"PriceColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            {numberToStringWithComma(item.settled_unit_price)}
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistoryPriceColumnContainer>
                ),
                CountColumn: (
                    <MarketProjectHistoryCountColumnContainer
                        textAlign={"center"}
                        key={"CountColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            {item.settled_quantity}
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistoryCountColumnContainer>
                ),
                DateColumn: (
                    <MarketProjectHistoryDateColumnContainer
                        textAlign={"center"}
                        key={"DateColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            <span>
                                {format(new Date(item.created_at), "yy.MM.dd")}
                            </span>
                            <span>
                                {format(new Date(item.created_at), "HH:mm:ss")}
                            </span>
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistoryDateColumnContainer>
                ),
                OrderIdColumn: (
                    <MarketProjectHistoryOrderIdColumnContainer
                        textAlign={"center"}
                        key={"OrderIdColumn"}
                    >
                        <MarketProjectHistoryTableBodyColumn>
                            {item.settlement_idx}
                        </MarketProjectHistoryTableBodyColumn>
                    </MarketProjectHistoryOrderIdColumnContainer>
                ),
            };
        },
    );

    return (
        <MarketProjectHistoryTableWrapper>
            <ApiResBoundary
                isLoading={isProjectExchangeListLoading}
                error={errorProjectExchangeList}
                isNoData={projectExchangeList.length === 0}
                noDataFallback={
                    <DefaultNoDataComponent msg={"입출고 내역이 없습니다."} />
                }
            >
                <>
                    <MarketProjectHistoryTablePcContainer>
                        <CommonTable
                            id={"project-history-table"}
                            tableHeaderData={tableHeaderData}
                            tableBodyData={data}
                        />
                    </MarketProjectHistoryTablePcContainer>
                    <MarketProjectHistoryTableMobileContainer>
                        {data.map((item, idx) => {
                            return (
                                <MarketProjectHistoryMobileTableItem
                                    key={idx}
                                    item={item}
                                    tableHeaderData={tableHeaderData}
                                />
                            );
                        })}
                    </MarketProjectHistoryTableMobileContainer>
                    <MarketProjectHistoryMoreButtonContainer>
                        <MarketProjectHistoryMoreButtonWidth>
                            <MoreButton
                                isShow={hasProjectExchangeListNextPage}
                                isSupportFold={false}
                                onClick={async () => {
                                    await fetchProjectExchangeListNextPage();
                                }}
                            />
                        </MarketProjectHistoryMoreButtonWidth>
                    </MarketProjectHistoryMoreButtonContainer>
                </>
            </ApiResBoundary>
        </MarketProjectHistoryTableWrapper>
    );
};

export default MarketProjectHistoryTable;
