import { ReactElement } from "react";
import styled from "styled-components";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import {
    AdRegisterAdjustHeaderTitle,
    AdRegisterAdjustHeaderTitleDesc,
} from "../AdRegisterContents";
import ToggleSwitch from "../../../common/commonUnitComponent/toggleSwitch/ToggleSwitch";

export const AdRegisterAdjustAutoAcceptSwitchContainer = styled.div``;

export const AdRegisterAdjustCommonSwitchTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AdRegisterAdjustAutoAcceptSwitchDescMsgContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
`;

export const AdRegisterAdjustAutoAcceptSwitchDescMsg = styled.div`
    color: #888;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
`;

type Props = {
    isAdAutoAcceptOffer: boolean;
    setIsAdAutoAcceptOffer: (value: boolean) => void;
};

const AdRegisterAdjustAutoAcceptSwitch = (
    props: Props,
): ReactElement | null => {
    return (
        <WhiteBox>
            <AdRegisterAdjustAutoAcceptSwitchContainer>
                <AdRegisterAdjustCommonSwitchTitleContainer>
                    <AdRegisterAdjustHeaderTitle>
                        자동 낙찰 설정
                    </AdRegisterAdjustHeaderTitle>
                    <ToggleSwitch
                        id={"ad-auto-accept-toggle-switch"}
                        isChecked={props.isAdAutoAcceptOffer}
                        onChangeFunction={() => {
                            props.setIsAdAutoAcceptOffer(
                                !props.isAdAutoAcceptOffer,
                            );
                        }}
                        isDisabled={false}
                    />
                </AdRegisterAdjustCommonSwitchTitleContainer>
                <AdRegisterAdjustHeaderTitleDesc>
                    입찰기간이 종료되면 자동으로 가장 높은 가격에 입찰한 광고가
                    낙찰됩니다. (가격이 같을 경우 먼저 입찰한 광고가 낙찰)
                </AdRegisterAdjustHeaderTitleDesc>
                <AdRegisterAdjustAutoAcceptSwitchDescMsgContainer>
                    <AdRegisterAdjustAutoAcceptSwitchDescMsg>
                        · 입찰기간은 게재일 전날 09시 이며, 입찰한 내역이 없을
                        경우 해당 회차는 유찰됩니다.
                    </AdRegisterAdjustAutoAcceptSwitchDescMsg>
                    <AdRegisterAdjustAutoAcceptSwitchDescMsg>
                        · 자동 낙찰 설정을 해제할 경우 수동 낙찰하지 않으면
                        입찰건이 있어도 수동낙찰 하지 않으면 유찰될 수 있습니다.
                        (즉시구매 제외)
                    </AdRegisterAdjustAutoAcceptSwitchDescMsg>
                </AdRegisterAdjustAutoAcceptSwitchDescMsgContainer>
            </AdRegisterAdjustAutoAcceptSwitchContainer>
        </WhiteBox>
    );
};

export default AdRegisterAdjustAutoAcceptSwitch;
