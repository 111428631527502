import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { ItemListItemType } from "../../../../../../query/item/useItemListQuery";

import CssFilterConverter from "css-filter-converter";
import { useTransferItemMutation } from "../../../../../../query/item/transfer/useTransferItemMutation";
import {
    ModalButton,
    ModalButtonContainer,
    ModalTitle,
} from "../../../../../common/commonUnitComponent/modal/ModalStyle";
import {
    getUserSearchQuery,
    spreadListUserSearchResult,
} from "../../../../../../query/search/getUserSearchQuery";
import { useOnClickOutside } from "usehooks-ts";
import ModalCloseIcon from "../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import { Link } from "react-router-dom";
import { useGetOwnItemCnt } from "../../../../../../query/item/useGetOwnItemCnt";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import { useOpenCommonErrorModal } from "../../../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import Spinner from "../../../../../common/commonUnitComponent/spinner/Spinner";

export const ItemSendLayerContainer = styled.div`
    width: 352px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: auto;
    overflow: visible;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const ItemSendUseLayerInfoContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
    margin-top: 24px;
`;

export const ItemSendUseLayerInfoItemThumbnail = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 8px;
`;

export const ItemSendUseLayerInfoItemTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
`;

export const ItemSendUseLayerInfoItemTitle = styled.div`
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
`;

export const ItemSendUseLayerInfoItemProjectTitle = styled.div`
    color: #777;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
`;

export const ItemSendUseLayerInfoInputInfoContainer = styled.div`
    width: 100%;
    margin-top: 24px;
    position: relative;
`;

export const ItemSendUseLayerInfoInputInfoTitle = styled.div`
    color: #222;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 15px */
`;
export const ItemSendLayerInfoNicknameInputContainer = styled.div`
    position: relative;
    padding: 12px 10px;
    width: calc(100% - 22px);
    margin-top: 10px;
    border-radius: 8px;
    background: #f5f5f7;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    overflow: visible;
`;
export const ItemSendLayerInfoNicknameInputSelectedInfoContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const ItemSendLayerInfoNicknameInputSelectedNickname = styled.div`
    color: #000;
    font-size: 16px;
    font-style: normal;
    overflow: visible;
    font-weight: 400;
    line-height: 100%;
`;
export const ItemSendLayerInfoNicknameInputSelectedUserId = styled.div`
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-left: 2px;
`;
export const ItemSendLayerInfoNicknameInput = styled.input`
    background: transparent;
    color: #000;
    width: 100%;
    font-family: Noto Sans KR, serif;
    font-size: 16px;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding: 0;
    border: none;
    overflow: visible;
    padding-bottom: 2px;

    &:focus,
    &:active {
        outline: none;
        border: none;
    }

    // border: 1px solid #fa54fa;

    ::placeholder {
        color: rgba(0, 0, 0, 0.4);
        font-family: Noto Sans KR, serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
`;

export const ItemSendLayerInfoSelectedIcon = styled.img`
    width: 14px;
    height: 11px;
    filter: ${CssFilterConverter.hexToFilter("#FA54FA").color};
    margin-left: 4px;
`;

export const ItemSendLayerInfoNicknameListContainer = styled.div<{
    isShow: boolean;
}>`
    position: absolute;
    bottom: 0;
    transform: translate(0, 100%);
    width: 100%;
    z-index: 3;
    border-radius: 12px;
    background: var(--bg-default, #fff);
    box-shadow: 0 1px 3px 0 rgba(36, 36, 36, 0.12),
        0 8px 24px 0 rgba(83, 83, 83, 0.12);
    max-height: ${({ isShow }) => (isShow ? "220px" : 0)};
    transition: all 300ms ease;
    overflow: hidden;
`;

export const ItemSendLayerInfoNicknameList = styled.div<{ listLength: number }>`
    padding: ${({ listLength }) => (listLength === 0 ? 0 : "10px")};
    display: flex;
    flex-direction: column;
`;

export const ItemSendLayerInfoNicknameListItem = styled.div`
    display: flex;
    padding: 12px 6px;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f0f2;
        }
    }

    transition: all 300ms ease;
`;

export const ItemSendLayerInfoNicknameListItemNickname = styled.div`
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
`;

export const ItemSendLayerInfoNicknameListItemUserId = styled.div`
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
`;

export const ItemSendUseLayerInfoNumberContainer = styled.div`
    display: flex;
    gap: 6px;
    margin-top: 10px;
    align-items: center;
`;

export const ItemSendUseLayerInfoNumberButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px transparent solid;
    background: rgba(13, 13, 26, 0.06);
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
`;

export const ItemSendUseLayerInfoNumberInput = styled.input`
    display: flex;
    text-align: right;
    height: 40px;
    width: 60px;
    padding: 0 9px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;

    &:focus,
    &:active {
        outline: none;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const ItemSendUseLayerRemainCountContainer = styled.div`
    color: #888;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    margin-top: 10px;
    width: 100%;
`;

export const ItemSendUseLayerWarningContainer = styled.div`
    display: flex;
    padding: 14px 16px;
    flex-direction: column;
    align-items: flex-start;

    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    margin-top: 24px;
`;

export const ItemSendUseLayerWarningTitle = styled.div`
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 13px */
`;

export const ItemSendUseLayerWarningDescWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const ItemSendUseLayerWarningDescContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: baseline;
`;

export const ItemSendUseLayerWarningDesc = styled.div`
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    display: flex;
    align-items: center;
`;

export const ItemSendLayerWarningDescLink = styled(Link)`
    &:focus,
    &:visited,
    &:link,
    &:active {
        text-decoration: underline;
        color: #666;
    }
`;

type Props = {
    resolve: (value: boolean) => void;
    item: ItemListItemType;
};

const WalletCollectionItemSendModal = (props: Props): ReactElement | null => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const {
        data: rawOwnItemCnt,
        refetch: refetchOwnItemCnt,
        isSuccess: isOwnItemCntSuccess,
    } = useGetOwnItemCnt(props.item.item_idx);
    const [isPending, setIsPending] = useState(false);

    const inputNicknameRef = useRef<HTMLInputElement>(null);
    const inputNicknameContainerRef = useRef<HTMLDivElement>(null);
    const [inputNickname, setInputNickname] = useState("");
    const [inputNumber, setInputNumber] = useState(0);
    const warningList = [
        "수신인의 닉네임은 세이버즈 닉네임으로 표시됩니다.",
        "전송한 아이템은 되돌릴 수 없습니다. 수신인의 아이디와 닉네임을 다시 한번 확인해 주세요.",
        "귀속아이템은 전송후 수신인에게 귀속됩니다.",
    ];
    const [isOpenNicknameList, setIsOpenNicknameList] = useState(false);
    useOnClickOutside(inputNicknameContainerRef, () => {
        setIsOpenNicknameList(false);
    });

    const [selectedUserInfo, setSelectedUserInfo] = useState({
        user_id: "",
        user_idx: "-1",
        user_nick: "",
    });

    function initSelectedUserInfo() {
        if (selectedUserInfo.user_idx !== "-1") {
            setSelectedUserInfo({
                user_id: "",
                user_idx: "-1",
                user_nick: "",
            });
        }
    }

    const { data: rawUserSearchResult, refetch: refetchUserSearchResult } =
        getUserSearchQuery(
            {
                keyword: inputNickname,
                limit: 5,
                page_no: 1,
            },
            {
                enabled: false,
                cacheTime: 0,
                staleTime: 0,
            },
        );

    const memoizedUserSearchResult = spreadListUserSearchResult(
        rawUserSearchResult.pages,
    );

    useEffect(() => {
        if (inputNickname !== "") refetchUserSearchResult().then(() => {});
    }, [inputNickname]);

    const { mutateAsync: transferItem } = useTransferItemMutation(
        props.item.item_idx,
        {
            user_idx: selectedUserInfo.user_idx,
            count: inputNumber,
        },
    );

    const isTransferAble =
        inputNumber !== 0 && selectedUserInfo.user_idx !== "-1";

    const checkOwnItemCnt = async () => {
        await openCommonErrorModal({
            title: "전송 가능한 수량이 없습니다.",
        });

        props.resolve(false);
    };

    useEffect(() => {
        if (isOwnItemCntSuccess && rawOwnItemCnt.own_item_cnt === 0) {
            checkOwnItemCnt();
        }
    }, [isOwnItemCntSuccess, rawOwnItemCnt]);

    return (
        <ItemSendLayerContainer>
            <ModalTitle>아이템 전송</ModalTitle>
            <ModalCloseIcon resolve={props.resolve} />
            <ItemSendUseLayerInfoContainer>
                <ItemSendUseLayerInfoItemThumbnail
                    src={props.item.item_thumbnail_full_path}
                />
                <ItemSendUseLayerInfoItemTitleContainer>
                    <ItemSendUseLayerInfoItemTitle>
                        {props.item.item_name}
                    </ItemSendUseLayerInfoItemTitle>
                    <ItemSendUseLayerInfoItemProjectTitle>
                        {props.item.project_name}
                    </ItemSendUseLayerInfoItemProjectTitle>
                </ItemSendUseLayerInfoItemTitleContainer>
            </ItemSendUseLayerInfoContainer>
            <ItemSendUseLayerInfoInputInfoContainer
                ref={inputNicknameContainerRef}
            >
                <ItemSendUseLayerInfoInputInfoTitle>
                    전송 대상자
                </ItemSendUseLayerInfoInputInfoTitle>
                <ItemSendLayerInfoNicknameInputContainer>
                    {selectedUserInfo.user_id !== "" && (
                        <ItemSendLayerInfoNicknameInputSelectedInfoContainer
                            onClick={() => {
                                initSelectedUserInfo();
                                // 너무빨리 focusing 되서 focus 가 안걸림...
                                setTimeout(() => {
                                    inputNicknameRef.current?.focus();
                                }, 200);
                            }}
                        >
                            <ItemSendLayerInfoNicknameInputSelectedNickname>
                                {selectedUserInfo.user_nick}
                            </ItemSendLayerInfoNicknameInputSelectedNickname>
                            <ItemSendLayerInfoNicknameInputSelectedUserId>
                                ({selectedUserInfo.user_id})
                            </ItemSendLayerInfoNicknameInputSelectedUserId>
                            <ItemSendLayerInfoSelectedIcon
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-small.svg`}
                            />
                        </ItemSendLayerInfoNicknameInputSelectedInfoContainer>
                    )}
                    {selectedUserInfo.user_id === "" && (
                        <ItemSendLayerInfoNicknameInput
                            ref={inputNicknameRef}
                            placeholder={"닉네임 및 아이디를 입력해주세요."}
                            value={inputNickname}
                            onChange={(e) => {
                                initSelectedUserInfo();
                                setInputNickname(e.target.value);
                            }}
                            onFocus={() => {
                                setIsOpenNicknameList(true);
                            }}
                        />
                    )}
                </ItemSendLayerInfoNicknameInputContainer>
                <ItemSendLayerInfoNicknameListContainer
                    isShow={isOpenNicknameList}
                >
                    <ItemSendLayerInfoNicknameList
                        listLength={memoizedUserSearchResult.length}
                    >
                        {memoizedUserSearchResult.map((searchUserInfo, idx) => {
                            return (
                                <ItemSendLayerInfoNicknameListItem
                                    key={idx}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setInputNickname(
                                            searchUserInfo.user_nick,
                                        );
                                        setSelectedUserInfo(searchUserInfo);
                                        setIsOpenNicknameList(false);
                                    }}
                                >
                                    <ItemSendLayerInfoNicknameListItemNickname>
                                        {searchUserInfo.user_nick}
                                    </ItemSendLayerInfoNicknameListItemNickname>
                                    <ItemSendLayerInfoNicknameListItemUserId>
                                        ({searchUserInfo.user_id})
                                    </ItemSendLayerInfoNicknameListItemUserId>
                                </ItemSendLayerInfoNicknameListItem>
                            );
                        })}
                    </ItemSendLayerInfoNicknameList>
                </ItemSendLayerInfoNicknameListContainer>
            </ItemSendUseLayerInfoInputInfoContainer>
            <ItemSendUseLayerInfoInputInfoContainer>
                <ItemSendUseLayerInfoInputInfoTitle>
                    전송 개수
                    <ItemSendUseLayerInfoNumberContainer>
                        <ItemSendUseLayerInfoNumberButton
                            onClick={async () => {
                                if (inputNumber > 0)
                                    setInputNumber(inputNumber - 1);
                                await refetchOwnItemCnt();
                            }}
                        >
                            -
                        </ItemSendUseLayerInfoNumberButton>
                        <ItemSendUseLayerInfoNumberInput
                            type="number"
                            value={inputNumber}
                            onChange={async (e) => {
                                if (Number(e.target.value) >= 0)
                                    setInputNumber(Number(e.target.value));
                                await refetchOwnItemCnt();
                            }}
                        />
                        <ItemSendUseLayerInfoNumberButton
                            onClick={async () => {
                                if (inputNumber < rawOwnItemCnt.own_item_cnt) {
                                    setInputNumber(inputNumber + 1);
                                }
                                await refetchOwnItemCnt();
                            }}
                        >
                            +
                        </ItemSendUseLayerInfoNumberButton>
                    </ItemSendUseLayerInfoNumberContainer>
                </ItemSendUseLayerInfoInputInfoTitle>
            </ItemSendUseLayerInfoInputInfoContainer>
            <ItemSendUseLayerRemainCountContainer>
                전송 가능 개수{" "}
                {numberToStringWithComma(rawOwnItemCnt.own_item_cnt)}
            </ItemSendUseLayerRemainCountContainer>
            <ItemSendUseLayerWarningContainer>
                <ItemSendUseLayerWarningTitle>
                    유의사항
                </ItemSendUseLayerWarningTitle>
                <ItemSendUseLayerWarningDescWrapper>
                    {warningList.map((warningItem, idx) => {
                        return (
                            <ItemSendUseLayerWarningDescContainer key={idx}>
                                <ItemSendUseLayerWarningDesc>
                                    ·
                                </ItemSendUseLayerWarningDesc>
                                <ItemSendUseLayerWarningDesc>
                                    {warningItem}
                                </ItemSendUseLayerWarningDesc>
                            </ItemSendUseLayerWarningDescContainer>
                        );
                    })}
                </ItemSendUseLayerWarningDescWrapper>
            </ItemSendUseLayerWarningContainer>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    취소
                </ModalButton>
                <ModalButton
                    btnType={
                        isTransferAble
                            ? isPending
                                ? "filled-primary-pending"
                                : "filled-primary"
                            : "filled-primary-disabled"
                    }
                    onClick={async () => {
                        if (isTransferAble && !isPending) {
                            try {
                                setIsPending(true);
                                const result = await transferItem();
                                props.resolve(true);
                                // 실패하던 성공하던 닫기 때문에 확실히 pending 유지 시킨다.
                            } catch (e: any) {
                                props.resolve(false);
                            }
                        }
                    }}
                >
                    {isPending ? (
                        <Spinner
                            width={28}
                            height={28}
                            borderSize={3}
                            color={"#fa54fa #fa54fa #fa54fa #f0f0f2"}
                        />
                    ) : (
                        "전송하기"
                    )}
                </ModalButton>
            </ModalButtonContainer>
        </ItemSendLayerContainer>
    );
};

export default WalletCollectionItemSendModal;
