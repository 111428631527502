import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";

export const SearchResultNoDataContainer = styled.div`
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
    height: 261px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
`;

export const SearchResultNoDataImage = styled.img``;

export const SearchResultNoDataTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
`;

export const SearchResultNoDataDescContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 3px;
    width: 100%;
`;

export const SearchResultNoDataDesc = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 1;
    color: #999999;
`;

type Props = {
    searchInput: string;
};

const SearchResultNoData = (props: Props): ReactElement | null => {
    const warningList = [
        "모든 단어의 철자가 정확한지 확인하세요.",
        "다른 검색어를 사용해 보세요.",
        "더 일반적인 검색어를 사용해 보세요.",
        "키워드 수를 줄여보세요.",
    ];
    return (
        <SearchResultNoDataContainer>
            <SearchResultNoDataImage
                src={URL_CONSTANTS.ASSET_URL + "/icon/icon-search-no-data.svg"}
            />
            <SearchResultNoDataTitle>
                '{props.searchInput}'에 대한 검색 결과가 없습니다.
            </SearchResultNoDataTitle>
            <SearchResultNoDataDescContainer>
                {warningList.map((warning, idx) => {
                    return (
                        <SearchResultNoDataDesc key={idx}>
                            · {warning}
                        </SearchResultNoDataDesc>
                    );
                })}
            </SearchResultNoDataDescContainer>
        </SearchResultNoDataContainer>
    );
};

export default SearchResultNoData;
