import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";
import axios from "axios";
import {
    PointListApiParam,
    PointListApiResultType,
} from "../query/point/usePointListQuery";
import { PointDetailDataType } from "../query/point/usePointDataQuery";

export const PointApi = {
    getList: async (
        params: PointListApiParam,
    ): Promise<PointListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/point/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getDetailData: async (pointIdx: string): Promise<PointDetailDataType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/point/" + pointIdx, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
