import {
    AdCampaignSimpleListApiParans,
    AdCampaignSimpleListApiResult,
} from "../../query/ad/campaign/useAdCampaignSimpleListQuery";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import {
    AdCampaignManagedListApiParamsType,
    CampaignManagedListApiResultType,
} from "../../query/ad/campaign/useAdCampaignManagedListQuery";
import { AdCampaignInfoApiResultType } from "../../query/ad/campaign/useAdCampaignInfoQuery";
import axios from "axios";

export const AdCampaignApi = {
    // simpleType,
    getCampaignList: async (
        params: AdCampaignSimpleListApiParans,
    ): Promise<AdCampaignSimpleListApiResult> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/campaign/list`, {
                    params: params,
                    paramsSerializer: {
                        indexes: null, // recent_array number[] 보낼때 payload key recent_array[] 대괄호 지우는 옵션
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // manage Type
    getManagedCampaignList: async (
        params: AdCampaignManagedListApiParamsType,
    ): Promise<CampaignManagedListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/campaign/managed/list`, {
                    params: params,
                    paramsSerializer: {
                        indexes: null, // recent_array number[] 보낼때 payload key recent_array[] 대괄호 지우는 옵션
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getCampaignInfo: async (
        campaign_idx: string,
    ): Promise<AdCampaignInfoApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    URL_CONSTANTS.API_URL + "/v2/ad/campaign/" + campaign_idx,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
