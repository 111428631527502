import { ReactElement } from "react";
import styled from "styled-components";
import CommentListItem from "../../commonUnitComponent/commentListItem/CommentListItem";
import { onErrorImg } from "../../../../utils/Utils";
import {
    spreadCommentListPages,
    useCommentListQuery,
} from "../../../../query/comment/useCommentListQuery";
import { Link, useParams } from "react-router-dom";
import MoreButton from "../../commonUnitComponent/moreButton/MoreButton";
import ApiResBoundary from "../../apiResBoundary/ApiResBoundary";
import NoCommentCollection from "./NoCommentCollection";

export const CommunityCollectionContainer = styled.div`
    margin-top: 56px;
    width: 100%;
`;

export const CommentCollectionList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px 60px;
`;
export const CommentCollectionListItemContainer = styled.div`
    width: calc(50% - 30px);
    display: flex;

    @media ${(props) => props.theme.tabletL} {
        width: 100%;
    }
`;

export const CommentCollectionListItemCommentContainer = styled.div`
    width: 100%;
`;

export const CommentCollectionListItemTitle = styled(Link)`
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #222222;
    margin-bottom: 16px;
    cursor: pointer;
`;

export const CommentCollectionListItemThumbnailLink = styled(Link)``;

export const CommentCollectionListItemThumbnail = styled.img`
    width: 84px;
    height: 84px;
    border-radius: 12px;
    margin-right: 20px;
`;

export const CommentCollectionMoreButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
`;

export const CommentCollectionMoreButtonWidth = styled.div`
    width: 160px;
`;

type Props = {
    type: string;
    userIdx?: string;
};

const CommentCollection = (props: Props): ReactElement | null => {
    const params = useParams();
    const {
        data: rawCommentListData,
        isLoading,
        error,
        fetchNextPage,
        hasNextPage,
        refetch: reFetchCommentListData,
    } = useCommentListQuery("item", {
        list_type: props.type,
        [props.type + "_idx"]:
            props.type !== "user" ? params[props.type + "_id"] : props.userIdx,
        limit: 10,
        page_no: 1,
    });
    return (
        <CommunityCollectionContainer>
            <ApiResBoundary
                error={error}
                isLoading={isLoading}
                isNoData={
                    spreadCommentListPages(rawCommentListData.pages).length ===
                    0
                }
                defaultLoadingFallbackContainerHeight={"500px"}
                noDataFallback={<NoCommentCollection />}
                errorFallback={() => <NoCommentCollection />}
            >
                <>
                    <CommentCollectionList>
                        {spreadCommentListPages(rawCommentListData.pages).map(
                            (comment, idx) => {
                                if (comment.projectInfo && comment.itemInfo) {
                                    return (
                                        <CommentCollectionListItemContainer
                                            key={idx}
                                        >
                                            <CommentCollectionListItemThumbnailLink
                                                to={`/market/${comment.projectInfo.project_idx}/${comment.itemInfo.item_idx}`}
                                            >
                                                <CommentCollectionListItemThumbnail
                                                    src={
                                                        comment.itemInfo
                                                            .item_thumbnail_path
                                                    }
                                                    onError={(e) => {
                                                        onErrorImg(e);
                                                    }}
                                                />
                                            </CommentCollectionListItemThumbnailLink>
                                            <CommentCollectionListItemCommentContainer>
                                                <CommentCollectionListItemTitle
                                                    to={`/market/${comment.projectInfo.project_idx}/${comment.itemInfo.item_idx}`}
                                                >
                                                    {comment.itemInfo.item_name}
                                                </CommentCollectionListItemTitle>
                                                <CommentListItem
                                                    key={idx}
                                                    commentInfo={
                                                        comment.commentInfo
                                                    }
                                                    commentUserInfo={
                                                        comment.userInfo
                                                    }
                                                    reFetchCommentListData={
                                                        reFetchCommentListData
                                                    }
                                                    commentDisplayRow={2}
                                                />
                                            </CommentCollectionListItemCommentContainer>
                                        </CommentCollectionListItemContainer>
                                    );
                                }
                            },
                        )}
                        <CommentCollectionMoreButtonContainer>
                            <CommentCollectionMoreButtonWidth>
                                <MoreButton
                                    isShow={hasNextPage}
                                    onClick={() => {
                                        fetchNextPage().then(() => {});
                                    }}
                                />
                            </CommentCollectionMoreButtonWidth>
                        </CommentCollectionMoreButtonContainer>
                    </CommentCollectionList>
                </>
            </ApiResBoundary>
        </CommunityCollectionContainer>
    );
};

export default CommentCollection;

CommentCollection.defaultProps = {
    type: "project",
};
