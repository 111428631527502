import { ReactElement } from "react";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { onErrorImg } from "../../../../utils/Utils";

export const AdRegisterAdjustTemplateRegisterAdjustCampaignTitleContainer = styled.div`
    display: flex;
`;

export const AdRegisterAdjustTemplateRegisterAdjustCampaignThumbnailContainer = styled.div`
    width: 92px;
    height: 92px;
    border-radius: 8px;

    background: #f0f1fd;
    margin-right: 14px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

export const AdRegisterAdjustTemplateRegisterAdjustCampaignThumbnail = styled.img`
    border-radius: 8px;
    height: 80%;
`;

export const AdRegisterAdjustTemplateRegisterAdjustCampaignInfoContainer = styled.div``;

export const AdRegisterAdjustTemplateRegisterAdjustCampaignInfoLogo = styled.img`
    height: 16px;
    margin-top: 10px;
`;

export const AdRegisterAdjustTemplateRegisterAdjustCampaignInfoTitle = styled.div<{
    isSmallText?: boolean;
}>`
    margin-top: 10px;
    font-weight: ${({ isSmallText }) => (isSmallText ? "500" : "700")};
    font-size: ${({ isSmallText }) => (isSmallText ? "16px" : "18px")};
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #000000;
`;

type Props = {
    thumbnail: string;
    brandId: string;
    campaignName: string;
    isOnlyContents?: boolean;
};

const AdRegisterAdjustTemplateRegisterAdjustCampaignTitle = (
    props: Props,
): ReactElement | null => {
    return (
        <WhiteBox isOnlyContents={props.isOnlyContents}>
            <AdRegisterAdjustTemplateRegisterAdjustCampaignTitleContainer>
                <AdRegisterAdjustTemplateRegisterAdjustCampaignThumbnailContainer>
                    <AdRegisterAdjustTemplateRegisterAdjustCampaignThumbnail
                        src={props.thumbnail}
                        onError={onErrorImg}
                    />
                </AdRegisterAdjustTemplateRegisterAdjustCampaignThumbnailContainer>

                <AdRegisterAdjustTemplateRegisterAdjustCampaignInfoContainer>
                    <AdRegisterAdjustTemplateRegisterAdjustCampaignInfoLogo
                        src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${props.brandId}.svg`}
                    />
                    <AdRegisterAdjustTemplateRegisterAdjustCampaignInfoTitle
                        isSmallText={props.isOnlyContents}
                    >
                        {props.campaignName}
                    </AdRegisterAdjustTemplateRegisterAdjustCampaignInfoTitle>
                </AdRegisterAdjustTemplateRegisterAdjustCampaignInfoContainer>
            </AdRegisterAdjustTemplateRegisterAdjustCampaignTitleContainer>
        </WhiteBox>
    );
};

export default AdRegisterAdjustTemplateRegisterAdjustCampaignTitle;
AdRegisterAdjustTemplateRegisterAdjustCampaignTitle.defaultProps = {
    isOnlyContents: false,
};
