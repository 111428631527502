import { RefObject } from "react";
import {
    exceptionUiHandleByErrorCode,
    InputTextDataType,
} from "../../components/common/commonUnitComponent/commonInputText/CommonInputText";

export type ExceptionUiHandlerType = {
    errorTarget: ExceptionUiHandlerErrorTargetType;
    targetContainerRef?: RefObject<HTMLDivElement>;
    // inputTextType
    inputText?: InputTextDataType;
    setInputText?: (value: InputTextDataType) => void;
    isDuplicatePass?: boolean;
    isBlankCheck?: boolean;
    isWarningCheck?: boolean;
    isDuplicateCheck?: boolean;
    // fileType
    file?: File;
    isFileMinSizeCheck?: boolean;
    // idx Type
    selectedItemIdx?: string;
    // path type
    filePath?: string;
};

export const DefaultExceptionUiHandlerType = {
    isBlankCheck: true,
    isWarningCheck: true,
    isDuplicateCheck: true,
};

export type ExceptionUiHandlerErrorTargetType = {
    targetName: string;
    targetColumn?: string;
    targetIdx?: string;
};

export const NEED_TO_DUPLICATE_CHECK_MSG = "중복확인을 해주시기 바랍니다.";

export async function exceptionUiHandleAfterCallApi(
    callApi: () => Promise<void>,
    exceptionUiHandlers: ExceptionUiHandlerType[],
) {
    try {
        await callApi();
        return true;
    } catch (error) {
        let breakFlag = false;
        for (let i = 0; i < exceptionUiHandlers.length; i++) {
            const handler = exceptionUiHandlers[i];
            breakFlag = !exceptionUiHandleByErrorCode(
                handler,
                error,
                handler.errorTarget,
            );

            if (breakFlag) {
                break;
            }
        }
        return !breakFlag;
    }
}
