import styled, {
    css,
    FlattenInterpolation,
    ThemeProps,
} from "styled-components";

export const CommonTCContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 11px 0;
`;
export const CommonTC = styled.div<{ textAlign: string }>`
    text-align: ${({ textAlign }) => textAlign};
    display: flex;
    justify-content: ${({ textAlign }) => textAlign};
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    align-items: center;
`;

export const CommonTCThin = styled(CommonTC)<{ textAlign: string }>`
    font-size: 14px;
    color: #888888;
`;

export const CommonTCSmall = styled(CommonTC)<{ textAlign: string }>`
    font-size: 12px;
    color: #888888;
`;

export const CommonTCBold = styled(CommonTC)<{ textAlign: string }>`
    text-align: ${({ textAlign }) => textAlign};
    font-size: 14px;
    color: #222222;
`;

export const CommonNoDataContainer = styled.div<{
    noDataContainerStyle?: FlattenInterpolation<ThemeProps<any>>;
    noDataContainerHeight?: string;
    pb?: string;
}>`
    display: flex;
    height: ${({ noDataContainerHeight }) =>
        noDataContainerHeight ? noDataContainerHeight : "80px"};
    padding-bottom: ${({ pb }) => (pb ? pb : "24px")};
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: #555;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
    ${({ noDataContainerStyle }) =>
        noDataContainerStyle ? noDataContainerStyle : ``};

    @media ${(props) => props.theme.mobileL} {
        padding-bottom: 16px;
    }

    transition: height 300ms ease;
`;

export const CommonTCEllipsis = css`
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;
