import styled from "styled-components";

export const AdHistoryTableHeaderColumn = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #000000;
`;

export const AdHistoryTableHeaderQuestionMark = styled.span`
    width: 14.5px;
    height: 14.5px;
    font-size: 13px;
    flex-shrink: 0;
    font-weight: 800;
    color: #999999;
    border: 1.5px #999999 solid;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 2px;
    cursor: pointer;
    text-align: center;
`;

export const AdHistoryTableBodyColumn = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: #555555;
    padding: 19px 0;
`;

export const AdManageCommonPcTableWrapper = styled.div`
    @media ${(props) => props.theme.tabletM} {
        display: none;
    }
`;

export const AdManageCommonMobileTableWrapper = styled.div`
    display: none;

    @media ${(props) => props.theme.tabletM} {
        display: block;
    }
`;

export const AdManageCommonMobileTableContainer = styled.div`
    flex-direction: column;
`;

export const AdManageCommonTableMoreButtonContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: center;
`;

export const AdManageCommonTableMoreButton = styled.div`
    width: 152px;
`;

export const AdManageCommonMobileTableActionBtnContainer = styled.div`
    display: none;

    @media ${(props) => props.theme.tabletM} {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
`;

export const AdManageCommonMobileTableActionBtn = styled.div`
    background: rgba(13, 13, 26, 0.06);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #333333;
    text-align: center;
    cursor: pointer;
    @media ${(props) => props.theme.tabletM} {
        padding: 10px 52px;
    }
    @media ${(props) => props.theme.mobileL} {
        padding: 10px 0;
        width: 100%;
    }
`;
