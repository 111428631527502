import { ReactElement } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../commonStyle/CommonButtonStyle";
import { getIsLogin } from "../../../../store/auth/authKeyStore";
import { useLocalStorage } from "usehooks-ts";
import { useGoLoginPage } from "../../../../hooks/common/useGoLoginPage";

export const NoLoginHideInfoLayerContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 80px;
    transform: translate(-50%, 0);
    display: flex;
    width: 100%;
    z-index: 21;
    max-width: 340px;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #c8c8c8;
    background: #fff;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 35px);
    }
`;

export const NoLoginHideInfoLayerTitle = styled.div`
    color: #555;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 21px */
    margin-top: 32px;
`;

export const NoLoginHideInfoLayerBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    font-size: 16px;
    font-style: normal;
    width: calc(100% - 48px);
    font-weight: 700;
    height: 48px;
    margin: 24px;
`;

type Props = {};

const NoLoginHideInfoLayer = (props: Props): ReactElement | null => {
    const isLogin = getIsLogin();
    const [, setPathAfterLogin] = useLocalStorage("pathAfterLogin", "/");
    const { goLogin } = useGoLoginPage();

    return !isLogin ? (
        <NoLoginHideInfoLayerContainer>
            <NoLoginHideInfoLayerTitle>
                일부 거래 정보는 <br />
                로그인 이후에 확인할 수 있습니다.
            </NoLoginHideInfoLayerTitle>
            <NoLoginHideInfoLayerBtn
                btnType={"filled-primary"}
                onClick={async () => {
                    await setPathAfterLogin(location.pathname);
                    await goLogin();
                }}
            >
                로그인
            </NoLoginHideInfoLayerBtn>
        </NoLoginHideInfoLayerContainer>
    ) : null;
};

export default NoLoginHideInfoLayer;
