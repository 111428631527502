import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {};

const ServiceWorkerUpdater = (props: Props): ReactElement | null => {
    const location = useLocation();
    // 혹시 긴급으로 SW 업데이트 나갈수있으니깐 추가는 해둔다.
    useEffect(() => {
        try {
            navigator.serviceWorker.getRegistrations().then((regs) =>
                regs.forEach((reg) => {
                    // 페이지 이동시마다 sw 계속 없데이트 시킨다.
                    reg.update().catch((e) => {
                        console.info("SW:: update - error", e);
                    });
                }),
            );
        } catch (e) {
            console.info(e);
        }
    }, [location]);

    return null;
};

export default ServiceWorkerUpdater;
