import { ReactElement, useState } from "react";
import styled from "styled-components";
import AdManageTableContentsLayout from "../../_adManageLayout/AdManageTableContentsLayout";
import AdSaleManageAdListPcTable from "./components/AdSaleManageAdListPcTable";
import { DateRange } from "react-day-picker";
import { addDays, format } from "date-fns";
import {
    spreadMyAllAdDailyListPages,
    useGetMyAllAdDailyListQuery,
} from "../../../../../query/my/myAd/useGetMyAllAdDailyListQuery";
import DefaultNoDataComponent from "../../../../common/apiResBoundary/DefaultNoDataComponent";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";
import {
    AdManageCommonMobileTableWrapper,
    AdManageCommonPcTableWrapper,
    AdManageCommonTableMoreButton,
    AdManageCommonTableMoreButtonContainer,
} from "../../commonComponents/adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";
import MoreButton from "../../../../common/commonUnitComponent/moreButton/MoreButton";
import AdSaleManageAdListMobileTable from "./components/AdSaleManageAdListMobileTable";
import { AD_NOT_OPENED_YET } from "../../../../../types/ad/adDaily/AdDailyListItemType";

export const AdSaleManageAdListTableContentsContainer = styled.div``;

type Props = {};

// 광고 판매 관리 - 판매 내역 - 판매 목록 - contents
const AdSaleManageAdListTableContents = (props: Props): ReactElement | null => {
    const today = new Date();
    const defaultSelected: DateRange = {
        from: addDays(today, -15),
        to: addDays(today, +15),
    };
    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRange>(defaultSelected);

    const {
        data: rawMyAdDailyList,
        isLoading: isMyAdDailyListLoading,
        error: errorMyAdDailyList,
        hasNextPage: hasMyAdDailyListNextPage,
        fetchNextPage: fetchNextMyAdDailyList,
    } = useGetMyAllAdDailyListQuery({
        page_no: 1,
        limit: 20,
        ad_sales_date_from: format(
            selectedDateRange.from ? selectedDateRange.from : 0,
            "yyyy-MM-dd",
        ),
        ad_sales_date_to: format(
            selectedDateRange.to ? selectedDateRange.to : 0,
            "yyyy-MM-dd",
        ),
        sort_type: "ad_sales_date_desc",
        exclude_ad_daily_status: AD_NOT_OPENED_YET,
    });
    const myAdDailyList = spreadMyAllAdDailyListPages(rawMyAdDailyList.pages);

    return (
        <AdManageTableContentsLayout
            type={"sale"}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
        >
            <AdSaleManageAdListTableContentsContainer>
                <ApiResBoundary
                    isLoading={isMyAdDailyListLoading}
                    error={errorMyAdDailyList}
                    isNoData={myAdDailyList.length === 0}
                    noDataFallback={
                        <DefaultNoDataComponent msg={"입찰 내역이 없습니다"} />
                    }
                    defaultLoadingFallbackContainerHeight={"400px"}
                >
                    <>
                        <AdManageCommonPcTableWrapper>
                            <AdSaleManageAdListPcTable
                                selectedDateRange={selectedDateRange}
                                myAdDailyList={myAdDailyList}
                            />
                        </AdManageCommonPcTableWrapper>
                        <AdManageCommonMobileTableWrapper>
                            <AdSaleManageAdListMobileTable
                                selectedDateRange={selectedDateRange}
                                myAdDailyList={myAdDailyList}
                            />
                        </AdManageCommonMobileTableWrapper>
                        {hasMyAdDailyListNextPage && (
                            <AdManageCommonTableMoreButtonContainer>
                                <AdManageCommonTableMoreButton>
                                    <MoreButton
                                        isShow={true}
                                        isSupportFold={false}
                                        onClick={async () => {
                                            await fetchNextMyAdDailyList();
                                        }}
                                    />
                                </AdManageCommonTableMoreButton>
                            </AdManageCommonTableMoreButtonContainer>
                        )}
                    </>
                </ApiResBoundary>
            </AdSaleManageAdListTableContentsContainer>
        </AdManageTableContentsLayout>
    );
};

export default AdSaleManageAdListTableContents;
