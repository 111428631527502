import { useOpenCommonConfirmModal } from "./useOpenCommonConfirmModal";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useGoLoginPage } from "../../common/useGoLoginPage";

export function useOpenLoginConfirmModal() {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const [pathAfterLogin, setPathAfterLogin] = useLocalStorage(
        "pathAfterLogin",
        "/",
    );
    const navigate = useNavigate();
    const { goLogin } = useGoLoginPage();

    return {
        openLoginConfirmModal: async (willPathAfterLogin?: string) => {
            const confirmResult = await openCommonConfirmModal({
                desc: (
                    <div>
                        로그인이 필요한 기능입니다.
                        <br /> 로그인 하시겠습니까?
                    </div>
                ),
                buttonTitle: "로그인",
            });

            if (confirmResult) {
                if (typeof willPathAfterLogin !== "undefined") {
                    setPathAfterLogin(willPathAfterLogin);
                } else {
                    if (
                        pathAfterLogin === "/" &&
                        location.pathname.indexOf("/intro") === -1
                    )
                        setPathAfterLogin(location.pathname);
                }
                goLogin();
                return true;
            } else {
                // setPathAfterLogin("/");
                return false;
            }
        },
    };
}
