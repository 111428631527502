import { useAtom } from "jotai";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useMutation } from "react-query";
import { LikeApi } from "../../apis/LikeApi";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";

export type LikeApiParams = {
    type: string;
    targetIdx: string;
};

export const useLikeMutation = (params: LikeApiParams) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => LikeApi.like(params.type, params.targetIdx),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: () => {
                // pushSnackBarListItem(
                //     {
                //         title: "",
                //         desc: "",
                //         iconUrl:
                //             URL_CONSTANTS.CDN_URL +
                //             "/icon/icon-checked-in-circle.svg",
                //         confirmBtnTitle: "",
                //     },
                // );
            },
        },
    );
};
