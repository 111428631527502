import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";

export const NoCommentCollectionContainer = styled.div`
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    width: 100%;

    @media ${(props) => props.theme.mobileL} {
        padding: 60px 0;
    }
`;

export const NoCommentCollectionImg = styled.img`
    width: 120px;
    height: 102px;
`;

export const NoCommentCollectionTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NoCommentCollectionTitle = styled.div`
    color: #333;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
`;

export const NoCommentCollectionDesc = styled.div`
    color: #999;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    margin-top: 10px;
`;

type Props = {};

const NoCommentCollection = (props: Props): ReactElement | null => {
    return (
        <NoCommentCollectionContainer>
            <NoCommentCollectionImg
                src={URL_CONSTANTS.ASSET_URL + "/icon/icon-empty-comment.svg"}
            />
            <NoCommentCollectionTitleContainer>
                <NoCommentCollectionTitle>
                    댓글이 없습니다
                </NoCommentCollectionTitle>
                <NoCommentCollectionDesc>
                    프로젝트 아이템에 댓글을 작성해보세요.
                </NoCommentCollectionDesc>
            </NoCommentCollectionTitleContainer>
        </NoCommentCollectionContainer>
    );
};

export default NoCommentCollection;
