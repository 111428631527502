import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import Checkbox, {
    CheckboxProps,
    CheckIcon,
    HiddenCheckbox,
    Label,
} from "./Checkbox";

export const SquareCheckboxContainer = styled.div`
    display: flex;
    width: inherit;
    height: inherit;
    box-sizing: inherit;
    align-items: center;
    border-radius: 6px;
    padding: 6px 4px 6px 6px;
    transition: all 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 13, 26, 0.06);
        }
    }
`;

const SquareCheckBoxLabel = styled.label<{
    checked: boolean;
    isBorder: boolean;
}>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    flex-shrink: 0;

    border: ${({ checked }) =>
        checked ? `#FA54FA 1px solid` : `#c8c8c8 1px solid`};
    background: ${({ checked }) => (checked ? `#FA54FA` : `white`)};
    cursor: pointer;

    & > svg {
        position: absolute;
    }

    transition: all 300ms ease;
`;

const SquareCheckBox = ({
    id,
    checked,
    onChange,
    labelComponent,
    isBorder,
}: CheckboxProps): ReactElement | null => {
    return (
        <SquareCheckboxContainer>
            <SquareCheckBoxLabel
                checked={checked}
                htmlFor={id}
                isBorder={isBorder}
            >
                <HiddenCheckbox
                    id={id}
                    type="checkbox"
                    onChange={(e) => {
                        onChange(e);
                    }}
                    checked={checked}
                />
                <CheckIcon
                    checked={checked}
                    isBorder={true}
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-small.svg`}
                />
            </SquareCheckBoxLabel>
            {labelComponent ? (
                <Label htmlFor={id}>{labelComponent}</Label>
            ) : null}
        </SquareCheckboxContainer>
    );
};

export default SquareCheckBox;

SquareCheckBox.defaultProps = {
    isBorder: true,
};
