import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import ProjectListItem from "./ProjectListItem";
import {
    ProjectListApiParamsType,
    spreadProjectListPages,
    useProjectListQuery,
} from "../../../../query/project/useProjectListQuery";
import MoreButton from "../../commonUnitComponent/moreButton/MoreButton";
import { useSetStateOnWindowWidth } from "../../../../hooks/common/useSetStateOnWindowWidth";
import ApiResBoundary from "../../apiResBoundary/ApiResBoundary";
import DefaultNoDataComponent from "../../apiResBoundary/DefaultNoDataComponent";
import { Link, useLocation } from "react-router-dom";
import { shallowEqual } from "../../../../utils/Utils";
import { BRAND_ID_SOOP } from "../../../../constants/brand/Brand";
import { commonProjectListPerRowAtom } from "../../../../store/ui/perRowAtom";
import { useAtom } from "jotai";
import { COMMON_QUERY_PARAMS } from "../../../../types/BasicType";

export const ProjectListWrapper = styled.div<{
    hasContainerTopMargin: boolean;
}>`
    margin-top: ${({ hasContainerTopMargin }) =>
        hasContainerTopMargin ? "120px" : 0};

    @media ${(props) => props.theme.mobileL} {
        margin-top: ${({ hasContainerTopMargin }) =>
            hasContainerTopMargin ? "80px" : 0};
    }
`;

export const ProjectListTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        flex-wrap: wrap;
        gap: 24px;
    }
`;

export const ProjectListTitle = styled.div`
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    color: #222222;

    @media ${(props) => props.theme.mobileL} {
        font-size: 20px;
        line-height: 100%;
    }
`;

export const ProjectAddButton = styled(Link)`
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fa54fa;

    color: #fa54fa;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(250, 84, 250, 0.1);
        }
    }

    transition: all 300ms ease;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 32px);
        flex-shrink: 0;
        text-align: center;
    }
`;

export const ProjectListContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding-top: 8px;

    @media ${(props) => props.theme.tabletM} {
        gap: 20px;
    }

    @media ${(props) => props.theme.mobileL} {
        gap: 16px 16px;
    }
`;

export const ProjectListItemContainer = styled.div`
    width: calc(25% - 18px);

    @media ${(props) => props.theme.PCM} {
        width: calc(33.3% - 16px);
    }

    @media ${(props) => props.theme.tabletL} {
        width: calc(33.3% - 16px);
    }

    @media ${(props) => props.theme.tabletM} {
        width: calc(50% - 10px);
    }

    @media ${(props) => props.theme.mobileL} {
        width: calc(50% - 8px);
    }
`;

export const ProjectListMoreButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
`;

export const ProjectListMoreButtonWidth = styled.div`
    width: 160px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

type Props = {
    title: string;
    projectListApiParam: ProjectListApiParamsType;
    projectListQueryParam?: COMMON_QUERY_PARAMS;
    isShowAddBtn: boolean;
    projectBackground?: string;
    hasContainerTopMargin: boolean;
};

const ProjectList = (props: Props): ReactElement | null => {
    const location = useLocation();

    const [commonProjectListPerRow, setCommonProjectListPerRow] = useAtom(
        commonProjectListPerRowAtom,
    );
    const { isOnMountedSetDone } = useSetStateOnWindowWidth(
        setCommonProjectListPerRow,
        [4, 4, 3, 2, 2, 2, 2],
    );

    const projectListApiParam = {
        ...props.projectListApiParam,
        limit: commonProjectListPerRow,
    };

    const {
        data: rawProjectList,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isIdle,
        isFetching,
        error,
        remove,
    } = useProjectListQuery(projectListApiParam, {
        cacheTime: props.projectListQueryParam?.cacheTime || 60000,
        staleTime: props.projectListQueryParam?.staleTime || 5000,
        enabled:
            isOnMountedSetDone &&
            commonProjectListPerRow !== 0 &&
            (typeof props.projectListQueryParam !== "undefined"
                ? props.projectListQueryParam.enabled
                : true),
    });
    const projectList = spreadProjectListPages(rawProjectList.pages);

    useEffect(() => {
        // remove();
    }, [location.pathname]);

    return (
        <ProjectListWrapper hasContainerTopMargin={props.hasContainerTopMargin}>
            <ProjectListTitleContainer>
                <ProjectListTitle>{props.title}</ProjectListTitle>
                {props.isShowAddBtn && (
                    <ProjectAddButton to={"/market/add-project"}>
                        프로젝트 추가
                    </ProjectAddButton>
                )}
            </ProjectListTitleContainer>

            <ApiResBoundary
                error={error}
                isLoading={isLoading || isIdle}
                isNoData={projectList.length === 0}
                defaultLoadingFallbackContainerHeight={"444px"}
                noDataFallback={
                    <DefaultNoDataComponent
                        msg={"프로젝트가 없습니다."}
                        height={"6px"}
                    />
                }
            >
                <>
                    <ProjectListContainer>
                        {projectList.map((project, idx) => (
                            <ProjectListItemContainer key={idx}>
                                <ProjectListItem
                                    info={project}
                                    isMargin={false}
                                    projectBackground={props.projectBackground}
                                />
                            </ProjectListItemContainer>
                        ))}
                    </ProjectListContainer>
                    <ProjectListMoreButtonContainer>
                        <ProjectListMoreButtonWidth>
                            <MoreButton
                                isLoading={isFetching}
                                isShow={hasNextPage}
                                onClick={() => {
                                    fetchNextPage().then(() => {});
                                }}
                            />
                        </ProjectListMoreButtonWidth>
                    </ProjectListMoreButtonContainer>
                </>
            </ApiResBoundary>
        </ProjectListWrapper>
    );
};

export default React.memo(ProjectList, (prev, next) => {
    return shallowEqual(prev, next);
});

ProjectList.defaultProps = {
    projectListApiParam: {
        sort_type: "popular",
        list_type: "new_list",
        project_erc: "",
        project_network: "",
        category_no: "",
        brand_id: BRAND_ID_SOOP,
        limit: 4,
    },
    isShowAddBtn: false,
    hasContainerTopMargin: true,
};
