import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../../../../../../constants/UrlConstants";
import IncludeIconButton from "../../../../../../../../../../../common/commonUnitComponent/includeIconButton/IncludeIconButton";
import { useItemTitleRegisterDelete } from "../../../../../../../../../../../../hooks/item/useItemTitleRegisterDelete";
import { queryClient } from "../../../../../../../../../../../../App";
import CollectionCommonStateAtom from "../../../../../../../../../../../../store/commonState/CollectionCommonStateAtom";
import { useAtom } from "jotai";

export const WalletCollectionHubListTypeItemRepBtnContainer = styled.div``;

type Props = {
    userIdx: string;
    itemIdx: string;
    isTitleItem: boolean;
};

const WalletCollectionHubListTypeItemRepBtn = (
    props: Props,
): ReactElement | null => {
    const [collectionCommonState] = useAtom(CollectionCommonStateAtom);
    // collection 은 따로 refetch
    const refetchItemListTitleItemListQuery = async () => {
        await queryClient.refetchQueries({
            queryKey: collectionCommonState.collectionItemListQueryKey,
            refetchPage: () => true,
        });
        await queryClient.refetchQueries({
            queryKey: "title-item-list-" + props.userIdx,
            refetchPage: () => true,
        });
    };

    const { itemTitleRegisterDelete } = useItemTitleRegisterDelete(
        props.itemIdx,
        props.isTitleItem,
        async () => {
            //
        },
    );

    return (
        <IncludeIconButton
            isActive={props.isTitleItem}
            clickEventHandler={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await itemTitleRegisterDelete();
                await refetchItemListTitleItemListQuery();
            }}
            hasBorder={true}
            padding={"4px 10px 4px 6px"}
            bgColor={"rgba(13, 13, 26, 0.04)"}
            activeIconColor={"#FFC300"}
            hoverColor={"rgba(13, 13, 26, 0.08)"}
            icon={`${URL_CONSTANTS.ASSET_URL}/icon/icon-favorite-blank.svg`}
            title={"대표아이템"}
        />
    );
};

export default WalletCollectionHubListTypeItemRepBtn;
