import { useQuery } from "react-query";
import { ItemApi } from "../../apis/ItemApi";

export type ItemDetailDataType = {
    [key: string]: string | number | any;
    creatorInfo: ItemDetailDataCreatorInfoType;
    analysisInfo: ItemDetailDataAnalysisInfoType;
    itemInfo: ItemDetailDataItemInfoType;
};

export type ItemDetailDataCreatorInfoType = {
    [key: string]: string | number | any;
    creator_name: string;
    creator_idx: string;
    creator_id: string;
    creator_img_full_path: string;
    creator_follow_cnt: number;
    creator_description: string;
    follow_bool: boolean;
    user_influencer_bool: boolean;
    creator_type: string;
};

export type ItemDetailDataAnalysisInfoType = {
    [key: string]: string | number | any;
    project_currency: string;
    txid: string;
    tokenId: string;
    project_network: string;
    project_erc: number;
    brand_royalty: number;
    creator_royalty: number;
    item_status: string;
    item_owner_cnt: number;
    item_cnt: number;
    item_created_at: string;
    item_today_volume: number;
    item_yesterday_volume: number;
    item_all_volume: number;
};

export type ItemDetailDataItemInfoType = {
    [key: string]: string | number | any;
    item_idx: string;
    thumbnail: string;
    origin_full_path: string;
    thumbnail_full_path: string;
    brand_bi_full_path: string;
    profile_thumbnail: string;
    item_name: string;
    item_cnt: number;
    item_owned_bool: boolean;
    tokenId: string;
    project_idx: string;
    project_name: string;
    project_network: string;
    project_erc: string;
    brand_royalty: number;
    creator_royalty: number;
    project_currency: string;
    project_title_url: string;
    item_owner_cnt: number;
    like_cnt: number;
    like_bool: boolean;
    item_description: string;
    item_status: string;
    inventory_type: string;
    countable_bool: boolean;
    transaction_bool: boolean;
};

export const useItemDataQuery = (item_idx: string) => {
    const queryResult = useQuery<ItemDetailDataType, Error>({
        queryKey: ["item-data-" + item_idx, "like-refetch-list-item"],
        queryFn: () => ItemApi.getDetailData(item_idx),
        refetchOnWindowFocus: false,
        suspense: false,
        onError: () => {
            return [];
        },
    });

    const defaultData: ItemDetailDataType = {
        creatorInfo: {
            creator_name: "",
            creator_id: "",
            creator_idx: "0",
            creator_img_full_path: "",
            creator_follow_cnt: 0,
            creator_description: "",
            follow_bool: false,
            user_influencer_bool: false,
            creator_type: "brand",
        },
        analysisInfo: {
            project_currency: "",
            txid: "",
            tokenId: "0",
            project_network: "",
            project_erc: 0,
            brand_royalty: 0,
            creator_royalty: 0,
            item_status: "1",
            item_owner_cnt: 0,
            item_cnt: 0,
            item_created_at: "0",
            item_today_volume: 0,
            item_yesterday_volume: 0,
            item_all_volume: 0,
        },
        itemInfo: {
            item_idx: "0",
            thumbnail: "",
            origin_full_path: "",
            thumbnail_full_path: "",
            brand_bi_full_path: "",
            profile_thumbnail: "",
            item_name: "",
            item_cnt: 0,
            item_owned_bool: false,
            tokenId: "0",
            project_idx: "0",
            project_name: "",
            project_network: "",
            project_title_url: "",
            project_erc: "",
            brand_royalty: 0,
            creator_royalty: 0,
            project_currency: "",
            item_owner_cnt: 0,
            like_cnt: 0,
            like_bool: false,
            item_description: "",
            item_status: "0",
            inventory_type: "0",
            countable_bool: false,
            transaction_bool: false,
        },
    };

    return {
        ...queryResult,
        data: queryResult.data || defaultData,
    };
};
