import styled from "styled-components";
import Slider from "react-slick";

export const CarouselContainer = styled.div`
    position: relative;
`;

export const CarouselSlider = styled(Slider)<{
    isWindowTransparent: boolean;
    IndicatorBottomPositionList: string[] | undefined;
}>`
    .slick-dots {
        ${({ IndicatorBottomPositionList }) =>
            IndicatorBottomPositionList
                ? `bottom: ${IndicatorBottomPositionList[0]}`
                : ""};
        @media ${(props) => props.theme.PCM} {
            ${({ IndicatorBottomPositionList }) =>
                IndicatorBottomPositionList
                    ? `bottom: ${IndicatorBottomPositionList[1]}`
                    : ""};
        }

        @media ${(props) => props.theme.tabletL} {
            ${({ IndicatorBottomPositionList }) =>
                IndicatorBottomPositionList
                    ? `bottom: ${IndicatorBottomPositionList[2]}`
                    : ""};
        }

        @media ${(props) => props.theme.tabletM} {
            ${({ IndicatorBottomPositionList }) =>
                IndicatorBottomPositionList
                    ? `bottom: ${IndicatorBottomPositionList[3]}`
                    : ""};
        }

        @media ${(props) => props.theme.mobileL} {
            ${({ IndicatorBottomPositionList }) =>
                IndicatorBottomPositionList
                    ? `bottom: ${IndicatorBottomPositionList[4]}`
                    : ""};
        }

        @media ${(props) => props.theme.mobileM} {
            ${({ IndicatorBottomPositionList }) =>
                IndicatorBottomPositionList
                    ? `bottom: ${IndicatorBottomPositionList[5]}`
                    : ""};
        }
        transition: bottom 200ms ease;
    }

    .slick-dots li {
        width: 8px;
        height: 10px;
        margin: 0 6px;
    }
`;

export const CarouselDefaultArrowButton = styled.button<{
    direction: string;
}>`
    position: absolute;
    z-index: 2;
    ${({ direction }) => {
        return direction === "left" ? { left: "20px" } : { right: "20px" };
    }}
    cursor: pointer;
    border: 1px black solid;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    transition: all 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            transform: scale(1.1);
        }
    }
`;

export const CarouselIndicator = styled.div<{
    length: number;
    currentIndex: number;
    indicatorIndex: number;
    color?: string;
}>`
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: 2;

    border-radius: 7px;
    background-color: ${({ indicatorIndex, currentIndex, color }) => {
        if (indicatorIndex === currentIndex) return color ? color : "#000000";
        else return color ? color + "80" : "#00000020";
    }};

    cursor: pointer;
`;
