import { ReactElement, useState } from "react";
import styled from "styled-components";
import { TUTORIAL_INFO_LIST } from "../../TutorialInfoConstants";
import TutorialUpperCategory from "./components/TutorialUpperCategory";
import TutorialSubCategory from "./components/TutorialSubCategory";
import {
    TutorialInfoListType,
    TutorialSubCategoryInfoType,
} from "../../TutorialModal";

export const TutorialCategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TutorialSubCategoryWrapper = styled.div<{
    isSelected: boolean;
}>`
    padding: ${({ isSelected }) => (isSelected ? "6px 0" : 0)};
    max-height: ${({ isSelected }) => (isSelected ? "300px" : 0)};
    overflow: hidden;
    transition: all 300ms ease;
`;

type Props = {
    infoList: TutorialInfoListType;
    openUpperCategory: (value: string) => void;
    selectSubCategory: (value: string) => void;
    setIsSelectOnMobile: (value: boolean) => void;
};

const TutorialCategory = (props: Props): ReactElement | null => {
    return (
        <TutorialCategoryContainer>
            <TutorialUpperCategory
                title={props.infoList.upperCategoryTitle}
                icon={props.infoList.upperCategoryIcon}
                upperCategoryKey={props.infoList.upperCategoryKey}
                isOpen={props.infoList.isOpen}
                openUpperCategory={props.openUpperCategory}
            />
            <TutorialSubCategoryWrapper isSelected={props.infoList.isOpen}>
                {props.infoList.subCategoryList.map(
                    (subCategory, subCategoryIdx) => (
                        <TutorialSubCategory
                            key={subCategoryIdx}
                            subCategory={subCategory}
                            selectSubCategory={props.selectSubCategory}
                            setIsSelectOnMobile={props.setIsSelectOnMobile}
                        />
                    ),
                )}
            </TutorialSubCategoryWrapper>
        </TutorialCategoryContainer>
    );
};

export default TutorialCategory;
