import { useOpenModal } from "../../../../../../hooks/modal/useOpenModal";
import WebPushInduceModal from "./WebPushInduceModal";

export function useOpenWebPushInduceModal() {
    const { openModal } = useOpenModal();
    const openWebPushInduceModal = async () => {
        return await openModal("web-push-induce-modal", "modal", (resolve) => {
            return <WebPushInduceModal resolve={resolve} />;
        });
    };

    return {
        openWebPushInduceModal,
    };
}
