import { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { ItemListItemType } from "../../../../../../../query/item/useItemListQuery";
import { onErrorImg } from "../../../../../../../utils/Utils";
import SquareImg from "../../../../../../common/commonUiComponent/squqreImg/SquareImg";

export const MarketItemBottomContentsItemContainer = styled(Link)`
    width: 100%;
`;

export const MarketItemBottomContentsItemThumbnailContainer = css`
    border-radius: 16px;
    overflow: hidden;
`;

export const MarketItemBottomContentsItemTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */

    text-align: center;
    color: #222222;
    padding-top: 20px;
`;

type Props = {
    data: ItemListItemType;
};

const MarketItemBottomContentsItem = (props: Props): ReactElement | null => {
    const params = useParams();
    const projectIdx = params.project_id ? params.project_id : "0";
    return (
        <MarketItemBottomContentsItemContainer
            to={`/market/${projectIdx}/${props.data.item_idx}`}
        >
            <SquareImg
                src={props.data.item_thumbnail_full_path}
                customStyle={MarketItemBottomContentsItemThumbnailContainer}
                imageResizingPostfix={"_250x250.webp"}
            />

            <MarketItemBottomContentsItemTitle>
                {props.data.item_name}
            </MarketItemBottomContentsItemTitle>
        </MarketItemBottomContentsItemContainer>
    );
};

export default MarketItemBottomContentsItem;
