import React, { ReactElement } from "react";
import styled from "styled-components";
import RightSideBarCommunity from "./rightSideBarCommunity/RightSideBarCommunity";
import RightSideBarInfo from "./rightSideBarInfo/RightSideBarInfo";
import Scrollbar from "../../scrollbar/Scrollbar";
import { SCROLL_BAR_NORMAL_TYPE } from "../../scrollbar/ScrollbarType";

export const RightSideBarContainer = styled.div`
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    height: calc(100vh - 80px);
    overflow: hidden;
`;

export const RightSideBarWrapper = styled.div``;

export function convertAnalysisInfoKeyList(
    analysisInfo: any,
    analysisList: any[],
) {
    return analysisList.map((analysisListItem) => {
        switch (analysisListItem.key) {
            case "royalty":
                return {
                    ...analysisListItem,
                    value: analysisInfo
                        ? analysisInfo.creator_royalty +
                          analysisInfo.brand_royalty
                        : 0,
                };

            case "avg_click_cnt_30days/avg_expose_cnt_30days":
                return {
                    ...analysisListItem,
                    value:
                        analysisInfo.avg_expose_cnt_10rounds !== 0 &&
                        analysisInfo
                            ? Math.round(
                                  (analysisInfo.avg_click_cnt_10rounds /
                                      analysisInfo.avg_expose_cnt_10rounds) *
                                      10000,
                              ) / 100
                            : null,
                };

            default:
                return {
                    ...analysisListItem,
                    value: analysisInfo
                        ? analysisInfo[analysisListItem.key]
                        : 0,
                };
        }
    });
}

type Props = {
    analysisInfoKeyList: any[];
    data: any;
    refetch: () => void;
};

const RightSideBar = (props: Props): ReactElement | null => {
    return (
        <RightSideBarContainer>
            <Scrollbar id={"right-side-bar"} type={SCROLL_BAR_NORMAL_TYPE}>
                <RightSideBarWrapper>
                    <RightSideBarInfo
                        creatorInfo={props.data.creatorInfo}
                        analysisData={convertAnalysisInfoKeyList(
                            props.data.analysisInfo,
                            props.analysisInfoKeyList,
                        )}
                        refetchGetCreatorInfo={props.refetch}
                    />
                    <RightSideBarCommunity />
                </RightSideBarWrapper>
            </Scrollbar>
        </RightSideBarContainer>
    );
};

export default RightSideBar;
