import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../utils/Utils";
import CommonInputText, { InputTextDataType } from "./CommonInputText";

export const CommonInputTextPriceContainer = styled.div``;

type Props = {
    inputNumber: InputTextDataType;
    setInputNumber: (value: InputTextDataType) => void;
    focusOutEventHandler?: () => Promise<void>;

    max: number;
    min: number;

    title: string;
    postPositionalTitle: string;
    unit: string;

    disabled?: boolean;
    isThousandCheck: boolean; // 1000원 절삭 체크
};

const CommonInputTextNumber = (props: Props): ReactElement | null => {
    const inputPriceNumber = Number.parseInt(
        props.inputNumber.value.replace(/,/g, ""),
    );

    useEffect(() => {
        if (!props.inputNumber.isWarning) {
            if (isNaN(inputPriceNumber)) {
                props.setInputNumber({
                    ...props.inputNumber,
                    isWarning: true,
                    warningMsg: `반드시 작성되어야 하는 영역입니다.`,
                });
            } else if (inputPriceNumber > props.max) {
                props.setInputNumber({
                    ...props.inputNumber,
                    isWarning: true,
                    warningMsg: `${props.title}${
                        props.postPositionalTitle
                    } 최대 ${numberToStringWithComma(props.max)}${
                        props.unit
                    } 이하이여야 합니다.`,
                });
            }
            // 최소 금액
            else if (inputPriceNumber < props.min) {
                props.setInputNumber({
                    ...props.inputNumber,
                    isWarning: true,
                    warningMsg: `${props.title}${
                        props.postPositionalTitle
                    } 최소 ${numberToStringWithComma(props.min)}${
                        props.unit
                    } 이상이여야 합니다.`,
                });
            }
            // 1000 단위아닌 경우 오류만 노출
            else if (props.isThousandCheck && inputPriceNumber % 1000 !== 0) {
                props.setInputNumber({
                    ...props.inputNumber,
                    isWarning: true,
                    warningMsg: `${props.title}${props.postPositionalTitle} 1,000${props.unit} 단위로 입력이 가능합니다.`,
                });
            }
        }
    }, [props.inputNumber]);

    return (
        <CommonInputText
            inputText={props.inputNumber}
            setInputText={props.setInputNumber}
            maxLength={-1}
            minLength={-1}
            isOnlyNumber={true}
            isRightWrite={true}
            isDescRight={true}
            nanExceptionValue={""}
            disabled={props.disabled}
            focusOutEventHandler={async () => {
                if (props.focusOutEventHandler) {
                    await props.focusOutEventHandler();
                }
                if (!isNaN(inputPriceNumber)) {
                    props.setInputNumber({
                        ...props.inputNumber,
                        value: numberToStringWithComma(inputPriceNumber),
                    });
                }
            }}
        />
    );
};

export default CommonInputTextNumber;
