import { ReactElement } from "react";
import styled from "styled-components";
import { TableHeaderDataType } from "../../../../../../../../../../../common/commonUnitComponent/commonTable/CommonTable";
import { ItemListItemType } from "../../../../../../../../../../../../query/item/useItemListQuery";
import {
    getItemPrice,
    getItemStatusKR,
} from "../../../../../../../../walletCollectionItemListItem/WalletCollectionItem";
import { numberToStringWithComma } from "../../../../../../../../../../../../utils/Utils";
import { COLLECTION_PRIVATE_TAB } from "../../../../../../../WalletCollectionHub";
import { useIsMe } from "../../../../../../../../../../../../hooks/common/useIsMe";
import { Link } from "react-router-dom";

export const WalletCollectionHubListTypeMobileItemListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px 40px;

    @media ${(props) => props.theme.mobileL} {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 24px;
    }
`;

export const WalletCollectionHubListTypeMobileItemListItemContainer = styled(
    Link,
)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 32px;
    border-bottom: 1px #ebebeb solid;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        padding-bottom: 24px;
    }
`;

export const WalletCollectionHubListTypeMobileItemListItemThreeDotContainer = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
`;

export const WalletCollectionHubListTypeMobileItemListItemInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const WalletCollectionHubListTypeMobileItemListItemInfoRow = styled.div`
    display: flex;
`;

export const WalletCollectionHubListTypeMobileItemListItemInfoRowKey = styled.div`
    display: flex;
    width: 100px;
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const WalletCollectionHubListTypeMobileItemListItemInfoRowValue = styled.div`
    display: flex;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const WalletCollectionHubListTypeMobileItemListItemBtnContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: flex-start;
`;

type Props = {
    tableHeaderData: TableHeaderDataType[];
    tableBodyData: any[];
    collectionItemList: ItemListItemType[];
    userIdx: string;
    selectedSubKey: string;
};

const WalletCollectionHubListTypeMobileItemList = (
    props: Props,
): ReactElement | null => {
    const isMe = useIsMe();

    return (
        <WalletCollectionHubListTypeMobileItemListContainer>
            {props.tableBodyData.map((data, idx) => (
                <WalletCollectionHubListTypeMobileItemListItemContainer
                    to={`/market/${props.collectionItemList[idx].project_idx}/${props.collectionItemList[idx].item_idx}`}
                    key={idx}
                >
                    {isMe(props.userIdx) && (
                        <WalletCollectionHubListTypeMobileItemListItemThreeDotContainer>
                            {data.threeDotColumn}
                        </WalletCollectionHubListTypeMobileItemListItemThreeDotContainer>
                    )}
                    {data.itemNameColumn}

                    <WalletCollectionHubListTypeMobileItemListItemInfoContainer>
                        <WalletCollectionHubListTypeMobileItemListItemInfoRow>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                                수량
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                                {numberToStringWithComma(
                                    props.collectionItemList[idx].own_cnt || 0,
                                )}
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                        </WalletCollectionHubListTypeMobileItemListItemInfoRow>
                        <WalletCollectionHubListTypeMobileItemListItemInfoRow>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                                가격 (ST)
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                                {getItemPrice(
                                    props.collectionItemList[idx].item_status,
                                    props.collectionItemList[idx].low_price,
                                    props.collectionItemList[idx]
                                        .last_transaction_price,
                                    props.collectionItemList[idx]
                                        .project_currency,
                                )}
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                        </WalletCollectionHubListTypeMobileItemListItemInfoRow>
                        <WalletCollectionHubListTypeMobileItemListItemInfoRow>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                                판매 상태
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                                {getItemStatusKR(
                                    props.collectionItemList[idx].item_status,
                                )}
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                        </WalletCollectionHubListTypeMobileItemListItemInfoRow>
                        <WalletCollectionHubListTypeMobileItemListItemInfoRow>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                                아이템 종류
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                                {props.collectionItemList[idx].project_network}
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                        </WalletCollectionHubListTypeMobileItemListItemInfoRow>
                        <WalletCollectionHubListTypeMobileItemListItemInfoRow>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                                토큰 종류
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowKey>
                            <WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                                {props.collectionItemList[idx].own_cnt}
                            </WalletCollectionHubListTypeMobileItemListItemInfoRowValue>
                        </WalletCollectionHubListTypeMobileItemListItemInfoRow>
                    </WalletCollectionHubListTypeMobileItemListItemInfoContainer>

                    <WalletCollectionHubListTypeMobileItemListItemBtnContainer>
                        {data.likeCountColumn}
                        {!(
                            props.selectedSubKey === COLLECTION_PRIVATE_TAB ||
                            !isMe(props.userIdx)
                        ) && data.titleItemColumn}
                    </WalletCollectionHubListTypeMobileItemListItemBtnContainer>
                </WalletCollectionHubListTypeMobileItemListItemContainer>
            ))}
        </WalletCollectionHubListTypeMobileItemListContainer>
    );
};

export default WalletCollectionHubListTypeMobileItemList;
