import { checkUserAgent } from "../../utils/Utils";
import { useOpenCommonErrorModal } from "../modal/openModal/useOpenCommonErrorModal";
import { useOpenAppInstallModal } from "../modal/openModal/useOpenAppInstalModal";
import deferredPromptAtom from "../../store/commonState/deferredPromptAtom";
import { useAtom } from "jotai";

export const useA2HS = () => {
    const [deferredPrompt, setDeferredPrompt] = useAtom(deferredPromptAtom);
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const { openAppInstallModal } = useOpenAppInstallModal();

    const installApp = () => {
        // 설치 메서드 실행
        deferredPrompt?.prompt();
        deferredPrompt?.userChoice.then((choiceResult: any) => {
            clearPrompt();
        });
    };

    const clearPrompt = () => {
        setDeferredPrompt(null);
    };

    const commonInstallApp = async () => {
        if (deferredPrompt !== null) {
            installApp();
        }
        // 설치 불가
        else {
            // iOS 인 경우 설치 팝업
            if (checkUserAgent.isIOS()) {
                await openAppInstallModal();
            }
            // android, window 에서는 설치 불가 모달
            else {
                await openCommonErrorModal({
                    title: "이미 다운로드 하셨거나, 설치 불가 브라우저입니다.",
                });
            }
        }
    };

    const isAppInstallBtnShow =
        // iOS 인 경우 홈화면에 추가로 들어오지 않은 경우
        // 홈화면에 추가로 들어온 경우 standalone 값 true
        // 값이 없을 수 있음
        (checkUserAgent.isIOS() &&
            ("standalone" in navigator ? !navigator.standalone : true)) ||
        // 설치가 가능한 경우
        deferredPrompt !== null;

    return {
        deferredPrompt,
        installApp,
        clearPrompt,
        commonInstallApp,
        isAppInstallable: deferredPrompt !== null,
        isAppInstallBtnShow,
    };
};
