import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import axios from "axios";
import {
    MyItemExchangeListApiResultType,
    MyItemExchangeListParams,
} from "../../query/my/myItem/useGetMyItemExchangeListQuery";
import {
    MyItemOrderListApiParams,
    MyItemOrderListApiResultType,
} from "../../query/my/myItem/useGetMyItemOrderListQuery";

export const MyMarketItemApi = {
    getMyItemExchangeList: async (
        params: MyItemExchangeListParams,
    ): Promise<MyItemExchangeListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/my/exchange/list", {
                    params: {
                        ...params,
                        product_type: "3",
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMyItemOrderList: async (
        params: MyItemOrderListApiParams,
    ): Promise<MyItemOrderListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/my/order/item/list", {
                    params: {
                        ...params,
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
