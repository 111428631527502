import { ReactElement } from "react";
import styled, { css } from "styled-components";

export const AdManageCommonAnalysisTableModalTableRow = styled.div<{
    isHeader: boolean;
}>`
    display: flex;
    width: ${({ isHeader }) =>
        isHeader ? "calc(100% - 20px)" : "calc(100% - 32px)"};
    padding: ${({ isHeader }) => (isHeader ? "10px 10px" : "16px 10px")};
    gap: 8px;
    border-bottom: 1px #f2f2f2 solid;
    align-items: center;

    @media ${(props) => props.theme.mobileM} {
        gap: 0;
    }
`;

export const AdManageCommonAnalysisTableModalHeaderStyle = css`
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
`;

export const AdManageCommonAnalysisTableModalListKeyStyle = css<{
    isBold: boolean;
}>`
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: ${({ isBold }) => (isBold ? 700 : 500)};
    line-height: 140%; /* 18.2px */
`;

export const AdManageCommonAnalysisTableModalListValueStyle = css<{
    isBold: boolean;
}>`
    color: ${({ isBold }) => (isBold ? "#FA54FA" : "#555")};
    font-size: 12px;
    font-style: normal;
    font-weight: ${({ isBold }) => (isBold ? 700 : 500)};
    line-height: 100%; /* 12px */
`;

export const AdManageCommonAnalysisTableModalAdTitleColumn = styled.div<{
    isHeader: boolean;
    isBold: boolean;
}>`
    text-align: left;
    width: 176px;
    ${({ isHeader }) =>
        isHeader
            ? AdManageCommonAnalysisTableModalHeaderStyle
            : AdManageCommonAnalysisTableModalListKeyStyle};

    @media ${(props) => props.theme.mobileL} {
        flex-grow: 2;
    }
`;

export const AdManageCommonAnalysisTableModalClickCntColumn = styled.div<{
    isHeader: boolean;
    isBold: boolean;
}>`
    flex-shrink: 0;
    text-align: right;
    width: 80px;
    ${({ isHeader }) =>
        isHeader
            ? AdManageCommonAnalysisTableModalHeaderStyle
            : AdManageCommonAnalysisTableModalListValueStyle};
`;

export const AdManageCommonAnalysisTableModalExposeCntColumn = styled.div<{
    isHeader: boolean;
    isBold: boolean;
}>`
    flex-shrink: 0;
    text-align: right;
    width: 80px;
    ${({ isHeader }) =>
        isHeader
            ? AdManageCommonAnalysisTableModalHeaderStyle
            : AdManageCommonAnalysisTableModalListValueStyle};
`;

export const AdManageCommonAnalysisTableModalRateColumn = styled.div<{
    isHeader: boolean;
    isBold: boolean;
}>`
    flex-shrink: 0;
    text-align: right;
    width: 52px;
    ${({ isHeader }) =>
        isHeader
            ? AdManageCommonAnalysisTableModalHeaderStyle
            : AdManageCommonAnalysisTableModalListValueStyle};
`;

type Props = {
    isHeader: boolean;
    isBold: boolean;
    title: string;
    clickCnt: string;
    exposeCnt: string;
    rate: string;
};

const AdManageCommonAnalysisTableModalSpaceInfoRow = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonAnalysisTableModalTableRow isHeader={props.isHeader}>
            <AdManageCommonAnalysisTableModalAdTitleColumn
                isHeader={props.isHeader}
                isBold={props.isBold}
            >
                {props.title}
            </AdManageCommonAnalysisTableModalAdTitleColumn>
            <AdManageCommonAnalysisTableModalClickCntColumn
                isHeader={props.isHeader}
                isBold={props.isBold}
            >
                {props.clickCnt}
            </AdManageCommonAnalysisTableModalClickCntColumn>
            <AdManageCommonAnalysisTableModalExposeCntColumn
                isHeader={props.isHeader}
                isBold={props.isBold}
            >
                {props.exposeCnt}
            </AdManageCommonAnalysisTableModalExposeCntColumn>
            <AdManageCommonAnalysisTableModalRateColumn
                isHeader={props.isHeader}
                isBold={props.isBold}
            >
                {props.rate}
            </AdManageCommonAnalysisTableModalRateColumn>
        </AdManageCommonAnalysisTableModalTableRow>
    );
};

export default AdManageCommonAnalysisTableModalSpaceInfoRow;
