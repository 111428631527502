import { useOpenModal } from "../../../../../../hooks/modal/useOpenModal";
import WebPushDeniedConfirmModal from "./WebPushDeniedConfirmModal";

export function useOpenWebPushDeniedConfirmModal() {
    const { openModal } = useOpenModal();
    const openWebPushDeniedConfirmModal = async () => {
        return await openModal(
            "web-push-denied-confirm-modal",
            "modal",
            (resolve) => {
                return <WebPushDeniedConfirmModal resolve={resolve} />;
            },
        );
    };

    return {
        openWebPushDeniedConfirmModal,
    };
}
