import { hasPathAfterNestedResolve, useOpenModal } from "../useOpenModal";
import ModalComponentInfoListAtom from "../../../store/ui/ModalComponentInfoListAtom";
import { useAtom } from "jotai";
import UserProfileModal from "../../../components/common/modal/userProfileModal/UserProfileModal";
import TutorialModal from "../../../components/common/modal/tutorialModal/TutorialModal";
import { TUTORIAL_INFO_LIST } from "../../../components/common/modal/tutorialModal/TutorialInfoConstants";
import { useOpenAdManageUrlModal } from "./useOpenAdManageUrlModal";

export function useOpenTutorialModal() {
    const { openModalWithReturnValue } = useOpenModal();

    return {
        openTutorialModal: async () => {
            return await openModalWithReturnValue(
                "tutorial-modal",
                "bottomSheet",
                (resolve) => {
                    return (
                        <TutorialModal
                            resolve={(value: any) => {
                                resolve(value);
                            }}
                        />
                    );
                },
            );
        },
    };
}
