import { ReactElement, useEffect } from "react";
import { useWindowSize } from "usehooks-ts";
import styled from "styled-components";
import { HeaderLeftSlideTransition } from "../HeaderSearchAreaStyle";
import { useLocation } from "react-router-dom";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";
import isDarkModeAtom from "../../../../../../../store/ui/isDarkModeAtom";
import { useAtom } from "jotai";
import {
    GLOBAL_SEARCH_RESULT_MODAL,
    useOpenSearchResultModal,
} from "../../../../../../../hooks/modal/openModal/useOpenSearchResultModal";
import globalSearchInputAtom from "../../../../../../../store/globalSearch/globalSearchInputAtom";
import ModalComponentInfoListAtom from "../../../../../../../store/ui/ModalComponentInfoListAtom";

export const HeaderSearchInputContainer = styled.div<{ isSearching: boolean }>`
    height: 40px;
    @media ${(props) => props.theme.tabletL} {
        position: fixed;
        top: ${({ isSearching }) => (isSearching ? 0 : "-100px")};
        width: calc(100% - 26px);
        height: 60px;
        padding: 0 16px 0 6px;

        z-index: 3;
        background: white;

        display: flex;
        align-items: center;
        overflow: hidden;

        ${HeaderLeftSlideTransition};
    }
`;

export const HeaderSearchInputCancelIconImg = styled.img<{
    isShow: boolean;
    isSearching: boolean;
}>`
    width: 28px;
    height: 28px;

    z-index: 2;
    cursor: pointer;

    display: ${({ isShow, isSearching }) =>
        isShow && isSearching ? "block" : "none"};

    position: absolute;
    top: 6px;
    right: 56px;

    @media ${(props) => props.theme.tabletL} {
        width: 24px;
        height: 24px;
        top: 20px;
        right: 30px;
        display: ${({ isShow, isSearching }) =>
            isShow && isSearching ? "block" : "none"};
    }
`;
export const HeaderSearchMobileBackIconImg = styled.img<{
    isSearching: boolean;
}>`
    width: 0;
    height: 32px;
    display: none;
    transition: ${({ isSearching }) =>
        isSearching ? "none" : "width 50ms ease 500ms"};
    cursor: pointer;
    @media ${(props) => props.theme.tabletL} {
        display: block;
        ${({ isSearching }) => {
            if (isSearching)
                return `
                    width: 40px;      
                    height: 40px; 
                    margin-right: 8px;
            `;
        }};
    }
`;

export const HeaderSearchInputTag = styled.input<{
    isSearching: boolean;
    isDarkMode: boolean;
    searchInput: string;
}>`
    width: 242px;
    height: 36px;
    padding-left: 20px;
    padding-right: 85px;
    vertical-align: middle;
    z-index: 1;
    border-radius: 22px;

    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    outline: none;

    &:focus {
        outline: none;
    }

    transition: all 300ms ease;

    @media ${(props) => props.theme.tabletL} {
        color: black;
        width: calc(100% - 40px);
        background: #f0f1f2;
        border-radius: 22px;

        ::placeholder {
            color: rgba(0, 0, 0, 0.3);
        }
    }

    ${({ isDarkMode, isSearching }) => {
        if (isDarkMode) {
            if (isSearching) {
                return `
                    background: white;
                    color: black;
                    border: 1px solid #FA54FA;
              `;
            } else {
                return `
                    background: rgba(255, 255, 255, 0.12);
                    border: 1px solid transparent;
              `;
            }
        } else {
            if (isSearching) {
                return `
                    background: white;
                    color: black;
                    border: 1px solid #FA54FA;
              `;
            } else {
                return `
                    background: #fff;
                    border: 1px solid #E5E5E5;
              `;
            }
        }
    }}
    ::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        /* identical to box height, or 16px */

        display: flex;
        align-items: center;

        ${({ isDarkMode, isSearching }) => {
            if (isDarkMode) {
                if (isSearching) {
                    return `
                    color: #0000004D;
              `;
                } else {
                    return `
                    color: #FFFFFF40;
              `;
                }
            } else {
                if (isSearching) {
                    return `
                    color: #0000004D;
              `;
                } else {
                    return `
                    color: #00000040;
              `;
                }
            }
        }}
    }
`;
type Props = {};

const HeaderSearchInput = (props: Props): ReactElement | null => {
    const [isDarkMode] = useAtom(isDarkModeAtom);
    const [globalSearchInput, setGlobalSearchInput] = useAtom(
        globalSearchInputAtom,
    );
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const searchResultModalInfo = modalComponentInfoList.find(
        (info) => info.id === GLOBAL_SEARCH_RESULT_MODAL,
    );

    const { openSearchResultModal, isOpenSearchResultModal } =
        useOpenSearchResultModal();

    const isSearching = isOpenSearchResultModal;
    const location = useLocation();
    const { width } = useWindowSize();

    useEffect(() => {
        setGlobalSearchInput("");
    }, [location.pathname, isSearching]);

    return (
        <HeaderSearchInputContainer isSearching={isSearching}>
            <HeaderSearchInputCancelIconImg
                onClick={() => {
                    setGlobalSearchInput("");
                }}
                isSearching={isSearching}
                isShow={globalSearchInput !== ""}
                src={
                    width > 1280 && width !== 0
                        ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-search-cancel.png`
                        : `${URL_CONSTANTS.ASSET_URL}/icon/icon-search-cancel-mobile.svg`
                }
            />

            <HeaderSearchMobileBackIconImg
                isSearching={isSearching}
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-arrow-back.png`}
                onClick={() => {
                    searchResultModalInfo?.resolve(-1);
                }}
            />

            <HeaderSearchInputTag
                id={"header-search-input"}
                autoComplete={"off"}
                isDarkMode={isDarkMode}
                placeholder={"검색어를 입력해주세요."}
                isSearching={isSearching}
                searchInput={globalSearchInput}
                type="text"
                value={globalSearchInput}
                onChange={(e) => {
                    if (e.target.value.length < 26) {
                        setGlobalSearchInput(e.target.value);
                    }
                }}
                onFocus={async () => {
                    if (!isSearching) {
                        await openSearchResultModal();
                    }
                }}
            />
        </HeaderSearchInputContainer>
    );
};

export default HeaderSearchInput;
