import { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalInfoContainer,
} from "../../../../commonUnitComponent/modal/ModalStyle";
import {
    InfoTableColumnBoldValue,
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
} from "../../../../../../styles/InfoTableStyle";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import { useItemDataQuery } from "../../../../../../query/item/useItemDataQuery";
import { OrderItemReceiptInfoType } from "../OrderItemReceiptModal";

export const OrderItemReceiptSellCompleteInfoContainer = styled.div`
    width: 100%;
`;

type Props = {
    receiptInfo: OrderItemReceiptInfoType;
    resolve: (value: boolean) => void;
};

const OrderItemReceiptSellCompleteInfo = (
    props: Props,
): ReactElement | null => {
    const { data: rawItemData } = useItemDataQuery(
        props.receiptInfo.product_idx,
    );

    const itemSellRoyalty =
        (props.receiptInfo.unit_brand_royalty +
            props.receiptInfo.unit_creator_royalty) *
        props.receiptInfo.order_quantity;
    return (
        <OrderItemReceiptSellCompleteInfoContainer>
            <ModalInfoContainer width={"280px"} mt={"13px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        판매수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_quantity,
                            )}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        판매단가
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_unit_price,
                            )}
                            <InfoTableColumnUnit>
                                {rawItemData.itemInfo.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        판매수익
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_unit_price *
                                    props.receiptInfo.order_quantity,
                            )}
                            <InfoTableColumnUnit>
                                {rawItemData.itemInfo.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        판매로열티
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(itemSellRoyalty)}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                </InfoTableRowContainer>
            </ModalInfoContainer>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </ModalButton>
            </ModalButtonContainer>
        </OrderItemReceiptSellCompleteInfoContainer>
    );
};

export default OrderItemReceiptSellCompleteInfo;
