import { atom } from "jotai";

export type SnackBarDataAtomType = {
    title: string;
    desc: string;
    iconUrl: string;
    confirmBtnTitle: string;
    isInfinite?: boolean;
    confirmEventHandler?: (value?: any) => Promise<void>;
};

const SnackBarListAtom = atom<SnackBarDataAtomType[]>([]);

export default SnackBarListAtom;

export const asyncPushSnackBarListItemAtom = atom(
    null,
    async (get, set, newValue: SnackBarDataAtomType) => {
        if (get(SnackBarListAtom).length > 0) {
            const prevItem =
                get(SnackBarListAtom)[get(SnackBarListAtom).length - 1];
            if (
                prevItem.title !== newValue.title ||
                prevItem.desc !== newValue.desc ||
                prevItem.iconUrl !== newValue.iconUrl ||
                prevItem.confirmBtnTitle !== newValue.confirmBtnTitle
            ) {
                set(SnackBarListAtom, [...get(SnackBarListAtom), newValue]);
            }
        } else {
            set(SnackBarListAtom, [...get(SnackBarListAtom), newValue]);
        }
    },
);

let test: null | Promise<boolean> = null;

export const asyncPopSnackBarListItem = atom(null, async (get, set) => {
    set(SnackBarListAtom, get(SnackBarListAtom).slice(0, -1));
});

export const asyncInitSnackBarListItem = atom(null, async (get, set) => {
    set(SnackBarListAtom, []);
});
