import { InputTextDataType } from "../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import {
    DefaultExceptionUiHandlerType,
    ExceptionUiHandlerType,
} from "../../../../../apis/ErrorHandler/callApiExcpetionHandler";
import { useRef, useState } from "react";

export function useGetAdDaySalePrice(initInputData?: InputTextDataType) {
    const [adDaySalePrice, setAdDaySalePrice] = useState<InputTextDataType>(
        initInputData || {
            value: "1,000",
            isWarning: false,
            warningMsg: "",
        },
    );

    const adDaySalePriceContainerRef = useRef<HTMLDivElement>(null);

    const exceptionUiHandler: ExceptionUiHandlerType = {
        ...DefaultExceptionUiHandlerType,
        inputText: adDaySalePrice,
        setInputText: setAdDaySalePrice,
        errorTarget: {
            targetName: "AdMinPrice",
        },
        targetContainerRef: adDaySalePriceContainerRef,
        isDuplicateCheck: false,
    };

    return {
        adDaySalePriceState: {
            adDaySalePrice: adDaySalePrice,
            setAdDaySalePrice: setAdDaySalePrice,
            adDaySalePriceContainerRef: adDaySalePriceContainerRef,
        },
        adDaySalePriceExceptionUiHandler: exceptionUiHandler,
    };
}
