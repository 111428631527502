import { ReactElement } from "react";
import styled from "styled-components";
import CalendarSelectedMobileCellRow from "./CalendarSelectedMobileCellRow";

export const CalendarCellMobileSecondaryRowContainer = styled.div``;

type Props = {
    keyMsg: string;
    valueMsg: string;
};

const CalendarSelectedMobileCellSecondaryRow = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarSelectedMobileCellRow
            {...props}
            keyStyle={{
                color: "#555",
            }}
        />
    );
};

export default CalendarSelectedMobileCellSecondaryRow;
