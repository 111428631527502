import React, { ReactElement } from "react";
import styled from "styled-components";
import UserProfileModalUserLoginInfo from "./components/UserProfileModalUserLoginInfo";
import UserProfileModalUserPointList from "./components/UserProfileModalUserPointList";

export const UserProfileModalUserInfoContainer = styled.div`
    padding: 0 10px;
    margin-top: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media ${(props) => props.theme.tabletL} {
        border-radius: 0;
        gap: 20px;
        padding: 10px;
        margin: 0;
    }
`;

type Props = {
    resolve: (value: boolean | number) => void;
};

const UserProfileModalUserInfo = (props: Props): ReactElement | null => {
    return (
        <UserProfileModalUserInfoContainer>
            <UserProfileModalUserLoginInfo resolve={props.resolve} />
            <UserProfileModalUserPointList />
        </UserProfileModalUserInfoContainer>
    );
};

export default UserProfileModalUserInfo;
