import {ReactElement} from "react";
import styled, {keyframes} from "styled-components";
import {URL_CONSTANTS} from "../../../../constants/UrlConstants";

export const SpinnerContainer = styled.div<{ width: string; height: string }>`
    display: inline-block;
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerItem = styled.div<{
    order: number;
    borderSize: string;
    borderColor: string;
}>`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;

    animation: ${spin} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    nimation-delay: ${({ order }) => {
        switch (order) {
            case 0:
                return "animation-delay: -0.45s";
            case 1:
                return "animation-delay: -0.3s";
            case 2:
                return "animation-delay: -0.15s";
        }
    }};
`;

export const SpinnerItemIcon = styled.img<{ width: string }>`
    position: absolute;
    bottom: ${({ width }) => "calc(50% - " + width + ")"};
    left: ${({ width }) => "calc(50% - " + width + ")"};
    width: inherit;
    height: inherit;
`;

type Props = {
    width: number;
    height: number;
    borderSize: number;
    color: string;
};

const Spinner = (props: Props): ReactElement | null => {
    const array = [0];
    return (
        <SpinnerContainer
            width={props.width + "px"}
            height={props.height + "px"}
        >
            {array.map((num) => {
                return (
                    <SpinnerItem
                        order={num}
                        key={num}
                        borderSize={props.borderSize + "px"}
                        borderColor={props.color}
                    >
                        <SpinnerItemIcon
                            width={props.width / 2 + "px"}
                            src={
                                URL_CONSTANTS.ASSET_URL + "/icon/icon-spinner.svg"
                            }
                        />
                    </SpinnerItem>
                );
            })}
        </SpinnerContainer>
    );
};

export default Spinner;

Spinner.defaultProps = {
    borderSize: 7,
    height: 30,
    width: 30,
    color: "black",
};
