import { ReactElement } from "react";
import { AdSaleManageScheduleCalendarCellBodyBtn } from "../AdSaleManageScheduleCalendarCellBodyBtns";
import { useOpenAdSaleManageOfferListModal } from "../../../../../../../../../../../../hooks/modal/openModal/useOpenAdSaleManageOfferListModal";
import { AdDailyListItemStatusCodeType } from "../../../../../../../../../../../../types/ad/adDaily/AdDailyListItemType";

type Props = {
    adIdx: string;
    cellDate: Date;
    hasOfferSuccess: boolean;
    adDailyStatus: AdDailyListItemStatusCodeType;
};

const AdSaleManageScheduleCalendarCellBodyAcceptOfferBtn = (
    props: Props,
): ReactElement | null => {
    const { openAdSaleManageOfferListModal } =
        useOpenAdSaleManageOfferListModal();
    return (
        <AdSaleManageScheduleCalendarCellBodyBtn
            btnType={"filled-secondary"}
            onClick={() => {
                openAdSaleManageOfferListModal(
                    props.adIdx,
                    props.cellDate,
                    props.adDailyStatus,
                    props.hasOfferSuccess,
                );
            }}
        >
            {props.hasOfferSuccess ? "템플릿 보기" : "낙찰하기"}
        </AdSaleManageScheduleCalendarCellBodyBtn>
    );
};

export default AdSaleManageScheduleCalendarCellBodyAcceptOfferBtn;
