import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import axios from "axios";
import { OrderBuyParams } from "../../query/market/common/useOrderBuyMutation";
import { OrderSellParams } from "../../query/market/common/useOrderSellMutation";
import {
    GetSaleListParams,
    MarketSaleListApiResultType,
} from "../../query/market/common/useGetMarketSaleListQuery";
import {
    MarketSettlementListApiResultType,
    MarketSettlementListParamsType,
} from "../../query/market/common/useGetMarketSettlementListQuery";
import {
    MarketAveragePriceParams,
    MarketAveragePriceResultType,
} from "../../query/market/common/useGetMarketAveragePrice";
import { MarketHomeDonutListItemType } from "../../query/market/common/useGetMarketDonutDataQuery";

export const MarketCommonApi = {
    orderBuy: async (
        pageType: string,
        targetIdx: string,
        params: OrderBuyParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/order/${pageType}/${targetIdx}/buy`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    orderSell: async (
        pageType: string,
        targetIdx: string,
        params: OrderSellParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/order/${pageType}/${targetIdx}/sell`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    orderCancel: async (pageType: string, orderIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(URL_CONSTANTS.API_URL + "/order/" + orderIdx, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getSaleList: async (
        pageType: string,
        targetIdx: string,
        params: GetSaleListParams,
    ): Promise<MarketSaleListApiResultType> => {
        const tempParams = {
            ...params,
            product_type: pageType === "point" ? 1 : 3,
            product_idx: targetIdx,
        };
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/order/order-books/list`, {
                    params: tempParams,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            params.limit &&
                            params.page_no &&
                            res.data.data.totalCount >
                                params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getSettlementList: async (
        pageType: string,
        targetIdx: string,
        params: MarketSettlementListParamsType,
    ): Promise<MarketSettlementListApiResultType> => {
        const tempParams = {
            ...params,
            product_type: pageType === "point" ? 1 : 3,
            product_idx: targetIdx,
        };
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/settlement/settlement-sub/with-order/list`,
                    {
                        params: tempParams,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAveragePrice: async (
        pageType: string,
        targetIdx: string,
        params: MarketAveragePriceParams,
    ): Promise<MarketAveragePriceResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/order/average-price", {
                    params: {
                        ...params,
                        product_idx: targetIdx,
                        product_type: pageType === "point" ? 1 : 3,
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getDonutData: async (): Promise<MarketHomeDonutListItemType[]> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/point-token/market-data/chart/donut`,
                    {
                        paramsSerializer: {
                            indexes: null,
                        },
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
