import { ReactElement, useState } from "react";
import styled from "styled-components";
import { useOpenLoginConfirmModal } from "../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { getIsLogin } from "../../../../store/auth/authKeyStore";

export const CommonTabBoxContainer = styled.div<{
    hasPaddingBottom: boolean;
    hasMobilePaddingRight: boolean;
    backgroundColor: string;
    isOpen: boolean;
    isOpenSupport: boolean;
    opacity: number;
    isLogin: boolean;
    isGuestAddOpacityEffect: boolean;
}>`
    background: ${({ backgroundColor }) => backgroundColor};
    border-radius: 16px;
    padding: ${({ hasPaddingBottom, isOpen }) =>
        hasPaddingBottom || !isOpen ? "24px" : "24px 24px 0 24px"};
    overflow: ${({ isOpenSupport }) => (isOpenSupport ? "hidden" : "visible")};

    @media ${(props) => props.theme.mobileL} {
        padding: ${({ hasPaddingBottom, isOpen, hasMobilePaddingRight }) => {
            // hasPaddingBottom || !isOpen ? "16px" : "16px 16px 0 16px";

            if (isOpen) {
                return `16px ${hasMobilePaddingRight ? "16px" : "0"} ${
                    hasPaddingBottom ? "16px" : "0"
                } 16px`;
            } else {
                return "16px";
            }
        }};
    }

    transition: ${({ isOpen }) =>
        isOpen ? "all 200ms ease" : "padding 10ms 300ms ease"};

    box-sizing: border-box;
    opacity: ${({ isLogin, isGuestAddOpacityEffect }) =>
        isGuestAddOpacityEffect && !isLogin ? 0.3 : 1};
    pointer-events: ${({ isLogin, isGuestAddOpacityEffect }) =>
        isGuestAddOpacityEffect && !isLogin ? "none" : "auto"};
`;

export const CommonTabBoxHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CommonTabBoxHeaderTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const CommonTabBoxHeaderTitleIcon = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 6px;
`;

export const CommonTabBoxHeaderTitle = styled.div<{
    isSelected: boolean;
    isOneTab: boolean;
}>`
    position: relative;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: ${({ isSelected }) =>
        isSelected ? "#222222" : "rgba(34, 34, 34, 0.3)"};
    margin-right: 16px;
    margin-bottom: ${({ isOneTab }) => (isOneTab ? 0 : "8px")};
    cursor: pointer;
    @media ${(props) => props.theme.mobileL} {
        font-size: 18px;
    }
`;

export const CommonTabBoxHeaderSelectedTitleUnderLine = styled.div<{
    isSelected: boolean;
}>`
    position: absolute;
    width: 100%;
    bottom: -8px;
    border-bottom: ${({ isSelected }) =>
        isSelected ? "2px #222222 solid" : "none"};
`;

export const CommonTabBoxHeaderFoldArrowIcon = styled.img`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media ${(props) => props.theme.mobileL} {
        margin-right: 9px;
    }
`;

export const CommonTabBoxBodyWrapper = styled.div<{
    isOpen: boolean;
    boxBodyMaxHeight: string;
}>`
    position: relative;
    overflow: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    margin-top: ${({ isOpen }) => (isOpen ? "24px" : "0")};
    max-height: ${({ isOpen, boxBodyMaxHeight }) =>
        isOpen ? boxBodyMaxHeight : "0"};
    transition: ${({ isOpen }) =>
        !isOpen
            ? "max-height 300ms ease, margin-top 20ms 300ms ease"
            : "max-height, height 300ms ease"};
`;

export const CommonTabBoxLoadingContainerHasPB = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 24px;

    @media ${(props) => props.theme.mobileL} {
        padding-bottom: 16px;
    }
`;
type Props = {
    iconUrl?: string;
    tabList: {
        title: string;
        isNeedLogin?: boolean;
    }[];
    selectedTabIdx?: number;
    setSelectedTabIdx?: (value: number) => void;

    isOpenSupport: boolean;
    isDefaultOpen: boolean;

    boxBodyMaxHeight: string;

    hasPaddingBottom: boolean;
    hasMobilePaddingRight: boolean;
    backgroundColor: string;
    isGuestAddOpacityEffect: boolean;

    children: ReactElement;
};

const CommonTabBox = (props: Props): ReactElement | null => {
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const [isOpen, setIsOpen] = useState(props.isDefaultOpen);
    const isLogin = getIsLogin();

    return (
        <CommonTabBoxContainer
            isOpen={isOpen}
            isOpenSupport={props.isOpenSupport}
            backgroundColor={props.backgroundColor}
            hasPaddingBottom={props.hasPaddingBottom}
            hasMobilePaddingRight={props.hasMobilePaddingRight}
            opacity={props.isGuestAddOpacityEffect && !isLogin ? 0.3 : 1}
            isGuestAddOpacityEffect={props.isGuestAddOpacityEffect}
            isLogin={isLogin}
        >
            <CommonTabBoxHeader>
                <CommonTabBoxHeaderTitleContainer>
                    {props.iconUrl !== "" && (
                        <CommonTabBoxHeaderTitleIcon
                            src={URL_CONSTANTS.ASSET_URL + props.iconUrl}
                        />
                    )}

                    {props.tabList.length > 1 &&
                        props.tabList.map((tab, idx) => {
                            return (
                                <CommonTabBoxHeaderTitle
                                    isOneTab={false}
                                    onClick={async () => {
                                        if (tab.isNeedLogin && !getIsLogin()) {
                                            await openLoginConfirmModal();
                                        } else {
                                            if (props.setSelectedTabIdx) {
                                                props.setSelectedTabIdx(idx);
                                            }
                                        }
                                    }}
                                    isSelected={props.selectedTabIdx === idx}
                                    key={idx}
                                >
                                    {tab.title}
                                    <CommonTabBoxHeaderSelectedTitleUnderLine
                                        isSelected={
                                            props.selectedTabIdx === idx
                                        }
                                    />
                                </CommonTabBoxHeaderTitle>
                            );
                        })}
                    {props.tabList.length === 1 && (
                        <CommonTabBoxHeaderTitle
                            isSelected={true}
                            isOneTab={true}
                        >
                            {props.tabList[0].title}
                        </CommonTabBoxHeaderTitle>
                    )}
                </CommonTabBoxHeaderTitleContainer>

                {props.isOpenSupport && (
                    <CommonTabBoxHeaderFoldArrowIcon
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        src={`${
                            URL_CONSTANTS.ASSET_URL
                        }/icon/icon-tab-box-more-${
                            !isOpen ? "south" : "north"
                        }.svg`}
                    />
                )}
            </CommonTabBoxHeader>

            <CommonTabBoxBodyWrapper
                isOpen={isOpen}
                boxBodyMaxHeight={props.boxBodyMaxHeight}
            >
                {props.children}
            </CommonTabBoxBodyWrapper>
        </CommonTabBoxContainer>
    );
};

export default CommonTabBox;
CommonTabBox.defaultProps = {
    iconUrl: "",
    backgroundColor: "#f5f5f7",
    boxBodyMaxHeight: "600px",
    hasPaddingBottom: true,
    isOpenSupport: true,
    isDefaultOpen: true,
    isGuestAddOpacityEffect: false,
    hasMobilePaddingRight: true,
};
