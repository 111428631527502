import { ReactElement } from "react";
import styled from "styled-components";

export const CalendarHeaderWrapper = styled.div`
    width: inherit;
    max-width: inherit;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
`;

export const CalendarHeaderContainer = styled.div<{
    containerStyle: any;
}>`
    ${({ containerStyle }) => containerStyle}
`;

type Props = {
    headerContainerStyle: any;
};

const CalendarHeader = (props: Props): ReactElement | null => {
    const days = [];
    const date = ["일", "월", "화", "수", "목", "금", "토"];

    for (let headerIndex = 0; headerIndex < date.length; headerIndex++) {
        days.push(
            <CalendarHeaderContainer
                key={headerIndex}
                containerStyle={props.headerContainerStyle}
            >
                {date[headerIndex]}
            </CalendarHeaderContainer>,
        );
    }

    return <CalendarHeaderWrapper>{days}</CalendarHeaderWrapper>;
};

export default CalendarHeader;
