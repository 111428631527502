import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import MarketItemScrollableContents from "./components/marketItemMainContents/marketItemScrollableContents/MarketItemScrollableContents";
import MarketItemBottomContents from "./components/marketItemMainContents/marketItemBottomContents/MarketItemBottomContents";
import { useItemDataQuery } from "../../../query/item/useItemDataQuery";
import ApiResBoundary from "../../common/apiResBoundary/ApiResBoundary";
import { useSetHelmetInfo } from "../../../hooks/common/useSetHelmetInfo";
import FourWayLayout from "../../common/commonUiComponent/doubleSideBar/fourWayLayout/FourWayLayout";
import MarketItemTopContents from "./components/marketItemMainContents/marketItemTopContents/MarketItemTopContents";
import MarketItemStickyContents from "./components/marketItemMainContents/marketItemStickyContents/MarketItemStickyContents";
import {
    initSubTabList,
    SubTabType,
} from "../../common/commonUiComponent/subTab/SubTab";
import { useWindowSize } from "usehooks-ts";

export const MarketItemContentsContainer = styled.div``;

type Props = {};

export const ITEM_ORDER_TAB = "order";
export const ITEM_CONCLUSION_TAB = "conclusion";
export const ITEM_INFO_TAB = "info";
export const ITEM_COMMUNITY_TAB = "community";
export const ItemAnalysisList = [
    { title: "거래 통화", key: "project_currency", unit: "" },
    { title: "계약주소", key: "txid", unit: "" },
    { title: "토큰 ID", key: "tokenId", unit: "" },
    { title: "네트워크", key: "project_network", unit: "" },
    { title: "토큰표준", key: "project_erc", unit: "" },
    { title: "로열티", key: "royalty", unit: "%" },
    { title: "보유자", key: "item_owner_cnt", unit: "명" },
    { title: "발행개수", key: "item_cnt", unit: "개" },
    { title: "최초발행일", key: "item_created_at", unit: "" },
    { title: "재판매 가능 여부", key: "transaction_bool", unit: "" },
];

const MarketItemContents = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const projectIdx = params.project_id ? params.project_id : "0";
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const {
        data: rawItemData,
        error: itemDataError,
        isSuccess: isItemDataSuccess,
        isLoading: isItemDataLoading,
    } = useItemDataQuery(itemIdx);

    useSetHelmetInfo("itemName", rawItemData.itemInfo.item_name, rawItemData);

    const [subTabList, setSubTabList] = useState<SubTabType[]>([
        { value: "주문", key: ITEM_ORDER_TAB, isSelected: true },
        { value: "체결", key: ITEM_CONCLUSION_TAB, isSelected: true },
        { value: "정보", key: ITEM_INFO_TAB, isSelected: true },
        { value: "커뮤니티", key: ITEM_COMMUNITY_TAB, isSelected: true },
    ]);

    useEffect(() => {
        initSubTabList(subTabList, setSubTabList);
    }, [width]);

    // const { likeUnLike } = useLikeUnLike(
    //     "item",
    //     rawItemData.itemInfo.item_idx,
    //     rawItemData.itemInfo.like_bool,
    // );

    // project_idx 가 잘못 들어온 경우
    useEffect(() => {
        if (isItemDataSuccess) {
            if (
                typeof rawItemData.itemInfo.project_idx !== "undefined" &&
                projectIdx !== rawItemData.itemInfo.project_idx
            ) {
                navigate(
                    `/market/${rawItemData.itemInfo.project_idx}/${itemIdx}`,
                );
            }
        }
    }, [rawItemData, isItemDataSuccess]);

    return (
        <ApiResBoundary
            isNoData={false}
            isLoading={isItemDataLoading}
            error={itemDataError}
            errorFallback={() => {
                return <div></div>;
            }}
            isLoadingSpinnerShow={false}
        >
            <FourWayLayout
                renderTopComponent={() => <MarketItemTopContents />}
                renderStickyComponent={() => (
                    <MarketItemStickyContents itemIdx={itemIdx} />
                )}
                renderScrollableComponent={() => (
                    <MarketItemScrollableContents
                        subTabList={subTabList}
                        setSubTabList={setSubTabList}
                    />
                )}
                renderBottomComponent={() => (
                    <MarketItemBottomContents
                        projectName={rawItemData.itemInfo.project_name}
                        creatorId={rawItemData.creatorInfo.creator_id}
                        creatorIdx={rawItemData.creatorInfo.creator_idx}
                        creatorType={rawItemData.creatorInfo.creator_type}
                        creatorNickname={rawItemData.creatorInfo.creator_name}
                    />
                )}
            />
        </ApiResBoundary>
    );
};

export default MarketItemContents;
