import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import IncludeIconButton from "../../../../commonUnitComponent/includeIconButton/IncludeIconButton";
import {
    getPageType,
    numberToStringWithComma,
    shortenNumberToStringWithComma,
} from "../../../../../../utils/Utils";
import { ItemDetailDataCreatorInfoType } from "../../../../../../query/item/useItemDataQuery";
import { format } from "date-fns";
import Nickname from "../../../../commonUnitComponent/nickname/Nickname";
import MoreButton from "../../../../commonUnitComponent/moreButton/MoreButton";
import { useFollowUnFollow } from "../../../../../../hooks/common/useFollowUnFollow";
import RightSideBarInfoIntroduce from "./RightSideBarInfoIntroduce";
import { PointDetailCreatorInfoType } from "../../../../../../query/point/usePointDataQuery";
import { CreatorInfoType } from "../../../../../../types/creator/CreatorInfoType";

export const RightSideBarInfoContainer = styled.div`
    margin-top: 24px;
`;

export const RightSideBarInfoProfileContainer = styled.div``;

export const RightSideBarInfoProfile = styled.div<{ isBrand: boolean }>`
    display: flex;
    ${({ isBrand }) => (isBrand ? "flex-direction: column" : "")};
`;

export const RightSideBarInfoProfileThumbnailContainer = styled.div``;

export const RightSideBarInfoProfileThumbnail = styled.img<{
    isBrand: boolean;
}>`
    width: ${({ isBrand }) => (isBrand ? "auto" : "46px")};
    height: ${({ isBrand }) => (isBrand ? "32px" : "46px")};
    border-radius: ${({ isBrand }) => (isBrand ? "none" : "23px")};
    margin-right: 10px;
`;

export const RightSideBarInfoProfileInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RightSideBarInfoProfileInfoCreatorNickname = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #000000;
`;

export const RightSideBarInfoProfileInfoCreatorUserId = styled.div`
    margin-top: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: #888888;
`;

export const RightSideBarInfoProfileFavContainer = styled.div`
    margin-top: 14px;
    display: flex;
`;

export const RightSideBarInfoIntroduceContainer = styled.div`
    margin-top: 48px;

    @media ${(props) => props.theme.mobileL} {
    }
`;
export const RightSideBarIntroduceTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #222222;

    @media ${(props) => props.theme.PCM} {
        // display: none;
    }
`;

export const RightSideBarInfoIntroduceMoreButtonContainer = styled.div`
    margin-top: 20px;
`;

export const RightSideBarInfoAnalysisContainer = styled.div`
    margin-top: 48px;
`;

export const RightSideBarInfoAnalysisList = styled.div<{ isOpen: boolean }>`
    margin-top: 20px;
    height: ${({ isOpen }) => (isOpen ? "100%" : "96px")};
    overflow: hidden;
`;

export const RightSideBarInfoAnalysisListItem = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 12px;
    color: #555555;
`;

export const RightSideBarInfoAnalysisListItemNumber = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
`;

type Props = {
    creatorInfo:
        | CreatorInfoType
        | ItemDetailDataCreatorInfoType
        | PointDetailCreatorInfoType;
    refetchGetCreatorInfo: () => void;
    analysisData: any[];
};

const RightSideBarInfo = (props: Props): ReactElement | null => {
    const pageType = getPageType(location.pathname);
    const [isOpenAnalysis, setIsOpenAnalysis] = useState(pageType === "item");

    const isBrand = props.creatorInfo.creator_type === "brand";

    const { followUnFollow } = useFollowUnFollow(
        props.creatorInfo.creator_idx,
        props.creatorInfo.follow_bool,
        props.refetchGetCreatorInfo,
    );

    function convertAnalysisValue(data: any) {
        try {
            switch (data.key) {
                case "transaction_bool":
                    return data.value ? "가능" : "불가능";

                case "item_status":
                    return data.value === 1 ? "판매중" : "판매완료";

                case "item_created_at":
                    return format(new Date(data.value), "yyyy.MM.dd HH:mm:ss");

                default:
                    return typeof data.value !== "undefined" &&
                        data.value !== null &&
                        data.value !== ""
                        ? `${numberToStringWithComma(data.value)} ${data.unit}`
                        : "-";
            }
        } catch (e) {
            return data.value;
        }
    }

    return (
        <RightSideBarInfoContainer>
            <RightSideBarInfoProfileContainer>
                <RightSideBarInfoProfile isBrand={isBrand}>
                    <RightSideBarInfoProfileThumbnailContainer>
                        <RightSideBarInfoProfileThumbnail
                            isBrand={isBrand}
                            src={props.creatorInfo.creator_img_full_path}
                        />
                    </RightSideBarInfoProfileThumbnailContainer>
                    <RightSideBarInfoProfileInfoContainer>
                        {!isBrand && (
                            <>
                                <RightSideBarInfoProfileInfoCreatorNickname>
                                    <Nickname
                                        userIdx={props.creatorInfo.creator_idx}
                                        nickname={
                                            props.creatorInfo.creator_name
                                        }
                                        isVerified={
                                            props.creatorInfo
                                                .user_influencer_bool
                                        }
                                        isShowVerifiedIcon={true}
                                        tooltipPosition={"bottom"}
                                    />
                                </RightSideBarInfoProfileInfoCreatorNickname>
                                <RightSideBarInfoProfileInfoCreatorUserId>
                                    {props.creatorInfo.creator_id}
                                </RightSideBarInfoProfileInfoCreatorUserId>
                            </>
                        )}
                        <RightSideBarInfoProfileFavContainer>
                            <IncludeIconButton
                                isActive={props.creatorInfo.follow_bool}
                                clickEventHandler={async (e) => {
                                    await followUnFollow(!isBrand);
                                }}
                                activeIconColor={"#FA54FA"}
                                icon={`${URL_CONSTANTS.ASSET_URL}/icon/icon-follow.svg`}
                                title={shortenNumberToStringWithComma(
                                    props.creatorInfo.creator_follow_cnt,
                                )}
                            />
                        </RightSideBarInfoProfileFavContainer>
                    </RightSideBarInfoProfileInfoContainer>
                </RightSideBarInfoProfile>
            </RightSideBarInfoProfileContainer>
            <RightSideBarInfoIntroduceContainer>
                <RightSideBarIntroduceTitle>
                    {isBrand ? "브랜드" : "크리에이터"} 소개
                </RightSideBarIntroduceTitle>
                <RightSideBarInfoIntroduce
                    introduce={props.creatorInfo.creator_description}
                    isBrand={isBrand}
                />
            </RightSideBarInfoIntroduceContainer>

            <RightSideBarInfoAnalysisContainer>
                <RightSideBarIntroduceTitle>분석</RightSideBarIntroduceTitle>
                <RightSideBarInfoAnalysisList isOpen={isOpenAnalysis}>
                    {props.analysisData.map((AnalysisListItem, idx) => (
                        <RightSideBarInfoAnalysisListItem key={idx}>
                            {AnalysisListItem.title}
                            <RightSideBarInfoAnalysisListItemNumber>
                                {convertAnalysisValue(AnalysisListItem)}
                            </RightSideBarInfoAnalysisListItemNumber>
                        </RightSideBarInfoAnalysisListItem>
                    ))}
                </RightSideBarInfoAnalysisList>
                {pageType !== "item" && (
                    <RightSideBarInfoIntroduceMoreButtonContainer>
                        <MoreButton
                            isShow={true}
                            isSupportFold={true}
                            onClick={() => {
                                setIsOpenAnalysis(!isOpenAnalysis);
                            }}
                        />
                    </RightSideBarInfoIntroduceMoreButtonContainer>
                )}
            </RightSideBarInfoAnalysisContainer>
        </RightSideBarInfoContainer>
    );
};

export default RightSideBarInfo;
