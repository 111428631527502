import { ReactElement, useState } from "react";
import styled from "styled-components";
import AdManageContentsHeader from "../../../../commonComponents/adManageContentsHeader/AdManageContentsHeader";
import { useAdCampaignSimpleListQuery } from "../../../../../../../query/ad/campaign/useAdCampaignSimpleListQuery";
import { ChipType } from "../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import AdManageMyTemplateListBody from "./components/AdManageMyTemplateListBody/AdManageMyTemplateListBody";
import ApiResBoundary from "../../../../../../common/apiResBoundary/ApiResBoundary";
import { DropdownListItemType } from "../../../../../../common/commonUnitComponent/commonDropdownBtn/CommonDropdownBtn";
import {
    BRAND_ID_SAYBUZZ,
    BRAND_ID_SOOP,
    BRAND_NAME_SAYBUZZ,
    BRAND_NAME_SOOP,
} from "../../../../../../../constants/brand/Brand";

export const AdManageMyTemplateListContainer = styled.div``;

export const AdManageMyTemplateListBodyContainer = styled.div``;

export const AdManageMyTemplateBrandList: DropdownListItemType[] = [
    {
        value: "전체",
        key: "",
        isSelected: true,
    },
    {
        value: BRAND_NAME_SAYBUZZ,
        key: BRAND_ID_SAYBUZZ,
        isSelected: false,
    },
    {
        value: BRAND_NAME_SOOP,
        key: BRAND_ID_SOOP,
        isSelected: false,
    },
];

export const AdManageMyTemplateStatusChips: ChipType[] = [
    {
        title: "사용가능",
        key: "2",
        isSelected: false,
    },
    {
        title: "검토중",
        key: "1",
        isSelected: false,
    },
    {
        title: "반려",
        key: "3",
        isSelected: false,
    },
];
export const AdManageMyTemplateBackgroundColors = [
    "#F7F0FC",
    "#F0F1FD",
    "#ECF4FB",
    "#EDF5F7",
    "#FBF5E9",
    "#FAF0EB",
    "#FAEDEE",
    "#FAF0F7",
];

type Props = {};

/** contents - 내 광고 템플릿 **/
const AdManageMyTemplateList = (props: Props): ReactElement | null => {
    const [brandDropdownList, setBrandDropdownList] = useState<
        DropdownListItemType[]
    >(AdManageMyTemplateBrandList);

    const [templateChips, setTemplateChips] = useState<ChipType[]>(
        AdManageMyTemplateStatusChips,
    );

    const selectedBrandKey = brandDropdownList.find(
        (filter) => filter.isSelected,
    )?.key;

    const {
        data: rawAdCampaignSimpleList,
        error: errorAdCampaignSimpleList,
        isLoading: isAdCampaignSimpleListLoading,
    } = useAdCampaignSimpleListQuery({
        brand_id_array: selectedBrandKey || "",
        campaign_type_array: "1, 2",
    });

    return (
        <AdManageMyTemplateListContainer>
            <AdManageContentsHeader
                brandDropdownList={brandDropdownList}
                setBrandDropdownList={setBrandDropdownList}
                templateChips={templateChips}
                setTemplateChips={setTemplateChips}
                isChipsAllShow={true}
            />

            <ApiResBoundary
                isLoading={isAdCampaignSimpleListLoading}
                isNoData={rawAdCampaignSimpleList.campaignList.length === 0}
                error={errorAdCampaignSimpleList}
                defaultLoadingFallbackContainerHeight={"600px"}
            >
                <AdManageMyTemplateListBodyContainer>
                    {rawAdCampaignSimpleList.campaignList.map(
                        (campaign, campaignIdx) => (
                            <AdManageMyTemplateListBody
                                key={campaign.campaign_idx}
                                campaignSimpleListItem={campaign}
                                campaignDataOrderIdx={campaignIdx}
                                templateChips={templateChips}
                                brandDropdownList={brandDropdownList}
                            />
                        ),
                    )}
                </AdManageMyTemplateListBodyContainer>
            </ApiResBoundary>
        </AdManageMyTemplateListContainer>
    );
};

export default AdManageMyTemplateList;
