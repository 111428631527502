import { ReactElement, useRef } from "react";
import { useHover, useLocalStorage, useOnClickOutside } from "usehooks-ts";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { getIsLogin } from "../../../../../../store/auth/authKeyStore";
import Tooltip from "../../../../commonUnitComponent/tooltip/Tooltip";
import isDarkModeAtom from "../../../../../../store/ui/isDarkModeAtom";
import isShowUserProfileMenuAtom from "../../../../../../store/ui/isShowUserProfileMenuAtom";
import { ResponsiveWidth } from "../../../../../../styles/Theme";
import { useOpenUserProfileModal } from "../../../../../../hooks/modal/openModal/useOpenUserProfileModal";
import { useGoLoginPage } from "../../../../../../hooks/common/useGoLoginPage";
import { BRAND_ID_SOOP } from "../../../../../../constants/brand/Brand";
import { hasPathAfterResolve } from "../../../../../../hooks/modal/useOpenModal";

export const HeaderUserProfileAreaContainer = styled.div`
    margin: 0 0 0 12px;
    line-height: 1;

    @media ${(props) => props.theme.tabletL} {
        margin: 0;
    } ;
`;

export const HeaderUserProfileAreaButtonContainer = styled.div``;

export const HeaderUserProfileAreaUserImg = styled.img<{
    isLogin: boolean;
    isDarkMode: boolean;
}>`
    width: 40px;
    cursor: pointer;
    display: ${({ isLogin }) => (isLogin ? "block" : "none")};
    @media ${(props) => props.theme.tabletL} {
        width: 34px;
        height: 34px;
        display: none;
    }
    border-radius: 10px;
    transition: background-color 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            text-decoration: none;
            background: ${({ isDarkMode }) =>
                isDarkMode
                    ? "rgba(255, 255, 255, 0.12)"
                    : "rgba(13, 13, 26, 0.06)"};
        }
    }
`;

export const HeaderUserProfileAreaUserIconImg = styled.img<{
    isDarkMode: boolean;
}>`
    width: 40px;
    height: 40px;
    display: none;
    cursor: pointer;

    @media ${(props) => props.theme.tabletL} {
        display: block;
        margin: 4px;
    }
    border-radius: 10px;
    transition: background-color 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isDarkMode }) =>
                isDarkMode
                    ? "rgba(13, 13, 26, 0.06)"
                    : "rgba(255, 255, 255, 0.12)"}
        }

    }
}
`;
export const HeaderUserProfileAreaLoginButton = styled.button<{
    isLogin: boolean;
    isDarkMode: boolean;
}>`
    position: relative;
    width: 85px;
    height: 40px;
    margin: 0 auto;
    border: none;
    text-decoration: none;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    background: ${({ isDarkMode }) => (isDarkMode ? "#ffffff12" : "#0D0D1A06")};
    padding: 0;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }

    transition: 300ms ease background-color;
    display: ${({ isLogin }) => (isLogin ? "none" : "flex")};

    @media ${(props) => props.theme.tabletL} {
        display: none;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isDarkMode }) =>
                isDarkMode ? "#ffffff20" : "#0D0D1A12"};
        }
    }
`;
export const HeaderUserProfileAreaLoginButtonTooltipContainer = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
`;

export const HeaderUserProfileAreaLoginButtonTooltipLogo = styled.img`
    height: 20px;
    margin-right: 8px;
`;

export const HeaderUserProfileAreaLoginButtonTooltip = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 0.7; /* 16px */
`;
type Props = {};

const HeaderUserProfileArea = (props: Props): ReactElement | null => {
    const { openUserProfileModal } = useOpenUserProfileModal();
    const [pathAfterLogin, setPathAfterLogin] = useLocalStorage(
        "pathAfterLogin",
        "/",
    );

    const location = useLocation();

    const [isShowUserProfileMenu, setIsShowUserProfileMenu] = useAtom(
        isShowUserProfileMenuAtom,
    );
    const [isDarkMode] = useAtom(isDarkModeAtom);

    const userContainerRef = useRef<HTMLDivElement>(null);
    const loginButtonRef = useRef<HTMLButtonElement>(null);

    const { goLogin } = useGoLoginPage();
    const navigate = useNavigate();
    const isLogin = getIsLogin();

    const handleClickOutside = () => {
        if (
            isShowUserProfileMenu &&
            window.innerWidth > ResponsiveWidth.tabletL
        ) {
            setIsShowUserProfileMenu(false);
        }
    };
    useOnClickOutside(userContainerRef, handleClickOutside);
    const isLoginButtonHover = useHover(loginButtonRef);

    return (
        <HeaderUserProfileAreaContainer ref={userContainerRef}>
            {/*로그인 상태 + 1280px 초과*/}
            <HeaderUserProfileAreaUserImg
                id={"header-user-icon-pc"}
                isDarkMode={isDarkMode}
                isLogin={isLogin}
                onClick={async () => {
                    await openUserProfileModal();
                }}
                src={
                    isDarkMode
                        ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-user.png`
                        : `${URL_CONSTANTS.ASSET_URL}/icon/icon-user-black.png`
                }
            />

            {/*비로그인 상태 + 1280px 초과*/}
            <HeaderUserProfileAreaLoginButton
                ref={loginButtonRef}
                isDarkMode={isDarkMode}
                isLogin={isLogin}
                onClick={async () => {
                    goLogin(() => {
                        if (
                            pathAfterLogin === "/" &&
                            location.pathname.indexOf("/intro") === -1
                        ) {
                            setPathAfterLogin(location.pathname);
                        }
                    });
                }}
            >
                로그인
                <Tooltip
                    isHover={isLoginButtonHover}
                    position={"bottom"}
                    padding={"9px 12px"}
                >
                    <HeaderUserProfileAreaLoginButtonTooltipContainer>
                        <HeaderUserProfileAreaLoginButtonTooltipLogo
                            src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${BRAND_ID_SOOP}-only-icon.svg`}
                        />
                        <HeaderUserProfileAreaLoginButtonTooltip>
                            SOOP으로 로그인
                        </HeaderUserProfileAreaLoginButtonTooltip>
                    </HeaderUserProfileAreaLoginButtonTooltipContainer>
                </Tooltip>
            </HeaderUserProfileAreaLoginButton>

            {/*로그인 여부 상관x 1280px 미만*/}
            <HeaderUserProfileAreaUserIconImg
                id={"header-user-icon-mobile"}
                isDarkMode={isDarkMode}
                onClick={async () => {
                    await openUserProfileModal();
                }}
                src={
                    isDarkMode
                        ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-menu-white.png`
                        : `${URL_CONSTANTS.ASSET_URL}/icon/icon-menu-black.png`
                }
            />
        </HeaderUserProfileAreaContainer>
    );
};

export default HeaderUserProfileArea;
