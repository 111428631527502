import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";
import CalendarSelectedMobileCellSecondaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellSecondaryRow";
import CalendarSelectedMobileCellBlueRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellBlueRow";
import CalendarSelectedMobileCelleKeyPrimaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCelleKeyPrimaryRow";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";
import CalendarSelectedMobileCellDangerRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellDangerRow";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";

export const AdBidCalendarSelectedMobileCellBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarSelectedMobileCellInReview = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const adSalesInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;

    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdDailyDateData(props);

    return !isAdStop ? (
        isTodaySale ? (
            myOfferInfo ? (
                <>
                    <CalendarSelectedMobileCellSecondaryRow
                        keyMsg={"시스템 준비중"}
                        valueMsg={""}
                    />
                    {myOfferInfo.buy_now_available_bool && (
                        <CalendarSelectedMobileCellBlueRow
                            keyMsg={"즉시구매 가능"}
                            valueMsg={`${numberToStringWithComma(
                                adSalesInfo.ad_reserve_price * 1.1,
                            )} KRW`}
                        />
                    )}
                    <HiddenKey
                        id={
                            "AD_IN_REVIEW__notAdStop__dailySale__myOfferInfo__ms"
                        }
                    />
                </>
            ) : otherOfferInfo ? (
                <>
                    <CalendarSelectedMobileCellSecondaryRow
                        keyMsg={"시스템 준비중"}
                        valueMsg={""}
                    />
                    {otherOfferInfo.buy_now_available_bool && (
                        <CalendarSelectedMobileCellBlueRow
                            keyMsg={"즉시구매 가능"}
                            valueMsg={`${numberToStringWithComma(
                                adSalesInfo.ad_reserve_price * 1.1,
                            )} KRW`}
                        />
                    )}
                    <HiddenKey
                        id={
                            "AD_IN_REVIEW__notAdStop__dailySale__otherOfferInfo__ms"
                        }
                    />
                </>
            ) : myOfferSuccessInfo ? (
                <>
                    <CalendarSelectedMobileCelleKeyPrimaryRow
                        keyMsg={"내 광고 낙찰"}
                        valueMsg={`${numberToStringWithComma(
                            myOfferSuccessInfo.offer_price,
                        )} KRW`}
                    />
                    <HiddenKey
                        id={
                            "AD_IN_REVIEW__notAdStop__dailySale__myOfferSuccessInfo__ms"
                        }
                    />
                </>
            ) : otherOfferSuccessInfo ? (
                <>
                    <CalendarSelectedMobileCellSecondaryRow
                        keyMsg={"낙찰 완료"}
                        valueMsg={`${numberToStringWithComma(
                            otherOfferSuccessInfo.offer_price,
                        )} KRW`}
                    />
                    <HiddenKey
                        id={
                            "AD_IN_REVIEW__notAdStop__dailySale__otherOfferSuccessInfo__ms"
                        }
                    />
                </>
            ) : (
                <>
                    <CalendarSelectedMobileCellSecondaryRow
                        keyMsg={"시스템 준비중"}
                        valueMsg={""}
                    />
                    <CalendarSelectedMobileCellBlueRow
                        keyMsg={"즉시구매 가능"}
                        valueMsg={`${numberToStringWithComma(
                            adSalesInfo.ad_reserve_price * 1.1,
                        )} KRW`}
                    />
                    <HiddenKey
                        id={"AD_IN_REVIEW__notAdStop__notAdDailySale__else__ms"}
                    />
                </>
            )
        ) : (
            <>
                <CalendarSelectedMobileCellDangerRow
                    keyMsg={"판매 중단"}
                    valueMsg={""}
                />
                <HiddenKey id={"AD_IN_REVIEW__notAdStop__notAdDailySale__ms"} />
            </>
        )
    ) : (
        <>
            <HiddenKey id={"AD_IN_REVIEW__AdStop__notAdDailySale__ms"} />
        </>
    );
};

export default AdBidCalendarSelectedMobileCellInReview;
