import { addDays, format } from "date-fns";
import { ReactElement, useState } from "react";
import { DateRange } from "react-day-picker";
import styled from "styled-components";
import AdManageTableContentsLayout from "../../_adManageLayout/AdManageTableContentsLayout";
import AdBuyManageAllOfferListPcTable from "./components/AdBuyManageAllOfferListPcTable";
import {
    spreadAllAdMyOfferListPages,
    useGetAllAdMyBuyOfferListQuery,
} from "../../../../../query/my/myAd/useGetAllAdMyBuyOfferListQuery";
import DefaultNoDataComponent from "../../../../common/apiResBoundary/DefaultNoDataComponent";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";
import AdBuyManageAllOfferListMobileTable from "./components/AdBuyManageAllOfferListMobileTable";
import MoreButton from "../../../../common/commonUnitComponent/moreButton/MoreButton";
import {
    AdManageCommonMobileTableWrapper,
    AdManageCommonPcTableWrapper,
    AdManageCommonTableMoreButton,
    AdManageCommonTableMoreButtonContainer,
} from "../../commonComponents/adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";

export const AdBuyManageHistoryTableContentsContainer = styled.div`
    margin-bottom: 136px;

    @media ${(props) => props.theme.mobileL} {
        margin-bottom: 36px;
    }
`;

type Props = {};

const AdBuyManageAllAdOfferListTableContents = (
    props: Props,
): ReactElement | null => {
    const today = new Date();
    const defaultSelected: DateRange = {
        from: addDays(today, -15),
        to: addDays(today, +15),
    };
    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRange>(defaultSelected);

    const {
        data: rawMyAdOfferHistory,
        isLoading: isMyAdOfferHistoryLoading,
        error: errorMyAdOfferHistory,
        hasNextPage: hasMyAdOfferHistoryPage,
        fetchNextPage: fetchNextMyAdOfferHistory,
    } = useGetAllAdMyBuyOfferListQuery({
        page_no: 1,
        limit: 20,
        ad_sales_date_from: format(
            selectedDateRange.from ? selectedDateRange.from : 0,
            "yyyy-MM-dd",
        ),
        ad_sales_date_to: format(
            selectedDateRange.to ? selectedDateRange.to : 0,
            "yyyy-MM-dd",
        ),
        sort_type: "created_at_desc",
    });

    const allAdMyOfferList = spreadAllAdMyOfferListPages(
        rawMyAdOfferHistory.pages,
    );

    return (
        <AdManageTableContentsLayout
            type={"buy"}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
        >
            <AdBuyManageHistoryTableContentsContainer>
                <ApiResBoundary
                    isLoading={isMyAdOfferHistoryLoading}
                    error={errorMyAdOfferHistory}
                    isNoData={allAdMyOfferList.length === 0}
                    noDataFallback={
                        <DefaultNoDataComponent msg={"입찰 내역이 없습니다"} />
                    }
                    defaultLoadingFallbackContainerHeight={"400px"}
                >
                    <>
                        <AdManageCommonPcTableWrapper>
                            <AdBuyManageAllOfferListPcTable
                                selectedDateRange={selectedDateRange}
                                allAdMyOfferList={allAdMyOfferList}
                            />
                        </AdManageCommonPcTableWrapper>
                        <AdManageCommonMobileTableWrapper>
                            <AdBuyManageAllOfferListMobileTable
                                selectedDateRange={selectedDateRange}
                                allAdMyOfferList={allAdMyOfferList}
                            />
                        </AdManageCommonMobileTableWrapper>
                        {hasMyAdOfferHistoryPage && (
                            <AdManageCommonTableMoreButtonContainer>
                                <AdManageCommonTableMoreButton>
                                    <MoreButton
                                        isShow={true}
                                        isSupportFold={false}
                                        onClick={async () => {
                                            await fetchNextMyAdOfferHistory();
                                        }}
                                    />
                                </AdManageCommonTableMoreButton>
                            </AdManageCommonTableMoreButtonContainer>
                        )}
                    </>
                </ApiResBoundary>
            </AdBuyManageHistoryTableContentsContainer>
        </AdManageTableContentsLayout>
    );
};

export default AdBuyManageAllAdOfferListTableContents;
