import isWebPushSubscribeAtom from "../../store/notification/isWebPushSubscribeAtom";
import { useAtom } from "jotai";
import webPushSubscriptionAtom from "../../store/notification/webPushSubscriptionAtom";
import { WebPushApi } from "../../apis/WebPushApi";

export const NOTI_PERMISSION_GRANTED = "granted";
export const NOTI_PERMISSION_DEFAULT = "default";
export const NOTI_PERMISSION_DENIED = "denied";
type NOTI_PERMISSION_STATE = "granted" | "default" | "denied";

export function useGetPushInfo() {
    const [isWebPushSubscribe, setIsWebPushSubscribe] = useAtom(
        isWebPushSubscribeAtom,
    );
    const [webPushSubscription, setWebPushSubscription] = useAtom(
        webPushSubscriptionAtom,
    );

    const isWebPushSupport = "Notification" in window || false;
    let webPushPermissionState: NOTI_PERMISSION_STATE = isWebPushSupport
        ? Notification.permission || NOTI_PERMISSION_DENIED
        : NOTI_PERMISSION_DENIED;

    const subscribeWebPushInClient = async () => {
        try {
            let registration = await navigator.serviceWorker.getRegistration();
            const _webPushSubscription =
                (await registration?.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey:
                        "BM1h8W-HQednUvcpKIbH1Eo8wZzUBsieCC9oHm_XRcvp7wNYDzWSqQvfG4dEq71AOR5rbQ2FkqLe8xN7y9qA3to",
                })) || null;
            setWebPushSubscription(_webPushSubscription);
            console.info("subscribe in client", _webPushSubscription);
            return _webPushSubscription;
        } catch (e) {
            console.info("subscribe in client fail - ", e);
            unsubscribeWebPushInClient();
        }
        return null;
    };

    const _registerWebPushSubscription = async (
        webPushSubscription: PushSubscription,
    ) => {
        try {
            await WebPushApi.registerWebPushSubscription(webPushSubscription);
            setIsWebPushSubscribe(true);
        } catch (e) {
            console.info("register subscription fail - ", e);
        }
    };

    const registerWebPushSubscription = async (
        webPushSubscription: PushSubscription,
    ) => {
        await _registerWebPushSubscription(webPushSubscription);
        console.info("register subscription in db", webPushSubscription);
    };

    const updateWebPushSubscription = async (
        webPushSubscription: PushSubscription,
    ) => {
        await _registerWebPushSubscription(webPushSubscription);
        console.info("update subscription in db", webPushSubscription);
    };

    const unsubscribeWebPushInClient = async () => {
        try {
            let registration = await navigator.serviceWorker.getRegistration();
            const subscription =
                await registration?.pushManager.getSubscription();
            if (typeof subscription !== "undefined" && subscription) {
                await subscription.unsubscribe();
                setWebPushSubscription(null);
                console.info("unsubscribe in client", subscription);
            } else {
                console.info("unsubscribe in client fail - no subscription");
            }
        } catch (e) {
            console.info("unsubscribe in client fail - ", e);
        }
    };

    const unRegisterWebPushSubscription = async () => {
        try {
            let registration = await navigator.serviceWorker.getRegistration();
            const subscription =
                await registration?.pushManager.getSubscription();

            if (typeof subscription !== "undefined" && subscription) {
                await WebPushApi.unRegisterWebPushSubscription(subscription);
                setIsWebPushSubscribe(false);
                console.info("unregister subscription in db", subscription);
            }
            //
            else {
                console.info("unregister subscription fail - no subscription");
            }
        } catch (e) {
            console.info("unregister subscription fail - ", e);
        }
    };

    // 로그아웃 시 해당 기기에 알림을 안가게 하기 위해 deactivate call
    // 등록은 되있지만 비활성화 시키기
    const deactivateWebPushSubscription = async () => {
        try {
            let registration = await navigator.serviceWorker.getRegistration();
            const subscription =
                await registration?.pushManager.getSubscription();

            if (typeof subscription !== "undefined" && subscription) {
                if (isWebPushSubscribe) {
                    await WebPushApi.deactivateWebPushSubscription(
                        subscription,
                    );
                    console.info("deactivate subscription in db", subscription);
                }
            } else {
                console.info("deactivate subscription fail - no subscription");
            }
        } catch (e) {
            console.info("deactivate subscription fail - ", e);
        }
    };

    const requestWebPushPermission = async () => {
        try {
            if (isWebPushSupport) {
                const requestPermissionState =
                    await Notification.requestPermission();

                // 푸시 허용 팝업에서 허용을 한 경우
                if (requestPermissionState === NOTI_PERMISSION_GRANTED) {
                    const webPushSubscriptionInClient =
                        await subscribeWebPushInClient();
                    if (webPushSubscriptionInClient) {
                        await registerWebPushSubscription(
                            webPushSubscriptionInClient,
                        );
                    }
                }
            } else {
                console.info(
                    "requestWebPushPermission fail - no support device",
                );
            }
        } catch (e) {
            console.info("requestWebPushPermission fail - ", e);
        }
    };

    return {
        isWebPushSupport,
        webPushPermissionState,
        isWebPushSubscribe,
        webPushSubscription,
        subscribeWebPushInClient,
        unsubscribeWebPushInClient,
        registerWebPushSubscription,
        updateWebPushSubscription,
        unRegisterWebPushSubscription,
        requestWebPushPermission,
        deactivateWebPushSubscription,
    };
}
