import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import {
    convertDate,
    numberToStringWithComma,
} from "../../../../../../../../../../../utils/Utils";

import {
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
} from "../../../../../../../../../../../styles/InfoTableStyle";
import Spinner from "../../../../../../../../../../common/commonUnitComponent/spinner/Spinner";
import {
    ModalButton,
    ModalButtonContainer,
    ModalInfoContainer,
    ModalTitle,
} from "../../../../../../../../../../common/commonUnitComponent/modal/ModalStyle";
import { useAdCancelOfferMutation } from "../../../../../../../../../../../query/ad/adBidOffer/useAdCancelOfferMutation";
import { useLocation } from "react-router-dom";
import { PathAfterResolveType } from "../../../../../../../../../../../hooks/modal/useOpenModal";
import { format } from "date-fns";
import { useCancelAdOfferSuccessByBuyerMutation } from "../../../../../../../../../../../query/ad/adBidOffer/useCancelAdOfferSuccessByBuyerMutation";
import {
    AD_OFFER_STATUS_CANCELED_OFFER,
    AD_OFFER_STATUS_CANCELED_SUCCESS_OFFER,
    AD_OFFER_STATUS_NO_SELECT_OFFER,
    AD_OFFER_STATUS_OFFERED,
    AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER,
    AD_OFFER_STATUS_SUCCESS_OFFER,
    staticConvertAdOfferStatus,
} from "../../../../../../../../../../../hooks/ad/staticConvertAdOfferStatus";
import {
    AD_EXPIRED,
    AD_ON_SCHEDULE,
} from "../../../../../../../../../../../types/ad/adDaily/AdDailyListItemType";
import { useOpenCommonConfirmModal } from "../../../../../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";

export const AdBidOrderMyOfferHistoryOrderReceiptModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const AdBidOrderMyOfferHistoryOrderReceiptModalDesc = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: #777777;
    margin-top: 16px;
`;

export const AdBidOrderMyOfferHistoryOrderReceiptModalRedFont = styled.span`
    color: #ff4671;
`;

export type AdBidOfferReceiptInfoType = {
    date: string;
    offerPrice: number;
    offerStatus: string;
    adIdx: string;
    adOfferIdx: string;
    offerInvalidStatus: string;
    adSalesDate: string;
    isTodaySale: boolean;
    isAdStop: boolean;
    adDailyStatus: string;
    refundablePrice?: number;
    refundRatio?: number;
    daysOnAir?: number;
};

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
} & AdBidOfferReceiptInfoType;

const AdBidOrderMyOfferHistoryOrderReceiptModal = (
    props: Props,
): ReactElement | null => {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { mutateAsync: cancelOffer } = useAdCancelOfferMutation(
        props.adIdx,
        props.adOfferIdx,
    );

    const { mutateAsync: cancelOfferSuccessByBuyer } =
        useCancelAdOfferSuccessByBuyerMutation(props.adIdx, {
            ad_sales_date: format(new Date(props.adSalesDate), "yyyy-MM-dd"),
        });

    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        setIsPending(false);
    }, []);
    const location = useLocation();
    const isHistoryPage = location.pathname.indexOf("history") !== -1;
    const convertedAdOfferStatus = staticConvertAdOfferStatus(
        props.offerStatus,
        props.offerInvalidStatus,
    );

    return (
        <AdBidOrderMyOfferHistoryOrderReceiptModalContainer>
            <ModalTitle>입찰 내역</ModalTitle>
            <AdBidOrderMyOfferHistoryOrderReceiptModalDesc>
                {convertDate(new Date(props.date), 0, 0, 0, true, true)}
            </AdBidOrderMyOfferHistoryOrderReceiptModalDesc>
            <ModalInfoContainer width={"312px"} mt={"14px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        입찰 금액
                        <InfoTableColumnValue>
                            {numberToStringWithComma(props.offerPrice)}
                            <InfoTableColumnUnit>KRW</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        입찰 상태
                        <InfoTableColumnValue>
                            {convertedAdOfferStatus.offerStatusKR}
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    {(props.offerStatus === AD_OFFER_STATUS_SUCCESS_OFFER ||
                        props.offerStatus ===
                            AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER) &&
                        convertedAdOfferStatus.offerStatusSubKR !== "" && (
                            <InfoTableMajorRow>
                                낙찰 방법
                                <InfoTableColumnValue>
                                    {convertedAdOfferStatus.offerStatusSubKR}
                                </InfoTableColumnValue>
                            </InfoTableMajorRow>
                        )}
                    {(props.offerStatus === AD_OFFER_STATUS_CANCELED_OFFER ||
                        props.offerStatus === AD_OFFER_STATUS_NO_SELECT_OFFER ||
                        props.offerStatus ===
                            AD_OFFER_STATUS_CANCELED_SUCCESS_OFFER) && (
                        <InfoTableMajorRow>
                            취소 사유
                            <InfoTableColumnValue>
                                <AdBidOrderMyOfferHistoryOrderReceiptModalRedFont>
                                    {
                                        convertedAdOfferStatus.offerInvalidStatusKR
                                    }
                                </AdBidOrderMyOfferHistoryOrderReceiptModalRedFont>
                            </InfoTableColumnValue>
                        </InfoTableMajorRow>
                    )}
                </InfoTableRowContainer>
            </ModalInfoContainer>

            <ModalButtonContainer>
                <ModalButton
                    btnType={
                        !isPending
                            ? "filled-secondary"
                            : "filled-secondary-disabled"
                    }
                    onClick={() => {
                        if (!isPending) props.resolve(false);
                    }}
                >
                    닫기
                </ModalButton>

                {props.offerStatus === AD_OFFER_STATUS_OFFERED && (
                    <ModalButton
                        btnType={
                            !isPending
                                ? "filled-danger"
                                : "filled-danger-pending"
                        }
                        onClick={async () => {
                            try {
                                setIsPending(true);
                                await cancelOffer();
                                props.resolve(true);
                            } catch (e) {
                                props.resolve(false);
                            }
                            // isPending 초기화 삭제 - 실패시에도 닫는다
                        }}
                    >
                        {isPending ? (
                            <Spinner
                                width={28}
                                height={28}
                                borderSize={3}
                                color={"#fa54fa #fa54fa #fa54fa #f0f0f2"}
                            />
                        ) : (
                            "입찰 취소"
                        )}
                    </ModalButton>
                )}
                {(props.offerStatus === AD_OFFER_STATUS_SUCCESS_OFFER ||
                    props.offerStatus ===
                        AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER) &&
                    props.isTodaySale &&
                    !props.isAdStop &&
                    props.adDailyStatus !== AD_EXPIRED &&
                    props.adDailyStatus !== AD_ON_SCHEDULE && (
                        <ModalButton
                            btnType={
                                !isPending
                                    ? "filled-danger"
                                    : "filled-danger-pending"
                            }
                            onClick={async () => {
                                try {
                                    setIsPending(true);
                                    const adSalesDate = new Date(
                                        props.adSalesDate,
                                    );

                                    const daysOnAir = props.daysOnAir || 0;

                                    const confirmResult =
                                        await openCommonConfirmModal({
                                            title: "낙찰 취소",
                                            desc: `${
                                                daysOnAir === 0
                                                    ? "광고 게재일은 낙찰 취소가 불가능합니다."
                                                    : `광고 게재일 기준 D-${daysOnAir}일로 낙찰금액의 ${props.refundRatio}%가 환불됩니다.`
                                            } 그래도 낙찰 취소하시겠습니까?`,
                                            buttonTitle: "낙찰 취소",
                                        });

                                    if (confirmResult) {
                                        await cancelOfferSuccessByBuyer();
                                        props.resolve(true);
                                    }
                                } catch (e) {
                                    props.resolve(false);
                                }
                                // isPending 초기화 삭제 - 실패시에도 닫는다
                            }}
                        >
                            {isPending ? (
                                <Spinner
                                    width={28}
                                    height={28}
                                    borderSize={3}
                                    color={"#fa54fa #fa54fa #fa54fa #f0f0f2"}
                                />
                            ) : (
                                "낙찰 취소"
                            )}
                        </ModalButton>
                    )}
            </ModalButtonContainer>
        </AdBidOrderMyOfferHistoryOrderReceiptModalContainer>
    );
};

export default AdBidOrderMyOfferHistoryOrderReceiptModal;
