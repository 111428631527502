import { ReactElement, RefObject, useEffect } from "react";
import { InputTextDataType } from "../../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import {
    AdRegisterAdjustDaySaleInfoKey,
    AdRegisterAdjustDaySaleInfoRow,
    AdRegisterAdjustDaySaleInfoValueContainer,
    AdRegisterAdjustDaySaleInfoValuePriceInputContainer,
} from "../AdRegisterAdjustDaySaleSwitch";
import CommonInputTextNumber from "../../../../../common/commonUnitComponent/commonInputText/CommonInputTextNumber";

type Props = {
    adPrice: number;
    adDaySalePrice: InputTextDataType;
    setAdDaySalePrice: (value: InputTextDataType) => void;
    adDaySalePriceContainerRef: RefObject<HTMLDivElement>;
    isAdDaySale: boolean;
};

const AdRegisterAdjustDaySalePriceRow = (props: Props): ReactElement | null => {
    const maxPrice = props.adPrice - 1000;
    const minPrice = 1000;

    useEffect(() => {
        let adDaySalePrice = 3000;
        if (props.isAdDaySale) {
            if (props.adPrice / 2 >= 1000) {
                adDaySalePrice = Math.floor(props.adPrice / 2000) * 1000;
            } else {
                adDaySalePrice = 1000;
            }
        }

        props.setAdDaySalePrice({
            value: numberToStringWithComma(adDaySalePrice),
            isWarning: false,
            warningMsg: "",
        });
    }, [props.adPrice]);

    return (
        <AdRegisterAdjustDaySaleInfoRow ref={props.adDaySalePriceContainerRef}>
            <AdRegisterAdjustDaySaleInfoKey>
                할인 판매가
            </AdRegisterAdjustDaySaleInfoKey>
            <AdRegisterAdjustDaySaleInfoValueContainer>
                <AdRegisterAdjustDaySaleInfoValuePriceInputContainer>
                    <CommonInputTextNumber
                        inputNumber={props.adDaySalePrice}
                        setInputNumber={props.setAdDaySalePrice}
                        max={maxPrice}
                        min={minPrice}
                        title={"할인 판매가"}
                        postPositionalTitle={"는"}
                        isThousandCheck={true}
                        unit={"KRW"}
                    />
                </AdRegisterAdjustDaySaleInfoValuePriceInputContainer>
                KRW
            </AdRegisterAdjustDaySaleInfoValueContainer>
        </AdRegisterAdjustDaySaleInfoRow>
    );
};

export default AdRegisterAdjustDaySalePriceRow;
