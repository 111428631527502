import { ReactElement } from "react";
import styled from "styled-components";
import { TableHeaderDataType } from "../../../../common/commonUnitComponent/commonTable/CommonTable";
import { AdHistoryTableHeaderQuestionMark } from "./AdManageCommonHistoryTableStyle";

export const AdManageCommonMobileTableCellRow = styled.div`
    padding: 32px 0;
    border-bottom: 1px #ebebeb solid;
`;

export const AdManageCommonMobileTableCellLayoutRowTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AdManageCommonMobileTableCellLayoutRowInfoContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px 0;
`;

export const AdManageCommonMobileTableCellLayoutInfo = styled.div`
    display: flex;
    width: 50%;
    align-items: center;
    height: 30px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const AdManageCommonMobileTableCellLayoutInfoKey = styled.div`
    width: 126px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #777777;
`;

export const AdManageCommonMobileTableCellLayoutInfoValue = styled.div``;

type Props = {
    titleComponent: ReactElement;
    buttonComponent?: ReactElement;
    mobileHeaderDataList: TableHeaderDataType[];
    rawRowValue: any;
    convertRowValue: (
        rawRowKey: TableHeaderDataType,
        rawRowValue: any,
    ) => ReactElement;
};

const AdManageCommonMobileTableCell = (props: Props): ReactElement | null => {
    return (
        <AdManageCommonMobileTableCellRow>
            <AdManageCommonMobileTableCellLayoutRowTitleContainer>
                {props.titleComponent}
            </AdManageCommonMobileTableCellLayoutRowTitleContainer>
            <AdManageCommonMobileTableCellLayoutRowInfoContainer>
                {props.mobileHeaderDataList.map((mobileHeaderItem, idx) => {
                    return (
                        <AdManageCommonMobileTableCellLayoutInfo key={idx}>
                            {/*key */}
                            <AdManageCommonMobileTableCellLayoutInfoKey>
                                {mobileHeaderItem.component}

                                {mobileHeaderItem.questionMarkHandler && (
                                    <AdHistoryTableHeaderQuestionMark
                                        onClick={async () => {
                                            if (
                                                mobileHeaderItem.questionMarkHandler
                                            )
                                                await mobileHeaderItem.questionMarkHandler();
                                        }}
                                    >
                                        ?
                                    </AdHistoryTableHeaderQuestionMark>
                                )}
                            </AdManageCommonMobileTableCellLayoutInfoKey>

                            {/*value   */}
                            <AdManageCommonMobileTableCellLayoutInfoValue>
                                {props.convertRowValue(
                                    mobileHeaderItem,
                                    props.rawRowValue,
                                )}
                            </AdManageCommonMobileTableCellLayoutInfoValue>
                        </AdManageCommonMobileTableCellLayoutInfo>
                    );
                })}
                {props.buttonComponent && props.buttonComponent}
            </AdManageCommonMobileTableCellLayoutRowInfoContainer>
        </AdManageCommonMobileTableCellRow>
    );
};

export default AdManageCommonMobileTableCell;
