import { ReactElement } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../../../utils/Utils";

export const AdManageCommonHistoryColumnClickExposeCountContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #555555;
    display: flex;
    gap: 3px;
`;

type Props = {
    clickCount: number;
    exposeCount: number;
};

const AdManageCommonHistoryColumnClickExposeCount = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnClickExposeCountContainer>
            <div>{numberToStringWithComma(props.clickCount)}</div>
            <div>/</div>
            <div>{numberToStringWithComma(props.exposeCount)}</div>
            {props.exposeCount !== 0 && (
                <div>
                    (
                    {Math.round(
                        (props.clickCount / props.exposeCount) * 10000,
                    ) / 100}
                    %)
                </div>
            )}
        </AdManageCommonHistoryColumnClickExposeCountContainer>
    );
};

export default AdManageCommonHistoryColumnClickExposeCount;
