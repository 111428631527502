import { useEffect, useState } from "react";
import {
    MarketProjectLeftSideBarHeaderFilters,
    MarketProjectLeftSideBarHeaderLoggedInChips,
    MarketProjectLeftSideBarHeaderNonLoggedInChips,
} from "./MarketProejctSideBarHeaderMenus";
import { ChipType } from "../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { FilterType } from "../../../common/commonUiComponent/searchFilter/SearchFilter";
import { getIsLogin } from "../../../../store/auth/authKeyStore";
import { useLocation } from "react-router-dom";

export function useInitProjectItemHeaderChips(
    setHeaderFilters: (value: FilterType[]) => void,
) {
    const { state } = useLocation();
    const isLogin = getIsLogin();

    const [headerChips, setHeaderChips] = useState<ChipType[]>(
        isLogin
            ? MarketProjectLeftSideBarHeaderLoggedInChips
            : MarketProjectLeftSideBarHeaderNonLoggedInChips,
    );

    useEffect(() => {
        setHeaderChips(
            isLogin
                ? MarketProjectLeftSideBarHeaderLoggedInChips
                : MarketProjectLeftSideBarHeaderNonLoggedInChips,
        );
    }, [isLogin]);

    useEffect(() => {
        if (state === "project-add" || state === "project-adjust") {
            setHeaderChips(
                headerChips.map((chip) => {
                    return {
                        ...chip,
                        isSelected: chip.key === "my",
                    };
                }),
            );
        }
    }, [state]);

    useEffect(() => {
        // chips 전체인 경우 - searchFilter 출시 여부 > 출시완료로 세팅 + 나머지 초기화
        if (headerChips.every((chip) => !chip.isSelected)) {
            setHeaderFilters(
                MarketProjectLeftSideBarHeaderFilters.map((filters) => {
                    if (filters.key === "release_bool") {
                        return {
                            ...filters,
                            filterList: filters.filterList.map(
                                (filter, filterIdx) => {
                                    return {
                                        ...filter,
                                        isSelected: filterIdx === 0,
                                    };
                                },
                            ),
                        };
                    } else {
                        return filters;
                    }
                }),
            );
        }
        // chips 전체 아닌 경우 - searchFilter 출시 여부 > 전체 세팅 + 나머지 초기화
        else {
            setHeaderFilters(
                MarketProjectLeftSideBarHeaderFilters.map((filters) => {
                    if (filters.key === "release_bool") {
                        return {
                            ...filters,
                            filterList: filters.filterList.map((filter) => {
                                return {
                                    ...filter,
                                    isSelected: false,
                                };
                            }),
                        };
                    } else {
                        return filters;
                    }
                }),
            );
        }
    }, [headerChips]);

    return {
        headerChips,
        setHeaderChips,
    };
}
