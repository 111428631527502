import React, { createRef, ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useAtom, useSetAtom } from "jotai";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";
import isMobileLeftSideBarOpenAtom from "../../../../../../store/isMobileLeftSideBarOpenAtom";
import "../../../../modal/modalProvider/transition/modal-transition.css";
import {
    asyncPushModalComponentInfo,
    asyncRemoveModalComponentInfo,
} from "../../../../../../store/ui/ModalComponentInfoListAtom";
import FoldableBottomFixedBtn from "../../../../commonUnitComponent/foldableBottomFixedBtn/FoldableBottomFixedBtn";
import { useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import BottomSheetLayout from "../../../../modal/modalProvider/components/BottomSheetLayout";
import { ResponsiveWidth } from "../../../../../../styles/Theme";
import isShowMobileBottomButtonAtom from "../../../../../../store/ui/isShowMobileBottomButtonAtom";
import hasSubHeaderAtom from "../../../../../../store/ui/hasSubHeaderAtom";

export const MOBILE_SIDE_BAR_ID = "mobile-side-bar";

export const MobileLeftSideBarContainer = styled.div``;

type Props = {
    title: string;
    renderLeftSideBarComponent: () => ReactElement;
};
const MobileLeftSideBar = (props: Props): ReactElement | null => {
    const location = useLocation();
    const { width } = useWindowSize();
    const setHasSubHeader = useSetAtom(hasSubHeaderAtom);
    const pushModalComponentInfo = useSetAtom(asyncPushModalComponentInfo);
    const popModalComponentInfo = useSetAtom(asyncRemoveModalComponentInfo);
    const [isMobileLeftSideBarOpen, setIsMobileLeftSideBarOpen] = useAtom(
        isMobileLeftSideBarOpenAtom,
    );
    const setIsShowMobileBottomButton = useSetAtom(
        isShowMobileBottomButtonAtom,
    );
    const mobileLeftSideBarContainerRef = useRef(null);

    useOnClickOutside(mobileLeftSideBarContainerRef, () => {
        if (isMobileLeftSideBarOpen) {
            setIsMobileLeftSideBarOpen(false);
        }
    });

    useEffect(() => {
        if (width !== 0 && width > ResponsiveWidth.tabletM) {
            popModalComponentInfo("mobile-side-bar").then(() => {
                setIsMobileLeftSideBarOpen(false);
            });
        }
    }, [width]);

    useEffect(() => {
        if (!isMobileLeftSideBarOpen) {
            popModalComponentInfo("mobile-side-bar").then(() => {
                setIsMobileLeftSideBarOpen(false);
            });
        }
    }, [location, isMobileLeftSideBarOpen]);

    useEffect(() => {
        setIsShowMobileBottomButton(!isMobileLeftSideBarOpen);
    }, [isMobileLeftSideBarOpen]);

    return (
        <MobileLeftSideBarContainer>
            <FoldableBottomFixedBtn
                btnTitle={props.title}
                iconPath={`${URL_CONSTANTS.ASSET_URL}/icon/icon-list-bold3.svg`}
                onClickHandler={async () => {
                    await pushModalComponentInfo({
                        id: MOBILE_SIDE_BAR_ID,
                        type: "onlyHistory",
                        renderComponent: () => <div></div>,
                        resolve: () => {
                            setIsMobileLeftSideBarOpen(false);
                        },
                        nodeRef: createRef<HTMLDivElement>(),
                        isShowAnimated: false,
                    });
                    setIsMobileLeftSideBarOpen(true);
                }}
            />

            <TransitionGroup>
                {isMobileLeftSideBarOpen && (
                    <CSSTransition
                        timeout={300}
                        classNames={"bottom-sheet-slide-up"}
                    >
                        <BottomSheetLayout
                            modalId={"mobile-side-bar"}
                            modalResolve={(value) => {
                                popModalComponentInfo("mobile-side-bar").then(
                                    () => {
                                        setIsMobileLeftSideBarOpen(false);
                                    },
                                );
                            }}
                        >
                            {props.renderLeftSideBarComponent()}
                        </BottomSheetLayout>
                    </CSSTransition>
                )}
            </TransitionGroup>
        </MobileLeftSideBarContainer>
    );
};

export default MobileLeftSideBar;
