import { hasPathAfterResolve, useOpenModal } from "../useOpenModal";
import React from "react";
import { useNavigate } from "react-router-dom";
import AdBidOrderMyOfferHistoryOrderReceiptModal, {
    AdBidOfferReceiptInfoType,
} from "../../../components/ad/adBid/components/adBidMainContents/adBidScrollableContents/components/adBidBox/components/adBidOrderMyOfferHistoryTab/components/AdBidOrderMyOfferHistoryOrderReceiptModal";
import { AllAdMyBuyOfferListItemType } from "../../../types/ad/adMy/allAdMyBuyOffer/AllAdMyBuyOfferListItemType";
import { getIsTodaySale } from "../../ad/staticConvertAdStatusKR";
import { convertSafariAdSalesDate } from "../../../utils/Utils";

export function useOpenAdBidReceiptModal() {
    const navigate = useNavigate();
    const { openModalWithReturnValue } = useOpenModal();

    return {
        openAdBidReceiptModal: async (
            adBidReceiptInfo: AdBidOfferReceiptInfoType,
        ) => {
            return await openModalWithReturnValue(
                "ad-bid-history-area-modal",
                "bottomSheet",
                (resolve) => {
                    return (
                        <AdBidOrderMyOfferHistoryOrderReceiptModal
                            resolve={resolve}
                            {...adBidReceiptInfo}
                        />
                    );
                },
            );
        },
    };
}

export function getAdV2OfferReceiptInfo(
    offer: AllAdMyBuyOfferListItemType,
): AdBidOfferReceiptInfoType {
    return {
        date: offer.offerInfo.created_at,
        offerPrice: offer.offerInfo.offer_price,
        offerStatus: offer.offerInfo.offer_status,
        offerInvalidStatus: offer.offerInfo.offer_invalid_status,
        adOfferIdx: offer.offerInfo.offer_idx,
        adIdx: offer.advertisementInfo.ad_idx,
        adSalesDate: convertSafariAdSalesDate(
            offer.advertisementDailyInfo.ad_sales_date,
        ),
        isTodaySale: getIsTodaySale(
            offer.advertisementDailyInfo.ad_sales_daily_status,
        ),
        isAdStop: offer.advertisementInfo.ad_stop_switch_bool,
        adDailyStatus: offer.advertisementDailyInfo.ad_daily_status_code,
        refundablePrice:
            offer.advertisementDailyInfo.myOfferSuccessInfo?.refundable_price,
        refundRatio:
            offer.advertisementDailyInfo.myOfferSuccessInfo
                ?.refund_ratio_in_percent,
        daysOnAir: offer.advertisementDailyInfo.myOfferSuccessInfo?.days_on_air,
    };
}
