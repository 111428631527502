import { ReactElement } from "react";
import { ProjectDetailDataType } from "../../../../../../query/project/useProjectDataQuery";
import FoldableDesc from "../../../../../common/commonUnitComponent/foldebleDesc/FoldableDesc";

type Props = {
    projectData: ProjectDetailDataType;
};

const MarketProjectTitleDesc = (props: Props): ReactElement | null => {
    return <FoldableDesc desc={props.projectData.project_description} />;
};

export default MarketProjectTitleDesc;
