import { ReactElement } from "react";
import styled from "styled-components";
import { TableHeaderDataType } from "../../../../common/commonUnitComponent/commonTable/CommonTable";

export const MarketProjectHistoryMobileTableItemContainer = styled.div`
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px #ebebeb solid;
`;

export const MarketProjectHistoryMobileTableItemRow = styled.div`
    display: flex;
`;

export const MarketProjectHistoryMobileTableItemKey = styled.div`
    width: 110px;
    text-align: left;
`;

export const MarketProjectHistoryMobileTableItemValue = styled.div``;

type Props = {
    item: any;
    tableHeaderData: TableHeaderDataType[];
};

const MarketProjectHistoryMobileTableItem = (
    props: Props,
): ReactElement | null => {
    return (
        <MarketProjectHistoryMobileTableItemContainer>
            {props.tableHeaderData.map((tableHeaderItem, idx) => {
                return (
                    <MarketProjectHistoryMobileTableItemRow key={idx}>
                        {tableHeaderItem.key !== "itemNameColumn" && (
                            <MarketProjectHistoryMobileTableItemKey>
                                {tableHeaderItem.component}
                            </MarketProjectHistoryMobileTableItemKey>
                        )}
                        <MarketProjectHistoryMobileTableItemValue>
                            {props.item[tableHeaderItem.key]}
                        </MarketProjectHistoryMobileTableItemValue>
                    </MarketProjectHistoryMobileTableItemRow>
                );
            })}
        </MarketProjectHistoryMobileTableItemContainer>
    );
};

export default MarketProjectHistoryMobileTableItem;
