import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageScheduleCalendarCellBodyBtn } from "../AdSaleManageScheduleCalendarCellBodyBtns";
import { useOpenCommonConfirmModal } from "../../../../../../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { useSetAdDailyStatusMutation } from "../../../../../../../../../../../../query/ad/adDaily/useSetAdDailyStatusMutation";
import { format } from "date-fns";

export const AdSaleManageScheduleCalendarCellBodySetStatusBtnContainer = styled.div``;

type Props = {
    adIdx: string;
    cellDate: Date;
    isTodaySale: boolean;
};

const AdSaleManageScheduleCalendarCellBodySetStatusBtn = (
    props: Props,
): ReactElement | null => {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { mutateAsync: setAdDailyStatus } = useSetAdDailyStatusMutation(
        props.adIdx,
        {
            ad_sales_date: format(props.cellDate, "yyyy-MM-dd"),
            ad_sales_daily_status: props.isTodaySale ? "1" : "0",
        },
    );

    return (
        <AdSaleManageScheduleCalendarCellBodyBtn
            btnType={props.isTodaySale ? "filled-danger" : "filled-secondary"}
            onClick={async () => {
                if (props.isTodaySale) {
                    if (
                        await openCommonConfirmModal({
                            title: "일시 중단",
                            desc: "현재 보고계신 일자의 판매를 중단합니다. 중단된 일자의 입찰목록은 초기화됩니다.",
                            buttonTitle: "일시 중단",
                        })
                    ) {
                        await setAdDailyStatus();
                    }
                } else {
                    if (
                        await openCommonConfirmModal({
                            title: "일시 중단 해제",
                            desc: "현재 보고계신 일자의 판매 중단을 해제합니다.",
                            buttonTitle: "일시 중단 해제",
                        })
                    ) {
                        await setAdDailyStatus();
                    }
                }
            }}
        >
            {props.isTodaySale ? "일시 중단" : "일시 중단 해제"}
        </AdSaleManageScheduleCalendarCellBodyBtn>
    );
};

export default AdSaleManageScheduleCalendarCellBodySetStatusBtn;
