import { RefObject, useCallback, useEffect, useRef, useState } from "react";

export function useSetCustomScrollMouseEvent(
    wrapperRef: RefObject<HTMLElement>,
    windowRef: RefObject<HTMLElement>,
    fullPagePositionTop: number,
) {
    const [isDragging, _setIsDragging] = useState(false);
    const isDraggingRef = useRef(isDragging);
    const setIsDragging = (data: boolean) => {
        isDraggingRef.current = data;
        _setIsDragging(data);
    };

    const [dragStartPoint, _setDragStartPoint] = useState(0);
    const dragStartPointRef = useRef(dragStartPoint);
    const setDragStartPoint = (data: number) => {
        dragStartPointRef.current = data;
        _setDragStartPoint(data);
    };

    const [scrollTopBeforeDrag, _setScrollTopBeforeDrag] = useState(0);
    const scrollTopBeforeDragRef = useRef(scrollTopBeforeDrag);
    const setScrollTopBeforeDrag = (data: number) => {
        scrollTopBeforeDragRef.current = data;
        _setScrollTopBeforeDrag(data);
    };

    // 스크롤바 드래그 이벤트 - 브라우저 테두리에 걸칠 때, dom event 는 동작하지 않으므로 move, up 이벤트는 window 이벤트로 뺀다.
    // 윈도우 이벤트에서는 state 를 공유할 수 없으므로 필요한 변수는 window object 에 따로 저장.
    const handleMouseDown = (e: any) => {
        if (e.type === "touchstart") e.pageY = e.changedTouches[0].pageY;
        else {
            e.preventDefault();
        }
        setIsDragging(true);
        setDragStartPoint(e.pageY - fullPagePositionTop);
        if (windowRef.current)
            setScrollTopBeforeDrag(windowRef.current.scrollTop);
    };

    const handleMouseUp = (
        e: MouseEvent | TouchEvent | React.MouseEvent | React.TouchEvent,
    ) => {
        if (e.target instanceof Element) {
            setIsDragging(false);
            setDragStartPoint(0);
            setScrollTopBeforeDrag(0);
        }
    };
    const handleMouseMove = useCallback(
        (e: any) => {
            if (
                isDraggingRef.current &&
                wrapperRef.current &&
                windowRef.current
            ) {
                if (e.type === "touchmove") {
                    e.clientY = e.changedTouches[0].clientY;
                }
                let willScrollTopPixel =
                    scrollTopBeforeDragRef.current +
                    ((e.clientY -
                        dragStartPointRef.current -
                        fullPagePositionTop) /
                        (windowRef.current.offsetHeight -
                            fullPagePositionTop)) *
                        wrapperRef.current.offsetHeight;

                willScrollTopPixel =
                    willScrollTopPixel < 0 ? 0 : willScrollTopPixel;
                // 스크롤 바를 움직일 경우 스크롤이 되는 것이라 onScroll 이벤트에서만 페인팅.
                windowRef.current.scrollTop = willScrollTopPixel;
            }
        },
        [isDraggingRef.current, wrapperRef.current, windowRef.current],
    );

    useEffect(() => {
        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("touchend", handleMouseUp);
        window.addEventListener("touchmove", handleMouseMove);
        // window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("mouseup", handleMouseUp);
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("touchend", handleMouseUp);
            window.removeEventListener("touchmove", handleMouseMove);
            // window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    return {
        handleMouseDown,
    };
}
