import { ReactElement } from "react";
import styled from "styled-components";
import { infiniteScrollCallbackType } from "../../../../commonUiComponent/scrollbar/components/customScrollbar/CustomScrollbar";
import Scrollbar from "../../../../commonUiComponent/scrollbar/Scrollbar";
import { TableBodyWrapper } from "./TableBody";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../commonUiComponent/scrollbar/ScrollbarType";

export const TableBodyLayoutContainer = styled.div``;

type Props = {
    id: string;
    infiniteScrollCallback?: infiniteScrollCallbackType;
    tableBodyHeight: string;
    hasScrollY?: boolean;
    children: ReactElement;
};

const TableBodyLayout = (props: Props): ReactElement | null => {
    return typeof props.hasScrollY === "undefined" || props.hasScrollY ? (
        <TableBodyWrapper bodyHeight={props.tableBodyHeight}>
            <Scrollbar
                id={`table-body`}
                type={SCROLL_BAR_NORMAL_TYPE}
                infiniteScrollCallback={props.infiniteScrollCallback}
                isThin={true}
            >
                {props.children}
            </Scrollbar>
        </TableBodyWrapper>
    ) : (
        props.children
    );
};

export default TableBodyLayout;
