import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAtom } from "jotai/index";
import fullScrollTopAtom from "../../../../store/scroll/fullScrollTopAtom";
import isScrollUpAtom from "../../../../store/scroll/isScrollUpAtom";
import { checkUserAgent, getPageType } from "../../../../utils/Utils";

export const FoldableBottomFixedBtnContainer = styled.div<{
    isPcHide: boolean;
    isFolded: boolean;
    isAdType: boolean;
    isShow: boolean;
    isMobileDevice: boolean;
}>`
    position: fixed;
    z-index: 2;
    bottom: ${({ isFolded, isMobileDevice }) => {
        if (!isMobileDevice) {
            return isFolded ? "12px" : "18px";
        } else {
            return isFolded ? "10px" : "18px";
        }
    }};
    right: ${({ isMobileDevice }) => (!isMobileDevice ? "30px" : "10px")};
    display: ${({ isPcHide, isShow }) =>
        isPcHide || !isShow ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: linear-gradient(
        180deg,
        #fa54fa 0%,
        #fa54fa 0.01%,
        #ff54bb 100%
    );
    box-shadow: 0 4px 10px 0 rgba(250, 84, 250, 0.3);
    cursor: pointer;

    width: ${({ isFolded, isMobileDevice }) => {
        if (!isMobileDevice) {
            return isFolded ? "56px" : "auto";
        } else {
            return isFolded ? "44px" : "auto";
        }
    }};

    height: ${({ isFolded, isMobileDevice }) => {
        if (!isMobileDevice) {
            return isFolded ? "56px" : "44px";
        } else {
            return isFolded ? "44px" : "34px";
        }
    }};

    border-radius: ${({ isMobileDevice }) =>
        !isMobileDevice ? "24px" : "18px"};

    gap: ${({ isFolded, isMobileDevice }) => {
        if (!isMobileDevice) {
            return isFolded ? "0" : "5px";
        } else {
            return isFolded ? "0px" : "4px";
        }
    }};
    padding: ${({ isFolded }) => (isFolded ? "0" : "0 20px")};
    padding: ${({ isFolded, isMobileDevice }) => {
        if (!isMobileDevice) {
            return isFolded ? "0" : "0 20px 0 16px";
        } else {
            return isFolded ? "0px" : "0 16px 0 10px";
        }
    }};

    transition: all 300ms ease-out, border-radius 300ms ease-out;

    @media ${(props) => props.theme.tabletL} {
        bottom: ${({ isFolded, isMobileDevice }) => {
            if (!isMobileDevice) {
                return isFolded ? "75px" : "83px";
            } else {
                return isFolded ? "75px" : "83px";
            }
        }};
    }

    @media ${(props) => props.theme.tabletM} {
        display: flex;
    }
`;

export const MobileLeftSideBarBottomFixedButtonIcon = styled.img<{
    isFolded: boolean;
    isMobileDevice: boolean;
}>`
    width: ${({ isMobileDevice }) => (!isMobileDevice ? "40px" : "32px")};
    height: ${({ isMobileDevice }) => (!isMobileDevice ? "40px" : "32px")};
    transition: all 300ms ease-out;
`;

export const MobileLeftSideBarBottomFixedButtonTitleContainer = styled.div<{
    isFolded: boolean;
}>`
    width: ${({ isFolded }) => (!isFolded ? "auto" : "0")};
    overflow: hidden;
    display: flex;
    visibility: ${({ isFolded }) => (!isFolded ? "visible" : "hidden")};
    opacity: ${({ isFolded }) => (!isFolded ? 1 : 0)};
    transition: all 300ms ease-out;
`;

export const MobileLeftSideBarBottomFixedButtonTitle = styled.div<{
    isMobileDevice: boolean;
}>`
    color: #fff;
    text-align: center;
    font-size: ${({ isMobileDevice }) => (!isMobileDevice ? "20px" : "16px")};
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    flex-shrink: 0;
    word-break: break-all;
    width: 100%;
    text-overflow: hidden;
`;

type Props = {
    isShow: boolean;
    btnTitle: string;
    iconPath: string;
    isPcHide: boolean;
    onClickHandler: () => Promise<void>;
};

const FoldableBottomFixedBtn = (props: Props): ReactElement | null => {
    const [fullScrollTop] = useAtom(fullScrollTopAtom);
    const [isScrollUp] = useAtom(isScrollUpAtom);
    const pageType = getPageType(location.pathname);
    const isAdBidPage = pageType === "ad";

    const [isFolded, setIsFolded] = useState(
        !isScrollUp && fullScrollTop !== 0,
    );
    const timeout = useRef<any>(null);

    useEffect(() => {
        if (
            isFolded === !(!isScrollUp && fullScrollTop !== 0) &&
            timeout.current === null
        ) {
            // 모바일에서 터치로 scrollUp 잡는게 민감해서 애니메이션 동작중에 값이 바뀌는건
            // 애니메이션이 끝난 후 대응하는 방식으로 변경
            timeout.current = setTimeout(() => {
                if (isFolded === !(!isScrollUp && fullScrollTop !== 0)) {
                    setIsFolded(!isScrollUp && fullScrollTop !== 0);
                }
                clearTimeout(timeout.current);
                timeout.current = null;
            }, 300);
        }
    }, [isScrollUp, fullScrollTop]);

    return (
        <FoldableBottomFixedBtnContainer
            isShow={props.isShow}
            isPcHide={props.isPcHide}
            isFolded={isFolded}
            isAdType={isAdBidPage}
            onClick={props.onClickHandler}
            isMobileDevice={checkUserAgent.isMobileDevice()}
        >
            <MobileLeftSideBarBottomFixedButtonIcon
                isFolded={isFolded}
                isMobileDevice={checkUserAgent.isMobileDevice()}
                src={props.iconPath}
            />
            <MobileLeftSideBarBottomFixedButtonTitleContainer
                isFolded={isFolded}
            >
                <MobileLeftSideBarBottomFixedButtonTitle
                    isMobileDevice={checkUserAgent.isMobileDevice()}
                >
                    {props.btnTitle}
                </MobileLeftSideBarBottomFixedButtonTitle>
            </MobileLeftSideBarBottomFixedButtonTitleContainer>
        </FoldableBottomFixedBtnContainer>
    );
};

export default FoldableBottomFixedBtn;

FoldableBottomFixedBtn.defaultProps = {
    isShow: true,
    isPcHide: true,
};
