import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useA2HS } from "../../../../../../hooks/common/useA2HS";

export const UserProfileModalA2HSButtonWrapper = styled.div`
    background: white;
`;

export const UserProfileModalA2HSButtonContainer = styled.div`
    height: 52px;
    padding: 0 10px;
    margin: 0 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.15px;
    color: #222222;
    background: white;

    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 300ms ease;
    cursor: pointer;
    border-radius: 4px;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 19, 26, 0.05);
        }
    }

    @media ${(props) => props.theme.tabletL} {
        margin: 0;
        padding: 0 24px;
    }
`;

export const UserProfileModalA2HSButtonAppContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

export const UserProfileModalA2HSButtonAppIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const UserProfileModalA2HSButtonDownloadIcon = styled.img<{
    mr?: string;
}>`
    margin-right: ${({ mr }) => (mr ? mr : 0)};
`;

type Props = {
    resolve: (value: boolean | number) => void;
};

const UserProfileModalAppInstallBtn = (props: Props): ReactElement | null => {
    const { isAppInstallBtnShow, commonInstallApp } = useA2HS();

    return isAppInstallBtnShow ? (
        <UserProfileModalA2HSButtonWrapper>
            <UserProfileModalA2HSButtonContainer
                onClick={async () => {
                    await commonInstallApp();
                }}
            >
                <UserProfileModalA2HSButtonAppContainer>
                    <UserProfileModalA2HSButtonAppIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/favicon/favicon-96x96.png`}
                    />
                    앱 다운로드
                </UserProfileModalA2HSButtonAppContainer>
                <UserProfileModalA2HSButtonDownloadIcon
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-download.svg`}
                />
            </UserProfileModalA2HSButtonContainer>
        </UserProfileModalA2HSButtonWrapper>
    ) : null;
};

export default UserProfileModalAppInstallBtn;
