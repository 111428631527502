import { Query, useQuery } from "react-query";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { queryClient } from "../../../App";
import { MarketCommonApi } from "../../../apis/market/MarketCommonApi";

export type MarketAveragePriceResultType = {
    averagePrice: number; // 평규 가격
    availableQuantity: number; // 구매 가능 수량
    isInfiniteBool: boolean; // 무한 유무
    totalAmount: number; // 구매 가능 가격
    royalty: {
        brandRoyalty: number;
        creatorRoyalty: number;
    };
};

export type MarketAveragePriceParams = {
    quantity: number;
};

export const useGetMarketAveragePrice = (
    pageType: string,
    targetIdx: string,
    params: MarketAveragePriceParams,
    enabled: boolean,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();

    const queryResult = useQuery<MarketAveragePriceResultType, Error>({
        queryKey: [
            `market-${pageType}-average-price-${targetIdx}${JSON.stringify(
                params,
            )}`,
            `market-${pageType}-average-price-${targetIdx}`,
        ],
        queryFn: () =>
            MarketCommonApi.getAveragePrice(pageType, targetIdx, params),
        suspense: false,
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
        cacheTime: 0,
        staleTime: 0,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                predicate: (query: Query) =>
                    query.queryKey.includes(
                        `${pageType}-info-refresh-target-${targetIdx}`,
                    ) || query.queryKey === `${pageType}-info-refresh-target`,
            });
        },
        enabled: enabled,
    });

    const DefaultMarketAveragePriceResult: MarketAveragePriceResultType = {
        averagePrice: 0,
        availableQuantity: -1,
        totalAmount: 0,
        isInfiniteBool: false,
        royalty: {
            brandRoyalty: 0,
            creatorRoyalty: 0,
        },
    };

    return {
        ...queryResult,
        data: queryResult.data || DefaultMarketAveragePriceResult,
    };
};
