import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import {
    ProjectListApiParamsType,
    ProjectListApiResultType,
} from "../query/project/useProjectListQuery";
import { ProjectDetailDataType } from "../query/project/useProjectDataQuery";
import { getAuthKey } from "../store/auth/authKeyStore";
import { AddProjectParams } from "../query/project/useAddProjectMutation";
import { convertFormData } from "../utils/Utils";
import { AdjustProjectParams } from "../query/project/useAdjustProjectMutation";

export const ProjectApi = {
    getList: async (
        params: ProjectListApiParamsType,
    ): Promise<ProjectListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/project/list", {
                    params: params,
                    paramsSerializer: {
                        // recent_array number[] 보낼때 payload key recent_array[] 대괄호 지우는 옵션
                        indexes: null,
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getDetailData: async (
        projectIdx: string,
    ): Promise<ProjectDetailDataType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/project/" + projectIdx)
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    addProject: async (params: AddProjectParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/item/project",
                    convertFormData(params),
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    checkDuplicateProjectColumn: async (
        columnKey: string,
        value: string,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/item/project/${columnKey}/check`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                        params: {
                            [columnKey]: value,
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    adjustProject: async (
        projectIdx: string,
        params: AdjustProjectParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    URL_CONSTANTS.API_URL + "/item/project/" + projectIdx,
                    convertFormData(params),
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deleteProject: async (projectIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(URL_CONSTANTS.API_URL + "/item/project/" + projectIdx, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
