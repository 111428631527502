import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import TutorialCategory, {
    TutorialCategoryContainer,
} from "./components/tutorialCategory/TutorialCategory";
import {
    ModalContainer,
    ModalTitle,
} from "../../commonUnitComponent/modal/ModalStyle";
import { TUTORIAL_INFO_LIST } from "./TutorialInfoConstants";
import TutorialUpperCategory from "./components/tutorialCategory/components/TutorialUpperCategory";
import TutorialSubCategory from "./components/tutorialCategory/components/TutorialSubCategory";
import TutorialContents from "./components/tutorialContents/TutorialContents";
import TutorialContactBtn from "./components/TutorialContactBtn";
import TutorialCategories from "./components/tutorialCategory/TutorialCategories";
import { useWindowSize } from "usehooks-ts";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { ResponsiveWidth } from "../../../../styles/Theme";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";

export const TutorialModalWrapper = styled.div`
    width: 672px;
    height: 548px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        height: 598px;
        overflow: hidden;
    }
`;

export const TutorialModalTitleContainer = styled.div<{
    isSelectOnMobile: boolean;
}>`
    display: flex;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        justify-content: ${({ isSelectOnMobile }) =>
            !isSelectOnMobile ? "center" : "flex-start"};
    }
`;

export const TutorialModalTitle = styled.div`
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 100% */
    margin-top: 4px;
    margin-bottom: 16px;

    @media ${(props) => props.theme.mobileL} {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 20px */
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const TutorialContentsMobileHeaderIcon = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

export const TutorialModalContainer = styled.div<{
    isSelectOnMobile: boolean;
}>`
    width: 100%;
    height: inherit;
    max-height: 534px;
    margin-top: 20px;
    display: flex;
    gap: 32px;

    @media ${(props) => props.theme.mobileL} {
        transform: translateX(
            ${({ isSelectOnMobile }) => (isSelectOnMobile ? "-100%" : 0)}
        );
        transition: all 300ms ease;
        gap: 0;
        max-height: unset;
    }
`;

export type TutorialInfoListType = {
    upperCategoryTitle: string;
    upperCategoryKey: string;
    upperCategoryIcon: string;
    isOpen: boolean;
    subCategoryList: TutorialSubCategoryInfoType[];
};

export type TutorialSubCategoryInfoType = {
    subCategoryTitle: string;
    title: string;
    desc: string;
    descAnnotation: string;
    imgSrc: string;
    guideLink?: string;
    actionLink?: {
        btnTitle: string;
        btnLink: string;
        btnAction?: () => void;
        btnLinkState?: any;
    };
    key: string;
    isSelected: boolean;
};

export const findSelectedTutorialInfo = (key: string) => {};

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
};

const TutorialModal = (props: Props): ReactElement | null => {
    const [tutorialInfoList, setTutorialInfoList] =
        useState(TUTORIAL_INFO_LIST);

    const [isSelectOnMobile, setIsSelectOnMobile] = useState(false);
    const { width } = useWindowSize();

    useEffect(() => {
        setIsSelectOnMobile(false);
    }, [width]);

    const selectedInfo =
        tutorialInfoList
            .find((upperCategory) => {
                return upperCategory.subCategoryList.find(
                    (subCategory) => subCategory.isSelected,
                );
            })
            ?.subCategoryList.find((subCategory) => subCategory.isSelected) ||
        tutorialInfoList[0].subCategoryList[0];

    return (
        <TutorialModalWrapper>
            <TutorialModalTitleContainer isSelectOnMobile={isSelectOnMobile}>
                {!isSelectOnMobile || width > ResponsiveWidth.mobileL ? (
                    <TutorialModalTitle>세이버즈 가이드</TutorialModalTitle>
                ) : (
                    <>
                        <TutorialContentsMobileHeaderIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-arrow-back.png`}
                            onClick={() => {
                                setIsSelectOnMobile(false);
                            }}
                        />
                        <TutorialModalTitle>
                            {selectedInfo.subCategoryTitle}
                        </TutorialModalTitle>
                    </>
                )}
            </TutorialModalTitleContainer>

            <TutorialModalContainer isSelectOnMobile={isSelectOnMobile}>
                <TutorialCategories
                    tutorialInfoList={tutorialInfoList}
                    setTutorialInfoList={setTutorialInfoList}
                    setIsSelectOnMobile={setIsSelectOnMobile}
                />
                <TutorialContents
                    tutorialInfoList={tutorialInfoList}
                    setTutorialInfoList={setTutorialInfoList}
                    setIsSelectOnMobile={setIsSelectOnMobile}
                    resolve={props.resolve}
                />
            </TutorialModalContainer>
        </TutorialModalWrapper>
    );
};

export default TutorialModal;
