import { useEffect } from "react";
import { useWindowSize } from "usehooks-ts";
import { ResponsiveWidth } from "../../styles/Theme";

export const useBackgroundScrollFix = (isShow: boolean) => {
    const { width } = useWindowSize();

    useEffect(() => {
        if (width < ResponsiveWidth.tabletL) {
            // const body = document.querySelector("body");
            // if (body !== null) body.style.overflow = isShow ? "hidden" : "";
        }
    }, [isShow]);
};
