import { useMutation } from "react-query";
import { TitleItemApi } from "../../../apis/TitleItemApi";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useAtom } from "jotai";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";

export const useTitleItemDeleteMutation = (itemIdx: string) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<null, Error>(
        () => TitleItemApi.deleteTitleItem(itemIdx),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },

            onSuccess: () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "해당아이템이 대표 아이템에서 해제되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
