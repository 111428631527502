import { useQuery } from "react-query";
import { MyMarketPointApi } from "../../../apis/myApi/MyMarketPointApi";

export type pointTypeType = {
    [key: string]: string;
    RP: string;
    SP: string;
    ST: string;
};

export const POINT_TYPE: pointTypeType = {
    RP: "1",
    SP: "2",
    ST: "3",
};

export type MyPointType = {
    [key: string]: any;
    rp: MyPointListItemType;
    sp: MyPointListItemType;
    st: MyPointListItemType;
};

export type MyPointListApiResultType = MyPointListItemType[];

export type MyPointListItemType = {
    created_at: string;
    point_idx: string;
    remaining: number;
    remaining_unsettled: number;
    updated_at: string;
    wallet_idx: string;
    wallet_remaining_idx: string;
    point_type: string;
};

export const DefaultMyPointListItem = {
    created_at: "",
    point_idx: "",
    remaining: 0,
    remaining_unsettled: 0,
    updated_at: "",
    wallet_idx: "",
    wallet_remaining_idx: "",
    point_type: "",
};

export const useGetMyRemainingPointListQuery = (isEnabled = true) => {
    const queryResult = useQuery<MyPointListApiResultType, Error>({
        queryKey: ["my-point-list", "wallet-refresh-target"],
        queryFn: () => MyMarketPointApi.getMyPointRemainingList(),
        suspense: false,
        enabled: isEnabled,
    });

    const defaultMyPoint: MyPointType = {
        rp:
            queryResult.data?.find(
                (point) => point.point_type === POINT_TYPE.RP,
            ) ?? DefaultMyPointListItem,
        sp:
            queryResult.data?.find(
                (point) => point.point_type === POINT_TYPE.SP,
            ) ?? DefaultMyPointListItem,
        st:
            queryResult.data?.find(
                (point) => point.point_type === POINT_TYPE.ST,
            ) ?? DefaultMyPointListItem,
    };

    return {
        myPoint: defaultMyPoint,
        ...queryResult,
        data: queryResult.data || [],
    };
};
