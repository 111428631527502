import { ReactElement } from "react";
import { useSetUserInfoQuery } from "../../../../query/user/useSetUserInfoQuery";

type Props = {
    children: ReactElement[];
};

const UserInfoProvider = (props: Props): ReactElement | null => {
    // 최초 접근시 AuthKey 로 userInfo 초기화
    const { isFetched } = useSetUserInfoQuery();

    if (isFetched) return <>{props.children.map((c) => c)}</>;
    else return null;
};

export default UserInfoProvider;
