import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";

export const LikeApi = {
    like: async (type: string, targetIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/${
                        type === "ad/comment" ? "v2/ad/comment" : type
                    }/${targetIdx}/like`,
                    {},
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    unLike: async (type: string, targetIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    `${URL_CONSTANTS.API_URL}/${
                        type === "ad/comment" ? "v2/ad/comment" : type
                    }/${targetIdx}/like`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
