import { ReactElement } from "react";
import styled from "styled-components";
import CalendarSelectedMobileCellRow from "./CalendarSelectedMobileCellRow";

export const CalendarCellMobileBlueRowContainer = styled.div``;

type Props = {
    keyMsg: string;
    valueMsg: string | ReactElement;
    hasCancelLine?: boolean;
    opacity?: number;
};

const CalendarSelectedMobileCellBlueRow = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarSelectedMobileCellRow
            {...props}
            keyStyle={{
                color: "#2E8EFF",
            }}
            valueStyle={{
                opacity: props.opacity || 1,
            }}
        />
    );
};

export default CalendarSelectedMobileCellBlueRow;
