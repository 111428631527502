import { ReactElement, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import CssFilterConverter from "css-filter-converter";
import { useExtractUrlMetaQuery } from "../../../../query/item/useExtractUrlMetaQuery";

export const RepresentativeUrlContainer = styled.a`
    padding: 5px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 188px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
    gap: 6px;
`;

export const RepresentativeUrlIcon = styled.img`
    width: 22px;
    height: 22px;
`;

export const RepresentativeUrlTitle = styled.div`
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
`;
type Props = {
    link: string;
};

const RepresentativeUrl = (props: Props): ReactElement | null => {
    try {
        const { data: linkMetaData, isSuccess: isLinkMetaDataSuccess } =
            useExtractUrlMetaQuery(props.link);
        const isGetSuccess = isLinkMetaDataSuccess && linkMetaData.extract_bool;
        const title =
            isGetSuccess && linkMetaData.title !== ""
                ? linkMetaData.title
                : props.link;

        const [isFaviconLoadError, setIsFaviconLoadError] = useState(false);

        const favicon =
            isGetSuccess &&
            linkMetaData.favicon_url !== "" &&
            !isFaviconLoadError
                ? linkMetaData.favicon_url
                : URL_CONSTANTS.ASSET_URL + "/icon/icon-world.svg";

        return (
            <RepresentativeUrlContainer
                href={props.link}
                rel="noopener noreferrer"
                target={"_blank"}
            >
                <RepresentativeUrlIcon
                    style={
                        isGetSuccess
                            ? {}
                            : {
                                  filter:
                                      CssFilterConverter.hexToFilter("#ffffff")
                                          .color + "",
                              }
                    }
                    src={favicon}
                    onError={() => {
                        setIsFaviconLoadError(true);
                    }}
                />
                <RepresentativeUrlTitle>{title}</RepresentativeUrlTitle>
            </RepresentativeUrlContainer>
        );
    } catch (e) {
        console.info("extract favicon error", e);
    }
    return null;
};

export default RepresentativeUrl;
