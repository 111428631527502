import { ReactElement } from "react";
import styled from "styled-components";
import { CarouselContainer } from "../../CarouselStyle";

export const CarouselArrowButtonContainer = styled.div<{
    isArrowHoverEvent: boolean;
    direction: string;
    isDragging: boolean;
}>`
    opacity: ${({ isArrowHoverEvent }) => (isArrowHoverEvent ? 0 : 1)};
    transition: opacity ease 300ms;
    position: absolute;
    display: block;
    z-index: ${({ isDragging }) => (isDragging ? 1 : 3)};
    // height: 100%;

    @media (hover: hover) and (pointer: fine) {
        ${CarouselContainer}:hover & {
            opacity: 1;
        }
    }

    ${({ direction }) => {
        return direction === "left"
            ? { left: "0", top: "50%" }
            : { right: "0", top: "50%" };
    }}
`;

type Props = {
    renderArrow: (data: any) => ReactElement;
    direction: string;
    isArrowHoverEvent: boolean;
    onClick?: (value?: any) => void;
    isDragging: boolean;
};

const CarouselArrowButton = (props: Props): ReactElement | null => {
    return (
        <CarouselArrowButtonContainer
            isDragging={props.isDragging}
            direction={props.direction}
            isArrowHoverEvent={props.isArrowHoverEvent}
            onClick={props.onClick}
        >
            {props.renderArrow({ type: props.direction })}
        </CarouselArrowButtonContainer>
    );
};

export default CarouselArrowButton;
