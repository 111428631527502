import { ReactElement, useRef } from "react";
import styled from "styled-components";
import SearchResultNoData from "../SearchResultNoData";
import Spinner from "../../../../commonUnitComponent/spinner/Spinner";
import Scrollbar from "../../../../commonUiComponent/scrollbar/Scrollbar";
import {
    GlobalAllSearchResultCategories,
    GlobalSearchResultTabIDsType,
} from "../../../../../../constants/menus/headerMenus";
import SearchResultListItems from "./SearchResultListItems";
import getGlobalSearchQuery from "../../../../../../query/search/getGlobalSearchQuery";
import viewHeightAtom from "../../../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import { useDebounce } from "usehooks-ts";
import globalSearchInputAtom from "../../../../../../store/globalSearch/globalSearchInputAtom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../commonUiComponent/scrollbar/ScrollbarType";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";

// 통합검색 loading fallback container
const HeaderSearchResultListLoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 84px;
    width: 100%;
    background: transparent;
`;

export const SearchResultDataWrapper = styled(TransitionGroup)<{
    vh: number;
    height: string;
}>`
    position: relative;
    height: ${({ height }) => height};
    max-height: calc(100vh - 180px);
    transition: height 100ms ease;

    @media ${(props) => props.theme.tabletL} {
        height: ${({ vh }) => `calc(${100 * vh}px - 111px)`};
        max-height: none;
    }
`;

export const SearchResultDataContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

type Props = {
    selectedSearchMenuId: GlobalSearchResultTabIDsType;
    setSelectedSearchMenuId: (value: GlobalSearchResultTabIDsType) => void;
    resolve: (value: boolean | number | PathAfterResolveType) => void;
};

const SearchResultList = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const [globalSearchInput] = useAtom(globalSearchInputAtom);
    const debouncedSearchInput = useDebounce<string>(globalSearchInput, 500);

    const {
        data: rawGlobalSearchData,
        error: errorGlobalSearch,
        isLoading: isGlobalSearchLoading,
    } = getGlobalSearchQuery({
        keyword: debouncedSearchInput,
        tab: props.selectedSearchMenuId,
        isEnabled: true,
    });

    const searchResultListLength =
        props.selectedSearchMenuId === "total"
            ? Object.values(rawGlobalSearchData).reduce(
                  (total, subArr) => total + subArr.length,
                  0,
              )
            : rawGlobalSearchData[
                  GlobalAllSearchResultCategories.find(
                      (category) => category.id === props.selectedSearchMenuId,
                  )?.key || "ad"
              ].length;

    const nonEmptyResultListCount = Object.values(rawGlobalSearchData).filter(
        (list) => list.length !== 0,
    ).length;

    const listRef = useRef<HTMLDivElement>(null);

    const wrapperHeight =
        isGlobalSearchLoading || errorGlobalSearch
            ? listRef.current?.offsetHeight + "px" || "261px"
            : searchResultListLength === 0
            ? "261px"
            : (props.selectedSearchMenuId === "total"
                  ? 10 +
                    nonEmptyResultListCount * (30 + 34 + 10) +
                    searchResultListLength * 64
                  : 10 + searchResultListLength * 64 + 10) + "px";

    return (
        <SearchResultDataWrapper vh={vh} height={wrapperHeight}>
            {isGlobalSearchLoading ? (
                <CSSTransition
                    key={"search-result-list-loading"}
                    timeout={300}
                    classNames={"b-absolute-loading-fade"}
                >
                    <HeaderSearchResultListLoadingContainer>
                        <Spinner />
                    </HeaderSearchResultListLoadingContainer>
                </CSSTransition>
            ) : errorGlobalSearch || searchResultListLength === 0 ? (
                <CSSTransition
                    key={"search-result-list-no"}
                    timeout={300}
                    classNames={"b-absolute-loading-fade"}
                >
                    <SearchResultNoData searchInput={globalSearchInput} />
                </CSSTransition>
            ) : (
                <CSSTransition
                    key={"search-result-list-data"}
                    timeout={300}
                    classNames={"b-absolute-loading-fade"}
                >
                    <Scrollbar
                        type={SCROLL_BAR_NORMAL_TYPE}
                        id={"search-result-list"}
                    >
                        <SearchResultDataContainer ref={listRef}>
                            {GlobalAllSearchResultCategories.map(
                                (menuId, menuIdx) => (
                                    <SearchResultListItems
                                        resolve={props.resolve}
                                        key={menuIdx}
                                        searchResults={
                                            rawGlobalSearchData[menuId.key] ||
                                            []
                                        }
                                        selectedSearchMenuId={
                                            props.selectedSearchMenuId
                                        }
                                        setSelectedSearchMenuId={
                                            props.setSelectedSearchMenuId
                                        }
                                        searchListItemTitle={menuId.name}
                                        searchListItemId={menuId.id}
                                    />
                                ),
                            )}
                        </SearchResultDataContainer>
                    </Scrollbar>
                </CSSTransition>
            )}
        </SearchResultDataWrapper>
    );
};

export default SearchResultList;
