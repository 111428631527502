import {SortableHandle} from "react-sortable-hoc";
import {ReactElement} from "react";

type Props = {
    renderTrigger: (value?: any) => ReactElement;
};

const DndListItemTrigger = SortableHandle<Props>((props: Props) =>
    props.renderTrigger(),
);

export default DndListItemTrigger;
