import { CampaignInfoSpaceType } from "./CampaignInfoSpaceType";

export type CampaignInfoType = {
    campaign_idx: string;
    campaign_id: string;
    campaign_type: string;
    campaign_name: string;
    campaign_description: string;
    campaign_note_array: string[];
    campaign_thumbnail_path: string;
    campaign_space: CampaignInfoSpaceType[];
    user_idx: string;
    brand_id: string;
    brand_idx: string;
    brand_royalty: number;
    user_royalty: number;
    creator_royalty: number;
    days_to_adjust_after_ad: number;
    service_country: string;
    created_at: string;
    updated_at: string;
    campaign_ad_sell_preview: {
        campaign_ad_sell_preview_background_path: string;
        campaign_ad_sell_preview_idx: string;
        campaign_idx: string;
        created_at: string;
        order_no: number;
        updated_at: string;
    }[];
};

export const DEFAULT_CAMPAIGN_INFO_TYPE_DATA: CampaignInfoType = {
    campaign_idx: "",
    campaign_id: "",
    campaign_type: "",
    campaign_name: "",
    campaign_description: "",
    campaign_note_array: [],
    campaign_thumbnail_path: "",
    campaign_space: [],
    user_idx: "",
    brand_id: "",
    brand_idx: "",
    brand_royalty: 0,
    user_royalty: 0,
    creator_royalty: 0,
    days_to_adjust_after_ad: 0,
    service_country: "",
    created_at: "",
    updated_at: "",
    campaign_ad_sell_preview: [],
};
