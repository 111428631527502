import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";

export const SearchFilterButtonContainer = styled.div`
    padding: 6px 10px 6px 6px;
    display: flex;

    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    border-radius: 8px;

    color: #999999;
    cursor: pointer;
    flex-shrink: 0;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f5f5f5;
            color: #999999;
        }
    }
    transition: all 300ms ease;
`;

export const SearchFilterIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 4px;
`;

type Props = {
    id: string;
    isSearchFilterOpen: boolean;
    setIsSearchFilterOpen: (value: boolean) => void;
};

const SearchFilterButton = (props: Props): ReactElement | null => {
    return (
        <SearchFilterButtonContainer
            onClick={() => {
                props.setIsSearchFilterOpen(!props.isSearchFilterOpen);
            }}
        >
            <SearchFilterIcon
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-filter.svg`}
            />
            검색필터
        </SearchFilterButtonContainer>
    );
};

export default SearchFilterButton;
