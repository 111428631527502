import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { CoachMarkInfoAtom } from "../../../../store/ui/coachMarkInfoAtom";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import CoachMark from "./CoachMark";
import { useWindowSize } from "usehooks-ts";
import { useLocation } from "react-router-dom";
import fullScrollTopAtom from "../../../../store/scroll/fullScrollTopAtom";
import isMobileKeyboardOpenAtom from "../../../../store/ui/isMobileKeyboardOpenAtom";
import { ResponsiveWidth } from "../../../../styles/Theme";
import { useLocationChangeEffect } from "../../../../hooks/common/useLocationChangeEffect";

export const CoachMarkDimWrapper = styled.div``;

export const CoachMarkDimContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const CoachMarkDim = styled.div`
    position: fixed;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.2);
    width: 100vw;
    z-index: 2222;
`;

export const CoachMarkDimTop = styled(CoachMarkDim)``;

export const CoachMarkDimLeft = styled(CoachMarkDim)``;

export const CoachMarkDimRight = styled(CoachMarkDim)``;

export const CoachMarkDimBottom = styled(CoachMarkDim)``;

type Props = {};

const CoachMarkProvider = (props: Props): ReactElement | null => {
    const [fullScrollTop] = useAtom(fullScrollTopAtom);
    const location = useLocation();
    const [vh] = useAtom(viewHeightAtom);
    const [coachMarkInfo, setCoachMarkInfo] = useAtom(CoachMarkInfoAtom);
    const [isMobileKeyboardOpen] = useAtom(isMobileKeyboardOpenAtom);

    const [coachMarkOrder, setCoachMarkOrder] = useState(0);
    const { width } = useWindowSize();

    useEffect(() => {
        // 안쓰는 로직인데 혹시모르니 일단 살려는 둔다.
        const handleVisualViewPortResize = () => {
            // console.info(visualViewport?.height, window.innerHeight);
            if (
                (visualViewport?.height || window.innerHeight) <
                window.innerHeight
            ) {
                // 모바일 키패드 열림
            } else {
                // 모바일 키패드 닫힘
            }
        };

        if (visualViewport) {
            visualViewport.onresize = handleVisualViewPortResize;
        }

        return () => {
            if (visualViewport) {
                visualViewport.onresize = null;
            }
        };
    }, []);

    useEffect(() => {
        if (coachMarkInfo.coachMarkList.length !== 0) {
            coachMarkInfo.coachMarkList[
                coachMarkOrder
            ].containerRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    }, [coachMarkInfo, coachMarkOrder]);

    useLocationChangeEffect((prevLocation) => {
        setCoachMarkOrder(0);
    });

    useEffect(() => {
        // only re render
    }, [width, fullScrollTop]);

    const nextCoachMark = () => {
        if (coachMarkOrder < coachMarkInfo.coachMarkList.length - 1) {
            if (
                coachMarkInfo &&
                coachMarkInfo.coachMarkList &&
                coachMarkInfo.coachMarkList[coachMarkOrder] &&
                typeof coachMarkInfo.coachMarkList[coachMarkOrder].resolve ===
                    "function"
            ) {
                const resolveFunction =
                    coachMarkInfo?.coachMarkList?.[coachMarkOrder]?.resolve ||
                    (() => {});
                resolveFunction(true);
            }

            setCoachMarkOrder(coachMarkOrder + 1);
        } else {
            setCoachMarkOrder(0);
            setCoachMarkInfo({
                ...coachMarkInfo,
                coachMarkList: [],
            });
        }
    };

    return coachMarkInfo.coachMarkList.length !== 0 &&
        !coachMarkInfo.isHideForever ? (
        <CoachMarkDimWrapper>
            {coachMarkInfo.coachMarkList.map(
                (coachMarkInfoItem, coachMarkInfoIdx) => {
                    const pos =
                        coachMarkInfoItem.containerRef.current?.getBoundingClientRect();

                    const offset = {
                        left:
                            (pos?.left || 0) -
                            (coachMarkInfoItem.padding?.left || 0),
                        top:
                            (pos?.top || 0) -
                            (coachMarkInfoItem.padding?.top || 0),
                        width:
                            (pos?.width || 0) +
                            (coachMarkInfoItem.padding?.left || 0) +
                            (coachMarkInfoItem.padding?.right || 0),
                        height:
                            (pos?.height || 0) +
                            (coachMarkInfoItem.padding?.bottom || 0),
                    };
                    const minOffsetTop =
                        width > ResponsiveWidth.tabletL
                            ? 80
                            : width <= ResponsiveWidth.mobileL
                            ? 0
                            : 60;
                    if (
                        coachMarkInfoIdx === coachMarkOrder &&
                        !isMobileKeyboardOpen
                    ) {
                        return (
                            <CoachMarkDimContainer
                                key={coachMarkInfoIdx}
                                onWheel={(e) => {
                                    if (document.getElementById("full")) {
                                        document
                                            .getElementById("full")
                                            ?.scrollTo(
                                                0,
                                                (document.getElementById("full")
                                                    ?.scrollTop || 0) +
                                                    e.deltaY,
                                            );
                                    }
                                }}
                            >
                                {/*   -=-   */}
                                {/*   - -   */}
                                {/*   ---   */}
                                {/*Top 에 topnav 를 안 포함하려다 보니 영역이 바뀜*/}
                                <CoachMarkDimTop
                                    onClick={nextCoachMark}
                                    style={{
                                        top: 0,
                                        left: offset.left + "px",
                                        width: offset.width + "px",
                                        height:
                                            offset.top < minOffsetTop
                                                ? minOffsetTop
                                                : offset.top + "px",
                                    }}
                                />
                                {/*   =--   */}
                                {/*   = -   */}
                                {/*   ---   */}
                                <CoachMarkDimLeft
                                    onClick={nextCoachMark}
                                    style={{
                                        top: 0,
                                        left: 0,
                                        width:
                                            offset.left < 0
                                                ? 0
                                                : offset.left + "px",
                                        height:
                                            offset.top + offset.height + "px",
                                    }}
                                />
                                {/*   --=   */}
                                {/*   - =   */}
                                {/*   ---   */}
                                <CoachMarkDimRight
                                    onClick={nextCoachMark}
                                    style={{
                                        top: 0,
                                        left: offset.left + offset.width + "px",
                                        width: `calc(100vw - ${
                                            offset.left + offset.width
                                        }px)`,
                                        height:
                                            offset.top + offset.height + "px",
                                    }}
                                />
                                {/*   ---   */}
                                {/*   - -   */}
                                {/*   ===   */}
                                <CoachMarkDimBottom
                                    onClick={nextCoachMark}
                                    style={{
                                        top:
                                            (offset.top + offset.height > 0
                                                ? offset.top + offset.height
                                                : 0) + "px",
                                        height: 100 * vh + "px",

                                        left: 0,
                                    }}
                                >
                                    <CoachMark
                                        coachMarkOrder={coachMarkOrder}
                                        setCoachMarkOrder={setCoachMarkOrder}
                                        coachMarkInfoItem={coachMarkInfoItem}
                                        isLast={
                                            coachMarkInfoIdx ===
                                            coachMarkInfo.coachMarkList.length -
                                                1
                                        }
                                        idx={coachMarkInfoIdx}
                                        isOnlyOne={
                                            coachMarkInfo.coachMarkList
                                                .length === 1
                                        }
                                    />
                                </CoachMarkDimBottom>
                            </CoachMarkDimContainer>
                        );
                    }
                },
            )}
        </CoachMarkDimWrapper>
    ) : (
        <></>
    );
};

export default CoachMarkProvider;
