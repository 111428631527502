import React, { ReactElement } from "react";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import styled from "styled-components";
import Carousel, {
    RenderComponentDataType,
} from "../../../common/commonUnitComponent/carousel/Carousel";
import {
    AdRegisterAdjustBodyContainer,
    AdRegisterAdjustHeaderTitle,
} from "../AdRegisterContents";
import { onErrorImg } from "../../../../utils/Utils";
import { AdCarouselArrowButton } from "../../../common/commonUnitComponent/carousel/components/carouselArrowButton/CarouselArrowButtonStyle";
import { NormalCarouselIndicator } from "../../../common/commonUnitComponent/carousel/components/carouselPageIndicator/CarouselPageIndicatorStyle";

export const AdRegisterAdjustImageAreaContainer = styled.div``;

export const AdRegisterAdjustCarouselWrapper = styled.div`
    background: #f5f5f7;
    border-radius: 8px;
`;

export const AdRegisterAdjustCarouselContainer = styled.div`
    width: 100%;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AdRegisterAdjustCarouselImg = styled.img`
    padding: 10px;
    width: calc(100% - 20px);
    height: 360px;
    margin: 0 auto;
    max-height: 100%;
    object-fit: contain;
`;

export const AdRegisterAdjustCarouselArrowButton = styled(
    AdCarouselArrowButton,
)<{
    direction: string;
}>``;

export const AdRegisterAdjustCarouselIndicator = styled(
    NormalCarouselIndicator,
)`
    bottom: 10px;
    right: 10px;
`;

type Props = {
    campaignSpacePreviewBackgroundPath: string[];
};

const AdRegisterAdjustImageArea = (props: Props): ReactElement | null => {
    return (
        <WhiteBox>
            <AdRegisterAdjustImageAreaContainer>
                <AdRegisterAdjustHeaderTitle>
                    광고 영역
                </AdRegisterAdjustHeaderTitle>
                <AdRegisterAdjustBodyContainer>
                    <AdRegisterAdjustCarouselWrapper>
                        <Carousel
                            isShowPagination={true}
                            responsiveDisplayLength={[1, 1, 1, 1, 1, 1]}
                            dataList={props.campaignSpacePreviewBackgroundPath}
                            displayLength={1}
                            renderComponent={(
                                data: RenderComponentDataType,
                            ) => (
                                <AdRegisterAdjustCarouselContainer
                                    key={data.idx}
                                >
                                    <AdRegisterAdjustCarouselImg
                                        onError={onErrorImg}
                                        src={data.listItem}
                                    />
                                </AdRegisterAdjustCarouselContainer>
                            )}
                            renderArrow={(data) => (
                                <AdRegisterAdjustCarouselArrowButton
                                    direction={data.type}
                                />
                            )}
                            renderPagination={(data) => (
                                <AdRegisterAdjustCarouselIndicator>
                                    {data.currentIndex + 1}/{data.length}
                                </AdRegisterAdjustCarouselIndicator>
                            )}
                        />
                    </AdRegisterAdjustCarouselWrapper>
                </AdRegisterAdjustBodyContainer>
            </AdRegisterAdjustImageAreaContainer>
        </WhiteBox>
    );
};

export default AdRegisterAdjustImageArea;
