import { ReactElement } from "react";
import styled from "styled-components";
import { RenderCalendarCellParamType } from "./CalendarDateBody";
import { format } from "date-fns";

export const CalendarCellContainer = styled.div<{
    calendarLength: number;
    isCellFold: boolean;
    isShowOnlyWeek: boolean;
}>`
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    min-height: ${({ isCellFold }) => (isCellFold ? "0" : "inherit")};
    height: ${({ isCellFold }) => (isCellFold ? "0" : "inherit")};
    padding: ${({ isCellFold }) => (isCellFold ? "0" : "10px")};
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 16px;

    opacity: ${({ isCellFold }) => (isCellFold ? 0 : 1)};

    border-right: ${({ isCellFold }) =>
        isCellFold ? "none" : "1px solid #ebebeb"};
    border-bottom: ${({ isCellFold, isShowOnlyWeek }) =>
        isCellFold || isShowOnlyWeek ? "none" : "1px solid #ebebeb"};

    &:nth-child(7n) {
        border-right: none;
    }

    &:nth-child(n + ${({ calendarLength }) => calendarLength - 6}) {
        ${({ isCellFold }) => {
            if (!isCellFold) {
                return "border-bottom: none";
            }
        }}
    }

    @media ${(props) => props.theme.tabletL} {
        padding: ${({ isCellFold }) => (isCellFold ? "0" : "6px")};
    }

    @media ${(props) => props.theme.mobileL} {
        min-height: ${({ isCellFold }) => (isCellFold ? "0" : "inherit")};
        padding: ${({ isCellFold }) => (isCellFold ? "0" : "10px")};
        gap: 10px;
        border: none;
        background: none;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #f5f5f7;
        }
    }

    transition: height 300ms ease, min-height 300ms ease,
        background-color 300ms ease;
`;

export const CalendarCellContainerSelectedBorder = styled.div<{
    isShow: boolean;
}>`
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    top: 0;
    left: 0;
    border: 2px solid #fa54fa;
    border-radius: 6px;

    visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    transition: all 300ms ease;
    pointer-events: none;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 3px);
        height: calc(100% - 3px);
        border: 1.5px solid #fa54fa;
    }
`;

export const CalendarCellHeader = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        justify-content: center;
    }
`;

export const CalendarCellHeaderDate = styled.div<{
    fontColor: string;
    isSelectedDate: boolean;
    isToday: boolean;
}>`
    color: ${({ fontColor }) => fontColor};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    display: flex;

    @media ${(props) => props.theme.mobileL} {
        font-size: 15px;
        font-weight: ${({ isToday, isSelectedDate }) =>
            isSelectedDate ? 700 : isToday ? 900 : 400};
        ${({ isToday, isSelectedDate }) =>
            isSelectedDate ? `color: #FA54FA` : isToday ? `color: #000` : ``}
    }
    transition: all 300ms ease;
`;

export const CalendarCellHeaderMonth = styled.span`
    display: block;
    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const CalendarCellHeaderTodayBadge = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    border-radius: 5px;
    padding: 4px;
    background: #555;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 12px */

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const CalendarCellBody = styled.div`
    height: 100%;
`;

type Props = {
    children: ReactElement;
    selectedDate: Date;
    setSelectedDate: (value: Date) => void;
    displayingDate: Date;
} & RenderCalendarCellParamType;

const CalendarCell = (props: Props): ReactElement | null => {
    const cellYear = new Date(props.cellDate).getFullYear();
    const cellMonth = new Date(props.cellDate).getMonth();
    const cellDate = new Date(props.cellDate).getDate();
    const cellDay = format(new Date(props.cellDate), "E");

    const dateColor =
        props.cellState === "disabled"
            ? "#00000020"
            : cellDay === "Sat"
            ? "#2E8EFF"
            : cellDay === "Sun"
            ? "#FF4671"
            : "#000";

    const isSelected = props.selectedDate
        ? cellYear === props.selectedDate.getFullYear() &&
          cellMonth === props.selectedDate.getMonth() &&
          cellDate === props.selectedDate.getDate()
        : false;

    const isToday =
        cellYear === new Date().getFullYear() &&
        cellMonth === new Date().getMonth() &&
        cellDate === new Date().getDate();

    return (
        <CalendarCellContainer
            className={props.cellState}
            isCellFold={props.isCellFold}
            isShowOnlyWeek={props.isShowOnlyWeek}
            calendarLength={props.calendarLength}
            onClick={() => {
                props.setSelectedDate(props.cellDate);
            }}
        >
            {/*{(cellInfo ? "valid" : props.cellState) === "disabled" && (*/}
            {/*    <CalendarCellContainerDim />*/}
            {/*)}*/}

            <CalendarCellContainerSelectedBorder isShow={isSelected} />
            <CalendarCellHeader>
                <CalendarCellHeaderDate
                    fontColor={dateColor}
                    isSelectedDate={isSelected}
                    isToday={isToday}
                >
                    <CalendarCellHeaderMonth>
                        {cellMonth !== props.displayingDate.getMonth() &&
                            cellDate === 1 &&
                            cellMonth + 1 + ". "}
                    </CalendarCellHeaderMonth>{" "}
                    {cellDate}
                </CalendarCellHeaderDate>
                {isToday && (
                    <CalendarCellHeaderTodayBadge>
                        오늘
                    </CalendarCellHeaderTodayBadge>
                )}
            </CalendarCellHeader>
            {<CalendarCellBody>{props.children}</CalendarCellBody>}
        </CalendarCellContainer>
    );
};

export default CalendarCell;
