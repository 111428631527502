import {ReactElement} from "react";
import styled from "styled-components";

export const WhiteBoxTitleLayoutContainer = styled.div``;

export const WhiteBoxTitleLayoutTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #000000;
`;

export const WhiteBoxTitleLayoutTitleDesc = styled.div`
    color: #888;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    margin-top: 10px;
`;

export const WhiteBoxTitleLayoutBodyContainer = styled.div<{ mt: string }>`
    margin-top: ${({ mt }) => mt};
`;

type Props = {
    title: string;
    desc?: string;
    children: ReactElement;
    bodyMt: string;
};

const WhiteBoxTitleLayout = (props: Props): ReactElement | null => {
    return (
        <WhiteBoxTitleLayoutContainer>
            <WhiteBoxTitleLayoutTitle>{props.title}</WhiteBoxTitleLayoutTitle>
            {props.desc && (
                <WhiteBoxTitleLayoutTitleDesc>
                    {props.desc}
                </WhiteBoxTitleLayoutTitleDesc>
            )}
            <WhiteBoxTitleLayoutBodyContainer mt={props.bodyMt}>
                {props.children}
            </WhiteBoxTitleLayoutBodyContainer>
        </WhiteBoxTitleLayoutContainer>
    );
};

export default WhiteBoxTitleLayout;

WhiteBoxTitleLayout.defaultProps = {
    bodyMt: "32px",
};
