import {
    AdInfoSalesType,
    DEFAULT_ADVERTISEMENT_INFO_SALES,
} from "./AdInfoSalesType";

export type AdInfoType = {
    ad_auto_offer_accept_bool: boolean;
    ad_id: string;
    ad_idx: string;
    ad_posting_date: number;
    ad_product_name: string;
    ad_product_sub_name: string;
    ad_sort_priority: number;
    ad_status: string;
    ad_stop_switch_bool: boolean;
    ad_thumbnail_path: string;
    advertisement_sales?: AdInfoSalesType;
    brand_id: string;
    brand_idx: string;
    brand_name: string;
    campaign_idx: string;
    campaign_type: string;
    created_at: string;
    favorite_cnt: number;
    like_bool: boolean;
    like_cnt: number;
    updated_at: string;
    user_idx: string;
};

export const DEFAULT_ADVERTISEMENT_INFO: AdInfoType = {
    ad_auto_offer_accept_bool: false,
    ad_id: "",
    ad_idx: "",
    ad_posting_date: 0,
    ad_product_name: "",
    ad_product_sub_name: "",
    ad_sort_priority: 0,
    ad_status: "",
    ad_stop_switch_bool: false,
    ad_thumbnail_path: "",
    advertisement_sales: DEFAULT_ADVERTISEMENT_INFO_SALES,
    brand_id: "",
    brand_idx: "",
    brand_name: "",
    campaign_idx: "",
    campaign_type: "",
    created_at: "",
    favorite_cnt: 0,
    like_bool: false,
    like_cnt: 0,
    updated_at: "",
    user_idx: "",
};
