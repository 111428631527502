import {
    ChipType,
    SortingItemType,
} from "../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { FilterType } from "../../../common/commonUiComponent/searchFilter/SearchFilter";
import { MarketProjectSideBarSearchFilterExceptionOnChangeHandlers } from "./MarketProjectSideBarSearchFilterExceptionOnChangeHandlers";
import {
    BRAND_ID_SOOP,
    BRAND_NAME_SOOP,
} from "../../../../constants/brand/Brand";

export const MarketProjectLeftSideBarHeaderNonLoggedInChips: ChipType[] = [
    {
        title: "최근",
        key: "recent",
        isSelected: false,
    },
];
export const MarketProjectLeftSideBarHeaderLoggedInChips: ChipType[] = [
    {
        title: "좋아요",
        key: "like",
        isSelected: false,
    },
    {
        title: "최근",
        key: "recent",
        isSelected: false,
    },
    {
        title: "내 프로젝트",
        key: "my",
        isSelected: false,
    },
];

export const MarketProjectLeftSideBarHeaderSortingList: SortingItemType[] = [
    {
        title: "추천순",
        key: "suggest",
        isSelected: true,
    },
    {
        title: "좋아요 합 높은 순",
        key: "like_cnt_desc",
        isSelected: false,
    },
    {
        title: "좋아요 합 낮은 순",
        key: "like_cnt_asc",
        isSelected: false,
    },
    {
        title: "최신순",
        key: "created_at_desc",
        isSelected: false,
    },
    {
        title: "오래된순",
        key: "created_at_asc",
        isSelected: false,
    },
];

export const MarketProjectLeftSideBarHeaderFilters: FilterType[] = [
    {
        title: "브랜드",
        key: "brand",
        isCheckbox: false,
        totalAdditionalExceptionOnChangeHandler: (isSelected, tempFilters) => {
            return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.brandFilterHandler(
                false,
                tempFilters,
            );
        },
        filterList: [
            {
                title: BRAND_NAME_SOOP,
                key: BRAND_ID_SOOP,
                isSelected: false,
                additionalExceptionOnChangeHandler: (
                    isSelected,
                    tempFilters,
                ) => {
                    return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.brandFilterHandler(
                        true,
                        tempFilters,
                    );
                },
            },
        ],
    },
    {
        title: "네트워크",
        key: "network",
        isCheckbox: true,
        totalAdditionalExceptionOnChangeHandler: (isSelected, tempFilters) => {
            return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.networkFilterHandler(
                true,
                true,
                tempFilters,
            );
        },
        filterList: [
            {
                title: "폴리곤",
                key: "Polygon",
                isSelected: false,
                additionalExceptionOnChangeHandler: (
                    isSelected,
                    tempFilters,
                ) => {
                    let isSelectedPolygon = isSelected;
                    let isSelectedInApp = false;
                    tempFilters.forEach((filter) => {
                        if (filter.key === "network") {
                            filter.filterList.filter((filterListItem) => {
                                if (
                                    filterListItem.key === "In-App" &&
                                    filterListItem.isSelected
                                ) {
                                    isSelectedInApp = true;
                                }
                            });
                        }
                    });
                    return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.networkFilterHandler(
                        isSelectedPolygon,
                        isSelectedInApp,
                        tempFilters,
                    );
                },
            },
            {
                title: "In-App",
                key: "In-App",
                isSelected: false,
                additionalExceptionOnChangeHandler: (
                    isSelected,
                    tempFilters,
                ) => {
                    let isSelectedPolygon = false;
                    let isSelectedInApp = isSelected;
                    tempFilters.forEach((filter) => {
                        if (filter.key === "network") {
                            filter.filterList.filter((filterListItem) => {
                                if (
                                    filterListItem.key === "Polygon" &&
                                    filterListItem.isSelected
                                ) {
                                    isSelectedPolygon = true;
                                }
                            });
                        }
                    });

                    return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.networkFilterHandler(
                        isSelectedPolygon,
                        isSelectedInApp,
                        tempFilters,
                    );
                },
            },
        ],
    },
    {
        title: "토큰 표준",
        isCheckbox: true,
        key: "erc",
        filterList: [
            {
                title: "ERC-721",
                key: "721",
                isSelected: false,
            },
            {
                title: "ERC-1155",
                key: "1155",
                isSelected: false,
            },
            {
                title: "기타",
                key: "0",
                isSelected: false,
            },
        ],
    },
    {
        title: "출시 여부",
        isCheckbox: true,
        key: "release_bool",
        totalAdditionalExceptionOnChangeHandler: (isSelected, tempFilters) => {
            return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.releaseBoolFilterHandler(
                true,
                true,
                tempFilters,
            );
        },

        filterList: [
            {
                title: "출시 완료",
                key: "1",
                isSelected: true,
                additionalExceptionOnChangeHandler: (
                    isSelected,
                    tempFilters,
                ) => {
                    const releaseBoolTureChecked = isSelected;
                    const releaseBoolFalseChecked =
                        tempFilters.find(
                            (filter) => filter.key === "release_bool",
                        )?.filterList[1].isSelected || false;

                    return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.releaseBoolFilterHandler(
                        releaseBoolTureChecked,
                        releaseBoolFalseChecked,
                        tempFilters,
                    );
                },
            },
            {
                title: "출시 대기",
                key: "0",
                isSelected: false,
                additionalExceptionOnChangeHandler: (
                    isSelected,
                    tempFilters,
                ) => {
                    const releaseBoolTureChecked =
                        tempFilters.find(
                            (filter) => filter.key === "release_bool",
                        )?.filterList[0].isSelected || false;

                    return MarketProjectSideBarSearchFilterExceptionOnChangeHandlers.releaseBoolFilterHandler(
                        releaseBoolTureChecked,
                        isSelected,
                        tempFilters,
                    );
                },
            },
        ],
    },
    {
        title: "재거래 가능 여부",
        isCheckbox: true,
        key: "transaction_bool",
        filterList: [
            {
                title: "재거래 가능",
                key: "1",
                isSelected: false,
            },
            {
                title: "귀속",
                key: "0",
                isSelected: false,
            },
        ],
    },
];
