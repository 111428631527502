import { ReactElement } from "react";
import styled from "styled-components";
import { FilterType } from "../../SearchFilter";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../commonStyle/CommonButtonStyle";

export const SearchFilterBottomButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-bottom: 16px;
    padding-left: 16px;
    width: calc(100% - 32px);

    @media ${(props) => props.theme.tabletM} {
        position: fixed;
        bottom: 0;
        background: white;
        margin-top: 20px;
        margin-bottom: 0;
        border-top: #ebebeb 1px solid;
        padding: 16px;
        z-index: 1;
    }
`;

export const SearchFilterBottomButton = styled.div<CommonButtonType>`
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    ${CommonButtonStyle};
    max-width: 160px;
    height: 48px;

    @media ${(props) => props.theme.tabletM} {
        width: 100%;
        max-width: none;
        text-align: center;
    }
`;

type Props = {
    close: () => void;
    setFilters: (value: FilterType[]) => void;
    tempFilters: FilterType[];
};

const SearchFilterBottomButtons = (props: Props): ReactElement | null => {
    return (
        <SearchFilterBottomButtonContainer>
            <SearchFilterBottomButton
                btnType={"filled-secondary"}
                onClick={() => {
                    props.close();
                }}
            >
                취소
            </SearchFilterBottomButton>
            <SearchFilterBottomButton
                btnType={"filled-primary"}
                onClick={() => {
                    props.setFilters(props.tempFilters);
                    props.close();
                }}
            >
                적용
            </SearchFilterBottomButton>
        </SearchFilterBottomButtonContainer>
    );
};

export default SearchFilterBottomButtons;
