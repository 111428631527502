import { ReactElement } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import Chips from "../../../commonUnitComponent/chips/Chips";
import { ChipType } from "../../../commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { NotificationListItemType } from "../../../../../query/notification/useGetNotificationListQuery";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";
import { Link } from "react-router-dom";
import { useOpenLoginConfirmModal } from "../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";

export const NotiListHeaderWrapper = styled.div``;

export const NotiListHeaderContainer = styled.div``;

export const NotiListHeaderTitleContainer = styled.div`
    display: flex;
    height: 48px;
    align-items: center;

    padding-left: 10px;
    padding-right: 10px;

    @media ${(props) => props.theme.tabletL} {
        height: 60px;
        padding: 0 6px;
    }
`;

export const NotiListHeaderTitleLeftContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const NotiListMobileBackBtn = styled.div`
    width: 40px;
    height: 40px;
    display: none;
    cursor: pointer;

    @media ${(props) => props.theme.tabletL} {
        display: block;
    } ;
`;

export const NotiListHeaderMobileBackIconImg = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
`;

export const NotiListHeaderTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    margin-left: 10px;

    @media ${(props) => props.theme.tabletL} {
        margin-left: 4px;
    }
`;

export const NotiListHeaderTitleNotiCnt = styled.div`
    color: #fa54fa;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
    margin-left: 6px;
`;
export const NotiListHeaderSettingIconLink = styled(Link)``;

export const NotiListHeaderSettingIconImg = styled.img`
    width: 32px;
    height: 32px;

    position: absolute;
    top: 18px;
    right: 18px;

    @media ${(props) => props.theme.tabletL} {
        width: 40px;
        height: 40px;
        margin: 4px;
        top: 6px;
        right: 6px;
    }
`;

export const NotiListKeywordChipsContainer = styled.div`
    margin-top: 6px;
    position: relative;
`;

type Props = {
    notiKeywordChips: ChipType[];
    setNotiKeywordChips: (value: ChipType[]) => void;
    resolve: (value: number | boolean) => void;
    notificationList: NotificationListItemType[];
};

const NotiListHeader = (props: Props): ReactElement | null => {
    const { t } = useTranslation();
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();

    const notReadNotiCount = props.notificationList.filter(
        (noti) => !noti.read_bool,
    ).length;

    return (
        <NotiListHeaderWrapper>
            <NotiListHeaderContainer>
                <NotiListHeaderTitleContainer>
                    <NotiListHeaderTitleLeftContainer>
                        <NotiListMobileBackBtn>
                            <NotiListHeaderMobileBackIconImg
                                onClick={() => {
                                    props.resolve(-1);
                                }}
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-arrow-back.png`}
                            />
                        </NotiListMobileBackBtn>
                        <NotiListHeaderTitle>{t("알림")}</NotiListHeaderTitle>
                        {notReadNotiCount !== 0 && (
                            <NotiListHeaderTitleNotiCnt>
                                {notReadNotiCount}
                            </NotiListHeaderTitleNotiCnt>
                        )}
                    </NotiListHeaderTitleLeftContainer>

                    <NotiListHeaderSettingIconLink
                        to={getIsLogin() ? "/my/notification" : {}}
                        onClick={async () => {
                            if (!getIsLogin()) {
                                await openLoginConfirmModal("/my/notification");
                            } else {
                                props.resolve(-1);
                            }
                        }}
                    >
                        <NotiListHeaderSettingIconImg
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-noti-setting.png`}
                        />
                    </NotiListHeaderSettingIconLink>
                </NotiListHeaderTitleContainer>
            </NotiListHeaderContainer>
            {getIsLogin() && (
                <NotiListKeywordChipsContainer>
                    <Chips
                        isOnlyOneSelected={true}
                        chips={props.notiKeywordChips}
                        setChips={props.setNotiKeywordChips}
                    />
                </NotiListKeywordChipsContainer>
            )}
        </NotiListHeaderWrapper>
    );
};

export default NotiListHeader;
