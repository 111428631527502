import React, { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import {
    numberToStringWithComma,
    shallowEqual,
} from "../../../../../utils/Utils";
import { ItemListItemType } from "../../../../../query/item/useItemListQuery";
import { TitleItemListItemType } from "../../../../../query/item/titleItem/useTitleItemListQuery";
import { queryClient } from "../../../../../App";
import IncludeIconButton from "../../../../common/commonUnitComponent/includeIconButton/IncludeIconButton";
import { useIsMe } from "../../../../../hooks/common/useIsMe";
import { useAtom } from "jotai";
import CollectionCommonStateAtom from "../../../../../store/commonState/CollectionCommonStateAtom";
import { useHover } from "usehooks-ts";
import WalletCollectionItemThreeDot from "./components/WalletCollectionItemThreeDot";
import { Link } from "react-router-dom";
import { useLikeUnLike } from "../../../../../hooks/common/useLikeUnLike";
import WalletCollectionItemThumbnail from "./components/WalletCollectionItemThumbnail";
import { CommonBoxHoverEffect } from "../../../../common/commonStyle/CommonStyle";
import {
    COLLECTION_CREATED_TAB,
    COLLECTION_LIKED_TAB,
    COLLECTION_OWNED_TAB,
    COLLECTION_PRIVATE_TAB,
} from "../walletCollectionHub/WalletCollectionHub";
import Shimmer from "../../../../common/commonUiComponent/shimmer/Shimmer";

export const WalletCollectionItemLink = styled(Link)``;

export const WalletCollectionItemWrapper = styled.div<{
    type: string;
    idx: number;
}>`
    background: #f5f5f7;
    border-radius: 24px;
    cursor: pointer;
    margin-right: 0;
    overflow: visible;
    position: relative;
    max-width: 100%;
    width: 100%;
    z-index: ${({ idx }) => 10000 - idx};

    @media ${(props) => props.theme.mobileM} {
        border-radius: 16px;
    }

    ${CommonBoxHoverEffect};

    @media (hover: hover) and (pointer: fine) {
        &:hover {
        }
    }
`;

export const WalletCollectionItemContainer = styled.div`
    padding: 14px;
    position: relative;
    display: flex;
    flex-direction: column;

    @media ${(props) => props.theme.mobileL} {
        padding: 0;
    }
`;

export const WalletCollectionItemInfoContainer = styled.div<{ type: string }>`
    position: relative;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${(props) => props.theme.mobileL} {
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
        margin-top: 10px;
    }
`;

export const WalletCollectionItemInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const WalletCollectionItemProjectContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const WalletCollectionItemProjectName = styled.div`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: calc(100% - 40px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        font-size: 13px;
    }
`;

export const WalletCollectionItemInfoItemName = styled.div`
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    margin-top: 6px;
    line-height: 1.4;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        font-size: 15px;
    }
`;

export const WalletCollectionItemInfoPrice = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    margin-top: 8px;
    color: #fa54fa;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        font-size: 13px;
    }
`;

export const WalletCollectionItemInfoStateChipsWrapper = styled.div`
    overflow: hidden;
`;

export const WalletCollectionItemInfoStateChipsContainer = styled.div`
    display: flex;
    gap: 6px;
    margin-top: 16px;
`;

export const WalletCollectionItemInfoStateChip = styled.div`
    display: flex;
    padding: 7px 10px;
    border-radius: 20px;
    background: #e3e3e8;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    @media ${(props) => props.theme.mobileL} {
        font-size: 12px;
    }
`;

export const WalletCollectionItemInfoButtonContainer = styled.div<{
    type: string;
}>`
    display: flex;
    justify-content: ${({ type }) =>
        type === "representative" ? "flex-end" : "space-between"};
    align-items: center;
    margin-top: 4px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 6px;
    }
`;

export const WalletCollectionItemInfoSellState = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #888888;

    @media ${(props) => props.theme.mobileL} {
        font-size: 13px;
    }
`;

export function getIsTradeAvailableItem(status: string) {
    return parseInt(status) <= 2 || parseInt(status) === 7;
}

export function getItemStatusKR(itemStatus: string) {
    switch (itemStatus) {
        case "0":
            return "판매대기";
        case "1":
            return "판매중";
        case "2":
            return "귀속됨";
        case "3":
            return "출시 대기";
        case "4":
            return "검토중";
        case "5":
            return "반려";
        case "7":
            return "비공개";
        default:
            return "상태";
    }
}

export function getItemPrice(
    itemStatus: string,
    lowPrice: number,
    lastPrice: number,
    currency: string,
) {
    return itemStatus === "1"
        ? lowPrice === 0
            ? "-"
            : numberToStringWithComma(lowPrice) + ` ${currency}`
        : lastPrice === 0
        ? "-"
        : numberToStringWithComma(lastPrice) + ` ${currency}`;
}

export function getItemType(inventoryType: string) {
    return inventoryType === "1" ? "소모형" : "수집형";
}

type Props = {
    userIdx: string;
    listIdx: number;
    item: ItemListItemType | TitleItemListItemType | any; // 앞에 두개인데 value 가 달라서 any 로 열어놓긴한다
    selectedSubKey: string;
    isMobileOrderChanging?: boolean;
    bind?: any;
    renderDndTrigger?: (children: ReactElement) => ReactElement;
};

const WalletCollectionItem = (props: Props): ReactElement | null => {
    const [collectionCommonState] = useAtom(CollectionCommonStateAtom);
    const isMe = useIsMe();
    const isNormalType = props.selectedSubKey !== "representative";
    const itemContainerRef = useRef<HTMLDivElement>(null);
    const isContainerHover = useHover(itemContainerRef);

    const refetchItemListTitleItemListQuery = async () => {
        await queryClient.refetchQueries({
            queryKey: collectionCommonState.collectionItemListQueryKey,
            refetchPage: () => true,
        });
        await queryClient.refetchQueries({
            queryKey: "title-item-list-" + props.userIdx,
            refetchPage: () => true,
        });
    };

    // collection 은 따로 refetch
    const { likeUnLike } = useLikeUnLike(
        "item",
        props.item.item_idx,
        props.item.like_bool,
    );

    const [isImageLoading, setIsImageLoading] = useState(true);

    return (
        <WalletCollectionItemWrapper
            type={props.selectedSubKey}
            idx={props.listIdx}
        >
            <WalletCollectionItemLink
                draggable={false}
                to={`/market/${props.item.project_idx}/${props.item.item_idx}`}
            >
                <WalletCollectionItemContainer ref={itemContainerRef}>
                    <WalletCollectionItemThumbnail
                        {...props}
                        refetchItemListTitleItemListQuery={
                            refetchItemListTitleItemListQuery
                        }
                        isContainerHover={isContainerHover}
                        isImageLoading={isImageLoading}
                        setIsImageLoading={setIsImageLoading}
                    />
                    <WalletCollectionItemInfoContainer
                        type={props.selectedSubKey}
                    >
                        {!isImageLoading &&
                            isMe(props.userIdx) &&
                            (props.selectedSubKey === COLLECTION_OWNED_TAB ||
                                props.selectedSubKey === COLLECTION_LIKED_TAB ||
                                props.selectedSubKey ===
                                    COLLECTION_CREATED_TAB ||
                                props.selectedSubKey ===
                                    COLLECTION_PRIVATE_TAB ||
                                props.selectedSubKey === "representative") && (
                                <WalletCollectionItemThreeDot
                                    userIdx={props.userIdx}
                                    type={props.selectedSubKey}
                                    item={props.item}
                                    refetchItemListTitleItemListQuery={
                                        refetchItemListTitleItemListQuery
                                    }
                                />
                            )}
                        <WalletCollectionItemInfo>
                            <WalletCollectionItemProjectContainer>
                                <WalletCollectionItemProjectName>
                                    <Shimmer isLoading={isImageLoading}>
                                        {props.item.project_name}
                                    </Shimmer>
                                </WalletCollectionItemProjectName>
                            </WalletCollectionItemProjectContainer>
                            <WalletCollectionItemInfoItemName>
                                <Shimmer isLoading={isImageLoading}>
                                    {props.item.item_name}
                                </Shimmer>
                            </WalletCollectionItemInfoItemName>
                            {isNormalType && (
                                <WalletCollectionItemInfoPrice>
                                    <Shimmer isLoading={isImageLoading}>
                                        {getItemPrice(
                                            props.item.item_status,
                                            props.item.low_price,
                                            props.item.last_transaction_price,
                                            props.item.project_currency,
                                        )}
                                    </Shimmer>
                                </WalletCollectionItemInfoPrice>
                            )}
                        </WalletCollectionItemInfo>

                        <WalletCollectionItemInfoStateChipsWrapper>
                            <Shimmer isLoading={isImageLoading}>
                                <WalletCollectionItemInfoStateChipsContainer>
                                    <WalletCollectionItemInfoStateChip>
                                        {props.item.project_network}
                                    </WalletCollectionItemInfoStateChip>

                                    <WalletCollectionItemInfoStateChip>
                                        {getItemType(props.item.inventory_type)}
                                    </WalletCollectionItemInfoStateChip>
                                </WalletCollectionItemInfoStateChipsContainer>
                            </Shimmer>
                        </WalletCollectionItemInfoStateChipsWrapper>

                        <WalletCollectionItemInfoButtonContainer
                            type={props.selectedSubKey}
                        >
                            {isNormalType && (
                                <Shimmer
                                    isLoading={isImageLoading}
                                    width={"auto"}
                                >
                                    <WalletCollectionItemInfoSellState>
                                        {getItemStatusKR(
                                            props.item.item_status,
                                        )}
                                    </WalletCollectionItemInfoSellState>
                                </Shimmer>
                            )}

                            <IncludeIconButton
                                isActive={props.item.like_bool}
                                hasBorder={false}
                                hoverColor={"rgba(13, 13, 26, 0.06)"}
                                clickEventHandler={async () => {
                                    await likeUnLike();
                                    await queryClient.refetchQueries({
                                        queryKey:
                                            "get-user-profile-" + props.userIdx,
                                    });
                                    await refetchItemListTitleItemListQuery();
                                }}
                                activeIconColor={"#FF4671"}
                                icon={`${URL_CONSTANTS.ASSET_URL}/icon/icon-heart.svg`}
                                title={numberToStringWithComma(
                                    props.item.like_cnt,
                                )}
                                isLoading={isImageLoading}
                            />
                        </WalletCollectionItemInfoButtonContainer>
                    </WalletCollectionItemInfoContainer>
                </WalletCollectionItemContainer>
            </WalletCollectionItemLink>
        </WalletCollectionItemWrapper>
    );
};

export default React.memo(WalletCollectionItem, (prevProps, nextProps) =>
    shallowEqual(prevProps, nextProps),
);
