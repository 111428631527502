import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";
import CalendarSelectedMobileCelleKeyPrimaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCelleKeyPrimaryRow";
import CalendarSelectedMobileCellSecondaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellSecondaryRow";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";
import CalendarCellRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";

export const AdBidCalendarSelectedMobileCellBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarSelectedMobileCellBidExpired = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
    } = useGetAdDailyDateData(props);

    return myOfferInfo ? (
        <>
            <CalendarCellRow msg={"유찰"} type={"normal"} />
            <HiddenKey
                id={"AD_EXPIRED__notAdStop__dailySale__myOfferInfo__ms"}
            />
        </>
    ) : otherOfferInfo ? (
        <>
            <CalendarCellRow msg={"유찰"} type={"normal"} />
            <HiddenKey
                id={"AD_EXPIRED__notAdStop__dailySale__otherOfferInfo__ms"}
            />
        </>
    ) : myOfferSuccessInfo ? (
        <>
            <CalendarSelectedMobileCelleKeyPrimaryRow
                keyMsg={"내 광고 게재 완료"}
                valueMsg={`${numberToStringWithComma(
                    myOfferSuccessInfo.offer_price,
                )} KRW`}
                hasIcon={false}
            />
            <HiddenKey
                id={"AD_EXPIRED__notAdStop__dailySale__myOfferSuccessInfo__ms"}
            />
        </>
    ) : otherOfferSuccessInfo ? (
        <>
            <CalendarSelectedMobileCellSecondaryRow
                keyMsg={"게재 완료"}
                valueMsg={`${numberToStringWithComma(
                    otherOfferSuccessInfo.offer_price,
                )} KRW`}
            />
            <HiddenKey
                id={
                    "AD_EXPIRED__notAdStop__dailySale__otherOfferSuccessInfo__ms"
                }
            />
        </>
    ) : (
        <>
            <CalendarCellRow msg={"유찰"} type={"normal"} />
            <HiddenKey id={"AD_EXPIRED__notAdStop__notAdDailySale__else__ms"} />
        </>
    );
};

export default AdBidCalendarSelectedMobileCellBidExpired;
