import { useQuery } from "react-query";
import { NotificationApi } from "../../apis/NotificationApi";

export type NotificationStatusResultType = {
    readable_bool: boolean;
};

export const useGetNotificationStatusQuery = () => {
    const queryResult = useQuery<NotificationStatusResultType, Error>({
        queryKey: ["notification-status"],
        queryFn: () => NotificationApi.getStatus(),
        suspense: false,
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: true,
        onError: () => {
            return [];
        },
    });

    return {
        ...queryResult,
        data: queryResult.data || {
            readable_bool: false,
        },
    };
};
