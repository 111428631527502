import React, { ReactElement } from "react";
import styled from "styled-components";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import { MyAdByDateOfferListItemType } from "../../../../../../types/ad/adMy/myAd__sale/myAdByDateOfferList/MyAdByDateOfferListItemType";
import {
    AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER,
    AD_OFFER_STATUS_SUCCESS_OFFER,
} from "../../../../../../hooks/ad/staticConvertAdOfferStatus";

export const AdSaleManageOfferListModalListItemWrapper = styled.div<{
    isSelected: boolean;
}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: ${({ isSelected }) =>
        isSelected ? "1px #FA54FA solid" : "1px transparent solid"};
    background-color: ${({ isSelected }) =>
        isSelected ? "#FA54FA20" : "transparent"};

    transition: all 300ms ease;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: ${({ isSelected }) =>
                isSelected ? "#FBF2FC" : "#F5F5F7"};
        }
    }

    @media ${(props) => props.theme.mobileL} {
        height: 48px;
        width: auto;
        flex-shrink: 0;
        padding: 8px 10px;
        justify-content: center;

        background-color: ${({ isSelected }) =>
            isSelected ? "#FA54FA20" : "#F5F5F7"};
    }
`;

export const AdSaleManageOfferListModalListItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`;

export const AdSaleManageOfferListModalListItemPrice = styled.div<{
    isSelected: boolean;
}>`
    width: 100%;
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#555")};
    font-size: 14px;
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? "500" : "400")};
    line-height: 100%; /* 14px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 13px;
    }
`;

export const AdSaleManageOfferListModalListItemUserNick = styled.div`
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 11px;
    }
`;

export const AdSaleManageOfferListModalListItemBadge = styled.div`
    display: flex;
    padding: 5px 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    background: #fa54fa;
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 100% */
    position: absolute;
    top: 10px;
    right: 10px;

    @media ${(props) => props.theme.mobileL} {
        position: relative;
        top: unset;
        right: unset;
        margin-right: 8px;
    }
`;

type Props = {
    data: MyAdByDateOfferListItemType;
    openAdHistoryAddBlacklistModal: () => Promise<boolean>;
    resolve: (value: boolean | PathAfterResolveType) => void;
    selectedOfferIdx: string;
    setSelectedOfferIdx: (value: string) => void;
};

const AdSaleManageOfferListModalListItem = (
    props: Props,
): ReactElement | null => {
    const offerInfo = props.data.offerInfo;
    const isSelected = props.selectedOfferIdx === offerInfo.offer_idx;

    return (
        <AdSaleManageOfferListModalListItemWrapper
            isSelected={isSelected}
            onClick={() => {
                props.setSelectedOfferIdx(offerInfo.offer_idx);
            }}
        >
            {(offerInfo.offer_status === AD_OFFER_STATUS_SUCCESS_OFFER ||
                offerInfo.offer_status ===
                    AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER) && (
                <AdSaleManageOfferListModalListItemBadge>
                    낙찰
                </AdSaleManageOfferListModalListItemBadge>
            )}
            <AdSaleManageOfferListModalListItemContainer>
                <AdSaleManageOfferListModalListItemPrice
                    isSelected={isSelected}
                >
                    {numberToStringWithComma(offerInfo.offer_price)} KRW
                </AdSaleManageOfferListModalListItemPrice>
                <AdSaleManageOfferListModalListItemUserNick>
                    {offerInfo.user_nick}
                </AdSaleManageOfferListModalListItemUserNick>
            </AdSaleManageOfferListModalListItemContainer>
        </AdSaleManageOfferListModalListItemWrapper>
    );
};

export default AdSaleManageOfferListModalListItem;
