import { FilterType } from "../../components/common/commonUiComponent/searchFilter/SearchFilter";
import { useEffect } from "react";
import { getPageType, getSelectedFilterCheckBoxItem } from "../../utils/Utils";

import { FilterItemType } from "../../components/common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { useAdCampaignSimpleListQuery } from "../../query/ad/campaign/useAdCampaignSimpleListQuery";
import { usePrevState } from "../common/usePrevState";

export const useSetExceptionHeaderFilters = function (
    filters: FilterType[],
    setFilters: (value: FilterType[]) => void,
) {
    const pageType = getPageType(location.pathname);
    const hasBrandIdKey = filters.some((filter) => filter.key === "brand");
    const isAdBrandIdException = pageType === "ad" && hasBrandIdKey;
    const brandFilter = filters.find(
        (filter) => filter.key === "brand",
    )?.filterList;

    const brandFilterList =
        filters.find((filter) => filter.key === "brand")?.filterList || [];

    const isBrandSelected = brandFilterList.some((filter) => filter.isSelected);

    const {
        data: rawAdCampaignSimpleTypeList,
        isSuccess: isAdCampaignListSimpleTypeSuccess,
        refetch: refetchAdCampaignListSimpleType,
    } = useAdCampaignSimpleListQuery(
        {
            brand_id_array: getSelectedFilterCheckBoxItem("brand", filters),
        },
        false,
    );

    const { prevState: prevBrandFilter } = usePrevState(brandFilter);

    useEffect(() => {
        if (isAdBrandIdException) {
            if (isBrandSelected) {
                if (
                    JSON.stringify(prevBrandFilter) !==
                    JSON.stringify(brandFilter)
                ) {
                    if (isAdCampaignListSimpleTypeSuccess) {
                        addCampaignFilter();
                    } else {
                        refetchAdCampaignListSimpleType().then(() => {});
                    }
                }
            } else {
                setFilters(
                    filters.filter(
                        (filter) =>
                            filter.key === "brand" ||
                            filter.key === "ad_round_status",
                    ),
                );
            }
        }
    }, [brandFilter]);

    const addCampaignFilter = () => {
        try {
            const brandFilterList = filters.filter(
                (filter) => filter.key === "brand",
            )[0].filterList;

            const isSelectedKey = brandFilterList.find(
                (filter) => filter.isSelected,
            )?.key;

            const personalTypeCampaignList: FilterItemType[] =
                rawAdCampaignSimpleTypeList.campaignList
                    .filter(
                        (campaign: any) =>
                            campaign.campaign_type === "1" &&
                            campaign.brand_id === isSelectedKey,
                    )
                    .map((campaign: any) => {
                        return {
                            key: campaign.campaign_type,
                            title: campaign.campaign_name,
                            isSelected: false,
                        };
                    });

            setFilters([
                ...filters.filter(
                    (filter) =>
                        filter.key === "brand" ||
                        filter.key === "ad_round_status",
                ),
                {
                    title: "캠페인",
                    key: "campaign_type",
                    isCheckbox: true,
                    filterList: [
                        {
                            title: "플랫폼광고 캠페인",
                            key: "2",
                            isSelected: false,
                        },
                        ...personalTypeCampaignList,
                    ],
                },
            ]);
        } catch (e) {
            console.info("campaign filter error");
        }
    };

    useEffect(() => {
        if (isAdCampaignListSimpleTypeSuccess && isAdBrandIdException) {
            addCampaignFilter();
        }
    }, [rawAdCampaignSimpleTypeList, isAdCampaignListSimpleTypeSuccess]);
};
