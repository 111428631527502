import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import MyAdSaleRoundOfferListItem from "./components/AdSaleManageOfferListModalListItem";
import Scrollbar from "../../../../common/commonUiComponent/scrollbar/Scrollbar";
import AdHistoryAddBlacklistModal from "../../../myAdBlackList/MyAdBlackListAddModal";
import {
    PathAfterResolveType,
    useOpenModal,
} from "../../../../../hooks/modal/useOpenModal";
import ModalCloseIcon from "../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import { ModalTitle } from "../../../../common/commonUnitComponent/modal/ModalStyle";
import {
    MyAdByDateOfferListPages,
    useGetMyAdByDateOfferListQuery,
} from "../../../../../query/my/myAd/useGetMyAdByDateOfferListQuery";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";
import AdSaleManageOfferListModalNoList from "./components/AdSaleManageOfferListModalNoList";
import AdSaleManageOfferListModalPreview from "./components/adSaleManageOfferListModalPreview/AdSaleManageOfferListModalPreview";
import { format } from "date-fns";
import { MyAdByDateOfferListItemType } from "../../../../../types/ad/adMy/myAd__sale/myAdByDateOfferList/MyAdByDateOfferListItemType";
import { AdDailyListItemStatusCodeType } from "../../../../../types/ad/adDaily/AdDailyListItemType";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../common/commonUiComponent/scrollbar/ScrollbarType";

export const AdSaleRoundOfferListModalContainer = styled.div`
    width: 552px;
    padding: 28px 24px 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        padding: 0 16px 16px 16px;
        width: calc(100% - 32px);
    }
`;

export const AdSaleManageOfferListModalWrapper = styled.div`
    width: 100%;
`;

export const AdSaleManageOfferListModalContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 24px;

    @media ${(props) => props.theme.mobileL} {
        flex-direction: column;
        gap: 0;
    }
`;

export const AdSaleManageOfferListModalOfferListWrapper = styled.div`
    width: 208px;
    margin-top: 32px;
    height: 500px;
    position: relative;
    overflow: visible;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% + 32px);
        margin-left: -16px;
        height: 48px;
        overflow: hidden;
    }
`;

export const AdSaleManageOfferListModalOfferListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-bottom: 24px;

    @media ${(props) => props.theme.mobileL} {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 0;
        flex-direction: row;
    }
`;

export const AdSaleManageOfferListModalTemplatePreviewContainer = styled.div`
    width: calc(100% - 234px);
    margin-top: 32px;
    position: relative;
    overflow: visible;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 16px;
        width: 100%;
    }
`;

type Props = {
    adIdx: string;
    adSalesDate: Date;
    adDailyStatus: AdDailyListItemStatusCodeType;
    hasOfferSuccess: boolean;
    resolve: (value: boolean | PathAfterResolveType) => void;
};

const AdSaleManageOfferListModal = (props: Props): ReactElement | null => {
    const { openModal } = useOpenModal();
    const openAdHistoryAddBlacklistModal = async () => {
        return await openModal(
            "ad-sell-history-add-blacklist-modal",
            "bottomSheet",
            (resolve) => {
                return <AdHistoryAddBlacklistModal resolve={resolve} />;
            },
        );
    };

    const {
        data: rawMyAdSaleRoundOfferList,
        refetch: refetchMyAdSaleRoundOfferList,
        isLoading: isMyAdSaleRoundOfferListLoading,
        isSuccess: isMyAdSaleRoundOfferListSuccess,
        error: errorMyAdSaleRoundOfferList,
    } = useGetMyAdByDateOfferListQuery(props.adIdx, {
        limit: 50,
        page_no: 1,
        ad_sales_date_from: format(props.adSalesDate, "yyyy-MM-dd"),
        ad_sales_date_to: format(props.adSalesDate, "yyyy-MM-dd"),
        offer_status: props.hasOfferSuccess ? "20, 21, 3" : "0, 20, 21",
        sort_type: "created_at_desc",
        offer_invalid_status: props.hasOfferSuccess ? "0, 4" : "0",
    });
    const myAdSaleRoundOfferList = MyAdByDateOfferListPages(
        rawMyAdSaleRoundOfferList.pages,
    );

    const [selectedOfferIdx, setSelectedOfferIdx] = useState("0");

    useEffect(() => {
        if (
            isMyAdSaleRoundOfferListSuccess &&
            myAdSaleRoundOfferList.length > 0
        ) {
            setSelectedOfferIdx(myAdSaleRoundOfferList[0].offerInfo.offer_idx);
        }
    }, [isMyAdSaleRoundOfferListSuccess, rawMyAdSaleRoundOfferList]);

    return (
        <AdSaleRoundOfferListModalContainer>
            <ModalCloseIcon resolve={props.resolve} />
            <ModalTitle>광고 템플릿 보기</ModalTitle>
            <AdSaleManageOfferListModalWrapper>
                <ApiResBoundary
                    isLoading={isMyAdSaleRoundOfferListLoading}
                    error={errorMyAdSaleRoundOfferList}
                    isNoData={myAdSaleRoundOfferList.length === 0}
                    noDataFallback={
                        <AdSaleManageOfferListModalNoList
                            resolve={props.resolve}
                        />
                    }
                >
                    <AdSaleManageOfferListModalContainer>
                        <AdSaleManageOfferListModalOfferListWrapper>
                            <Scrollbar
                                type={SCROLL_BAR_NORMAL_TYPE}
                                id={
                                    "ad-sale-manage-offer-list-modal-offer-list"
                                }
                                isThin={true}
                            >
                                <AdSaleManageOfferListModalOfferListContainer>
                                    {myAdSaleRoundOfferList.map(
                                        (
                                            AdOfferHistoryItem: MyAdByDateOfferListItemType,
                                            idx: number,
                                        ) => {
                                            return (
                                                <MyAdSaleRoundOfferListItem
                                                    key={idx}
                                                    data={AdOfferHistoryItem}
                                                    resolve={props.resolve}
                                                    openAdHistoryAddBlacklistModal={
                                                        openAdHistoryAddBlacklistModal
                                                    }
                                                    selectedOfferIdx={
                                                        selectedOfferIdx
                                                    }
                                                    setSelectedOfferIdx={
                                                        setSelectedOfferIdx
                                                    }
                                                />
                                            );
                                        },
                                    )}
                                </AdSaleManageOfferListModalOfferListContainer>
                            </Scrollbar>
                        </AdSaleManageOfferListModalOfferListWrapper>
                        <AdSaleManageOfferListModalTemplatePreviewContainer>
                            <AdSaleManageOfferListModalPreview
                                {...props}
                                selectedOfferIdx={selectedOfferIdx}
                            />
                        </AdSaleManageOfferListModalTemplatePreviewContainer>
                    </AdSaleManageOfferListModalContainer>
                </ApiResBoundary>
            </AdSaleManageOfferListModalWrapper>
        </AdSaleRoundOfferListModalContainer>
    );
};

export default AdSaleManageOfferListModal;
