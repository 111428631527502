import { CommonErrorModalInfoType } from "../../hooks/modal/openModal/useOpenCommonErrorModal";

export function getApiErrorMessage(error: any): CommonErrorModalInfoType {
    try {
        if ("response" in error) {
            if (
                error.response.data.data.errorCode
                    .toLowerCase()
                    .indexOf("banword") !== -1
            ) {
                return {
                    title: error.response.data.message,
                    banwords: error.response.data.data.description,
                };
            }

            switch (error.response.data.data.errorCode) {
                default:
                    return {
                        title: error.response.data.message,
                    };
            }
        } else {
            return {
                title: error.message,
            };
        }
    } catch (err) {
        return {
            title: JSON.stringify(err),
        };
    }
}
