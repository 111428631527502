import { ReactElement } from "react";
import styled from "styled-components";
import CalendarSelectedMobileCellRow from "./CalendarSelectedMobileCellRow";

export const CalendarCellMobileAllPrimaryRowContainer = styled.div``;

type Props = {
    keyMsg: string;
    valueMsg: string;
    badgeMsg?: string;
};

const CalendarSelectedMobileCellAllPrimaryRow = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarSelectedMobileCellRow
            {...props}
            keyStyle={{
                color: "#FA54FA",
            }}
            valueStyle={{
                color: "#FA54FA",
                fontWeight: 500,
            }}
        />
    );
};

export default CalendarSelectedMobileCellAllPrimaryRow;
