import { ReactElement } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../../../utils/Utils";

export const AdRegisterAdjustDaySalePriceResultBoxWrapper = styled.div`
    margin-top: 14px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #999;
`;

export const AdRegisterAdjustDaySalePriceResultBoxTitle = styled.div`
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
`;

export const AdRegisterAdjustDaySalePriceResultBoxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const AdRegisterAdjustDaySalePriceResultBoxRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const AdRegisterAdjustDaySalePriceResultBoxRowKey = styled.div`
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustDaySalePriceResultBoxRowValue = styled.div`
    display: flex;
    align-items: baseline;
    gap: 6px;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustDaySalePriceResultBoxRowBoldValue = styled.div`
    color: #fa54fa;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
`;

export const AdRegisterAdjustDaySalePriceResultBoxRowValueUnit = styled.div`
    color: #888;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustDaySalePriceResultBoxRowUnderBar = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
`;

type Props = {
    price: number;
    rate: number;
};

const AdRegisterAdjustDaySalePriceResultBox = (
    props: Props,
): ReactElement | null => {
    const price = props.price || 0;

    return (
        <AdRegisterAdjustDaySalePriceResultBoxWrapper>
            <AdRegisterAdjustDaySalePriceResultBoxTitle>
                할인 적용 가격
            </AdRegisterAdjustDaySalePriceResultBoxTitle>
            <AdRegisterAdjustDaySalePriceResultBoxContainer>
                <AdRegisterAdjustDaySalePriceResultBoxRow>
                    <AdRegisterAdjustDaySalePriceResultBoxRowKey>
                        할인 판매가
                    </AdRegisterAdjustDaySalePriceResultBoxRowKey>
                    <AdRegisterAdjustDaySalePriceResultBoxRowValue>
                        {numberToStringWithComma(price)}
                        <AdRegisterAdjustDaySalePriceResultBoxRowValueUnit>
                            KRW
                        </AdRegisterAdjustDaySalePriceResultBoxRowValueUnit>
                    </AdRegisterAdjustDaySalePriceResultBoxRowValue>
                </AdRegisterAdjustDaySalePriceResultBoxRow>
                <AdRegisterAdjustDaySalePriceResultBoxRow>
                    <AdRegisterAdjustDaySalePriceResultBoxRowKey>
                        할인율
                    </AdRegisterAdjustDaySalePriceResultBoxRowKey>
                    <AdRegisterAdjustDaySalePriceResultBoxRowValue>
                        {props.rate || 0}
                        <AdRegisterAdjustDaySalePriceResultBoxRowValueUnit>
                            %
                        </AdRegisterAdjustDaySalePriceResultBoxRowValueUnit>
                    </AdRegisterAdjustDaySalePriceResultBoxRowValue>
                </AdRegisterAdjustDaySalePriceResultBoxRow>
                <AdRegisterAdjustDaySalePriceResultBoxRowUnderBar />
                <AdRegisterAdjustDaySalePriceResultBoxRow>
                    <AdRegisterAdjustDaySalePriceResultBoxRowKey>
                        VAT 포함
                    </AdRegisterAdjustDaySalePriceResultBoxRowKey>
                    <AdRegisterAdjustDaySalePriceResultBoxRowValue>
                        <AdRegisterAdjustDaySalePriceResultBoxRowBoldValue>
                            {numberToStringWithComma(price * 1.1)}
                        </AdRegisterAdjustDaySalePriceResultBoxRowBoldValue>
                        <AdRegisterAdjustDaySalePriceResultBoxRowValueUnit>
                            KRW
                        </AdRegisterAdjustDaySalePriceResultBoxRowValueUnit>
                    </AdRegisterAdjustDaySalePriceResultBoxRowValue>
                </AdRegisterAdjustDaySalePriceResultBoxRow>
            </AdRegisterAdjustDaySalePriceResultBoxContainer>
        </AdRegisterAdjustDaySalePriceResultBoxWrapper>
    );
};

export default AdRegisterAdjustDaySalePriceResultBox;
