import { RefObject, useEffect } from "react";
import { useLocation, useNavigationType, useParams } from "react-router-dom";
import { usePrevious } from "../../../../../../hooks/common/usePrevious";
import {
    AD_BID_PAGE_REG_EXP,
    INTRO_PAGE_REG_EXP,
    ITEM_PAGE_REG_EXP,
    POINT_PAGE_REG_EXP,
    PROJECT_PAGE_REG_EXP,
} from "../../../../../../constants/PageRegExp";
import { getSessionStorageItem } from "../../../../../../utils/Utils";
import { useSetAtom } from "jotai/index";
import isScrollUpAtom from "../../../../../../store/scroll/isScrollUpAtom";
import isFullScrollOverHasLnbInSubHeaderAtom from "../../../../../../store/scroll/isFullScrollOverHasLnbInSubHeaderAtom";
import isFullScrollOverNoLnbInSubHeaderAtom from "../../../../../../store/scroll/isFullScrollOverNoLnbInSubHeaderAtom";

export function useSetLocationChangeHook(
    windowRef: RefObject<HTMLElement>,
    wrapperRef: RefObject<HTMLElement>,
    setScrollTop: (value: number) => void,
    isHide: boolean,
    isRootType: boolean,
    isDoubleSideBarLayoutContentsType: boolean,
    isDoubleSideBarLayoutLeftSideBarType: boolean,
    id: string,
    isSaveScrollTop?: boolean,
) {
    const params = useParams();
    const prevParams = usePrevious(params);
    const location = useLocation();
    const navigationType = useNavigationType();
    const setIsScrollUp = useSetAtom(isScrollUpAtom);
    const setIsFullScrollOverHasLnbInSubHeader = useSetAtom(
        isFullScrollOverHasLnbInSubHeaderAtom,
    );
    const setIsFullScrollOverNoLnbInSubHeader = useSetAtom(
        isFullScrollOverNoLnbInSubHeaderAtom,
    );

    const isAdBidPage = AD_BID_PAGE_REG_EXP.test(location.pathname);
    const isPointPage = POINT_PAGE_REG_EXP.test(location.pathname);
    const isProjectPage = PROJECT_PAGE_REG_EXP.test(location.pathname);
    const isItemPage = ITEM_PAGE_REG_EXP.test(location.pathname);
    const isIntroPage = INTRO_PAGE_REG_EXP.test(location.pathname);

    const isHideByPathname =
        ((isAdBidPage ||
            isPointPage ||
            isProjectPage ||
            isItemPage ||
            isIntroPage) &&
            isRootType) ||
        isHide; // 전체 스크롤 숨김

    const init = () => {
        if (windowRef.current?.scrollTop) {
            windowRef.current.scrollTop = 0;
        }
        setIsScrollUp(false);
        setIsFullScrollOverHasLnbInSubHeader(false);
        setIsFullScrollOverNoLnbInSubHeader(false);
        setScrollTop(0);
    };

    // pathname 변경시 핸들링
    useEffect(() => {
        // 뒤로가기 / 앞으로가기 인 경우
        if (
            navigationType === "POP" &&
            typeof isSaveScrollTop !== "undefined" &&
            isSaveScrollTop
        ) {
            new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (windowRef.current && wrapperRef.current) {
                        const scrollTopInSS =
                            getSessionStorageItem<number>(
                                `path-${location.pathname}-id-${id}`,
                            ) || 0;

                        if (scrollTopInSS <= wrapperRef.current?.offsetHeight) {
                            windowRef.current.scrollTop =
                                getSessionStorageItem<number>(
                                    `path-${location.pathname}-id-${id}`,
                                ) || 0;
                            clearTimeout(interval);
                            resolve(1);
                        }
                    }
                }, 300);
            });
        }
        // 일반 페이지 이동인 경우
        else {
            // 위치 변경이되도 left side bar 에 있는 스크롤은 고정 시킴
            if (
                (isPointPage || isItemPage || isProjectPage || isAdBidPage) &&
                isDoubleSideBarLayoutLeftSideBarType
            ) {
                return;
            }
            // 광고입찰 페이지 중앙 컨텐츠일 경우 날짜 변경시에는 유지 광고 변경시에는 초기화
            else if (isAdBidPage && isDoubleSideBarLayoutContentsType) {
                const adIdx = params.ad_id ? params.ad_id : "0";
                const prevAdIdx = prevParams?.ad_id ? prevParams.ad_id : "0";

                if (prevAdIdx === adIdx) {
                    return;
                } else {
                    init();
                }
            } else {
                init();
            }
        }
    }, [location.pathname]);

    return {
        isHideByPathname,
    };
}
