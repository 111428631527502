import { useAtom } from "jotai";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { Query, useMutation } from "react-query";
import { NotificationApi } from "../../apis/NotificationApi";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { queryClient } from "../../App";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";

export type ReadNotificationParamsType = {
    notification_type: string;
};

export const useReadNotificationMutation = (
    notificationIdx: string,
    params: ReadNotificationParamsType,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => NotificationApi.readNoti(notificationIdx, params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                // pushSnackBarListItem(
                //     {
                //         title: "",
                //         desc: "알림을 정상적으로 읽으셨습니다.",
                //         iconUrl:
                //             URL_CONSTANTS.ASSET_URL +
                //             "/icon/icon-checked-in-circle.svg",
                //         confirmBtnTitle: "",
                //     },
                // );

                await queryClient.refetchQueries({
                    predicate: (query: Query) =>
                        query.queryKey.includes("get-notification-list"),
                });
            },
        },
    );
};
