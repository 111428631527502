import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import MarketProjectTitleBadges from "./components/marketProjectTitleBadges/MarketProjectTitleBadges";
import MarketProjectTitleSubInfo from "./components/MarketProjectTitleSubInfo";
import MarketProjectTitleDetailInfo from "./components/MarketProjectTitleDetailInfo";
import MarketProjectTitleDesc from "./components/MarketProjectTitleDesc";
import MarketProjectTitleThreeDotBtn from "./components/MarketProjectTitleThreeDotBtn";
import { ProjectDetailDataType } from "../../../../../query/project/useProjectDataQuery";
import Shimmer from "../../../../common/commonUiComponent/shimmer/Shimmer";
import SquareImg from "../../../../common/commonUiComponent/squqreImg/SquareImg";
import { onErrorImg } from "../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";

export const MarketProjectTitleContainer = styled.div`
    position: relative;
    display: flex;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const MarketProjectTitleThumbnailContainer = styled.div`
    margin-right: 40px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgb(245, 245, 247);

    @media ${(props) => props.theme.mobileL} {
        width: 60px;
        height: 60px;
    }

    @media ${(props) => props.theme.mobileL} {
        margin-right: 14px;
    }
`;

export const MarketProjectTitleInfoContainer = styled.div`
    width: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const MarketProjectTitleLogo = styled.img`
    height: 20px;
    width: 68.4px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 4px;
        height: 14px;
        width: 47.9px;
    }
`;

export const MarketProjectTitleText = styled.div`
    font-weight: 700;
    font-size: 32px;
    height: 45px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #000000;

    @media ${(props) => props.theme.mobileL} {
        height: 32px;
        font-size: 22px;
        margin-top: 0;
    }
`;

export const MarketProjectTitleHeartContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #888888;
    display: inline-flex;
    align-items: center;
`;

export const MarketProjectTitleHeartIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const MarketProjectTitleCreatorContainer = styled.div`
    padding: 9px 10px;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: inline-flex;
    align-items: center;
    color: #888888;
    cursor: pointer;

    transition: background 300ms ease;
    background: transparent;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f5f5f5;
        }
    }
`;

export const MarketProjectTitleCreator = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #222222;
    margin-left: 4px;
    padding-bottom: 1px;
`;

export const MarketProjectBadgeContainer = styled.div`
    position: absolute;
    top: -10px;
    right: 0;

    @media ${(props) => props.theme.mobileL} {
        position: static;
    }
`;

type Props = {
    projectData: ProjectDetailDataType;
};

const MarketProjectTitle = (props: Props): ReactElement | null => {
    const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);
    return (
        <MarketProjectTitleContainer>
            {/*쓰리닷*/}
            <MarketProjectTitleThreeDotBtn projectData={props.projectData} />

            {/*썸네일*/}
            <MarketProjectTitleThumbnailContainer>
                <Shimmer isLoading={isThumbnailLoading}>
                    <SquareImg
                        setIsImageLoading={setIsThumbnailLoading}
                        src={props.projectData.project_thumbnail_full_path}
                    />
                </Shimmer>
            </MarketProjectTitleThumbnailContainer>

            {/*썸네일* 우측 영역 */}
            <MarketProjectTitleInfoContainer>
                {/*뱃지 계열*/}
                <MarketProjectBadgeContainer>
                    <MarketProjectTitleBadges data={props.projectData} />
                </MarketProjectBadgeContainer>

                {/*로고*/}
                <MarketProjectTitleLogo
                    src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${props.projectData.brand_id}.svg`}
                    onError={onErrorImg}
                />

                {/*프로젝트명*/}
                <MarketProjectTitleText>
                    {props.projectData.project_name}
                </MarketProjectTitleText>

                {/*subInfo - 크리에이터명 / 링크복사 / 좋아요 수 */}
                <MarketProjectTitleSubInfo projectData={props.projectData} />

                {/*detailInfo - 아이템 / 카테고리 / 보유자 / 누적 거래량 */}
                <MarketProjectTitleDetailInfo projectData={props.projectData} />

                {/* 소개(더보기) */}
                <MarketProjectTitleDesc projectData={props.projectData} />
            </MarketProjectTitleInfoContainer>
        </MarketProjectTitleContainer>
    );
};

export default MarketProjectTitle;
