import { useInfiniteQuery } from "react-query";
import { AdMyApi } from "../../../apis/adApi/AdMyApi";
import { BrandInfoType } from "../../../types/brand/BrandInfoType";
import { CreatorInfoType } from "../../../types/creator/CreatorInfoType";
import {
    AdDailyListItemOtherOfferSuccessInfoType,
    AdDailyListItemType,
} from "../../../types/ad/adDaily/AdDailyListItemType";
import { AdInfoType } from "../../../types/ad/advertisementInfo/AdInfoType";
import { CampaignInfoType } from "../../../types/ad/camapaignInfo/CampaignInfoType";
import { AD_SALE_ANALYSIS } from "../../../components/ad/adManageNew/_adManageLayout/AdManageTableContentsLayout";

export type MyAdAnalysisListApiResultType = {
    buyAnalysisList: MyAdAnalysisListItemType[];
    sellAnalysisList: MyAdAnalysisListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type MyAdAnalysisListItemType = {
    total_appearance_in_hour: number;
    total_click_count: number;
    total_impression_count: number;
    advertisementDailyInfo: AdDailyListItemType;
    advertisementInfo: AdInfoType;
    brandInfo: BrandInfoType;
    creatorInfo: CreatorInfoType;
    campaignInfo: CampaignInfoType;
    advertisement_space: MyAdAnalysisAdSpaceType[];
    otherOfferSuccessInfo?: AdDailyListItemOtherOfferSuccessInfoType;
};

export type MyAdAnalysisAdSpaceType = {
    ad_round_idx: string;
    ad_round_space_idx: string;
    created_at: string;
    impression_cnt: number;
    presv_campaign_space_id: string;
    presv_campaign_space_name: string;
    presv_campaign_space_no: number;
    presv_campaign_space_preview_background_path: string;
    total_click_cnt: number;
    updated_at: string;
};

export type MyAdAnalysisCreatorInfoType = {
    creator_description: string;
    creator_id: string;
    creator_idx: string;
    creator_img_full_path: string;
    creator_name: string;
    creator_type: string;
    user_influencer_bool: boolean;
};

export type MyAdSellAnalysisListParams = {
    limit: number;
    page_no: number;
    ad_sales_date_from: string;
    ad_sales_date_to: string;
    sort?: string;
    order?: string;
};

export const useGetMyAdAnalysisListQuery = (
    type: string,
    params: MyAdSellAnalysisListParams,
) => {
    const queryResult = useInfiniteQuery<MyAdAnalysisListApiResultType, Error>({
        queryKey: ["my-ad-" + type + JSON.stringify(params)],
        queryFn: ({ pageParam = 1 }) =>
            AdMyApi.getMyAdAnalysisList(type, {
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: MyAdAnalysisListApiResultType[] = [
        {
            buyAnalysisList: [],
            sellAnalysisList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadMyAdAnalysisList(
    type: string,
    pages: MyAdAnalysisListApiResultType[],
) {
    return pages
        .map((result) =>
            type === AD_SALE_ANALYSIS
                ? result.sellAnalysisList
                : result.buyAnalysisList,
        )
        .reduce((prev, next) => prev.concat(next));
}
