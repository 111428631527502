import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "jotai";
import "./index.css";
import PACKAGE from "../package.json";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { HelmetProvider } from "react-helmet-async";
import { registerPwaServiceWorker } from "./serviceWorkerRegistration";

console.info(
    `${PACKAGE.name.toUpperCase()} :: ${
        PACKAGE.version
    } :: ${process.env.NODE_ENV.toUpperCase()}`,
);

window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
    event: "appInfo",
    appName: PACKAGE.name.toUpperCase(),
    appVersion: PACKAGE.version,
    appEnv: process.env.NODE_ENV.toUpperCase(),
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <HelmetProvider>
        <Provider>
            <App />
        </Provider>
    </HelmetProvider>,
);

try {
    registerPwaServiceWorker(
        process.env.NODE_ENV === "development" ||
            process.env.NODE_ENV === "stage",
    );
} catch (e) {
    console.info(e);
}
