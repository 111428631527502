import { ReactElement } from "react";
import styled from "styled-components";

export const FourWayLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: -46px;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 46px;
        gap: 24px;
    }
`;

export const TopContentsContainer = styled.div``;

export const CenterContentsContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;

    @media ${(props) => props.theme.tabletL} {
        flex-direction: column;
        gap: 40px;
    }
`;

export const CenterLeftStickyContentsContainer = styled.div`
    position: sticky;
    top: 48px;
    width: calc(50% - 20px);
    margin-right: 20px;
    height: 100%;

    @media ${(props) => props.theme.PCM} {
        width: calc(50% - 16px);
        margin-right: 16px;
        top: 22px;
    }

    @media ${(props) => props.theme.tabletL} {
        position: relative;
        top: 0;
        width: 100%;
        height: 100%;
        margin-right: 0;
    }
`;

export const CenterRightScrollableContentsContainer = styled.div`
    width: calc(50% - 20px);
    margin-left: 20px;

    @media ${(props) => props.theme.PCM} {
        width: calc(50% - 16px);
        margin-left: 16px;
    }

    @media ${(props) => props.theme.tabletL} {
        width: 100%;
        margin-left: 0;
    }
`;

export const BottomContentsContainer = styled.div``;

type Props = {
    renderTopComponent: () => ReactElement;
    renderStickyComponent: () => ReactElement;
    renderScrollableComponent: () => ReactElement;
    renderBottomComponent: () => ReactElement;
};

const FourWayLayout = (props: Props): ReactElement | null => {
    return (
        <FourWayLayoutWrapper>
            <TopContentsContainer>
                {props.renderTopComponent()}
            </TopContentsContainer>

            <CenterContentsContainer>
                <CenterLeftStickyContentsContainer>
                    {props.renderStickyComponent()}
                </CenterLeftStickyContentsContainer>

                <CenterRightScrollableContentsContainer>
                    {props.renderScrollableComponent()}
                </CenterRightScrollableContentsContainer>
            </CenterContentsContainer>

            <BottomContentsContainer>
                {props.renderBottomComponent()}
            </BottomContentsContainer>
        </FourWayLayoutWrapper>
    );
};

export default FourWayLayout;
