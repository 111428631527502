import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";

export const UserProfileModalCommonRowContainer = styled.div<{
    $isDownSizingMobile: boolean;
}>`
    display: flex;
    padding: 0 10px;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    background: white;
    cursor: pointer;

    transition: all 300ms ease;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 19, 26, 0.05);
        }
    }

    @media ${(props) => props.theme.tabletL} {
        ${({ $isDownSizingMobile }) => {
            if ($isDownSizingMobile) {
                return `height: 48px;
                        padding: 0 14px;
                        width: calc(50% - 28px);
                
                        &:hover {
                            background: white;
                        }`;
            }
        }}
`;

export const UserProfileModalCommonRowLeftContainer = styled.div<{
    isDownSizingMobile: boolean;
}>`
    display: flex;
    gap: 16px;
    align-items: center;

    @media ${(props) => props.theme.tabletL} {
        ${({ isDownSizingMobile }) => {
            if (isDownSizingMobile) {
                return `gap: 10px;`;
            }
        }}
    }
`;

export const UserProfileModalCommonRowLeftIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const UserProfileModalCommonRowLeftTitle = styled.div<{
    isDownSizingMobile: boolean;
}>`
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 100% */

    @media ${(props) => props.theme.tabletL} {
        ${({ isDownSizingMobile }) => {
            if (isDownSizingMobile) {
                return `font-size: 14px;`;
            }
        }}
    }
`;

export const UserProfileModalCommonRowRightContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const UserProfileModalCommonRowRightIcon = styled.img<{
    isDownSizingMobile: boolean;
}>`
    width: 24px;
    height: 24px;

    @media ${(props) => props.theme.tabletL} {
        ${({ isDownSizingMobile }) => {
            if (isDownSizingMobile) {
                return `display: none;`;
            }
        }}
    }
`;

type Props = {
    title: string;
    path?: string;
    isNewTab: boolean;
    onClickHandler?: () => Promise<void>;
    leftIconPath: string;
    rightIconPath?: string;
    isDownSizingMobile: boolean;
    resolve?: (value: boolean | number | PathAfterResolveType) => void;
};

const UserProfileModalCommonRow = (props: Props): ReactElement | null => {
    return (
        <UserProfileModalCommonRowContainer
            onClick={async () => {
                if (props.resolve) {
                    props.resolve({
                        pathAfterResolve: props.path || "",
                    });
                }
            }}
            $isDownSizingMobile={props.isDownSizingMobile}
        >
            <UserProfileModalCommonRowLeftContainer
                isDownSizingMobile={props.isDownSizingMobile}
            >
                <UserProfileModalCommonRowLeftIcon src={props.leftIconPath} />
                <UserProfileModalCommonRowLeftTitle
                    isDownSizingMobile={props.isDownSizingMobile}
                >
                    {props.title}
                </UserProfileModalCommonRowLeftTitle>
            </UserProfileModalCommonRowLeftContainer>
            <UserProfileModalCommonRowRightContainer>
                <UserProfileModalCommonRowRightIcon
                    isDownSizingMobile={props.isDownSizingMobile}
                    src={
                        props.rightIconPath
                            ? props.rightIconPath
                            : `${URL_CONSTANTS.ASSET_URL}/icon/${
                                  props.isNewTab
                                      ? "icon-go-out"
                                      : "icon-arrow-right-square"
                              }.svg`
                    }
                />
            </UserProfileModalCommonRowRightContainer>
        </UserProfileModalCommonRowContainer>
    );
};

export default UserProfileModalCommonRow;
