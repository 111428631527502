import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";

export const OrderTypeDropDownContainer = styled.div`
    position: relative;
`;

export const OrderTypeDropDownBtn = styled.div`
    padding: 12px 14px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #333333;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
`;
export const OrderTypeDropDownButtonValue = styled.div`
    width: 48px;
`;

export const OrderTypeDropDownButtonIcon = styled.img`
    width: 12px;
    height: 7px;
    margin-left: 27px;
`;
export const OrderTypeDropDownLayer = styled.div<{
    isOpen: boolean;
}>`
    position: absolute;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    width: 100%;

    max-height: ${({ isOpen }) => (isOpen ? "200px" : 0)};
    transition: max-height 300ms ease;
    overflow: hidden;
    ${(props) => props.theme.zIndexFixedSingleUI};
`;

export const OrderTypeDropDownList = styled.div`
    padding: 10px;
`;

export const OrderTypeDropDownListItem = styled.div`
    padding: 10px;
    font-size: 16px;
    line-height: 16px;
    color: #222222;
    cursor: pointer;
`;

export type OrderSellMoreItemType = {
    key: string;
    value: string;
    isSelected: boolean;
};

type Props = {
    moreItemList: OrderSellMoreItemType[];
    setMoreItemList: (value: OrderSellMoreItemType[]) => void;
};

const OrderTypeDropDownButton = (props: Props): ReactElement | null => {
    const [isOpen, setIsOpen] = useState(false);
    const moreButtonContainerRef = useRef(null);

    useOnClickOutside(moreButtonContainerRef, () => {
        setIsOpen(false);
    });

    return (
        <OrderTypeDropDownContainer ref={moreButtonContainerRef}>
            <OrderTypeDropDownBtn
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <OrderTypeDropDownButtonValue>
                    {
                        props.moreItemList.filter((item) => item.isSelected)[0]
                            .value
                    }
                </OrderTypeDropDownButtonValue>
                <OrderTypeDropDownButtonIcon
                    src={URL_CONSTANTS.ASSET_URL + "/icon/icon-more.svg"}
                />
            </OrderTypeDropDownBtn>
            <OrderTypeDropDownLayer isOpen={isOpen}>
                <OrderTypeDropDownList>
                    {props.moreItemList.map((item, idx) => {
                        return (
                            <OrderTypeDropDownListItem
                                key={idx}
                                onClick={() => {
                                    props.setMoreItemList(
                                        props.moreItemList.map(
                                            (innerItem, innerItemIdx) => {
                                                if (idx === innerItemIdx) {
                                                    return {
                                                        ...innerItem,
                                                        isSelected: true,
                                                    };
                                                } else {
                                                    return {
                                                        ...innerItem,
                                                        isSelected: false,
                                                    };
                                                }
                                            },
                                        ),
                                    );
                                    setIsOpen(false);
                                }}
                            >
                                {item.value}
                            </OrderTypeDropDownListItem>
                        );
                    })}
                </OrderTypeDropDownList>
            </OrderTypeDropDownLayer>
        </OrderTypeDropDownContainer>
    );
};

export default OrderTypeDropDownButton;
