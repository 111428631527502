import { URL_CONSTANTS } from "../../constants/UrlConstants";

import axios from "axios";
import { getAuthKey } from "../../store/auth/authKeyStore";
import { MyPointListApiResultType } from "../../query/my/myPoint/useGetMyRemainingPointListQuery";
import {
    MyPointExchangeListApiResultType,
    MyPointExchangeListParamsType,
} from "../../query/my/myPoint/useGetMyPointExchangeList";
import {
    MyMergeListApiResultType,
    ParamsType,
} from "../../query/my/myPoint/useGetMyMergeListQuery";
import { MyRpRemainingApiResultType } from "../../query/market/point/useGetMyRpRemainingQuery";
import {
    MyPointOrderListApiResultType,
    MyPointOrderListParams,
} from "../../query/my/myPoint/useGetMyPointOrderListQuery";

export const MyMarketPointApi = {
    getMyRpRemaining: async (): Promise<MyRpRemainingApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + `/my/point/rp/remaining/list`, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMyPointRemainingList: async (): Promise<MyPointListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + `/my/point/remaining/list`, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMyPointExchangeList: async (
        params: MyPointExchangeListParamsType,
    ): Promise<MyPointExchangeListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/my/point/exchange/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMyMergeList: async (
        params: ParamsType,
    ): Promise<MyMergeListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/my/exchange/list", {
                    params: {
                        ...params,
                        product_type: "2",
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMyPointOrderList: async (
        params: MyPointOrderListParams,
    ): Promise<MyPointOrderListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/my/order/point/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
