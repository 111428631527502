import { useAtom } from "jotai";
import { useMutation } from "react-query";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { AdTemplateApi } from "../../../apis/adApi/AdTemplateApi";

export type AdTemplateOrderChangeParamsType = {
    campaign_idx: string;
    template_order: AdTemplateOrderType[];
};

export type AdTemplateOrderType = {
    template_idx: string;
    order_no: number;
};

export const useAdTemplateOrderChangeMutation = (
    params: AdTemplateOrderChangeParamsType,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => AdTemplateApi.changeTemplateOrder(params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "정상적으로 순서 변경이 완료되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
