import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";

export const WalletCollectionNoItemInGroupContainer = styled.div`
    margin-top: 40px;
    height: 374px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
`;

export const WalletCollectionNoItemInGroupIcon = styled.img``;

export const WalletCollectionNoItemInGroupTextContainer = styled.div``;

export const WalletCollectionNoItemInGroupTitle = styled.div`
    color: #333;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
`;

export const WalletCollectionNoItemInGroupDesc = styled.div`
    margin-top: 10px;
    color: #999;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
`;

type Props = {};

const WalletCollectionNoItemInGroup = (props: Props): ReactElement | null => {
    return (
        <WalletCollectionNoItemInGroupContainer>
            <WalletCollectionNoItemInGroupIcon
                src={
                    URL_CONSTANTS.ASSET_URL + "/icon/icon-empty-group-item.svg"
                }
            />

            <WalletCollectionNoItemInGroupTextContainer>
                <WalletCollectionNoItemInGroupTitle>
                    이 그룹에 등록된 아이템이 없습니다.
                </WalletCollectionNoItemInGroupTitle>
                <WalletCollectionNoItemInGroupDesc>
                    전체 아이템 목록에서 <b>우측 더보기 버튼</b>을 눌러 <br />
                    아이템을 그룹으로 이동하실 수 있습니다.
                </WalletCollectionNoItemInGroupDesc>
            </WalletCollectionNoItemInGroupTextContainer>
        </WalletCollectionNoItemInGroupContainer>
    );
};

export default WalletCollectionNoItemInGroup;
