import { ReactElement, useState } from "react";
import styled from "styled-components";
import LeftSideBarHeader from "./components/LeftSideBarHeader";
import LeftSideBarBody from "./components/LeftSideBarBody";
import { FilterType } from "../../searchFilter/SearchFilter";

export const LeftSideBarContainer = styled.div``;

export type ChipType = {
    title: string;
    key: string;
    isSelected: boolean;
    isNeedLogin?: boolean;
};

export type SortingItemType = {
    title: string;
    key: string;
    isSelected: boolean;
};

export type FilterItemType = {
    title: string;
    key: string;
    isSelected: boolean;
    additionalExceptionOnChangeHandler?: (
        value: boolean,
        tempFilters: FilterType[],
    ) => FilterType[];
};

type Props = {
    title: string;
    list?: any[];
    addList?: (value?: number) => void;

    renderLeftSideBarListItem: (data?: any, params?: any) => ReactElement;

    headerChips: ChipType[];
    setHeaderChips: (value: ChipType[]) => void;
    headerSortingList: SortingItemType[];
    setHeaderSortingList: (value: SortingItemType[]) => void;
    headerFilters?: FilterType[];
    setHeaderFilters?: (value: FilterType[]) => void;

    getLeftSideBarListQuery: (value: any) => any | null;
    leftSideBarListQueryParam: any;
    recentListKey?: string;
};

const LeftSideBar = (props: Props): ReactElement | null => {
    const [headerHeight, setHeaderHeight] = useState(0); // scrollbar 에서 header height 만큼 빼줘야하기 때문에 값 가지도록 한다.

    return (
        <LeftSideBarContainer>
            <LeftSideBarHeader
                title={props.title}
                setHeaderHeight={setHeaderHeight}
                headerChips={props.headerChips}
                setHeaderChips={props.setHeaderChips}
                sortingList={props.headerSortingList}
                setSortingList={props.setHeaderSortingList}
                headerFilters={props.headerFilters}
                setHeaderFilters={props.setHeaderFilters}
            />
            <LeftSideBarBody
                title={props.title}
                headerHeight={headerHeight}
                headerChips={props.headerChips}
                renderLeftSideBarListItem={props.renderLeftSideBarListItem}
                getLeftSideBarListQuery={props.getLeftSideBarListQuery}
                leftSideBarListQueryParam={props.leftSideBarListQueryParam}
                recentListKey={props.recentListKey}
            />
        </LeftSideBarContainer>
    );
};

export default LeftSideBar;
