import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import MarketProjectItemListItem from "./components/MarketProjectItemListItem";
import { Link } from "react-router-dom";
import {
    getMarketProjectItemListFilters,
    MarketProjectItemListSortingList,
} from "./components/MarketProjectItemConstants";
import { useDebounce } from "usehooks-ts";
import { useAtom } from "jotai";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../common/commonStyle/CommonButtonStyle";
import { useOpenCommonStatusInfoModal } from "../../../../../hooks/modal/openModal/useOpenCommonStatusInfoModal";
import SearchFilter, {
    FilterType,
} from "../../../../common/commonUiComponent/searchFilter/SearchFilter";
import { MarketProjectItemListPerRowAtom } from "../../../../../store/ui/perRowAtom";
import { useIsMe } from "../../../../../hooks/common/useIsMe";
import { useSetStateOnWindowWidth } from "../../../../../hooks/common/useSetStateOnWindowWidth";
import { useProjectDataQuery } from "../../../../../query/project/useProjectDataQuery";
import {
    ItemListItemType,
    spreadItemListPages,
    useItemListQuery,
} from "../../../../../query/item/useItemListQuery";
import {
    getSelectedFilterItemStatus,
    getSelectedSortingList,
} from "../../../../../utils/Utils";
import isLeftSideBarRenderAtom from "../../../../../store/ui/isLeftSideBarRenderAtom";
import SortingButton from "../../../../common/commonUnitComponent/sortingButton/SortingButton";
import SubSearch from "../../../../common/commonUiComponent/subSearch/SubSearch";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";
import Spinner from "../../../../common/commonUnitComponent/spinner/Spinner";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import MoreButton from "../../../../common/commonUnitComponent/moreButton/MoreButton";
import { queryClient } from "../../../../../App";

export const MarketProjectItemListContainer = styled.div``;

export const MarketProjectItemListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 10px;
    flex-wrap: nowrap;
    gap: 8px 4px;

    @media ${(props) => props.theme.mobileL} {
        flex-wrap: wrap;
    }
`;

export const MarketProjectItemListLeftHeader = styled.div<{ isMe: boolean }>`
    width: 100%;
    display: flex;
    justify-content: ${({ isMe }) => (isMe ? "space-between" : "flex-end")};
`;

export const MarketProjectItemListItemAddLink = styled(Link)``;

export const MarketProjectItemListItemAddBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    font-size: 14px;
    padding: 10px 16px;
    width: auto;
    height: 34px;
`;

export const MarketProjectItemListRightHeader = styled.div`
    display: flex;
    gap: 4px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const MarketProjectItemListHeaderBtnContainer = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`;

export const MarketProjectItemListHeaderSearchContainer = styled.div`
    width: 320px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const MarketProjectItemListBody = styled.div`
    display: flex;
    flex-wrap: wrap;

    gap: 24px;
    margin-top: 20px;

    @media ${(props) => props.theme.tabletM} {
        gap: 24px 20px;
    }

    @media ${(props) => props.theme.mobileL} {
        gap: 16px 12px;
    }
`;

export const MarketProjectItemListMoreButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
`;

export const MarketProjectItemListMoreButtonWidth = styled.div`
    width: 160px;
`;

export const MarketProjectItemListExceptionContainer = styled.div`
    min-height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const MarketProjectItemListSearchFilterStateIconImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export const MarketProjectItemListNoDataImg = styled.img`
    width: 108.571px;
    height: 120px;
`;

export const MarketProjectItemListNoDataMsg = styled.div`
    color: #333;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    margin-top: 28px;
`;

type Props = {
    projectIdx: string;
    projectUserIdx: string;
};

const MarketProjectItemList = (props: Props): ReactElement | null => {
    const { openCommonStatusInfoModal } = useOpenCommonStatusInfoModal("item");
    const marketItemFilters: FilterType[] = getMarketProjectItemListFilters(
        openCommonStatusInfoModal,
    );

    const [filters, setFilters] = useState(marketItemFilters);
    const [sortingList, setSortingList] = useState(
        MarketProjectItemListSortingList(),
    );
    const [searchInput, setSearchInput] = useState("");
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const [marketProjectItemListPerRow, setMarketProjectItemListPerRow] =
        useAtom(MarketProjectItemListPerRowAtom);
    const isMe = useIsMe();
    const isProjectOwner = isMe(props.projectUserIdx);
    const { isOnMountedSetDone } = useSetStateOnWindowWidth(
        setMarketProjectItemListPerRow,
        [10, 10, 8, 6, 6, 6, 4],
    );

    const { data: rawProjectData } = useProjectDataQuery(props.projectIdx);

    const {
        data: rawItemListData,
        hasNextPage,
        fetchNextPage,
        isLoading: isItemListLoading,
        isIdle: isItemListIdle,
        isSuccess: isItemListSuccess,
        error: itemListError,
        refetch: refetchItemListData,
    } = useItemListQuery(
        {
            list_type: "project",
            sort_type: getSelectedSortingList(sortingList),
            page_no: 1,
            limit: marketProjectItemListPerRow,
            project_idx: props.projectIdx,
            item_name: debouncedSearchInput,
            item_price: getSelectedFilterItemStatus("price", filters),
            item_status: getSelectedFilterItemStatus("status", filters),
        },
        {
            cacheTime: 60000,
            staleTime: 5000,
            enabled: isOnMountedSetDone && marketProjectItemListPerRow !== 0,
        },
    );

    const [isLeftSideBarRender, setIsLeftSideBarRender] = useAtom(
        isLeftSideBarRenderAtom,
    );

    useEffect(() => {
        if (!isLeftSideBarRender && (isItemListSuccess || itemListError)) {
            setIsLeftSideBarRender(true);
        }
    }, [isItemListSuccess, itemListError]);

    const itemList = spreadItemListPages(rawItemListData.pages);
    return (
        <MarketProjectItemListContainer>
            <MarketProjectItemListHeader>
                <MarketProjectItemListLeftHeader isMe={isProjectOwner}>
                    {isProjectOwner && (
                        <MarketProjectItemListItemAddLink
                            to={"/market/add-item"}
                            state={{
                                selectedProject: rawProjectData,
                            }}
                        >
                            <MarketProjectItemListItemAddBtn
                                btnType={"filled-primary"}
                            >
                                아이템 등록
                            </MarketProjectItemListItemAddBtn>
                        </MarketProjectItemListItemAddLink>
                    )}
                    <MarketProjectItemListHeaderBtnContainer>
                        <SearchFilter
                            id={"market-project-item-list"}
                            title={"아이템 검색 필터"}
                            layerPositionStyleString={"left: 10px;"}
                            filters={filters}
                            setFilters={setFilters}
                            filterItemWidth={"200px"}
                        />
                        <SortingButton
                            sortingList={sortingList}
                            setSortingList={setSortingList}
                        />
                    </MarketProjectItemListHeaderBtnContainer>
                </MarketProjectItemListLeftHeader>

                <MarketProjectItemListRightHeader>
                    <MarketProjectItemListHeaderSearchContainer>
                        <SubSearch
                            searchInput={searchInput}
                            setSearchInput={setSearchInput}
                            placeholder={"아이템명을 입력해주세요."}
                            blurHandler={async () => {
                                queryClient.removeQueries({
                                    predicate: (query) =>
                                        query.queryKey.includes("item-list"),
                                });
                            }}
                        />
                    </MarketProjectItemListHeaderSearchContainer>
                </MarketProjectItemListRightHeader>
            </MarketProjectItemListHeader>

            <ApiResBoundary
                isNoData={itemList.length === 0}
                isLoading={isItemListLoading || isItemListIdle}
                loadingFallback={
                    <MarketProjectItemListExceptionContainer>
                        <Spinner />
                    </MarketProjectItemListExceptionContainer>
                }
                noDataFallback={
                    <MarketProjectItemListExceptionContainer>
                        <MarketProjectItemListNoDataImg
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-empty-item.svg`}
                        />
                        <MarketProjectItemListNoDataMsg>
                            아이템이 없습니다.
                        </MarketProjectItemListNoDataMsg>
                    </MarketProjectItemListExceptionContainer>
                }
                error={itemListError}
            >
                <MarketProjectItemListBody>
                    {itemList.map((item: ItemListItemType, idx: number) => {
                        return (
                            <MarketProjectItemListItem
                                key={idx}
                                refetchItemList={refetchItemListData}
                                data={item}
                            />
                        );
                    })}
                </MarketProjectItemListBody>
            </ApiResBoundary>

            <MarketProjectItemListMoreButtonContainer>
                <MarketProjectItemListMoreButtonWidth>
                    <MoreButton
                        isShow={hasNextPage}
                        onClick={() => {
                            fetchNextPage().then(() => {});
                        }}
                    />
                </MarketProjectItemListMoreButtonWidth>
            </MarketProjectItemListMoreButtonContainer>
        </MarketProjectItemListContainer>
    );
};

export default MarketProjectItemList;
