import React, { ReactElement } from "react";
import styled from "styled-components";

export const MarketPointOrderSellCountPercent = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #777777;
    padding: 9px 14px;
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    cursor: pointer;
`;

export const MarketPointOrderSellCountPercentRow = styled.div`
    display: flex;
    justify-content: right;
    gap: 6px;
    margin-top: -12px;
`;

type Props = {
    percentList: number[];
    available_order: number;
    setCount: (value: number) => void;
};

const OrderCountPercentRow = (props: Props): ReactElement | null => {
    return (
        <MarketPointOrderSellCountPercentRow>
            {props.percentList.map((percent, idx) => {
                return (
                    <MarketPointOrderSellCountPercent
                        key={idx}
                        onClick={() => {
                            props.setCount(
                                Math.floor(
                                    (props.available_order * percent) / 100,
                                ),
                            );
                        }}
                    >
                        {percent}%
                    </MarketPointOrderSellCountPercent>
                );
            })}
        </MarketPointOrderSellCountPercentRow>
    );
};

export default OrderCountPercentRow;
