import { ReactElement, useRef } from "react";
import styled from "styled-components";
import { useAtom, useSetAtom } from "jotai";
import viewHeightAtom from "../../../../../store/scroll/viewHeightAtom";
import { useOnClickOutside } from "usehooks-ts";
import ModalComponentInfoListAtom from "../../../../../store/ui/ModalComponentInfoListAtom";
import isScrollLockAtom from "../../../../../store/scroll/isScrollLockAtom";

export const ModalLayoutWrapper = styled.div<{
    vh: number;
}>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: ${({ vh }) => 100 * vh + "px"};
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    overflow: hidden;

    ${(props) => props.theme.zIndexBgDark};
`;

export const ModalLayoutContainer = styled.div`
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(36, 36, 36, 0.12),
        0 8px 24px 0 rgba(83, 83, 83, 0.12);
`;

type Props = {
    modalId: string;
    children: string | ReactElement;
    modalResolve: (value: any) => void;
};

const ModalLayout = (props: Props): ReactElement | null => {
    const setIsScrollLock = useSetAtom(isScrollLockAtom);
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const [vh] = useAtom(viewHeightAtom);
    const modalContainerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(modalContainerRef, (e) => {
        if (
            modalComponentInfoList.length > 0 &&
            modalComponentInfoList[modalComponentInfoList.length - 1].id ===
                props.modalId &&
            modalComponentInfoList[modalComponentInfoList.length - 1].id !==
                "common-loading-modal"
        )
            props.modalResolve(false);
    });

    return (
        <ModalLayoutWrapper vh={vh} id={"modal-layout-wrapper"}>
            <ModalLayoutContainer ref={modalContainerRef}>
                {props.children}
            </ModalLayoutContainer>
        </ModalLayoutWrapper>
    );
};

export default ModalLayout;
