import { ReactElement, useEffect } from "react";
import { useAtom } from "jotai";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import isDarkModeAtom from "../../../../../../store/ui/isDarkModeAtom";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useGetNotificationStatusQuery } from "../../../../../../query/notification/useGetNotificationStatusQuery";
import { useOpenNotiListModal } from "../../../../../../hooks/modal/openModal/useOpenNotiListModal";

export const NotiContainer = styled.div`
    z-index: 2;
    line-height: 1;
    color: black;
`;

export const NotiIcon = styled.img<{
    isDarkMode: boolean;
}>`
    width: 40px;
    height: 40px;
    line-height: auto;
    position: relative;
    vertical-align: middle;
    cursor: pointer;

    border-radius: 10px;
    transition: background-color 300ms ease;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isDarkMode }) =>
                isDarkMode
                    ? "rgba(255, 255, 255, 0.12)"
                    : "rgba(13, 13, 26, 0.06)"};
        }
    }

    // filter: opacity(0.5) drop-shadow(0 0 0 #000000);
    @media ${(props) => props.theme.tabletL} {
        margin: 4px;
    }
`;

type Props = {};

const HeaderNotiArea = (props: Props): ReactElement | null => {
    const [isDarkMode] = useAtom(isDarkModeAtom);
    const location = useLocation();

    const { data: rawNotificationStatus, refetch: refetchNotificationStatus } =
        useGetNotificationStatusQuery();

    useEffect(() => {
        refetchNotificationStatus();
    }, [location.pathname]);

    const { openNotiListModal } = useOpenNotiListModal();

    return (
        <NotiContainer>
            <NotiIcon
                id={"header-noti-icon"}
                isDarkMode={isDarkMode}
                src={
                    !isDarkMode
                        ? rawNotificationStatus.readable_bool
                            ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-notification-black-dot.svg`
                            : `${URL_CONSTANTS.ASSET_URL}/icon/icon-notification-black.png`
                        : rawNotificationStatus.readable_bool
                        ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-notification-dot.svg`
                        : `${URL_CONSTANTS.ASSET_URL}/icon/icon-notification.png`
                }
                onClick={async () => {
                    await openNotiListModal();
                }}
            />
        </NotiContainer>
    );
};

export default HeaderNotiArea;
