import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import { SortEnd } from "react-sortable-hoc";
import { arrayMoveImmutable, checkUserAgent } from "../../../../utils/Utils";
import styled from "styled-components";
import { useAtom } from "jotai";
import { flushSync } from "react-dom";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";
import { useWindowSize } from "usehooks-ts";
import DndListContainer from "../../commonUnitComponent/dndList/DndListContainer";

export const DndListWrapper = styled.div``;

export const XYOrderChangDndListButtonContainer = styled.div<{
    isMobileDevice: boolean;
    isMobileOrderChanging: boolean;
}>`
    position: fixed;
    bottom: 0;
    left: 0;
    background: #ffffff;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 16px 16px 0 0;

    gap: 10px;
    z-index: 20;
    width: calc(100% - 32px);
    padding: 0 16px;
    display: none;

    @media ${(props) => props.theme.tabletL} {
        display: ${({ isMobileDevice }) => (isMobileDevice ? "flex" : "none")};
        align-items: center;
        transition: all 300ms ease;
        overflow: hidden;

        height: ${({ isMobileOrderChanging }) =>
            isMobileOrderChanging ? "80px" : "0"};
    }
`;

export const XYOrderChangDndListCancelButton = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #555555;
    width: 50%;
    background: #f0f0f2;
    border-radius: 8px;
    text-align: center;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const XYOrderChangDndListOkButton = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    width: 50%;
    background: #fa54fa;
    border-radius: 8px;
    text-align: center;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type Props = {
    id: string;

    boxPerRow: number;
    rowGap: number;
    columnGap: number;

    list: any[];
    setList: (value: any) => void;
    onSortEnd: (value?: any | any[]) => void | Promise<any>;
    renderListItem: (value?: any) => ReactElement;

    isMobileOrderChanging: boolean;
    setIsMobileOrderChanging: (value: boolean) => void;

    exceptionalContainerStyle?: CSSProperties;
    exceptionalItemStyle?: CSSProperties;
};

const XYOrderChangDndList = (props: Props): JSX.Element => {
    const { width } = useWindowSize();
    const [tempMobileList, setTempMobileList] = useState<any[]>([]);
    const isMobileAndTouchDevice =
        checkUserAgent.isMobileDevice() ||
        (!checkUserAgent.isMobileDevice() &&
            checkUserAgent.isSafari() &&
            checkUserAgent.isTouchDevice());

    useEffect(() => {
        if (checkUserAgent.isMobileDevice()) {
            setTempMobileList(props.list);
        }
    }, [props.list]);

    const onSortEnd = async ({ oldIndex, newIndex }: SortEnd) => {
        const changedIndexList = arrayMoveImmutable(
            !checkUserAgent.isMobileDevice() ? props.list : tempMobileList,
            oldIndex,
            newIndex,
        );

        if (!checkUserAgent.isMobileDevice()) {
            // flush 하지 않으면 부모 list 가 갱신되지 않은 상태로 sortEnd 실행
            flushSync(() => {
                props.setList(changedIndexList);
            }, []);
            await props.onSortEnd(changedIndexList);
        }
        //
        else {
            setTempMobileList(changedIndexList);
        }
        setIsMoving(false);
    };

    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);

    // 모달열릴경우 강제 취소
    useEffect(() => {
        if (modalComponentInfoList.length !== 0) {
            props.setIsMobileOrderChanging(false);
        }
    }, [
        modalComponentInfoList,
        props.list,
        props.boxPerRow,
        props.rowGap,
        props.columnGap,
        width,
    ]);
    const [isMoving, setIsMoving] = useState(false);

    return (
        <DndListWrapper>
            <DndListContainer
                isMoving={isMoving}
                dndList={
                    !checkUserAgent.isMobileDevice()
                        ? props.list
                        : tempMobileList
                }
                renderListItem={props.renderListItem}
                axis={"xy"}
                id={props.id}
                hideSortableGhost={true}
                lockToContainerEdges={false}
                {...(!isMobileAndTouchDevice && {
                    getContainer: () => {
                        return (
                            document.getElementById(
                                "root__custom-scrollbar-children__wrapper",
                            ) ||
                            (document.scrollingElement as HTMLElement) ||
                            document.getElementById("root") ||
                            document.getElementsByTagName("body")[0]
                        );
                    },
                })}
                useWindowAsScrollContainer={isMobileAndTouchDevice}
                useDragHandle
                onSortEnd={onSortEnd}
                itemPerRow={props.boxPerRow}
                columnGap={props.columnGap}
                rowGap={props.rowGap}
                exceptionalContainerStyle={props.exceptionalContainerStyle}
                exceptionalItemStyle={props.exceptionalItemStyle}
                onSortStart={() => {
                    setIsMoving(true);
                }}
            />

            <XYOrderChangDndListButtonContainer
                isMobileDevice={checkUserAgent.isMobileDevice()}
                isMobileOrderChanging={props.isMobileOrderChanging}
            >
                <XYOrderChangDndListCancelButton
                    onClick={() => {
                        setTempMobileList(props.list);
                        props.setIsMobileOrderChanging(false);
                    }}
                >
                    취소
                </XYOrderChangDndListCancelButton>
                <XYOrderChangDndListOkButton
                    onClick={async () => {
                        flushSync(() => {
                            props.setList(tempMobileList);
                        });
                        await props.onSortEnd();
                        props.setIsMobileOrderChanging(false);
                    }}
                >
                    저장
                </XYOrderChangDndListOkButton>
            </XYOrderChangDndListButtonContainer>
        </DndListWrapper>
    );
};

export default XYOrderChangDndList;
