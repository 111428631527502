import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";
import CalendarCellBackgroundRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import CalendarCellRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import { URL_CONSTANTS } from "../../../../../../../../../../../constants/UrlConstants";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";
import CalendarBoldRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarBoldRow";
import { getRateTimeSalePrice } from "../../../../../../../../../adRegister/components/adRegisterAdjustDaySaleSwitch/AdRegisterAdjustDaySaleSwitch";

export const AdBidCalendarCellPcBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarCellPcBodyOnSchedule = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const adSalesInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;

    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdDailyDateData(props);

    return isTodaySale ? (
        myOfferSuccessInfo ? (
            // 당일 판매중 - 전체 판매 중단 상관X - 낙찰 표기
            <>
                <CalendarCellBackgroundRow
                    type={"primary"}
                    msg={"내 광고 게재중"}
                />
                <CalendarCellRow
                    msg={`${numberToStringWithComma(
                        myOfferSuccessInfo.offer_price,
                    )} KRW`}
                    type={"normal"}
                />
                <HiddenKey
                    id={"AD_ON_SCHEDULE__dailySale__myOfferSuccessInfo"}
                />
            </>
        ) : otherOfferSuccessInfo ? (
            <>
                <CalendarCellBackgroundRow
                    type={"secondary"}
                    msg={"광고 게재중"}
                />
                <CalendarCellRow
                    msg={`${numberToStringWithComma(
                        otherOfferSuccessInfo.offer_price,
                    )} KRW`}
                    type={"normal"}
                />
                <HiddenKey
                    id={"AD_ON_SCHEDULE__dailySale__otherOfferSuccessInfo"}
                />
            </>
        ) : !isAdStop ? (
            // 당일 판매중 - 전체 판매중 - 낙찰X
            <>
                <CalendarCellBackgroundRow
                    msg={"즉시구매 가능"}
                    type={"blue"}
                    iconPath={`${URL_CONSTANTS.ASSET_URL}/icon/icon-thunder-blue.svg`}
                />
                {cellInfo.dailyClosedTimeSaleInfo
                    ?.buy_now_closetime_sale_bool ? (
                    <>
                        <CalendarCellRow
                            msg={`${numberToStringWithComma(
                                adSalesInfo.ad_reserve_price * 1.1,
                            )} KRW`}
                            type={"normal"}
                            hasCancelLine={true}
                            opacity={0.3}
                        />
                        <CalendarBoldRow
                            rate={getRateTimeSalePrice(
                                cellInfo.dailyClosedTimeSaleInfo
                                    .buy_now_closetime_sale_price,
                                adSalesInfo.ad_reserve_price,
                            )}
                            msg={`${numberToStringWithComma(
                                cellInfo.dailyClosedTimeSaleInfo
                                    .buy_now_closetime_sale_price * 1.1,
                            )} KRW`}
                        />
                    </>
                ) : (
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            adSalesInfo.ad_reserve_price * 1.1,
                        )} KRW`}
                        type={"blue"}
                    />
                )}
                <HiddenKey
                    id={"AD_ON_SCHEDULE__notAdStop__dailySale__noOfferSuccess"}
                />
            </>
        ) : (
            // 당일 판매중 - 전체 판매 중단 - 낙찰X
            <>
                <HiddenKey
                    id={"AD_ON_SCHEDULE__AdStop__dailySale__noOfferSuccess"}
                />
            </>
        )
    ) : !isAdStop ? (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <CalendarCellBackgroundRow msg={"판매 중단"} type={"danger"} />
            <HiddenKey
                id={"AD_ON_SCHEDULE__notAdStop__notDailySale__noOfferSuccess"}
            />
        </>
    ) : (
        // 당일 판매 중단 - 전체 판매 중단 - 낙찰X
        <>
            <HiddenKey
                id={"AD_ON_SCHEDULE__AdStop__notDailySale__noOfferSuccess"}
            />
        </>
    );
};

export default AdBidCalendarCellPcBodyOnSchedule;
