import { ReactElement } from "react";
import styled from "styled-components";
import { ProjectDetailDataType } from "../../../../../../query/project/useProjectDataQuery";
import { numberToStringWithComma } from "../../../../../../utils/Utils";

export const MarketProjectTitleDetailInfoInfoContainer = styled.div`
    overflow: hidden;
    margin-top: 4px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 0;
    }
`;

export const MarketProjectTitleDetailInfoValueContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px 0;
    max-width: 603px;
`;

export const MarketProjectTitleDetailInfoInfoColumn = styled.div`
    display: flex;
`;

export const MarketProjectTitleDetailInfoInfoKey = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;

    color: #888888;

    @media ${(props) => props.theme.mobileL} {
        font-size: 14px;
    }
`;

export const MarketProjectTitleDetailInfoInfoValue = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;
    margin-left: 4px;
    @media ${(props) => props.theme.mobileL} {
        font-size: 14px;
    }
    color: #222222;
`;

export const MarketProjectTitleDetailInfoInfoPoint = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;

    align-items: center;

    color: #888888;
    margin: auto 8px;
    @media ${(props) => props.theme.mobileL} {
        font-size: 14px;
    }
`;

type ProjectDetailInfoType = {
    title: string;
    key: string;
};

type Props = {
    projectData: ProjectDetailDataType;
};

const MarketProjectTitleDetailInfo = (props: Props): ReactElement | null => {
    const detailInfo: ProjectDetailInfoType[] = [
        {
            title: "아이템",
            key: "item_cnt",
        },
        {
            title: "카테고리",
            key: "project_category",
        },
        {
            title: "보유자",
            key: "item_owner_cnt",
        },
        {
            title: "누적 거래량",
            key: "item_volume",
        },
    ];

    const convertValue = function (info: ProjectDetailInfoType): string {
        switch (info.key) {
            case "item_cnt":
            case "item_owner_cnt":
            case "item_volume":
                return numberToStringWithComma(props.projectData[info.key]);

            case "project_category":
                return props.projectData[info.key].title;

            case "project_currency":
                return props.projectData[info.key];

            case "brand_royalty":
            case "creator_royalty":
                return (
                    numberToStringWithComma(props.projectData[info.key]) + "%"
                );

            case "royalty":
                return (
                    numberToStringWithComma(
                        props.projectData.brand_royalty +
                            props.projectData.creator_royalty,
                    ) + "%"
                );
            default:
                return "";
        }
    };

    return (
        <MarketProjectTitleDetailInfoInfoContainer>
            <MarketProjectTitleDetailInfoValueContainer>
                {detailInfo.map((info: ProjectDetailInfoType, idx: number) => {
                    return (
                        <MarketProjectTitleDetailInfoInfoColumn key={info.key}>
                            <MarketProjectTitleDetailInfoInfoKey>
                                {info.title}{" "}
                            </MarketProjectTitleDetailInfoInfoKey>{" "}
                            <MarketProjectTitleDetailInfoInfoValue>
                                {convertValue(info)}
                            </MarketProjectTitleDetailInfoInfoValue>
                            {idx !== detailInfo.length - 1 && (
                                <MarketProjectTitleDetailInfoInfoPoint>
                                    ·
                                </MarketProjectTitleDetailInfoInfoPoint>
                            )}
                        </MarketProjectTitleDetailInfoInfoColumn>
                    );
                })}
            </MarketProjectTitleDetailInfoValueContainer>
        </MarketProjectTitleDetailInfoInfoContainer>
    );
};

export default MarketProjectTitleDetailInfo;
