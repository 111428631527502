import { ReactElement } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useOpenLoginConfirmModal } from "../../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { ResponsiveWidth } from "../../../../../../styles/Theme";
import { getIsLogin } from "../../../../../../store/auth/authKeyStore";
import { LinkMenuType } from "../../../header/components/HeaderMenu/HeaderMenu";

export const FooterPathListItemWrapper = styled.div<{
    wrapperWidth: number;
}>`
    ${({ wrapperWidth }) => {
        let style = "";

        if (wrapperWidth <= ResponsiveWidth.mobileM) {
            style += `width: 100%;`;
        }

        return style;
    }};
`;

export const FooterPathListItemContainer = styled(Link)<{}>`
    display: block;
    width: auto;
`;

export const FooterPathListItemTopTitle = styled.div<{
    wrapperWidth: number;
}>`
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    padding: 6px;
    margin-bottom: 8px;
    color: #555555;

    ${({ wrapperWidth }) => {
        let style = "";

        if (wrapperWidth <= ResponsiveWidth.mobileL) {
            style += `
             padding: 6px 0 6px 0;`;
        }

        return style;
    }};
`;

export const FooterPathListItemSubTitle = styled.div<{
    wrapperWidth: number;
}>`
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #888888;
    padding: 6px;
    margin-bottom: 8px;
    cursor: pointer;

    ${({ wrapperWidth }) => {
        let style = "";

        if (wrapperWidth <= ResponsiveWidth.mobileL) {
            style += `
             padding: 6px 0 6px 0;`;
        }

        return style;
    }};
`;

type Props = {
    wrapperWidth: number;
    footerData: LinkMenuType;
    type: string;
    isMoreParentMenu?: boolean;
};

const FooterPathListItem = (props: Props): ReactElement | null => {
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const isMoreParentMenu =
        typeof props.isMoreParentMenu !== "undefined"
            ? props.isMoreParentMenu
            : false;

    return (
        <FooterPathListItemWrapper wrapperWidth={props.wrapperWidth}>
            <FooterPathListItemContainer
                onClick={async (e) => {
                    if (props.footerData.path === "") {
                        e.preventDefault();
                        e.stopPropagation();
                    } else if (props.footerData.isNeedLogin && !getIsLogin()) {
                        await openLoginConfirmModal(props.footerData.path);
                    }
                }}
                to={
                    (props.footerData.isNeedLogin && !getIsLogin()) ||
                    props.footerData.path === ""
                        ? {}
                        : props.footerData.path
                }
                style={{
                    cursor: isMoreParentMenu ? "text" : "pointer",
                }}
                target={props.footerData.isNewTab ? "_blank" : ""}
            >
                {props.type === "main" ? (
                    <FooterPathListItemTopTitle
                        wrapperWidth={props.wrapperWidth}
                    >
                        {props.footerData.name}
                    </FooterPathListItemTopTitle>
                ) : (
                    <FooterPathListItemSubTitle
                        wrapperWidth={props.wrapperWidth}
                    >
                        {props.footerData.name}
                    </FooterPathListItemSubTitle>
                )}
            </FooterPathListItemContainer>
        </FooterPathListItemWrapper>
    );
};

export default FooterPathListItem;
