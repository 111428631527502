import { useQuery } from "react-query";
import { AdMyApi } from "../../../apis/adApi/AdMyApi";
import { MyAdOfferInfoType } from "../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoType";
import { DEFAULT_ADVERTISEMENT_INFO } from "../../../types/ad/advertisementInfo/AdInfoType";
import { DEFAULT_MY_AD_DAILY_LIST_ITEM_TYPE_DATA } from "../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";
import { DEFAULT_MY_AD_BY_DATE_OFFER_LIST_ITEM_OFFER_INFO_TYPE_DATA } from "../../../types/ad/adMy/myAd__sale/myAdByDateOfferList/MyAdByDateOfferListItemOfferInfoType";

export type MyOfferTemplateApiResultType = MyAdOfferInfoType;

export const useGetMyAdOfferInfo = (
    adIdx: string,
    offerIdx: string,
    isEnabled = true,
) => {
    const queryResult = useQuery<MyOfferTemplateApiResultType, Error>({
        queryKey: [
            "my-ad-offer-info" + offerIdx,
            "ad-sale-manage-refresh-target",
        ],
        queryFn: () => {
            return AdMyApi.getMyAdOfferInfo(adIdx, offerIdx);
        },
        suspense: false,
        cacheTime: 0,
        staleTime: 0,
        enabled: isEnabled,
        onError: (err) => {},
    });

    const defaultData: MyOfferTemplateApiResultType | MyAdOfferInfoType = {
        advertisementInfo: DEFAULT_ADVERTISEMENT_INFO,
        advertisementDailyInfo: DEFAULT_MY_AD_DAILY_LIST_ITEM_TYPE_DATA,
        offerInfo: {
            ...DEFAULT_MY_AD_BY_DATE_OFFER_LIST_ITEM_OFFER_INFO_TYPE_DATA,
            offer_space: [],
            broad_live_redirect_user_id: "",
            template_name: "",
            template_description: "",
        },
    };

    return {
        ...queryResult,
        data: queryResult.data || defaultData,
    };
};
