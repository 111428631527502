import { ReactElement, RefObject } from "react";
import styled, { CSSProp } from "styled-components";
import {
    addPostfixOnImageUrl,
    onErrorImg,
    onErrorResizingImg,
} from "../../../../utils/Utils";

export const SquareImgContainerContainer = styled.div<{
    parentStyle?: CSSProp;
}>`
    width: 100%;
    position: relative;

    ${({ parentStyle }) => parentStyle};

    ::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
`;

export const SquareImgTag = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
`;

type Props = {
    customStyle?: CSSProp;
    imgRef?: RefObject<HTMLImageElement>;
    src: string;
    setIsImageLoading?: (isLoading: boolean) => void;
    imageResizingPostfix?: string;
    fetchPriority?: "low" | "high" | "auto";
};

const SquareImg = (props: Props): ReactElement | null => {
    return (
        <SquareImgContainerContainer parentStyle={props.customStyle}>
            <SquareImgTag
                src={
                    props.imageResizingPostfix
                        ? addPostfixOnImageUrl(
                              props.src,
                              props.imageResizingPostfix,
                          )
                        : props.src
                }
                onError={(e) => {
                    if (props.imageResizingPostfix) {
                        onErrorResizingImg(
                            e,
                            props.src,
                            props.imageResizingPostfix,
                        );
                    } else {
                        onErrorImg(e);
                    }
                }}
                fetchPriority={
                    props.fetchPriority ? props.fetchPriority : "auto"
                }
                ref={props.imgRef}
                onLoad={() => {
                    if (props.setIsImageLoading) {
                        props.setIsImageLoading(false);
                    }
                }}
            />
        </SquareImgContainerContainer>
    );
};

export default SquareImg;
