import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import MoreButton from "../../../../commonUnitComponent/moreButton/MoreButton";
import { RightSideBarInfoIntroduceMoreButtonContainer } from "./RightSideBarInfo";

export const RightSideBarInfoDescContainer = styled.div``;

export const RightSideBarInfoIntroduceHideContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #555555;
    overflow: scroll;
    white-space: nowrap;
    opacity: 0;
`;

export const RightSideBarInfoIntroduceDesc = styled.div<{ isOpen: boolean }>`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #555555;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${({ isOpen }) => (isOpen ? "none" : 1)};
    -webkit-box-orient: vertical;
    word-break: break-all; // 문단으로 끊어져서 줄바꿈 됨

    max-height: 100vh;
    transition: all 300ms ease;
`;

type Props = {
    introduce: string;
    isBrand: boolean;
};

const RightSideBarInfoIntroduce = (props: Props): ReactElement | null => {
    const hideContainerRef = useRef<HTMLDivElement>(null);
    const isOverflow =
        hideContainerRef.current &&
        hideContainerRef.current?.scrollWidth >
            hideContainerRef.current?.clientWidth;

    const [isOpenIntroduce, setIsOpenIntroduce] = useState(false);

    return (
        <RightSideBarInfoDescContainer>
            {/*투명하게 해서 한줄 넘치는지 판단용*/}
            <RightSideBarInfoIntroduceHideContainer ref={hideContainerRef}>
                {props.introduce}
            </RightSideBarInfoIntroduceHideContainer>
            {props.isBrand ? (
                <RightSideBarInfoIntroduceDesc
                    isOpen={isOpenIntroduce}
                    dangerouslySetInnerHTML={{ __html: props.introduce }}
                />
            ) : (
                <RightSideBarInfoIntroduceDesc isOpen={isOpenIntroduce}>
                    {props.introduce}
                </RightSideBarInfoIntroduceDesc>
            )}

            {isOverflow && (
                <RightSideBarInfoIntroduceMoreButtonContainer>
                    <MoreButton
                        isShow={true}
                        isSupportFold={true}
                        onClick={() => {
                            setIsOpenIntroduce(!isOpenIntroduce);
                        }}
                    />
                </RightSideBarInfoIntroduceMoreButtonContainer>
            )}
        </RightSideBarInfoDescContainer>
    );
};
export default RightSideBarInfoIntroduce;
