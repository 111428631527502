import { useInfiniteQuery } from "react-query";
import { ItemInventoryApi } from "../../apis/ItemInventoryApi";
import { ItemInventoryBelongingGroupListItemType } from "../../types/itemInventory/ItemInventoryBelongingGroupListItemType";

export type ItemInventoryBelongingGroupListApiResultType = {
    groupList: ItemInventoryBelongingGroupListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type ItemInventoryBelongingGroupListParamsType = {
    limit: number;
    page_no: number;
};
export const useGetItemInventoryBelongingGroupList = (itemIdx: string) => {
    const queryResult = useInfiniteQuery<
        ItemInventoryBelongingGroupListApiResultType,
        Error
    >({
        queryKey: [
            "item-inventory-belonging-group-list-" + itemIdx,
            "item-inventory-belonging-group-list",
        ],
        queryFn: () => ItemInventoryApi.getItemBelongingGroupList(itemIdx),
        suspense: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: ItemInventoryBelongingGroupListApiResultType[] = [
        {
            groupList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadItemInventoryBelongingGroupListPages(
    pages: ItemInventoryBelongingGroupListApiResultType[],
) {
    return pages
        .map((result) => result.groupList)
        .reduce((prev, next) => prev.concat(next));
}
