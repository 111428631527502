import React, { ReactElement, useEffect, useState } from "react";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import Checkbox from "../../../../commonUnitComponent/checkbox/Checkbox";
import Spinner from "../../../../commonUnitComponent/spinner/Spinner";
import {
    InfoTableColumnBoldValue,
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableColumnWarningValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
    InfoTableSubColumnValue,
    InfoTableSubRow,
    InfoTableTerm,
    InfoTableTermAgreeLabel,
    InfoTableTermCheckboxContainer,
    InfoTableTermContainer,
    InfoTableTermLink,
    InfoTableTermSpan,
    InfoTableUnderLine,
} from "../../../../../../styles/InfoTableStyle";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalDescWarning,
    ModalInfoContainer,
    ModalLargeDesc,
    ModalTitle,
} from "../../../../commonUnitComponent/modal/ModalStyle";
import ModalCloseIcon from "../../../../modal/modalCloseIcon/ModalCloseIcon";
import { queryClient } from "../../../../../../App";
import { Query } from "react-query";
import { useOrderBuyMutation } from "../../../../../../query/market/common/useOrderBuyMutation";
import { useGetMarketAveragePrice } from "../../../../../../query/market/common/useGetMarketAveragePrice";

export const PointBuyModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 432px;

    @media ${(props) => props.theme.mobileL} {
        max-width: 100%;
    }
`;

type Props = {
    pointIdx: string;
    count: number;
    setCount: (value: number) => void;
    pointBuyBrandRoyalty: number;
    resolve: (value: boolean) => void;
};

const PointBuyModal = (props: Props): ReactElement | null => {
    const termsCheckboxLabel = "위 사항을 확인하였으며, 구매에 동의합니다.";
    const terms = [
        "[필수] 현재 구매가를 확인했으며, 구매하고자 하는 상품과 가격이 확실합니다.",
        "[필수] 주문 또는 체결시 내 주문(체결) 정보가 다른 사람들에게 공개됩니다. 계속해서 진행할 경우 동의한 것으로 간주합니다.",
        <InfoTableTermSpan>
            [필수]{" "}
            <InfoTableTermLink
                to={"https://about.saybuzz.io/ko/policy/service"}
                target={"_blank"}
            >
                서비스 이용약관
            </InfoTableTermLink>
            을 확인 및 동의하고 결제를 진행합니다.
        </InfoTableTermSpan>,
        <InfoTableTermSpan>
            [필수]{" "}
            <InfoTableTermLink
                target={"_blank"}
                to={"https://about.saybuzz.io/ko/policy/privacy/usaged"}
            >
                개인정보 수집 및 이용 동의
            </InfoTableTermLink>
            를 읽고 이해했으며 이에 동의합니다.
        </InfoTableTermSpan>,
        "[필수] 거래가 체결되면 취소가 불가능합니다.",
    ];
    const [isAgree, setIsAgree] = useState(false);
    const [isRetry, setIsRetry] = useState(false);
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        setIsAgree(false);
        setIsPending(false);
    }, []);

    useEffect(() => {
        setIsAgree(false);
        setIsPending(false);
    }, [isRetry]);

    const {
        data: rawMarketPointAveragePrice,
        refetch: refetchMarketPointAveragePrice,
    } = useGetMarketAveragePrice(
        "point",
        props.pointIdx,
        {
            quantity: props.count,
        },
        true,
    );

    const { mutateAsync: buyPoint, isLoading: isBuyPointLoading } =
        useOrderBuyMutation("point", props.pointIdx, {
            order_quantity: props.count,
            average_unit_price: rawMarketPointAveragePrice.averagePrice,
        });

    return (
        <PointBuyModalContainer>
            <ModalCloseIcon isShow={!isPending} resolve={props.resolve} />
            <ModalTitle>포인트 {isRetry ? "재" : ""}구매</ModalTitle>
            <ModalLargeDesc>
                아래 가격으로 포인트를 {isRetry ? "재" : ""}구매하시겠습니까?
            </ModalLargeDesc>
            <ModalDescWarning>
                *직접 등록한 상품은 구매가 제한되어 제외한 가격이며, <br />
                구매 시점에 따라 가격이 달라질 수 있습니다.
            </ModalDescWarning>
            <ModalInfoContainer width={"412px"} mt={"20px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        구매수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(props.count)}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        구매가격
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                rawMarketPointAveragePrice.totalAmount,
                            )}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        평균단가
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                rawMarketPointAveragePrice.averagePrice,
                            )}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableMajorRow>
                        구매로열티
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                rawMarketPointAveragePrice.royalty.brandRoyalty,
                            )}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                </InfoTableRowContainer>
                <InfoTableUnderLine />
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        총 주문가격
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                rawMarketPointAveragePrice.totalAmount +
                                    rawMarketPointAveragePrice.royalty
                                        .brandRoyalty,
                            )}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        <div></div>
                        <InfoTableColumnWarningValue>
                            *구매 로열티 {props.pointBuyBrandRoyalty}% 포함
                        </InfoTableColumnWarningValue>
                    </InfoTableSubRow>
                </InfoTableRowContainer>
            </ModalInfoContainer>
            <InfoTableTermContainer>
                {terms.map((term, idx) => (
                    <InfoTableTerm key={idx}>{term}</InfoTableTerm>
                ))}
            </InfoTableTermContainer>

            <InfoTableTermCheckboxContainer>
                <Checkbox
                    id={"market--point__buy__term__check-box"}
                    checked={isAgree}
                    onChange={() => {
                        setIsAgree((prev) => {
                            return !prev;
                        });
                    }}
                    labelComponent={
                        <InfoTableTermAgreeLabel>
                            {termsCheckboxLabel}
                        </InfoTableTermAgreeLabel>
                    }
                />
            </InfoTableTermCheckboxContainer>

            <ModalButtonContainer>
                <ModalButton
                    btnType={
                        !isPending
                            ? "filled-secondary"
                            : "filled-secondary-disabled"
                    }
                    onClick={() => {
                        if (!isPending) {
                            props.resolve(false);
                        }
                    }}
                >
                    취소
                </ModalButton>
                <ModalButton
                    btnType={
                        !isAgree
                            ? "filled-primary-disabled"
                            : !isPending
                            ? "filled-primary"
                            : "filled-primary-pending"
                    }
                    onClick={async () => {
                        if (!isBuyPointLoading && isAgree) {
                            try {
                                setIsPending(true);
                                await buyPoint();
                                props.setCount(0);
                                props.resolve(true);
                            } catch (err: any) {
                                if (
                                    err.response.data.data.errorCode.indexOf(
                                        "AveragePriceVaried",
                                    ) !== -1
                                ) {
                                    await refetchMarketPointAveragePrice();
                                    setIsRetry(true);
                                } else {
                                    props.resolve(false);
                                    setTimeout(() => {
                                        setIsPending(false);
                                    }, 400);
                                }
                            }
                            await queryClient.resetQueries({
                                predicate: (query: Query) =>
                                    query.queryKey.includes(
                                        "point-info-refresh-target-" +
                                            props.pointIdx,
                                    ) ||
                                    query.queryKey.includes(
                                        `market-point-average-price-${props.pointIdx}`,
                                    ) ||
                                    query.queryKey.includes("my-point-list"),
                            });
                        }
                    }}
                >
                    {isPending ? (
                        <Spinner
                            width={28}
                            height={28}
                            borderSize={3}
                            color={"#fa54fa #fa54fa #fa54fa #f0f0f2"}
                        />
                    ) : isRetry ? (
                        "재구매하기"
                    ) : (
                        "구매하기"
                    )}
                </ModalButton>
            </ModalButtonContainer>
        </PointBuyModalContainer>
    );
};

export default PointBuyModal;
