import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { numberToStringWithComma } from "../../../../../../../../../../utils/Utils";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellBackgroundRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import CalendarCellRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import {
    AdSaleManageScheduleCalendarCellBodyBlankContainer,
    HiddenKeyManage,
} from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";
import CalendarBoldRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarBoldRow";
import { getRateTimeSalePrice } from "../../../../../../../../adRegister/components/adRegisterAdjustDaySaleSwitch/AdRegisterAdjustDaySaleSwitch";

export const AdBidCalendarCellPcBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarCellPcBodyOnSchedule = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const {
        targetDateInfo: cellInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdMyDailyDateData(props);

    return isTodaySale ? (
        // 당일 판매중 - 전체 판매 중단 상관X - 낙찰 표기
        cellInfo.offerSuccessInfo ? (
            <>
                <CalendarCellBackgroundRow
                    msg={"광고 게재중"}
                    type={"secondary"}
                />
                <CalendarCellRow
                    msg={`${numberToStringWithComma(
                        cellInfo.offerSuccessInfo.offer_price,
                    )} KRW`}
                    type={"secondary"}
                />
                <CalendarCellRow
                    msg={cellInfo.offerSuccessInfo.offer_success_user_nick}
                    type={"secondary"}
                    hasNoMt={true}
                />
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__dailySale__offerSuccessInfo"}
                />
            </>
        ) : !isAdStop ? (
            // 당일 판매중 - 전체 판매중 - 낙찰X
            <>
                <CalendarCellBackgroundRow
                    msg={"즉시구매 가능"}
                    type={"blue"}
                />
                <CalendarCellRow msg={"즉시구매가"} type={"secondary"} />
                {cellInfo.dailyClosedTimeSaleInfo
                    ?.buy_now_closetime_sale_bool ? (
                    <>
                        <CalendarCellRow
                            msg={`${numberToStringWithComma(
                                cellInfo.adSaleInfo.ad_reserve_price * 1.1,
                            )} KRW`}
                            type={"normal"}
                            hasCancelLine={true}
                            opacity={0.3}
                        />
                        <CalendarBoldRow
                            rate={getRateTimeSalePrice(
                                cellInfo.dailyClosedTimeSaleInfo
                                    .buy_now_closetime_sale_price,
                                cellInfo.adSaleInfo.ad_reserve_price,
                            )}
                            msg={`${numberToStringWithComma(
                                cellInfo.dailyClosedTimeSaleInfo
                                    .buy_now_closetime_sale_price * 1.1,
                            )} KRW`}
                        />
                    </>
                ) : (
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            cellInfo.adSaleInfo.ad_reserve_price * 1.1,
                        )} KRW`}
                        type={"secondary"}
                        hasNoMt={true}
                    />
                )}
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__notAdStop__dailySale__noOffer"}
                />
            </>
        ) : (
            // 당일 판매중 - 전체 판매 중단 - 낙찰X
            <>
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__adStop__dailySale__noOffer"}
                />
            </>
        )
    ) : !isAdStop ? (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <CalendarCellBackgroundRow msg={"판매 중단"} type={"danger"} />
            <AdSaleManageScheduleCalendarCellBodyBlankContainer />
            <HiddenKeyManage
                id={"AD_ON_SCHEDULE__notAdStop__notAdDailySale__noOffer"}
            />
        </>
    ) : (
        // 당일 판매 중단 - 전체 판매 중단 - 낙찰X
        <>
            <HiddenKeyManage
                id={"AD_ON_SCHEDULE__AdStop__notAdDailySale__noOffer"}
            />
        </>
    );
};

export default AdSaleManageCalendarCellPcBodyOnSchedule;
