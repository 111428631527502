import { ReactElement, useRef } from "react";
import styled from "styled-components";
import { useHover } from "usehooks-ts";
import MarketProjectTitleBadge from "./MarketProjectTitleBadge";
import { ProjectDetailDataType } from "../../../../../../../query/project/useProjectDataQuery";
import {
    getNetworkBackground,
    onErrorImg,
} from "../../../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";
import Tooltip from "../../../../../../common/commonUnitComponent/tooltip/Tooltip";

export const MarketProjectBadge = styled.div<{
    backgroundColor?: string;
    isHover?: boolean;
}>`
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    display: flex;
    align-items: center;
    color: ${({ backgroundColor }) =>
        backgroundColor ? "#FFFFFF" : "#333333"};
    background: ${({ backgroundColor, isHover }) => {
        let color = backgroundColor ? backgroundColor : "#f0f0f2";
        color += isHover ? "70" : "";
        return color;
    }};
    border-radius: 20px;
    padding: 9px 14px;
    position: relative;
    transition: all 300ms ease;
`;

export const MarketProjectTokenContainer = styled.div`
    width: 36px;
    height: 36px;
    position: relative;
`;
export const MarketProjectToken = styled.img<{
    isHover: boolean;
}>`
    width: 36px;
    height: 36px;
    opacity: ${({ isHover }) => (isHover ? 0.7 : 1)};
`;

export const MarketProjectTitleBadgesContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

type Props = {
    data: ProjectDetailDataType;
};

const MarketProjectTitleBadges = ({ data }: Props): ReactElement | null => {
    const tokenBadgeRef = useRef(null);
    const isTokenBadgeHover = useHover(tokenBadgeRef);

    return (
        <MarketProjectTitleBadgesContainer>
            {/*네트워크 뱃지*/}
            <MarketProjectTitleBadge
                backgroundColor={getNetworkBackground(data.project_network)}
                tooltip={data.project_network + " 네트워크를 사용합니다."}
                info={data.project_network}
            />

            {/*로열티 뱃지*/}
            <MarketProjectTitleBadge
                tooltip={`브랜드 로열티 (${data.brand_royalty}%) + 크리에이터 로열티(${data.creator_royalty}%)`}
                info={
                    (data.brand_royalty + data.creator_royalty).toFixed(1) + "%"
                }
            />

            {/*project_erc 뱃지*/}
            {data.project_erc !== "" && (
                <MarketProjectTitleBadge
                    tooltip={
                        data.project_erc === "ERC-1155"
                            ? "ERC-1155는 다수의 아이템(대체 가능한 아이템)을 생성할 수 있는 프로토콜입니다."
                            : "ERC-721은 고유한 하나의 아이템(대체 불가능한 아이템)을 생성하는 프로토콜입니다."
                    }
                    info={data.project_erc}
                />
            )}
            {/*귀속 뱃지*/}
            {!data.transaction_bool && (
                <MarketProjectTitleBadge
                    tooltip={
                        "제작 후 거래 및 전송이 완료되면 더 이상 거래가 불가합니다."
                    }
                    info={"귀속"}
                />
            )}

            {/*토큰*/}
            <MarketProjectTokenContainer>
                <MarketProjectToken
                    isHover={isTokenBadgeHover}
                    ref={tokenBadgeRef}
                    src={`${
                        URL_CONSTANTS.ASSET_URL
                    }/token/${data.project_currency.toLowerCase()}-small.svg`}
                    onError={onErrorImg}
                />
                <Tooltip isHover={isTokenBadgeHover}>
                    <>
                        사용 가능한 토큰은 {data.project_currency.toUpperCase()}
                        입니다.
                    </>
                </Tooltip>
            </MarketProjectTokenContainer>
        </MarketProjectTitleBadgesContainer>
    );
};

export default MarketProjectTitleBadges;
