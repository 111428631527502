import { ReactElement } from "react";
import styled, { keyframes } from "styled-components";

export const ShimmerWrapper = styled.div<{
    width?: string;
    height?: string;
    bgColor?: string;
    isLoading: boolean;
}>`
    position: relative;
    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "100%")};
    background-color: ${({ bgColor, isLoading }) =>
        bgColor && isLoading ? bgColor : "transparent"};
`;

const skeletonGradient = keyframes`
    0% {
        transform: translateX(-85%);
    }
    50% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(85%);
    }
`;

export const ShimmerContainer = styled.div<{
    width?: string;
    height?: string;
    isThin?: boolean;
    duration?: number;
}>`
    top: 0;
    left: 0;
    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "100%")};
    position: absolute;
    z-index: 2;
    display: block;

    background: ${({ isThin }) => `
        linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.6) ${isThin ? "45%" : "35%"},
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0.6) ${isThin ? "45%" : "55%"},
            rgba(255, 255, 255, 0) 100%
        )
    `};
    animation: ${skeletonGradient} ${({ duration }) => duration || 1.5}s
        infinite ease-out;
`;

export const ShimmerChildrenContainer = styled.div<{
    isLoading: boolean;
}>`
    opacity: ${({ isLoading }) => (isLoading ? "0" : "1")};
`;

type Props = {
    isLoading: boolean;
    width?: string;
    height?: string;
    children?: ReactElement | string;
    bgColor?: string;
    isThin?: boolean;
    duration?: number;
};

const Shimmer = (props: Props): ReactElement | null => {
    return (
        <ShimmerWrapper
            width={props.width}
            height={props.height}
            bgColor={props.bgColor}
            isLoading={props.isLoading}
        >
            {props.isLoading && (
                <ShimmerContainer
                    width={props.width}
                    height={props.height}
                    duration={props.duration}
                />
            )}
            <ShimmerChildrenContainer isLoading={props.isLoading}>
                {props.children}
            </ShimmerChildrenContainer>
        </ShimmerWrapper>
    );
};

export default Shimmer;
