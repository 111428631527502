import { useQuery } from "react-query";
import {
    CampaignInfoType,
    DEFAULT_CAMPAIGN_INFO_TYPE_DATA,
} from "../../../types/ad/camapaignInfo/CampaignInfoType";
import {
    BrandInfoType,
    DEFAULT_BRAND_INFO_TYPE_DATA,
} from "../../../types/brand/BrandInfoType";
import { AdCampaignApi } from "../../../apis/adApi/AdCampaignApi";

export interface AdCampaignInfoApiResultType {
    brandInfo: BrandInfoType;
    campaignInfo: CampaignInfoType;
}

const DefaultAdCampaignData: AdCampaignInfoApiResultType = {
    brandInfo: DEFAULT_BRAND_INFO_TYPE_DATA,
    campaignInfo: DEFAULT_CAMPAIGN_INFO_TYPE_DATA,
};

export const useAdCampaignInfoQuery = (campaign_idx: string) => {
    const queryResult = useQuery<AdCampaignInfoApiResultType, Error>({
        queryKey: ["ad-campaign-" + campaign_idx],
        queryFn: () => AdCampaignApi.getCampaignInfo(campaign_idx),
        suspense: false,
        onError: () => {},
    });

    return {
        ...queryResult,
        data: queryResult.data || DefaultAdCampaignData,
    };
};
