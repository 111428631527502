import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../../../../modal/modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalButtonContainer,
    ModalInfoContainer,
    ModalLargeDesc,
    ModalTitle,
} from "../../../../commonUnitComponent/modal/ModalStyle";
import {
    InfoTableColumnBoldValue,
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
    InfoTableSubColumnValue,
    InfoTableSubRow,
    InfoTableTerm,
    InfoTableTermAgreeLabel,
    InfoTableTermCheckboxContainer,
    InfoTableTermContainer,
    InfoTableTermLink,
    InfoTableTermSpan,
    InfoTableUnderLine,
} from "../../../../../../styles/InfoTableStyle";
import {
    getRoyalty,
    numberToStringWithComma,
} from "../../../../../../utils/Utils";
import Checkbox from "../../../../commonUnitComponent/checkbox/Checkbox";
import Spinner from "../../../../commonUnitComponent/spinner/Spinner";
import { useProjectDataQuery } from "../../../../../../query/project/useProjectDataQuery";
import { useOrderSellMutation } from "../../../../../../query/market/common/useOrderSellMutation";
import { OrderSellMoreItemType } from "../../components/OrderTypeDropDownButton";

export const ItemSellModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 432px;

    @media ${(props) => props.theme.mobileL} {
        max-width: 100%;
    }
`;

type Props = {
    resolve: (value: boolean) => void;
    selectedSellPeriod: OrderSellMoreItemType;
    count: number;
    itemPricePerPiece: number;
    itemIdx: string;
    projectIdx: string;
};

const ItemSellModal = (props: Props): ReactElement | null => {
    const terms = [
        "[필수] 현재 판매가를 확인했으며, 판매하고자 하는 상품과 가격이 확실합니다.",
        "[필수] 주문 또는 체결시 내 주문(체결) 정보가 다른 사람들에게 공개됩니다. 계속해서 진행할 경우 동의한 것으로 간주합니다.",
        <InfoTableTermSpan>
            [필수]{" "}
            <InfoTableTermLink
                to={"https://about.saybuzz.io/ko/policy/service"}
                target={"_blank"}
            >
                서비스 이용약관
            </InfoTableTermLink>
            을 확인 및 동의하고 결제를 진행합니다.
        </InfoTableTermSpan>,
        <InfoTableTermSpan>
            [필수]{" "}
            <InfoTableTermLink
                target={"_blank"}
                to={"https://about.saybuzz.io/ko/policy/privacy/usaged"}
            >
                개인정보 수집 및 이용 동의
            </InfoTableTermLink>
            를 읽고 이해했으며 이에 동의합니다.
        </InfoTableTermSpan>,
        "[필수] 거래가 체결되면 취소가 불가능합니다.",
    ];

    const termsCheckboxLabel = "위 사항을 확인하였으며, 판매에 동의합니다.";
    const [isAgree, setIsAgree] = useState(false);
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        setIsAgree(false);
        setIsPending(false);
    }, []);

    const { data: rawProjectData } = useProjectDataQuery(props.projectIdx);

    const itemPriceAmount = props.itemPricePerPiece * props.count;

    const brandRoyaltyAmount = getRoyalty(
        rawProjectData.brand_royalty / 100,
        props.itemPricePerPiece,
        props.count,
    );

    const creatorRoyaltyAmount = getRoyalty(
        rawProjectData.creator_royalty / 100,
        props.itemPricePerPiece,
        props.count,
    );

    const { mutateAsync: sellItem, isLoading: isSellItemLoading } =
        useOrderSellMutation("item", props.itemIdx, {
            order_quantity: props.count,
            order_unit_price: props.itemPricePerPiece,
            order_valid_period: props.selectedSellPeriod.key,
        });

    const splitSellPeriod = () => {
        try {
            return props.selectedSellPeriod.value.split("일")[0];
        } catch (e) {
            console.info(e);
        }
        return props.selectedSellPeriod.value;
    };

    return (
        <ItemSellModalContainer>
            <ModalCloseIcon isShow={!isPending} resolve={props.resolve} />
            <ModalTitle>아이템 판매</ModalTitle>
            <ModalLargeDesc>
                아래 가격으로 아이템을 판매하시겠습니까?
            </ModalLargeDesc>
            <ModalInfoContainer width={"412px"} mt={"20px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        등록기간
                        <InfoTableColumnValue>
                            {splitSellPeriod()}
                            {/*혹시몰라 split 에러 처리*/}
                            {splitSellPeriod().indexOf("일") === -1 && (
                                <InfoTableColumnUnit>일</InfoTableColumnUnit>
                            )}
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        판매수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(props.count)}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        판매단가
                        <InfoTableColumnValue>
                            {numberToStringWithComma(props.itemPricePerPiece)}
                            <InfoTableColumnUnit>
                                {" " + rawProjectData.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        판매수익
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(itemPriceAmount)}
                            <InfoTableColumnUnit>
                                {" " + rawProjectData.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                </InfoTableRowContainer>
                <InfoTableUnderLine />
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        판매 로열티
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                brandRoyaltyAmount + creatorRoyaltyAmount,
                            )}
                            <InfoTableColumnUnit> RP</InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        브랜드 로열티
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(brandRoyaltyAmount)}
                            <InfoTableColumnUnit> RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableSubRow>
                        크리에이터 로열티
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(creatorRoyaltyAmount)}
                            <InfoTableColumnUnit> RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                </InfoTableRowContainer>
            </ModalInfoContainer>

            <InfoTableTermContainer>
                {terms.map((term, idx) => {
                    return <InfoTableTerm key={idx}>{term}</InfoTableTerm>;
                })}
            </InfoTableTermContainer>
            <InfoTableTermCheckboxContainer>
                <Checkbox
                    id={"market--point__bid__sell__term__check-box"}
                    checked={isAgree}
                    onChange={() => {
                        setIsAgree(!isAgree);
                    }}
                    labelComponent={
                        <InfoTableTermAgreeLabel>
                            {termsCheckboxLabel}
                        </InfoTableTermAgreeLabel>
                    }
                />
            </InfoTableTermCheckboxContainer>

            <ModalButtonContainer>
                <ModalButton
                    btnType={
                        !isPending
                            ? "filled-secondary"
                            : "filled-secondary-disabled"
                    }
                    onClick={() => {
                        if (!isPending) props.resolve(false);
                    }}
                >
                    취소
                </ModalButton>
                <ModalButton
                    btnType={
                        isAgree
                            ? !isPending
                                ? "filled-primary"
                                : "filled-primary-pending"
                            : "filled-primary-disabled"
                    }
                    onClick={async () => {
                        if (isAgree && !isSellItemLoading) {
                            setIsPending(true);
                            try {
                                await sellItem();
                            } catch (e) {
                                //
                            }
                            props.resolve(true);
                            // isPending 초기화 삭제 - 실패시에도 닫는다
                        }
                    }}
                >
                    {isPending ? (
                        <Spinner
                            width={28}
                            height={28}
                            borderSize={3}
                            color={"#fa54fa #fa54fa #fa54fa #f0f0f2"}
                        />
                    ) : (
                        "판매하기"
                    )}
                </ModalButton>
            </ModalButtonContainer>
        </ItemSellModalContainer>
    );
};

export default ItemSellModal;
