import { useTitleItemRegisterMutation } from "../../query/item/titleItem/useTitleItemRegisterMutation";
import { useTitleItemDeleteMutation } from "../../query/item/titleItem/useTitleItemDeleteMutation";

export function useItemTitleRegisterDelete(
    itemIdx: string,
    isTitleItem: boolean,
    refetchFunction: (value?: any) => Promise<any>,
) {
    const { mutateAsync: registerTitleItem } =
        useTitleItemRegisterMutation(itemIdx);

    const { mutateAsync: deleteTitleItem } =
        useTitleItemDeleteMutation(itemIdx);

    const itemTitleRegisterDelete = async function () {
        try {
            if (!isTitleItem) {
                await registerTitleItem();
            } else {
                await deleteTitleItem();
            }
            await refetchFunction();
        } catch (e) {
            //
        }
    };

    return { itemTitleRegisterDelete };
}
