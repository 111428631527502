import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalTitle,
} from "../../../../../common/commonUnitComponent/modal/ModalStyle";
import ModalCloseIcon from "../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import { ItemListItemType } from "../../../../../../query/item/useItemListQuery";
import { useUseItemMutation } from "../../../../../../query/item/use/useUseItemMutation";
import {
    ItemSendLayerWarningDescLink,
    ItemSendUseLayerInfoContainer,
    ItemSendUseLayerInfoInputInfoContainer,
    ItemSendUseLayerInfoInputInfoTitle,
    ItemSendUseLayerInfoItemProjectTitle,
    ItemSendUseLayerInfoItemThumbnail,
    ItemSendUseLayerInfoItemTitle,
    ItemSendUseLayerInfoItemTitleContainer,
    ItemSendUseLayerInfoNumberButton,
    ItemSendUseLayerInfoNumberContainer,
    ItemSendUseLayerInfoNumberInput,
    ItemSendUseLayerRemainCountContainer,
    ItemSendUseLayerWarningContainer,
    ItemSendUseLayerWarningDesc,
    ItemSendUseLayerWarningDescContainer,
    ItemSendUseLayerWarningDescWrapper,
    ItemSendUseLayerWarningTitle,
} from "./WalletCollectionItemSendModal";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import { useGetOwnItemCnt } from "../../../../../../query/item/useGetOwnItemCnt";
import { useOpenCommonErrorModal } from "../../../../../../hooks/modal/openModal/useOpenCommonErrorModal";

export const WalletCollectionItemUseModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 352px;
    margin: 0;

    cursor: auto;
    overflow: visible;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

type Props = {
    resolve: (value: boolean) => void;
    item: ItemListItemType;
};

const WalletCollectionItemUseModal = (props: Props): ReactElement | null => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const {
        data: rawOwnItemCnt,
        refetch: refetchOwnItemCnt,
        isSuccess: isOwnItemCntSuccess,
    } = useGetOwnItemCnt(props.item.item_idx);

    const [inputNumber, setInputNumber] = useState(1);
    const { mutateAsync: useItem } = useUseItemMutation(props.item.item_idx, {
        count: inputNumber,
    });
    const warningList = [
        "사용한 아이템은 복구할 수 없으며, 환불이 불가능합니다.",
        "아이템은 사용 즉시 소모되며, 해당 브랜드에서 사용된 상태를 확인할 수 있습니다.",
        "‘SOOP  → 선물·후원 → 선물받은 별풍선’에서 일반, 베스트, 파트너 별풍선 환전 시 사용이 가능합니다.",
        <>
            <ItemSendLayerWarningDescLink
                to={"https://ch.sooplive.co.kr/saybuzz/posts/99386269"}
                target={"_blank"}
            >
                세이버즈 고객센터
            </ItemSendLayerWarningDescLink>
        </>,
    ];

    const isUseAble = inputNumber !== 0;

    useEffect(() => {
        if (inputNumber <= 0) {
            setInputNumber(1);
        }
    }, [inputNumber]);

    useEffect(() => {
        if (isOwnItemCntSuccess && rawOwnItemCnt.own_item_cnt === 0) {
            openCommonErrorModal({
                title: "전송 가능한 수량이 없습니다.",
            });
            props.resolve(false);
        }
    }, [isOwnItemCntSuccess, rawOwnItemCnt]);

    return (
        <WalletCollectionItemUseModalContainer>
            <ModalTitle>아이템 사용</ModalTitle>
            <ModalCloseIcon resolve={props.resolve} />
            <ItemSendUseLayerInfoContainer>
                <ItemSendUseLayerInfoItemThumbnail
                    src={props.item.item_thumbnail_full_path}
                />
                <ItemSendUseLayerInfoItemTitleContainer>
                    <ItemSendUseLayerInfoItemTitle>
                        {props.item.item_name}
                    </ItemSendUseLayerInfoItemTitle>
                    <ItemSendUseLayerInfoItemProjectTitle>
                        {props.item.project_name}
                    </ItemSendUseLayerInfoItemProjectTitle>
                </ItemSendUseLayerInfoItemTitleContainer>
            </ItemSendUseLayerInfoContainer>
            <ItemSendUseLayerInfoInputInfoContainer>
                <ItemSendUseLayerInfoInputInfoTitle>
                    사용 개수
                    <ItemSendUseLayerInfoNumberContainer>
                        <ItemSendUseLayerInfoNumberButton
                            onClick={async () => {
                                if (inputNumber > 0)
                                    setInputNumber(inputNumber - 1);
                                await refetchOwnItemCnt();
                            }}
                        >
                            -
                        </ItemSendUseLayerInfoNumberButton>
                        <ItemSendUseLayerInfoNumberInput
                            type="number"
                            value={inputNumber}
                            onChange={async (e) => {
                                if (Number(e.target.value) > 0) {
                                    setInputNumber(Number(e.target.value));
                                }
                                await refetchOwnItemCnt();
                            }}
                        />
                        <ItemSendUseLayerInfoNumberButton
                            onClick={async () => {
                                setInputNumber(inputNumber + 1);
                                await refetchOwnItemCnt();
                            }}
                        >
                            +
                        </ItemSendUseLayerInfoNumberButton>
                    </ItemSendUseLayerInfoNumberContainer>
                </ItemSendUseLayerInfoInputInfoTitle>
            </ItemSendUseLayerInfoInputInfoContainer>
            <ItemSendUseLayerRemainCountContainer>
                현재 보유량{" "}
                {numberToStringWithComma(rawOwnItemCnt.own_item_cnt)}
            </ItemSendUseLayerRemainCountContainer>
            <ItemSendUseLayerWarningContainer>
                <ItemSendUseLayerWarningTitle>
                    유의사항
                </ItemSendUseLayerWarningTitle>
                <ItemSendUseLayerWarningDescWrapper>
                    {warningList.map((warningItem, idx) => {
                        return (
                            <ItemSendUseLayerWarningDescContainer key={idx}>
                                <ItemSendUseLayerWarningDesc>
                                    ·
                                </ItemSendUseLayerWarningDesc>
                                <ItemSendUseLayerWarningDesc>
                                    {warningItem}
                                </ItemSendUseLayerWarningDesc>
                            </ItemSendUseLayerWarningDescContainer>
                        );
                    })}
                </ItemSendUseLayerWarningDescWrapper>
            </ItemSendUseLayerWarningContainer>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    취소
                </ModalButton>
                <ModalButton
                    btnType={
                        isUseAble ? "filled-primary" : "filled-primary-disabled"
                    }
                    onClick={async () => {
                        if (isUseAble) {
                            try {
                                const result = await useItem();
                                props.resolve(true);
                            } catch (e: any) {
                                props.resolve(false);
                            }
                        }
                    }}
                >
                    사용하기
                </ModalButton>
            </ModalButtonContainer>
        </WalletCollectionItemUseModalContainer>
    );
};

export default WalletCollectionItemUseModal;
