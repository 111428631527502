import { useEffect } from "react";
import {
    AD_BID_PAGE_REG_EXP,
    INTRO_PAGE_REG_EXP,
    ITEM_PAGE_REG_EXP,
    POINT_PAGE_REG_EXP,
    PROJECT_PAGE_REG_EXP,
} from "../../../../../../constants/PageRegExp";
import { useLocation, useNavigationType, useParams } from "react-router-dom";
import { usePrevious } from "../../../../../../hooks/common/usePrevious";
import { getSessionStorageItem } from "../../../../../../utils/Utils";

export function useSetNoCustomScrollbarLocationChangeHook(
    isRoot: boolean,
    isDoubleSideBarLayoutLeftSideBarType: boolean,
    isDoubleSideBarLayoutContentsType: boolean,
    isHide: boolean,
    isSaveScrollTop: boolean,
    id: string,
    init: () => void,
) {
    const params = useParams();
    const prevParams = usePrevious(params);
    const location = useLocation();
    const navigationType = useNavigationType();

    const isAdBidPage = AD_BID_PAGE_REG_EXP.test(location.pathname);
    const isPointPage = POINT_PAGE_REG_EXP.test(location.pathname);
    const isProjectPage = PROJECT_PAGE_REG_EXP.test(location.pathname);
    const isItemPage = ITEM_PAGE_REG_EXP.test(location.pathname);
    const isIntroPage = INTRO_PAGE_REG_EXP.test(location.pathname);
    const isHideByLocationPathname =
        ((isAdBidPage ||
            isPointPage ||
            isProjectPage ||
            isItemPage ||
            isIntroPage) &&
            isRoot) ||
        isHide; // 전체 스크롤 숨김

    useEffect(() => {
        // 위치 변경이되도 left side bar 에 있는 스크롤은 고정 시킴
        if (
            (isAdBidPage || isPointPage || isItemPage || isProjectPage) &&
            isDoubleSideBarLayoutLeftSideBarType
        ) {
            return;
        }
        init();

        // 뒤로가기 / 앞으로가기 인 경우
        if (navigationType === "POP" && isSaveScrollTop) {
            new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    const scrollTopInSS =
                        getSessionStorageItem<number>(
                            `path-${location.pathname}-id-${id}`,
                        ) || 0;

                    if (
                        scrollTopInSS <= document.documentElement.offsetHeight
                    ) {
                        document.documentElement.scrollTop =
                            getSessionStorageItem<number>(
                                `path-${location.pathname}-id-${id}`,
                            ) || 0;
                        clearTimeout(interval);
                        resolve(1);
                    }
                }, 300);
            });
        }
        // 일반 페이지 이동인 경우
        else {
            // 위치 변경이되도 left side bar 에 있는 스크롤은 고정 시킴
            if (
                (isPointPage || isItemPage || isProjectPage || isAdBidPage) &&
                isDoubleSideBarLayoutLeftSideBarType
            ) {
                return;
            }
            // 광고입찰 페이지 중앙 컨텐츠일 경우 날짜 변경시에는 유지 광고 변경시에는 초기화
            else if (isAdBidPage && isDoubleSideBarLayoutContentsType) {
                const adIdx = params.ad_id ? params.ad_id : "0";
                const prevAdIdx = prevParams?.ad_id ? prevParams.ad_id : "0";

                if (prevAdIdx === adIdx) {
                    return;
                } else {
                    init();
                }
            } else {
                init();
            }
        }
    }, [location.pathname]);
    return {
        isHideByLocationPathname,
    };
}
