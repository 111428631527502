import React, { ReactElement } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetAdBidCalendarCellComponentByCode } from "../useGetAdBidCalendarCellComponentByCode";
import { AdDailyListItemType } from "../../../../../../../../../../types/ad/adDaily/AdDailyListItemType";

export const AdBidCalendarCellBodyWrapper = styled.div<{
    isShowOnlyWeek: boolean;
}>`
    min-height: ${({ isShowOnlyWeek }) => (isShowOnlyWeek ? "83px" : "111px")};
    overflow: hidden;
    transition: all 200ms ease;

    @media ${(props) => props.theme.mobileL} {
        min-height: inherit;
    }
`;

export const AdBidCalendarCellBodyPcContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const AdBidCalendarCellBodyMobileContainer = styled.div`
    display: none;
    @media ${(props) => props.theme.mobileL} {
        display: flex;
        justify-content: center;
    }
`;

export const HiddenKey = styled.div`
    width: 0;
    height: 0;
    display: none;
    overflow: hidden;
`;

type Props = {
    adDailyList: AdDailyListItemType[];
    cellDate: Date;
    displayingDate: Date;
    isShowOnlyWeek: boolean;
};

const AdBidCalendarCellBody = (props: Props): ReactElement | null => {
    const params = useParams();
    const adIdx = params.ad_id ? params.ad_id : "0";
    const { cellPcBody, cellMobileBody } =
        useGetAdBidCalendarCellComponentByCode({
            cellDate: props.cellDate,
            adDailyList: props.adDailyList,
            adIdx: adIdx,
        });

    return (
        <AdBidCalendarCellBodyWrapper isShowOnlyWeek={props.isShowOnlyWeek}>
            <AdBidCalendarCellBodyPcContainer>
                {cellPcBody}
            </AdBidCalendarCellBodyPcContainer>
            <AdBidCalendarCellBodyMobileContainer>
                {cellMobileBody}
            </AdBidCalendarCellBodyMobileContainer>
        </AdBidCalendarCellBodyWrapper>
    );
};

export default AdBidCalendarCellBody;
