import React, { ReactElement, useRef } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import Tooltip from "../../../commonUnitComponent/tooltip/Tooltip";
import { useHover } from "usehooks-ts";

export const OrderTabRowContainer = styled.div<{ isWrap?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        flex-wrap: ${({ isWrap }) => (isWrap ? "wrap" : "nowrap")};
        gap: ${({ isWrap }) => (isWrap ? "13px" : "0")};
    }
`;

export const OrderTabRowKey = styled.div<{ isPrice: boolean }>`
    font-weight: ${({ isPrice }) => (isPrice ? 500 : 400)};
    font-size: 16px;
    line-height: 100%;
    color: ${({ isPrice }) => (isPrice ? "#333333" : "#777777")};
    display: flex;
    align-items: center;
`;

export const OrderTabRowValue = styled.div<{
    isPrice: boolean;
    isWrap?: boolean;
}>`
    font-weight: ${({ isPrice }) => (isPrice ? 700 : 500)};
    font-size: ${({ isPrice }) => (isPrice ? "24px" : "16px")};
    line-height: 100%;
    display: flex;
    color: ${({ isPrice }) => (isPrice ? "#fa54fa" : "#222222")};
    align-items: ${({ isPrice }) => (isPrice ? "flex-end" : "center")};
    align-content: ${({ isPrice }) => (isPrice ? "flex-end" : "")};

    @media ${(props) => props.theme.mobileL} {
        width: ${({ isWrap }) => (isWrap ? "100%" : "auto")};
        justify-content: ${({ isWrap }) =>
            isWrap ? "flex-end" : "flex-start"};
    }
`;

export const OrderTabRowValueUnit = styled.div`
    margin-left: 2px;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #888888;
`;

export const OrderTabRowValuePriceUnit = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    margin-left: 4px;
`;

export const OrderTabIconContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 4px;
    width: 24px;
    height: 24px;
`;

export const OrderTabIcon = styled.img`
    width: 24px;
    height: 24px;
`;

type Props = {
    rowKey: string | ReactElement;
    rowValue: string | ReactElement;
    tooltipMsg?: string;
    rowValueUnit?: string;
    isPrice: boolean;
    isWrap?: boolean;
};

const OrderTabRow = (props: Props): ReactElement | null => {
    const infoIconRef = useRef(null);
    const isHover = useHover(infoIconRef);

    return (
        <OrderTabRowContainer isWrap={props.isWrap}>
            <OrderTabRowKey isPrice={props.isPrice}>
                {props.rowKey}
                {props.tooltipMsg && (
                    <OrderTabIconContainer>
                        <OrderTabIcon
                            ref={infoIconRef}
                            src={
                                URL_CONSTANTS.ASSET_URL + "/icon/icon-info.svg"
                            }
                        />
                        <Tooltip isHover={isHover}>{props.tooltipMsg}</Tooltip>
                    </OrderTabIconContainer>
                )}
            </OrderTabRowKey>
            <OrderTabRowValue isPrice={props.isPrice} isWrap={props.isWrap}>
                {props.rowValue}
                {props.rowValueUnit && props.isPrice ? (
                    <OrderTabRowValuePriceUnit>
                        {props.rowValueUnit}
                    </OrderTabRowValuePriceUnit>
                ) : (
                    <OrderTabRowValueUnit>
                        {props.rowValueUnit}
                    </OrderTabRowValueUnit>
                )}
            </OrderTabRowValue>
        </OrderTabRowContainer>
    );
};

export default OrderTabRow;
OrderTabRow.defaultProps = {
    isPrice: false,
    isWrap: false,
};
