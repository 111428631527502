export type ConvertedOfferStatusType = {
    offerStatusKR: string;
    offerStatusSubKR: string;
    offerStatus: string;
    offerInvalidStatusKR: string;
    offerInvalidStatus: string;
};

export const AD_OFFER_STATUS_OFFERED = "0";
export const AD_OFFER_STATUS_CANCELED_OFFER = "1";
export const AD_OFFER_STATUS_SUCCESS_OFFER = "20";
export const AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER = "21";
export const AD_OFFER_STATUS_NO_SELECT_OFFER = "3";
export const AD_OFFER_STATUS_CANCELED_SUCCESS_OFFER = "4";

const _convertAdOfferInvalidStatus = (adOfferInvalidStatus: string) => {
    switch (adOfferInvalidStatus) {
        case "1":
            return "구매자 직접 취소";
        case "2":
            return "시스템 취소";
        case "3":
            return "판매자 직접 취소";
        case "4":
            return "미채택";
        case "5":
            return "결제 실패";
        default:
            return "not defined invalidStatus";
    }
};

export const staticConvertAdOfferStatus = function (
    adOfferStatus: string,
    adOfferInvalidStatus: string,
): ConvertedOfferStatusType {
    let returnValue = {
        offerStatus: adOfferStatus,
        offerInvalidStatus: adOfferInvalidStatus,
        offerInvalidStatusKR:
            _convertAdOfferInvalidStatus(adOfferInvalidStatus),
    };

    switch (adOfferStatus) {
        case AD_OFFER_STATUS_OFFERED:
            return {
                ...returnValue,
                offerStatusKR: "입찰",
                offerStatusSubKR: "",
            };

        case AD_OFFER_STATUS_CANCELED_OFFER:
            return {
                ...returnValue,
                offerStatusKR: "입찰 취소",
                offerStatusSubKR: "",
            };

        case AD_OFFER_STATUS_SUCCESS_OFFER:
            return {
                ...returnValue,
                offerStatusKR: "낙찰",
                offerStatusSubKR: "입찰",
            };

        case AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER:
            return {
                ...returnValue,
                offerStatusKR: "낙찰",
                offerStatusSubKR: "즉시 구매",
            };

        case AD_OFFER_STATUS_NO_SELECT_OFFER:
            return {
                ...returnValue,
                offerStatusKR: "미채택",
                offerStatusSubKR: "",
            };

        case AD_OFFER_STATUS_CANCELED_SUCCESS_OFFER:
            return {
                ...returnValue,
                offerStatusKR: "낙찰 취소",
                offerStatusSubKR: "",
            };

        default:
            return {
                ...returnValue,
                offerStatusKR: "offer-status-error",
                offerStatusSubKR: "",
            };
    }
};
