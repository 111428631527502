import { ReactElement } from "react";
import styled from "styled-components";
import Carousel, {
    RenderComponentDataType,
} from "../../../../../../common/commonUnitComponent/carousel/Carousel";
import { AdBidingSelectTemplateCarouselIndicator } from "../../../../../adBiding/components/AdBidingSelectTemplate/AdBidingSelectTemplate";
import { AdCarouselArrowButton } from "../../../../../../common/commonUnitComponent/carousel/components/carouselArrowButton/CarouselArrowButtonStyle";
import AdSaleManageOfferListModalPreviewInfo from "./components/AdSaleManageOfferListModalPreviewInfo";
import { useGetMyAdOfferInfo } from "../../../../../../../query/my/myAd/useGetMyAdOfferInfo";
import ApiResBoundary from "../../../../../../common/apiResBoundary/ApiResBoundary";
import { PathAfterResolveType } from "../../../../../../../hooks/modal/useOpenModal";
import { AdDailyListItemStatusCodeType } from "../../../../../../../types/ad/adDaily/AdDailyListItemType";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../common/commonStyle/CommonButtonStyle";
import { queryClient } from "../../../../../../../App";
import { Query } from "react-query";
import AdSaleManageOfferListModalPreviewCancelOfferBtn from "./components/AdSaleManageOfferListModalPreviewCancelOfferBtn";
import AdSaleManageOfferListModalPreviewAcceptOfferBtn from "./components/AdSaleManageOfferListModalPreviewAcceptOfferBtn";
import AdSaleManageOfferListModalPreviewCancelOfferSuccessBtn from "./components/AdSaleManageOfferListModalPreviewCancelOfferSuccessBtn";
import AdSaleManageOfferListModalPreviewCarouselItem from "./components/AdSaleManageOfferListModalPreviewCarouselItem";

export const AdSaleManageOfferListModalPreviewCarouselWrapper = styled.div`
    width: 100%;
`;

export const AdSaleManageOfferListModalPreviewCarouselContainer = styled.div``;

export const AdSaleManageOfferListModalPreviewBtnsContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 32px;
`;

export const AdSaleManageOfferListModalPreviewBtn = styled.div<
    CommonButtonType & {
        isOnlyMobileShow: boolean;
    }
>`
    ${CommonButtonStyle};
    height: 48px;
    font-size: 16px;

    display: ${({ isOnlyMobileShow }) => (isOnlyMobileShow ? "none" : "flex")};

    @media ${(props) => props.theme.mobileL} {
        display: flex;
    }
`;

export const AdSaleManageOfferListModalCarouselButton = styled(
    AdCarouselArrowButton,
)<{
    direction: string;
}>``;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    adIdx: string;
    selectedOfferIdx: string;
    adSalesDate: Date;
    adDailyStatus: AdDailyListItemStatusCodeType;
};

const AdSaleManageOfferListModalPreview = (
    props: Props,
): ReactElement | null => {
    const {
        data: rawOfferData,
        isLoading: isOfferDataLoading,
        error: errorOfferData,
    } = useGetMyAdOfferInfo(
        props.adIdx,
        props.selectedOfferIdx,
        props.selectedOfferIdx !== "0",
    );

    const refreshCache = async function () {
        await queryClient.refetchQueries({
            predicate: (query: Query) =>
                query.queryKey.includes("ad-bid-refresh-target") ||
                query.queryKey.includes("ad-sale-manage-refresh-target"),
        });
    };

    return (
        <ApiResBoundary
            isLoading={isOfferDataLoading}
            isNoData={false}
            error={errorOfferData}
        >
            <AdSaleManageOfferListModalPreviewCarouselWrapper>
                <AdSaleManageOfferListModalPreviewCarouselContainer>
                    <Carousel
                        isShowPagination={true}
                        responsiveDisplayLength={[1, 1, 1, 1, 1, 1]}
                        dataList={rawOfferData.offerInfo.offer_space}
                        displayLength={1}
                        renderComponent={(data: RenderComponentDataType) => (
                            <AdSaleManageOfferListModalPreviewCarouselItem
                                key={data.idx}
                                offerSpace={data.listItem}
                            />
                        )}
                        renderArrow={(data) => (
                            <AdSaleManageOfferListModalCarouselButton
                                direction={data.type}
                            />
                        )}
                        renderPagination={(data) => (
                            <AdBidingSelectTemplateCarouselIndicator>
                                {data.currentIndex + 1}/{data.length}
                            </AdBidingSelectTemplateCarouselIndicator>
                        )}
                        isArrowHoverEvent={true}
                    />
                </AdSaleManageOfferListModalPreviewCarouselContainer>

                <AdSaleManageOfferListModalPreviewInfo
                    offerInfo={rawOfferData.offerInfo}
                />

                <AdSaleManageOfferListModalPreviewBtnsContainer>
                    <AdSaleManageOfferListModalPreviewBtn
                        isOnlyMobileShow={true}
                        btnType={"filled-secondary"}
                        onClick={() => {
                            props.resolve(false);
                        }}
                    >
                        닫기
                    </AdSaleManageOfferListModalPreviewBtn>

                    {/*템플릿 반려 버튼*/}
                    <AdSaleManageOfferListModalPreviewCancelOfferBtn
                        offerStatus={rawOfferData.offerInfo.offer_status}
                        {...props}
                        refreshCache={refreshCache}
                    />

                    {/*낙찰하기 버튼*/}
                    <AdSaleManageOfferListModalPreviewAcceptOfferBtn
                        resolve={props.resolve}
                        selectedOfferInfo={rawOfferData}
                        adIdx={props.adIdx}
                        refreshCache={refreshCache}
                        adSalesDate={props.adSalesDate}
                    />

                    {/*낙찰취소 버튼*/}
                    <AdSaleManageOfferListModalPreviewCancelOfferSuccessBtn
                        offerStatus={rawOfferData.offerInfo.offer_status}
                        {...props}
                        refreshCache={refreshCache}
                    />
                </AdSaleManageOfferListModalPreviewBtnsContainer>
            </AdSaleManageOfferListModalPreviewCarouselWrapper>
        </ApiResBoundary>
    );
};

export default AdSaleManageOfferListModalPreview;
