import axios from "axios";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import { AdInfoApiResultType } from "../../query/ad/ad/useAdInfoQuery";
import {
    AdDailyListApiResultType,
    AdDailyListDateRangeType,
} from "../../query/ad/adDaily/useGetAdDailyListQuery";
import {
    AdListApiParamsType,
    AdListApiResultType,
} from "../../query/ad/ad/useAdListQuery";
import { AdRegisterParams } from "../../query/ad/ad/useAdRegisterMutation";
import { AdAdjustParams } from "../../query/ad/ad/useAdAdjustMutation";
import { ClickCountListParams } from "../../query/ad/analysis/useGetClickCountListQuery";
import { SetAdDailyStatusParamsType } from "../../query/ad/adDaily/useSetAdDailyStatusMutation";
import { AdDailyInfoApiResultType } from "../../query/ad/adDaily/useGetAdDailyInfoQuery";
import { AdRestartParams } from "../../query/ad/ad/useAdRestartMutation";

export const AdApi = {
    getList: async (
        params: AdListApiParamsType,
    ): Promise<AdListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/list`, {
                    params: params,
                    paramsSerializer: {
                        // recent_array number[] 보낼때 payload key recent_array[] 대괄호 지우는 옵션
                        indexes: null,
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdInfo: async (ad_idx: string): Promise<AdInfoApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/${ad_idx}`, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdDailyList: async (
        ad_idx: string,
        dateRange: AdDailyListDateRangeType,
    ): Promise<AdDailyListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/${ad_idx}/daily`, {
                    params: dateRange,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdDailyInfo: async (
        ad_idx: string,
        dateString: string,
    ): Promise<AdDailyInfoApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${ad_idx}/daily/${dateString}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    setAdDailyState: async (
        ad_idx: string,
        params: SetAdDailyStatusParamsType,
    ): Promise<AdDailyListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${ad_idx}/daily/availability`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    registerAd: async (params: AdRegisterParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(URL_CONSTANTS.API_URL + "/v2/ad", params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    adjustAd: async (adIdx: string, params: AdAdjustParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(URL_CONSTANTS.API_URL + "/v2/ad/" + adIdx, params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    // 더이상 사용 X 남겨는 둔다
    deleteAd: async (adIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(URL_CONSTANTS.API_URL + "/v2/ad/" + adIdx + "", {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    stopAd: async (adIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/v2/ad/" + adIdx + "/stop",
                    {},
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    restartAd: async (adIdx: string, params: AdRestartParams): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/restart`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdClickCount: async (adIdx: string, type: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/analysis/click-count/${type}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getAdClickCountList: async (
        adIdx: string,
        params: ClickCountListParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/${adIdx}/analysis/click-count/list`,
                    {
                        params,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        clickCountList: res.data.data.clickCountList.reverse(),
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
