export const ResponsiveWidth = {
    mobileM: 499,
    mobileL: 719,
    tabletM: 1023,
    tabletL: 1279,
    PCM: 1439,
    PCL: 1920,
};

const device = {
    mobileM: `(max-width: ${ResponsiveWidth.mobileM}px)`,
    mobileL: `(max-width: ${ResponsiveWidth.mobileL}px)`,
    tabletM: `(max-width: ${ResponsiveWidth.tabletM}px)`,
    tabletL: `(max-width: ${ResponsiveWidth.tabletL}px)`,
    PCM: `(max-width: ${ResponsiveWidth.PCM}px)`,
    PCL: `(max-width: ${ResponsiveWidth.PCL}px)`,

    minMobileL: `(min-width: ${ResponsiveWidth.mobileM}px)`,
    minTabletM: `(min-width: ${ResponsiveWidth.mobileL}px)`,
    minTabletL: `(min-width: ${ResponsiveWidth.tabletM}px)`,
    PC: `(min-width: ${ResponsiveWidth.tabletL}px)`,

    zIndexFullModalOrLayer: `z-index: 10001`,
    zIndexModalOrLayer: `z-index: 10001`,
    zIndexBgDark: `z-index: 10000`,

    zIndexHeaderUpper: `z-index: 9500`,
    zIndexHeader: `z-index: 9500`,
    zIndexSubHeader: `z-index: 9499`,

    zIndexFixedSingleUI: `z-index: 9000`,

    zIndexScrollBar: `z-index: 8500`,
    zIndexScrollArea: `z-index: 8499`,
};

export default device;

// ${({ wrapperWidth }) => {
//     let style = "";
//     if (wrapperWidth <= ResponsiveWidth.PCM) {
//     }
//
//     if (wrapperWidth <= ResponsiveWidth.tabletL) {
//     }
//
//     if (wrapperWidth <= ResponsiveWidth.tabletM) {
//     }
//
//     if (wrapperWidth <= ResponsiveWidth.mobileL) {
//     }
//     if (wrapperWidth <= ResponsiveWidth.mobileM) {
//     }
// }};
