import { TermType } from "../../components/common/commonUnitComponent/terms/Terms";
import { useOpenCommonErrorModal } from "../modal/openModal/useOpenCommonErrorModal";

export function useCheckIsAllTermAgree(termsList: TermType[]) {
    const { openCommonErrorModal } = useOpenCommonErrorModal();

    return (isOpenErrorModal = true) => {
        const result = termsList.every((item) => item.state);
        if (result) return true;
        else {
            if (isOpenErrorModal) {
                openCommonErrorModal({
                    title: `필수 약관에 동의해주셔야 합니다.`,
                }).then(() => {});
            }

            return false;
        }
    };
}
