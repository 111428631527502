import CssFilterConverter from "css-filter-converter";
import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";

export const CalendarMonthPickerLayerContainer = styled.div<{
    isShowSelectLayer: boolean;
}>`
    position: absolute;
    top: 44px;
    height: ${({ isShowSelectLayer }) => (isShowSelectLayer ? "244px" : 0)};
    padding-top: ${({ isShowSelectLayer }) => (isShowSelectLayer ? "16px" : 0)};
    padding-bottom: ${({ isShowSelectLayer }) =>
        isShowSelectLayer ? "16px" : 0};
    padding-left: 16px;
    padding-right: 16px;

    overflow: hidden;
    transition: all 300ms ease;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: #fff;
    width: 280px;
    z-index: 1;
    box-shadow: 0px 8px 24px 0px rgba(83, 83, 83, 0.12),
        0px 1px 3px 0px rgba(36, 36, 36, 0.12);
`;

export const CalendarMonthPickerLayerHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const CalendarMonthPickerLayerHeaderYear = styled.div`
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
    margin-left: 10px;
`;

export const CalendarMonthPickerLayerHeaderArrowIconWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const CalendarMonthPickerLayerHeaderArrowIconContainer = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 13, 26, 0.06);
        }
    }
`;

export const CalendarMonthPickerLayerHeaderArrowIcon = styled.img`
    width: 8px;
    height: 12px;
`;

export const CalendarMonthPickerLayerBody = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3열로 설정 */
    grid-template-rows: repeat(4, 1fr); /* 4행으로 설정 */
`;

export const CalendarMonthPickerLayerBodyMonth = styled.div<{
    isSelected: boolean;
}>`
    display: flex;
    height: 48px;
    width: 93.3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: #000;
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    border-radius: 8px;
    transition: all 300ms ease;
    cursor: pointer;
    background: ${({ isSelected }) => (isSelected ? "#FA54FA" : "white")};
    color: ${({ isSelected }) => (isSelected ? "#fff" : "#000")};
    font-weight: ${({ isSelected }) => (isSelected ? "700" : "400")};

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isSelected }) =>
                isSelected ? "#FA54FA" : "rgba(13, 13, 26, 0.06)"};
        }
    }
`;

type Props = {
    displayingDate: Date;
    setDisplayingDate: (value: Date) => void;
    isShowSelectLayer: boolean;
    setIsShowSelectLayer: (value: boolean) => void;
};

const CalendarMonthPickerLayer = (props: Props): ReactElement | null => {
    const fullMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const [clickedYearCnt, setClickedYearCnt] = useState(0);
    const layerContainerRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(layerContainerRef, () => {
        props.setIsShowSelectLayer(false);
    });

    return (
        <CalendarMonthPickerLayerContainer
            ref={layerContainerRef}
            isShowSelectLayer={props.isShowSelectLayer}
        >
            <CalendarMonthPickerLayerHeader>
                <CalendarMonthPickerLayerHeaderYear>
                    {props.displayingDate.getFullYear() + clickedYearCnt}년
                </CalendarMonthPickerLayerHeaderYear>
                <CalendarMonthPickerLayerHeaderArrowIconWrapper>
                    <CalendarMonthPickerLayerHeaderArrowIconContainer
                        onClick={() => {
                            setClickedYearCnt(clickedYearCnt - 1);
                        }}
                    >
                        <CalendarMonthPickerLayerHeaderArrowIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-right.svg`}
                            style={{
                                transform: "rotate(180deg)",
                                filter:
                                    CssFilterConverter.hexToFilter("#000")
                                        .color + "",
                            }}
                        />
                    </CalendarMonthPickerLayerHeaderArrowIconContainer>

                    <CalendarMonthPickerLayerHeaderArrowIconContainer
                        onClick={() => {
                            setClickedYearCnt(clickedYearCnt + 1);
                        }}
                    >
                        <CalendarMonthPickerLayerHeaderArrowIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-right.svg`}
                            style={{
                                filter:
                                    CssFilterConverter.hexToFilter("#000")
                                        .color + "",
                            }}
                        />
                    </CalendarMonthPickerLayerHeaderArrowIconContainer>
                </CalendarMonthPickerLayerHeaderArrowIconWrapper>
            </CalendarMonthPickerLayerHeader>

            <CalendarMonthPickerLayerBody>
                {fullMonths.map((month) => {
                    return (
                        <CalendarMonthPickerLayerBodyMonth
                            key={month}
                            isSelected={
                                props.displayingDate.getMonth() + 1 === month
                            }
                            onClick={() => {
                                props.displayingDate.setMonth(month - 1);
                                props.displayingDate.setFullYear(
                                    props.displayingDate.getFullYear() +
                                        clickedYearCnt,
                                );
                                props.setDisplayingDate(
                                    new Date(props.displayingDate.getTime()),
                                );
                                setClickedYearCnt(0);
                                props.setIsShowSelectLayer(false);
                            }}
                        >
                            {month}월
                        </CalendarMonthPickerLayerBodyMonth>
                    );
                })}
            </CalendarMonthPickerLayerBody>
        </CalendarMonthPickerLayerContainer>
    );
};

export default CalendarMonthPickerLayer;
