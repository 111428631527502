import { ReactElement } from "react";
import styled from "styled-components";
import ChartTradingView, {
    ChartTradingViewDataType,
} from "../../../../../../../../common/commonUnitComponent/chartTradingView/ChartTradingView";
import { useParams } from "react-router-dom";
import { useItemDataQuery } from "../../../../../../../../../query/item/useItemDataQuery";
import {
    MarketSettlementListItemType,
    spreadSettlementListPages,
    useGetMarketSettlementListQuery,
} from "../../../../../../../../../query/market/common/useGetMarketSettlementListQuery";
import CommonTabBox from "../../../../../../../../common/commonUnitComponent/commonTabBox/CommonTabBox";
import { getIsTradeAvailableItem } from "../../../../../../../../wallet/walletCollection/components/walletCollectionItemListItem/WalletCollectionItem";
import ApiResBoundary from "../../../../../../../../common/apiResBoundary/ApiResBoundary";

export const MarketItemTrendChartBoxWrapper = styled.div<{ inSubTab: boolean }>`
    display: block;

    @media ${(props) => props.theme.PCM} {
        display: ${({ inSubTab }) => (inSubTab ? "block" : "none")};
        margin-top: 0;
    }
`;

export const MarketItemTrendChartBoxContainer = styled.div<{
    isNoData: boolean;
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(85, 85, 85);
    text-align: center;
    font-size: 14px;
    line-height: 1;
    height: ${({ isNoData }) => (!isNoData ? "228px" : "80px")};
`;

type Props = {
    inSubTab: boolean;
};

const MarketItemTrendChartBox = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const { data: rawItemData } = useItemDataQuery(itemIdx);

    const {
        data: rawMarketItemSettlementList,
        isLoading: isMarketItemSettlementListLoading,
        error: errorMarketItemSettlementList,
    } = useGetMarketSettlementListQuery("item", itemIdx, {
        limit: 20,
        page_no: 1,
    });
    const marketItemSettlementList = spreadSettlementListPages(
        rawMarketItemSettlementList.pages,
    );

    let chartData: ChartTradingViewDataType[] = [];
    try {
        chartData = marketItemSettlementList.map(
            (item: MarketSettlementListItemType, idx: number) => {
                return {
                    time: new Date(item.settlement.settled_at).getTime(),
                    value: item.settled_unit_price,
                };
            },
        );
    } catch (e) {
        //
    }

    // 판매대기, 판매중, 귀속됨, 비공개만 보여줌
    const isTradAble = getIsTradeAvailableItem(
        rawItemData.itemInfo.item_status,
    );

    return (
        <MarketItemTrendChartBoxWrapper inSubTab={props.inSubTab}>
            <CommonTabBox
                iconUrl={"/icon/icon-graph.svg"}
                tabList={[{ title: "가격 추이" }]}
                isGuestAddOpacityEffect={true}
                isOpenSupport={false}
            >
                <ApiResBoundary
                    isLoading={isMarketItemSettlementListLoading}
                    error={errorMarketItemSettlementList}
                    isNoData={chartData.length === 0 || !isTradAble}
                    noDataFallback={
                        <MarketItemTrendChartBoxContainer
                            isNoData={chartData.length === 0}
                        >
                            가격정보가 없습니다.
                        </MarketItemTrendChartBoxContainer>
                    }
                >
                    <MarketItemTrendChartBoxContainer
                        isNoData={chartData.length === 0}
                    >
                        <ChartTradingView
                            title={rawItemData.itemInfo.item_name}
                            data={chartData.reverse()}
                            unit={rawItemData.itemInfo.project_currency.toUpperCase()}
                        />
                    </MarketItemTrendChartBoxContainer>
                </ApiResBoundary>
            </CommonTabBox>
        </MarketItemTrendChartBoxWrapper>
    );
};

export default MarketItemTrendChartBox;
