import { useEffect } from "react";
import {
    ChipType,
    SortingItemType,
} from "../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import {
    COLLECTION_CREATED_TAB,
    COLLECTION_LIKED_TAB,
    COLLECTION_OWNED_TAB,
    COLLECTION_PRIVATE_TAB,
} from "./WalletCollectionHub";
import {
    walletCollectionCreatedItemSortingList,
    walletCollectionGroupItemSortingList,
    walletCollectionLikedItemSortingList,
    walletCollectionOwnedItemSortingList,
} from "./etc/WalletCollectionItemListConsntants";

export function useInitSortingList(
    sortingList: SortingItemType[],
    setSortingList: (value: SortingItemType[]) => void,
    selectedSubTabKey: string,
    groupChips: ChipType[],
    isItemInventoryGroupListSuccess: boolean,
) {
    const initSortingList = (list: SortingItemType[]) => {
        return list.map((item, idx) => {
            return { ...item, isSelected: idx === 0 };
        });
    };

    // 보유 아이템일 경우, 아이템 리스트 > sortingList > 그룹에 영향을 받아서 api call 최소화를 위해
    // sortingList init 을 가장 나중에하고 그 뒤에 item/list api call
    useEffect(() => {
        if (isItemInventoryGroupListSuccess) {
            switch (selectedSubTabKey) {
                case COLLECTION_OWNED_TAB:
                    // 그룹이 선택 되있는 경우
                    if (groupChips.some((group) => group.isSelected)) {
                        if (
                            sortingList.length !==
                            walletCollectionGroupItemSortingList.length
                        ) {
                            setSortingList(
                                walletCollectionGroupItemSortingList.map(
                                    (sortingItem, sortingItemIdx) => {
                                        return {
                                            ...sortingItem,
                                            isSelected: sortingItemIdx === 0,
                                        };
                                    },
                                ),
                            );
                        }
                    }
                    // 그룹이 선택 되있지 않은 경우
                    else {
                        if (
                            sortingList.length !==
                            walletCollectionOwnedItemSortingList.length
                        ) {
                            setSortingList(
                                walletCollectionOwnedItemSortingList.map(
                                    (sortingItem, sortingItemIdx) => {
                                        return {
                                            ...sortingItem,
                                            isSelected: sortingItemIdx === 0,
                                        };
                                    },
                                ),
                            );
                        }
                    }
                    break;

                case COLLECTION_LIKED_TAB:
                    setSortingList(
                        initSortingList(walletCollectionLikedItemSortingList),
                    );
                    break;

                case COLLECTION_CREATED_TAB:
                    setSortingList(
                        initSortingList(walletCollectionCreatedItemSortingList),
                    );
                    break;

                case COLLECTION_PRIVATE_TAB:
                    setSortingList(
                        initSortingList(walletCollectionCreatedItemSortingList),
                    );
                    break;

                default:
                    setSortingList(
                        initSortingList(walletCollectionOwnedItemSortingList),
                    );
            }
        }
    }, [selectedSubTabKey, groupChips]);
}
