import { ReactElement } from "react";
import styled from "styled-components";

export const AdManageCommonHistoryColumnNormalContainer = styled.div`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

type Props = {
    msg: string;
};

const AdManageCommonHistoryColumnNormal = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnNormalContainer>
            {props.msg}
        </AdManageCommonHistoryColumnNormalContainer>
    );
};

export default AdManageCommonHistoryColumnNormal;
