import CssFilterConverter from "css-filter-converter";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";
import { getIsLogin } from "../../../../../../../store/auth/authKeyStore";

export const MarketTargetProductGetMethodContainer = styled(Link)<{
    $isLogin: boolean;
}>`
    width: calc(100% - 32px);
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-radius: 16px;
    background: #f5f5f7;
    align-items: center;
    opacity: ${({ $isLogin }) => ($isLogin ? 1 : 0.3)};
`;
export const MarketTargetProductGetMethodTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const MarketTargetProductGetMethodSymbol = styled.img`
    margin-right: 6px;
`;

export const MarketTargetProductGetMethodTitle = styled.div`
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
`;

export const MarketTargetProductGetMethodGoOutIcon = styled.img``;

type Props = {
    type: string;
};

const MarketTargetProductGetMethod = (props: Props): ReactElement | null => {
    const isLogin = getIsLogin();

    const data =
        props.type === "point"
            ? {
                  symbol_path:
                      URL_CONSTANTS.ASSET_URL + "/icon/icon-point-symbol.svg",
                  title: "포인트 획득 방법",
                  link: "https://about.saybuzz.io/ko/guide/point/gain_method",
              }
            : {
                  symbol_path:
                      URL_CONSTANTS.ASSET_URL + "/icon/icon-token-symbol.svg",
                  title: "토큰 획득 방법",
                  link: "https://about.saybuzz.io/ko/guide/point/merge",
              };
    return (
        <MarketTargetProductGetMethodContainer
            to={data.link}
            target={"_blank"}
            $isLogin={isLogin}
        >
            <MarketTargetProductGetMethodTitleContainer>
                <MarketTargetProductGetMethodSymbol src={data.symbol_path} />
                <MarketTargetProductGetMethodTitle>
                    {data.title}
                </MarketTargetProductGetMethodTitle>
            </MarketTargetProductGetMethodTitleContainer>
            <MarketTargetProductGetMethodGoOutIcon
                style={{
                    filter: CssFilterConverter.hexToFilter("#888").color + "",
                }}
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-go-out.svg`}
            />
        </MarketTargetProductGetMethodContainer>
    );
};

export default MarketTargetProductGetMethod;
