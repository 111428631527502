import { useAdInfoQuery } from "../../../../../../../../query/ad/ad/useAdInfoQuery";
import {
    AD_BID_CLOSED,
    AD_EXPIRED,
    AD_IN_REVIEW,
    AD_NOT_OPENED_YET,
    AD_ON_BID,
    AD_ON_SCHEDULE,
    AD_PREPARING,
    AD_UNKNOWN,
} from "../../../../../../../../types/ad/adDaily/AdDailyListItemType";
import { ReactElement } from "react";
import CalendarCellBackgroundRow from "../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import AdSaleManageCalendarCellPcBodyOnBid from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenOnBid/AdSaleManageCalendarCellPcBodyOnBid";
import AdSaleManageCalendarCellMobileBodyOnBid from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenOnBid/AdSaleManageCalendarCellMobileBodyOnBid";
import AdSaleManageCalendarSelectedMobileCellOnBid from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenOnBid/AdSaleManageCalendarSelectedMobileCellOnBid";
import AdSaleManageCalendarCellPcBodyInReview from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenInReview/AdSaleManageCalendarCellPcBodyInReview";
import AdSaleManageCalendarCellMobileBodyInReview from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenInReview/AdSaleManageCalendarCellMobileBodyInReview";
import AdSaleManageCalendarSelectedMobileCellInReview from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenInReview/AdSaleManageCalendarSelectedMobileCellInReview";
import AdSaleManageCalendarCellPcBodyBidClosedAndPreparing from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenBidClosedAndPreparing/AdSaleManageCalendarCellPcBodyBidClosedAndPreparing";
import AdSaleManageCalendarCellMobileBodyBidClosedAndPreparing from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenBidClosedAndPreparing/AdSaleManageCalendarCellMobileBodyBidClosedAndPreparing";
import AdSaleManageCalendarSelectedMobileCellBidClosedAndPreparing from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenBidClosedAndPreparing/AdSaleManageCalendarSelectedMobileCellBidClosedAndPreparing";
import AdSaleManageCalendarCellPcBodyOnSchedule from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenOnSchedule/AdSaleManageCalendarCellPcBodyOnSchedule";
import AdSaleManageCalendarCellMobileBodyOnSchedule from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenOnSchedule/AdSaleManageCalendarCellMobileBodyOnSchedule";
import AdSaleManageCalendarSelectedMobileCellOnSchedule from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenOnSchedule/AdSaleManageCalendarSelectedMobileCellOnSchedule";
import AdSaleManageCalendarCellPcBodyBidExpired from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenBidExpired/AdSaleManageCalendarCellPcBodyBidExpired";
import AdSaleManageCalendarCellMobileBodyBidExpired from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenBidExpired/AdSaleManageCalendarCellMobileBodyBidExpired";
import AdSaleManageCalendarSelectedMobileCellBidExpired from "./adSaleManageCalendarCellChildrenByCode/adSaleManageCalendarCellChildrenBidExpired/AdSaleManageCalendarSelectedMobileCellBidExpired";
import { useGetAdMyDailyDateData } from "../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import { MyAdDailyListItemType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";

export type AdSaleManageCalendarCellCommonProps = {
    cellDate: Date;
    adIdx: string;
    myAdDailyList: MyAdDailyListItemType[];
};

export function useGetAdSaleManageCalendarCellByCode(
    params: AdSaleManageCalendarCellCommonProps,
) {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(params.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const { targetDateInfo: cellInfo } = useGetAdMyDailyDateData(params);

    let cellPcBody: ReactElement = <></>;
    let cellMobileBody: ReactElement = <></>;
    let cellMobileSelectedCell: ReactElement = <></>;

    switch (cellInfo.ad_daily_status_code) {
        case AD_ON_BID:
            cellPcBody = <AdSaleManageCalendarCellPcBodyOnBid {...params} />;
            cellMobileBody = (
                <AdSaleManageCalendarCellMobileBodyOnBid {...params} />
            );
            cellMobileSelectedCell = (
                <AdSaleManageCalendarSelectedMobileCellOnBid {...params} />
            );
            break;

        case AD_IN_REVIEW:
            cellPcBody = <AdSaleManageCalendarCellPcBodyInReview {...params} />;
            cellMobileBody = (
                <AdSaleManageCalendarCellMobileBodyInReview {...params} />
            );
            cellMobileSelectedCell = (
                <AdSaleManageCalendarSelectedMobileCellInReview {...params} />
            );
            break;

        case AD_PREPARING:
        case AD_BID_CLOSED:
            cellPcBody = (
                <AdSaleManageCalendarCellPcBodyBidClosedAndPreparing
                    {...params}
                />
            );
            cellMobileBody = (
                <AdSaleManageCalendarCellMobileBodyBidClosedAndPreparing
                    {...params}
                />
            );
            cellMobileSelectedCell = (
                <AdSaleManageCalendarSelectedMobileCellBidClosedAndPreparing
                    {...params}
                />
            );
            break;

        case AD_ON_SCHEDULE:
            cellPcBody = (
                <AdSaleManageCalendarCellPcBodyOnSchedule {...params} />
            );
            cellMobileBody = (
                <AdSaleManageCalendarCellMobileBodyOnSchedule {...params} />
            );
            cellMobileSelectedCell = (
                <AdSaleManageCalendarSelectedMobileCellOnSchedule {...params} />
            );
            break;

        case AD_EXPIRED:
            cellPcBody = (
                <AdSaleManageCalendarCellPcBodyBidExpired {...params} />
            );
            cellMobileBody = (
                <AdSaleManageCalendarCellMobileBodyBidExpired {...params} />
            );
            cellMobileSelectedCell = (
                <AdSaleManageCalendarSelectedMobileCellBidExpired {...params} />
            );
            break;

        case AD_UNKNOWN:
            cellPcBody = (
                <CalendarCellBackgroundRow type={"danger"} msg={"판매 중단"} />
            );
            cellMobileBody = <></>;
            cellMobileSelectedCell = <></>;
            break;

        case AD_NOT_OPENED_YET:
            cellMobileSelectedCell = (
                <CalendarCellBackgroundRow
                    type={"secondary"}
                    msg={"입찰 등록 대기중"}
                />
            );
            break;

        default:
            break;
    }

    return {
        cellPcBody,
        cellMobileBody,
        cellMobileSelectedCell,
        adDailyStatusCode: cellInfo.ad_daily_status_code,
    };
}
