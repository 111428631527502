import { ReactElement } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../../../common/commonStyle/CommonButtonStyle";
import { useOpenCollectionGroupAddModal } from "../../../../../../../../../hooks/modal/openModal/useOpenCollectionGroupAddModal";

export const WalletCollectionGroupMoveNoListContainer = styled.div`
    width: 100%;
    height: 252px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
`;

export const WalletCollectionGroupMoveNoListButtonContainer = styled.div``;

export const WalletCollectionGroupMoveNoListButton = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    padding: 13px 16px;
    height: 40px;

    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
`;

type Props = {};

const WalletCollectionGroupMoveNoList = (props: Props): ReactElement | null => {
    const { openCollectionGroupAddModal } = useOpenCollectionGroupAddModal();

    return (
        <WalletCollectionGroupMoveNoListContainer>
            그룹이 없습니다. <br />
            그룹을 생성해주세요.
            <WalletCollectionGroupMoveNoListButtonContainer>
                <WalletCollectionGroupMoveNoListButton
                    btnType={"filled-primary"}
                    onClick={async () => {
                        await openCollectionGroupAddModal();
                    }}
                >
                    그룹 추가
                </WalletCollectionGroupMoveNoListButton>
            </WalletCollectionGroupMoveNoListButtonContainer>
        </WalletCollectionGroupMoveNoListContainer>
    );
};

export default WalletCollectionGroupMoveNoList;
