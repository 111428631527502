import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "./usePrevious";
import ModalComponentInfoListAtom from "../../store/ui/ModalComponentInfoListAtom";
import { useAtom } from "jotai";

export function useLocationChangeEffect(effect: (prevLocation?: any) => void) {
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const prevLocation = usePrevious(window.history.state.key);
    const location = useLocation();

    useEffect(() => {
        // 0이 아닌 경우는 modal 열고 닫음(pushState, popState)으로 인한 location change.
        if (modalComponentInfoList.length === 0) {
            effect(prevLocation);
        }
    }, [location]);
}
