import { ReactElement } from "react";
import styled from "styled-components";
import WalletCollectionItemThreeDot from "../../../../../../../../walletCollectionItemListItem/components/WalletCollectionItemThreeDot";
import { ItemListItemType } from "../../../../../../../../../../../../query/item/useItemListQuery";
import { TitleItemListItemType } from "../../../../../../../../../../../../query/item/titleItem/useTitleItemListQuery";
import CollectionCommonStateAtom from "../../../../../../../../../../../../store/commonState/CollectionCommonStateAtom";
import { queryClient } from "../../../../../../../../../../../../App";
import { useAtom } from "jotai";

export const WalletCollectionHubListTypeItemThreeDotBtnContainer = styled.div``;

type Props = {
    userIdx: string;
    item: ItemListItemType | TitleItemListItemType | any; // 앞에 두개인데 value 가 달라서 any 로 열어놓긴한다
    selectedSubKey: string;
};

const WalletCollectionHubListTypeItemThreeDotBtn = (
    props: Props,
): ReactElement | null => {
    const [collectionCommonState] = useAtom(CollectionCommonStateAtom);
    // collection 은 따로 refetch
    const refetchItemListTitleItemListQuery = async () => {
        await queryClient.refetchQueries({
            queryKey: collectionCommonState.collectionItemListQueryKey,
            refetchPage: () => true,
        });
        await queryClient.refetchQueries({
            queryKey: "title-item-list-" + props.userIdx,
            refetchPage: () => true,
        });
    };

    return (
        <WalletCollectionItemThreeDot
            isPositionStatic={true}
            userIdx={props.userIdx}
            item={props.item}
            type={props.selectedSubKey}
            refetchItemListTitleItemListQuery={
                refetchItemListTitleItemListQuery
            }
        />
    );
};

export default WalletCollectionHubListTypeItemThreeDotBtn;
