import { useQuery } from "react-query";
import { GlobalSearchApi } from "../../apis/GlobalSearchApi";
import { GlobalSearchResultType } from "../../types/GlobalSearchResultDataType";
import { GlobalSearchResultTabIDsType } from "../../constants/menus/headerMenus";

/**
 * 2023/09/14 통합검색 관련 쿼리
 */

export type TGlobalSearchQueryParams = {
    keyword: string;
};
export type TGlobalSearchInternalQueryParams = TGlobalSearchQueryParams & {
    tab: GlobalSearchResultTabIDsType;
    isEnabled: boolean;
};

export const globalSearchResultDefaultObject: GlobalSearchResultType = {
    adProductsList: [],
    collectorsList: [],
    itemsList: [],
    projectsList: [],
};

export const getGlobalSearchQuery = (
    params: TGlobalSearchInternalQueryParams,
) => {
    const queryResult = useQuery<GlobalSearchResultType, Error>({
        queryKey: ["search-" + JSON.stringify(params)],
        queryFn: () => GlobalSearchApi.search(params),
        onError: () => {},
        enabled: params.isEnabled,
    });
    return {
        ...queryResult,
        data:
            (queryResult.data as GlobalSearchResultType) ||
            globalSearchResultDefaultObject,
    };
};

export default getGlobalSearchQuery;
