import { ReactElement } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../../../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalTitle,
} from "../../../../../../../common/commonUnitComponent/modal/ModalStyle";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";
import CssFilterConverter from "css-filter-converter";
import { MyAdOfferInfoType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoType";
import { numberToStringWithComma } from "../../../../../../../../utils/Utils";

export const AdSaleManageAcceptOfferSuccessModalCheckedIcon = styled.img`
    width: 60px;
    height: 60px;
`;

export const AdSaleManageAcceptOfferSuccessModalInfoContainer = styled.div`
    display: flex;
    padding: 16px;
    width: 260px;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    border-radius: 10px;
    background: #f5f5f7;
    margin-top: 24px;
`;

export const AdSaleManageAcceptOfferSuccessModalInfoRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const AdSaleManageAcceptOfferSuccessModalInfoRowKey = styled.div`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdSaleManageAcceptOfferSuccessModalInfoRowValue = styled.div`
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
`;

type Props = {
    resolve: (value: boolean) => void;
    selectedOfferInfo: MyAdOfferInfoType;
};

const AdSaleManageAcceptOfferSuccessModal = (
    props: Props,
): ReactElement | null => {
    return (
        <ModalContainer isNoPadding={false}>
            <AdSaleManageAcceptOfferSuccessModalCheckedIcon
                src={
                    URL_CONSTANTS.ASSET_URL + "/icon/icon-checked-in-circle.svg"
                }
                style={{
                    filter:
                        CssFilterConverter.hexToFilter("#0CD28B").color + "",
                }}
            />
            <ModalCloseIcon resolve={props.resolve} />

            <ModalTitle>낙찰 성공</ModalTitle>
            <AdSaleManageAcceptOfferSuccessModalInfoContainer>
                <AdSaleManageAcceptOfferSuccessModalInfoRow>
                    <AdSaleManageAcceptOfferSuccessModalInfoRowKey>
                        낙찰자
                    </AdSaleManageAcceptOfferSuccessModalInfoRowKey>
                    <AdSaleManageAcceptOfferSuccessModalInfoRowValue>
                        {props.selectedOfferInfo.offerInfo.user_nick}
                    </AdSaleManageAcceptOfferSuccessModalInfoRowValue>
                </AdSaleManageAcceptOfferSuccessModalInfoRow>
                <AdSaleManageAcceptOfferSuccessModalInfoRow>
                    <AdSaleManageAcceptOfferSuccessModalInfoRowKey>
                        낙찰가격
                    </AdSaleManageAcceptOfferSuccessModalInfoRowKey>
                    <AdSaleManageAcceptOfferSuccessModalInfoRowValue>
                        {numberToStringWithComma(
                            props.selectedOfferInfo.offerInfo.offer_price,
                        )}
                    </AdSaleManageAcceptOfferSuccessModalInfoRowValue>
                </AdSaleManageAcceptOfferSuccessModalInfoRow>
                <AdSaleManageAcceptOfferSuccessModalInfoRow>
                    <AdSaleManageAcceptOfferSuccessModalInfoRowKey>
                        광고 진행 예정 일시
                    </AdSaleManageAcceptOfferSuccessModalInfoRowKey>
                    <AdSaleManageAcceptOfferSuccessModalInfoRowValue>
                        {
                            props.selectedOfferInfo.advertisementDailyInfo
                                .ad_sales_date
                        }{" "}
                        00:00
                    </AdSaleManageAcceptOfferSuccessModalInfoRowValue>
                </AdSaleManageAcceptOfferSuccessModalInfoRow>
            </AdSaleManageAcceptOfferSuccessModalInfoContainer>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-primary"}
                    onClick={() => {
                        props.resolve(true);
                    }}
                >
                    확인
                </ModalButton>
            </ModalButtonContainer>
        </ModalContainer>
    );
};

export default AdSaleManageAcceptOfferSuccessModal;
