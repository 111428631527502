import styled from "styled-components";
import { ItemInventoryGroupListItemType } from "../../../../../../../../../types/itemInventory/ItemInventoryGroupListItemType";
import DndListItemTrigger from "../../../../../../../../common/commonUnitComponent/dndList/DndListItemTrigger";
import { URL_CONSTANTS } from "../../../../../../../../../constants/UrlConstants";
import { useDeleteItemInventoryGroupMutation } from "../../../../../../../../../query/itemInventory/useDeleteItemInventoryGroupMutation";
import { useOpenCommonConfirmModal } from "../../../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { useOpenCollectionGroupTitleAdjustModal } from "../../../../../../../../../hooks/modal/openModal/useOpenCollectionGroupTitleAdjustModal";

export const WalletCollectionGroupEditListItemContainer = styled.div<{
    isItemMoving: boolean;
}>`
    display: flex;
    height: 52px;
    padding: 0 10px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 8px;
    background: #f5f5f7;
    width: calc(100% - 20px);

    ${({ isItemMoving }) => {
        if (isItemMoving) {
            return `
                border: 1px solid #FA54FA;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
                `;
        }
    }};
    @media ${(props) => props.theme.mobileL} {
        gap: 6px;
    }
`;

export const WalletCollectionGroupEditListItemLeftContainer = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 64px);
`;

export const WalletCollectionGroupEditListItemMoveIcon = styled.img`
    margin-right: 10px;
    cursor: pointer;
`;

export const WalletCollectionGroupEditListItemLinkInputDefaultValueContainer = styled.div`
    display: inline-flex;
    align-items: center;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 15px */

    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */

    transition: background-color 300ms ease;
    border-radius: 8px;
`;

export const WalletCollectionGroupEditListItemRightContainer = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
`;

export const WalletCollectionGroupEditListItemFunctionIconContainer = styled.div`
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #f0f0f2;
        }
    }

    transition: background-color 300ms ease;
`;

export const WalletCollectionGroupEditListItemEditIcon = styled.img``;

export const WalletCollectionGroupEditListItemDeleteIcon = styled.img``;

type Props = {
    groupInfo: ItemInventoryGroupListItemType;
    isItemMoving: boolean;
};

const WalletCollectionGroupEditListItem = (props: Props): JSX.Element => {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { openCollectionGroupTitleAdjustModal } =
        useOpenCollectionGroupTitleAdjustModal(props.groupInfo);

    const { mutateAsync: deleteGroup } = useDeleteItemInventoryGroupMutation(
        props.groupInfo.item_inventory_group_idx,
    );

    return (
        <WalletCollectionGroupEditListItemContainer
            isItemMoving={props.isItemMoving}
        >
            <WalletCollectionGroupEditListItemLeftContainer>
                <DndListItemTrigger
                    renderTrigger={() => (
                        <WalletCollectionGroupEditListItemMoveIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-list-move.svg`}
                        />
                    )}
                />

                <WalletCollectionGroupEditListItemLinkInputDefaultValueContainer>
                    {props.groupInfo.item_inventory_group_name}
                </WalletCollectionGroupEditListItemLinkInputDefaultValueContainer>
            </WalletCollectionGroupEditListItemLeftContainer>
            <WalletCollectionGroupEditListItemRightContainer>
                <WalletCollectionGroupEditListItemFunctionIconContainer
                    onClick={async () => {
                        await openCollectionGroupTitleAdjustModal();
                    }}
                >
                    <WalletCollectionGroupEditListItemEditIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-edit.svg`}
                    />
                </WalletCollectionGroupEditListItemFunctionIconContainer>

                <WalletCollectionGroupEditListItemFunctionIconContainer
                    onClick={async () => {
                        const confirmResult = await openCommonConfirmModal({
                            title: "그룹 삭제",
                            desc: "선택한 그룹을 정말 삭제하시겠습니까? 삭제 후 그룹에 속해있지 않은 아이템들은 '미분류'로 편성됩니다.",
                        });

                        if (confirmResult) {
                            await deleteGroup();
                        }
                    }}
                >
                    <WalletCollectionGroupEditListItemDeleteIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-delete.svg`}
                    />
                </WalletCollectionGroupEditListItemFunctionIconContainer>
            </WalletCollectionGroupEditListItemRightContainer>
        </WalletCollectionGroupEditListItemContainer>
    );
};

export default WalletCollectionGroupEditListItem;
