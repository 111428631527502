import { ReactElement } from "react";
import styled from "styled-components";
import { TemplateInfoSpaceType } from "../../../../types/ad/templateInfo/TemplateInfoSpaceType";

export const TemplatePreviewContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TemplatePreviewBackgroundImgContainer = styled.div`
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TemplatePreviewBackgroundImg = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

export const TemplatePreviewImg = styled.img`
    position: absolute;
`;

export const TemplatePreviewText = styled.div`
    position: absolute;
`;

type Props = {
    templateSpace?: TemplateInfoSpaceType[];
    imgPath?: string;
};

const TemplatePreview = (props: Props): ReactElement | null => {
    return (
        <TemplatePreviewContainer>
            <TemplatePreviewBackgroundImgContainer>
                <TemplatePreviewBackgroundImg src={props.imgPath} />
                {/*{props.templateSpace.templateSpaceElement.map(*/}
                {/*    (spaceElement, spaceElementIdx) => {*/}
                {/*        if (*/}
                {/*            spaceElement.presv_campaign_space_element_type ===*/}
                {/*            "0"*/}
                {/*        ) {*/}
                {/*            return (*/}
                {/*                <TemplatePreviewText*/}
                {/*                    key={spaceElementIdx}*/}
                {/*                    style={*/}
                {/*                        spaceElement.presv_campaign_space_element_preview_offset*/}
                {/*                    }*/}
                {/*                >*/}
                {/*                    {spaceElement.template_space_element_text}*/}
                {/*                </TemplatePreviewText>*/}
                {/*            );*/}
                {/*        } else {*/}
                {/*            return (*/}
                {/*                <TemplatePreviewImg*/}
                {/*                    key={spaceElementIdx}*/}
                {/*                    style={*/}
                {/*                        spaceElement.presv_campaign_space_element_preview_offset*/}
                {/*                    }*/}
                {/*                    src={*/}
                {/*                        spaceElement.template_space_element_img_full_path*/}
                {/*                    }*/}
                {/*                />*/}
                {/*            );*/}
                {/*        }*/}
                {/*    },*/}
                {/*)}*/}
            </TemplatePreviewBackgroundImgContainer>
        </TemplatePreviewContainer>
    );
};

export default TemplatePreview;
