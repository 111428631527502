import React, { ReactElement } from "react";
import styled from "styled-components";
import Carousel, {
    RenderComponentDataType,
} from "../../../commonUnitComponent/carousel/Carousel";
import { ResponsiveWidth } from "../../../../../styles/Theme";
import MiniProfileModalItemListItem from "./MiniProfileModalItemListItem";
import { useWindowSize } from "usehooks-ts";
import { UserProfileType } from "../../../../../query/user/useGetUserProfileQuery";
import {
    MiniProfileModalTitleItemContainer,
    MiniProfileModalUserItemCarouselArrowButton,
} from "../MiniProfileModal";

export const MiniProfileModalTitleItemListContainer = styled.div``;

type Props = {
    data: UserProfileType;
};

const MiniProfileModalTitleItemList = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();

    return (
        <MiniProfileModalTitleItemListContainer>
            <MiniProfileModalTitleItemContainer>
                <Carousel
                    dataList={
                        props.data.titleList.itemList.length >= 3
                            ? props.data.titleList.itemList
                            : // 더미 데이터 생성
                              (width !== 0 && width <= ResponsiveWidth.mobileL
                                  ? [true, true, true, true]
                                  : [true, true, true]
                              ).map((isDummy, idx) => {
                                  if (
                                      idx < props.data.titleList.itemList.length
                                  )
                                      return {
                                          ...props.data.titleList.itemList[idx],
                                      };
                                  else
                                      return {
                                          ...props.data.titleList.itemList[0],
                                          isDummy: true,
                                      };
                              })
                    }
                    displayLength={3}
                    responsiveDisplayLength={[3, 3, 3, 3, 3.5, 3.5]}
                    isCenterMode={false}
                    isInfinite={true}
                    isArrowHoverEvent={true}
                    isAuto={false}
                    isWindowTransparent={false}
                    renderComponent={(data: RenderComponentDataType) => (
                        <MiniProfileModalItemListItem
                            data={data.listItem}
                            key={data.idx}
                        />
                    )}
                    renderArrow={(data) => (
                        <MiniProfileModalUserItemCarouselArrowButton
                            direction={data.type}
                            key={data.idx}
                        />
                    )}
                />
            </MiniProfileModalTitleItemContainer>
        </MiniProfileModalTitleItemListContainer>
    );
};

export default MiniProfileModalTitleItemList;
