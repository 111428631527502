import { ReactElement, useState } from "react";
import {
    OrderTabButton,
    OrderTabContainer,
    OrderTabRowList,
    OrderTabSubRowList,
    OrderTabUnderLine,
    OrderTabWarningRow,
} from "../components/OrderTabStyle";
import OrderTabRow from "../components/OrderTabRow";
import {
    getRoyalty,
    numberToStringWithComma,
} from "../../../../../utils/Utils";
import OrderTypeDropDownButton, {
    OrderSellMoreItemType,
} from "../components/OrderTypeDropDownButton";
import OrderCountInput from "../components/OrderCountInput";
import OrderCountPercentRow from "../components/OrderCountPercentRow";
import OrderTabSubRow from "../components/OrderTabSubRow";
import {
    OrderSellBoxRowRightCountValueContainer,
    OrderSellBoxRowRightCountValueUnit,
} from "../orderPoint/PointOrderSellTab";
import { useProjectDataQuery } from "../../../../../query/project/useProjectDataQuery";
import { useItemDataQuery } from "../../../../../query/item/useItemDataQuery";
import { useParams } from "react-router-dom";
import { useGetOwnItemCnt } from "../../../../../query/item/useGetOwnItemCnt";
import { useIsMe } from "../../../../../hooks/common/useIsMe";
import { useOpenModal } from "../../../../../hooks/modal/useOpenModal";
import ItemSellModal from "./itemOrderModal/ItemSellModal";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { useOpenCommonErrorModal } from "../../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import styled from "styled-components";
import { useGetMyRemainingPointListQuery } from "../../../../../query/my/myPoint/useGetMyRemainingPointListQuery";

export const ItemOrderSellTabCountInput = styled.input<{
    isWarning: boolean;
}>`
    padding: 16px 10px;
    width: 64px;
    height: 8px;
    background: #ffffff;
    border: ${({ isWarning }) =>
        isWarning ? "1px solid #FF4671" : "1px solid #d9d9d9"};
    border-radius: 8px;
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    transition: all 300ms ease;

    &:focus {
        outline: none;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const DefaultItemSellPeriodList: OrderSellMoreItemType[] = [
    {
        key: "1d",
        value: "1일",
        isSelected: false,
    },
    {
        key: "7d",
        value: "7일",
        isSelected: false,
    },
    {
        key: "15d",
        value: "15일",
        isSelected: true,
    },
    {
        key: "30d",
        value: "30일",
        isSelected: false,
    },
];

const DefaultItemSellTypeList: OrderSellMoreItemType[] = [
    {
        key: "fixed",
        value: "지정가",
        isSelected: true,
    },
];

type Props = {
    displayType: string;
};

const ItemOrderSellTab = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const projectIdx = params.project_id ? params.project_id : "0";
    const isMe = useIsMe();
    const isLogin = getIsLogin();
    const [sellPeriodList, setSellPeriodList] = useState<
        OrderSellMoreItemType[]
    >(
        props.displayType !== "1"
            ? DefaultItemSellPeriodList
            : [
                  ...DefaultItemSellPeriodList,
                  { key: "infinite", value: "무기한", isSelected: false },
              ],
    );
    const { openModal } = useOpenModal();
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const openItemSellModal = async () => {
        return await openModal("item-sell-modal", "bottomSheet", (resolve) => {
            return (
                <ItemSellModal
                    resolve={resolve}
                    selectedSellPeriod={
                        sellPeriodList.find((period) => period.isSelected) || {
                            key: "1d",
                            value: "1일",
                            isSelected: true,
                        }
                    }
                    count={count}
                    itemPricePerPiece={itemPricePerPiece}
                    itemIdx={itemIdx}
                    projectIdx={projectIdx}
                />
            );
        });
    };

    const [count, setCount] = useState(1);
    const { data: rawProjectData } = useProjectDataQuery(projectIdx);
    const { data: rawItemData } = useItemDataQuery(itemIdx);
    const { data: rawOwnItemCnt } = useGetOwnItemCnt(itemIdx);
    const { myPoint, isSuccess: isMyPointSuccess } =
        useGetMyRemainingPointListQuery(isLogin);

    const [sellTypeList, setSellTypeList] = useState<OrderSellMoreItemType[]>(
        DefaultItemSellTypeList,
    );
    const [itemPricePerPiece, setItemPricePerPiece] = useState(0);

    const sellCountPercentList = [10, 25, 50, 100];
    const itemPriceAmount = itemPricePerPiece * count;

    const brandRoyaltyAmount = getRoyalty(
        rawProjectData.brand_royalty / 100,
        itemPricePerPiece,
        count,
    );

    const creatorRoyaltyAmount = getRoyalty(
        rawProjectData.creator_royalty / 100,
        itemPricePerPiece,
        count,
    );

    const isInsufficientRp =
        myPoint.rp.remaining < brandRoyaltyAmount + creatorRoyaltyAmount;

    return (
        <OrderTabContainer>
            {props.displayType !== "1" && (
                <OrderTabRow
                    rowKey={"판매 가능 수량"}
                    rowValue={numberToStringWithComma(
                        rawOwnItemCnt.own_item_cnt,
                    )}
                    rowValueUnit={"개"}
                />
            )}

            <OrderTabRow
                rowKey={"등록 기간"}
                rowValue={
                    <OrderTypeDropDownButton
                        moreItemList={sellPeriodList}
                        setMoreItemList={setSellPeriodList}
                    />
                }
            />

            {props.displayType !== "1" && (
                <OrderTabRow
                    rowKey={"판매 수량"}
                    rowValue={
                        <OrderCountInput
                            count={count}
                            setCount={setCount}
                            maxCount={rawOwnItemCnt.own_item_cnt}
                        />
                    }
                />
            )}

            {/*[10, 25, 50, 100]*/}
            {props.displayType !== "1" && (
                <OrderCountPercentRow
                    percentList={sellCountPercentList}
                    available_order={rawOwnItemCnt.own_item_cnt}
                    setCount={setCount}
                />
            )}

            <OrderTabRow
                rowKey={"판매 단가"}
                rowValue={
                    <OrderSellBoxRowRightCountValueContainer>
                        {/*타입(지정가) 선택창*/}
                        <OrderTypeDropDownButton
                            moreItemList={sellTypeList}
                            setMoreItemList={setSellTypeList}
                        />
                        {/*개당 가격 input*/}
                        <ItemOrderSellTabCountInput
                            isWarning={isInsufficientRp}
                            type={"number"}
                            value={itemPricePerPiece.toString()}
                            onChange={(e) => {
                                const perPrice = Number(e.target.value);
                                if (perPrice >= 100000000) {
                                    setItemPricePerPiece(100000000);
                                } else {
                                    setItemPricePerPiece(
                                        perPrice > 0
                                            ? Number(
                                                  Math.round(perPrice * 100) /
                                                      100,
                                              )
                                            : 0,
                                    );
                                }
                            }}
                        />
                        <OrderSellBoxRowRightCountValueUnit>
                            {rawProjectData.project_currency}
                        </OrderSellBoxRowRightCountValueUnit>
                    </OrderSellBoxRowRightCountValueContainer>
                }
            />

            <OrderTabUnderLine />

            {props.displayType !== "1" && (
                <OrderTabRowList>
                    <OrderTabRow
                        rowKey={"판매 로열티"}
                        tooltipMsg={"로열티는 브랜드별로 상이합니다."}
                        rowValue={numberToStringWithComma(
                            brandRoyaltyAmount + creatorRoyaltyAmount,
                        )}
                        rowValueUnit={"RP"}
                        isPrice={true}
                    />

                    <OrderTabSubRowList>
                        <OrderTabSubRow
                            rowKey={`${rawProjectData.brand_name} ${rawItemData.itemInfo.brand_royalty}%`}
                            rowValue={numberToStringWithComma(
                                brandRoyaltyAmount,
                            )}
                            rowValueUnit={"RP"}
                        />
                        <OrderTabSubRow
                            rowKey={`크리에이터 ${rawItemData.itemInfo.creator_royalty}%`}
                            rowValue={numberToStringWithComma(
                                creatorRoyaltyAmount,
                            )}
                            rowValueUnit={"RP"}
                        />
                        {isInsufficientRp && (
                            <OrderTabWarningRow>
                                보유한 RP가 부족합니다.
                            </OrderTabWarningRow>
                        )}
                    </OrderTabSubRowList>
                </OrderTabRowList>
            )}

            {props.displayType !== "1" && <OrderTabUnderLine />}

            {props.displayType !== "1" && (
                <OrderTabRow
                    rowKey={"판매 수익"}
                    rowValue={numberToStringWithComma(itemPriceAmount)}
                    rowValueUnit={rawProjectData.project_currency}
                    isPrice={true}
                />
            )}

            <OrderTabButton
                isDisabled={isInsufficientRp}
                onClick={async () => {
                    if (isLogin) {
                        if (count === 0) {
                            await openCommonErrorModal({
                                title: "주문 불가능한 수량 입니다.",
                            });
                            return;
                        }
                        if (itemPricePerPiece === 0) {
                            await openCommonErrorModal({
                                title: "주문 불가능한 가격 입니다.",
                            });
                            return;
                        }

                        await openItemSellModal();
                    } else {
                        await openLoginConfirmModal();
                    }
                }}
            >
                판매 등록
            </OrderTabButton>
        </OrderTabContainer>
    );
};

export default ItemOrderSellTab;
