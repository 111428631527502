import { useScrollEvents } from "../../../../../../hooks/ui/scroll/useScrollEvents";
import { RefObject, useCallback, useRef, useState } from "react";
import { useSetAtom } from "jotai/index";
import isScrollUpAtom from "../../../../../../store/scroll/isScrollUpAtom";
import fullScrollTopAtom from "../../../../../../store/scroll/fullScrollTopAtom";
import IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM from "../../../../../../store/scroll/IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM";
import fullScrollTopPxAtom from "../../../../../../store/scroll/fullScrollTopPxAtom";
import isFullScrollOverHasLnbInSubHeaderAtom from "../../../../../../store/scroll/isFullScrollOverHasLnbInSubHeaderAtom";
import isFullScrollOverNoLnbInSubHeaderAtom from "../../../../../../store/scroll/isFullScrollOverNoLnbInSubHeaderAtom";
import { infiniteScrollCallbackType } from "./CustomScrollbar";
import { setSessionStorageItem } from "../../../../../../utils/Utils";
import { useLocation } from "react-router-dom";

export const useSetCustomScrollEvent = (
    wrapperRef: RefObject<HTMLElement>,
    windowRef: RefObject<HTMLElement>,
    setIsRootScrolling: (value: boolean) => void,
    setIsScrolling: (value: boolean) => void,
    setScrollTop: (value: number) => void,
    isRootType: boolean,
    isDoubleSideBarLayoutContentsType: boolean,
    setIsLoadingInfiniteCallback: (value: boolean) => void,
    id: string,
    infiniteScrollCallback?: infiniteScrollCallbackType,
    isSaveScrollTop?: boolean,
) => {
    const location = useLocation();
    const setIsScrollUp = useSetAtom(isScrollUpAtom);
    const [preScrollTop, _setPreScrollTop] = useState(0);
    const preScrollTopRef = useRef(preScrollTop);
    const setPreScrollTop = (data: number) => {
        preScrollTopRef.current = data;
        _setPreScrollTop(data);
    };

    const setFullScrollTop = useSetAtom(fullScrollTopAtom);
    const setIsFullScrollOver = useSetAtom(
        IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM,
    );
    const setFullScrollTopPx = useSetAtom(fullScrollTopPxAtom);
    const setIsFullScrollOverHasLnbInSubHeader = useSetAtom(
        isFullScrollOverHasLnbInSubHeaderAtom,
    );
    const setIsFullScrollOverNoLnbInSubHeaderAtom = useSetAtom(
        isFullScrollOverNoLnbInSubHeaderAtom,
    );

    const [infiniteCallbackTimeout, setInfiniteCallbackTimeout] =
        useState<any>(null);

    const setScrollTopInSessionStorage = useCallback(
        (scrollTopPx: number) => {
            setSessionStorageItem(
                `path-${window.location.pathname}-id-${id}`,
                scrollTopPx,
            );
        },
        [location, id],
    );

    /**
     * 스크롤이 될 때 걸리는 이벤트
     * handleScroll 내부에서 플래그 사용해서 체크하려면 jotai, state 모두 값 변화가 안됌..
     * 어쩔수없이 window.flag 로 처리
     * scroll 방향, scrolling, preScroll 값 세팅
     */
    const handleScroll = useCallback(
        (e: any) => {
            if (wrapperRef.current && windowRef.current) {
                setIsScrolling(true);
                if (isRootType || isDoubleSideBarLayoutContentsType) {
                    setIsRootScrolling(true);
                }
                const scrollTopPx = windowRef.current?.scrollTop || 0;
                const wrapperHeightPx = wrapperRef.current?.offsetHeight || 0;
                const windowHeightPx = windowRef.current?.offsetHeight || 0;

                const willScrollTop =
                    Math.round((scrollTopPx / wrapperHeightPx) * 1000000) /
                    10000;

                if (!isNaN(willScrollTop)) {
                    setScrollTop(willScrollTop);

                    // type 이 double-side-contents 인경우는 double-side-contents 가 root type 임
                    // root type scroll 시 header 상태를 위한 값 지정
                    if (isRootType || isDoubleSideBarLayoutContentsType) {
                        setFullScrollTop(willScrollTop);
                        setIsScrollUp(preScrollTopRef.current > willScrollTop);
                        setIsFullScrollOver(
                            location.pathname === "/"
                                ? willScrollTop > 0.01
                                : willScrollTop > 5,
                        );
                        setFullScrollTopPx(scrollTopPx);
                        setIsFullScrollOverHasLnbInSubHeader(scrollTopPx < 46);
                        setIsFullScrollOverNoLnbInSubHeaderAtom(
                            scrollTopPx >= 240,
                        );
                    }

                    // 이전 페이지 이동시 스크롤 유지를 위한 scrollTop 저장
                    if (isSaveScrollTop) {
                        setScrollTopInSessionStorage(scrollTopPx);
                    }

                    // 무한 스크롤 콜백 풀스크롤 막고 + 로딩 걸고 + 무한 스크롤 콜백 부르기
                    if (typeof infiniteScrollCallback !== "undefined") {
                        if (
                            wrapperHeightPx > windowHeightPx &&
                            scrollTopPx >= wrapperHeightPx - windowHeightPx - 3
                        ) {
                            if (infiniteCallbackTimeout !== null) {
                                clearTimeout(infiniteCallbackTimeout);
                            }

                            setInfiniteCallbackTimeout(
                                setTimeout(async () => {
                                    if (
                                        typeof infiniteScrollCallback !==
                                        "undefined"
                                    ) {
                                        await infiniteScrollCallback(() => {
                                            setIsLoadingInfiniteCallback(true);
                                        });
                                        setIsScrolling(true);
                                        if (
                                            isRootType ||
                                            isDoubleSideBarLayoutContentsType
                                        ) {
                                            setIsRootScrolling(true);
                                        }
                                        setInfiniteCallbackTimeout(null);
                                        setTimeout(() => {
                                            setIsLoadingInfiniteCallback(false);
                                            setIsRootScrolling(false);
                                            setIsScrolling(false);
                                        }, 300);
                                    }
                                }, 150),
                            );
                        }
                    }

                    setPreScrollTop(willScrollTop);
                }
            }
        },
        [location],
    );
    // 스크롤이 종료될 때 걸리는 이벤트
    const handleScrollEnd = () => {
        setIsRootScrolling(false);
        setIsScrolling(false);
    };

    useScrollEvents(windowRef, handleScroll, handleScrollEnd, 300);
};
