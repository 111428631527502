import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import {
    OrderTabButton,
    OrderTabContainer,
    OrderTabRowList,
    OrderTabUnderLine,
    OrderTabWarningRow,
} from "../components/OrderTabStyle";
import {
    getRoyalty,
    numberToStringWithComma,
} from "../../../../../utils/Utils";
import OrderTypeDropDownButton, {
    OrderSellMoreItemType,
} from "../components/OrderTypeDropDownButton";
import OrderCountInput from "../components/OrderCountInput";
import OrderTabRow from "../components/OrderTabRow";
import OrderCountPercentRow from "../components/OrderCountPercentRow";
import { useOpenModal } from "../../../../../hooks/modal/useOpenModal";
import PointSellModal from "./pointOrderModal/PointSellModal";
import { usePointDataQuery } from "../../../../../query/point/usePointDataQuery";
import { useParams } from "react-router-dom";
import {
    DefaultMyPointListItem,
    POINT_TYPE,
    useGetMyRemainingPointListQuery,
} from "../../../../../query/my/myPoint/useGetMyRemainingPointListQuery";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { useOpenCommonErrorModal } from "../../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { PointOrderBuyTabKeyDesc } from "./PointOrderBuyTab";

export const PointOrderSellTabCountInput = styled.input<{
    isWarning: boolean;
}>`
    padding: 16px 10px;
    width: 116px;
    height: 8px;
    background: #ffffff;
    border: ${({ isWarning }) =>
        isWarning ? "1px solid #FF4671" : "1px solid #d9d9d9"};
    border-radius: 8px;
    text-align: right;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;

    &:focus {
        outline: none;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const OrderSellBoxRowRightCountValueContainer = styled.div`
    display: flex;
    gap: 6px;
    align-items: flex-end;
`;

export const OrderSellBoxRowRightCountValueUnit = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    align-items: center;

    color: #888888;
`;

const DefaultPointSellPeriodList: OrderSellMoreItemType[] = [
    {
        key: "1d",
        value: "1일",
        isSelected: false,
    },
    {
        key: "7d",
        value: "7일",
        isSelected: false,
    },
    {
        key: "15d",
        value: "15일",
        isSelected: true,
    },
    {
        key: "30d",
        value: "30일",
        isSelected: false,
    },
];

const DefaultPointSellTypeList: OrderSellMoreItemType[] = [
    {
        key: "fixed",
        value: "지정가",
        isSelected: true,
    },
];

type Props = {};

const PointOrderSellTab = (props: Props): ReactElement | null => {
    const params = useParams();
    const pointIdx = params.point_id ? params.point_id : "0";
    const isLogin = getIsLogin();
    const [count, setCount] = useState(1);
    const [pointPricePerPiece, setPointPricePerPiece] = useState(1);
    const [sellPeriodList, setSellPeriodList] = useState<
        OrderSellMoreItemType[]
    >(DefaultPointSellPeriodList);

    const [sellTypeList, setSellTypeList] = useState<OrderSellMoreItemType[]>(
        DefaultPointSellTypeList,
    );

    const sellPercentList = [10, 25, 50, 100];

    const { data: rawPointData } = usePointDataQuery(pointIdx);

    const {
        myPoint,
        data: rawMyPointList,
        isLoading: isMyPointListLoading,
        error: errorMyPointList,
    } = useGetMyRemainingPointListQuery();

    const { openModal } = useOpenModal();
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const openPointSellModal = async () => {
        return await openModal("point-sell-modal", "bottomSheet", (resolve) => {
            return (
                <PointSellModal
                    resolve={resolve}
                    sellPeriodList={sellPeriodList}
                    count={count}
                    pointSellBrandRoyalty={pointSellBrandRoyalty}
                    pointPricePerePiece={pointPricePerPiece}
                    pointIdx={pointIdx}
                />
            );
        });
    };

    const availableOrder = (
        rawMyPointList.find(
            (point) =>
                point.point_type ===
                POINT_TYPE[rawPointData.pointInfo.point_symbol],
        ) ?? DefaultMyPointListItem
    ).remaining;

    const pointSellBrandRoyalty = 0.9;
    const pointPriceAmount = pointPricePerPiece * count;
    const sellBrandRoyaltyAmount = getRoyalty(
        pointSellBrandRoyalty / 100,
        pointPricePerPiece,
        count,
    );

    const isInsufficientRp = myPoint.rp.remaining < sellBrandRoyaltyAmount;

    return (
        <OrderTabContainer>
            <OrderTabRow
                rowKey={"판매 가능 " + rawPointData.pointInfo.point_symbol}
                rowValue={numberToStringWithComma(availableOrder)}
                rowValueUnit={rawPointData.pointInfo.point_symbol}
            />

            <OrderTabRow
                rowKey={"등록 기간"}
                rowValue={
                    <OrderTypeDropDownButton
                        moreItemList={sellPeriodList}
                        setMoreItemList={setSellPeriodList}
                    />
                }
            />

            <OrderTabRow
                rowKey={"판매수량"}
                rowValue={
                    <OrderCountInput
                        count={count}
                        setCount={setCount}
                        maxCount={Math.floor(availableOrder)}
                    />
                }
            />

            {/*[10, 25, 50, 100]*/}
            <OrderCountPercentRow
                percentList={sellPercentList}
                available_order={availableOrder}
                setCount={setCount}
            />

            <OrderTabRow
                isWrap={true}
                rowKey={"개당 판매 가격"}
                rowValue={
                    <OrderSellBoxRowRightCountValueContainer>
                        {/*타입(지정가) 선택창*/}
                        <OrderTypeDropDownButton
                            moreItemList={sellTypeList}
                            setMoreItemList={setSellTypeList}
                        />
                        {/*개당 가격 input*/}
                        <PointOrderSellTabCountInput
                            type={"number"}
                            isWarning={isInsufficientRp}
                            value={(pointPricePerPiece * 1).toString()}
                            onChange={(e) => {
                                const perPrice = Number(e.target.value);

                                if (perPrice >= 100000000) {
                                    setPointPricePerPiece(100000000);
                                } else {
                                    setPointPricePerPiece(
                                        perPrice > 0
                                            ? Number(
                                                  Math.round(perPrice * 100) /
                                                      100,
                                              )
                                            : 0,
                                    );
                                }
                            }}
                        />
                        <OrderSellBoxRowRightCountValueUnit>
                            RP
                        </OrderSellBoxRowRightCountValueUnit>
                    </OrderSellBoxRowRightCountValueContainer>
                }
            />

            <OrderTabUnderLine />

            <OrderTabRow
                rowKey={"총 결제 수량"}
                rowValue={numberToStringWithComma(pointPriceAmount)}
                rowValueUnit={"RP"}
                isPrice={true}
            />

            <OrderTabRowList>
                <OrderTabRow
                    rowKey={"입력 수량"}
                    rowValue={numberToStringWithComma(count)}
                    rowValueUnit={"개"}
                />
                <OrderTabRow
                    rowKey={`판매 로열티`}
                    tooltipMsg={"로열티는 브랜드별로 상이합니다."}
                    rowValue={numberToStringWithComma(sellBrandRoyaltyAmount)}
                    rowValueUnit={"RP"}
                />
                <PointOrderBuyTabKeyDesc>
                    ({rawPointData.brandInfo.brand_name} :{" "}
                    {numberToStringWithComma(pointSellBrandRoyalty)}%)
                </PointOrderBuyTabKeyDesc>
                {isInsufficientRp && (
                    <OrderTabWarningRow>
                        보유한 RP가 부족합니다.
                    </OrderTabWarningRow>
                )}
            </OrderTabRowList>

            <OrderTabUnderLine />

            <OrderTabRow
                rowKey={"예상 수익"}
                rowValue={numberToStringWithComma(
                    pointPriceAmount - sellBrandRoyaltyAmount,
                )}
                rowValueUnit={"RP"}
                isPrice={true}
            />
            {/*아이템 페이지 - 판매 박스*/}

            <OrderTabButton
                isDisabled={isInsufficientRp}
                onClick={async () => {
                    if (isLogin) {
                        if (count === 0) {
                            await openCommonErrorModal({
                                title: "주문 불가능한 수량 입니다.",
                            });
                            return;
                        }
                        if (pointPricePerPiece === 0) {
                            await openCommonErrorModal({
                                title: "주문 불가능한 가격 입니다.",
                            });
                            return;
                        }

                        await openPointSellModal();
                    } else {
                        await openLoginConfirmModal();
                    }
                }}
            >
                판매 등록
            </OrderTabButton>
        </OrderTabContainer>
    );
};

export default PointOrderSellTab;
