import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import { CommonTC } from "../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import {
    spreadMyAdAnalysisList,
    useGetMyAdAnalysisListQuery,
} from "../../../../../query/my/myAd/useGetMyAdAnalysisListQuery";

import MoreButton from "../../../../common/commonUnitComponent/moreButton/MoreButton";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";
import {
    AD_SALE_ANALYSIS,
    AdManageTableContentsMoreButton,
    AdManageTableContentsMoreButtonContainer,
} from "../../_adManageLayout/AdManageTableContentsLayout";
import AdManageCommonAnalysisPcTable from "./components/AdManageCommonAnalysisPcTable";
import AdManageCommonAnalysisMobileTable from "./components/AdManageCommonAnalysisMobileTable";

const DateColumnWidth = css`
    width: 120px;
`;

const TitleColumnWidth = css`
    width: 353px;
`;
const BrandColumnWidth = css`
    width: 140px;
`;

const PriceColumnWidth = css`
    width: 110px;
`;

const RateColumnWidth = css`
    width: 126px;
`;
const ButtonColumnWidth = css`
    width: 104px;
`;

export const AdManageCommonAnalysisTableDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${DateColumnWidth};
`;

export const AdManageCommonAnalysisTableTitleColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${TitleColumnWidth};
`;

export const AdManageCommonAnalysisTableBrandColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${BrandColumnWidth};
`;

export const AdManageCommonAnalysisTablePriceColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${PriceColumnWidth};
`;

export const AdManageCommonAnalysisTableRateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${RateColumnWidth};
`;

export const AdManageCommonAnalysisTableButtonColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${ButtonColumnWidth};
`;

export const AdManageCommonAnalysisTableContainer = styled.div``;

export const AdManageCommonAnalysisPCTableContainer = styled.div`
    @media ${(props) => props.theme.tabletM} {
        display: none;
    }
`;

export const AdManageCommonAnalysisMobileTableContainer = styled.div`
    display: none;
    @media ${(props) => props.theme.tabletM} {
        display: block;
    }
`;

type Props = {
    type: string;
    selectedDateRange: DateRange;
};

const AdManageCommonAnalysisTable = (props: Props): ReactElement | null => {
    const {
        data: rawAdAnalysisList,
        isLoading: isAdAnalysisListLoading,
        error: errorAdAnalysisList,
        hasNextPage: hasAdAnalysisListNextPage,
        fetchNextPage: fetchAdAnalysisListNextPage,
    } = useGetMyAdAnalysisListQuery(
        props.type === AD_SALE_ANALYSIS ? "sell" : "buy",
        {
            limit: 20,
            page_no: 1,
            ad_sales_date_from: format(
                props.selectedDateRange.from ? props.selectedDateRange.from : 0,
                "yyyy-MM-dd",
            ),
            ad_sales_date_to: format(
                props.selectedDateRange.to ? props.selectedDateRange.to : 0,
                "yyyy-MM-dd",
            ),
            sort: "ad_started_at",
            order: "desc",
        },
    );
    const adAnalysisList =
        spreadMyAdAnalysisList(props.type, rawAdAnalysisList.pages) || [];

    return (
        <AdManageCommonAnalysisTableContainer>
            <ApiResBoundary
                isLoading={isAdAnalysisListLoading}
                error={errorAdAnalysisList}
                isNoData={adAnalysisList.length === 0}
                defaultLoadingFallbackContainerHeight={"400px"}
            >
                <>
                    <AdManageCommonAnalysisPCTableContainer>
                        <AdManageCommonAnalysisPcTable
                            type={props.type}
                            adAnalysisList={adAnalysisList}
                        />
                    </AdManageCommonAnalysisPCTableContainer>
                    <AdManageCommonAnalysisMobileTableContainer>
                        <AdManageCommonAnalysisMobileTable
                            type={props.type}
                            adAnalysisList={adAnalysisList}
                        />
                    </AdManageCommonAnalysisMobileTableContainer>
                    {hasAdAnalysisListNextPage && (
                        <AdManageTableContentsMoreButtonContainer>
                            <AdManageTableContentsMoreButton>
                                <MoreButton
                                    isShow={true}
                                    isSupportFold={false}
                                    onClick={async () => {
                                        await fetchAdAnalysisListNextPage();
                                    }}
                                />
                            </AdManageTableContentsMoreButton>
                        </AdManageTableContentsMoreButtonContainer>
                    )}
                </>
            </ApiResBoundary>
        </AdManageCommonAnalysisTableContainer>
    );
};

export default AdManageCommonAnalysisTable;
