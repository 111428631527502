import { ReactElement, useState } from "react";
import styled from "styled-components";
import TutorialCategory from "./TutorialCategory";
import TutorialContactBtn from "../TutorialContactBtn";
import {
    TutorialInfoListType,
    TutorialSubCategoryInfoType,
} from "../../TutorialModal";
import { TUTORIAL_INFO_LIST } from "../../TutorialInfoConstants";

export const TutorialCategoryWrapper = styled.div`
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        flex-shrink: 0;
    }
`;

type Props = {
    tutorialInfoList: TutorialInfoListType[];
    setTutorialInfoList: (value: TutorialInfoListType[]) => void;
    setIsSelectOnMobile: (value: boolean) => void;
};

const TutorialCategories = (props: Props): ReactElement | null => {
    const openUpperCategory = (selectedKey: string) => {
        props.setTutorialInfoList(
            props.tutorialInfoList.map((upperCategory) => {
                return {
                    ...upperCategory,
                    isOpen: upperCategory.upperCategoryKey === selectedKey,
                };
            }),
        );
    };

    const selectSubCategory = (selectedKey: string) => {
        props.setTutorialInfoList(
            props.tutorialInfoList.map((upperCategory) => {
                return {
                    ...upperCategory,
                    subCategoryList: upperCategory.subCategoryList.map(
                        (subCategory) => {
                            return {
                                ...subCategory,
                                isSelected: selectedKey === subCategory.key,
                            };
                        },
                    ),
                };
            }),
        );
    };

    return (
        <TutorialCategoryWrapper>
            {props.tutorialInfoList.map((infoList, infoListIdx) => {
                return (
                    <TutorialCategory
                        infoList={infoList}
                        key={infoListIdx}
                        openUpperCategory={openUpperCategory}
                        selectSubCategory={selectSubCategory}
                        setIsSelectOnMobile={props.setIsSelectOnMobile}
                    />
                );
            })}
            <TutorialContactBtn />
        </TutorialCategoryWrapper>
    );
};

export default TutorialCategories;
