import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../commonStyle/CommonButtonStyle";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";

export const FoldableDescContainer = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
`;

export const FoldableHiddenDesc = styled.div`
    width: calc(100% - 82px);
    position: absolute;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;

    white-space: nowrap;
    word-break: break-all;
    opacity: 0;
`;

export const FoldableShowingDesc = styled.div<{
    isOpen: boolean;
}>`
    margin-top: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${({ isOpen }) => (isOpen ? "unset" : "nowrap")};
    width: ${({ isOpen }) => (isOpen ? "100%" : "calc(100% - 82px)")};
    color: #888;
    max-height: ${({ isOpen }) => (isOpen ? "300px" : "24px")};
    transition: max-height 300ms ease;
`;

export const FoldableDescMoreButtonContainer = styled.div<
    { isOpen: boolean } & CommonButtonType
>`
    ${CommonButtonStyle};
    position: ${({ isOpen }) => (isOpen ? "absolute" : "relative")};
    flex-shrink: 0;
    z-index: 2;
    width: 72px;
    height: 27px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 14px;
    line-height: 100%;
    color: #777;

    ${({ isOpen }) => {
        if (isOpen) {
            return `
                bottom: -33px;
                right: 0;
            `;
        }
    }}

    color: #555555;
    cursor: pointer;

    @media ${(props) => props.theme.mobileL} {
        font-size: 14px;
    }
`;

export const FoldableDescMoreButtonIcon = styled.img`
    margin-left: 6px;
    width: 7.5px;
    height: 11.5px;
`;

type Props = {
    desc: string;
};

const FoldableDesc = (props: Props): ReactElement | null => {
    const hideContainerRef = useRef<HTMLDivElement>(null);
    const isOverflow =
        hideContainerRef.current &&
        hideContainerRef.current?.scrollWidth >
            hideContainerRef.current?.clientWidth;
    const [isDescOpen, setIsDescOpen] = useState(false);

    return (
        <FoldableDescContainer>
            <FoldableHiddenDesc ref={hideContainerRef}>
                {props.desc}
            </FoldableHiddenDesc>

            <FoldableShowingDesc isOpen={isDescOpen}>
                {props.desc}
            </FoldableShowingDesc>
            {isOverflow && (
                <FoldableDescMoreButtonContainer
                    isOpen={isDescOpen}
                    btnType={"outlined-secondary"}
                    onClick={() => {
                        setIsDescOpen(!isDescOpen);
                    }}
                >
                    {isDescOpen ? "접기" : "더 보기"}
                    <FoldableDescMoreButtonIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-more${
                            isDescOpen ? "-reverse" : ""
                        }.svg`}
                    />
                </FoldableDescMoreButtonContainer>
            )}
        </FoldableDescContainer>
    );
};

export default FoldableDesc;
