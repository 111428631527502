import { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonFixedContainer,
    ModalButtonFixedSpace,
    ModalTitle,
} from "../../commonUnitComponent/modal/ModalStyle";
import ModalCloseIcon from "../modalCloseIcon/ModalCloseIcon";

export const CommonStatusInfoModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        max-width: none;
    }
`;
export const CommonStatusInfoModalImgContainer = styled.div`
    width: calc(100% - 32px);
    padding: 14px 16px;
    margin-top: 34px;
    border-radius: 10px;
    background: #f5f5f7;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CommonStatusInfoModalImg = styled.img`
    max-width: 306.5px;
    width: auto;
`;

export const CommonStatusInfoModalImgDesc = styled.div`
    color: #555;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 20px;
    width: 100%;
`;

export const CommonStatusInfoModalList = styled.div<{
    marginTop: string;
}>`
    display: flex;
    flex-direction: column;
    margin-top: ${({ marginTop }) => marginTop};
    gap: 10px;
`;

export const CommonStatusInfoModalRow = styled.div`
    display: flex;
    align-items: baseline;
    gap: 6px;
`;

export const CommonStatusInfoModalTitle = styled.div<{ titleWidth: string }>`
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    width: ${({ titleWidth }) => titleWidth};
    flex-shrink: 0;
`;

export const CommonStatusInfoModalDesc = styled.div`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

export type statusInfoListItemType = {
    title: string;
    desc: string;
};

type Props = {
    resolve: (value: boolean) => void;
    title: string;
    imgSrc?: string;
    descList?: string[];
    statusInfoTitleWidth: string;
    statusInfoList: statusInfoListItemType[];
};

const CommonStatusInfoModal = (props: Props): ReactElement | null => {
    return (
        <CommonStatusInfoModalContainer>
            <ModalCloseIcon resolve={props.resolve} />

            <ModalTitle>{props.title}</ModalTitle>
            {(props.imgSrc || props.descList) && (
                <CommonStatusInfoModalImgContainer>
                    {props.imgSrc && (
                        <CommonStatusInfoModalImg src={props.imgSrc} />
                    )}
                    {props.descList && (
                        <CommonStatusInfoModalImgDesc>
                            {props.descList.map((desc) => {
                                return <div>{desc}</div>;
                            })}
                        </CommonStatusInfoModalImgDesc>
                    )}
                </CommonStatusInfoModalImgContainer>
            )}

            <CommonStatusInfoModalList
                marginTop={props.imgSrc || props.descList ? "20px" : "32px"}
            >
                {props.statusInfoList.map((info, idx) => (
                    <CommonStatusInfoModalRow key={idx}>
                        <CommonStatusInfoModalTitle
                            titleWidth={props.statusInfoTitleWidth}
                        >
                            {info.title}
                        </CommonStatusInfoModalTitle>
                        <CommonStatusInfoModalDesc>
                            {info.desc}
                        </CommonStatusInfoModalDesc>
                    </CommonStatusInfoModalRow>
                ))}
            </CommonStatusInfoModalList>
            <ModalButtonFixedSpace />
            <ModalButtonFixedContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </ModalButton>
            </ModalButtonFixedContainer>
        </CommonStatusInfoModalContainer>
    );
};

export default CommonStatusInfoModal;
