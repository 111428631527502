import { ReactElement } from "react";
import styled from "styled-components";
import {
    GlobalSearchResultTabIDsType,
    GlobalSearchResultTabs,
} from "../../../../../constants/menus/headerMenus";

export const SearchResultHeaderWrapper = styled.div`
    height: 46px;
    border-bottom: #ebebeb 1px solid;
    display: flex;
    justify-content: center;

    @media ${(props) => props.theme.tabletL} {
        justify-content: flex-start;
        padding: 0 16px;
    }
`;

export const SearchResultHeaderContainer = styled.div`
    height: 46px;
    display: flex;
    gap: 10px;
    align-content: flex-end;
`;

export const SearchResultHeaderItem = styled.div<{
    isSelected: boolean;
}>`
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 6px;
    font-size: 15px;
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "black")};
    font-weight: ${({ isSelected }) => (isSelected ? "700" : "400")};
    cursor: pointer;
    flex-shrink: 0;
`;

export const SearchResultSelectedMenuUnderBar = styled.div<{
    isSelected: boolean;
}>`
    width: 100%;
    margin-left: -6px;
    position: absolute;
    bottom: 0;

    border-bottom: ${({ isSelected }) =>
        isSelected ? "3px #FA54FA solid" : "none"};
`;

type Props = {
    selectedSearchMenuId: GlobalSearchResultTabIDsType;
    setSelectedSearchMenuId: (value: GlobalSearchResultTabIDsType) => void;
};

const SearchResultHeader = (props: Props): ReactElement | null => {
    return (
        <SearchResultHeaderWrapper>
            <SearchResultHeaderContainer>
                {GlobalSearchResultTabs.map((menu, idx) => {
                    const isSelected = props.selectedSearchMenuId === menu.id;
                    return (
                        <SearchResultHeaderItem
                            onClick={() => {
                                props.setSelectedSearchMenuId(menu.id);
                            }}
                            isSelected={isSelected}
                            key={idx}
                        >
                            {menu.name}
                            <SearchResultSelectedMenuUnderBar
                                isSelected={isSelected}
                            />
                        </SearchResultHeaderItem>
                    );
                })}
            </SearchResultHeaderContainer>
        </SearchResultHeaderWrapper>
    );
};

export default SearchResultHeader;
