import { ReactElement } from "react";
import styled from "styled-components";
import SearchFilterLayerListItem from "./SearchFilterLayerListItem";
import { FilterType } from "../../../SearchFilter";
import { FilterItemType } from "../../../../doubleSideBar/leftSideBar/LeftSideBar";

export const SearchFilterLayerListContainer = styled.div<{
    searchFilterId: string;
}>`
    width: ${({ searchFilterId }) =>
        searchFilterId === "left-side-header" ? "100%" : "auto"};
    @media ${(props) => props.theme.tabletM} {
        width: calc(50% - 8px);
    }
`;
export const SearchFilterLayerListTitleContainer = styled.div<{
    isFullModal: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${({ isFullModal }) =>
        isFullModal ? "none" : "1px solid #d9d9d9"};
    width: 100%;
    height: 36px;
    padding-bottom: 4px;
    margin-bottom: 4px;
`;

export const SearchFilterLayerListTitle = styled.div`
    padding: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #000000;
`;

export const SearchFilterLayerListTitleUnit = styled.div`
    color: #888;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
`;

type Props = {
    tempFilterIdx: number;
    filterItemWidth: string;
    tempFilters: FilterType[];
    setTempFilters: (value: FilterType[]) => void;
    id: string;
    isFullModal: boolean;
    isLayerType: boolean;
};

const SearchFilterLayerList = (props: Props): ReactElement | null => {
    return (
        <SearchFilterLayerListContainer searchFilterId={props.id}>
            <SearchFilterLayerListTitleContainer
                isFullModal={props.isFullModal}
            >
                <SearchFilterLayerListTitle>
                    {props.tempFilters[props.tempFilterIdx].title}
                </SearchFilterLayerListTitle>
                {props.tempFilters[props.tempFilterIdx].subTitle && (
                    <SearchFilterLayerListTitleUnit>
                        {props.tempFilters[props.tempFilterIdx].subTitle}
                    </SearchFilterLayerListTitleUnit>
                )}
            </SearchFilterLayerListTitleContainer>

            <form action={props.id}>
                <SearchFilterLayerListItem
                    id={props.id}
                    filterItemWidth={props.filterItemWidth}
                    filterIdx={props.tempFilterIdx}
                    filterItem={{
                        key: props.tempFilters[props.tempFilterIdx].filterList
                            .map((filter) => filter.key)
                            .join(","),
                        title: "전체",
                        isSelected: props.tempFilters[
                            props.tempFilterIdx
                        ].filterList
                            .map((filterListItem) => filterListItem.isSelected)
                            .every((item) => !item),
                    }}
                    filterItemIdx={-1}
                    tempFilters={props.tempFilters}
                    setTempFilters={props.setTempFilters}
                    additionalExceptionOnChangeHandler={
                        props.tempFilters[props.tempFilterIdx]
                            .totalAdditionalExceptionOnChangeHandler
                    }
                />
                {props.tempFilters[props.tempFilterIdx].filterList.map(
                    (filterItem: FilterItemType, filterItemIdx: number) => {
                        return (
                            <SearchFilterLayerListItem
                                key={filterItemIdx}
                                id={props.id}
                                filterItemWidth={props.filterItemWidth}
                                filterIdx={props.tempFilterIdx}
                                filterItem={filterItem}
                                filterItemIdx={filterItemIdx}
                                tempFilters={props.tempFilters}
                                setTempFilters={props.setTempFilters}
                                additionalExceptionOnChangeHandler={
                                    filterItem.additionalExceptionOnChangeHandler
                                }
                            />
                        );
                    },
                )}
            </form>
        </SearchFilterLayerListContainer>
    );
};

export default SearchFilterLayerList;
