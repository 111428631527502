import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { useA2HS } from "../../../../../hooks/common/useA2HS";

export const FooterAppDownloadBtnContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    width: 125px;
    height: 34px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #555;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    letter-spacing: -0.15px;

    cursor: pointer;

    transition: all 300ms ease;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(240, 240, 242, 0.7);
        }
    } ;
`;

export const FooterAppDownloadIcon = styled.img`
    margin-right: 4px;
`;

type Props = {};

const FooterAppDownloadBtn = (props: Props): ReactElement | null => {
    const { commonInstallApp } = useA2HS();

    return (
        <FooterAppDownloadBtnContainer
            onClick={async () => {
                await commonInstallApp();
            }}
        >
            <FooterAppDownloadIcon
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-download.svg`}
            />
            앱 다운로드
        </FooterAppDownloadBtnContainer>
    );
};

export default FooterAppDownloadBtn;
