import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
    spreadItemInventoryGroupListPages,
    useGetItemInventoryGroupList,
} from "../../../../../../../../query/itemInventory/useGetItemInventoryGroupList";
import { ModalTitle } from "../../../../../../../common/commonUnitComponent/modal/ModalStyle";
import ModalCloseIcon from "../../../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import WalletCollectionGroupEditNoGroup from "./components/WalletCollectionGroupEditNoGroup";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../../common/commonStyle/CommonButtonStyle";
import { useOpenCollectionGroupAddModal } from "../../../../../../../../hooks/modal/openModal/useOpenCollectionGroupAddModal";
import { ItemInventoryGroupListItemType } from "../../../../../../../../types/itemInventory/ItemInventoryGroupListItemType";
import WalletCollectionGroupEditList, {
    scrollbarId,
} from "./components/WalletCollectionGroupEditList";

export const WalletCollectionGroupModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const WalletCollectionGroupEditListWrapper = styled.div`
    width: 352px;
    margin-top: 24px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const WalletCollectionGroupEditListDescContainer = styled.div`
    margin-top: 6px;
    color: #888;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
`;

export const WalletCollectionGroupEditListAddBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};

    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #121212;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    width: 100%;
    height: 40px;
`;

type Props = {
    resolve: (value: boolean) => void;
    targetUserIdx: string;
};

const WalletCollectionGroupEditModal = (props: Props): ReactElement | null => {
    const { openCollectionGroupAddModal } = useOpenCollectionGroupAddModal();
    const [itemInventoryGroupList, setItemInventoryGroupList] = useState<
        ItemInventoryGroupListItemType[]
    >([]);
    const scrollEditListRef = useRef<HTMLDivElement>(null);
    const [isGroupAddDone, setIsGroupAddDone] = useState(false);

    const {
        isSuccess: isItemInventoryGroupListSuccess,
        data: rawItemInventoryGroupList,
        isLoading: isItemInventoryGroupListLoading,
        isFetching: isItemInventoryGroupListFetching,
    } = useGetItemInventoryGroupList({
        user_idx: props.targetUserIdx,
        limit: 20,
        page_no: 1,
    });

    useEffect(() => {
        if (isItemInventoryGroupListSuccess) {
            setItemInventoryGroupList(
                spreadItemInventoryGroupListPages(
                    rawItemInventoryGroupList.pages,
                ),
            );
        }
    }, [rawItemInventoryGroupList, isItemInventoryGroupListSuccess]);

    useEffect(() => {
        if (isItemInventoryGroupListSuccess && isGroupAddDone) {
            // 추가된 그룹이 렌더링되는 시간을 위해 timeout
            setTimeout(() => {
                const scrollEditListScrollBar = document.getElementById(
                    scrollbarId + "__custom-scrollbar-children__wrapper",
                );
                if (scrollEditListScrollBar) {
                    scrollEditListScrollBar.scrollIntoView({
                        block: "end",
                    });
                }

                setIsGroupAddDone(false);
            }, 300);
        }
    }, [
        isGroupAddDone,
        rawItemInventoryGroupList,
        isItemInventoryGroupListSuccess,
    ]);

    return (
        <WalletCollectionGroupModalContainer>
            <ModalCloseIcon resolve={props.resolve} />
            <ModalTitle>그룹 편집</ModalTitle>

            {itemInventoryGroupList.length === 0 ? (
                <WalletCollectionGroupEditNoGroup />
            ) : (
                <WalletCollectionGroupEditListWrapper>
                    <WalletCollectionGroupEditList
                        scrollEditListRef={scrollEditListRef}
                        itemInventoryGroupList={itemInventoryGroupList}
                        setItemInventoryGroupList={setItemInventoryGroupList}
                        rawItemInventoryGroupListPages={
                            rawItemInventoryGroupList.pages
                        }
                    />
                    <WalletCollectionGroupEditListDescContainer>
                        그룹은 최대 20개까지 생성이 가능합니다.
                    </WalletCollectionGroupEditListDescContainer>
                    <WalletCollectionGroupEditListAddBtn
                        btnType={"text-secondary"}
                        onClick={async () => {
                            const result = await openCollectionGroupAddModal();
                            if (result) {
                                setIsGroupAddDone(true);
                            }
                        }}
                    >
                        + 그룹 추가
                    </WalletCollectionGroupEditListAddBtn>
                </WalletCollectionGroupEditListWrapper>
            )}
        </WalletCollectionGroupModalContainer>
    );
};

export default WalletCollectionGroupEditModal;
