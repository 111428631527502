import styled, { css } from "styled-components";

export const CalendarCellRowContainerStyle = css`
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 18.2px */
    padding: 4px 6px;
    width: calc(100% - 12px);
    border-radius: 4px;

    @media ${(props) => props.theme.tabletL} {
        font-size: 12px;
        width: calc(100% - 8px);
        padding: 2px 4px;
    }

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 12px);
    }
`;

export const CalendarCellRowIcon = styled.img`
    width: 18px;
    height: 18px;
`;
