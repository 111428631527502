import { useInfiniteQuery } from "react-query";
import { MarketCommonApi } from "../../../apis/market/MarketCommonApi";

export type MarketSaleListApiResultType = {
    orderSubList: MarketSaleListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type MarketSaleListItemType = {
    created_at: string;
    currency_idx: string;
    order_idx: string;
    order_sub_idx: string;
    order_sub_quantity: number;
    order_sub_type: string;
    order_sub_unit_price: number;
    order_valid_until: string;
    product_idx: string;
    product_type: string;
    settled_quantity_sum: number;
    updated_at: string;
    user_idx: string;
    user_nick: string;
};

export type GetSaleListParams = {
    limit: number;
    page_no: number;
};

export const useGetMarketSaleListQuery = (
    pageType: string,
    targetIdx: string,
    params: GetSaleListParams,
) => {
    const queryResult = useInfiniteQuery<MarketSaleListApiResultType, Error>({
        queryKey: [
            `market-${pageType}-sale-list-${targetIdx}${JSON.stringify(
                params,
            )}`,
            `${pageType}-info-refresh-target-${targetIdx}`,
        ],
        queryFn: ({ pageParam = 1 }) =>
            MarketCommonApi.getSaleList(pageType, targetIdx, {
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: MarketSaleListApiResultType[] = [
        {
            orderSubList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadMarketSaleList(pages: MarketSaleListApiResultType[]) {
    return pages
        .map((result) => result.orderSubList)
        .reduce((prev, next) => prev.concat(next));
}
