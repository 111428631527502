import { ReactElement } from "react";
import styled from "styled-components";
import { UserProfileMyMenus } from "../../../../../../../constants/menus/headerMenus";
import UserProfileModalCommonRow from "../UserProfileModalCommonRow";
import { PathAfterResolveType } from "../../../../../../../hooks/modal/useOpenModal";

export const UserProfileMyMenusContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    background: white;

    @media ${(props) => props.theme.tabletL} {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 10px 10px 10px;
    }
`;

type Props = {
    resolve: (value: boolean | number | PathAfterResolveType) => void;
};

const UserProfileModalMyMenus = (props: Props): ReactElement | null => {
    return (
        <UserProfileMyMenusContainer>
            {UserProfileMyMenus.map((myMenu, idx) => (
                <UserProfileModalCommonRow
                    key={idx}
                    title={myMenu.name}
                    path={myMenu.path}
                    leftIconPath={myMenu.imgPath || ""}
                    isNewTab={false}
                    isDownSizingMobile={true}
                    resolve={props.resolve}
                />
            ))}
        </UserProfileMyMenusContainer>
    );
};

export default UserProfileModalMyMenus;
