import { TemplateInfoSpaceType } from "./TemplateInfoSpaceType";

export type TemplateInfoType = {
    brand_idx: string;
    broad_live_redirect_bool: boolean;
    broad_live_redirect_user_id: string | null;
    campaign_id: string;
    campaign_idx: string;
    created_at: string;
    endPoint: string;
    order_no: number;
    template_description: string;
    template_idx: string;
    template_name: string;
    template_space: TemplateInfoSpaceType[]; // assuming any array for now
    template_status: string;
    template_type: string;
    template_status_checked_at: string;
    template_status_reason: string | null;
    updated_at: string;
};

export const DEFAULT_TEMPLATE_INFO_TYPE_DATA: TemplateInfoType = {
    brand_idx: "",
    broad_live_redirect_bool: false,
    broad_live_redirect_user_id: null,
    campaign_id: "",
    campaign_idx: "",
    created_at: "",
    endPoint: "",
    order_no: 0,
    template_description: "",
    template_idx: "",
    template_name: "",
    template_space: [],
    template_status: "",
    template_type: "1",
    template_status_checked_at: "",
    template_status_reason: null,
    updated_at: "",
};
