import { ReactElement } from "react";
import styled from "styled-components";
import AdSaleManageScheduleCalendarCellBodyBtns from "../adSaleManageCalendarCellBody/components/adSaleManageScheduleCalendarCellBodyBtns/AdSaleManageScheduleCalendarCellBodyBtns";
import { useGetAdSaleManageCalendarCellByCode } from "../useGetAdSaleManageCalendarCellByCode";
import { MyAdDailyListItemType } from "../../../../../../../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";
import { CalendarSelectedDateInfoContainer } from "../../../../../../../../common/commonUiComponent/calendar/components/CalendarDateBody";
import { getMonthStartEnd } from "../../../../../../../../../utils/Utils";
import { differenceInDays } from "date-fns";
import { AD_NOT_OPENED_YET } from "../../../../../../../../../types/ad/adDaily/AdDailyListItemType";

export const AdSaleManageCalendarSelectedMobileCellContainer = styled.div`
    padding: 12px 10px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
`;

type Props = {
    adIdx: string;
    cellDate: Date;
    displayingDate: Date;
    myAdDailyList: MyAdDailyListItemType[];
};

const AdSaleManageCalendarSelectedMobileCell = (
    props: Props,
): ReactElement | null => {
    const monthStartEnd = getMonthStartEnd(props.displayingDate);
    const selectedDateIndex = differenceInDays(
        props.cellDate,
        monthStartEnd.startDate,
    );

    const { cellMobileSelectedCell, adDailyStatusCode } =
        useGetAdSaleManageCalendarCellByCode({
            cellDate: props.cellDate,
            adIdx: props.adIdx,
            myAdDailyList: props.myAdDailyList,
        });

    return adDailyStatusCode !== AD_NOT_OPENED_YET ? (
        <CalendarSelectedDateInfoContainer
            bgColor={"white"}
            borderColor={"#e0e0e0"}
            selectedDateInfoContainerIndex={selectedDateIndex}
        >
            <AdSaleManageCalendarSelectedMobileCellContainer>
                {cellMobileSelectedCell}
                <AdSaleManageScheduleCalendarCellBodyBtns
                    adIdx={props.adIdx}
                    cellDate={props.cellDate}
                    myAdDailyList={props.myAdDailyList}
                />
            </AdSaleManageCalendarSelectedMobileCellContainer>
        </CalendarSelectedDateInfoContainer>
    ) : null;
};

export default AdSaleManageCalendarSelectedMobileCell;
