import { ReactElement, useState } from "react";
import styled from "styled-components";
import ProjectList from "../../../../../common/commonUiComponent/projectList/ProjectList";
import MarketItemBottomContentsItem from "./components/MarketItemBottomContentsItem";
import {
    ItemListItemType,
    spreadItemListPages,
    useItemListQuery,
} from "../../../../../../query/item/useItemListQuery";
import { useParams } from "react-router-dom";
import MoreButton from "../../../../../common/commonUnitComponent/moreButton/MoreButton";
import { useSetStateOnWindowWidth } from "../../../../../../hooks/common/useSetStateOnWindowWidth";
import ApiResBoundary from "../../../../../common/apiResBoundary/ApiResBoundary";
import { CommonBoxHoverEffect } from "../../../../../common/commonStyle/CommonStyle";

export const MarketItemBottomContentsWrapper = styled.div``;

export const MarketItemBottomContentsContainer = styled.div`
    margin-top: 120px;
`;

export const MarketItemBottomContentsTitle = styled.div`
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    color: #000000;

    @media ${(props) => props.theme.mobileL} {
        font-size: 20px;
    }
`;

export const MarketItemBottomContentsList = styled.div`
    display: flex;
    flex-wrap: wrap;

    gap: 36px 24px;
    margin-top: 24px;

    @media ${(props) => props.theme.tabletM} {
        gap: 24px 20px;
    }

    @media ${(props) => props.theme.mobileL} {
        gap: 16px 16px;
    }
`;

export const MarketItemBottomContentsItemContainer = styled.div`
    width: calc(20% - 19.2px);
    @media ${(props) => props.theme.PCM} {
        width: calc(25% - 18px);
    }

    @media ${(props) => props.theme.tabletL} {
        width: calc(33% - 14px);
    }

    @media ${(props) => props.theme.tabletM} {
        width: calc(33% - 11.2px);
    }

    @media ${(props) => props.theme.mobileL} {
        width: calc(50% - 8px);
    }

    transition: all 300ms ease;

    ${CommonBoxHoverEffect};
    flex-shrink: 0;
    cursor: pointer;
`;

export const MarketItemOtherItemListMoreButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 24px;
        width: 100%;
    }
`;

export const MarketItemOtherItemListMoreButtonWidth = styled.div`
    width: 160px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

type Props = {
    projectName: string;
    creatorId: string;
    creatorIdx: string;
    creatorNickname: string;
    creatorType: string;
};

const MarketItemBottomContents = (props: Props): ReactElement | null => {
    const params = useParams();
    const projectIdx = params.project_id ? params.project_id : "0";
    const itemIdx = params.item_id ? params.item_id : "0";
    const [itemPerRow, setItemPerRow] = useState(5);

    const { isOnMountedSetDone } = useSetStateOnWindowWidth(
        setItemPerRow,
        [5, 5, 4, 3, 3, 2, 2],
    );

    const {
        data: rawOtherItemListData,
        hasNextPage,
        fetchNextPage,
        error: otherItemListDataError,
        isLoading: isOtherItemListLoading,
    } = useItemListQuery(
        {
            list_type: "not_item",
            sort_type: "created_at_desc",
            page_no: 1,
            limit: itemPerRow,
            project_idx: projectIdx,
            not_item_idx: itemIdx,
        },
        {
            enabled: isOnMountedSetDone,
            staleTime: 60000,
            cacheTime: 60000,
        },
    );

    return (
        <MarketItemBottomContentsWrapper>
            {spreadItemListPages(rawOtherItemListData.pages).length !== 0 && (
                <MarketItemBottomContentsContainer>
                    <MarketItemBottomContentsTitle>
                        {props.projectName}의 다른 아이템
                    </MarketItemBottomContentsTitle>
                    <ApiResBoundary
                        isNoData={false}
                        isLoading={isOtherItemListLoading}
                        error={otherItemListDataError}
                        defaultLoadingFallbackContainerHeight={"444px"}
                    >
                        <>
                            <MarketItemBottomContentsList>
                                {spreadItemListPages(
                                    rawOtherItemListData.pages,
                                ).map((item: ItemListItemType, idx: number) => {
                                    return (
                                        <MarketItemBottomContentsItemContainer
                                            key={idx}
                                        >
                                            <MarketItemBottomContentsItem
                                                data={item}
                                            />
                                        </MarketItemBottomContentsItemContainer>
                                    );
                                })}
                            </MarketItemBottomContentsList>
                            <MarketItemOtherItemListMoreButtonContainer>
                                <MarketItemOtherItemListMoreButtonWidth>
                                    <MoreButton
                                        isShow={hasNextPage}
                                        onClick={() => {
                                            fetchNextPage().then(() => null);
                                        }}
                                    />
                                </MarketItemOtherItemListMoreButtonWidth>
                            </MarketItemOtherItemListMoreButtonContainer>
                        </>
                    </ApiResBoundary>
                </MarketItemBottomContentsContainer>
            )}

            <ProjectList
                title={props.creatorNickname + "의 인기 프로젝트"}
                projectListApiParam={{
                    list_type: props.creatorType === "brand" ? "brand" : "user",
                    sort_type: "popular",
                    page_no: 0,
                    limit: 4,
                    [props.creatorType === "brand" ? "brand_idx" : "user_idx"]:
                        props.creatorIdx,
                }}
                projectListQueryParam={{
                    enabled: true,
                    staleTime: 60000,
                    cacheTime: 60000,
                }}
            />
        </MarketItemBottomContentsWrapper>
    );
};

export default MarketItemBottomContents;
