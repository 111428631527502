import { ReactElement } from "react";
import styled from "styled-components";
import { isMenuSelected } from "../../../../../../utils/Utils";
import { getIsLogin } from "../../../../../../store/auth/authKeyStore";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { Link } from "react-router-dom";
import { LinkMenuType } from "./HeaderMenu";
import { useOpenLoginConfirmModal } from "../../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { useOpenTutorialModal } from "../../../../../../hooks/modal/openModal/useOpenTutorialModal";

export const HeaderDropdownMenuListItemLinkContainer = styled.div<{
    isSelected: boolean;
}>`
    font-weight: ${({ isSelected }) => (isSelected ? "700" : "500")};
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#222222")};
`;

export const HeaderDropdownMenuListItemLink = styled(Link)`
    width: 150px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    border-radius: 6px;

    font-size: 16px;
    color: inherit;
    cursor: pointer;

    &:hover {
        background-color: #f0f1f2;
    }

    transition: background ease 300ms;
`;

export const HeaderDropdownMenuItemIconImg = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
`;

export const HeaderDropdownMenuItemTitle = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderDropdownMenuItemGoIConImg = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 2px;
    float: right;
`;

type Props = {
    menu: LinkMenuType;
    isMoreMenu: boolean;
    setIsHeaderMenuDropdownOpen: (value: boolean) => void;
};

const HeaderMenuDropdownListItem = (props: Props): ReactElement | null => {
    const isLogin = getIsLogin();
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const { openTutorialModal } = useOpenTutorialModal();
    return (
        <HeaderDropdownMenuListItemLinkContainer
            key={props.menu.id}
            isSelected={isMenuSelected(props.menu.path)}
            onClick={async () => {
                props.setIsHeaderMenuDropdownOpen(false);

                if (props.menu.isNeedLogin && !getIsLogin()) {
                    await openLoginConfirmModal(props.menu.path);
                }
            }}
        >
            <HeaderDropdownMenuListItemLink
                to={
                    (props.menu.isNeedLogin && !getIsLogin()) ||
                    props.menu.path === ""
                        ? {}
                        : props.menu.path
                }
                state={
                    props.menu.path === "/" && !isLogin
                        ? {
                              isIntro: false,
                          }
                        : null
                }
                target={
                    !props.menu.isNeedLogin &&
                    props.isMoreMenu &&
                    props.menu.path !== ""
                        ? "_blank"
                        : ""
                }
                onClick={async () => {
                    // 더 보기 > 가이드 버튼
                    if (props.menu.id === 993) {
                        await openTutorialModal();
                    }
                }}
            >
                <HeaderDropdownMenuItemTitle>
                    {props.menu.icon && (
                        <HeaderDropdownMenuItemIconImg src={props.menu.icon} />
                    )}
                    {props.menu.name}
                </HeaderDropdownMenuItemTitle>

                {props.isMoreMenu && props.menu.isNewTab && (
                    <HeaderDropdownMenuItemGoIConImg
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-link.png`}
                    />
                )}
            </HeaderDropdownMenuListItemLink>
        </HeaderDropdownMenuListItemLinkContainer>
    );
};

export default HeaderMenuDropdownListItem;
