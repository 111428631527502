import React, { ReactElement } from "react";
import SearchFilterContents, {
    SearchFilterLayer,
} from "./SearchFilterContents/SearchFilterContents";
import { FilterType } from "../SearchFilter";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../../../store/scroll/viewHeightAtom";

type Props = {
    id: string;
    filters: FilterType[];
    setFilters: (value: FilterType[]) => void;
    layerPositionStyleString: string;
    filterItemWidth: string;
    isSearchFilterOpen: boolean;
    setIsSearchFilterOpen: (value: boolean) => void;
};

const LayerTypeSearchFilter = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);

    return (
        <SearchFilterLayer
            isOpen={props.isSearchFilterOpen}
            position={props.layerPositionStyleString}
            vh={vh}
        >
            <SearchFilterContents
                id={props.id}
                filterItemWidth={props.filterItemWidth}
                filters={props.filters}
                setFilters={props.setFilters}
                isSearchFilterOpen={props.isSearchFilterOpen}
                close={() => {
                    props.setIsSearchFilterOpen(false);
                }}
                isLayerType={true}
            />
        </SearchFilterLayer>
    );
};

export default LayerTypeSearchFilter;
