import { useAtom } from "jotai";
import HelmetInfoAtom from "../../store/helmetInfoAtom";
import { useEffect } from "react";

export function useSetHelmetInfo(key: string, value: string, reSetDeps: any) {
    const [helmetInfo, setHelmetInfo] = useAtom(HelmetInfoAtom);
    useEffect(() => {
        setHelmetInfo({
            ...helmetInfo,
            [key]: value,
        });
    }, [reSetDeps]);
}
