import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import SearchFilterLayerList from "./components/SearchFilterLayerList";
import { FilterType } from "../../SearchFilter";
import SearchFilterBottomButtons from "../SearchFilterBottomButtons/SearchFilterBottomButtons";
import Scrollbar from "../../../scrollbar/Scrollbar";
import { useSetExceptionHeaderFilters } from "../../../../../../hooks/project/useSetExceptionHeaderFilters";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../scrollbar/ScrollbarType";

export const SearchFilterContentsContainer = styled.div`
    position: relative;
`;

export const SearchFilterLayer = styled.div<{
    isOpen: boolean;
    position: string;
    vh: number;
}>`
    position: absolute;
    ${(props) => props.theme.zIndexBgDark};
    top: 45px;
    overflow: hidden;
    background: white;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    transition: max-height 300ms ease;
    max-height: ${({ isOpen }) => (isOpen ? "700px" : 0)};
    ${({ position }) => position};
`;

export const ModalSearchFilterLayer = styled.div<{
    vh: number;
}>`
    margin-top: 24px;

    @media ${(props) => props.theme.tabletM} {
        position: relative;
        z-index: auto;
        overflow: scroll;
        max-height: ${({ vh }) => "calc(" + 100 * vh + "px - 280px)"};
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        border: none;
        padding-bottom: 84px;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;
    }
`;

export const SearchFilterLayerListOuterWrapper = styled.div<{
    isFullModal: boolean;
}>`
    width: 100%;
    ${({ isFullModal }) => {
        if (isFullModal) {
            return `
                border-top: 1px solid #ebebeb;
                border-bottom: 1px solid #ebebeb;
                margin-bottom: 20px;
                height: 504px;
                position: relative;
            `;
        }
    }}
`;

export const SearchFilterLayerListInnerWrapper = styled.div<{
    isFullModal: boolean;
    isLayerType: boolean;
    filterLength: number;
}>`
    padding: 16px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: space-between;
    gap: 20px;

    ${({ isLayerType, filterLength }) => {
        if (isLayerType && filterLength === 1) {
            return `
                min-width: 420px;
            `;
        }
    }}

    ${({ isFullModal }) => {
        if (isFullModal) {
            return `
                flex-wrap: wrap;
                justify-content: flex-start;
            `;
        }
    }};
    @media ${(props) => props.theme.tabletM} {
        gap: 16px;
        flex-wrap: wrap;
        padding: 0 16px;
    }
`;
export const SearchFilterLayerMobileHeader = styled.div`
    padding: 0 16px 30px 16px;
`;

export const SearchFilterLayerMobileTitleContainer = styled.div`
    display: flex;
    align-items: center;

    justify-content: center;
    @media ${(props) => props.theme.tabletM} {
        justify-content: flex-start;
    }
`;

export const SearchFilterLayerMobileGoBackButton = styled.img`
    width: 10px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
    display: none;
    @media ${(props) => props.theme.tabletM} {
        display: block;
    }
`;

export const SearchFilterLayerMobileTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    align-items: center;

    color: #000000;

    @media ${(props) => props.theme.tabletM} {
        font-size: 20px;
    }
`;

type Props = {
    id: string;
    filters: FilterType[];
    setFilters: (value: FilterType[]) => void;
    isSearchFilterOpen?: boolean;
    filterItemWidth: string;
    isFullModal: boolean;
    close: () => void;
    isLayerType: boolean;
};

const SearchFilterContents = (props: Props): ReactElement | null => {
    const [tempFilters, _setTempFilters] = useState<FilterType[]>(
        props.filters,
    );
    const setTempFilters = (filters: FilterType[]) => {
        _setTempFilters(filters);
    };
    // API call 이 필요한 예외처리
    useSetExceptionHeaderFilters(tempFilters, setTempFilters);

    useEffect(() => {
        if (
            typeof props.isSearchFilterOpen !== "undefined" &&
            !props.isSearchFilterOpen
        ) {
            setTempFilters(props.filters);
        }
    }, [props.isSearchFilterOpen]);

    useEffect(() => {
        setTempFilters(props.filters);
    }, [props.filters]);

    return (
        <SearchFilterContentsContainer>
            <SearchFilterLayerListOuterWrapper isFullModal={props.isFullModal}>
                <Scrollbar
                    type={SCROLL_BAR_NORMAL_TYPE}
                    id={"search-filter-contents"}
                >
                    <SearchFilterLayerListInnerWrapper
                        isFullModal={props.isFullModal}
                        isLayerType={props.isLayerType}
                        filterLength={tempFilters.length}
                    >
                        {tempFilters.map((_, tempFilterIdx) => (
                            <SearchFilterLayerList
                                id={props.id}
                                key={tempFilterIdx}
                                filterItemWidth={props.filterItemWidth}
                                tempFilterIdx={tempFilterIdx}
                                tempFilters={tempFilters}
                                setTempFilters={(value: FilterType[]) => {
                                    setTempFilters([...value]);
                                }}
                                isFullModal={props.isFullModal}
                                isLayerType={props.isLayerType}
                            />
                        ))}
                    </SearchFilterLayerListInnerWrapper>
                </Scrollbar>
            </SearchFilterLayerListOuterWrapper>

            {/*mobile 에서 잔상이 남아서 flag 에 따라 dom 노출 여부 결정*/}
            {props.isSearchFilterOpen && (
                <SearchFilterBottomButtons
                    setFilters={props.setFilters}
                    close={props.close}
                    tempFilters={tempFilters}
                />
            )}
        </SearchFilterContentsContainer>
    );
};

export default SearchFilterContents;
SearchFilterContents.defaultProps = {
    isFullModal: false,
    isLayerType: false,
};
