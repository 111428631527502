import { ReactElement } from "react";
import styled from "styled-components";
import { getIsLogin } from "../../../../store/auth/authKeyStore";

export const NoTabNoHeaderBoxContainer = styled.div<{
    isGuestAddOpacityEffect: boolean;
    isLogin: boolean;
}>`
    background: #f5f5f7;
    border-radius: 16px;
    padding: 24px;
    overflow: hidden;
    opacity: ${({ isGuestAddOpacityEffect, isLogin }) =>
        !isLogin && isGuestAddOpacityEffect ? 0.3 : 1};
    pointer-events: ${({ isLogin, isGuestAddOpacityEffect }) =>
        isGuestAddOpacityEffect && !isLogin ? "none" : "auto"};
    @media ${(props) => props.theme.mobileL} {
        padding: 16px;
    }
`;

type Props = {
    children: ReactElement;
    isGuestAddOpacityEffect: boolean;
};

const NoTabNoHeaderBox = (props: Props): ReactElement | null => {
    const isLogin = getIsLogin();
    return (
        <NoTabNoHeaderBoxContainer
            isGuestAddOpacityEffect={props.isGuestAddOpacityEffect}
            isLogin={isLogin}
        >
            {props.children}
        </NoTabNoHeaderBoxContainer>
    );
};

export default NoTabNoHeaderBox;
