import { css } from "styled-components";

// height transition 은 창 너비 조절 시 잠깐 보이는 현상때문에 집어 넣음
export const HeaderLeftSlideTransition = css<{ isSearching: boolean }>`
    left: ${({ isSearching }) => (isSearching ? "0" : "100vw")};
    transition: ${({ isSearching }) =>
        isSearching
            ? "left 300ms ease"
            : "left 300ms ease , height 100ms linear 200ms, top 100ms linear 200ms"};
`;
