import { URL_CONSTANTS } from "../UrlConstants";
import { LinkMenuType } from "../../components/common/commonUiComponent/header/components/HeaderMenu/HeaderMenu";
import {
    AD_MANAGE_BUY_TEMPLATES,
    AD_MANAGE_SALE_SCHEDULE,
} from "../PageRegExp";

export const HEADER_MENUS: LinkMenuType[] = [
    {
        id: 0,
        name: "홈",
        path: "/",
        isNewTab: false,
        isNeedLogin: false,
        children: [
            {
                id: 1,
                name: "메인",
                path: "/",
                isNewTab: false,
                isNeedLogin: false,
            },
            {
                id: 2,
                name: "소개",
                path: "/intro",
                isNewTab: false,
                isNeedLogin: false,
            },
        ],
    },
    {
        id: 3,
        name: "광고",
        path: "/ad",
        isNewTab: false,
        isNeedLogin: false,
        children: [
            {
                id: 4,
                name: "광고 홈",
                path: "/ad",
                isNewTab: false,
                isNeedLogin: false,
            },
            {
                id: 5,
                name: "입찰",
                path: "/ad/root/date/0",
                isNewTab: false,
                isNeedLogin: false,
            },
            {
                id: 6,
                name: "판매 관리",
                path: AD_MANAGE_SALE_SCHEDULE,
                isNewTab: false,
                isNeedLogin: true,
            },
            {
                id: 7,
                name: "구매 관리",
                path: AD_MANAGE_BUY_TEMPLATES,
                isNewTab: false,
                isNeedLogin: true,
            },
        ],
    },
    {
        id: 8,
        name: "플리마켓",
        path: "/market",
        isNewTab: false,
        isNeedLogin: false,
        children: [
            {
                id: 9,
                name: "플리마켓 홈",
                path: "/market",
                isNewTab: false,
                isNeedLogin: false,
            },
            {
                id: 10,
                name: "포인트부스",
                path: "/market/point/root",
                isNewTab: false,
                isNeedLogin: false,
            },
            {
                id: 11,
                name: "아이템부스",
                path: "/market/root",
                isNewTab: false,
                isNeedLogin: false,
            },
            {
                id: 12,
                name: "거래 내역",
                path: "/market/manage/point",
                isNewTab: false,
                isNeedLogin: true,
            },
        ],
    },
    {
        id: 13,
        name: "지갑",
        path: "/wallet",
        isNewTab: false,
        isNeedLogin: true,
        children: [
            {
                id: 14,
                name: "포인트&토큰",
                path: "/wallet",
                isNewTab: false,
                isNeedLogin: true,
            },
            {
                id: 15,
                name: "토큰 합성",
                path: "/wallet/merge",
                isNewTab: false,
                isNeedLogin: true,
            },
            {
                id: 16,
                name: "컬렉션",
                path: "/my-wallet",
                isNewTab: false,
                isNeedLogin: true,
            },
        ],
    },
];

export type GlobalSearchResultTabIDsType =
    | "total"
    | "ad"
    | "project"
    | "item"
    | "collector";

export const GlobalSearchResultTabs: {
    name: string;
    id: GlobalSearchResultTabIDsType;
}[] = [
    {
        name: "전체",
        id: "total",
    },
    {
        name: "광고상품",
        id: "ad",
    },
    {
        name: "프로젝트",
        id: "project",
    },
    {
        name: "아이템",
        id: "item",
    },
    {
        name: "컬렉터",
        id: "collector",
    },
];

export const GlobalAllSearchResultCategories: {
    name: string;
    id: GlobalSearchResultTabIDsType;
    key: "adProductsList" | "projectsList" | "itemsList" | "collectorsList";
}[] = [
    {
        name: "광고상품",
        id: "ad",
        key: "adProductsList",
    },
    {
        name: "프로젝트",
        id: "project",
        key: "projectsList",
    },
    {
        name: "아이템",
        id: "item",
        key: "itemsList",
    },
    {
        name: "컬렉터",
        id: "collector",
        key: "collectorsList",
    },
];

export const UserProfileMyMenus: LinkMenuType[] = [
    {
        id: 1001,
        name: "내 광고",
        path: AD_MANAGE_SALE_SCHEDULE,
        isNeedLogin: true,
        imgPath: URL_CONSTANTS.ASSET_URL + "/icon/icon-louder-2.svg",
    },
    {
        id: 1002,
        name: "내 포인트&토큰",
        path: "/wallet",
        isNeedLogin: true,
        imgPath: URL_CONSTANTS.ASSET_URL + "/icon/icon-wallet-2.svg",
    },
    {
        id: 1003,
        name: "내 아이템",
        path: "/my-wallet",
        isNeedLogin: true,
        imgPath: URL_CONSTANTS.ASSET_URL + "/icon/icon-my-items-2.svg",
    },
    {
        id: 1004,
        name: "결제수단 관리",
        path: "/my/payments",
        isNeedLogin: true,
        imgPath: URL_CONSTANTS.ASSET_URL + "/icon/icon-payments-2.svg",
    },
];

export const HeaderMoreMenus: LinkMenuType[] = [
    {
        id: 990,
        name: "공지사항",
        path: "//about.saybuzz.io/ko/notice",
        icon: URL_CONSTANTS.ASSET_URL + "/icon/icon-noti-board.png",
        isNewTab: true,
        isNeedLogin: false,
    },
    {
        id: 991,
        name: "고객센터",
        path: "https://about.saybuzz.io/ko/help/faq",
        icon: URL_CONSTANTS.ASSET_URL + "/icon/icon-customer.png",
        isNewTab: true,
        isNeedLogin: false,
    },
    {
        id: 992,
        name: "이벤트",
        path: "//about.saybuzz.io/ko/event",
        icon: URL_CONSTANTS.ASSET_URL + "/icon/icon-event.png",
        isNewTab: true,
        isNeedLogin: false,
    },
    {
        id: 993,
        name: "가이드",
        path: "",
        icon: URL_CONSTANTS.ASSET_URL + "/icon/icon-guide.png",
        isNewTab: false,
        isNeedLogin: false,
    },
];
