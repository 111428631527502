import React, { ReactElement } from "react";
import OrderTabRow from "../components/OrderTabRow";
import OrderCountInput from "../components/OrderCountInput";
import {
    OrderTabButton,
    OrderTabContainer,
    OrderTabRowList,
    OrderTabUnderLine,
    OrderTabWarningRow,
} from "../components/OrderTabStyle";
import { numberToStringWithComma } from "../../../../../utils/Utils";
import { useOpenModal } from "../../../../../hooks/modal/useOpenModal";
import PointBuyModal from "./pointOrderModal/PointBuyModal";
import { useParams } from "react-router-dom";
import { usePointDataQuery } from "../../../../../query/point/usePointDataQuery";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { useOpenCommonErrorModal } from "../../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import styled from "styled-components";
import ApiResBoundary from "../../../apiResBoundary/ApiResBoundary";
import { DefaultLoadingFallbackContainer } from "../../../apiResBoundary/DefaultLoadingComponent";
import Spinner from "../../../commonUnitComponent/spinner/Spinner";
import { useSetAveragePrice } from "../../../../../hooks/common/useSetAveragePrice";

export const PointOrderBuyTabKeyDesc = styled.div`
    color: #888;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    margin-top: -6px;
`;

type Props = {};

const PointOrderBuyTab = (props: Props): ReactElement | null => {
    const params = useParams();
    const pointIdx = params.point_id ? params.point_id : "0";
    const isLogin = getIsLogin();
    const { openModal } = useOpenModal();
    const openPointBuyModal = async () => {
        return await openModal("point-buy-modal", "bottomSheet", (resolve) => {
            return (
                <PointBuyModal
                    pointIdx={pointIdx}
                    count={count}
                    setCount={setCount}
                    resolve={resolve}
                    pointBuyBrandRoyalty={pointBuyBrandRoyalty}
                />
            );
        });
    };

    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const pointBuyBrandRoyalty = 0.9;

    const { data: rawPointData } = usePointDataQuery(pointIdx);

    const {
        count,
        debouncedCount,
        setCount,
        maxCount,
        rawAveragePrice,
        isAveragePriceLoading,
        errorAveragePrice,
        remainingToken,
        warningMsg,
        isWarning,
    } = useSetAveragePrice("point", pointIdx, "rp");

    return (
        <OrderTabContainer>
            <OrderTabRow
                rowKey={"구매 수량"}
                rowValue={
                    <OrderCountInput
                        count={count}
                        setCount={setCount}
                        maxCount={maxCount}
                        isWarning={isWarning}
                    />
                }
            />
            <OrderTabUnderLine />

            <ApiResBoundary
                isNoData={false}
                error={errorAveragePrice}
                isLoading={isAveragePriceLoading}
                loadingFallback={
                    <DefaultLoadingFallbackContainer containerHeight={"136px"}>
                        <Spinner borderSize={6} height={24} width={24} />
                    </DefaultLoadingFallbackContainer>
                }
            >
                <OrderTabRowList>
                    <OrderTabRow
                        rowKey={"사용가능 RP"}
                        rowValue={numberToStringWithComma(remainingToken)}
                        rowValueUnit={"RP"}
                    />

                    <OrderTabRow
                        rowKey={"평균 단가"}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.averagePrice,
                        )}
                        rowValueUnit={"RP"}
                    />

                    <OrderTabRow
                        rowKey={"구매 금액"}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.totalAmount,
                        )}
                        rowValueUnit={"RP"}
                    />

                    <OrderTabRow
                        rowKey={"구매 로열티"}
                        tooltipMsg={"로열티는 브랜드별로 상이합니다."}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.royalty.brandRoyalty,
                        )}
                        rowValueUnit={"RP"}
                    />
                    <PointOrderBuyTabKeyDesc>
                        ({rawPointData.brandInfo.brand_name} :{" "}
                        {numberToStringWithComma(pointBuyBrandRoyalty)}%)
                    </PointOrderBuyTabKeyDesc>
                </OrderTabRowList>
            </ApiResBoundary>

            <OrderTabUnderLine />

            <ApiResBoundary
                isNoData={false}
                error={errorAveragePrice}
                isLoading={isAveragePriceLoading}
                loadingFallback={
                    <DefaultLoadingFallbackContainer
                        containerHeight={"inherit"}
                    >
                        <Spinner borderSize={6} height={24} width={24} />
                    </DefaultLoadingFallbackContainer>
                }
            >
                <OrderTabRowList>
                    <OrderTabRow
                        rowKey={"결제금액"}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.totalAmount +
                                rawAveragePrice.royalty.brandRoyalty,
                        )}
                        rowValueUnit={"RP"}
                        isPrice={true}
                    />
                    {isWarning && (
                        <OrderTabWarningRow>{warningMsg}</OrderTabWarningRow>
                    )}
                </OrderTabRowList>
            </ApiResBoundary>

            <OrderTabButton
                isDisabled={isWarning}
                onClick={async () => {
                    if (isLogin && !isWarning) {
                        if (debouncedCount === 0) {
                            await openCommonErrorModal({
                                title: "주문 불가능한 수량 입니다.",
                            });
                            return;
                        }
                        await openPointBuyModal();
                    } else {
                        await openLoginConfirmModal();
                    }
                }}
            >
                최저가 구매
            </OrderTabButton>
        </OrderTabContainer>
    );
};

export default PointOrderBuyTab;
