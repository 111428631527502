import { ReactElement } from "react";
import styled from "styled-components";
import AdManageMyTemplateList from "./components/adManageMyBidTemplateList/AdManageMyTemplateList";

export const AdBuyManageTemplatesContentsContainer = styled.div``;

type Props = {};

const AdBuyManageTemplatesContents = (props: Props): ReactElement | null => {
    return (
        <AdBuyManageTemplatesContentsContainer>
            <AdManageMyTemplateList />
        </AdBuyManageTemplatesContentsContainer>
    );
};

export default AdBuyManageTemplatesContents;
