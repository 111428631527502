import {
    TutorialInfoListType,
    TutorialSubCategoryInfoType,
} from "./TutorialModal";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";

export const TUTORIAL_INFO_LIST: TutorialInfoListType[] = [
    {
        upperCategoryTitle: "서비스 소개",
        upperCategoryIcon: `${URL_CONSTANTS.ASSET_URL}/logo/logo-saybuzz-only-icon.svg`,
        upperCategoryKey: "saybuzz",
        isOpen: true,
        subCategoryList: [
            {
                subCategoryTitle: "세이버즈란?",
                title: "세이버즈란?",
                desc: "디지털 광고·포인트·굿즈 3in1 플랫폼으로, 세이버즈 하나로 스트리머와 팬이 다양한 방법으로 소통할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-saybuzz.svg`,
                guideLink: "",
                key: "saybuzz",
                isSelected: true,
            },
            {
                subCategoryTitle: "광고 서비스란?",
                title: "광고 서비스란?",
                desc: "유저가 직접 광고주가 되어 스트리머와 플랫폼 광고지면에 ‘이벤트 · 기념일’ 등을 홍보할 수 있는 다이렉트 광고 거래 서비스입니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-ad.svg`,
                guideLink: "",
                actionLink: {
                    btnTitle: "광고 서비스 가이드 보기",
                    btnLink: "",
                },
                key: "saybuzz-ad",
                isSelected: false,
            },
            {
                subCategoryTitle: "포인트 서비스란?",
                title: "포인트&토큰 서비스란?",
                desc: "제휴 브랜드를 이용하며 획득한 포인트와 세이버즈 광고 판매를 통해 획득한 포인트를 거래하고 이를 합성하여 아이템 구매를 위한 토큰을 생성합니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-point.svg`,
                guideLink: "",
                actionLink: {
                    btnTitle: "포인트&토큰 서비스 가이드 보기",
                    btnLink: "",
                },
                key: "saybuzz-point",
                isSelected: false,
            },
            {
                subCategoryTitle: "아이템 서비스란?",
                title: "아이템 서비스란?",
                desc: "아이템을 직접 만들거나 크리에이터들이 만든 아이템을 구매해서, 재판매 또는 보관·전시할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-item.svg`,
                guideLink: "",
                actionLink: {
                    btnTitle: "아이템 서비스 가이드 보기",
                    btnLink: "",
                },
                key: "saybuzz-item",
                isSelected: false,
            },
        ],
    },
    {
        upperCategoryTitle: "광고 서비스",
        upperCategoryIcon: URL_CONSTANTS.ASSET_URL + "/icon/icon-louder-2.svg",
        upperCategoryKey: "ad",
        isOpen: false,
        subCategoryList: [
            {
                subCategoryTitle: "판매 등록하기",
                title: "내 지면을 판매등록해보세요!",
                desc: "개인의 채널 및 프로필 영역의 광고를 판매하면 RP를 획득할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-ad-register.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/ad/registration",
                actionLink: {
                    btnTitle: "판매등록 하러가기",
                    btnLink: "/ad/register/3",
                },
                key: "ad-sell",
                isSelected: false,
            },
            {
                subCategoryTitle: "광고 낙찰하기",
                title: "내 지면에 게재 될 광고를 직접 고를 수 있어요!",
                desc: "입찰받은 광고들 중 게재될 광고를 직접 선택해 적용할 수 있어요.",
                descAnnotation:
                    "자동낙찰을 활성화 할 경우 직접 낙찰하지 않아도 자동 낙찰됩니다.",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-ad-offer-success.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/ad/select_ad",
                actionLink: {
                    btnTitle: "낙찰 하러가기",
                    btnLink: "/ad/manage/sale/schedule",
                },
                key: "ad-offer-success",
                isSelected: false,
            },
            {
                subCategoryTitle: "방송중 광고 노출하기",
                title: "내가 판매한 광고를 방송화면에 노출할 수 있어요!",
                desc: "현재 채널 및 프로필에 노출중인 광고를 방송중에 노출해 더 극대화된 광고효과를 획득할 수 있어요.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-ad-expose.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/ad/ad-exposure",
                actionLink: {
                    btnTitle: "방송화면에 광고 노출 하러가기",
                    btnLink: "/ad/manage/sale/schedule",
                    btnLinkState: {
                        isOpenUrlModal: true,
                    },
                },
                key: "ad-broadcasting",
                isSelected: false,
            },
            {
                subCategoryTitle: "템플릿 생성하기",
                title: "사용할 광고소재 구성을 템플릿으로 직접 만들수 있어요!",
                desc: "이벤트 · 기념일 등 다양한 내용으로 ‘나만의 광고’를 직접 만들어 광고할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-ad-template.svg`,
                guideLink:
                    "https://about.saybuzz.io/ko/guide/ad/template_registration",
                actionLink: {
                    btnTitle: "템플릿 생성하러 가기",
                    btnLink: "/ad/manage/buy/templates",
                },
                key: "ad-template-register",
                isSelected: false,
            },
            {
                subCategoryTitle: "광고 구매하기",
                title: "원하는 영역을 구매해 생성한 템플릿을 게재할 수 있어요!",
                desc: "SOOP PC 메인화면, SAYBUZZ 플랫폼, 스트리머의 개인 채널 및 프로필 등 다양한 영역의 광고를 구매해 광고를 게재해 보세요!",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-ad-buy.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/ad/bid",
                actionLink: {
                    btnTitle: "광고 입찰하러 가기",
                    btnLink: "/ad/1/date/root",
                },
                key: "ad-buy",
                isSelected: false,
            },
        ],
    },
    {
        upperCategoryTitle: "포인트 서비스",
        upperCategoryKey: "point",
        upperCategoryIcon: URL_CONSTANTS.ASSET_URL + "/icon/icon-wallet-2.svg",
        isOpen: false,
        subCategoryList: [
            {
                subCategoryTitle: "RP 획득하기",
                title: "광고를 판매하고 RP를 획득해보세요!",
                desc: "개인의 채널 및 프로필 영역의 광고를 판매하면 RP를 획득할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-point-rp.svg`,
                guideLink:
                    "https://about.saybuzz.io/ko/guide/point/gain_method",
                actionLink: {
                    btnTitle: "광고 판매등록 하러가기",
                    btnLink: "/ad/register/3",
                },
                key: "point-rp",
                isSelected: false,
            },
            {
                subCategoryTitle: "SP 획득하기",
                title: "SOOP 방송을 시청하고 SP를 획득해보세요!",
                desc: "SOOP 방송을 시청하고 SP를 획득해보세요!",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-point-sp-get.svg`,
                guideLink:
                    "https://about.saybuzz.io/ko/guide/point/gain_method",
                actionLink: {
                    btnTitle: "방송시청 하러가기",
                    btnLink: "https://www.sooplive.co.kr/",
                },
                key: "point-sp",
                isSelected: false,
            },
            {
                subCategoryTitle: "SP 구매하기",
                title: "포인트 부스에서 RP로 SP를 구매할 수 있어요!",
                desc: "광고를 판매해 얻은 RP로 포인트 부스에서 SP를 구매 할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-point-sp-buy.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/point/purchase",
                actionLink: {
                    btnTitle: "SP 구매 하러 가기",
                    btnLink: "/market/point/2",
                },
                key: "point-buy-sp",
                isSelected: false,
            },
            {
                subCategoryTitle: "SP 판매하기",
                title: "포인트 부스에서 SP를 판매하여 RP를 얻을 수 있어요!",
                desc: "SOOP 방송을 시청해 얻은 SP를 포인트 부스에서 판매해 RP를 얻을 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-point-sp-sell.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/point/sale",
                actionLink: {
                    btnTitle: "SP 판매 하러 가기",
                    btnLink: "/market/point/2",
                },
                key: "point-sell-sp",
                isSelected: false,
            },
            {
                subCategoryTitle: "포인트 확인하기",
                title: "지갑에서 보유중인 포인트를 확인할 수 있어요!",
                desc: "‘지갑 → 포인트&토큰’ 에서 현재 보유중인 ST, RP, SP를 확인할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-point-check.svg`,
                guideLink:
                    "https://about.saybuzz.io/ko/guide/point/check_amount",
                actionLink: {
                    btnTitle: "보유량 확인 하러 가기",
                    btnLink: "/wallet",
                },
                key: "point-check-sp",
                isSelected: false,
            },
            {
                subCategoryTitle: "토큰합성 하기",
                title: "RP와 SP를 합성해 ST를 생성해 보세요!",
                desc: "‘지갑 → 토큰 합성’ 에서 보유중인 RP와 SP를 합성해 ST를 생성할 수 있습니다.",
                descAnnotation:
                    "생성한 ST로 아이템부스에서 아이템을 구매할 수 있습니다.",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-point-merge.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/point/merge",
                actionLink: {
                    btnTitle: "토큰 합성 하러 가기",
                    btnLink: "/wallet/merge",
                },
                key: "point-merge-token",
                isSelected: false,
            },
        ],
    },
    {
        upperCategoryTitle: "아이템 서비스",
        upperCategoryIcon:
            URL_CONSTANTS.ASSET_URL + "/icon/icon-my-items-2.svg",
        upperCategoryKey: "item",
        isOpen: false,
        subCategoryList: [
            {
                subCategoryTitle: "아이템 만들기",
                title: "소중한 순간을 아이템으로 만들어보세요!",
                desc: "방송 중 하이라이트 · 포토카드 등으로 스트리머와 팬 누구든 손쉽게 아이템을 생성할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-item-make.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/item/create",
                actionLink: {
                    btnTitle: "아이템 생성 하러 가기",
                    btnLink: "/market/add-item",
                },
                key: "item-make",
                isSelected: false,
            },
            {
                subCategoryTitle: "아이템 판매 하기",
                title: "아이템 부스에서 아이템을 판매할 수 있어요!",
                desc: "생성한 아이템을 판매해 ST를 획득할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-item-sell.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/item/sale",
                actionLink: {
                    btnTitle: "아이템 판매 하러 가기",
                    btnLink: "/market/root",
                },
                key: "item-sell",
                isSelected: false,
            },
            {
                subCategoryTitle: "아이템 구매 하기",
                title: "아이템 부스에서 아이템을 구매할 수 있어요!",
                desc: "크리에이터가 생성한 아이템을 ST로 구매할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-item-buy.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/item/purchase",
                actionLink: {
                    btnTitle: "아이템 구매 하러 가기",
                    btnLink: "/market/root",
                },
                key: "item-buy",
                isSelected: false,
            },
            {
                subCategoryTitle: "아이템 전송 하기",
                title: "생성한 아이템을 팬분들과 나누어 보세요!",
                desc: "아이템은 거래뿐 아니라 전송을 통해 무료로 공유할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-item-transfer.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/item/send",
                actionLink: {
                    btnTitle: "아이템 전송 하러 가기",
                    btnLink: "/my-wallet",
                },
                key: "item-transfer-item",
                isSelected: false,
            },
            {
                subCategoryTitle: "대표아이템 등록 하기",
                title: "보유한 아이템을 대표아이템으로 등록해 보세요!",
                desc:
                    "등록된 대표아이템은 방송중 채팅창 프로필을 클릭하면\n" +
                    "확인 할 수 있습니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-item-title.svg`,
                guideLink:
                    "https://about.saybuzz.io/ko/guide/item/representative_item",
                actionLink: {
                    btnTitle: "대표아이템 등록 하러 가기",
                    btnLink: "/my-wallet",
                },
                key: "item-register-title-item",
                isSelected: false,
            },
            {
                subCategoryTitle: "확장프로그램 이용 하기",
                title: "아이템 자동뽑기로 더 재미있는 콘텐츠를 만들어 보세요!",
                desc:
                    "방송 중 후원을 받으면 후원을 한 팬분께 자동으로 아이템이\n" +
                    "전송됩니다.",
                descAnnotation: "",
                imgSrc: `${URL_CONSTANTS.ASSET_URL}/guide/guide-item-extension.svg`,
                guideLink: "https://about.saybuzz.io/ko/guide/item/extention1",
                actionLink: {
                    btnTitle: "확장프로그램 추가 하러 가기",
                    btnLink:
                        "https://extension.sooplive.co.kr/detail.php?ext_id=caec46a5d466bf4e62f4b658ae81cacf",
                },
                key: "item-extension",
                isSelected: false,
            },
        ],
    },
];
