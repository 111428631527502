import { useQuery } from "react-query";
import { ItemApi } from "../../apis/ItemApi";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";

export type UrlMetaDataType = {
    extract_bool: boolean;
    favicon_url: string;
    title: string;
};

export const useExtractUrlMetaQuery = (url: string) => {
    const queryResult = useQuery<UrlMetaDataType, Error>({
        queryFn: () => {
            return url !== ""
                ? ItemApi.extractUrlMetaData(url)
                : new Promise((resolve) => {
                      resolve({
                          extract_bool: false,
                          favicon_url: "",
                          title: "",
                      });
                  });
        },
        queryKey: ["extract-meta-data-" + url],
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            console.info("error : extract url meta data : ", errorResult);
        },
        onSuccess: () => {},
    });

    return {
        ...queryResult,
        data: queryResult.data || {
            extract_bool: false,
            favicon_url: "",
            title: "",
        },
    };
};
