import { useOpenModal } from "../useOpenModal";
import CommonStatusInfoModal, {
    statusInfoListItemType,
} from "../../../components/common/modal/commonStatusInfoModal/CommonStatusInfoModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";

export function useOpenCommonStatusInfoModal(id: string) {
    const { openModal } = useOpenModal();

    return {
        openCommonStatusInfoModal: async (params: {
            title: string;
            imgSrc?: string;
            descList?: string[];
            statusInfoTitleWidth: string;
            statusInfoList: statusInfoListItemType[];
        }): Promise<boolean> => {
            return await openModal(
                id + "-status-info-modal",
                "bottomSheet",
                (resolve) => (
                    <CommonStatusInfoModal resolve={resolve} {...params} />
                ),
            );
        },
    };
}

export const ItemStatusInfoParams = {
    title: "상태 유형별 정의",
    imgSrc: URL_CONSTANTS.ASSET_URL + "/guide/item-status-info.svg",
    statusInfoTitleWidth: "72px",
    statusInfoList: [
        {
            title: "· 출시대기",
            desc: "프로젝트에 아이템을 등록했으나, 아직 검토의뢰를 하지 않은 아이템.",
        },
        {
            title: "· 검토중",
            desc: "아이템 출시 신청을 의뢰한 아이템.",
        },
        {
            title: "· 반려",
            desc: "검토 결과 운영정책에 위반되는 사유로 반려 받은 아이템 재 검토 의뢰시 다시 ‘검토중’으로 적용",
        },
        {
            title: "· 판매중",
            desc: "현재 판매등록되어 구매가 가능한 아이템.",
        },
        {
            title: "· 판매 대기",
            desc: "판매등록이 가능하나 판매중이지 않은 아이템.",
        },
        {
            title: "· 귀속됨",
            desc: "귀속 아이템이 이미 ‘전송’ 및 ‘거래’가 되어 특정 유저의 보유아이템 리스트로 이동해 더 이상 ‘전송’ 혹은 ‘거래’가 불가능한 아이템.",
        },
    ],
    descList: [
        "· 신청취소는 '검토중' 단계에서만 가능",
        "· 출시 심사실패 시 '검토중'으로 복귀",
    ],
};

export const MyAdBuyOfferStatusInfoParams = {
    title: "입찰 상태",
    statusInfoTitleWidth: "72px",
    statusInfoList: [
        {
            title: "입찰",
            desc: "광고 구매자가 해당 광고상품에 입찰 했을 때의 상태",
        },
        {
            title: "입찰 취소",
            desc: "입찰한 건이 구매자 혹은 판매자가 직접 취소하거나 시스템에서 취소된 상태",
        },
        {
            title: "낙찰",
            desc: "판매자가 입찰건을 낙찰하거나, 지정가 구매 혹은 즉시 구매로 구매자가 광고를 구매한 상태",
        },
        {
            title: "미채택",
            desc: "판매자가 미채택하거나 채택 시도했음에도 결제에 실패하는 등의 이유로 입찰되지 못했을 때의 상태",
        },
        {
            title: "낙찰 취소",
            desc: "낙찰한 건이 구매자 혹은 판매자가 직접 취소하거나 시스템에서 취소된 상태",
        },
    ],
};

export const MyAdBuyRoundStatusInfoParams = {
    title: "광고 상태",
    statusInfoTitleWidth: "108px",
    statusInfoList: [
        {
            title: "입찰 기간",
            desc: "판매가 시작되어 입찰 혹은 지정가 구매가 가능한 기간",
        },
        {
            title: "최종 검수기간",
            desc: "입찰이 마감되고, 낙찰자가 광고 템플릿 검수에 집중하는 기간",
        },
        {
            title: "시스템 준비기간",
            desc: "광고의 적용 및 신규 광고일정을 생성하는 등의 시스템 조치 기간",
        },
        {
            title: "광고 게재기간",
            desc: "광고가 게재되는 기간",
        },
        {
            title: "광고 종료",
            desc: "광고 게재기간이 종료된 상태",
        },
        {
            title: "유찰",
            desc: "낙찰되어 게재되는 광고가 없어 유찰된 상태",
        },
        {
            title: "판매 중단",
            desc: "광고 판매를 판매자가 직접 중단하거나 시스템에서 중단 조치한 상태",
        },
        {
            title: "광고 중단",
            desc: "게재중이던 광고를 판매자가 직접 중단하거나 시스템에서 중단 조치한 상태",
        },
    ],
};

export const MyAdSellPaymentAdjustStatusInfoParams = {
    title: "정산 상태",
    statusInfoTitleWidth: "92px",
    statusInfoList: [
        {
            title: "결제 완료",
            desc: "낙찰되어 결제가 완료된 상태",
        },
        {
            title: "정산 대기",
            desc: "결제가 완료된 회차가 광고 게재가 종료된 상태",
        },
        {
            title: "정산 완료",
            desc: "정상적으로 광고집행이 되었음이 확인되어 정산이 진행된 상태",
        },
        {
            title: "분쟁 발생",
            desc: "정상적인 광고집행이 이루어지지 않아 분쟁이 발생한 상태",
        },
        // {
        //     title: "환불 완료",
        //     desc: "결제되었던 전액이 환불된 상태",
        // },
        // {
        //     title: "일부 환불 완료",
        //     desc: "결제되었던 금액 중 일부가 환불된 상태",
        // },
    ],
};
