import { useRef, useState } from "react";
import { InputTextDataType } from "../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import {
    DefaultExceptionUiHandlerType,
    ExceptionUiHandlerType,
} from "../../../../../apis/ErrorHandler/callApiExcpetionHandler";

export const DEFAULT_AD_MIN_PRICE = "5,000";

export function useGetAdMinPriceState(initInputData?: InputTextDataType) {
    const [adMinPrice, setAdMinPrice] = useState<InputTextDataType>(
        initInputData || {
            value: DEFAULT_AD_MIN_PRICE,
            isWarning: false,
            warningMsg: "",
        },
    );

    const adMinPriceContainerRef = useRef<HTMLDivElement>(null);

    const exceptionUiHandler: ExceptionUiHandlerType = {
        ...DefaultExceptionUiHandlerType,
        inputText: adMinPrice,
        setInputText: setAdMinPrice,
        errorTarget: {
            targetName: "AdMinPrice",
        },
        targetContainerRef: adMinPriceContainerRef,
        isDuplicateCheck: false,
    };

    return {
        adMinPriceState: {
            adMinPrice: adMinPrice,
            setAdMinPrice: setAdMinPrice,
            adMinPriceContainerRef: adMinPriceContainerRef,
        },
        adMinPriceExceptionUiHandler: exceptionUiHandler,
    };
}
