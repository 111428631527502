export const SCROLL_BAR_ROOT_TYPE = "root";
export const SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS =
    "double-side-bar-layout-contents";
export const SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_LEFT_SIDE_BAR =
    "double-side-bar-layout-left-side-bar";

export const SCROLL_BAR_NORMAL_TYPE = "normal";

export type ScrollbarType =
    | "root"
    | "double-side-bar-layout-left-side-bar"
    | "double-side-bar-layout-contents"
    | "normal";
