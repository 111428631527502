import { ReactElement, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import CommonTable, {
    TableHeaderDataType,
} from "../../../commonUnitComponent/commonTable/CommonTable";
import OrderHistoryTabFilterHeaderItem from "../components/OrderHistoryTabFilterHeaderItem";
import {
    CommonTC,
    CommonTCBold,
    CommonTCContainer,
    CommonTCSmall,
    CommonTCThin,
} from "../../../commonUnitComponent/commonTable/components/CommonTableStyle";
import {
    getOrderHistoryStatus,
    numberToStringWithComma,
} from "../../../../../utils/Utils";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import ApiResBoundary from "../../../apiResBoundary/ApiResBoundary";
import {
    spreadMyItemOrderListPages,
    useGetMyItemOrderListQuery,
} from "../../../../../query/my/myItem/useGetMyItemOrderListQuery";
import {
    getOrderItemReceiptInfo,
    useOpenItemReceiptModal,
} from "../../../../../hooks/modal/openModal/useOpenItemReceiptModal";
import { CommonTabBoxLoadingContainerHasPB } from "../../../commonUnitComponent/commonTabBox/CommonTabBox";
import Spinner from "../../../commonUnitComponent/spinner/Spinner";

export const ItemOrderHistoryTabBodyStateButtonColumn = styled.div<{
    isSell: boolean;
}>`
    display: inline-block;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px 0;
    width: 80px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${({ isSell }) => (isSell ? "#2E8EFF" : "#FF4671")};
    cursor: pointer;

    transition: background-color 300ms ease;
    text-align: center;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(13, 13, 26, 0.12);
        }
    }
`;

export const ItemOrderHistoryTabContainer = styled.div``;

const TypeColumnWidth = css`
    width: 80px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

const DateColumnWidth = css`
    width: 80px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

const CountColumnWidth = css`
    width: 54px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

const PriceColumnWidth = css`
    width: 54px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

export const OrderHistoryTypeColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${TypeColumnWidth};
`;

export const OrderHistoryDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${DateColumnWidth};
`;

export const OrderHistoryCountColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${CountColumnWidth};
`;

export const OrderHistoryPriceColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${PriceColumnWidth};
`;

type Props = {};

const ItemOrderHistoryTab = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const typeFilterList = [
        { key: "all", title: "전체" },
        { key: "sell", title: "판매" },
        { key: "buy", title: "구매" },
    ];
    const [selectedTypeIdx, setSelectedTypeIdx] = useState(0);
    const { openItemReceiptModal } = useOpenItemReceiptModal();

    const {
        data: rawMarketItemOrderList,
        isLoading: isMarketItemOrderListLoading,
        error: errorMarketItemOrderList,
        hasNextPage: hasMarketItemOrderListNextPage,
        fetchNextPage: fetchMarketItemOrderListNextPage,
    } = useGetMyItemOrderListQuery({
        limit: 20,
        page_no: 1,
        item_idx: itemIdx,
    });

    const marketItemOrderList = spreadMyItemOrderListPages(
        rawMarketItemOrderList.pages,
    );

    const hasMarketItemOrderListNextPageRef = useRef(
        hasMarketItemOrderListNextPage,
    );
    useEffect(() => {
        hasMarketItemOrderListNextPageRef.current =
            hasMarketItemOrderListNextPage;
    }, [hasMarketItemOrderListNextPage]);

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "typeColumn",
            component: (
                <OrderHistoryTypeColumnContainer
                    textAlign={"center"}
                    key={"typeColumn"}
                >
                    <OrderHistoryTabFilterHeaderItem
                        filterList={typeFilterList}
                        selectedIdx={selectedTypeIdx}
                        setSelectedIdx={setSelectedTypeIdx}
                    />
                </OrderHistoryTypeColumnContainer>
            ),
        },
        {
            key: "dateColumn",
            component: (
                <OrderHistoryDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    주문일시
                </OrderHistoryDateColumnContainer>
            ),
        },
        {
            key: "countColumn",
            component: (
                <OrderHistoryCountColumnContainer
                    textAlign={"center"}
                    key={"countColumn"}
                >
                    <CommonTCContainer>
                        <CommonTCBold textAlign={"center"}>
                            체결수량
                        </CommonTCBold>
                        <CommonTCThin textAlign={"center"}>
                            주문수량
                        </CommonTCThin>
                    </CommonTCContainer>
                </OrderHistoryCountColumnContainer>
            ),
        },
        {
            key: "priceColumn",
            component: (
                <OrderHistoryPriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <CommonTCContainer>
                        <CommonTCBold textAlign={"center"}>
                            체결단가
                        </CommonTCBold>
                        <CommonTCThin textAlign={"center"}>
                            주문단가
                        </CommonTCThin>
                    </CommonTCContainer>
                </OrderHistoryPriceColumnContainer>
            ),
        },
    ];

    const data = marketItemOrderList.map((item) => {
        return {
            typeColumn: (
                <OrderHistoryTypeColumnContainer
                    textAlign={"center"}
                    key={"typeColumn"}
                >
                    <ItemOrderHistoryTabBodyStateButtonColumn
                        isSell={item.order_sub_type === "2"}
                        onClick={async () => {
                            await openItemReceiptModal(
                                getOrderItemReceiptInfo(item),
                            );
                        }}
                    >
                        {getOrderHistoryStatus(
                            item.order.order_status,
                            item.order_sub_type,
                        )}
                    </ItemOrderHistoryTabBodyStateButtonColumn>
                </OrderHistoryTypeColumnContainer>
            ),
            dateColumn: (
                <OrderHistoryDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    <CommonTCContainer>
                        <CommonTCBold textAlign={"center"}>
                            {format(
                                new Date(item.order.ordered_at),
                                "HH:mm:ss",
                            )}
                        </CommonTCBold>
                        <CommonTCSmall textAlign={"center"}>
                            {format(
                                new Date(item.order.ordered_at),
                                "yyyy-MM-dd",
                            )}
                        </CommonTCSmall>
                    </CommonTCContainer>
                </OrderHistoryDateColumnContainer>
            ),
            countColumn: (
                <OrderHistoryCountColumnContainer
                    textAlign={"center"}
                    key={"countColumn"}
                >
                    <CommonTCContainer>
                        <CommonTCBold textAlign={"center"}>
                            {numberToStringWithComma(item.settled_quantity_sum)}
                        </CommonTCBold>
                        <CommonTCThin textAlign={"center"}>
                            {numberToStringWithComma(item.order_sub_quantity)}
                        </CommonTCThin>
                    </CommonTCContainer>
                </OrderHistoryCountColumnContainer>
            ),
            priceColumn: (
                <OrderHistoryPriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <CommonTCContainer>
                        <CommonTCBold textAlign={"center"}>
                            {item.settled_quantity_sum === 0
                                ? 0
                                : numberToStringWithComma(
                                      item.order_sub_unit_price,
                                  )}
                        </CommonTCBold>
                        <CommonTCThin textAlign={"right"}>
                            {numberToStringWithComma(item.order_sub_unit_price)}
                        </CommonTCThin>
                    </CommonTCContainer>
                </OrderHistoryPriceColumnContainer>
            ),
        };
    });

    return (
        <ItemOrderHistoryTabContainer>
            <ApiResBoundary
                isLoading={isMarketItemOrderListLoading}
                isNoData={false}
                error={errorMarketItemOrderList}
                loadingFallback={
                    <CommonTabBoxLoadingContainerHasPB>
                        <Spinner />
                    </CommonTabBoxLoadingContainerHasPB>
                }
            >
                <CommonTable
                    id="item-order-history-box"
                    tableHeaderData={tableHeaderData}
                    tableBodyData={data}
                    tableBodyHeight={"486px"}
                    noDataMsg={"주문내역이 없습니다."}
                    infiniteScrollCallback={async (showSpinner: () => void) => {
                        if (hasMarketItemOrderListNextPageRef.current) {
                            showSpinner();
                            await fetchMarketItemOrderListNextPage();
                        }
                    }}
                />
            </ApiResBoundary>
        </ItemOrderHistoryTabContainer>
    );
};

export default ItemOrderHistoryTab;
