import { FilterType } from "../../../common/commonUiComponent/searchFilter/SearchFilter";
import { MarketProjectLeftSideBarHeaderFilters } from "./MarketProejctSideBarHeaderMenus";
import { ItemCategory } from "../../../../constants/category/ItemCategory";

export const MarketProjectSideBarSearchFilterExceptionOnChangeHandlers = {
    brandFilterHandler: (isSelected: boolean, tempFilters: FilterType[]) => {
        if (isSelected) {
            if (!tempFilters.some((filter) => filter.key === "category"))
                return [
                    ...tempFilters.slice(0, 1),
                    ItemCategory,
                    ...tempFilters.slice(1),
                ];
            else {
                return tempFilters;
            }
        } else {
            return tempFilters.filter((filter) => filter.key !== "category");
        }
    },
    networkFilterHandler: (
        isSelectedPolygon: boolean,
        isSelectedInApp: boolean,
        tempFilters: FilterType[],
    ) => {
        return tempFilters.map((filter) => {
            if (filter.key === "erc") {
                if (isSelectedInApp && !isSelectedPolygon) {
                    return {
                        ...filter,
                        filterList:
                            MarketProjectLeftSideBarHeaderFilters.filter(
                                (originalFilter) =>
                                    originalFilter.key === "erc",
                            )[0].filterList.filter(
                                (filterListItem) => filterListItem.key === "0",
                            ),
                    };
                } else if (!isSelectedInApp && isSelectedPolygon) {
                    return {
                        ...filter,
                        filterList:
                            MarketProjectLeftSideBarHeaderFilters.filter(
                                (originalFilter) =>
                                    originalFilter.key === "erc",
                            )[0].filterList.filter(
                                (filterListItem) => filterListItem.key !== "0",
                            ),
                    };
                } else {
                    return {
                        ...filter,
                        filterList:
                            MarketProjectLeftSideBarHeaderFilters.filter(
                                (originalFilter) =>
                                    originalFilter.key === "erc",
                            )[0].filterList,
                    };
                }
            }
            return filter;
        });
    },
    releaseBoolFilterHandler: (
        releaseBoolTureChecked: boolean,
        releaseBoolFalseChecked: boolean,
        tempFilters: FilterType[],
    ) => {
        return tempFilters.map((filters) => {
            if (filters.key === "transaction_bool") {
                const releaseBoolFilterList =
                    tempFilters.find((filter) => filter.key === "release_bool")
                        ?.filterList || [];
                // 출시완료 미선택 출시 대기 선택 시
                if (!releaseBoolTureChecked && releaseBoolFalseChecked) {
                    return {
                        ...filters,
                        filterList: filters.filterList.filter(
                            (filterItem) => filterItem.key !== "0",
                        ),
                    };
                } else {
                    return (
                        MarketProjectLeftSideBarHeaderFilters.find(
                            (originalFilter) =>
                                originalFilter.key === "transaction_bool",
                        ) || {
                            title: "",
                            isCheckbox: true,
                            key: "",
                            filterList: [],
                        }
                    );
                }
            } else {
                return filters;
            }
        });
    },
};
