import { useOpenModal } from "../useOpenModal";
import React, { ReactElement } from "react";
import CommonConfirmModal from "../../../components/common/modal/commonConfirmModal/CommonConfirmModal";
import { CommonBtnStringType } from "../../../components/common/commonStyle/CommonButtonStyle";

export type CommonConfirmModalInfoType = {
    title?: string;
    desc: string | ReactElement;
    buttonTitle?: string;
    buttonType?: CommonBtnStringType;
    closeBtnTitle?: string;
    isTwoBtn?: boolean;
};

export function useOpenCommonConfirmModal() {
    const { openModal } = useOpenModal();

    return {
        openCommonConfirmModal: async (
            params: CommonConfirmModalInfoType,
        ): Promise<boolean> => {
            return openModal(
                "common-confirm-modal-" +
                    encodeURIComponent(params.desc.toString()),
                "modal",
                (resolve: (value: boolean) => void) => {
                    return <CommonConfirmModal resolve={resolve} {...params} />;
                },
            );
        },
    };
}
