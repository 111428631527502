import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";
import {
    ExternalAdGetSpaceInfoApiParamsType,
    ExternalAdSpaceInfoType,
} from "../query/externalAd/useGetExternalAdSpaceInfoQuery";

export const EXTERNAL_API_ERROR_CODE = {
    CHECK: "CheckPM",
};

export const ExternalAdApi = {
    getSpaceInfo: async (
        brandId: string,
        campaignId: string,
        spaceId: string,
        params?: ExternalAdGetSpaceInfoApiParamsType,
    ): Promise<ExternalAdSpaceInfoType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.EXTERNAL_AD_URL}/ad/brand/${brandId}/campaign/${campaignId}/space/${spaceId}`,
                    {
                        params: params,
                        headers: {
                            "Saybuzz-Api-Version": "1.0",
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    if (res.data.result) {
                        resolve(res.data.data);
                    } else {
                        reject(res.data.data);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
