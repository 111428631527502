import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";
import { FileUploadParams } from "../query/common/useFileUploadMutation";
import { CheckWhiteListApiParam } from "../query/common/useCheckWhiteListMutation";
import { VersionDataType } from "../query/common/useGetVersionQuery";

export const CommonApi = {
    fileUpload: async (params: FileUploadParams): Promise<null> => {
        let formData = new FormData();
        formData.append("directory", params.directory);
        formData.append("file", params.file);

        if (params.circular_mark_bool) {
            formData.append("circular_mark_bool", "Y");
        }

        return new Promise((resolve, reject) => {
            axios
                .post(`${URL_CONSTANTS.API_URL}/file`, formData, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    checkWhiteList: async (params: CheckWhiteListApiParam): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/white-list/check`, {
                    params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getVersion: async (): Promise<VersionDataType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/version`)
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
