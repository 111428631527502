import { ReactElement } from "react";
import * as CarouselStyle from "../../CarouselStyle";

type Props = {
    isShowIndicator: boolean;
    dataLength: number;
    indicatorIndex: number;
    currentIndex: number;
    renderIndicator?: (value?: any) => ReactElement;
    clickEvent: (value: number) => void;
};
const CarouselPageIndicator = (props: Props): ReactElement | null => {
    return (
        <a>
            {props.isShowIndicator && !props.renderIndicator && (
                <CarouselStyle.CarouselIndicator
                    onClick={() => {
                        props.clickEvent(props.indicatorIndex);
                    }}
                    length={props.dataLength}
                    currentIndex={props.currentIndex}
                    indicatorIndex={props.indicatorIndex}
                />
            )}
            {props.isShowIndicator && props.renderIndicator && (
                <div
                    onClick={() => {
                        props.clickEvent(props.indicatorIndex);
                    }}
                >
                    {props.renderIndicator({
                        length: props.dataLength,
                        currentIndex: props.currentIndex,
                        indicatorIndex: props.indicatorIndex,
                    })}
                </div>
            )}
        </a>
    );
};

export default CarouselPageIndicator;
