import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { FooterLogo } from "./Footer";
import FooterAppDownloadBtn from "./components/FooterAppDownloadBtn";
import FooterPathList from "./components/footerPathList/FooterPathList";
import FooterCompanyInfoList from "./components/FooterCompanyInfoList";
import FooterPolicyList from "./components/FooterPolicyList";
import FooterCopyRight from "./components/FooterCopyRight";

export const FooterMobileTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
`;

export const FooterMobileTypeLogoContainer = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

export const FooterMobileTypeUnderLine = styled.div`
    width: 100%;
    height: 1px;
    background: #e5e5e5;
`;

type Props = {
    footerWrapperWidth: number;
};

const FooterMobileType = (props: Props): ReactElement | null => {
    return (
        <FooterMobileTypeContainer>
            <FooterMobileTypeLogoContainer>
                <FooterLogo
                    src={
                        URL_CONSTANTS.ASSET_URL + "/logo/logo-saybuzz-black.svg"
                    }
                />
            </FooterMobileTypeLogoContainer>
            <FooterPathList
                footerWrapperWidth={props.footerWrapperWidth}
                type={"mobile"}
            />
            <FooterAppDownloadBtn />
            <FooterMobileTypeUnderLine />
            <FooterCompanyInfoList />
            <FooterPolicyList />
            <FooterCopyRight />
        </FooterMobileTypeContainer>
    );
};

export default FooterMobileType;
