import { atom } from "jotai/index";

// perRow 계열 뒤로가기(re-render)시에도 값이 유지되어야 하므로 atom 으로 뺀다
export const marketHomeCreatorPerRowAtom = atom(0);

export const marketHomeItemPerRowAtom = atom(0);

export const commonProjectListPerRowAtom = atom(0);

export const adHomePlatformAdCardPerRowAtom = atom(0);

export const adHomePersonalAdCardPerRowAtom = atom(0);

export const MarketProjectItemListPerRowAtom = atom(0);

export const CollectionItemListPerRowAtom = atom(0);
