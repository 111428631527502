import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import isMobileLeftSideBarOpenAtom from "../../../../store/isMobileLeftSideBarOpenAtom";
import { useSetAtom } from "jotai";
import {
    getNetworkBackground,
    numberToStringWithComma,
    onErrorImg,
    shallowEqual,
} from "../../../../utils/Utils";
import { ProjectListDataType } from "../../../../query/project/useProjectListQuery";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useLocalStorage } from "usehooks-ts";
import CssFilterConverter from "css-filter-converter";
import ImageWithSkeleton from "../../../common/commonUiComponent/skeletonImage/ImageWithSkeleton";
import SquareImg from "../../../common/commonUiComponent/squqreImg/SquareImg";
import Shimmer from "../../../common/commonUiComponent/shimmer/Shimmer";

export const MarketProjectLeftSideBarListItemContainer = styled(Link)`
    display: block;
`;

export const MarketProjectLeftSideBarListItemContents = styled.div<{
    isSelected: boolean;
}>`
    position: relative;
    padding: 10px 8px;
    display: flex;
    border-radius: 12px;
    transition: background-color ease 300ms;
    background: ${({ isSelected }) => (isSelected ? "#fbf2fc" : "white")};

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isSelected }) =>
                isSelected ? "#fbf2fc" : "#f5f5f7"};
        }
    }
`;
export const MarketProjectLeftSideBarListItemThumbnailContainer = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 30px;
    margin-right: 10px;
    overflow: hidden;
    background-color: #f5f5f7;
`;

export const MarketProjectLeftSideBarListItemInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 58px);
`;

export const MarketProjectLeftSideBarListItemInfoTitleContainer = styled.div<{
    isSelected: boolean;
}>`
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    display: flex;
    align-items: center;
    height: 19px;

    transition: color 300ms ease;
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#222222")};
`;

export const MarketProjectLeftSideBarListItemInfoNetworkBadge = styled.div<{
    backgroundColor: string;
}>`
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    /* identical to box height, or 11px */

    display: flex;
    align-items: center;

    color: #ffffff;
    padding: 4px 6px;
    border-radius: 10px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    margin-right: 6px;
    flex-shrink: 0;
`;

export const MarketProjectLeftSideBarListItemInfoTitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 2px;
    height: 17px;
`;

export const MarketProjectLeftSideBarListItemInfoDesc = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #555555;
    margin-top: 6px;
`;
export const MarketProjectLeftSideBarListItemInfoLikeContainer = styled.div`
    margin-top: 8px;
    color: #555;
    font-size: 12px;
    line-height: 100%;
`;

export const MarketProjectLeftSideBarRecentListItemRemoveIcon = styled.img`
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0, -50%);
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 8px;
    transition: all 300ms ease;
    opacity: 0;

    &:hover {
        background: rgba(13, 13, 26, 0.06);
    }

    ${MarketProjectLeftSideBarListItemContainer}:hover & {
        opacity: 1;
    }

    @media ${(props) => props.theme.mobileL} {
        opacity: 1;
    }
`;

type Props = {
    data: ProjectListDataType;
    isRecent: boolean;
};

const MarketProjectLeftSideBarListItem = (
    props: Props,
): ReactElement | null => {
    const params = useParams();
    const projectIdx = params.project_id ? params.project_id : "0";
    const isSelected = props.data.project_idx === projectIdx;
    const setIsMobileLeftSideBarOpen = useSetAtom(isMobileLeftSideBarOpenAtom);
    const [recentProjectList, setRecentProjectList] = useLocalStorage<string[]>(
        "recentProjectList",
        [],
    );

    const thumbnailRef = useRef<HTMLImageElement>(null);
    const [isImageLoading, setIsImageLoading] = useState(false);

    useEffect(() => {
        // 이미지가 이미 load 된 뒤인 경우 = 프로젝트 페이지에서 아이템 상세 페이지로 이동한 경우 (혹은 그 반대) 강제로 false 로 만들어둠
        // ref 가 정의 되기전에는 일단 loading 다 된걸로 처리. 로 해둠
        setIsImageLoading(
            !(
                typeof thumbnailRef.current?.complete === "undefined" ||
                thumbnailRef.current?.complete === true
            ),
        );
    }, [thumbnailRef.current?.complete]);

    return (
        <MarketProjectLeftSideBarListItemContainer
            to={"/market/" + props.data.project_idx}
            onClick={() => {
                setRecentProjectList(
                    [props.data.project_idx, ...recentProjectList].reduce(
                        (ac: string[], v) => (ac.includes(v) ? ac : [...ac, v]),
                        [],
                    ),
                );
            }}
        >
            <MarketProjectLeftSideBarListItemContents
                isSelected={isSelected}
                onClick={() => {
                    setIsMobileLeftSideBarOpen(false);
                }}
            >
                <MarketProjectLeftSideBarListItemThumbnailContainer>
                    <Shimmer isLoading={isImageLoading}>
                        <SquareImg
                            imgRef={thumbnailRef}
                            src={props.data.project_thumbnail_full_path}
                            imageResizingPostfix={"_50x50.webp"}
                            fetchPriority={"low"}
                            setIsImageLoading={setIsImageLoading}
                        />
                    </Shimmer>
                </MarketProjectLeftSideBarListItemThumbnailContainer>

                <MarketProjectLeftSideBarListItemInfoContainer>
                    <MarketProjectLeftSideBarListItemInfoTitleContainer
                        isSelected={isSelected}
                    >
                        <MarketProjectLeftSideBarListItemInfoNetworkBadge
                            backgroundColor={getNetworkBackground(
                                props.data.project_network,
                            )}
                        >
                            {props.data.project_network}
                        </MarketProjectLeftSideBarListItemInfoNetworkBadge>

                        <MarketProjectLeftSideBarListItemInfoTitle>
                            {props.data.project_name}
                        </MarketProjectLeftSideBarListItemInfoTitle>
                    </MarketProjectLeftSideBarListItemInfoTitleContainer>
                    <MarketProjectLeftSideBarListItemInfoDesc>
                        {props.data.brand_name}
                        {" · "}
                        {numberToStringWithComma(
                            props.data.brand_royalty +
                                props.data.creator_royalty,
                        )}
                        %
                    </MarketProjectLeftSideBarListItemInfoDesc>
                    <MarketProjectLeftSideBarListItemInfoLikeContainer>
                        좋아요 {numberToStringWithComma(props.data.like_cnt)}개
                    </MarketProjectLeftSideBarListItemInfoLikeContainer>
                </MarketProjectLeftSideBarListItemInfoContainer>
                {props.isRecent && (
                    <MarketProjectLeftSideBarRecentListItemRemoveIcon
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setRecentProjectList(
                                recentProjectList.filter(
                                    (recentProject) =>
                                        recentProject !==
                                        props.data.project_idx,
                                ),
                            );
                        }}
                        style={{
                            filter:
                                CssFilterConverter.hexToFilter("#999999")
                                    .color + "",
                        }}
                        src={URL_CONSTANTS.ASSET_URL + "/icon/icon-close.svg"}
                    />
                )}
            </MarketProjectLeftSideBarListItemContents>
        </MarketProjectLeftSideBarListItemContainer>
    );
};

export default React.memo(MarketProjectLeftSideBarListItem, (prev, next) => {
    return shallowEqual(prev, next);
});
