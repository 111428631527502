import React, { Component, ErrorInfo, ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import MoreButton from "../components/common/commonUnitComponent/moreButton/MoreButton";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../components/common/commonStyle/CommonButtonStyle";
import { getPureJsSessionStorage } from "./Utils";

export const ErrorBoundaryWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        margin: auto 16px;
        width: calc(100% - 32px);
    }
`;

export const ErrorBoundaryContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const ErrorBoundaryImg = styled.img``;

export const ErrorBoundaryTitle = styled.div`
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
    margin-top: 40px;
`;

export const ErrorBoundaryDesc = styled.div`
    color: #666;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: 14px;
`;

export const ErrorDetailInfoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
`;
export const ErrorDetailInfoContainer = styled.div<{ isShow: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: #f5f5f7;

    margin-top: 32px;
    max-height: ${({ isShow }) => (isShow ? "300px" : "0")};
    width: calc(100% - 48px);
    transition: max-height 300ms ease
        ${({ isShow }) => (!isShow ? ", padding 100ms 300ms ease" : "")};
    padding: ${({ isShow }) => (isShow ? "24px 24px 0 24px" : "0")};
    overflow: hidden;
`;

export const ErrorDetailInfoMoreButtonContainer = styled.div<{
    isShowError: boolean;
}>`
    width: 100px;
    margin-top: ${({ isShowError }) => (isShowError ? "10px" : "0")};
    transition: all 300ms ease;
`;

export const ErrorDetailInfoTitle = styled.div`
    width: 100%;
    text-align: left;
    font-weight: 700;
`;

export const ErrorDetailInfoDesc = styled.div`
    width: 100%;
    height: 200px;
    overflow: auto;
    font-size: 14px;
`;

export const ErrorGoBackButton = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    width: 100px;
    height: 48px;
    font-size: 16px;
    margin-top: 24px;
`;

interface Props {
    children?: ReactNode;
    fallback?: ReactElement;
}

interface State {
    hasError: boolean;
    error: any;
    errorInfo: any;
    isShow: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
        errorInfo: null,
        isShow: false,
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.warn("Uncaught error:", error, errorInfo);

        this.setState({
            ...this.state,
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    public render() {
        if (this.state.hasError) {
            return this.props.fallback ? (
                this.props.fallback
            ) : (
                <ErrorBoundaryWrapper>
                    <ErrorBoundaryContainer>
                        <ErrorBoundaryImg
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-error-page.svg`}
                        />
                        <ErrorBoundaryTitle>
                            페이지를 찾을 수 없습니다
                        </ErrorBoundaryTitle>
                        <ErrorBoundaryDesc>
                            서비스 이용에 불편을 드려 죄송합니다. 현재
                            일시적으로 서비스 사용이 불가합니다.
                        </ErrorBoundaryDesc>

                        <ErrorDetailInfoWrapper>
                            <ErrorGoBackButton
                                btnType={"filled-primary"}
                                onClick={() => {
                                    location.reload();
                                }}
                            >
                                새로고침
                            </ErrorGoBackButton>

                            <ErrorDetailInfoContainer
                                isShow={this.state.isShow}
                            >
                                <ErrorDetailInfoTitle>
                                    Error message : {this.state.error.message}
                                </ErrorDetailInfoTitle>
                                <ErrorDetailInfoDesc>
                                    {JSON.stringify(this.state.errorInfo)}
                                </ErrorDetailInfoDesc>
                            </ErrorDetailInfoContainer>

                            <ErrorDetailInfoMoreButtonContainer
                                isShowError={this.state.isShow}
                            >
                                <MoreButton
                                    hasBorder={false}
                                    isShow={true}
                                    isSupportFold={true}
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            isShow: !this.state.isShow,
                                        });
                                    }}
                                />
                            </ErrorDetailInfoMoreButtonContainer>
                        </ErrorDetailInfoWrapper>
                    </ErrorBoundaryContainer>
                    {/*<h3>*/}
                    {/*    {this.state.error.message*/}
                    {/*        ? this.state.error.message*/}
                    {/*        : "no message"}*/}
                    {/*</h3>*/}
                    {/*<h3>{JSON.stringify(this.state.errorInfo)}</h3>*/}
                </ErrorBoundaryWrapper>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
