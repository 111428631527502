import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import axios from "axios";
import {
    CollectionExchangeListApiResultType,
    CollectionExchangeListParamsType,
} from "../../query/market/item/useGetCollectionExchangeListQuery";
import {
    ProjectExchangeListApiResultType,
    ProjectExchangeListParamsType,
} from "../../query/market/item/useGetProjectExchangeList";

import {
    MarketItemOrderListApiResultType,
    MarketItemOrderListParams,
} from "../../query/market/item/useGetMarketItemOrderList";

export const MarketItemApi = {
    getMarketItemOrderList: async (
        itemIdx: string,
        params: MarketItemOrderListParams,
    ): Promise<MarketItemOrderListApiResultType> => {
        const tempParams = {
            ...params,
            product_type: 3,
            product_idx: itemIdx,
        };
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/order/list`, {
                    params: tempParams,
                    paramsSerializer: {
                        indexes: null,
                    },
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getWalletExchangeList: async (
        userIdx: string,
        params: CollectionExchangeListParamsType,
    ): Promise<CollectionExchangeListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/item/collection/${userIdx}/exchange/list`,
                    {
                        params: params,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getProjectExchangeList: async (
        projectIdx: string,
        params: ProjectExchangeListParamsType,
    ): Promise<ProjectExchangeListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/item/project/${projectIdx}/exchange/list`,
                    {
                        params: params,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    cancelOrder: async (orderIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(URL_CONSTANTS.API_URL + "/order/" + orderIdx, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
