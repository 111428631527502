import { useMutation } from "react-query";
import { UserApi } from "../../../apis/UserApi";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";

export const useUserUnFollowMutation = (userIdx: string) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    return useMutation<null, Error>(() => UserApi.unfollow(userIdx), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
    });
};
