import React, { ReactElement, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import HelmetInfoAtom from "../../../../store/helmetInfoAtom";
import {
    AD_BID_PAGE_REG_EXP,
    AD_BIDING_COMPLETE_PAGE_REG_EXP,
    AD_BIDING_PAGE_REG_EXP,
    AD_BUYING_PAGE_REG_EXP,
    AD_HOME_PAGE_PATHNAME,
    AD_MANAGE_AD_ADJUST_PAGE_REG_EXP,
    AD_MANAGE_AD_REGISTER_PAGE_REG_EXP,
    AD_MANAGE_PAGE_PATHNAME,
    AD_MANAGE_TEMPLATE_ADJUST_PAGE_REG_EXP,
    AD_MANAGE_TEMPLATE_REGISTER_PAGE_REG_EXP,
    HOME_PAGE_PATHNAME,
    ITEM_PAGE_REG_EXP,
    MARKET_HOME_PAGE_PATHNAME,
    MARKET_ITEM_MANAGE_PAGE_PATHNAME,
    MARKET_POINT_MANAGE_PAGE_PATHNAME,
    MY_NOTIFICATION_SETTING_PAGE_PATHNAME,
    MY_ORDER_HISTORY_PAGE_PATHNAME,
    MY_PAYMENTS_PAGE_PATHNAME,
    MY_PROFILE_PAGE_PATHNAME,
    POINT_PAGE_REG_EXP,
    PROJECT_PAGE_REG_EXP,
    WALLET_MERGE_PAGE_PATHNAME,
    WALLET_PAGE_REG_EXP,
    WALLET_POINT_AND_TOKEN_PAGE_PATHNAME,
} from "../../../../constants/PageRegExp";

const PageHelmet = (): ReactElement | null => {
    const location = useLocation();
    const [helmetInfo] = useAtom(HelmetInfoAtom);
    const getPageTitle = () => {
        const path = location.pathname;

        if (path === HOME_PAGE_PATHNAME)
            return {
                title: "세이버즈",
                desc: "인플루언서(스트리머)와 팬이 만들어가는 디지털 광고·굿즈 거래 서비스. 스트리머에게 광고하고 스트리머의 굿즈를 모으며 서로에게 더 특별하고 소중한 존재가 될 수 있습니다! 사이트에서 세이버즈를 만나보세요.",
            };
        else if (path === AD_HOME_PAGE_PATHNAME)
            return {
                title: "광고 - 세이버즈",
                desc: "효과적인 인플루언서 광고를 찾고 계신가요? 인플루언서(스트리머)와 광고주가 플랫폼에서 직접 거래하는 간편하고 뛰어난 가성비의 세이버즈 광고서비스를 이용해보세요. 간단한 조작 몇번으로 누구나 광고주가 될 수 있습니다!",
            };
        else if (AD_BID_PAGE_REG_EXP.test(path)) {
            const adIdx = path.replace("/ad/", "");
            return {
                title: helmetInfo.adName + " - 세이버즈",
                desc:
                    adIdx === "2"
                        ? "가성비 좋은 디지털 광고, 세이버즈 플랫폼에 광고해보세요! 스트리머 방송 홍보·제품 홍보 뿐만 아니라 생일·결혼 등 개인 기념일도 간단한 조작으로 누구나 손쉽게 광고할 수 있습니다."
                        : adIdx === "1"
                        ? "누구나 SOOP 플랫폼에 광고 할 수 있습니다! 하루 평균 100만명 이상의 방문자들에게 스트리머 방송 홍보와 기념일 홍보까지 저렴한 가격과 간단한 조작으로 효율적으로 광고해보세요."
                        : undefined,
            };
        } else if (AD_BIDING_PAGE_REG_EXP.test(path))
            return {
                title: "구매입찰 - " + helmetInfo.adName + " - 세이버즈",
            };
        else if (AD_BUYING_PAGE_REG_EXP.test(path))
            return {
                title: "즉시구매 - " + helmetInfo.adName + " - 세이버즈",
            };
        else if (AD_BIDING_COMPLETE_PAGE_REG_EXP.test(path))
            return {
                title: helmetInfo.adName + " - 세이버즈",
            };
        else if (path === AD_MANAGE_PAGE_PATHNAME)
            return { title: "광고 관리 - 세이버즈" };
        else if (AD_MANAGE_AD_REGISTER_PAGE_REG_EXP.test(path))
            return {
                title: "광고 판매 등록 - 세이버즈",
            };
        else if (AD_MANAGE_AD_ADJUST_PAGE_REG_EXP.test(path))
            return {
                title: "광고 판매 수정 - 세이버즈",
            };
        else if (AD_MANAGE_TEMPLATE_REGISTER_PAGE_REG_EXP.test(path))
            return {
                title: "광고 템플릿 등록 - 세이버즈",
            };
        else if (AD_MANAGE_TEMPLATE_ADJUST_PAGE_REG_EXP.test(path))
            return {
                title: "광고 템플릿 수정 - 세이버즈",
            };
        else if (path === MARKET_HOME_PAGE_PATHNAME)
            return {
                title: "플리마켓 - 세이버즈",
                desc: "인플루언서(스트리머)의 소중한 순간을 영원히 간직하고 싶으신가요? 세이버즈에서 디지털 굿즈로 만들고 거래·전송 하며 수집해서 나만의 컬렉션을 만들어보세요!",
            };
        else if (PROJECT_PAGE_REG_EXP.test(path))
            return {
                title: helmetInfo.projectName + " - 세이버즈",
                desc: "크리에이터들이 만든 스트리머들의 영상, 이미지, 인증서들을 세이버즈에서 거래하고 수집할 수 있습니다. 크리에이터들이 만든 다양한 디지털 굿즈를 아이템부스에서 만나보세요!",
            };
        else if (ITEM_PAGE_REG_EXP.test(path)) {
            const itemIdx = path.replace("/market/", "");
            return {
                title: helmetInfo.itemName + " - 세이버즈",
                desc:
                    itemIdx === "1/1"
                        ? "SOOP 별풍선 환전 수수료 부담을 줄일수 있는 방법을 찾고 계신가요? 세이버즈에서 광고하고 포인트를 모아 별풍선 환전 보너스 쿠폰을 구매해 더 만족스러운 수익을 얻어보세요."
                        : undefined,
            };
        } else if (POINT_PAGE_REG_EXP.test(path))
            return {
                title: helmetInfo.pointName + " - 세이버즈",
                desc: "SOOP 방송만 시청해도 포인트가 쌓인다? 광고 판매·방송 시청 등으로 포인트를 얻고 이를 거래하며 원하는 디지털 굿즈를 구매해보세요.",
            };
        else if (path === MARKET_POINT_MANAGE_PAGE_PATHNAME)
            return {
                title: "포인트 거래내역 - 세이버즈",
            };
        else if (path === MARKET_ITEM_MANAGE_PAGE_PATHNAME)
            return { title: "아이템 거래내역 - 세이버즈", desc: "" };
        else if (path === WALLET_POINT_AND_TOKEN_PAGE_PATHNAME)
            return {
                title: "포인트&토큰 - 세이버즈",
            };
        else if (path === WALLET_MERGE_PAGE_PATHNAME)
            return {
                title: "토큰 합성 - 세이버즈",
            };
        else if (WALLET_PAGE_REG_EXP.test(path))
            return {
                title: helmetInfo.userNickname + " - 컬렉션 - 세이버즈",
            };
        else if (path === MY_PROFILE_PAGE_PATHNAME)
            return {
                title: "계정 관리 - 세이버즈",
            };
        else if (path === MY_PAYMENTS_PAGE_PATHNAME)
            return {
                title: "결제수단 관리 - 세이버즈",
            };
        else if (path === MY_NOTIFICATION_SETTING_PAGE_PATHNAME)
            return { title: "알림설정 - 세이버즈" };
        else if (path === MY_ORDER_HISTORY_PAGE_PATHNAME)
            return {
                title: "유료결제 내역 - 세이버즈",
            };
        else if (path === "login") return { title: "로그인 - 세이버즈" };
        else
            return {
                title: "세이버즈",
            };
    };
    const [pageTitle, setPageTitle] = useState("");
    const [pageDescription, setPageDescription] = useState("");

    useEffect(() => {
        const data = getPageTitle();
        setPageTitle(data.title);
        setPageDescription(data.desc ? data.desc : "SAYBUZZ");
        // SOOP 네이티브 앱에서 index.html 을 강제로 바꾸는 듯.
        // 강제로 light 모드 지정 + 마운팅 후 다시 지정
        setColorScheme("light only");
        setSupportedColorSchemes("light");
        document.title = data.title;
        document
            .querySelector('meta[name="description"]')
            ?.setAttribute("content", data.desc ? data.desc : "SAYBUZZ");

        document
            .querySelector('meta[name="color-scheme"]')
            ?.setAttribute("content", "light only");

        document
            .querySelector('meta[name="supported-color-schemes"]')
            ?.setAttribute("content", "light");

        // document. = pageDescription;
    }, [location, helmetInfo]);

    const [colorScheme, setColorScheme] = useState("light only");
    const [supportedColorSchemes, setSupportedColorSchemes] = useState("light");

    return (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            {/*native app 에서 index.html 강제 접근하는듯한 느낌*/}
            <meta name="color-scheme" content={colorScheme} />
            <meta
                name="supported-color-schemes"
                content={supportedColorSchemes}
            />
        </Helmet>
    );
};

export default PageHelmet;
