import { ReactElement } from "react";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import styled from "styled-components";
import Terms, {
    TermType,
} from "../../../common/commonUnitComponent/terms/Terms";
import {
    AdRegisterAdjustBodyContainer,
    AdRegisterAdjustHeaderTitle,
} from "../AdRegisterContents";

export const AdRegisterAdjustTermsWrapper = styled.div``;

type Props = {
    termsList: TermType[];
    setTermsList: (value: TermType[]) => void;
};
const AdRegisterAdjustTerms = (props: Props): ReactElement | null => {
    return (
        <WhiteBox>
            <AdRegisterAdjustTermsWrapper>
                <AdRegisterAdjustHeaderTitle>
                    광고정책 확인 및 동의
                </AdRegisterAdjustHeaderTitle>
                <AdRegisterAdjustBodyContainer>
                    <Terms
                        termsList={props.termsList}
                        setTermsList={props.setTermsList}
                    />
                </AdRegisterAdjustBodyContainer>
            </AdRegisterAdjustTermsWrapper>
        </WhiteBox>
    );
};

export default AdRegisterAdjustTerms;
