import { useInfiniteQuery } from "react-query";
import { CommentApi } from "../../apis/CommentApi";
import { getPageType } from "../../utils/Utils";

export type CommentListApiResultType = {
    itemCommentList?: (CommentListItemType & ItemCommentListType)[];
    AdCommentList?: CommentListItemType[];
    pointCommentList?: CommentListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type CommentListItemType = {
    commentInfo: CommentListItemCommentInfoType;
    userInfo: CommentListItemUserInfoType;
};

export type ItemCommentListType = {
    projectInfo: ItemCommentListProjectInfoType;
    itemInfo: ItemCommentListItemInfoType;
};

export type CommentListItemUserInfoType = {
    user_nick: string;
    user_id: string;
    user_idx: string;
    user_thumbnail_path: string;
    user_influencer_bool: boolean;
};

export type ItemCommentListItemInfoType = {
    item_idx: string;
    category_no: string;
    item_description: string;
    item_origin_path: string;
    item_thumbnail_path: string;
    item_status: string;
};

export type ItemCommentListProjectInfoType = {
    project_idx: string;
    project_name: string;
    project_thumbnail_full_path: string;
};

export type CommentListItemCommentInfoType = {
    comment_idx: string;
    comment_status: string;
    comment: string;
    created_at: string;
    like_cnt: number;
    blame_bool: boolean;
    like_bool: boolean;
};

export type CommentListApiParamsType = {
    list_type?: string;

    ad_idx?: string;
    item_idx?: string;
    project_idx?: string;
    owner_user_idx?: string;

    limit: number;
    page_no: number;
};

export const useCommentListQuery = (
    commentType: string,
    params: CommentListApiParamsType,
) => {
    const queryResult = useInfiniteQuery<CommentListApiResultType, Error>({
        queryKey: [
            "comment-list-" + JSON.stringify(params),
            "like-refetch-list-" + commentType + "/comment",
            commentType + "-bid-refresh-target",
        ],
        queryFn: ({ pageParam = 1 }) =>
            CommentApi.getList(commentType, {
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        onSuccess: (res) => {},
        onError: () => {},
    });

    const defaultData: CommentListApiResultType[] = [
        { itemCommentList: [], totalCount: 0 },
    ];

    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadCommentListPages(
    pages: CommentListApiResultType[],
): any[] {
    const commentListList = pages.map(
        (result) =>
            result.itemCommentList ||
            result.AdCommentList ||
            result.pointCommentList ||
            [],
    );
    const oneDimensionalArray = [];

    for (const commentList of commentListList) {
        for (const item of commentList) {
            oneDimensionalArray.push(item);
        }
    }
    return oneDimensionalArray;
}

export function getCommentListParam(params: any, limit = 20) {
    const listType = getPageType(location.pathname);

    return {
        ...(listType !== "ad" && { list_type: listType }),
        [listType + "_idx"]: params[listType + "_id"],
        limit: limit,
        page_no: 1,
    };
}
