import { useAtom } from "jotai";

import { useMutation } from "react-query";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { AdOfferApi } from "../../../apis/adApi/AdOfferApi";
import { queryClient } from "../../../App";

export type CancelAdOfferSuccessBySellerApiParam = {
    ad_sales_date: string;
};

export const useCancelAdOfferSuccessBySellerMutation = (
    adIdx: string,
    params: CancelAdOfferSuccessBySellerApiParam,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => AdOfferApi.cancelOfferSuccessBySeller(adIdx, params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "낙찰 취소 되었습니다.",
                    iconUrl: `${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-in-circle.svg`,
                    confirmBtnTitle: "",
                });

                await queryClient.refetchQueries({
                    predicate: (query) =>
                        query.queryKey.includes("all-ad-my-buy-offer-list") ||
                        query.queryKey.includes("ad-daily-list-" + adIdx) ||
                        query.queryKey.includes(`ad-bid-refresh-target`),
                });
            },
        },
    );
};
