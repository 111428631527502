import { ReactElement } from "react";
import styled from "styled-components";
import { TutorialInfoListType } from "../../../TutorialModal";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";
import { MoreButtonIcon } from "../../../../../commonUnitComponent/moreButton/MoreButton";
import CssFilterConverter from "css-filter-converter";

export const TutorialUpperCategoryContainer = styled.div<{
    isSelected: boolean;
}>`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 176px;
    padding: 0 12px;
    height: 48px;
    align-items: center;
    border-radius: 8px;

    background-color: ${({ isSelected }) =>
        isSelected ? "#FBF2FC" : "transparent"};
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#333")};
    transition: all 300ms ease;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 24px);
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isSelected }) =>
                isSelected ? "#FBF2FC" : "rgba(13, 13, 26, 0.05);"};
        }
    }
`;

export const TutorialUpperCategoryLeftContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const TutorialUpperCategoryIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    display: none;

    @media ${(props) => props.theme.mobileL} {
        display: block;
    }
`;

export const TutorialUpperCategoryTitle = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
`;

export const TutorialUpperCategoryFoldIcon = styled.img`
    width: 14px;
    height: 10px;
    transition: all 300ms ease;
`;

type Props = {
    title: string;
    upperCategoryKey: string;
    isOpen: boolean;
    icon: string;
    openUpperCategory: (value: string) => void;
};

const TutorialUpperCategory = (props: Props): ReactElement | null => {
    return (
        <TutorialUpperCategoryContainer
            isSelected={props.isOpen}
            onClick={() => {
                props.openUpperCategory(props.upperCategoryKey);
            }}
        >
            <TutorialUpperCategoryLeftContainer>
                <TutorialUpperCategoryIcon
                    src={props.icon}
                    style={{
                        filter: `${
                            CssFilterConverter.hexToFilter(
                                props.isOpen ? "#FA54FA" : "#000",
                            ).color
                        }`,
                    }}
                />
                <TutorialUpperCategoryTitle>
                    {props.title}
                </TutorialUpperCategoryTitle>
            </TutorialUpperCategoryLeftContainer>
            <TutorialUpperCategoryFoldIcon
                style={{
                    filter:
                        (props.isOpen
                            ? CssFilterConverter.hexToFilter("#FA54FA").color
                            : "none") + "",
                }}
                src={
                    props.isOpen
                        ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-more-reverse.svg`
                        : `${URL_CONSTANTS.ASSET_URL}/icon/icon-more.svg`
                }
            />
        </TutorialUpperCategoryContainer>
    );
};

export default TutorialUpperCategory;
