import { useEffect, useRef, useState } from "react";

export function useGetIsImageLoadComplete(src: string) {
    const imgRef = useRef<HTMLImageElement>(null);
    const [isImageLoadComplete, setIsImageLoadComplete] = useState(false);

    useEffect(() => {
        if (!imgRef.current) {
            return;
        }

        const updateStatus = () => {
            if (imgRef.current) {
                //
            }
            const isLoadComplete =
                imgRef.current &&
                imgRef.current.complete &&
                imgRef.current.naturalHeight !== 0;
            setIsImageLoadComplete(isLoadComplete || false);
        };
        imgRef.current.addEventListener("load", updateStatus);

        return () => {
            if (imgRef.current)
                imgRef.current.removeEventListener("load", updateStatus);
        };
    }, [imgRef, src]);

    useEffect(() => {
        setIsImageLoadComplete(false);
    }, [src]);

    return {
        imgRef,
        isImageLoadComplete,
    };
}
