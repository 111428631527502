import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import SnackBarListAtom from "../../../../store/ui/snackBarListAtom";
import { useAtom } from "jotai";
import SnackBar from "./SnackBar";

export const SnackBarProviderContainer = styled.div`
    position: fixed;
    bottom: 32px;
    width: 100%;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 16px;
    transition: all 500ms ease;
    z-index: 20000;
`;

type Props = {};

const SnackBarProvider = (props: Props): ReactElement | null => {
    const [snackbarList, setSnackbarList] = useAtom(SnackBarListAtom);

    const [deleteTimeout, setDeleteTimeout] = useState<any>(null);

    // 하위 snackBar 에서 snackbarData 초기화시 연타할 경우 동기처리가 안되서
    // 상위에서 최종으로 변한 후 5초 뒤 (삭제 애니메이션 0.5초)에 전체 리셋
    useEffect(() => {
        clearTimeout(deleteTimeout);
        setDeleteTimeout(
            setTimeout(async () => {
                setSnackbarList(
                    snackbarList.filter((snackbar) =>
                        snackbar.isInfinite ? snackbar.isInfinite : false,
                    ),
                );
            }, 3500),
        );
    }, [snackbarList]);

    return (
        <SnackBarProviderContainer
            id={"snackbar--provider__container"}
            onClick={(e) => {
                e.preventDefault();
            }}
        >
            {snackbarList.map((data, idx) => {
                return (
                    <SnackBar
                        key={encodeURIComponent(data.desc) + idx}
                        title={data.title}
                        desc={data.desc}
                        iconUrl={data.iconUrl}
                        confirmBtnTitle={data.confirmBtnTitle}
                        isInfinite={data.isInfinite}
                        confirmEventHandler={data.confirmEventHandler}
                    />
                );
            })}
        </SnackBarProviderContainer>
    );
};

export default SnackBarProvider;
