// 대표이사 엄재원 | 이메일 support@saybuzz.io 경기도 성남시 분당구 판교로 228번길 15 판교세븐벤처밸리 1단지 2동 8층 사업자 등록번호 220-86-76113 통신판매업신고번호 제2018-서울강남-03954호
export const FooterCompanyInfo = [
    {
        title: "(주)프리메타 | 대표이사 엄재원",
        isMobileSeparate: false,
    },
    {
        title: "이메일 saybuzz@sooplive.com | 고객센터 031-622-6850",
        isMobileSeparate: false,
    },
    {
        title: "경기도 성남시 분당구 판교로 228번길 15 판교세븐벤처밸리 1단지 2동 8층",
        isMobileSeparate: false,
    },
    {
        title: "사업자 등록번호 414-81-08097 | 통신판매업신고번호 2022-성남분당C-0330",
        isMobileSeparate: true,
    },
];
//주소 : 경기도 성남시 분당구 판교로228번길 15 판교세븐밴처밸리 1단지 2동 ㈜SOOP(삼평동)      FAX : 031-622-8008      123@sooplive.com (1688-7022)
export const FooterPolicy = [
    {
        title: "이용약관",
        path: "https://about.saybuzz.io/ko/policy/service",
    },
    {
        title: "개인정보처리방침",
        path: "https://about.saybuzz.io/ko/policy/privacy",
    },
    {
        title: "청소년보호정책",
        path: "https://about.saybuzz.io/ko/policy/youth",
    },
    {
        title: "사업자정보확인",
        path: "https://ftc.go.kr/bizCommPop.do?wrkr_no=4148108097&apv_perm_no=",
    },
];
