import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";

export const CalendarFoldBtnWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const CalendarFoldBtnContainer = styled.div`
    height: 34px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 17px;
    border: 1px solid #ebebeb;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
    cursor: pointer;
`;

export const CalendarFoldBtnIcon = styled.img`
    width: 5.5px;
    height: 12.5px;
`;

type Props = {
    isShowOnlyWeek: boolean;
    setIsShowOnlyWeek: (value: boolean) => void;
};

const CalendarFoldBtn = (props: Props): ReactElement | null => {
    return (
        <CalendarFoldBtnWrapper>
            <CalendarFoldBtnContainer
                onClick={() => {
                    props.setIsShowOnlyWeek(!props.isShowOnlyWeek);
                }}
            >
                {props.isShowOnlyWeek ? "달력 전체보기" : "달력 접기"}

                <CalendarFoldBtnIcon
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-right.svg`}
                    style={{
                        transform: props.isShowOnlyWeek
                            ? "rotate(90deg)"
                            : "rotate(-90deg)",
                    }}
                />
            </CalendarFoldBtnContainer>
        </CalendarFoldBtnWrapper>
    );
};

export default CalendarFoldBtn;
