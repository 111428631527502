import React from "react";
import { ItemListItemType } from "../../query/item/useItemListQuery";
import { useOpenModal } from "../modal/useOpenModal";
import WalletCollectionItemUseModal from "../../components/wallet/walletCollection/components/walletCollectionItemListItem/components/WalletCollectionItemUseModal";

export function useItemUseModal(item: ItemListItemType) {
    const { openModal } = useOpenModal();
    const openItemUseModal = async () => {
        return await openModal("item-use-modal", "bottomSheet", (resolve) => {
            return (
                <WalletCollectionItemUseModal resolve={resolve} item={item} />
            );
        });
    };

    return {
        openItemUseModal: openItemUseModal,
    };
}
