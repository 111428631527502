import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";
import { useNavigate } from "react-router-dom";
import { useGlobalSearchRecentStore } from "../../../../../hooks/globalSearch/useGlobalSearchRecentStore";
import globalSearchInputAtom from "../../../../../store/globalSearch/globalSearchInputAtom";
import { useSetAtom } from "jotai";
import { useGoLoginPage } from "../../../../../hooks/common/useGoLoginPage";
import { BRAND_ID_SOOP } from "../../../../../constants/brand/Brand";

export const SearchResultRecentListContainer = styled.div`
    padding: 10px;
    @media ${(props) => props.theme.tabletM} {
        display: block;
    }
`;

export const SearchResultRecentListTitle = styled.div<{
    recentLength: number;
    isNoData: boolean;
}>`
    padding: 8px 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;

    color: #777777;
    ${({ recentLength }) =>
        recentLength === 0
            ? `text-align: center; height: 126px; justify-content: center;`
            : ""};
`;

export const SearchResultRecentListItem = styled.div`
    padding: 12px 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: #222222;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f0f2;
        }
    }

    transition: background-color 300ms ease;
    border-radius: 6px;
`;

export const SearchResultRecentListItemRemoveIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const RecentNoLoginSearchList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 22px 14px;
    gap: 20px;
`;

export const RecentNoLoginSearchListMsg = styled.div`
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
`;

export const RecentNoLoginSearchListLoginBtn = styled.div`
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #4279ff;

    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;

    cursor: pointer;
`;

export const RecentNoLoginSearchListLoginLogoImg = styled.img`
    height: 16px;
`;
type Props = {};

const SearchResultRecentList = (props: Props): ReactElement | null => {
    const isLogin = getIsLogin();
    const navigate = useNavigate();
    const [recentKeywords, setRecentKeywords] = useState<string[]>([]);
    const { get: getRecentSearch, remove: removeRecentSearch } =
        useGlobalSearchRecentStore();
    useEffect(() => {
        setRecentKeywords(getRecentSearch());
    }, []);

    const setGlobalSearchInput = useSetAtom(globalSearchInputAtom);
    const { goLogin } = useGoLoginPage();

    return (
        <SearchResultRecentListContainer>
            {isLogin && (
                <>
                    <SearchResultRecentListTitle
                        recentLength={recentKeywords.length}
                        isNoData={recentKeywords.length === 0}
                    >
                        {recentKeywords.length !== 0
                            ? "최근 방문 페이지"
                            : "최근 방문 페이지가 없습니다."}
                    </SearchResultRecentListTitle>
                    {recentKeywords?.map((recentSearchItem, idx) => {
                        return (
                            <SearchResultRecentListItem
                                key={idx}
                                onClick={() => {
                                    // 최근 검색 클릭 시 input value 변경
                                    setGlobalSearchInput(recentSearchItem);
                                }}
                            >
                                {recentSearchItem}
                                <SearchResultRecentListItemRemoveIcon
                                    src={
                                        URL_CONSTANTS.ASSET_URL +
                                        "/icon/icon-search-cancel.png"
                                    }
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setRecentKeywords(
                                            removeRecentSearch(
                                                recentSearchItem,
                                            ),
                                        );
                                    }}
                                />
                            </SearchResultRecentListItem>
                        );
                    })}
                </>
            )}
            {!isLogin && (
                <RecentNoLoginSearchList>
                    <RecentNoLoginSearchListMsg>
                        SOOP 계정으로 로그인하시면
                        <br />
                        <span
                            style={{
                                fontWeight: 700,
                            }}
                        >
                            최근 방문 기록
                        </span>
                        을 활용할 수 있습니다.
                    </RecentNoLoginSearchListMsg>
                    <RecentNoLoginSearchListLoginBtn
                        onClick={() => {
                            goLogin();
                        }}
                    >
                        <RecentNoLoginSearchListLoginLogoImg
                            src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${BRAND_ID_SOOP}-white.svg`}
                        />
                        로그인
                    </RecentNoLoginSearchListLoginBtn>
                </RecentNoLoginSearchList>
            )}
        </SearchResultRecentListContainer>
    );
};

export default SearchResultRecentList;
