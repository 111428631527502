import React from "react";
import AdTemplateRegisterStepConfirmModal, {
    AdTemplateRegisterStepConfirmResolveType,
} from "./AdTemplateRegisterStepConfirmModal";
import { useOpenModal } from "../../../../../../hooks/modal/useOpenModal";
import { useNavigate } from "react-router-dom";

export function useOpenAdTemplateRegisterConfirmModal() {
    const { openModalWithReturnValue } = useOpenModal();
    const navigate = useNavigate();

    const _openAdTemplateRegisterConfirmModal =
        async (): Promise<AdTemplateRegisterStepConfirmResolveType> => {
            return openModalWithReturnValue(
                "ad-template-register-alert-modal",
                "modal",
                (
                    resolve: (
                        value: AdTemplateRegisterStepConfirmResolveType,
                    ) => void,
                ) => {
                    return (
                        <AdTemplateRegisterStepConfirmModal resolve={resolve} />
                    );
                },
            );
        };
    return {
        navigateTemplateRegisterPage: (
            campaignIdx: string,
            hasGuide: boolean,
            beforePath?: string,
        ) => {
            setTimeout(() => {
                navigate(
                    `/ad/template-register/${campaignIdx}${
                        hasGuide ? "/step/1" : ""
                    }`,
                    beforePath
                        ? {
                              state: { beforePath: beforePath },
                          }
                        : undefined,
                );
            }, 10);
        },
        openAdTemplateRegisterConfirmModal:
            async (): Promise<AdTemplateRegisterStepConfirmResolveType> => {
                const result = await _openAdTemplateRegisterConfirmModal();

                if (
                    typeof result.isOnlyClose === "undefined" ||
                    result.isOnlyClose
                ) {
                    return {
                        isOnlyClose: true,
                        hasGuide: false,
                    };
                } else {
                    return {
                        isOnlyClose: false,
                        hasGuide: result.hasGuide,
                    };
                }
            },
    };
}
