import React, { ReactElement, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useHover } from "usehooks-ts";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { InterworkType } from "../../../../../query/user/useGetUserProfileQuery";
import Tooltip from "../../../commonUnitComponent/tooltip/Tooltip";
import { BRAND_ID_SOOP } from "../../../../../constants/brand/Brand";

export const MiniProfileModalUserBrandIdLink = styled(Link)`
    width: calc(100% - 26px);
    z-index: 20;
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(0deg, #f5f5f7 0%, #f5f5f7 100%), #eaf2fe;
    overflow: visible;
    transition: all 300ms ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #e4e4e5;
        }
    }
`;

export const MiniProfileModalUserBrandThumbnail = styled.img`
    width: 34px;
    height: 34px;
    border-radius: 6px;
    margin-right: 6px;
    border: 1px solid #e0e0e0;
`;

export const MiniProfileModalUserBrandIdInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MiniProfileModalUserBrandIdInfoNickname = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #222222;
`;

export const MiniProfileModalUserBrandIdInfoId = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #888888;
    margin-top: 4px;
`;

type Props = {
    data: InterworkType;
};

const MiniProfileModalBrandListItem = (props: Props): ReactElement | null => {
    const brandIdLinkRef = useRef<HTMLAnchorElement>(null);
    const isHover = useHover(brandIdLinkRef);

    return (
        <MiniProfileModalUserBrandIdLink
            ref={brandIdLinkRef}
            target={"_blank"}
            to={
                props.data.user_interwork_service === BRAND_ID_SOOP
                    ? "//ch.sooplive.co.kr/" + props.data.user_interwork_id
                    : {}
            }
        >
            {props.data.user_interwork_service === BRAND_ID_SOOP && (
                <Tooltip isHover={isHover}>SOOP 채널가기</Tooltip>
            )}
            <MiniProfileModalUserBrandThumbnail
                src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${props.data.user_interwork_service}-thumbnail.svg`}
            />
            <MiniProfileModalUserBrandIdInfoContainer>
                <MiniProfileModalUserBrandIdInfoNickname>
                    {props.data.user_interwork_nick}
                </MiniProfileModalUserBrandIdInfoNickname>
                <MiniProfileModalUserBrandIdInfoId>
                    {props.data.user_interwork_id}
                </MiniProfileModalUserBrandIdInfoId>
            </MiniProfileModalUserBrandIdInfoContainer>
        </MiniProfileModalUserBrandIdLink>
    );
};

export default MiniProfileModalBrandListItem;
