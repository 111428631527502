import { useAtom } from "jotai";
import { Query, useMutation } from "react-query";
import { MarketCommonApi } from "../../../apis/market/MarketCommonApi";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { queryClient } from "../../../App";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";

export type OrderSellParams = {
    order_quantity: number;
    order_unit_price: number;
    order_valid_period: string;
};

export const useOrderSellMutation = (
    pageType: string,
    targetIdx: string,
    params: OrderSellParams,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => MarketCommonApi.orderSell(pageType, targetIdx, params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                await queryClient.resetQueries({
                    predicate: (query: Query) =>
                        query.queryKey.includes(
                            `${pageType}-info-refresh-target-${targetIdx}`,
                        ) || query.queryKey.includes("wallet-refresh-target"),
                });

                pushSnackBarListItem({
                    title: "",
                    desc: `${
                        pageType === "point" ? "포인트" : "아이템"
                    } 판매등록을 완료했습니다.`,
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
