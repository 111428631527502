import React, { ReactElement } from "react";
import styled from "styled-components";

export const MainContentsContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;

    @media ${(props) => props.theme.PCM} {
        flex-wrap: wrap;
    }
`;

export const MainStickyContentsContainer = styled.div`
    position: sticky;
    top: 48px;
    width: calc(50% - 20px);
    margin-right: 20px;
    height: 100%;

    @media ${(props) => props.theme.PCM} {
        position: relative;
        top: 0;
        width: 100%;
        height: 100%;
        margin-right: 0;
        margin-bottom: 55px;
    }
`;

export const SubTabContainer = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: none;
    @media ${(props) => props.theme.PCM} {
        display: block;
    }
`;

export const MainScrolledContentsContainer = styled.div`
    width: calc(50% - 20px);
    margin-left: 20px;

    @media ${(props) => props.theme.PCM} {
        width: 100%;
        margin-left: 0;
    }

    @media ${(props) => props.theme.tabletM} {
    }
`;

export const SubContentsContainer = styled.div``;

type Props = {
    renderMainStickyContentsComponent: (value?: any) => ReactElement;
    renderSubTabComponent: (value?: any) => ReactElement;
    renderMainScrolledContentsComponent: (value?: any) => ReactElement;
    renderSubContentsComponent: (value?: any) => ReactElement;
};

const DoubleSideBarMainMixedContentsLayout = (
    props: Props,
): ReactElement | null => {
    return (
        <>
            <MainContentsContainer>
                <MainStickyContentsContainer>
                    {props.renderMainStickyContentsComponent()}
                </MainStickyContentsContainer>
                <SubTabContainer>
                    {props.renderSubTabComponent()}
                </SubTabContainer>
                <MainScrolledContentsContainer>
                    {props.renderMainScrolledContentsComponent()}
                </MainScrolledContentsContainer>
            </MainContentsContainer>
            <SubContentsContainer>
                {props.renderSubContentsComponent()}
            </SubContentsContainer>
        </>
    );
};

export default DoubleSideBarMainMixedContentsLayout;
