import { hasPathAfterResolve, useOpenModal } from "../useOpenModal";
import AdSaleManageOfferListModal from "../../../components/ad/adManageNew/adSaleManage/adSaleManageOfferListModal/AdSaleManageOfferListModal";
import { useNavigate } from "react-router-dom";
import { AdDailyListItemStatusCodeType } from "../../../types/ad/adDaily/AdDailyListItemType";

export function useOpenAdSaleManageOfferListModal() {
    const navigate = useNavigate();
    const { openModalWithReturnValue } = useOpenModal();
    const openAdSaleManageOfferListModal = async (
        adIdx: string,
        adSalesDate: Date,
        adDailyStatus: AdDailyListItemStatusCodeType,
        hasOfferSuccess: boolean,
    ) => {
        return await openModalWithReturnValue(
            "offer-select-modal",
            "bottomSheet",
            (resolve) => {
                return (
                    <AdSaleManageOfferListModal
                        resolve={resolve}
                        adIdx={adIdx}
                        adSalesDate={adSalesDate}
                        adDailyStatus={adDailyStatus}
                        hasOfferSuccess={hasOfferSuccess}
                    />
                );
            },
        );
    };

    return {
        openAdSaleManageOfferListModal,
    };
}
