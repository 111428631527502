import { ReactElement, useEffect, useState } from "react";
import {
    ChipType,
    SortingItemType,
} from "../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { ProjectListApiParamsType } from "../../../query/project/useProjectListQuery";
import { FilterType } from "../../common/commonUiComponent/searchFilter/SearchFilter";
import {
    getSelectedChip,
    getSelectedFilterCheckBoxItem,
    getSelectedSubTabKey,
    onErrorImg,
} from "../../../utils/Utils";
import { useLocation, useParams } from "react-router-dom";
import SubTab, {
    SubTabType,
} from "../../common/commonUiComponent/subTab/SubTab";
import { useProjectDataQuery } from "../../../query/project/useProjectDataQuery";
import { useSetHelmetInfo } from "../../../hooks/common/useSetHelmetInfo";
import isLeftSideBarRenderAtom from "../../../store/ui/isLeftSideBarRenderAtom";
import ApiResBoundary from "../../common/apiResBoundary/ApiResBoundary";
import Shimmer from "../../common/commonUiComponent/shimmer/Shimmer";
import RepresentativeUrl from "../../common/commonUiComponent/representativUrl/RepresentativeUrl";
import CommentCollection from "../../common/commonUiComponent/commentCollection/CommentCollection";
import ProjectList from "../../common/commonUiComponent/projectList/ProjectList";
import { CommonPageContentContainer } from "../../common/commonStyle/CommonStyle";
import { useAtom } from "jotai";
import styled from "styled-components";
import MarketProjectTitle from "./components/MarketProjectTitle/MarketProjectTitle";
import MarketProjectTitleMobile from "./components/MarketProjectTitle/MarketProjectTitleMobile";
import MarketProjectItemList from "./components/MarketProjectItemList/MarketProjectItemList";
import MarketProjectHistory from "./components/MarketProjectHistory/MarketProjectHistory";

export const MarketProjectContentsWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 120px;
    background: white;

    @media ${(props) => props.theme.tabletM} {
        padding-top: 46px;
    }
`;

export const MarketProjectContentsLoadingContainer = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${(props) => props.theme.tabletL} {
        height: calc(100vh - 60px);
    }
`;
export const MarketProjectContentsBackgroundContainer = styled.div`
    width: 100%;
    height: 240px;
    position: sticky;
    z-index: 1;
    top: 0;
    overflow: hidden;
    background-color: rgb(245, 245, 247);

    @media ${(props) => props.theme.mobileL} {
        height: 100px;
    }
`;
export const MarketProjectContentsBackground = styled.img`
    width: 100%;
    height: 240px;
    object-fit: cover;
    position: sticky;
    max-width: 1600px;
    @media ${(props) => props.theme.mobileL} {
        height: 100px;
    }
`;
export const MarketProjectContentsContainerBackground = styled.div`
    position: relative;
    z-index: 2;
    background: white;
`;

export const MarketProjectContentsContainerWrapper = styled.div`
    position: relative;

    @media ${(props) => props.theme.PCL} {
        max-width: 1344px;
        width: 100%;
        margin: 0 auto;
    }

    @media ${(props) => props.theme.PCM} {
        max-width: 1280px;
    }
`;

export const MarketProjectContentsContainer = styled(
    CommonPageContentContainer,
)`
    padding-top: 32px;
    max-width: 1280px;
    position: relative;
    background: white;

    @media ${(props) => props.theme.PCL} {
        padding-top: 32px;
        max-width: 1344px;
        width: calc(100% - 64px);
        margin-left: 32px;
        margin-right: 32px;
    }

    @media ${(props) => props.theme.tabletM} {
        max-width: 1280px;
    }

    @media ${(props) => props.theme.mobileL} {
        padding-top: 16px;

        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
    }
`;

export const MarketProjectContentsSubTabContainer = styled.div`
    margin-top: 32px;
    @media ${(props) => props.theme.mobileL} {
        margin-top: 24px;
    }
`;

export const MarketProjectContentsRepresentativeUrlContainer = styled.div`
    position: absolute;
    top: -48px;
    right: 16px;
`;

export function getSNBProjectListApiParams(
    headerSortingList: SortingItemType[],
    headerFilters: FilterType[],
    headerChips: ChipType[],
): ProjectListApiParamsType {
    return {
        sort_type: headerSortingList.filter(
            (sortingItem) => sortingItem.isSelected,
        )[0].key,
        project_erc: getSelectedFilterCheckBoxItem("erc", headerFilters),
        project_network: getSelectedFilterCheckBoxItem(
            "network",
            headerFilters,
        ),
        ...(headerFilters.some((filter) => filter.key === "category") && {
            category_no: getSelectedFilterCheckBoxItem(
                "category",
                headerFilters,
            ),
        }),
        brand_id: getSelectedFilterCheckBoxItem("brand", headerFilters),
        release_bool: getSelectedFilterCheckBoxItem(
            "release_bool",
            headerFilters,
        ),
        transaction_bool: getSelectedFilterCheckBoxItem(
            "transaction_bool",
            headerFilters,
        ),
        list_type: getSelectedChip(headerChips),
        page_no: 1,
        limit: 20,
    };
}

export const PROJECT_ITEM_TAB = "item";
export const PROJECT_COMMUNITY_TAB = "community";
export const PROJECT_HISTORY_TAB = "history";

const ProjectItemListSubTabList: SubTabType[] = [
    { value: "아이템", key: PROJECT_ITEM_TAB, isSelected: true },
    { value: "커뮤니티", key: PROJECT_COMMUNITY_TAB, isSelected: false },
    { value: "입출고 내역", key: PROJECT_HISTORY_TAB, isSelected: false },
];

type Props = {};

const MarketProjectContents = (props: Props): ReactElement | null => {
    const params = useParams();
    const location = useLocation();
    const projectIdx = params.project_id ? params.project_id : "0";

    const [subTabList, setSubTabList] = useState<SubTabType[]>(
        ProjectItemListSubTabList,
    );
    const selectedSubTabKey = getSelectedSubTabKey(subTabList);

    const {
        data: rawProjectData,
        error: projectDataError,
        isLoading: isProjectDataLoading,
        isFetching: isProjectDataFetching,
        isIdle: isProjectDataIdle,
        isSuccess: isProjectDataSuccess,
    } = useProjectDataQuery(projectIdx);

    // set helmet data - title
    useSetHelmetInfo(
        "projectName",
        rawProjectData.project_name,
        rawProjectData,
    );

    useEffect(() => {
        setSubTabList(ProjectItemListSubTabList);
    }, [location.pathname]);

    const [isLeftSideBarRender, setIsLeftSideBarRender] = useAtom(
        isLeftSideBarRenderAtom,
    );

    useEffect(() => {
        if (!isLeftSideBarRender && projectDataError) {
            setIsLeftSideBarRender(true);
        }
    }, [projectDataError]);

    const [isBackgroundLoading, setIsBackgroundLoading] = useState(true);

    return (
        <ApiResBoundary
            error={projectDataError}
            isLoading={isProjectDataLoading || isProjectDataIdle}
            loadingFallback={
                <MarketProjectContentsLoadingContainer>
                    {/*<Spinner />*/}
                </MarketProjectContentsLoadingContainer>
            }
            errorFallback={(error) => (
                <MarketProjectContentsLoadingContainer>
                    존재하지 않는 프로젝트입니다.
                </MarketProjectContentsLoadingContainer>
            )}
            isNoData={false}
            // 리스트와 헤더 뺀 영억은 강제로 초기화
            isUiTreeReset={false}
        >
            <MarketProjectContentsWrapper>
                <MarketProjectContentsBackgroundContainer>
                    <Shimmer isLoading={isBackgroundLoading} isThin={true}>
                        <MarketProjectContentsBackground
                            key={projectIdx + "-background"}
                            src={rawProjectData.project_banner_full_path}
                            onError={onErrorImg}
                            onLoad={() => {
                                setIsBackgroundLoading(false);
                            }}
                        />
                    </Shimmer>
                </MarketProjectContentsBackgroundContainer>
                <MarketProjectContentsContainerBackground>
                    <MarketProjectContentsContainerWrapper>
                        <MarketProjectContentsRepresentativeUrlContainer>
                            {rawProjectData.project_title_url !==
                                "https://beta.freeblox.net/play/worlds/" &&
                                rawProjectData.project_title_url !==
                                    "https://" && (
                                    <RepresentativeUrl
                                        key={projectIdx + "-project-rep-url"}
                                        link={rawProjectData.project_title_url}
                                    />
                                )}
                        </MarketProjectContentsRepresentativeUrlContainer>
                        <MarketProjectContentsContainer>
                            <MarketProjectTitle
                                key={projectIdx + "-project-title-area"}
                                projectData={rawProjectData}
                            />

                            <MarketProjectTitleMobile
                                key={projectIdx + "-project-title-area-mobile"}
                                projectData={rawProjectData}
                            />

                            <MarketProjectContentsSubTabContainer>
                                <SubTab
                                    subTabList={subTabList}
                                    setSubTabList={setSubTabList}
                                />
                            </MarketProjectContentsSubTabContainer>

                            {selectedSubTabKey === PROJECT_ITEM_TAB && (
                                <MarketProjectItemList
                                    key={projectIdx + "-item-list"}
                                    projectIdx={rawProjectData.project_idx}
                                    projectUserIdx={rawProjectData.user_idx}
                                />
                            )}
                            {selectedSubTabKey === PROJECT_COMMUNITY_TAB && (
                                <CommentCollection
                                    type={"project"}
                                    key={"comment-collection"}
                                />
                            )}
                            {selectedSubTabKey === PROJECT_HISTORY_TAB && (
                                <MarketProjectHistory key={"history"} />
                            )}

                            <ProjectList
                                title={
                                    rawProjectData.brand_name + " 신규 프로젝트"
                                }
                                projectListApiParam={{
                                    list_type: "brand",
                                    sort_type: "created_at_desc",
                                    page_no: 0,
                                    limit: 4,
                                    brand_idx: rawProjectData.brand_idx,
                                    release_bool: "1",
                                }}
                                projectListQueryParam={{
                                    enabled: true,
                                    staleTime: 60000,
                                    cacheTime: 60000,
                                }}
                            />
                        </MarketProjectContentsContainer>
                    </MarketProjectContentsContainerWrapper>
                </MarketProjectContentsContainerBackground>
            </MarketProjectContentsWrapper>
        </ApiResBoundary>
    );
};

export default MarketProjectContents;
