import styled from "styled-components";

export const NormalCarouselIndicator = styled.div`
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 5px 12px;
    right: 8px;
    bottom: 13px;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    color: #ffffff;
`;
