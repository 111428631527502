import React, { ReactElement } from "react";
import styled from "styled-components";
import { useGetMyRemainingPointListQuery } from "../../../../../../../../query/my/myPoint/useGetMyRemainingPointListQuery";
import { numberToStringWithComma } from "../../../../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";

export const MyMenuUserPointListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    background: #f5f5f7;
    padding: 14px 0;
`;

export const MyMenuUserPointInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: calc(33.33% - ${2 / 3}px);
`;

export const MyMenuUserPointImg = styled.img<{
    size: string;
}>`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
`;

export const MyMenuUserPointRemainingAmountContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;

export const MyMenuUserPointRemainingAmountValue = styled.div`
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 13px */
`;

export const MyMenuUserPointRemainingAmountUnit = styled.div`
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`;

export const MyMenuUserPointVerticalLine = styled.div`
    width: 1px;
    height: 60px;
    background: #e5e5e5;
`;

type Props = {};

const UserProfileModalUserPointList = (props: Props): ReactElement | null => {
    const {
        myPoint,
        isSuccess: isMyPointListSuccess,
        error: errorMyPointList,
    } = useGetMyRemainingPointListQuery();
    const pointList = [
        {
            title: "RP",
            remainingAmount: myPoint.rp.remaining || 0,
        },
        {
            title: "SP",
            remainingAmount: myPoint.sp.remaining || 0,
        },
        {
            title: "ST",
            remainingAmount: myPoint.st.remaining || 0,
        },
    ];

    return (
        <MyMenuUserPointListContainer>
            {pointList.map((point, idx) => (
                <React.Fragment key={idx}>
                    <MyMenuUserPointInfoContainer>
                        <MyMenuUserPointImg
                            src={`${
                                URL_CONSTANTS.ASSET_URL
                            }/token/${point.title.toLowerCase()}-small.svg`}
                            size={
                                point.title.toLowerCase() === "sp"
                                    ? "34px"
                                    : "36px"
                            }
                        />
                        <MyMenuUserPointRemainingAmountContainer>
                            <MyMenuUserPointRemainingAmountValue>
                                {numberToStringWithComma(point.remainingAmount)}
                            </MyMenuUserPointRemainingAmountValue>
                            <MyMenuUserPointRemainingAmountUnit>
                                {point.title}
                            </MyMenuUserPointRemainingAmountUnit>
                        </MyMenuUserPointRemainingAmountContainer>
                    </MyMenuUserPointInfoContainer>
                    {idx !== pointList.length - 1 && (
                        <MyMenuUserPointVerticalLine />
                    )}
                </React.Fragment>
            ))}
        </MyMenuUserPointListContainer>
    );
};

export default UserProfileModalUserPointList;
