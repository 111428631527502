import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { CommonBoxHoverEffect } from "../../../../../common/commonStyle/CommonStyle";
import { ItemListItemType } from "../../../../../../query/item/useItemListQuery";
import { useProjectDataQuery } from "../../../../../../query/project/useProjectDataQuery";
import { useLikeUnLike } from "../../../../../../hooks/common/useLikeUnLike";
import {
    numberToStringWithComma,
    shortenNumberToStringWithComma,
} from "../../../../../../utils/Utils";
import { getItemStatusKR } from "../../../../../wallet/walletCollection/components/walletCollectionItemListItem/WalletCollectionItem";
import Shimmer from "../../../../../common/commonUiComponent/shimmer/Shimmer";
import SquareImg from "../../../../../common/commonUiComponent/squqreImg/SquareImg";
import ImageFullSizeViewButton from "../../../../../common/commonUnitComponent/imageFullSizeView/ImageFullSizeViewButton";
import IncludeIconButton from "../../../../../common/commonUnitComponent/includeIconButton/IncludeIconButton";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";

export const MarketProjectItemListItemContainer = styled(Link)`
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    background-color: #f5f5f7;
    display: block;

    width: calc(20% - 19.2px);
    @media ${(props) => props.theme.PCM} {
        width: calc(25% - 18px);
    }

    @media ${(props) => props.theme.tabletL} {
        width: calc(33% - 14px);
    }

    @media ${(props) => props.theme.tabletM} {
        width: calc(33% - 11.2px);
    }

    @media ${(props) => props.theme.mobileL} {
        width: calc(33% - 7px);
    }

    @media ${(props) => props.theme.mobileM} {
        width: calc(50% - 6px);
    }

    cursor: pointer;
    ${CommonBoxHoverEffect};
`;

export const MarketProjectItemListItemWrapper = styled.div`
    width: calc(100% - 28px);
    padding: 14px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        padding: 0;
    }
`;

export const MarketProjectItemListItemThumbnailContainer = styled.div`
    position: relative;
    background: #f5f5f7;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;

    @media ${(props) => props.theme.mobileL} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const MarketProjectItemListItemInfoContainer = styled.div`
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        padding: 8px;
        margin-top: 2px;
    }
`;

export const MarketProjectItemListItemMainInfoContainer = styled.div`
    min-height: 95px;
    @media ${(props) => props.theme.mobileL} {
        min-height: 86px;
    }
`;

export const MarketProjectItemListItemMainInfoTitle = styled.div`
    position: relative;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;

    @media ${(props) => props.theme.mobileL} {
        font-size: 15px;
    }
`;

export const MarketProjectItemListItemMainInfoLowestCostOrStatus = styled.div<{
    itemStatus: string;
}>`
    position: relative;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    color: ${({ itemStatus }) => {
        if (itemStatus === "1" || itemStatus === "0") return "#fa54fa";
        else if (itemStatus === "5") return "#FF4671";
        else return "#8C56FF";
    }};
    margin-top: 8px;

    @media ${(props) => props.theme.mobileL} {
        font-size: 14px;
    }
`;

export const MarketProjectItemListItemMainInfoLatestCost = styled.div`
    color: #888;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    margin-top: 8px;
`;

export const MarketProjectItemListItemSubInfoContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 8px;
    }
`;

type Props = {
    data: ItemListItemType;
    refetchItemList: () => Promise<any>;
};

const MarketProjectItemListItem = (props: Props): JSX.Element => {
    const params = useParams();
    const projectIdx = params.project_id ? params.project_id : "0";
    const { data: rawProjectData, refetch: refetchProjectData } =
        useProjectDataQuery(projectIdx);

    const { likeUnLike } = useLikeUnLike(
        "item",
        props.data.item_idx,
        props.data.like_bool,
    );

    const thumbnailRef = useRef<HTMLImageElement>(null);

    const projectItemPrice = (itemStatus: string, lowPrice: number) => {
        switch (itemStatus) {
            case "0":
                return "-";
            case "1":
                return lowPrice === 0 ? "-" : numberToStringWithComma(lowPrice);
            case "2":
                return "판매완료";
            default:
                return getItemStatusKR(itemStatus);
        }
    };

    const [isImageLoading, setIsImageLoading] = useState(true);

    return (
        <>
            <MarketProjectItemListItemContainer
                to={props.data.item_idx + ""}
                onMouseDown={(e) => {
                    const target = e.target as HTMLDivElement;

                    if (
                        e.button === 1 &&
                        target.classList.value.indexOf("include-icon") !== -1
                    ) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }
                }}
                onMouseUp={(e) => {
                    const target = e.target as HTMLDivElement;

                    if (
                        e.button === 1 &&
                        target.classList.value.indexOf("include-icon") !== -1
                    ) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                    }
                }}
            >
                <MarketProjectItemListItemWrapper>
                    <MarketProjectItemListItemThumbnailContainer>
                        <Shimmer
                            key={props.data.item_idx}
                            isLoading={isImageLoading}
                        >
                            <SquareImg
                                src={props.data.item_thumbnail_full_path}
                                imgRef={thumbnailRef}
                                setIsImageLoading={setIsImageLoading}
                                imageResizingPostfix={"_250x250.webp"}
                                fetchPriority={"high"}
                            />
                        </Shimmer>

                        <ImageFullSizeViewButton
                            src={props.data.item_thumbnail_full_path}
                            info={[
                                props.data.item_name,
                                props.data.project_name,
                                "크리에이터 : " + rawProjectData.user_nick,
                            ]}
                            thumbnailRef={thumbnailRef}
                        />
                    </MarketProjectItemListItemThumbnailContainer>
                    <MarketProjectItemListItemInfoContainer>
                        <MarketProjectItemListItemMainInfoContainer>
                            <MarketProjectItemListItemMainInfoTitle>
                                <Shimmer isLoading={isImageLoading}>
                                    {props.data.item_name}
                                </Shimmer>
                            </MarketProjectItemListItemMainInfoTitle>

                            <MarketProjectItemListItemMainInfoLowestCostOrStatus
                                itemStatus={props.data.item_status}
                            >
                                <Shimmer isLoading={isImageLoading}>
                                    {`${projectItemPrice(
                                        props.data.item_status,
                                        props.data.low_price,
                                    )} ${
                                        props.data.item_status === "1" &&
                                        props.data.low_price !== 0
                                            ? props.data.project_currency
                                            : ""
                                    }`}
                                </Shimmer>
                            </MarketProjectItemListItemMainInfoLowestCostOrStatus>

                            {(props.data.item_status === "0" ||
                                props.data.item_status === "1") && (
                                <MarketProjectItemListItemMainInfoLatestCost>
                                    <Shimmer isLoading={isImageLoading}>
                                        {props.data.last_transaction_price !== 0
                                            ? `최근 ${numberToStringWithComma(
                                                  props.data
                                                      .last_transaction_price,
                                              )} ${props.data.project_currency}`
                                            : "-"}
                                    </Shimmer>
                                </MarketProjectItemListItemMainInfoLatestCost>
                            )}
                        </MarketProjectItemListItemMainInfoContainer>
                        <MarketProjectItemListItemSubInfoContainer>
                            <IncludeIconButton
                                isActive={props.data.like_bool}
                                clickEventHandler={async () => {
                                    await likeUnLike();
                                    await props.refetchItemList();
                                    await refetchProjectData();
                                }}
                                hoverColor={"rgba(13, 13, 26, 0.06)"}
                                hasBorder={false}
                                activeIconColor={"#FF4671"}
                                icon={`${URL_CONSTANTS.ASSET_URL}/icon/icon-heart.svg`}
                                title={shortenNumberToStringWithComma(
                                    props.data.like_cnt,
                                )}
                                isLoading={isImageLoading}
                            />
                        </MarketProjectItemListItemSubInfoContainer>
                    </MarketProjectItemListItemInfoContainer>
                </MarketProjectItemListItemWrapper>
            </MarketProjectItemListItemContainer>
        </>
    );
};

export default MarketProjectItemListItem;
