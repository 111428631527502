import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { ReactElement } from "react";
import CssFilterConverter from "css-filter-converter";

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CheckBoxLabel = styled.label<{ checked: boolean; isBorder: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    flex-shrink: 0;

    border: ${({ checked }) =>
        checked ? `1.5px solid #FA54FA` : `1.5px solid #c8c8c8`};
    background: ${({ checked }) => (checked ? `#FA54FA` : `white`)};

    ${({ isBorder }) => (!isBorder ? `border: none; background: white;` : "")};
    cursor: pointer;

    & > svg {
        position: absolute;
    }

    transition: all 300ms ease;
`;

export const HiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
export const CheckIcon = styled.img<{ checked: boolean; isBorder: boolean }>`
    width: 12px;
    height: 10px;
    display: ${({ checked, isBorder }) => {
        if (isBorder) {
            return !checked ? `none` : `block`;
        } else {
            return "block";
        }
    }};

    filter: ${({ checked, isBorder }) => {
        if (!isBorder) {
            return checked
                ? CssFilterConverter.hexToFilter("#FA54FA").color
                : CssFilterConverter.hexToFilter("#C8C8C8").color;
        } else {
            return `none`;
        }
    }};
    transition: all 300ms ease;
`;

export const Label = styled.label`
    display: inline-block;
    cursor: pointer;
`;

export interface CheckboxProps {
    id: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    labelComponent: ReactElement;
    isBorder: boolean;
}

const Checkbox = ({
    id,
    checked,
    onChange,
    labelComponent,
    isBorder,
}: CheckboxProps) => {
    return (
        <CheckboxContainer>
            <CheckBoxLabel checked={checked} htmlFor={id} isBorder={isBorder}>
                <HiddenCheckbox
                    id={id}
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                />
                <CheckIcon
                    checked={checked}
                    isBorder={isBorder}
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-small.svg`}
                />
            </CheckBoxLabel>
            {labelComponent ? (
                <Label htmlFor={id}>{labelComponent}</Label>
            ) : null}
        </CheckboxContainer>
    );
};

export default Checkbox;

Checkbox.defaultProps = {
    isBorder: true,
};
