import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../../../../modal/modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalButtonContainer,
    ModalDescWarning,
    ModalInfoContainer,
    ModalLargeDesc,
    ModalTitle,
} from "../../../../commonUnitComponent/modal/ModalStyle";
import {
    InfoTableColumnBoldValue,
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableColumnWarningValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
    InfoTableSubColumnValue,
    InfoTableSubRow,
    InfoTableTerm,
    InfoTableTermAgreeLabel,
    InfoTableTermCheckboxContainer,
    InfoTableTermContainer,
    InfoTableTermLink,
    InfoTableTermSpan,
} from "../../../../../../styles/InfoTableStyle";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import Checkbox from "../../../../commonUnitComponent/checkbox/Checkbox";
import Spinner from "../../../../commonUnitComponent/spinner/Spinner";
import { useItemDataQuery } from "../../../../../../query/item/useItemDataQuery";
import { queryClient } from "../../../../../../App";
import { Query } from "react-query";
import { useOrderBuyMutation } from "../../../../../../query/market/common/useOrderBuyMutation";
import { useGetMarketAveragePrice } from "../../../../../../query/market/common/useGetMarketAveragePrice";

export const ItemBuyModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 432px;

    @media ${(props) => props.theme.mobileL} {
        max-width: 100%;
    }
`;

type Props = {
    resolve: (value: boolean) => void;
    count: number;
    setCount: (value: number) => void;
    itemIdx: string;
    projectIdx: string;
};

const ItemBuyModal = (props: Props): ReactElement | null => {
    const terms = [
        "[필수] 현재 구매가를 확인했으며, 구매하고자 하는 상품과 가격이 확실합니다.",
        "[필수] 주문 또는 체결시 내 주문(체결) 정보가 다른 사람들에게 공개됩니다. 계속해서 진행할 경우 동의한 것으로 간주합니다.",
        <InfoTableTermSpan>
            [필수]{" "}
            <InfoTableTermLink
                to={"https://about.saybuzz.io/ko/policy/service"}
                target={"_blank"}
            >
                서비스 이용약관
            </InfoTableTermLink>
            을 확인 및 동의하고 결제를 진행합니다.
        </InfoTableTermSpan>,
        <InfoTableTermSpan>
            [필수]{" "}
            <InfoTableTermLink
                target={"_blank"}
                to={"https://about.saybuzz.io/ko/policy/privacy/usaged"}
            >
                개인정보 수집 및 이용 동의
            </InfoTableTermLink>
            를 읽고 이해했으며 이에 동의합니다.
        </InfoTableTermSpan>,
        "[필수] 거래가 체결되면 취소가 불가능합니다.",
    ];

    const termsCheckboxLabel = "위 사항을 확인하였으며, 구매에 동의합니다.";
    const [isAgree, setIsAgree] = useState(false);
    const [isRetry, setIsRetry] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const { data: rawItemData } = useItemDataQuery(props.itemIdx);
    useEffect(() => {
        setIsAgree(false);
        setIsPending(false);
    }, []);

    useEffect(() => {
        setIsAgree(false);
        setIsPending(false);
    }, [isRetry]);

    const {
        data: rawMarketItemAveragePrice,
        refetch: refetchMarketItemAveragePrice,
    } = useGetMarketAveragePrice(
        "item",
        props.itemIdx,
        {
            quantity: props.count,
        },
        true,
    );

    const { mutateAsync: buyItem, isLoading: isBuyItemLoading } =
        useOrderBuyMutation("item", props.itemIdx, {
            order_quantity: props.count,
            order_unit_price: rawMarketItemAveragePrice.averagePrice,
        });

    return (
        <ItemBuyModalContainer>
            <ModalCloseIcon isShow={!isPending} resolve={props.resolve} />
            <ModalTitle>아이템 {isRetry ? "재" : ""}구매</ModalTitle>
            <ModalLargeDesc>
                아래 가격으로 아이템을 {isRetry ? "재" : ""}구매하시겠습니까?
            </ModalLargeDesc>
            <ModalDescWarning>
                직접 등록한 상품은 구매가 제한되어 제외한 가격이며, <br />
                구매 시점에 따라 가격이 달라질 수 있습니다.
            </ModalDescWarning>
            <ModalInfoContainer width={"412px"} mt={"20px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        구매수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(props.count)}{" "}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        {rawItemData.itemInfo.project_erc === ""
                            ? "구매금액"
                            : "결제금액"}
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                rawMarketItemAveragePrice.totalAmount,
                            )}{" "}
                            <InfoTableColumnUnit>
                                {rawItemData.itemInfo.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        평균단가
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                rawMarketItemAveragePrice.averagePrice,
                            )}{" "}
                            <InfoTableColumnUnit>
                                {rawItemData.itemInfo.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableMajorRow>
                        구매로열티
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                rawMarketItemAveragePrice.royalty.brandRoyalty +
                                    rawMarketItemAveragePrice.royalty
                                        .creatorRoyalty,
                            )}
                            <InfoTableColumnUnit> RP</InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        브랜드 로열티
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                rawMarketItemAveragePrice.royalty.brandRoyalty,
                            )}
                            <InfoTableColumnUnit> RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableSubRow>
                        크리에이터 로열티
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                rawMarketItemAveragePrice.royalty
                                    .creatorRoyalty,
                            )}
                            <InfoTableColumnUnit> RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableSubRow>
                        <div></div>
                        <InfoTableColumnWarningValue>
                            *구매 로열티{" "}
                            {numberToStringWithComma(
                                rawItemData.itemInfo.brand_royalty +
                                    rawItemData.itemInfo.creator_royalty,
                            )}
                            % 포함
                        </InfoTableColumnWarningValue>
                    </InfoTableSubRow>
                </InfoTableRowContainer>
                {/*{rawItemData.itemInfo.project_erc === "" && (*/}
                {/*    <InfoTableUnderLine />*/}
                {/*)}*/}
                {/*{rawItemData.itemInfo.project_erc === "" && (*/}
                {/*    <InfoTableRowContainer>*/}
                {/*        <InfoTableMajorRow>*/}
                {/*            총 주문가격*/}
                {/*            <InfoTableColumnBoldValue>*/}
                {/*                {numberToStringWithComma(itemPriceAmount)}*/}
                {/*                <InfoTableColumnUnit>*/}
                {/*                    {rawItemData.itemInfo.project_currency}*/}
                {/*                </InfoTableColumnUnit>*/}
                {/*            </InfoTableColumnBoldValue>*/}
                {/*        </InfoTableMajorRow>*/}
                {/*    </InfoTableRowContainer>*/}
                {/*)}*/}
            </ModalInfoContainer>
            <InfoTableTermContainer>
                {terms.map((term, idx) => {
                    return <InfoTableTerm key={idx}>{term}</InfoTableTerm>;
                })}
            </InfoTableTermContainer>

            <InfoTableTermCheckboxContainer>
                <Checkbox
                    id={"market__item__buy__term__check-box"}
                    checked={isAgree}
                    onChange={() => {
                        setIsAgree((prev) => {
                            return !prev;
                        });
                    }}
                    labelComponent={
                        <InfoTableTermAgreeLabel>
                            {termsCheckboxLabel}
                        </InfoTableTermAgreeLabel>
                    }
                />
            </InfoTableTermCheckboxContainer>

            <ModalButtonContainer>
                <ModalButton
                    btnType={
                        !isPending
                            ? "filled-secondary"
                            : "filled-secondary-disabled"
                    }
                    onClick={() => {
                        if (!isPending) {
                            props.resolve(false);
                        }
                    }}
                >
                    취소
                </ModalButton>
                <ModalButton
                    btnType={
                        !isAgree
                            ? "filled-primary-disabled"
                            : !isPending
                            ? "filled-primary"
                            : "filled-primary-pending"
                    }
                    onClick={async () => {
                        if (isAgree && !isBuyItemLoading) {
                            try {
                                setIsPending(true);
                                await buyItem();
                                props.setCount(0);
                                props.resolve(true);
                                // isPending 초기화 삭제 - 성공시에 닫는다
                            } catch (err: any) {
                                // 평균 금액이 변동된 경우
                                if (
                                    err.response.data.data.errorCode.indexOf(
                                        "AveragePriceVaried",
                                    ) !== -1
                                ) {
                                    setIsPending(false);
                                    setIsRetry(true);
                                }
                                // 일반 케이스 실패
                                else {
                                    props.resolve(false);
                                }
                            }
                            await refetchMarketItemAveragePrice();
                            await queryClient.resetQueries({
                                predicate: (query: Query) =>
                                    query.queryKey.includes(
                                        "item-info-refresh-target-" +
                                            props.itemIdx,
                                    ),
                            });
                        }
                    }}
                >
                    {isPending ? (
                        <Spinner
                            width={28}
                            height={28}
                            borderSize={3}
                            color={"#fa54fa #fa54fa #fa54fa #f0f0f2"}
                        />
                    ) : isRetry ? (
                        "재구매하기"
                    ) : (
                        "구매하기"
                    )}
                </ModalButton>
            </ModalButtonContainer>
        </ItemBuyModalContainer>
    );
};

export default ItemBuyModal;
