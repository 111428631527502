import { createRef, ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChipType } from "../../commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { Chip, CustomChipContainer } from "./Chips";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useDebounce, useWindowSize } from "usehooks-ts";
import { useIsMe } from "../../../../hooks/common/useIsMe";

export const ChipsNoScrollContainer = styled.div<{
    containerPadding?: string;
    containerHeight?: string;
}>`
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    justify-content: left;
    align-items: center;
    gap: 6px;
    padding-left: ${({ containerPadding }) => containerPadding};
    padding-right: ${({ containerPadding }) => containerPadding};
`;

export const EditChipContainer = styled.div<{
    containerPadding?: string;
    containerHeight?: string;
}>`
    cursor: pointer;
    display: flex;
    gap: 4px;
    height: ${({ containerHeight }) =>
        containerHeight ? containerHeight : "44px"};
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
`;

export const EditChipIcon = styled.img``;

export const FoldChipIcon = styled.img`
    display: block;
    width: 22px;
    height: 22px;
`;

type Props = {
    userIdx: string;
    isAllShow: boolean;
    allBtnTitle?: string;
    isOnlyOneSelected: boolean;
    chips: ChipType[];
    setChips: (value: ChipType[]) => void;
    containerPadding?: string;
    containerHeight?: string;
    renderCustomChip?: (idx: number, chip: ChipType) => ReactElement;
    onEditClickHandler?: () => Promise<void> | void;
};

const ChipsNoScroll = (props: Props): JSX.Element => {
    const { width } = useWindowSize();
    const debouncedWidth = useDebounce<number>(width, 100);
    const containerRef = useRef<HTMLDivElement>(null);
    const isAllNotSelected = () => {
        return props.chips.every((chip) => !chip.isSelected);
    };
    const isMe = useIsMe();
    const [isFold, setIsFold] = useState(true);
    const [fourthRowStartIndex, setFourthRowStartIndex] = useState(-1);
    const [isForceShow, setIsForceShow] = useState(false);
    const [isDomPainted, setIsDomPainted] = useState(false);

    async function oneChipClickHandler(idx: number) {
        props.setChips(
            props.chips.map((chip, innerIdx) => {
                return {
                    ...chip,
                    isSelected: idx === innerIdx,
                };
            }),
        );
    }

    const chipRefList = [
        ...props.chips.map(() => {
            return createRef<HTMLDivElement>();
        }),
        createRef<HTMLDivElement>(),
        createRef<HTMLDivElement>(),
    ];

    function findFourthRowStartIndex(arr: number[]): number {
        let changeCount = 0;

        for (let i = 1; i < arr.length; i++) {
            if (arr[i] !== arr[i - 1] && ++changeCount === 3) {
                return i;
            }
        }

        return 0;
    }

    function setHideChips() {
        let foundIndex = findFourthRowStartIndex(
            chipRefList.map((ref) => ref.current?.offsetTop || -1),
        );
        const targetWidth = (isMe(props.userIdx) ? 72 : 0) + 36 + 6;
        let sumWidth = 0;

        for (let i = foundIndex; i > 0; i--) {
            sumWidth += chipRefList[foundIndex - 1].current?.clientWidth || 0;
            if (sumWidth > targetWidth) {
                foundIndex = i - 1;
                break;
            }
        }
        setFourthRowStartIndex(foundIndex);
        setIsForceShow(false);
    }

    useEffect(() => {
        setIsForceShow(true);
    }, [width]);

    useEffect(() => {
        setHideChips();
    }, [debouncedWidth]);

    useEffect(() => {
        if (props.chips.length !== 0 && !isDomPainted) {
            setHideChips();
            setIsDomPainted(true);
        }
    }, [props.chips.length]);

    return (
        <ChipsNoScrollContainer
            ref={containerRef}
            containerPadding={props.containerPadding}
            containerHeight={props.containerHeight}
        >
            {props.isAllShow && (
                <Chip
                    isSelected={isAllNotSelected()}
                    onClick={async () => {
                        props.setChips(
                            props.chips.map((chip) => {
                                return {
                                    ...chip,
                                    isSelected: false,
                                };
                            }),
                        );
                    }}
                >
                    {props.allBtnTitle ? props.allBtnTitle : "전체"}
                </Chip>
            )}
            {props.chips.map((chip, idx) => {
                return props.renderCustomChip ? (
                    <CustomChipContainer
                        ref={chipRefList[idx]}
                        key={idx}
                        onClick={async () => {
                            await oneChipClickHandler(idx);
                        }}
                    >
                        {props.renderCustomChip(idx, chip)}
                    </CustomChipContainer>
                ) : (
                    <Chip
                        ref={chipRefList[idx]}
                        key={idx}
                        isSelected={chip.isSelected}
                        onClick={async () => {
                            await oneChipClickHandler(idx);
                        }}
                        isHide={
                            !isForceShow &&
                            isFold &&
                            fourthRowStartIndex !== 0 &&
                            fourthRowStartIndex !== -1 &&
                            idx >= fourthRowStartIndex
                        }
                    >
                        {chip.title}
                    </Chip>
                );
            })}

            {fourthRowStartIndex !== 0 && (
                <Chip
                    ref={chipRefList[props.chips.length]}
                    isSelected={false}
                    style={{
                        padding: "5px 6px",
                    }}
                    onClick={() => {
                        setIsFold(!isFold);
                    }}
                >
                    <FoldChipIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/${
                            isFold
                                ? "icon-more-horizontal.svg"
                                : "icon-arrow-up-small.svg"
                        }`}
                    />
                </Chip>
            )}
            {isMe(props.userIdx) && (
                <EditChipContainer
                    ref={chipRefList[props.chips.length + 1]}
                    containerPadding={props.containerPadding}
                    containerHeight={props.containerHeight}
                    onClick={async () => {
                        if (props.onEditClickHandler) {
                            props.onEditClickHandler();
                        }
                    }}
                >
                    <EditChipIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-edit.svg`}
                    />
                    편집
                </EditChipContainer>
            )}
        </ChipsNoScrollContainer>
    );
};

export default ChipsNoScroll;
