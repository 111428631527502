import { ReactElement } from "react";
import styled from "styled-components";
import { ResponsiveWidth } from "../../../../styles/Theme";
import { useElementSize } from "usehooks-ts";
import FooterPcType from "./FooterPcType";
import FooterMobileType from "./FooterMobileType";

type Props = {
    type: string;
};

export const FooterWrapper = styled.div`
    background: #fafafa;
`;

export const FooterContainer = styled.div<{
    wrapperWidth: number;
    type: string;
}>`
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    max-width: ${({ type }) => (type === "normal" ? "1280px" : "1080px")};
    width: 100%;

    ${({ wrapperWidth }) => {
        let style = "";
        if (wrapperWidth <= ResponsiveWidth.PCM) {
            style += `  
            width: calc(100% - 64px);
            padding-left: 32px;
            padding-right: 32px;`;
        }

        if (wrapperWidth <= ResponsiveWidth.mobileL) {
            style += `
            width: calc(100% - 32px);
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 80px;`;
        }

        return style;
    }};
`;

export const FooterPcTypeContainer = styled.div<{ wrapperWidth: number }>`
    width: 100%;

    ${({ wrapperWidth }) => {
        let style = "";
        if (wrapperWidth <= ResponsiveWidth.tabletM) {
            style += `display: none`;
        }
        return style;
    }};
`;

export const FooterMobileTypeContainer = styled.div<{ wrapperWidth: number }>`
    display: none;
    width: 100%;
    ${({ wrapperWidth }) => {
        let style = "";
        if (wrapperWidth <= ResponsiveWidth.tabletM) {
            style += `display: block`;
        }
        return style;
    }};
`;

export const FooterLogo = styled.img`
    width: 137px;
    height: 27px;
`;

const Footer = (props: Props): ReactElement | null => {
    const [footerWrapperRef, { width: rawFooterWrapperWidth }] =
        useElementSize();
    const footerWrapperWidth = rawFooterWrapperWidth || 0;

    return (
        <FooterWrapper ref={footerWrapperRef}>
            <FooterContainer
                type={props.type}
                wrapperWidth={footerWrapperWidth}
            >
                <FooterPcTypeContainer wrapperWidth={footerWrapperWidth}>
                    <FooterPcType footerWrapperWidth={footerWrapperWidth} />
                </FooterPcTypeContainer>
                <FooterMobileTypeContainer wrapperWidth={footerWrapperWidth}>
                    <FooterMobileType footerWrapperWidth={footerWrapperWidth} />
                </FooterMobileTypeContainer>
            </FooterContainer>
        </FooterWrapper>
    );
};

export default Footer;

Footer.defaultProps = {
    type: "normal",
};
