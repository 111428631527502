import { ReactElement } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../../../utils/Utils";

export const AdManageCommonHistoryColumnPriceContainer = styled.div<{
    fontColor: string;
    fontWeight: number;
}>`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: ${({ fontColor }) => fontColor};
`;

type Props = {
    offerPrice: number | string;
    fontColor: string;
    fontWeight: number;
};

const AdManageCommonHistoryColumnPrice = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnPriceContainer
            fontColor={props.fontColor}
            fontWeight={props.fontWeight}
        >
            {typeof props.offerPrice === "number"
                ? numberToStringWithComma(props.offerPrice)
                : props.offerPrice}
        </AdManageCommonHistoryColumnPriceContainer>
    );
};

export default AdManageCommonHistoryColumnPrice;
AdManageCommonHistoryColumnPrice.defaultProps = {
    fontColor: "#555555",
    fontWeight: 400,
};
