import { ReactElement } from "react";
import styled from "styled-components";
import ThreeDotButton from "../../../../../commonUnitComponent/threeDotButton/ThreeDotButton";
import { NotificationListItemType } from "../../../../../../../query/notification/useGetNotificationListQuery";
import { format } from "date-fns";
import { useDeleteNotificationMutation } from "../../../../../../../query/notification/useDeleteNotificationMutation";
import { useReadNotificationMutation } from "../../../../../../../query/notification/useReadNotificationMutation";
import { Link, useNavigate } from "react-router-dom";
import { getIsLogin } from "../../../../../../../store/auth/authKeyStore";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";

export const NotiListItemContainer = styled(Link)`
    width: calc(100% - 16px);
    display: flex;
    padding: 10px 0 10px 6px;
    border-radius: 12px;
    transition: background 300ms ease;
    position: relative;
    cursor: pointer;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f1f2;
        }
    }
`;

export const NotiListItemReadDim = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff60;
`;

export const NotiListItemThumbnailImgContainer = styled.div`
    width: 44px;
`;

export const NotiListItemThumbnailImg = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 50px;
`;

export const NotiListItemTextContainer = styled.div`
    width: calc(100% - 63px);
    padding-left: 13px;
`;

export const NotiListItemTitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const NotiListItemTitle = styled.div`
    color: #000;
    width: calc(100% - 32px);
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    padding: 5px 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all; // 문단으로 끊어져서 줄바꿈 됨
    height: auto;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
`;

export const NotiListItemThreeDotBtnContainer = styled.div`
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    line-height: 1;
`;

export const NotiListItemDesc = styled.div`
    margin-top: 3px;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    color: #888888;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all; // 문단으로 끊어져서 줄바꿈 됨
`;

export const NotiListItemNotiDate = styled.div`
    margin-top: 6px;
    width: 100%;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #fa54fa;
`;

type Props = {
    data: NotificationListItemType;
    isLogin: boolean; // 함수 콜 부모에서 한번만 시키기
    resolve: (value: number | boolean) => void;
};

const NotiListItem = (props: Props): ReactElement | null => {
    const navigate = useNavigate();
    const { mutateAsync: readNotification } = useReadNotificationMutation(
        props.data.notification_idx,
        {
            notification_type: props.data.notification_type,
        },
    );

    const { mutateAsync: deleteNotification } = useDeleteNotificationMutation(
        props.data.notification_idx,
        {
            notification_type: props.data.notification_type,
        },
    );

    const isLogin = getIsLogin();

    const moreThreeDotConfig = [
        {
            title: "읽음으로 표시",
            clickHandler: async () => {
                await readNotification();
            },
            isShow: !props.data.read_bool,
            isGoOutIcon: false,
        },
        {
            title: "삭제하기",
            clickHandler: async () => {
                await deleteNotification();
            },
            isGoOutIcon: false,
        },
    ];

    return (
        <NotiListItemContainer
            to={
                props.data.endPoint.indexOf(URL_CONSTANTS.PAGE_URL) !== -1
                    ? props.data.endPoint
                    : {}
            }
            onClick={async () => {
                if (isLogin) {
                    await readNotification();
                }
                if (
                    props.data.endPoint.indexOf(URL_CONSTANTS.PAGE_URL) === -1
                ) {
                    window.open(props.data.endPoint, "_blank");
                }
                props.resolve(-1);
            }}
        >
            {props.data.read_bool && <NotiListItemReadDim />}
            <NotiListItemThumbnailImgContainer>
                <NotiListItemThumbnailImg
                    src={props.data.thumbnail_full_path}
                />
            </NotiListItemThumbnailImgContainer>
            <NotiListItemTextContainer>
                <NotiListItemTitleContainer>
                    <NotiListItemTitle>{props.data.title}</NotiListItemTitle>
                    <NotiListItemThreeDotBtnContainer>
                        {props.isLogin && (
                            <ThreeDotButton
                                config={moreThreeDotConfig}
                                hasBorder={false}
                                hasHoverEvent={false}
                                size={"32px"}
                            />
                        )}
                    </NotiListItemThreeDotBtnContainer>
                </NotiListItemTitleContainer>
                <NotiListItemDesc>{props.data.sub_title}</NotiListItemDesc>
                <NotiListItemNotiDate>
                    {format(
                        new Date(props.data.created_at),
                        "yyyy년 MM월 dd일",
                    )}
                </NotiListItemNotiDate>
            </NotiListItemTextContainer>
        </NotiListItemContainer>
    );
};

export default NotiListItem;
