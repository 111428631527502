import { useOpenModal } from "../useOpenModal";
import React from "react";
import CommonErrorModal from "../../../components/common/modal/commonErrorModal/CommonErrorModal";

export type CommonErrorModalInfoType = {
    title: string;
    banwords?: string;
};

export function useOpenCommonErrorModal() {
    const { openModal } = useOpenModal();

    return {
        openCommonErrorModal: async (params: CommonErrorModalInfoType) => {
            return await openModal(
                "common-error-modal-" + encodeURIComponent(params.title),
                "modal",
                (resolve) => {
                    return <CommonErrorModal resolve={resolve} {...params} />;
                },
            );
        },
    };
}
