import { ReactElement } from "react";
import HeaderSearchInput from "./components/HeaderSearchInput";
import styled from "styled-components";
import isDarkModeAtom from "../../../../../../store/ui/isDarkModeAtom";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useAtom } from "jotai";
import CssFilterConverter from "css-filter-converter";
import { useOpenSearchResultModal } from "../../../../../../hooks/modal/openModal/useOpenSearchResultModal";

export const SearchAreaContainer = styled.div`
    position: relative;
    margin-right: auto;
    margin-left: 32px;

    display: flex;
    align-items: center;

    @media ${(props) => props.theme.tabletL} {
        margin-right: 0;
        margin-left: auto;
    }
`;

export const SearchIconImg = styled.img<{
    isDarkMode: boolean;
}>`
    position: absolute;
    right: 10px;
    width: 40px;
    height: 40px;
    z-index: 2;
    cursor: pointer;
    bottom: 0;

    @media ${(props) => props.theme.tabletL} {
        position: relative;
        right: auto; // 기존 right 풀어주기
        height: 40px;
        margin: 4px;
        border-radius: 10px;
        transition: background-color 300ms ease;
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background: ${({ isDarkMode }) =>
                    isDarkMode
                        ? "rgba(255, 255, 255, 0.12)"
                        : "rgba(13, 13, 26, 0.06)"};
            }
        }
    } ;
`;

type Props = {};

const HeaderSearchArea = (props: Props): ReactElement | null => {
    const [isDarkMode] = useAtom(isDarkModeAtom);
    const { openSearchResultModal, isOpenSearchResultModal } =
        useOpenSearchResultModal();

    const isSearching = isOpenSearchResultModal;

    return (
        <SearchAreaContainer>
            <SearchIconImg
                isDarkMode={isDarkMode}
                onClick={async () => {
                    await openSearchResultModal();
                }}
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-search-black.svg`}
                style={
                    isDarkMode && !isSearching
                        ? {
                              filter: `${
                                  CssFilterConverter.hexToFilter("#D9D9D9")
                                      .color
                              }`,
                          }
                        : {}
                }
            />
            <HeaderSearchInput />
        </SearchAreaContainer>
    );
};

export default HeaderSearchArea;
