import { ReactElement } from "react";
import styled from "styled-components";
import {
    CalendarCellRowContainerStyle,
    CalendarCellRowIcon,
} from "./CaldendarCellRowStyle";
import { getStyle } from "./CalendarCellBackgroundRow";

export const CalendarCellBackgroundRowTwoColumnWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: flex-start;
`;

export const CalendarCellBackgroundRowTwoColumnMsgContainer = styled.div`
    ${CalendarCellRowContainerStyle};
`;

export const CalendarCellBackgroundRowTwoColumnIconContainer = styled.div`
    ${CalendarCellRowContainerStyle};
    width: 18px;
    height: 18px;
    padding: 4px;

    @media ${(props) => props.theme.tabletL} {
        width: 18px;
        padding: 2px;
    }
`;

type Props = {
    msgType: string;
    iconType: string;
    msg: string;
    iconPath: string;
    isShowIcon?: boolean;
};

const CalendarCellBackgroundRowTwoColumn = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarCellBackgroundRowTwoColumnWrapper>
            <CalendarCellBackgroundRowTwoColumnMsgContainer
                style={getStyle(props.msgType)}
            >
                {props.msg}
            </CalendarCellBackgroundRowTwoColumnMsgContainer>

            {typeof props.isShowIcon !== "undefined" && props.isShowIcon && (
                <CalendarCellBackgroundRowTwoColumnIconContainer
                    style={getStyle(props.iconType)}
                >
                    <CalendarCellRowIcon src={props.iconPath} />
                </CalendarCellBackgroundRowTwoColumnIconContainer>
            )}
        </CalendarCellBackgroundRowTwoColumnWrapper>
    );
};

export default CalendarCellBackgroundRowTwoColumn;
