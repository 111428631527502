import { ReactElement } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../commonStyle/CommonButtonStyle";
import hasChannelTalkAccessAtom from "../../../../../store/ui/hasChannelTalkAccessAtom";
import { useAtom } from "jotai";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";

export const TutorialContactBtnContainer = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    height: 42px;
`;

export const TutorialContactBtnIcon = styled.img``;

type Props = {};

const TutorialContactBtn = (props: Props): ReactElement | null => {
    const [hasChannelTalkAccess, setHasChannelTalkAccess] = useAtom(
        hasChannelTalkAccessAtom,
    );

    return hasChannelTalkAccess ? (
        <TutorialContactBtnContainer
            btnType={"outlined-secondary"}
            onClick={() => {
                ChannelService.showMessenger();
            }}
        >
            <TutorialContactBtnIcon
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-channel-talk.svg`}
            />
            문의하기
        </TutorialContactBtnContainer>
    ) : null;
};

export default TutorialContactBtn;
