import { atom } from "jotai";

export type imageFullSizeViewInfoAtomType = {
    src: string;
    info: string[];
};

const imageFullSizeViewInfoAtom = atom<imageFullSizeViewInfoAtomType>({
    src: "",
    info: [],
});

export default imageFullSizeViewInfoAtom;
