import { atomWithReset } from "jotai/utils";

export type UserInfoType = {
    user_id: string;
    user_idx: string;
    user_nick: string;
};

export const reInitUserInfoData = {
    user_idx: "0",
    user_id: "",
    user_nick: "",
};
const UserInfoAtom = atomWithReset<UserInfoType>(reInitUserInfoData);

export default UserInfoAtom;
