export type CreatorInfoType = {
    creator_description: string;
    creator_follow_cnt: number;
    creator_id: string;
    creator_idx: string;
    creator_img_full_path: string;
    creator_name: string;
    creator_type: string;
    user_influencer_bool: boolean;
    follow_bool: boolean;
};

export const DEFAULT_CREATOR_INFO_TYPE_DATA: CreatorInfoType = {
    creator_description: "", // 문자열 타입 필드
    creator_follow_cnt: 0, // 숫자 타입 필드 (기본값 0)
    creator_id: "", // 문자열 타입 필드
    creator_idx: "", // 문자열 타입 필드
    creator_img_full_path: "", // 문자열 타입 필드
    creator_name: "", // 문자열 타입 필드
    creator_type: "", // 문자열 타입 필드
    user_influencer_bool: false,
    follow_bool: false,
};
