import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalTitle } from "../../../../../../../common/commonUnitComponent/modal/ModalStyle";
import {
    spreadItemInventoryBelongingGroupListPages,
    useGetItemInventoryBelongingGroupList,
} from "../../../../../../../../query/itemInventory/useGetItemInventoryBelongingGroupList";
import { ItemInventoryBelongingGroupListItemType } from "../../../../../../../../types/itemInventory/ItemInventoryBelongingGroupListItemType";
import { WalletCollectionGroupEditListDescContainer } from "../walletCollectionGroupEditModal/WalletCollectionGroupEditModal";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../../common/commonStyle/CommonButtonStyle";
import WalletCollectionGroupMoveCheckList from "./components/WalletCollectionGroupMoveCheckList";
import { useOpenCollectionGroupAddModal } from "../../../../../../../../hooks/modal/openModal/useOpenCollectionGroupAddModal";
import { useOpenCollectionGroupEditModal } from "../../../../../../../../hooks/modal/openModal/useOpenCollectionGroupEditModal";
import { useSetItemInventoryBelongingGroupMutation } from "../../../../../../../../query/itemInventory/useSetItemInventoryBelongingGroupMutation";
import ModalCloseIcon from "../../../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import Spinner from "../../../../../../../common/commonUnitComponent/spinner/Spinner";
import { shallowEqual } from "../../../../../../../../utils/Utils";

export const WalletCollectionGroupMoveModalWrapper = styled.div`
    width: 352px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const WalletCollectionGroupMoveModalContainer = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const WalletCollectionGroupMoveModalGroupCheckListWrapper = styled.div`
    width: 100%;
    position: relative;
    height: 252px;
    border-radius: 8px;
    border: 1px solid #ebebeb;
`;

export const WalletCollectionGroupMoveModalGroupCheckListContainer = styled.div`
    padding: 8px;
`;

export const WalletCollectionGroupMoveModalNoBorderBtnContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 12px;
`;

export const WalletCollectionGroupMoveModalNoBorderBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const WalletCollectionGroupMoveModalNoBorderBtnIcon = styled.img``;

export const WalletCollectionGroupMoveModalBtnContainer = styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    gap: 10px;
`;

export const WalletCollectionGroupMoveModalBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    height: 48px;
`;

type Props = {
    resolve: (value: boolean) => void;
    itemIdx: string;
    targetUserIdx: string;
};

export const CollectionMoveGroupTempScrollTopKey = `collection-move-group-temp-scrollTop`;

const WalletCollectionGroupMoveModal = (props: Props): ReactElement | null => {
    const { openCollectionGroupAddModal } = useOpenCollectionGroupAddModal();
    const { openCollectionGroupEditModal } = useOpenCollectionGroupEditModal();

    const {
        isSuccess: isItemInventoryBelongingGroupListSuccess,
        data: rawItemInventoryBelongingGroupList,
        refetch: refetchGetItemInventoryBelongingGroupList,
    } = useGetItemInventoryBelongingGroupList(props.itemIdx);

    const [itemInventoryGroupCheckList, setItemInventoryGroupCheckList] =
        useState<ItemInventoryBelongingGroupListItemType[]>([]);

    const [isPending, setIsPending] = useState(false);

    const originalItemInventoryGroupCheckList =
        spreadItemInventoryBelongingGroupListPages(
            rawItemInventoryBelongingGroupList.pages,
        );

    useEffect(() => {
        if (isItemInventoryBelongingGroupListSuccess) {
            setItemInventoryGroupCheckList(originalItemInventoryGroupCheckList);
        }
    }, [
        rawItemInventoryBelongingGroupList,
        isItemInventoryBelongingGroupListSuccess,
    ]);

    const { mutateAsync: setItemInventoryBelongingGroup } =
        useSetItemInventoryBelongingGroupMutation(props.itemIdx, {
            groupList:
                itemInventoryGroupCheckList.length ===
                originalItemInventoryGroupCheckList.length
                    ? itemInventoryGroupCheckList.map(
                          (itemBelongingGroup, itemBelongingGroupIdx) => {
                              return {
                                  item_inventory_group_idx:
                                      itemBelongingGroup.item_inventory_group_idx,
                                  item_inventory_group_sub_idx:
                                      itemBelongingGroup.item_inventory_group_sub_idx,
                                  insert_flag:
                                      !originalItemInventoryGroupCheckList[
                                          itemBelongingGroupIdx
                                      ].item_inventory_group_sub_exist &&
                                      itemBelongingGroup.item_inventory_group_sub_exist,
                                  delete_flag:
                                      originalItemInventoryGroupCheckList[
                                          itemBelongingGroupIdx
                                      ].item_inventory_group_sub_exist &&
                                      !itemBelongingGroup.item_inventory_group_sub_exist,
                              };
                          },
                      )
                    : [],
        });

    return (
        <WalletCollectionGroupMoveModalWrapper>
            <ModalCloseIcon resolve={props.resolve} />
            <ModalTitle>그룹으로 이동</ModalTitle>

            <WalletCollectionGroupMoveModalContainer>
                <WalletCollectionGroupMoveCheckList
                    itemInventoryGroupCheckList={itemInventoryGroupCheckList}
                    setItemInventoryGroupCheckList={
                        setItemInventoryGroupCheckList
                    }
                />

                <WalletCollectionGroupEditListDescContainer>
                    그룹은 최대 20개까지 생성이 가능합니다.
                </WalletCollectionGroupEditListDescContainer>
                <WalletCollectionGroupMoveModalNoBorderBtnContainer>
                    <WalletCollectionGroupMoveModalNoBorderBtn
                        btnType={"text-secondary"}
                        onClick={async () => {
                            const addResult =
                                await openCollectionGroupAddModal();

                            if (addResult)
                                setTimeout(() => {
                                    const scrollContainer =
                                        document.getElementById(
                                            "wallet-collection-group-move-modal__custom-scrollbar-children__wrapper",
                                        );

                                    if (scrollContainer) {
                                        scrollContainer.scrollIntoView({
                                            block: "end",
                                            behavior: "smooth",
                                        });
                                    }
                                }, 300);
                        }}
                    >
                        + 새 그룹 추가
                    </WalletCollectionGroupMoveModalNoBorderBtn>
                    <WalletCollectionGroupMoveModalNoBorderBtn
                        btnType={"text-secondary"}
                        onClick={async () => {
                            const editResult =
                                await openCollectionGroupEditModal(
                                    props.targetUserIdx,
                                );

                            if (editResult) {
                                await refetchGetItemInventoryBelongingGroupList();
                            }
                        }}
                    >
                        <WalletCollectionGroupMoveModalNoBorderBtnIcon />
                        그룹 편집하기
                    </WalletCollectionGroupMoveModalNoBorderBtn>
                </WalletCollectionGroupMoveModalNoBorderBtnContainer>

                <WalletCollectionGroupMoveModalBtnContainer>
                    <WalletCollectionGroupMoveModalBtn
                        btnType={"filled-secondary"}
                        onClick={() => {
                            props.resolve(false);
                        }}
                    >
                        취소
                    </WalletCollectionGroupMoveModalBtn>
                    <WalletCollectionGroupMoveModalBtn
                        btnType={
                            itemInventoryGroupCheckList.length === 0 ||
                            !itemInventoryGroupCheckList.some(
                                (checkListItem) =>
                                    checkListItem.item_inventory_group_sub_exist,
                            ) ||
                            shallowEqual(
                                originalItemInventoryGroupCheckList,
                                itemInventoryGroupCheckList,
                            )
                                ? "filled-primary-disabled"
                                : !isPending
                                ? "filled-primary"
                                : "filled-primary-pending"
                        }
                        onClick={async () => {
                            if (
                                !isPending &&
                                itemInventoryGroupCheckList.length !== 0 &&
                                itemInventoryGroupCheckList.some(
                                    (checkListItem) =>
                                        checkListItem.item_inventory_group_sub_exist,
                                ) &&
                                !shallowEqual(
                                    originalItemInventoryGroupCheckList,
                                    itemInventoryGroupCheckList,
                                )
                            ) {
                                try {
                                    setIsPending(true);
                                    await setItemInventoryBelongingGroup();
                                    props.resolve(true);
                                } catch (e) {
                                    setIsPending(false);
                                }
                            }
                        }}
                    >
                        {!isPending ? "이동하기" : <Spinner />}
                    </WalletCollectionGroupMoveModalBtn>
                </WalletCollectionGroupMoveModalBtnContainer>
            </WalletCollectionGroupMoveModalContainer>
        </WalletCollectionGroupMoveModalWrapper>
    );
};

export default WalletCollectionGroupMoveModal;
