import { useInfiniteQuery } from "react-query";
import { AdMyApi } from "../../../apis/adApi/AdMyApi";
import { MyAdDailyListItemType } from "../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";
import { MyAllAdDailyListApiResultType } from "./useGetMyAllAdDailyListQuery";

export type MyAdDailyListApiResultType = {
    advertisementDailyList: MyAdDailyListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type MyAdDailyListParams = {
    page_no: number;
    limit: number;
    ad_sales_date_from: string;
    ad_sales_date_to: string;
};

export const useGetMyAdDailyListQuery = (
    adIdx: string,
    params: MyAdDailyListParams,
) => {
    const queryResult = useInfiniteQuery<MyAdDailyListApiResultType, Error>({
        queryKey: [
            `my-ad-${adIdx}-daily-list-` + JSON.stringify(params),
            `my-ad-${adIdx}-daily-list`,
            "ad-sale-manage-refresh-target",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return AdMyApi.getMyAdDailyList(adIdx, {
                ...params,
                page_no: pageParam,
            });
        },
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        cacheTime: 0,
        staleTime: 0,
        onError: (err) => {},
    });
    const defaultData: MyAdDailyListApiResultType[] = [
        {
            advertisementDailyList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadMyAdDailyListPages(
    pages: MyAllAdDailyListApiResultType[],
) {
    return pages
        .map((result) => result.advertisementDailyList)
        .reduce((prev, next) => prev.concat(next));
}
