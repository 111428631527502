import { ReactElement } from "react";
import styled from "styled-components";
import UserProfileModalPageMenuItem from "./components/UserProfileModalPageMenuItem";
import ListItemTypeSelfAd from "../../../../../../selfAd/listItemTypeSelfAd/ListItemTypeSelfAd";
import {
    HEADER_MENUS,
    HeaderMoreMenus,
} from "../../../../../../../constants/menus/headerMenus";

export const UserProfileModalFullPageMenuWrapper = styled.div`
    cursor: pointer;
    display: none;
    flex-direction: column;
    gap: 10px;

    @media ${(props) => props.theme.tabletL} {
        display: flex;
    }
`;

export const UserProfileModalFullPageMenuContainer = styled.div`
    display: none;

    @media ${(props) => props.theme.tabletL} {
        display: block;
    }
`;

export const UserProfileModalSelfAdMobileContainer = styled.div`
    width: calc(100% - 20px);
    margin: 0;
    padding: 10px;
    background: white;
`;

export const UserProfileModalMoreMenuContainer = styled.div`
    display: none;
    background: white;
    padding: 10px 0;
    @media ${(props) => props.theme.tabletL} {
        display: block;
    }
`;

export const UserProfileModalMoreMenuListTitle = styled.div`
    display: flex;
    height: 33px;
    padding-left: 24px;
    align-items: center;
    color: #999;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 13px */
`;

type Props = {
    resolve: (value: boolean | number) => void;
};
const UserProfileModalPageMenus = (props: Props): ReactElement | null => {
    return (
        <UserProfileModalFullPageMenuWrapper>
            <UserProfileModalFullPageMenuContainer>
                {HEADER_MENUS.map((menu) => {
                    return (
                        <UserProfileModalPageMenuItem
                            key={menu.id}
                            menu={menu}
                            depth={1}
                            resolve={props.resolve}
                        />
                    );
                })}
                {/*세이버즈 광고*/}
                <UserProfileModalSelfAdMobileContainer>
                    <ListItemTypeSelfAd spaceId={"saybuzz_my_list"} />
                </UserProfileModalSelfAdMobileContainer>
            </UserProfileModalFullPageMenuContainer>

            <UserProfileModalMoreMenuContainer>
                <UserProfileModalMoreMenuListTitle>
                    더 보기
                </UserProfileModalMoreMenuListTitle>
                {HeaderMoreMenus.map((menu) => {
                    return (
                        <UserProfileModalPageMenuItem
                            key={menu.id}
                            menu={menu}
                            depth={1}
                            resolve={props.resolve}
                        />
                    );
                })}
            </UserProfileModalMoreMenuContainer>
        </UserProfileModalFullPageMenuWrapper>
    );
};

export default UserProfileModalPageMenus;
