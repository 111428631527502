import { ReactElement } from "react";
import styled from "styled-components";

export const ToggleSwitchWrapper = styled.div<{ isDisabled: boolean }>`
    position: relative;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.1 : 1.0)};
    transition: opacity 300ms ease;
    display: flex;
    align-items: center;
    height: 100%;
`;

export const ToggleSwitchContainer = styled.div`
    position: relative;
    height: 28px;
`;

export const ToggleSwitchLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 28px;
    border-radius: 17px;
    background: #bebebe;
    cursor: pointer;

    &::after {
        content: "";
        display: block;
        border-radius: 60%;
        width: 20px;
        height: 20px;
        margin: 4px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        transition: 0.2s;
    }
`;

export const ToggleSwitchInput = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 44px;
    height: 28px;

    &:checked + ${ToggleSwitchLabel} {
        background: #fa54fa;

        &::after {
            content: "";
            display: block;
            border-radius: 60%;
            width: 20px;
            height: 20px;
            margin-left: 20px;
            transition: 0.2s;
        }
    }
`;

type Props = {
    id: string;
    isChecked: boolean;
    onChangeFunction: () => void;
    isDisabled: boolean;
};

const ToggleSwitch = (props: Props): ReactElement | null => {
    return (
        <ToggleSwitchWrapper isDisabled={props.isDisabled}>
            <ToggleSwitchContainer>
                <ToggleSwitchInput
                    id={"toggle-switch-" + props.id}
                    type="checkbox"
                    checked={props.isChecked}
                    onChange={() => {
                        if (!props.isDisabled) {
                            props.onChangeFunction();
                        }
                    }}
                />
                <ToggleSwitchLabel
                    htmlFor={"toggle-switch-" + props.id}
                ></ToggleSwitchLabel>
            </ToggleSwitchContainer>
        </ToggleSwitchWrapper>
    );
};

export default ToggleSwitch;
