import { ReactElement } from "react";
import styled from "styled-components";

export const FooterCopyRightContainer = styled.div`
    color: #999;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`;

type Props = {};

const FooterCopyRight = (props: Props): ReactElement | null => {
    return (
        <FooterCopyRightContainer>
            Copyright 2024 All rights reserved. Freemeta™
        </FooterCopyRightContainer>
    );
};

export default FooterCopyRight;
