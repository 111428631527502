import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import NotiListItem from "./components/NotiListItem";
import Scrollbar from "../../../../commonUiComponent/scrollbar/Scrollbar";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../../../../store/scroll/viewHeightAtom";
import ListItemTypeSelfAd from "../../../../../selfAd/listItemTypeSelfAd/ListItemTypeSelfAd";
import { NotificationListItemType } from "../../../../../../query/notification/useGetNotificationListQuery";
import { getIsLogin } from "../../../../../../store/auth/authKeyStore";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../commonUiComponent/scrollbar/ScrollbarType";

export const NotiListBodyContainer = styled.div<{
    vh: number;
}>`
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    max-height: ${({ vh }) => 100 * vh - 328 + "px"};
    height: auto;
    padding-left: 10px;

    @media ${(props) => props.theme.tabletL} {
        margin-top: 0;
        padding-left: 10px;
        height: ${({ vh }) => 100 * vh - 150 + "px"};
        max-height: ${({ vh }) => 100 * vh - 150 + "px"};
    }
`;

export const NotiListNoDataContainer = styled.div`
    height: 392px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => props.theme.tabletL} {
        height: 100%;
        padding-top: 3px;
    }
`;

export const NotiListNoDataImg = styled.img`
    margin-top: 80px;
`;

export const NotiListNoDataTitle = styled.div`
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin-top: 24px;
`;

export const NotiListItemWithTypeSelfAdContainer = styled.div``;

export const NotiListItemTypeSelfAdContainer = styled.div`
    width: calc(100% - 10px);
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

type Props = {
    resolve: (value: number | boolean) => void;
    notificationList: NotificationListItemType[];
};

const NotiListBody = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const isLogin = getIsLogin();

    return (
        <NotiListBodyContainer vh={vh}>
            {props.notificationList.length !== 0 && (
                <Scrollbar type={SCROLL_BAR_NORMAL_TYPE} id={"noti-list-body"}>
                    <>
                        {props.notificationList.map(
                            (item: NotificationListItemType, idx: number) => {
                                if (
                                    props.notificationList.length >= 4 &&
                                    idx === 3
                                ) {
                                    return (
                                        <NotiListItemWithTypeSelfAdContainer
                                            key={idx}
                                        >
                                            <NotiListItemTypeSelfAdContainer>
                                                <ListItemTypeSelfAd
                                                    spaceId={"saybuzz_my_list"}
                                                    isEnabled={true}
                                                />
                                            </NotiListItemTypeSelfAdContainer>
                                            <NotiListItem
                                                data={item}
                                                isLogin={isLogin}
                                                resolve={props.resolve}
                                            />
                                        </NotiListItemWithTypeSelfAdContainer>
                                    );
                                } else {
                                    return (
                                        <NotiListItem
                                            key={idx}
                                            data={item}
                                            isLogin={isLogin}
                                            resolve={props.resolve}
                                        />
                                    );
                                }
                            },
                        )}
                        {props.notificationList.length < 4 && (
                            <NotiListItemTypeSelfAdContainer>
                                <ListItemTypeSelfAd
                                    spaceId={"saybuzz_my_list"}
                                    isEnabled={true}
                                />
                            </NotiListItemTypeSelfAdContainer>
                        )}
                    </>
                </Scrollbar>
            )}
            {props.notificationList.length === 0 && (
                <NotiListNoDataContainer>
                    <NotiListItemTypeSelfAdContainer>
                        <ListItemTypeSelfAd
                            spaceId={"saybuzz_my_list"}
                            isEnabled={true}
                        />
                    </NotiListItemTypeSelfAdContainer>
                    <NotiListNoDataImg
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-empty-notification.svg`}
                    />
                    <NotiListNoDataTitle>알림이 없습니다</NotiListNoDataTitle>
                </NotiListNoDataContainer>
            )}
        </NotiListBodyContainer>
    );
};

export default NotiListBody;
