import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageOfferListModalPreviewBtn } from "../AdSaleManageOfferListModalPreview";
import { useAcceptAdOfferMutation } from "../../../../../../../../query/ad/adBidOffer/useAcceptAdOfferMutation";
import { useOpenCommonConfirmModal } from "../../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { PathAfterResolveType } from "../../../../../../../../hooks/modal/useOpenModal";
import { format } from "date-fns";
import { useOpenAdSaleManageAcceptOfferSuccessModal } from "../../../../../../../../hooks/modal/openModal/useOpenAdSaleManageAcceptOfferSuccessModal";
import { MyAdOfferInfoType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoType";

export const AdSaleManageOfferListModalPreviewAcceptOfferBtnContainer = styled.div``;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    adIdx: string;
    selectedOfferInfo: MyAdOfferInfoType;
    refreshCache: () => Promise<void>;
    adSalesDate: Date;
};

const AdSaleManageOfferListModalPreviewAcceptOfferBtn = (
    props: Props,
): ReactElement | null => {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { mutateAsync: acceptOffer } = useAcceptAdOfferMutation(
        props.adIdx,
        props.selectedOfferInfo.offerInfo.offer_idx,
        {
            ad_sales_date: format(props.adSalesDate, "yyyy-MM-dd"),
        },
    );

    const { openAdSaleManageAcceptOfferSuccessModal } =
        useOpenAdSaleManageAcceptOfferSuccessModal(props.selectedOfferInfo);

    return props.selectedOfferInfo.offerInfo.offer_status === "0" ? (
        <AdSaleManageOfferListModalPreviewBtn
            isOnlyMobileShow={false}
            btnType={"filled-primary"}
            onClick={async () => {
                try {
                    const confirmResult = await openCommonConfirmModal({
                        title: "낙찰",
                        desc: "해당 입찰건을 낙찰하고 결제를 진행합니다.",
                        buttonTitle: "낙찰하기",
                    });

                    if (confirmResult) {
                        await acceptOffer();
                        await openAdSaleManageAcceptOfferSuccessModal();
                        props.resolve(false);
                        await props.refreshCache();
                    }
                } catch (e) {
                    console.info(e);
                }
            }}
        >
            낙찰하기
        </AdSaleManageOfferListModalPreviewBtn>
    ) : null;
};

export default AdSaleManageOfferListModalPreviewAcceptOfferBtn;
