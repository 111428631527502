import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useOnClickOutside } from "usehooks-ts";
import { SortingItemType } from "../../commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";

export const SortingButtonWrapper = styled.div`
    position: relative;
`;

export const SortingButtonContainer = styled.div`
    padding: 6px 10px 6px 6px;
    display: flex;

    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    border-radius: 8px;

    color: #999999;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f5f5f5;
            color: #999999;
        }
    }

    transition: all 300ms ease;
`;

export const SortingButtonIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 4px;
`;

export const SortingLayer = styled.div<{ isOpen: boolean }>`
    position: absolute;
    width: 180px;
    ${(props) => props.theme.zIndexFixedSingleUI};
    top: 45px;
    right: 0;
    overflow: hidden;
    background: white;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;

    transition: all 300ms ease;
    max-height: ${({ isOpen }) => (isOpen ? "400px" : 0)};
`;

export const SortingSortingList = styled.div`
    padding: 10px;
`;

export const SortingListItem = styled.div<{ isSelected: boolean }>`
    min-width: 80px;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    font-weight: ${({ isSelected }) => (isSelected ? "700" : "500")};
    font-size: 16px;
    line-height: 16px;
    padding: 14px 10px;
    /* identical to box height, or 100% */

    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#222222")};
    transition: background 300ms ease;
    cursor: pointer;

    &:hover {
        background: #f0f0f2;
        border-radius: 6px;
    }
`;

type Props = {
    sortingList: SortingItemType[];
    setSortingList: (value: SortingItemType[]) => void;
};

const SortingButton = (props: Props): ReactElement | null => {
    const sortingButtonRef = useRef(null);
    const [isSortingListOpen, setIsSortingListOpen] = useState(false);

    useOnClickOutside(sortingButtonRef, () => {
        setIsSortingListOpen(false);
    });

    return (
        <>
            <SortingButtonWrapper ref={sortingButtonRef}>
                <SortingButtonContainer
                    onClick={() => {
                        setIsSortingListOpen(!isSortingListOpen);
                    }}
                >
                    <SortingButtonIcon
                        src={URL_CONSTANTS.ASSET_URL + "/icon/icon-sorting.svg"}
                    />
                    {
                        props.sortingList.filter(
                            (sortingItem) => sortingItem.isSelected,
                        )[0].title
                    }
                </SortingButtonContainer>
                <SortingLayer isOpen={isSortingListOpen}>
                    <SortingSortingList>
                        {props.sortingList.map((sorting, idx) => {
                            return (
                                <SortingListItem
                                    key={idx}
                                    isSelected={sorting.isSelected}
                                    onClick={() => {
                                        const nowSortingItemIsSelected =
                                            props.sortingList[idx].isSelected;
                                        if (!nowSortingItemIsSelected) {
                                            props.setSortingList(
                                                props.sortingList.map(
                                                    (
                                                        sortingItem,
                                                        sortingItemIdx,
                                                    ) => {
                                                        if (
                                                            idx ===
                                                            sortingItemIdx
                                                        ) {
                                                            sortingItem.isSelected =
                                                                !nowSortingItemIsSelected;
                                                        } else {
                                                            sortingItem.isSelected =
                                                                nowSortingItemIsSelected;
                                                        }
                                                        return sortingItem;
                                                    },
                                                ),
                                            );
                                        }
                                        setIsSortingListOpen(false);
                                    }}
                                >
                                    {sorting.title}
                                </SortingListItem>
                            );
                        })}
                    </SortingSortingList>
                </SortingLayer>
            </SortingButtonWrapper>
        </>
    );
};

export default SortingButton;
