import React, { ReactElement, RefObject, useEffect } from "react";
import styled from "styled-components";
import WhiteBox from "../../../../common/commonUnitComponent/box/WhiteBox";
import {
    AdRegisterAdjustBodyContainer,
    AdRegisterAdjustHeaderTitle,
    AdRegisterAdjustHeaderTitleDesc,
} from "../../AdRegisterContents";
import { InputTextDataType } from "../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import { useGetUserProfileQuery } from "../../../../../query/user/useGetUserProfileQuery";
import userInfoAtom from "../../../../../store/userInfo/userInfoAtom";
import { useAtom } from "jotai";
import AdRegisterAdjustPriceInput from "./components/AdRegisterAdjustPriceInput";
import AdRegisterAdjustPriceInputDesc from "./components/AdRegisterAdjustPriceInputDesc";
import { removeComma } from "../../../../../utils/Utils";

export const AdRegisterAdjustPriceInputBoxContainer = styled.div``;

export const AdRegisterAdjustPriceInputBoxInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

type Props = {
    adPrice: InputTextDataType;
    setAdPrice: (value: InputTextDataType) => void;
    adPriceContainerRef: RefObject<HTMLDivElement>;

    adMinPrice: InputTextDataType;
    setAdMinPrice: (value: InputTextDataType) => void;
    adMinPriceContainerRef: RefObject<HTMLDivElement>;
    isAdjust: boolean;

    setIsAdDaySale: (value: boolean) => void;
};

const AdRegisterAdjustPriceInputBox = (props: Props): ReactElement | null => {
    const [userInfo] = useAtom(userInfoAtom);
    const { data: rawUserProfileData, isSuccess: isUserProfileDataSuccess } =
        useGetUserProfileQuery(userInfo.user_idx);

    const maxAdPrice = rawUserProfileData.userInfo.user_influencer_bool
        ? 10000000
        : 3000000;
    const minAdPrice = 1000;

    const adPriceNumber = removeComma(props.adPrice.value);
    const adMinPriceNumber = removeComma(props.adMinPrice.value);

    const exceptionalMaxAdMinPriceOverWarningMsg =
        "최소 입찰가는 즉시 구매가보다 높을 수 없습니다.";

    useEffect(() => {
        if (adMinPriceNumber > adPriceNumber) {
            props.setAdMinPrice({
                ...props.adMinPrice,
                warningMsg: exceptionalMaxAdMinPriceOverWarningMsg,
                isWarning: true,
            });
            return;
        } else {
            props.setAdMinPrice({
                ...props.adMinPrice,
                warningMsg: "",
                isWarning: false,
            });
        }
    }, [props.adPrice]);

    return (
        <WhiteBox>
            <AdRegisterAdjustPriceInputBoxContainer>
                <AdRegisterAdjustHeaderTitle>
                    입찰가 설정
                </AdRegisterAdjustHeaderTitle>
                <AdRegisterAdjustHeaderTitleDesc>
                    즉시 구매가는 입찰기간에 바로 구매하거나 즉시 구매할 때의
                    가격을 의미하며 최소 입찰가는 입찰이 가능한 최소 금액을
                    의미합니다.
                </AdRegisterAdjustHeaderTitleDesc>
                <AdRegisterAdjustBodyContainer>
                    <AdRegisterAdjustPriceInputBoxInputContainer>
                        <AdRegisterAdjustPriceInput
                            title={"즉시 구매가"}
                            postPositionalTitle={"는"}
                            inputPrice={props.adPrice}
                            setInputPrice={props.setAdPrice}
                            containerRef={props.adPriceContainerRef}
                            maxPrice={maxAdPrice}
                            minPrice={minAdPrice}
                            focusOutEventHandler={async () => {
                                if (adPriceNumber === 1000) {
                                    props.setIsAdDaySale(false);
                                }
                            }}
                        />

                        <AdRegisterAdjustPriceInput
                            title={"최소 입찰가"}
                            postPositionalTitle={"는"}
                            inputPrice={props.adMinPrice}
                            containerRef={props.adMinPriceContainerRef}
                            setInputPrice={props.setAdMinPrice}
                            maxPrice={removeComma(props.adPrice.value)}
                            minPrice={minAdPrice}
                        />
                    </AdRegisterAdjustPriceInputBoxInputContainer>

                    <AdRegisterAdjustPriceInputDesc />
                </AdRegisterAdjustBodyContainer>
            </AdRegisterAdjustPriceInputBoxContainer>
        </WhiteBox>
    );
};

export default AdRegisterAdjustPriceInputBox;
