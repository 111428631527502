import {useWindowSize} from "usehooks-ts";
import {useEffect, useState} from "react";

export const useDeviceWidth = () => {
    const { width } = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(false);
    const [isTabletWidth, setIsTabletWidth] = useState(false);

    useEffect(() => {
        setIsMobileWidth(width !== 0 && width < 720);
        setIsTabletWidth(width !== 0 && width > 720 && width < 1280);
    }, [width]);
    return { isMobileWidth, isTabletWidth };
};
