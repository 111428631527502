import { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useItemDataQuery } from "../../../../../../query/item/useItemDataQuery";
import { useProjectDataQuery } from "../../../../../../query/project/useProjectDataQuery";
import ApiResBoundary from "../../../../../common/apiResBoundary/ApiResBoundary";
import { onErrorImg } from "../../../../../../utils/Utils";
import Nickname from "../../../../../common/commonUnitComponent/nickname/Nickname";

export const MarketItemTopContentsContainer = styled(Link)`
    margin-top: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const MarketItemTopContentsBackIcon = styled.img`
    margin-left: 4px;
    height: 32px;
    margin-right: 14px;
`;

export const MarketItemTopContentsProjectThumbnail = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-right: 10px;
`;

export const MarketItemTopContentsProjectInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`;

export const MarketItemTopContentsProjectInfoProjectName = styled.div`
    color: #222;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 20px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 16px;
    }
`;

export const MarketItemTopContentsProjectInfoCreatorNickname = styled.div`
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 12px;
    }
`;

type Props = {};

const MarketItemTopContents = (props: Props): ReactElement | null => {
    const params = useParams();
    const projectIdx = params.project_id ? params.project_id : "0";
    const itemIdx = params.item_id ? params.item_id : "0";

    const {
        data: rawProjectData,
        error: projectDataError,
        isLoading: isProjectDataLoading,
    } = useProjectDataQuery(projectIdx);

    const {
        data: rawItemData,
        error: itemDataError,
        isLoading: isItemDataLoading,
    } = useItemDataQuery(itemIdx);

    return (
        <ApiResBoundary
            error={itemDataError || projectDataError}
            isLoading={isItemDataLoading || isProjectDataLoading}
            isNoData={false}
            isLoadingSpinnerShow={false}
        >
            <MarketItemTopContentsContainer
                to={`/market/${rawProjectData.project_idx}`}
            >
                <MarketItemTopContentsBackIcon
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-arrow-back.png`}
                />
                <MarketItemTopContentsProjectThumbnail
                    src={rawProjectData.project_thumbnail_full_path}
                    onError={onErrorImg}
                />

                <MarketItemTopContentsProjectInfoContainer>
                    <MarketItemTopContentsProjectInfoProjectName>
                        {rawProjectData.project_name}
                    </MarketItemTopContentsProjectInfoProjectName>
                    <MarketItemTopContentsProjectInfoCreatorNickname>
                        <Nickname
                            userIdx={rawProjectData.user_idx}
                            nickname={rawProjectData.user_nick}
                            isShowVerifiedIcon={true}
                            isVerified={rawProjectData.user_influencer_bool}
                        />
                    </MarketItemTopContentsProjectInfoCreatorNickname>
                </MarketItemTopContentsProjectInfoContainer>
            </MarketItemTopContentsContainer>
        </ApiResBoundary>
    );
};

export default MarketItemTopContents;
