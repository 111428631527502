import React, { ReactElement, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import styled from "styled-components";
import Scrollbar from "../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import {
    spreadAdTemplateListPages,
    useAdTemplateListQuery,
} from "../../../../../../../query/ad/template/useAdTemplateListQuery";
import AdBidingSelectTemplateModalHeader from "./components/AdBidingSelectTemplateModalHeader";
import ApiResBoundary from "../../../../../../common/apiResBoundary/ApiResBoundary";
import AdBidingSelectTemplateModalNoData from "./components/AdBidingSelectTemplateModalNoData";
import viewHeightAtom from "../../../../../../../store/scroll/viewHeightAtom";
import AdBidingSelectTemplateListItem from "./components/AdBidingSelectTemplateListItem";
import { TemplateListItemType } from "../../../../../../../types/ad/templateInfo/TemplateListItemType";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../../../common/commonUiComponent/scrollbar/ScrollbarType";

export const AdBidingSelectTemplateModalWrapper = styled.div`
    width: 592px;

    @media ${(props) => props.theme.mobileL} {
        padding: 16px 0;
        width: 100%;
    }
`;

export const AdBidingSelectTemplateModalBody = styled.div<{
    vh: number;
}>`
    margin-top: 32px;
    width: calc(100% + 16px);
    height: ${({ vh }) => "calc(" + 100 * vh + "px - 230px)"};
    max-height: 526px;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        height: 100%;
        overflow: scroll;
        margin-top: 24px;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;
    }
`;

export const AdBidingSelectTemplateModalTemplateList = styled.div`
    width: calc(100% - 16px);
`;

export type AdBidingTemplateSelectResolveType = {
    isOnlyClose: boolean;
    isAddTemplate: boolean;
    isAddTemplateHasGuide: boolean;
    isTemplateSelect: boolean;
};

type Props = {
    resolve: (value: AdBidingTemplateSelectResolveType) => void;
    campaignIdx: string;
    setSelectedTemplateIdx: (value: string) => void;
    setTemplateType?: (value: string) => void;
};

const AdBidingSelectTemplateModal = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const {
        data: rawTemplateList,
        fetchNextPage: nextFetchTemplateList,
        hasNextPage: _hasNextTemplateList,
        error: errorTemplateList,
        isSuccess: isTemplateListSuccess,
        isLoading: isTemplateListLoading,
    } = useAdTemplateListQuery({
        campaign_idx: props.campaignIdx,
        limit: 8,
        page_no: 1,
    });
    const templateList = spreadAdTemplateListPages(rawTemplateList.pages);
    const hasNextTemplateListRef = useRef(_hasNextTemplateList);

    useEffect(() => {
        hasNextTemplateListRef.current = _hasNextTemplateList;
    }, [_hasNextTemplateList]);

    return (
        <AdBidingSelectTemplateModalWrapper>
            <AdBidingSelectTemplateModalHeader
                resolve={props.resolve}
                isNoData={templateList.length === 0}
                campaignIdx={props.campaignIdx}
            />
            <ApiResBoundary
                isLoading={isTemplateListLoading}
                isNoData={templateList.length === 0}
                noDataFallback={
                    <AdBidingSelectTemplateModalNoData
                        campaignIdx={props.campaignIdx}
                        resolve={props.resolve}
                    />
                }
                error={errorTemplateList}
            >
                <AdBidingSelectTemplateModalBody vh={vh}>
                    <Scrollbar
                        id={"ad-biding-select-template-modal-body"}
                        type={SCROLL_BAR_NORMAL_TYPE}
                        infiniteScrollCallback={async (
                            showSpinner: () => void,
                        ) => {
                            if (hasNextTemplateListRef.current) {
                                showSpinner();
                                await nextFetchTemplateList();
                            }
                        }}
                    >
                        <AdBidingSelectTemplateModalTemplateList>
                            {templateList.map(
                                (
                                    templateListItem: TemplateListItemType,
                                    idx: number,
                                ) => (
                                    <AdBidingSelectTemplateListItem
                                        key={idx}
                                        templateListItem={templateListItem}
                                        resolve={props.resolve}
                                        setSelectedTemplateIdx={
                                            props.setSelectedTemplateIdx
                                        }
                                        setTemplateType={props.setTemplateType}
                                    />
                                ),
                            )}
                        </AdBidingSelectTemplateModalTemplateList>
                    </Scrollbar>
                </AdBidingSelectTemplateModalBody>
            </ApiResBoundary>
        </AdBidingSelectTemplateModalWrapper>
    );
};

export default AdBidingSelectTemplateModal;
