import { useEffect, useState } from "react";

export function useSetIsOverWindowWidthTarget(
    setIsOverFlag: (value: boolean) => void,
    targetWidth: number,
) {
    const [isSetDoneAfterMounted, setIsSetDoneAfterMounted] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsSetDoneAfterMounted(true);
            window.innerWidth;
            if (window.innerWidth > targetWidth) {
                setIsOverFlag(true);
            } else {
                setIsOverFlag(false);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        isSetDoneAfterMounted,
    };
}
