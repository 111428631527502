import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../common/commonStyle/CommonButtonStyle";

export const AdSaleManageOfferListModalNoListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    @media ${(props) => props.theme.mobileL} {
        padding-bottom: 0;
    }
`;

export const AdSaleManageOfferListModalNoListContainer = styled.div`
    height: 324px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const AdSaleManageOfferListModalNoListIcon = styled.img`
    width: 76px;
    height: 84px;
`;

export const AdSaleManageOfferListModalNoListMsg = styled.div`
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    margin-top: 24px;
`;

export const AdSaleManageOfferListModalNoListButtonContainer = styled.div`
    width: 100%;

    @media ${(props) => props.theme.mobileL} {
    }
`;

export const AdSaleManageOfferListModalNoListButton = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    height: 48px;
`;

type Props = {
    resolve: (value: boolean) => void;
};

const AdSaleManageOfferListModalNoList = (
    props: Props,
): ReactElement | null => {
    return (
        <AdSaleManageOfferListModalNoListWrapper>
            <AdSaleManageOfferListModalNoListContainer>
                <AdSaleManageOfferListModalNoListIcon
                    src={URL_CONSTANTS.ASSET_URL + "/icon/icon-empty-item.svg"}
                />
                <AdSaleManageOfferListModalNoListMsg>
                    입찰 이력이 없습니다
                </AdSaleManageOfferListModalNoListMsg>
            </AdSaleManageOfferListModalNoListContainer>
            <AdSaleManageOfferListModalNoListButtonContainer>
                <AdSaleManageOfferListModalNoListButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </AdSaleManageOfferListModalNoListButton>
            </AdSaleManageOfferListModalNoListButtonContainer>
        </AdSaleManageOfferListModalNoListWrapper>
    );
};

export default AdSaleManageOfferListModalNoList;
