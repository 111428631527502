import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import Nickname from "../nickname/Nickname";
import { format } from "date-fns";
import {
    CommentListItemCommentInfoType,
    CommentListItemUserInfoType,
} from "../../../../query/comment/useCommentListQuery";
import { useParams } from "react-router-dom";
import CssFilterConverter from "css-filter-converter";
import { useCommentAdjustMutation } from "../../../../query/comment/useCommentAdjustMutation";
import { getIsLogin } from "../../../../store/auth/authKeyStore";
import ThreeDotButton, {
    ThreeDotConfigType,
} from "../threeDotButton/ThreeDotButton";
import { useIsMe } from "../../../../hooks/common/useIsMe";
import { useCommentDeleteMutation } from "../../../../query/comment/useCommentDeleteMutation";
import { useCommentBlameMutation } from "../../../../query/comment/useCommentBlameMutation";
import {
    getPageType,
    shortenNumberToStringWithComma,
} from "../../../../utils/Utils";
import { useOpenLoginConfirmModal } from "../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { useLikeUnLike } from "../../../../hooks/common/useLikeUnLike";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../commonStyle/CommonButtonStyle";
import { useOpenCommonConfirmModal } from "../../../../hooks/modal/openModal/useOpenCommonConfirmModal";

export const CommentListItemContainer = styled.div`
    margin-bottom: 24px;
    width: 100%;
`;

export const CommentHeader = styled.div`
    display: flex;
    height: 32px;
    justify-content: space-between;
`;

export const CommentHeaderLeftContainer = styled.div`
    display: flex;
`;

export const CommentHeaderThumbnail = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin-right: 8px;
`;

export const CommentHeaderInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CommentHeaderInfoNickname = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    color: #777777;
`;

export const CommentHeaderInfoDate = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #999999;

    margin-top: 6px;
`;

export const CommentHeaderRightContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const CommentHeaderLikeButton = styled.div<{ isLike: boolean }>`
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    padding: 9.5px 6px;
    flex-shrink: 0;

    color: ${({ isLike }) => (isLike ? "#FA54FA" : "#b5b5b5")};
    border-radius: 4px;
    transition: background 300ms ease;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f5f5f5;
        }
    }
`;

export const CommentHeaderLikeButtonIcon = styled.img`
    margin-right: 4px;
`;

export const CommentBody = styled.div<{ commentDisplayRow: number }>`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #333333;

    margin-top: 8px;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${({ commentDisplayRow }) => commentDisplayRow};
    -webkit-box-orient: vertical;
    word-break: break-all; // 문단으로 끊어져서 줄바꿈 됨
`;

export const CommentAdjustTextarea = styled.textarea`
    margin-top: 8px;
    color: #333;
    font-size: 14px;
    font-family: Noto Sans KR, serif;
    line-height: 22px;
    display: flex;
    width: calc(100% - 16px);
    height: 49px;
    padding: 8px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    background: #f5f5f7;
    border: none;
    resize: none;
    outline: none;
`;

export const CommentAdjustButton = styled.div<CommonButtonType>`
    font-size: 14px;
    line-height: 100%;

    padding: 9px 10px;
    ${CommonButtonStyle};
    height: auto;
`;

export const BlamedCommentListItemContainer = styled.div`
    margin-bottom: 24px;
    width: calc(100% - 2px);

    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #ebebeb;
    color: #777;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
`;
type Props = {
    commentDisplayRow: number;
    commentInfo: CommentListItemCommentInfoType;
    commentUserInfo: CommentListItemUserInfoType;
    reFetchCommentListData: (value?: any) => any;
};

const CommentListItem = ({
    commentInfo,
    commentDisplayRow,
    commentUserInfo,
    reFetchCommentListData,
}: Props): ReactElement | null => {
    // project 타입의 단일 comment 는 item으로 변경
    const pageType =
        getPageType(location.pathname) === "project"
            ? "item"
            : getPageType(location.pathname);
    const params = useParams();
    const [isAdjust, setIsAdjust] = useState(false);
    const [adjustCommentInput, setAdjustCommentInput] = useState("");
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { mutateAsync: adjustComment } = useCommentAdjustMutation(
        pageType,
        commentInfo.comment_idx,
        {
            comment: adjustCommentInput,
        },
    );

    const { likeUnLike } = useLikeUnLike(
        pageType + "/comment",
        commentInfo.comment_idx,
        commentInfo.like_bool,
    );

    const isMe = useIsMe();

    const { mutateAsync: deleteComment } = useCommentDeleteMutation(
        pageType,
        commentInfo.comment_idx,
    );

    const { mutateAsync: blameComment } = useCommentBlameMutation(
        pageType,
        commentInfo.comment_idx,
    );

    const [moreThreeDotConfig, setMoreThreeDotConfig] = useState<
        ThreeDotConfigType[]
    >([]);

    useEffect(() => {
        setMoreThreeDotConfig(
            isMe(commentUserInfo.user_idx)
                ? [
                      {
                          title: "수정하기",
                          clickHandler: () => {
                              setIsAdjust(true);
                          },
                          isGoOutIcon: false,
                      },
                      {
                          title: "삭제하기",
                          clickHandler: async () => {
                              if (
                                  await openCommonConfirmModal({
                                      title: "댓글 삭제",
                                      desc: "댓글을 삭제합니다. 삭제한 댓글은 복구 할 수 없습니다.",
                                      buttonTitle: "삭제하기",
                                  })
                              ) {
                                  await deleteComment();
                                  await reFetchCommentListData();
                              }
                          },
                          isGoOutIcon: false,
                      },
                  ]
                : [
                      {
                          title: "신고하기",
                          clickHandler: async () => {
                              if (!isMe(commentUserInfo.user_idx)) {
                                  if (getIsLogin()) {
                                      const confirmResult =
                                          await openCommonConfirmModal({
                                              title: "댓글 신고",
                                              desc: "댓글을 신고합니다. 신고된 댓글은 신고 취소 할 수 없습니다.",
                                          });

                                      if (confirmResult) {
                                          await blameComment();
                                          await reFetchCommentListData();
                                      }
                                  } else {
                                      await openLoginConfirmModal();
                                  }
                              } else {
                                  alert("본인 댓글은 신고하실 수 없습니다.");
                              }
                          },
                          isGoOutIcon: false,
                      },
                  ],
        );
    }, [commentUserInfo]);

    useEffect(() => {
        setAdjustCommentInput(commentInfo.comment);
    }, [isAdjust]);
    return !commentInfo.blame_bool ? (
        <CommentListItemContainer>
            <CommentHeader>
                <CommentHeaderLeftContainer>
                    <CommentHeaderThumbnail
                        src={commentUserInfo.user_thumbnail_path}
                    />
                    <CommentHeaderInfoContainer>
                        <Nickname
                            userIdx={commentUserInfo.user_idx}
                            nickname={commentUserInfo.user_nick}
                            isVerified={commentUserInfo.user_influencer_bool}
                            nicknameStyledComponent={CommentHeaderInfoNickname}
                            isShowVerifiedIcon={true}
                        />

                        <CommentHeaderInfoDate>
                            {commentInfo.created_at &&
                                format(
                                    new Date(commentInfo.created_at).getTime(),
                                    "yyyy.MM.dd HH:mm:ss",
                                )}
                        </CommentHeaderInfoDate>
                    </CommentHeaderInfoContainer>
                </CommentHeaderLeftContainer>
                <CommentHeaderRightContainer>
                    {!isAdjust && (
                        <>
                            <CommentHeaderLikeButton
                                onClick={async () => {
                                    await likeUnLike();
                                    await reFetchCommentListData();
                                }}
                                isLike={commentInfo.like_bool}
                            >
                                <CommentHeaderLikeButtonIcon
                                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-like.svg`}
                                    style={{
                                        // 가변 filter 는 inline 으로 해야 워닝 안나옴
                                        filter: commentInfo.like_bool
                                            ? CssFilterConverter.hexToFilter(
                                                  "#FA54FA",
                                              ).color + ""
                                            : "",
                                    }}
                                />
                                {shortenNumberToStringWithComma(
                                    commentInfo.like_cnt,
                                )}
                            </CommentHeaderLikeButton>
                            <ThreeDotButton
                                config={moreThreeDotConfig}
                                size={"32px"}
                                hasBorder={false}
                            />
                        </>
                    )}
                    {isAdjust && (
                        <>
                            <CommentAdjustButton
                                btnType={"text-secondary"}
                                onClick={() => {
                                    setIsAdjust(false);
                                }}
                            >
                                취소
                            </CommentAdjustButton>
                            <CommentAdjustButton
                                btnType={"text-primary"}
                                onClick={async () => {
                                    await adjustComment();
                                    await reFetchCommentListData();
                                    setIsAdjust(false);
                                }}
                            >
                                수정
                            </CommentAdjustButton>
                        </>
                    )}
                </CommentHeaderRightContainer>
            </CommentHeader>
            {!isAdjust && (
                <CommentBody commentDisplayRow={commentDisplayRow}>
                    {commentInfo.comment}
                </CommentBody>
            )}
            {isAdjust && (
                <CommentAdjustTextarea
                    value={adjustCommentInput}
                    onChange={(e) => {
                        setAdjustCommentInput(e.target.value);
                    }}
                />
            )}
        </CommentListItemContainer>
    ) : (
        <BlamedCommentListItemContainer>
            댓글 신고됨
        </BlamedCommentListItemContainer>
    );
};

export default CommentListItem;

CommentListItem.defaultProps = {
    commentDisplayRow: 4,
};
