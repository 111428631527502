import { useInfiniteQuery } from "react-query";
import { ItemOwnerApi } from "../../../apis/ItemOwnerApi";

export type ItemOwnerListApiResultType = {
    ownerList: ItemOwnerListType[];
    totalCount: number;
    user_nick: string;
    nextPageNumber?: number;
};

export type ItemOwnerListType = {
    user_thumbnail_path: string;
    user_idx: string;
    user_id: string;
    user_nick: string;
    follow_cnt: number;
    follow_bool: boolean;
    own_cnt: number;
    user_influencer_bool: boolean;
};

export type ItemOwnerListApiParamsType = {
    limit: number;
    keyword: string;
    page_no: number;
};

export const useItemOwnerListQuery = (
    itemIdx: string,
    params: ItemOwnerListApiParamsType,
    isAutoFetch: boolean,
) => {
    const queryResult = useInfiniteQuery<ItemOwnerListApiResultType, Error>({
        queryKey: ["item-owner-list-" + JSON.stringify(params)],
        queryFn: ({ pageParam = 1 }) =>
            ItemOwnerApi.getItemOwnerList(itemIdx, {
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        enabled: isAutoFetch,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: ItemOwnerListApiResultType[] = [
        {
            ownerList: [],
            user_nick: "",
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};
