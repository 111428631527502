import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { Query, useMutation } from "react-query";
import { AdApi } from "../../../apis/adApi/AdApi";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { queryClient } from "../../../App";
import { useOpenCommonAlertModal } from "../../../hooks/modal/openModal/useOpenCommonAlertModal";

export type AdRegisterParams = {
    campaign_idx: string;
    ad_reserve_price: number;
    ad_minimum_bid_price: number;
    ad_auto_offer_accept_bool: boolean;
    buy_now_available_bool: boolean;
};

export const useAdRegisterMutation = (params: AdRegisterParams) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const { openCommonAlertModal } = useOpenCommonAlertModal();

    return useMutation<any, Error>(() => AdApi.registerAd(params), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
        onSuccess: async (data: string) => {
            const today = new Date();

            await openCommonAlertModal({
                hasCloseBtn: false,
                title: "판매 등록 완료",
                desc: `판매등록이 완료되었습니다.`,
            });

            await queryClient.resetQueries({
                predicate: (query: Query) =>
                    query.queryKey.includes("ad-campaign-list"),
            });
        },
    });
};
