import { ReactElement } from "react";
import styled from "styled-components";
import CommentCollection from "../../../../../../common/commonUiComponent/commentCollection/CommentCollection";
import WalletCollectionHubHistory from "../../../WalletCollectionHistory/WalletCollectionHubHistory";
import {
    COLLECTION_COMMUNITY_TAB,
    COLLECTION_CREATED_TAB,
    COLLECTION_HISTORY_TAB,
    COLLECTION_LIKED_TAB,
    COLLECTION_OWNED_TAB,
    COLLECTION_PRIVATE_TAB,
} from "../../WalletCollectionHub";
import { DateRange } from "react-day-picker";
import {
    ChipType,
    SortingItemType,
} from "../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { FilterType } from "../../../../../../common/commonUiComponent/searchFilter/SearchFilter";
import WalletCollectionHubItemList from "./components/walletCollectionHubItemList/WalletCollectionHubItemList";

export const WalletCollectionHubBodyWrapper = styled.div``;

export const WalletCollectionHubBodyContainer = styled.div`
    margin-top: 20px;
    overflow: visible;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px;
    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 1;

    @media ${(props) => props.theme.tabletM} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        justify-items: space-between;
    }

    @media ${(props) => props.theme.mobileL} {
        grid-gap: 12px;
    }

    @media ${(props) => props.theme.mobileM} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`;

export const WalletCollectionHubMoreButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 36px;
`;

export const WalletCollectionHubMoreButtonSize = styled.div`
    width: 160px;
`;

export const WalletCollectionHubSectionContainer = styled.div<{
    isShow: boolean;
}>`
    width: inherit;
    height: inherit;
    display: ${({ isShow }) => (isShow ? "inherit" : "none")};
    flex-wrap: wrap;
    gap: 24px;
`;

type Props = {
    userIdx: string;
    selectedSubTabKey: string;
    selectedDateRange: DateRange;
    selectedViewType: string;
    sortingList: SortingItemType[];
    filters: FilterType[];
    searchInput: string;
    groupChips: ChipType[];
    isOnlyShowNotGrouping: boolean;
};

const WalletCollectionHubBody = (props: Props): ReactElement | null => {
    return (
        <WalletCollectionHubBodyWrapper>
            {/*보유 아이템 / 관심 아이템 / 제작 아이템*/}

            {(props.selectedSubTabKey === COLLECTION_CREATED_TAB ||
                props.selectedSubTabKey === COLLECTION_LIKED_TAB ||
                props.selectedSubTabKey === COLLECTION_PRIVATE_TAB ||
                // 보유 아이템일 경우, 아이템 리스트 > sortingList > 그룹에 영향을 받아서 api call 최소화를 위해 렌더를 막음
                (props.selectedSubTabKey === COLLECTION_OWNED_TAB &&
                    props.sortingList.length !== 0)) && (
                <WalletCollectionHubItemList {...props} />
            )}

            {/*커뮤니티 모음*/}
            {props.selectedSubTabKey === COLLECTION_COMMUNITY_TAB && (
                <WalletCollectionHubSectionContainer isShow={true}>
                    <CommentCollection type={"user"} userIdx={props.userIdx} />
                </WalletCollectionHubSectionContainer>
            )}

            {/*입출고 내역*/}
            {props.selectedSubTabKey === COLLECTION_HISTORY_TAB && (
                <WalletCollectionHubSectionContainer isShow={true}>
                    <WalletCollectionHubHistory
                        userIdx={props.userIdx}
                        selectedDateRange={props.selectedDateRange}
                    />
                </WalletCollectionHubSectionContainer>
            )}
        </WalletCollectionHubBodyWrapper>
    );
};

export default WalletCollectionHubBody;
