import { ReactElement } from "react";
import { DateRange } from "react-day-picker";
import styled from "styled-components";
import { TableHeaderDataType } from "../../../../../common/commonUnitComponent/commonTable/CommonTable";
import AdManageCommonMobileTableLayout from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonMobileTableLayout";
import AdManageCommonMobileTableCell from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonMobileTableCell";
import {
    AdManageCommonMobileTableActionBtn,
    AdManageCommonMobileTableActionBtnContainer,
    AdManageCommonMobileTableContainer,
} from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";
import AdManageCommonHistoryColumnSellName from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnSellName";
import AdManageCommonHistoryColumnDate from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnDate";
import AdManageCommonHistoryColumnNormal from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnNormal";
import AdManageCommonHistoryColumnBrand from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBrand";
import AdManageCommonHistoryColumnPrice from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPrice";
import { convertPaymentAdjustStatus } from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPaymentAdjustColumn";
import AdManageCommonHistoryColumnBidder from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBidder";
import { useOpenAdSaleManageOfferListModal } from "../../../../../../hooks/modal/openModal/useOpenAdSaleManageOfferListModal";
import {
    MyAdBuyRoundStatusInfoParams,
    MyAdSellPaymentAdjustStatusInfoParams,
    useOpenCommonStatusInfoModal,
} from "../../../../../../hooks/modal/openModal/useOpenCommonStatusInfoModal";
import { MyAdDailyListItemType } from "../../../../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";
import {
    getIsTodaySale,
    staticConvertAdStatusKR,
} from "../../../../../../hooks/ad/staticConvertAdStatusKR";
import { convertSafariAdSalesDate } from "../../../../../../utils/Utils";

export const AdSaleManageAdListMobileTableContainer = styled.div``;

export const AdSaleManageAdListMobileTableRowTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

type Props = {
    selectedDateRange: DateRange;
    myAdDailyList: MyAdDailyListItemType[];
};

const AdSaleManageAdListMobileTable = (props: Props): ReactElement | null => {
    const { openCommonStatusInfoModal } = useOpenCommonStatusInfoModal(
        "mobile-my-ad-status-info",
    );

    const { openAdSaleManageOfferListModal } =
        useOpenAdSaleManageOfferListModal();

    const TABLE_KEY = "ad_sale_manage_ad_list_table_";
    const convertMobileRowValue = (
        rawRowKey: TableHeaderDataType,
        rawRowValue: MyAdDailyListItemType,
    ) => {
        switch (rawRowKey.key) {
            // 판매 목록 - mobileTable : 광고 게재일
            case TABLE_KEY + "ad_started_at":
                return (
                    <AdManageCommonHistoryColumnDate
                        date={
                            new Date(
                                convertSafariAdSalesDate(
                                    rawRowValue.ad_sales_date,
                                ),
                            )
                        }
                        format={"yyyy.MM.dd"}
                    />
                );

            // 판매 목록 - mobileTable : 정산 상태
            case TABLE_KEY + "ad_payment_adjust_state":
                return (
                    <AdManageCommonHistoryColumnNormal
                        msg={convertPaymentAdjustStatus(
                            rawRowValue.payment_adjust_status
                                ? rawRowValue.payment_adjust_status
                                : "",
                        )}
                    />
                );

            // 판매 목록 - mobileTable : 광고 상태
            case TABLE_KEY + "ad_status":
                return (
                    <AdManageCommonHistoryColumnNormal
                        msg={staticConvertAdStatusKR(
                            rawRowValue.ad_daily_status_code,
                            getIsTodaySale(
                                rawRowValue.adSaleInfo.ad_sales_daily_status,
                            ),
                            rawRowValue.ad_stop_switch_bool,
                        )}
                    />
                );

            // 판매 목록 - mobileTable : 브랜드
            case TABLE_KEY + "brand":
                return (
                    <AdManageCommonHistoryColumnBrand
                        brandId={rawRowValue.brand_id}
                        brandName={rawRowValue.brand_name}
                    />
                );

            // 판매 목록 - mobileTable : 희망가
            case TABLE_KEY + "ad_reserve_price":
                return (
                    <AdManageCommonHistoryColumnPrice
                        offerPrice={rawRowValue.adSaleInfo.ad_reserve_price}
                    />
                );

            // 판매 목록 - mobileTable : 낙찰가
            case TABLE_KEY + "offer_success_price":
                return (
                    <AdManageCommonHistoryColumnPrice
                        offerPrice={
                            rawRowValue.adSaleInfo.ad_reserve_price || "-"
                        }
                    />
                );

            // 판매 목록 - mobileTable : 낙찰자
            case TABLE_KEY + "bidder":
                return rawRowValue.offerSuccessInfo ? (
                    <AdManageCommonHistoryColumnBidder
                        userIdx={
                            rawRowValue.offerSuccessInfo.offer_success_user_idx
                        }
                        nickname={
                            rawRowValue.offerSuccessInfo.offer_success_user_nick
                        }
                        isVerified={
                            rawRowValue.offerSuccessInfo
                                .offer_success_user_influencer_bool
                        }
                    />
                ) : (
                    <AdManageCommonHistoryColumnBidder
                        userIdx={"0"}
                        nickname={"-"}
                        isVerified={false}
                    />
                );

            default:
                return <span>{rawRowKey.key} is not found</span>;
        }
    };

    const mobileHeaderData: TableHeaderDataType[] = [
        {
            key: TABLE_KEY + "ad_started_at",
            component: "광고 게재일",
        },
        {
            key: TABLE_KEY + "ad_payment_adjust_state",
            component: "정산 상태",
            questionMarkHandler: async () => {
                await openCommonStatusInfoModal(
                    MyAdSellPaymentAdjustStatusInfoParams,
                );
            },
        },
        {
            key: TABLE_KEY + "ad_status",
            component: "광고 상태",
            questionMarkHandler: async () => {
                await openCommonStatusInfoModal(MyAdBuyRoundStatusInfoParams);
            },
        },
        {
            key: TABLE_KEY + "brand",
            component: "브랜드",
        },
        {
            key: TABLE_KEY + "ad_reserve_price",
            component: "희망가(KRW)",
        },
        {
            key: TABLE_KEY + "offer_success_price",
            component: "낙찰가(KRW)",
        },
        {
            key: TABLE_KEY + "bidder",
            component: "낙찰자",
        },
    ];

    return (
        <AdManageCommonMobileTableLayout
            isNoData={props.myAdDailyList.length === 0}
        >
            <AdManageCommonMobileTableContainer>
                {props.myAdDailyList.map(
                    (
                        adDailyInfo: MyAdDailyListItemType,
                        adDailyInfoIdx: number,
                    ) => {
                        return (
                            <AdManageCommonMobileTableCell
                                key={adDailyInfoIdx}
                                mobileHeaderDataList={mobileHeaderData}
                                rawRowValue={adDailyInfo}
                                titleComponent={
                                    <AdSaleManageAdListMobileTableRowTitleContainer>
                                        <AdManageCommonHistoryColumnSellName
                                            name={adDailyInfo.campaign_name}
                                        />
                                    </AdSaleManageAdListMobileTableRowTitleContainer>
                                }
                                convertRowValue={convertMobileRowValue}
                                buttonComponent={
                                    <AdManageCommonMobileTableActionBtnContainer>
                                        <AdManageCommonMobileTableActionBtn
                                            onClick={async () => {
                                                return await openAdSaleManageOfferListModal(
                                                    adDailyInfo.ad_idx,
                                                    new Date(
                                                        convertSafariAdSalesDate(
                                                            adDailyInfo.ad_sales_date,
                                                        ),
                                                    ),
                                                    adDailyInfo.ad_daily_status_code,
                                                    typeof adDailyInfo !==
                                                        "undefined",
                                                );
                                            }}
                                        >
                                            입찰 이력
                                        </AdManageCommonMobileTableActionBtn>
                                    </AdManageCommonMobileTableActionBtnContainer>
                                }
                            />
                        );
                    },
                )}
            </AdManageCommonMobileTableContainer>
        </AdManageCommonMobileTableLayout>
    );
};

export default AdSaleManageAdListMobileTable;
