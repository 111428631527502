import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useWindowSize } from "usehooks-ts";

export const TooltipContainer = styled.div<{
    isHover: boolean;
    position: string;
    tooltipCoordinate: number;
    padding?: string;
}>`
    position: absolute;
    align-items: center;
    padding: ${({ padding }) => (padding ? padding : "15px 20px")};
    background: #282828;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: auto;
    z-index: 1;
    color: #ffffff;
    white-space: nowrap;

    ${({ position, tooltipCoordinate }) => {
        switch (position) {
            case "top":
                return `
                    top: 0;
                    left: calc(50% ${
                        tooltipCoordinate > 0
                            ? "+ " + tooltipCoordinate
                            : "- " + -tooltipCoordinate
                    }px);
                    transform: translate(-50%, calc(-100% - 10px));                
              `;

            case "bottom":
                return `
                    bottom: 0;
                    left: calc(50% ${
                        tooltipCoordinate > 0
                            ? "+ " + tooltipCoordinate
                            : "- " + -tooltipCoordinate
                    }px);
                    transform: translate(-50%, calc(100% + 10px));                
              `;
        }
    }}
    // 화살표 위치
  ::after {
        ${({ position, tooltipCoordinate }) => {
            switch (position) {
                case "top":
                    return `
                        border-top: 8px solid #282828;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 0 solid transparent;
                        content: "";
                        position: absolute;
                        top: calc(100% - 1px);
                        left: calc(50% - ${
                            tooltipCoordinate + 8
                        }px);                
              `;

                case "bottom":
                    return `
                        border-bottom: 8px solid #282828;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 0 solid transparent;
                        content: "";
                        position: absolute;
                        bottom: calc(100% - 1px);
                        left: calc(50% - ${tooltipCoordinate + 8}px); 
              `;
            }
        }}
    }

    // display: flex;
    display: ${({ isHover }) => (isHover ? "flex" : "none")};
    opacity: ${({ isHover }) => (isHover ? 1 : 0)};
`;

type Props = {
    isHover: boolean;
    children: ReactElement | null | string;
    position?: string;
    padding?: string;
};

const Tooltip = (props: Props): ReactElement | null => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [tooltipCoordinate, setTooltipCoordinate] = useState(0);
    const { width } = useWindowSize();

    useEffect(() => {
        if (tooltipRef.current) {
            const value =
                window.innerWidth -
                tooltipRef.current.getBoundingClientRect().left -
                tooltipRef.current.clientWidth;
            if (tooltipRef.current.getBoundingClientRect().left >= 0) {
                if (value < 0) {
                    setTooltipCoordinate(value - 16);
                } else if (
                    tooltipRef.current.getBoundingClientRect().left < 0
                ) {
                    setTooltipCoordinate(
                        tooltipRef.current.getBoundingClientRect().left - 16,
                    );
                } else {
                    setTooltipCoordinate(0);
                }
            } else {
                setTooltipCoordinate(
                    -tooltipRef.current.getBoundingClientRect().left + 16,
                );
            }
        }
    }, [width, props.isHover]);

    return (
        <TooltipContainer
            ref={tooltipRef}
            isHover={props.isHover}
            tooltipCoordinate={tooltipCoordinate}
            position={props.position ? props.position : "top"}
            padding={props.padding}
        >
            {props.children}
        </TooltipContainer>
    );
};

export default Tooltip;

Tooltip.defaultProps = {
    position: "top",
};
