import { ReactElement, useState } from "react";
import styled from "styled-components";
import AdManageTableContentsLayout, {
    AD_BUY_ANALYSIS,
} from "../../_adManageLayout/AdManageTableContentsLayout";
import { DateRange } from "react-day-picker";
import { addDays } from "date-fns";
import AdManageCommonAnalysisTable from "../../commonComponents/adManageCommonAnalysisTable/AdManageCommonAnalysisTable";

export const AdBuyManageHistoryAnalysisTableContentsContainer = styled.div``;

type Props = {};

const AdBuyManageHistoryAnalysisTableContents = (
    props: Props,
): ReactElement | null => {
    const today = new Date();
    const defaultSelected: DateRange = {
        from: addDays(today, -15),
        to: addDays(today, +15),
    };
    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRange>(defaultSelected);

    return (
        <AdManageTableContentsLayout
            type={"buy"}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
        >
            <AdManageCommonAnalysisTable
                type={AD_BUY_ANALYSIS}
                selectedDateRange={selectedDateRange}
            />
        </AdManageTableContentsLayout>
    );
};

export default AdBuyManageHistoryAnalysisTableContents;
