import { useQuery } from "react-query";
import { ProjectApi } from "../../apis/ProjectApi";

export type CategoryType = {
    title: string;
    value: string;
};

export interface ProjectDetailDataType {
    project_idx: string;
    brand_idx: string;
    brand_id: string;
    brand_name: string;
    user_idx: string;
    user_id: string;
    user_nick: string;
    project_name: string;
    project_network: string;
    project_erc: string;
    project_thumbnail_full_path: string;
    project_banner_full_path: string;
    project_description: string;
    project_currency: string;
    project_currency_local: string;
    project_status: string; //프로젝트 상태 (0: 비노출, 1: 노출)
    like_cnt: number;
    brand_royalty: number;
    creator_royalty: number;
    item_cnt: number;
    project_category: CategoryType;
    item_owner_cnt: number;
    item_volume: number;
    project_title_url: string;
    user_influencer_bool: boolean;
    transaction_bool: boolean;
    token_name: string;
    token_symbol: string;
    release_bool: boolean;

    [key: string]: any;
}

const DefaultProjectDetailData: ProjectDetailDataType = {
    project_idx: "0",
    brand_idx: "0",
    brand_id: "",
    brand_name: "",
    user_idx: "0",
    user_id: "",
    user_nick: "",
    project_name: "",
    project_network: "NULL",
    project_erc: "",
    project_thumbnail_full_path: "",
    project_banner_full_path: "",
    project_description: "",
    project_currency: "",
    project_currency_local: "",
    project_status: "0", //프로젝트 상태 (0: 비노출, 1: 노출)
    like_cnt: 0,
    brand_royalty: 0,
    creator_royalty: 0,
    item_cnt: 0,
    project_category: {
        title: "",
        value: "",
    },
    item_owner_cnt: 0,
    item_volume: 0,
    project_title_url: "",
    user_influencer_bool: false,
    transaction_bool: false,
    token_name: "",
    token_symbol: "",
    release_bool: true,
};

export const useProjectDataQuery = (project_idx: string) => {
    const queryResult = useQuery<ProjectDetailDataType, Error>({
        queryKey: ["project-data-" + project_idx, "like-refetch-list-item"],
        queryFn: () => ProjectApi.getDetailData(project_idx),
        refetchOnWindowFocus: false,
        suspense: false,
        onError: () => {
            return DefaultProjectDetailData;
        },
    });

    return {
        ...queryResult,
        data: queryResult.data || DefaultProjectDetailData,
    };
};
