import { ReactElement } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../common/commonStyle/CommonButtonStyle";
import { staticConvertAdOfferStatus } from "../../../../../../hooks/ad/staticConvertAdOfferStatus";

export const AdManageCommonHistoryColumnOfferStatusContainer = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    width: 108px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    padding: 10px 0;
    height: auto;

    color: #333;

    @media ${(props) => props.theme.tabletM} {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 14px */
        padding: 7px 8px;
    }
`;

type Props = {
    offerStatus: string;
    offerInvalidStatus: string;
    onClickHandler?: () => void;
};

const AdManageCommonHistoryColumnOfferStatus = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnOfferStatusContainer
            btnType={"outlined-secondary"}
            onClick={() => {
                if (props.onClickHandler) {
                    props.onClickHandler();
                }
            }}
        >
            {
                staticConvertAdOfferStatus(
                    props.offerStatus,
                    props.offerInvalidStatus,
                ).offerStatusKR
            }
        </AdManageCommonHistoryColumnOfferStatusContainer>
    );
};

export default AdManageCommonHistoryColumnOfferStatus;
