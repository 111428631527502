import { useOpenModal } from "../useOpenModal";
import React from "react";
import WalletCollectionGroupAddModal from "../../../components/wallet/walletCollection/components/walletCollectionHub/components/walletCollectionGroupModal/WalletCollectionGroupAddModal";

export function useOpenCollectionGroupAddModal() {
    const { openModal } = useOpenModal();

    return {
        openCollectionGroupAddModal: async (): Promise<boolean> => {
            return await openModal(
                "wallet-collection-group-add-modal",
                "modal",
                (resolve) => {
                    return <WalletCollectionGroupAddModal resolve={resolve} />;
                },
            );
        },
    };
}
