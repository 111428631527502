import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { numberToStringWithComma } from "../../../../../../../../../../utils/Utils";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellBackgroundRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import CalendarCellRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import {
    AdSaleManageScheduleCalendarCellBodyBlankContainer,
    HiddenKeyManage,
} from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarCellPcBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarCellPcBodyBidExpired = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const { targetDateInfo: cellInfo, isTodaySale } =
        useGetAdMyDailyDateData(props);
    const opacity = 0.3;

    return isTodaySale ? (
        cellInfo.offerSuccessInfo ? (
            <>
                <CalendarCellBackgroundRow
                    msg={"게재 완료"}
                    type={"normal"}
                    opacity={opacity}
                />
                <CalendarCellRow
                    opacity={opacity}
                    msg={`${numberToStringWithComma(
                        cellInfo.offerSuccessInfo.offer_price,
                    )} KRW`}
                    type={"secondary"}
                />
                <CalendarCellRow
                    opacity={opacity}
                    msg={cellInfo.offerSuccessInfo.offer_success_user_nick}
                    type={"secondary"}
                    hasNoMt={true}
                />
                <HiddenKeyManage
                    id={"AD_EXPIRED_dailySale__offerSuccessInfo"}
                />
            </>
        ) : (
            <>
                <CalendarCellBackgroundRow
                    msg={"유찰"}
                    type={"normal"}
                    opacity={opacity}
                />
                <HiddenKeyManage id={"AD_EXPIRED_dailySale__noOffer"} />
            </>
        )
    ) : (
        <>
            <CalendarCellBackgroundRow
                msg={"판매 중단"}
                type={"normal"}
                opacity={opacity}
            />
            <AdSaleManageScheduleCalendarCellBodyBlankContainer />
            <HiddenKeyManage id={"AD_EXPIRED_notAdDailySale"} />
        </>
    );
};

export default AdSaleManageCalendarCellPcBodyBidExpired;
