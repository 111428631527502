import { ReactElement, useState } from "react";
import styled from "styled-components";
import CssFilterConverter from "css-filter-converter";
import Shimmer from "../../commonUiComponent/shimmer/Shimmer";

export const IncludeIconButtonWrapper = styled.div<{
    bgColor?: string;
    hoverColor: string;
    hasClickEventHandler: boolean;
    hasBorder?: boolean;
}>`
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #888888;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 300ms ease;
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "transparent")};
    border: ${({ hasBorder }) => (hasBorder ? "1px solid #ebebeb" : "none")};
    overflow: hidden;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ hasClickEventHandler, hoverColor }) =>
                hasClickEventHandler ? hoverColor : "transparent"};
        }
    }

    flex-shrink: 0;
`;

export const IncludeIconButtonContainer = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const IncludeIconButtonContainerIcon = styled.img<{
    isActive: boolean;
}>`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;
type Props = {
    title: string;
    icon: string;
    isActive: boolean;
    bgColor?: string;
    activeIconColor?: string;
    hoverColor?: string;
    clickEventHandler?: (value?: any) => void | Promise<void>;
    hasBorder: boolean;
    marginRight?: string;
    isBlockEventBubble: boolean;
    onErrorHandler?: () => void;
    padding?: string;
    isLoading?: boolean;
    shimmerBgColor?: string;
};

export const IncludeIconButtonTitle = styled.div`
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;

const IncludeIconButton = (props: Props): ReactElement | null => {
    const [isActive, setIsActive] = useState(props.isActive);
    const filterValue =
        props.activeIconColor && props.isActive
            ? CssFilterConverter.hexToFilter(props.activeIconColor).color + ""
            : "";

    const hasClickEventHandler = typeof props.clickEventHandler !== "undefined";
    const [isClickHandling, setIsClickHandling] = useState(false);
    return (
        <IncludeIconButtonWrapper
            className={"include-icon__container"}
            bgColor={props.bgColor}
            hasClickEventHandler={hasClickEventHandler}
            hasBorder={props.hasBorder}
            hoverColor={props.hoverColor ? props.hoverColor : "#f5f5f5"}
            onClick={async (e) => {
                if (props.isBlockEventBubble) {
                    e.preventDefault();
                    e.stopPropagation();
                }

                if (props.clickEventHandler && !isClickHandling) {
                    setIsClickHandling(true);
                    setIsActive(!isActive);
                    await props.clickEventHandler(e);
                    setIsClickHandling(false);
                }
            }}
            onMouseDown={(e) => {
                if (e.button === 1) {
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                }
            }}
            onMouseUp={(e) => {
                if (e.button === 1) {
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                }
            }}
        >
            <Shimmer
                isLoading={props.isLoading ? props.isLoading : false}
                bgColor={props.shimmerBgColor}
            >
                <IncludeIconButtonContainer
                    style={{
                        padding: props.padding
                            ? props.padding
                            : props.hasBorder
                            ? "4px 8px 4px 4px"
                            : "4px",
                    }}
                >
                    <IncludeIconButtonContainerIcon
                        className={"include-icon__img"}
                        src={props.icon}
                        isActive={props.isActive}
                        style={{
                            // more class generate warning 떠서 inline 으로 뺌
                            marginRight: props.marginRight,
                            filter: filterValue,
                        }}
                        onError={props.onErrorHandler}
                    />
                    <IncludeIconButtonTitle>
                        {props.title}
                    </IncludeIconButtonTitle>
                </IncludeIconButtonContainer>
            </Shimmer>
        </IncludeIconButtonWrapper>
    );
};

export default IncludeIconButton;

IncludeIconButton.defaultProps = {
    hasBorder: true,
    marginRight: "0",
    isBlockEventBubble: true,
};
