import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import { HiddenKeyManage } from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarCellMobileBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarCellMobileBodyOnSchedule = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const {
        targetDateInfo: cellInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdMyDailyDateData(props);

    return isTodaySale ? (
        // 당일 판매중 - 전체 판매 중단 상관X - 낙찰 표기
        cellInfo.offerSuccessInfo ? (
            <>
                <CalendarCellRow msg={"게재중"} type={"normal"} />
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__dailySale__offerSuccessInfo__mobile"}
                />
            </>
        ) : !isAdStop ? (
            // 당일 판매중 - 전체 판매중 - 낙찰X
            <>
                <CalendarCellRow msg={"즉시구매"} type={"blue"} />
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__notAdStop__dailySale__noOffer__mobile"}
                />
            </>
        ) : (
            // 당일 판매중 - 전체 판매 중단 - 낙찰X
            <>
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__adStop__dailySale__noOffer__mobile"}
                />
            </>
        )
    ) : !isAdStop ? (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <CalendarCellRow msg={"판매중단"} type={"danger"} />
            <HiddenKeyManage
                id={
                    "AD_ON_SCHEDULE__notAdStop__notAdDailySale__noOffer__mobile"
                }
            />
        </>
    ) : (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <HiddenKeyManage
                id={"AD_ON_SCHEDULE__AdStop__notAdDailySale__noOffer__mobile"}
            />
        </>
    );
};

export default AdSaleManageCalendarCellMobileBodyOnSchedule;
