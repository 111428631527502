import { ReactElement } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";

export const AdSaleManageScheduleCalendarCellBodyHighestOfferRowContainer = styled.div`
    display: flex;
    padding: 4px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(250, 84, 250, 0.08);

    @media ${(props) => props.theme.mobileL} {
        flex-direction: row;
        justify-content: space-between;
        gap: 6px;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyHighestOfferRowLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;

    @media ${(props) => props.theme.mobileL} {
        flex-direction: row;
        gap: 6px;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyHighestOfferRowMsg = styled.div`
    color: #fa54fa;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
`;

type Props = {
    price: number;
    nickname: string;
};

const AdSaleManageScheduleCalendarCellBodyHighestOfferRow = (
    props: Props,
): ReactElement | null => {
    return (
        <AdSaleManageScheduleCalendarCellBodyHighestOfferRowContainer>
            <AdSaleManageScheduleCalendarCellBodyHighestOfferRowLeftContainer>
                <AdSaleManageScheduleCalendarCellBodyHighestOfferRowMsg
                    style={{
                        fontWeight: 500,
                    }}
                >
                    최고 입찰가
                </AdSaleManageScheduleCalendarCellBodyHighestOfferRowMsg>
                <AdSaleManageScheduleCalendarCellBodyHighestOfferRowMsg
                    style={{
                        fontWeight: 700,
                    }}
                >
                    {numberToStringWithComma(props.price)} KRW
                </AdSaleManageScheduleCalendarCellBodyHighestOfferRowMsg>
            </AdSaleManageScheduleCalendarCellBodyHighestOfferRowLeftContainer>

            <AdSaleManageScheduleCalendarCellBodyHighestOfferRowMsg
                style={{
                    fontWeight: 400,
                }}
            >
                {props.nickname}
            </AdSaleManageScheduleCalendarCellBodyHighestOfferRowMsg>
        </AdSaleManageScheduleCalendarCellBodyHighestOfferRowContainer>
    );
};

export default AdSaleManageScheduleCalendarCellBodyHighestOfferRow;
