import { ReactElement } from "react";
import styled from "styled-components";
import CalendarSelectedMobileCellRow from "./CalendarSelectedMobileCellRow";

export const CalendarSelectedMobileCellNormalRowContainer = styled.div``;

type Props = {
    keyMsg: string;
    valueMsg: string;
};

const CalendarSelectedMobileCellNormalRow = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarSelectedMobileCellRow
            {...props}
            keyStyle={{
                color: "#777",
                fontWeight: 400,
                width: "auto",
                display: "inline-flex",
                marginLeft: "4px",
            }}
            containerStyle={{
                gap: "6px",
            }}
        />
    );
};

export default CalendarSelectedMobileCellNormalRow;
