import styled from "styled-components";

export const OrderTabContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const OrderTabRowList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const OrderTabWarningRow = styled.div`
    width: 100%;
    color: #ff4671;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
`;

export const OrderTabSubRowList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: -4px;
`;

export const OrderTabUnderLine = styled.div`
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
`;

export const OrderTabButton = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 56px;
    background: ${({ isDisabled }) => (isDisabled ? "#E5E5E5" : "#fa54fa")};
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: ${({ isDisabled }) =>
        isDisabled ? "rgba(85, 85, 85, 0.30)" : "#ffffff"};
    cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
`;
