import { useAtom } from "jotai";
import { Query, useMutation } from "react-query";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { ItemApi } from "../../apis/ItemApi";
import { queryClient } from "../../App";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";

export const useDeleteItemMutation = (itemIdx: string) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(() => ItemApi.deleteItem(itemIdx), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
        onSuccess: async () => {
            pushSnackBarListItem({
                title: "",
                desc: "아이템이 정상적으로 삭제되었습니다.",
                iconUrl:
                    URL_CONSTANTS.ASSET_URL +
                    "/icon/icon-checked-in-circle.svg",
                confirmBtnTitle: "",
            });

            await queryClient.resetQueries({
                predicate: (query: Query) =>
                    query.queryKey.includes("project-list") ||
                    query.queryKey.includes("item-list"),
            });
        },
    });
};
