import {
    addDays,
    differenceInDays,
    differenceInWeeks,
    format,
    isSameDay,
    isSameMonth,
    startOfMonth,
} from "date-fns";
import { ReactElement, useRef, useState } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { ResponsiveWidth } from "../../../../../styles/Theme";
import { useSetIsOverWindowWidthTarget } from "../../../../../hooks/ui/useSetIsOverWindowWidthTarget";
import { getMonthStartEnd } from "../../../../../utils/Utils";

export const CalendarDateBodyContainer = styled.div<{
    containerStyle?: FlattenSimpleInterpolation;
}>`
    ${({ containerStyle }) => containerStyle}
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    width: inherit;
    max-width: inherit;
`;

export const CalendarSelectedDateInfoContainer = styled.div<{
    selectedDateInfoContainerIndex: number;
    bgColor?: string;
    borderColor?: string;
}>`
    grid-column-start: 1;
    grid-column-end: 8;

    position: relative;
    width: calc(100% - 20px);
    height: 0;
    border-radius: 8px;
    background: ${({ bgColor }) => (bgColor ? bgColor : "#f5f5f7")};
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${({ borderColor }) =>
        borderColor ? borderColor + " 1px solid " : "none"};

    @media ${(props) => props.theme.mobileL} {
        height: auto;
        margin: 10px;

        ::after {
            border-bottom: 8px solid
                ${({ borderColor }) => (borderColor ? borderColor : "#f5f5f7")};
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 0 solid transparent;
            content: "";
            position: absolute;
            top: -8px;
            left: ${({ selectedDateInfoContainerIndex }) => {
                const ratio =
                    Math.round(
                        ((selectedDateInfoContainerIndex % 7) / 7 + 1 / 28) *
                            10000,
                    ) / 100;

                return `calc(${ratio}% + ${(ratio * 18) / 100}px)`;
            }};
        }
    }

    transition: all 200ms ease;
`;

export type CalendarCellStateType =
    | "disabled"
    | "selected"
    | "not-valid"
    | "valid";

export type RenderCalendarCellParamType = {
    cellDate: Date;
    isCellFold: boolean;
    isShowOnlyWeek: boolean;
    calendarLength: number;
    cellState: CalendarCellStateType;
};

type Props = {
    selectedDate: Date;
    displayingDate: Date;
    onDateClick?: (value?: Date) => Promise<void>;

    // ui props
    bodyContainerStyle?: FlattenSimpleInterpolation;
    renderCellComponent: (value: RenderCalendarCellParamType) => ReactElement;
    renderMobileSelectedCellComponent?: () => ReactElement;
    mobileSelectedCellBgColor?: string;
    mobileSelectedCellBorderColor?: string;

    // conditional props
    isShowOnlyWeek: boolean;
};

const CalendarDateBody = (props: Props): ReactElement | null => {
    const containerRef = useRef<HTMLDivElement>(null);

    const monthStartEnd = getMonthStartEnd(props.displayingDate);
    const monthStart = startOfMonth(props.displayingDate);

    const startDate = monthStartEnd.startDate;
    const endDate = monthStartEnd.endDate;

    let days: ReactElement[] = [];
    let day = startDate;

    const [isOverMobileL, setIsOverMobileL] = useState(false);
    const { isSetDoneAfterMounted } = useSetIsOverWindowWidthTarget(
        setIsOverMobileL,
        ResponsiveWidth.mobileL,
    );

    function getDateState(day: Date): CalendarCellStateType {
        return !isSameMonth(day, monthStart)
            ? "disabled"
            : isSameDay(day, props.displayingDate)
            ? "selected"
            : format(props.displayingDate, "M") !== format(day, "M")
            ? "not-valid"
            : "valid";
    }

    let weekVar = 0;
    const weekOfDisplayingDate = differenceInWeeks(
        props.displayingDate,
        startDate,
    );
    const weekOfSelectedDate = differenceInWeeks(props.selectedDate, startDate);
    const calendarLength = differenceInDays(endDate, startDate) + 1;

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            days.push(
                props.renderCellComponent({
                    cellDate: day,
                    cellState: getDateState(day),
                    isCellFold:
                        props.isShowOnlyWeek &&
                        weekVar !== weekOfDisplayingDate,
                    isShowOnlyWeek: props.isShowOnlyWeek,
                    calendarLength: calendarLength,
                }),
            );
            day = addDays(day, 1);
        }
        if (
            weekVar === weekOfSelectedDate &&
            !isOverMobileL &&
            props.renderMobileSelectedCellComponent &&
            !(props.isShowOnlyWeek && weekVar !== weekOfDisplayingDate)
        ) {
            days.push(props.renderMobileSelectedCellComponent());
        }
        weekVar += 1;
    }

    return isSetDoneAfterMounted ? (
        <CalendarDateBodyContainer
            containerStyle={props.bodyContainerStyle}
            ref={containerRef}
        >
            {days.map((day) => day)}
        </CalendarDateBodyContainer>
    ) : (
        <></>
    );
};

export default CalendarDateBody;
