import { useAtom } from "jotai";
import { Query, useMutation } from "react-query";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { queryClient } from "../../../App";
import { MarketCommonApi } from "../../../apis/market/MarketCommonApi";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";

export const useOrderCancelMutation = (
    pageType: string,
    orderIdx: string,
    targetIdx: string,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => MarketCommonApi.orderCancel(pageType, orderIdx),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "성공적으로 판매 취소되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });

                await queryClient.resetQueries({
                    predicate: (query: Query) => {
                        return (
                            query.queryKey.includes(
                                `${pageType}-info-refresh-target-${targetIdx}`,
                            ) ||
                            query.queryKey.includes(
                                `${pageType}-info-refresh-target-history`,
                            ) ||
                            query.queryKey.includes("wallet-refresh-target")
                        );
                    },
                });
            },
        },
    );
};
