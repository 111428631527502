import { ReactElement } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ProjectDetailDataType } from "../../../../../../query/project/useProjectDataQuery";
import { useIsMe } from "../../../../../../hooks/common/useIsMe";
import { useOpenCommonConfirmModal } from "../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { useDeleteProjectMutation } from "../../../../../../query/project/useDeleteProjectMutation";
import ThreeDotButton from "../../../../../common/commonUnitComponent/threeDotButton/ThreeDotButton";

export const MarketProjectTitleThreeDotBtnContainer = styled.div`
    position: absolute;
    top: 46px;
    right: 0;
    z-index: 3;

    @media ${(props) => props.theme.mobileL} {
        top: 16px;
    }
`;

type Props = {
    projectData: ProjectDetailDataType;
};

const MarketProjectTitleThreeDotBtn = (props: Props): ReactElement | null => {
    const navigate = useNavigate();
    const isMe = useIsMe();
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const projectIdx = props.projectData.project_idx;
    const { mutateAsync: deleteProject } = useDeleteProjectMutation(projectIdx);
    const moreThreeDotConfig = [
        {
            title: "프로젝트 수정",
            clickHandler: () => {
                navigate("/market/adjust-project/" + projectIdx);
            },
            isShow: true,
            isGoOutIcon: false,
        },
        {
            title: "프로젝트 삭제",
            clickHandler: async () => {
                const confirmResult = await openCommonConfirmModal({
                    desc: "프로젝트를 삭제합니다. 프로젝트에 등록된 아이템은 모두 삭제되며 복구가 불가능합니다.",
                });
                if (confirmResult) {
                    await deleteProject();
                }
            },
            isShow: !props.projectData.release_bool,
            isGoOutIcon: false,
        },
    ];

    return isMe(props.projectData.user_idx) ? (
        <MarketProjectTitleThreeDotBtnContainer>
            <ThreeDotButton config={moreThreeDotConfig} />
        </MarketProjectTitleThreeDotBtnContainer>
    ) : null;
};

export default MarketProjectTitleThreeDotBtn;
