import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import {
    CommentListApiParamsType,
    CommentListApiResultType,
} from "../query/comment/useCommentListQuery";
import { getAuthKey } from "../store/auth/authKeyStore";
import {
    CommentAddApiParamsType,
    CommentAddApiResType,
} from "../query/comment/useCommentAddMutation";
import { CommentAdjustApiParamsType } from "../query/comment/useCommentAdjustMutation";

export const CommentApi = {
    getList: async (
        commentType: string,
        params: CommentListApiParamsType,
    ): Promise<CommentListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/${
                        commentType === "ad"
                            ? `v2/ad/${params.ad_idx}`
                            : commentType
                    }/comment/list`,
                    {
                        params:
                            "ad_idx" in params
                                ? {
                                      limit: params.limit,
                                      page_no: params.page_no,
                                  }
                                : params,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    const data = res.data.data;

                    resolve({
                        ...data,
                        nextPageNumber:
                            data.totalCount > params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    addComment: async (
        commentType: string,
        targetId: number,
        params: CommentAddApiParamsType,
    ): Promise<null | CommentAddApiResType> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/${
                        commentType === "ad" ? "v2/" : ""
                    }${commentType}/${targetId}/comment`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    adjustComment: async (
        commentType: string,
        commentIdx: string,
        params: CommentAdjustApiParamsType,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    `${URL_CONSTANTS.API_URL}/${
                        commentType === "ad" ? "v2/" : ""
                    }${commentType}/comment/${commentIdx}`,
                    {
                        comment: params.comment,
                        update_type: "comment",
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    deleteComment: async (
        commentType: string,
        commentIdx: string,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    `${URL_CONSTANTS.API_URL}/${
                        commentType === "ad" ? "v2/" : ""
                    }${commentType}/comment/${commentIdx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    blameComment: async (
        commentType: string,
        commentIdx: string,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${URL_CONSTANTS.API_URL}/${
                        commentType === "ad" ? "v2/" : ""
                    }${commentType}/comment/${commentIdx}/blame`,
                    {},
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
