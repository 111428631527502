import { useInfiniteQuery } from "react-query";
import { COMMA_SEPARATION_STRING } from "../../../types/BasicType";
import { CampaignManagedListItemType } from "../../../types/ad/camapaignInfo/CampaignManagedListItemType";
import { AdCampaignApi } from "../../../apis/adApi/AdCampaignApi";

export type CampaignManagedListApiResultType = {
    campaignList: CampaignManagedListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type AdCampaignManagedListApiParamsType = {
    [key: string]: any;
    page_no: number;
    limit: number;
    campaign_type_array: COMMA_SEPARATION_STRING;
    brand_id_array: COMMA_SEPARATION_STRING;
};

export const useAdCampaignManagedListQuery = (
    params: AdCampaignManagedListApiParamsType,
    isEnabled = true,
) => {
    const queryResult = useInfiniteQuery<
        CampaignManagedListApiResultType,
        Error
    >({
        queryKey: [
            "ad-campaign-list-manage-type-" + JSON.stringify(params),
            "ad-campaign-list",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return AdCampaignApi.getManagedCampaignList({
                ...params,
                page_no: pageParam,
            });
        },
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        onError: (err) => {},
        enabled: isEnabled,
    });

    const defaultData: CampaignManagedListApiResultType[] = [
        {
            campaignList: [],
            totalCount: 0,
        },
    ];

    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadAdCampaignManagedListPages(
    pages: CampaignManagedListApiResultType[],
) {
    return pages
        .map((result) => result.campaignList)
        .reduce((prev, next) => prev.concat(next));
}
