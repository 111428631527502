import { ReactElement, useEffect } from "react";
import {
    WalletCollectionHubBodyContainer,
    WalletCollectionHubMoreButtonContainer,
    WalletCollectionHubMoreButtonSize,
    WalletCollectionHubSectionContainer,
} from "../../WalletCollectionHubBody";
import {
    spreadItemListPages,
    useItemListQuery,
} from "../../../../../../../../../query/item/useItemListQuery";
import WalletCollectionItem from "../../../../../walletCollectionItemListItem/WalletCollectionItem";
import MoreButton from "../../../../../../../../common/commonUnitComponent/moreButton/MoreButton";
import {
    getSelectedChip,
    getSelectedFilterItemStatus,
    getSelectedSortingList,
} from "../../../../../../../../../utils/Utils";
import CollectionCommonStateAtom from "../../../../../../../../../store/commonState/CollectionCommonStateAtom";
import { useSetStateOnWindowWidth } from "../../../../../../../../../hooks/common/useSetStateOnWindowWidth";
import { useAtom, useSetAtom } from "jotai";
import {
    ChipType,
    SortingItemType,
} from "../../../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { FilterType } from "../../../../../../../../common/commonUiComponent/searchFilter/SearchFilter";
import { DateRange } from "react-day-picker";
import WalletCollectionHubListTypeItemList from "./components/walletCollectionHubListTypeItemList/WalletCollectionHubListTypeItemList";
import "../../../../../../../../common/modal/modalProvider/transition/modal-transition.css";
import { COLLECTION_DEFAULT_VIEW_TYPE } from "../../../../etc/WalletCollectionItemListConsntants";
import { CollectionItemListPerRowAtom } from "../../../../../../../../../store/ui/perRowAtom";
import { COLLECTION_OWNED_TAB } from "../../../../WalletCollectionHub";
import ApiResBoundary from "../../../../../../../../common/apiResBoundary/ApiResBoundary";
import WalletCollectionNoItemList from "../../../WalletCollectionNoItemList";
import WalletCollectionNoItemInGroup from "../../../WalletCollectionNoItemInGroup";
import { useDebounce } from "usehooks-ts";

type Props = {
    userIdx: string;
    selectedSubTabKey: string;
    selectedDateRange: DateRange;
    selectedViewType: string;
    sortingList: SortingItemType[];
    filters: FilterType[];
    searchInput: string;
    groupChips: ChipType[];
    isOnlyShowNotGrouping: boolean;
};

const WalletCollectionHubItemList = (props: Props): ReactElement | null => {
    const setCollectionCommonState = useSetAtom(CollectionCommonStateAtom);
    const [walletCollectionItemPerRow, setWalletCollectionItemPerRow] = useAtom(
        CollectionItemListPerRowAtom,
    );
    useSetStateOnWindowWidth(
        setWalletCollectionItemPerRow,
        [8, 8, 8, 8, 6, 6, 4],
    );
    const debouncedSearchInput = useDebounce<string>(props.searchInput, 500);

    const itemListQueryParam = {
        list_type: props.selectedSubTabKey,
        sort_type: getSelectedSortingList(props.sortingList),
        page_no: 1,
        limit: walletCollectionItemPerRow,

        user_idx: props.userIdx,
        item_name: debouncedSearchInput,
        item_status: getSelectedFilterItemStatus("status", props.filters),
        transaction_bool: getSelectedFilterItemStatus(
            "transaction_bool",
            props.filters,
        ),
        project_erc: getSelectedFilterItemStatus("erc", props.filters),
        project_network: getSelectedFilterItemStatus("network", props.filters),
        category_no: getSelectedFilterItemStatus("category", props.filters),
        brand_id: getSelectedFilterItemStatus("brand", props.filters),
        item_price: "0",
        ...(props.selectedSubTabKey === COLLECTION_OWNED_TAB &&
            getSelectedChip(props.groupChips) !== "all" && {
                item_inventory_group_idx: getSelectedChip(props.groupChips),
            }),
        ...(props.selectedSubTabKey === COLLECTION_OWNED_TAB &&
            getSelectedChip(props.groupChips) === "all" &&
            props.isOnlyShowNotGrouping && {
                item_group_depth: "0",
            }),
    };

    const {
        data: rawCollectionItemList,
        hasNextPage,
        fetchNextPage,
        isLoading: isCollectionItemListLoading,
        isFetching: isCollectionItemListFetching,
        isSuccess: isSuccessCollectionItemList,
        refetch: refetchCollectionItemList,
        error: collectionItemListError,
    } = useItemListQuery(itemListQueryParam, {
        cacheTime: 600000,
        staleTime: 0,
        enabled: walletCollectionItemPerRow !== 0,
    });
    const collectionItemList = spreadItemListPages(rawCollectionItemList.pages);

    useEffect(() => {
        setCollectionCommonState({
            collectionItemListQueryKey:
                "item-list-" + JSON.stringify(itemListQueryParam),
        });
    }, [itemListQueryParam]);

    return (
        <WalletCollectionHubSectionContainer isShow={true}>
            <ApiResBoundary
                isNoData={collectionItemList.length === 0}
                isLoading={isCollectionItemListLoading}
                error={collectionItemListError}
                defaultLoadingFallbackContainerHeight={"900px"}
                noDataFallback={
                    props.selectedSubTabKey === COLLECTION_OWNED_TAB &&
                    props.groupChips.some((group) => group.isSelected) ? (
                        <WalletCollectionNoItemInGroup />
                    ) : (
                        <WalletCollectionNoItemList
                            selectedSubTabKey={props.selectedSubTabKey}
                        />
                    )
                }
            >
                <div>
                    {props.selectedViewType === COLLECTION_DEFAULT_VIEW_TYPE ? (
                        // 액자형 default view type
                        <WalletCollectionHubBodyContainer>
                            {collectionItemList.map((item, idx) => (
                                <WalletCollectionItem
                                    userIdx={props.userIdx}
                                    listIdx={idx}
                                    key={idx}
                                    item={item}
                                    selectedSubKey={props.selectedSubTabKey}
                                />
                            ))}
                        </WalletCollectionHubBodyContainer>
                    ) : (
                        // 리스트형 default view type*/
                        <WalletCollectionHubListTypeItemList
                            key={COLLECTION_DEFAULT_VIEW_TYPE}
                            userIdx={props.userIdx}
                            selectedSubKey={props.selectedSubTabKey}
                            collectionItemList={collectionItemList}
                        />
                    )}
                    <WalletCollectionHubMoreButtonContainer>
                        <WalletCollectionHubMoreButtonSize>
                            <MoreButton
                                isLoading={isCollectionItemListFetching}
                                isShow={hasNextPage}
                                onClick={() => {
                                    fetchNextPage().then(() => null);
                                }}
                            />
                        </WalletCollectionHubMoreButtonSize>
                    </WalletCollectionHubMoreButtonContainer>
                </div>
            </ApiResBoundary>
        </WalletCollectionHubSectionContainer>
    );
};

export default WalletCollectionHubItemList;
