import React, { ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import Scrollbar from "../../../scrollbar/Scrollbar";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../../../../store/scroll/viewHeightAtom";
import { UseInfiniteQueryResult } from "react-query";
import { getPageType, getSelectedChip } from "../../../../../../utils/Utils";
import { ChipType } from "../LeftSideBar";
import { useLocalStorage } from "usehooks-ts";
import { SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_LEFT_SIDE_BAR } from "../../../scrollbar/ScrollbarType";
import ListItemTypeSelfAd from "../../../../../selfAd/listItemTypeSelfAd/ListItemTypeSelfAd";
import ApiResBoundary from "../../../../apiResBoundary/ApiResBoundary";
import isLeftSideBarRenderAtom from "../../../../../../store/ui/isLeftSideBarRenderAtom";

export const LeftSideBarBodyContainer = styled.div<{
    headerHeight: number;
    vh: number;
}>`
    position: relative;
    height: ${({ headerHeight, vh }) => `calc(100 * ${vh}px - 80px - 162px)`};
    overflow: hidden;

    @media ${(props) => props.theme.tabletL} {
        height: ${({ headerHeight, vh }) =>
            `calc(100 * ${vh}px - 60px - 162px)`};
    }

    @media ${(props) => props.theme.tabletM} {
        height: ${({ headerHeight, vh }) =>
            `calc(100 * ${vh}px - 148px - 178px)`};
    }

    @media ${(props) => props.theme.mobileL} {
        height: ${({ headerHeight, vh }) =>
            `calc(100 * ${vh}px - 148px - 142px)`};
    }
`;

export const LeftSideBarBodyList = styled.div`
    width: calc(100% - 20px);
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const LeftSideBarBodyListItemContainer = styled.div``;

export const LeftSideBarSelfAdContainer = styled.div``;

export const LeftSideBarNoDataContainer = styled.div`
    width: 100%;
    color: #555;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
    margin-top: 100px;
`;

type Props = {
    headerHeight: number;
    headerChips: ChipType[];
    renderLeftSideBarListItem: (value?: any) => ReactElement;
    getLeftSideBarListQuery: (
        value?: any,
    ) => UseInfiniteQueryResult<any, Error>;
    leftSideBarListQueryParam: any;
    recentListKey?: string;
    title: string;
};

const LeftSideBarBody = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);

    const [recentList] = useLocalStorage(
        props.recentListKey ? props.recentListKey : "",
        "",
    );

    function spreadListPages(pages: any[]) {
        return pages
            .map(
                (result) =>
                    result.projectList ||
                    result.advertisementList ||
                    result.pointList,
            )
            .reduce((prev, next) => prev.concat(next));
    }

    const queryResult = props.getLeftSideBarListQuery({
        ...props.leftSideBarListQueryParam,
        ...(getSelectedChip(props.headerChips) === "recent" && {
            recent_array: recentList,
        }),
    });

    const { data, isLoading, error, fetchNextPage, hasNextPage, refetch } =
        queryResult;

    const sideBarItemList = data ? spreadListPages(data.pages) : [];
    const pageType = getPageType(location.pathname);

    const hasNextPageRef = useRef(hasNextPage);

    useEffect(() => {
        hasNextPageRef.current = hasNextPage;
    }, [hasNextPage]);

    const [isLeftSideBarRender, setIsLeftSideBarRender] = useAtom(
        isLeftSideBarRenderAtom,
    );

    // 어차피 최초 접근 시에만 project item list 먼저 다운로드 받고
    // left sidebar list item thumbnail 에는 cache 태우니까 초기화 로직 안넣어도 될 것 같다.
    // useEffect(() => {
    //     return () => {
    //         setIsLeftSideBarRender(false);
    //     };
    // }, []);

    return (
        <LeftSideBarBodyContainer headerHeight={props.headerHeight} vh={vh}>
            {data && (
                <ApiResBoundary
                    error={error}
                    isLoading={
                        isLoading ||
                        (pageType === "project" ? !isLeftSideBarRender : false)
                    }
                    isNoData={false} // 광고 노출때문에 강제 false
                >
                    <Scrollbar
                        id={"left-side-bar-body"}
                        type={SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_LEFT_SIDE_BAR}
                        isSaveScrollTop={true}
                        infiniteScrollCallback={async (
                            showSpinner: () => void,
                        ) => {
                            if (hasNextPageRef.current) {
                                showSpinner();
                                await fetchNextPage();
                            }
                        }}
                    >
                        <LeftSideBarBodyList>
                            {sideBarItemList
                                .slice(0, 3)
                                .map((data: any, idx: number) => (
                                    <LeftSideBarBodyListItemContainer key={idx}>
                                        {props.renderLeftSideBarListItem({
                                            data: data,
                                        })}
                                    </LeftSideBarBodyListItemContainer>
                                ))}

                            <LeftSideBarSelfAdContainer>
                                <ListItemTypeSelfAd
                                    spaceId={"saybuzz_products_list"}
                                />
                            </LeftSideBarSelfAdContainer>

                            {sideBarItemList
                                .slice(3, sideBarItemList.length)
                                .map((data: any, idx: number) => (
                                    <LeftSideBarBodyListItemContainer key={idx}>
                                        {props.renderLeftSideBarListItem({
                                            data: data,
                                        })}
                                    </LeftSideBarBodyListItemContainer>
                                ))}

                            {sideBarItemList.length === 0 && (
                                <LeftSideBarNoDataContainer>
                                    {pageType === "ad"
                                        ? "광고 상품이 "
                                        : "프로젝트가 "}
                                    없습니다.
                                </LeftSideBarNoDataContainer>
                            )}
                        </LeftSideBarBodyList>
                    </Scrollbar>
                </ApiResBoundary>
            )}
        </LeftSideBarBodyContainer>
    );
};

export default LeftSideBarBody;

LeftSideBarBody.defaultProps = {
    leftSideBarBodyInfiniteScrollCallback: () => {
        console.warn("default left sidebar body infinite scroll callback");
    },
};
