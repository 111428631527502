import { useOpenModal } from "../useOpenModal";
import React from "react";
import WalletCollectionGroupMoveModal from "../../../components/wallet/walletCollection/components/walletCollectionHub/components/walletCollectionGroupModal/walletCollectionGroupMoveModal/WalletCollectionGroupMoveModal";

export function useOpenCollectionGroupMoveModal() {
    const { openModal } = useOpenModal();

    return {
        openCollectionGroupMoveModal: async (
            targetUserIdx: string,
            itemIdx: string,
        ): Promise<boolean> => {
            return await openModal(
                "wallet-collection-group-move-modal",
                "bottomSheet",
                (resolve) => {
                    return (
                        <WalletCollectionGroupMoveModal
                            resolve={resolve}
                            targetUserIdx={targetUserIdx}
                            itemIdx={itemIdx}
                        />
                    );
                },
            );
        },
    };
}
