import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";
import CalendarSelectedMobileCellGreenRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellGreenRow";
import CalendarSelectedMobileCellAllPrimaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellAllPrimaryRow";
import CalendarSelectedMobileCellBlueRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellBlueRow";
import CalendarSelectedMobileCelleKeyPrimaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCelleKeyPrimaryRow";
import CalendarSelectedMobileCellSecondaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellSecondaryRow";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";
import CalendarSelectedMobileCellDangerRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellDangerRow";
import CalendarSelectedMobileCellNormalRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellNormalRow";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";

export const AdBidCalendarSelectedMobileCellBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarSelectedMobileCellOnBid = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const adSalesInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;

    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
        isAdStop,
        isTodaySale,
    } = useGetAdDailyDateData(props);

    return !isAdStop ? (
        isTodaySale ? (
            myOfferInfo ? (
                <>
                    <CalendarSelectedMobileCellGreenRow
                        keyMsg={"입찰 가능"}
                        valueMsg={`${numberToStringWithComma(
                            myOfferInfo.highest_price_bool
                                ? myOfferInfo.offer_price
                                : Math.round(
                                      adSalesInfo.ad_minimum_bid_price * 1.1,
                                  ),
                        )} KRW 부터`}
                    />
                    <CalendarSelectedMobileCellAllPrimaryRow
                        keyMsg={"내 입찰"}
                        valueMsg={`${numberToStringWithComma(
                            myOfferInfo.offer_price,
                        )} KRW`}
                        badgeMsg={
                            myOfferInfo.highest_price_bool
                                ? "최고가"
                                : undefined
                        }
                    />
                    {myOfferInfo.buy_now_available_bool && (
                        <CalendarSelectedMobileCellBlueRow
                            keyMsg={"즉시구매 가능"}
                            valueMsg={`${numberToStringWithComma(
                                Math.round(adSalesInfo.ad_reserve_price * 1.1),
                            )} KRW`}
                        />
                    )}
                    <HiddenKey
                        id={"AD_ON_BID__notAdStop__dailySale__myOfferInfo__ms"}
                    />
                </>
            ) : otherOfferInfo ? (
                <>
                    <CalendarSelectedMobileCellGreenRow
                        keyMsg={"입찰 가능"}
                        valueMsg={`${numberToStringWithComma(
                            Math.round(adSalesInfo.ad_minimum_bid_price * 1.1),
                        )} KRW`}
                    />
                    {otherOfferInfo.buy_now_available_bool && (
                        <CalendarSelectedMobileCellBlueRow
                            keyMsg={"즉시구매 가능"}
                            valueMsg={`${numberToStringWithComma(
                                Math.round(adSalesInfo.ad_reserve_price * 1.1),
                            )} KRW`}
                        />
                    )}
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__dailySale__otherOfferInfo__ms"
                        }
                    />
                </>
            ) : myOfferSuccessInfo ? (
                <>
                    <CalendarSelectedMobileCelleKeyPrimaryRow
                        keyMsg={"내 광고 낙찰"}
                        valueMsg={`${numberToStringWithComma(
                            myOfferSuccessInfo.offer_price,
                        )} KRW`}
                    />
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__dailySale__myOfferSuccessInfo__ms"
                        }
                    />
                </>
            ) : otherOfferSuccessInfo ? (
                <>
                    <CalendarSelectedMobileCellSecondaryRow
                        keyMsg={"낙찰 완료"}
                        valueMsg={`${numberToStringWithComma(
                            otherOfferSuccessInfo.offer_price,
                        )} KRW`}
                    />
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__dailySale__otherOfferSuccessInfo__ms"
                        }
                    />
                </>
            ) : (
                <>
                    <CalendarSelectedMobileCellGreenRow
                        keyMsg={"입찰 가능"}
                        valueMsg={`${numberToStringWithComma(
                            Math.round(adSalesInfo.ad_minimum_bid_price * 1.1),
                        )} KRW 부터`}
                    />
                    <CalendarSelectedMobileCellBlueRow
                        keyMsg={"즉시구매 가능"}
                        valueMsg={`${numberToStringWithComma(
                            Math.round(adSalesInfo.ad_reserve_price * 1.1),
                        )} KRW`}
                    />
                    <HiddenKey
                        id={"AD_ON_BID__notAdStop__notAdDailySale__else__ms"}
                    />
                </>
            )
        ) : (
            <>
                <CalendarSelectedMobileCellDangerRow
                    keyMsg={"판매 중단"}
                    valueMsg={""}
                />
                <HiddenKey id={"AD_ON_BID__notAdStop__notAdDailySale__ms"} />
            </>
        )
    ) : (
        <>
            <CalendarSelectedMobileCellNormalRow
                keyMsg={"판매 중단"}
                valueMsg={""}
            />
            <HiddenKey id={"AD_ON_BID__AdStop__notAdDailySale__ms"} />
        </>
    );
};

export default AdBidCalendarSelectedMobileCellOnBid;
