import { useSetAtom } from "jotai";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useMutation } from "react-query";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { ItemInventoryApi } from "../../apis/ItemInventoryApi";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { queryClient } from "../../App";

export type AddGroupParams = {
    item_inventory_group_name: string;
};

export const useAddItemInventoryGroupMutation = (params: AddGroupParams) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const pushSnackBarListItem = useSetAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(() => ItemInventoryApi.addGroup(params), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({
                predicate: (query) =>
                    query.queryKey.includes("item-inventory-group-list"),
            });
            await queryClient.resetQueries({
                predicate: (query) =>
                    query.queryKey.includes(
                        "item-inventory-belonging-group-list",
                    ),
            });

            pushSnackBarListItem({
                title: "",
                desc: "그룹이 추가되었습니다.",
                iconUrl: `${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-in-circle.svg`,
                confirmBtnTitle: "",
            });
        },
    });
};
