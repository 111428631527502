import { ReactElement } from "react";
import styled from "styled-components";
import CalendarSelectedMobileCellRow from "./CalendarSelectedMobileCellRow";

export const CalendarCellMobileDangerRowContainer = styled.div``;

type Props = {
    keyMsg: string;
    valueMsg: string;
};

const CalendarSelectedMobileCellDangerRow = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarSelectedMobileCellRow
            {...props}
            keyStyle={{
                color: "#FF4671",
            }}
        />
    );
};

export default CalendarSelectedMobileCellDangerRow;
