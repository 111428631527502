import React, { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalContainer,
    ModalDesc,
    ModalTitle,
} from "../../../../commonUnitComponent/modal/ModalStyle";
import ModalCloseIcon from "../../../../modal/modalCloseIcon/ModalCloseIcon";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useLocalStorage } from "usehooks-ts";

export const WebPushInduceModalImg = styled.img`
    width: 244px;
    height: 150px;
`;

export const WebPushInduceModalButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 26px;
`;

type Props = {
    resolve: (value: boolean) => void;
};

const WebPushInduceModal = (props: Props): ReactElement | null => {
    const [
        isNotiInduceModalHiddenForWeekExpiredTime,
        setIsNotiInduceModalHiddenForWeekExpiredTime,
    ] = useLocalStorage("isNotiInduceModalHiddenForWeekExpiredTime", 0);

    return (
        <ModalContainer isNoPadding={false}>
            <ModalCloseIcon resolve={props.resolve} />
            <WebPushInduceModalImg
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-noti-induce.svg`}
            />

            <ModalTitle mt={"16px"}>
                이 기기에서 푸시 알림을 받아보세요!
            </ModalTitle>

            <ModalDesc>
                콘텐츠별 주문, 체결 상태 등을 바로 알려주는 알림을 켜보세요.
            </ModalDesc>

            <WebPushInduceModalButtonContainer>
                <ModalButton
                    btnType={"filled-primary"}
                    onClick={() => {
                        props.resolve(true);
                    }}
                >
                    기기 알림 켜기
                </ModalButton>

                <ModalButton
                    btnType={"text-secondary"}
                    onClick={() => {
                        let expire = new Date();
                        expire.setTime(
                            expire.getTime() + 7 * 24 * 60 * 60 * 1000,
                        );
                        setIsNotiInduceModalHiddenForWeekExpiredTime(
                            expire.getTime(),
                        );
                        props.resolve(false);
                    }}
                >
                    7일간 보지 않기
                </ModalButton>
            </WebPushInduceModalButtonContainer>
        </ModalContainer>
    );
};

export default WebPushInduceModal;
