import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";

export const SearchFilterLayerListItemCheckboxContainer = styled.div`
    width: 100%;
    display: flex;
`;
export const SearchFilterLayerListItemCheckboxLabel = styled.label<{
    checked: boolean;
}>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    border-radius: 5px;
    flex-shrink: 0;

    background: #ffffff;
    border: ${({ checked }) =>
        checked ? `1.5px solid #FA54FA` : `1.5px solid #c8c8c8`};

    background: ${({ checked }) => (checked ? `#FA54FA` : `white`)};
    cursor: pointer;
    margin-right: 10px;

    & > svg {
        position: absolute;
    }
`;

export const SearchFilterLayerListItemHiddenCheckbox = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const SearchFilterLayerListItemCheckboxIcon = styled.img``;

export const SearchFilterLayerListItemLabel = styled.div`
    word-break: break-all;
`;

type Props = {
    onChangeEventHandler: () => void;
    id: string;
    isSelected: boolean;
    title: string;
};

const SearchFilterLayerListItemCheckbox = (
    props: Props,
): ReactElement | null => {
    return (
        <SearchFilterLayerListItemCheckboxContainer>
            <SearchFilterLayerListItemCheckboxLabel
                htmlFor={props.id}
                checked={props.isSelected}
            >
                <SearchFilterLayerListItemHiddenCheckbox
                    id={props.id}
                    type="checkbox"
                    onChange={props.onChangeEventHandler}
                    checked={props.isSelected}
                />
                <SearchFilterLayerListItemCheckboxIcon
                    src={
                        URL_CONSTANTS.ASSET_URL + "/icon/icon-checked-small.svg"
                    }
                />
            </SearchFilterLayerListItemCheckboxLabel>
            <SearchFilterLayerListItemLabel>
                {props.title}
            </SearchFilterLayerListItemLabel>
        </SearchFilterLayerListItemCheckboxContainer>
    );
};

export default SearchFilterLayerListItemCheckbox;
