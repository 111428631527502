import { ReactElement, useState } from "react";
import styled from "styled-components";
import AdManageContentsHeader from "../../commonComponents/adManageContentsHeader/AdManageContentsHeader";
import { DropdownListItemType } from "../../../../common/commonUnitComponent/commonDropdownBtn/CommonDropdownBtn";
import { ChipType } from "../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import AdSaleManageScheduleCalendar from "./components/adSaleManageScheduleCalendar/AdSaleManageScheduleCalendar";
import {
    spreadAdCampaignManagedListPages,
    useAdCampaignManagedListQuery,
} from "../../../../../query/ad/campaign/useAdCampaignManagedListQuery";
import {
    BRAND_ID_SAYBUZZ,
    BRAND_ID_SOOP,
    BRAND_NAME_SOOP,
} from "../../../../../constants/brand/Brand";

export const AdSaleManageScheduleContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const AdSaleManageScheduleBrandList: DropdownListItemType[] = [
    {
        value: "전체",
        key: BRAND_ID_SOOP,
        isSelected: false,
    },
    {
        value: BRAND_NAME_SOOP,
        key: BRAND_ID_SOOP,
        isSelected: true,
    },
];

type Props = {};

/*광고 판매 관리 - 판매 일정 - contents*/
const AdSaleManageScheduleContents = (props: Props): ReactElement | null => {
    const { data: rawAdCampaignManagedList } = useAdCampaignManagedListQuery(
        {
            campaign_type_array: "1",
            brand_id_array: `${BRAND_ID_SOOP}, ${BRAND_ID_SAYBUZZ}`,
            page_no: 1,
            limit: 4,
        },
        true,
    );
    const adCampaignManagedList = spreadAdCampaignManagedListPages(
        rawAdCampaignManagedList.pages,
    );

    const [brandDropdownList, setBrandDropdownList] = useState<
        DropdownListItemType[]
    >(AdSaleManageScheduleBrandList);

    const [templateChips, setTemplateChips] = useState<ChipType[]>(
        adCampaignManagedList.map((info, infoIdx) => {
            return {
                title: info.campaignInfo.campaign_name,
                key: info.adInfo.ad_idx,
                isSelected: infoIdx === 0,
            };
        }),
    );

    const selectedTemplateChip = templateChips.find(
        (template) => template.isSelected,
    ) || {
        title: "",
        key: "",
        isSelected: false,
    };

    return (
        <AdSaleManageScheduleContentsContainer>
            <AdManageContentsHeader
                brandDropdownList={brandDropdownList}
                setBrandDropdownList={setBrandDropdownList}
                templateChips={templateChips}
                setTemplateChips={setTemplateChips}
                isChipsAllShow={false}
            />

            <AdSaleManageScheduleCalendar adIdx={selectedTemplateChip.key} />
        </AdSaleManageScheduleContentsContainer>
    );
};

export default AdSaleManageScheduleContents;
