import { atom } from "jotai";

const b = document.cookie.match(
    "(^|;)\\s*" + "tempAuthKey" + "\\s*=\\s*([^;]+)",
);
const tempAuthKey = typeof b !== "undefined" && b !== null ? b.pop() + "" : "";

const tempAuthKeyAtom = atom(tempAuthKey);

export default tempAuthKeyAtom;
