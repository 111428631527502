import React, { ReactElement } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { numberToStringWithComma } from "../../../../../../../utils/Utils";
import { useItemDataQuery } from "../../../../../../../query/item/useItemDataQuery";
import { useProjectDataQuery } from "../../../../../../../query/project/useProjectDataQuery";
import ApiResBoundary from "../../../../../../common/apiResBoundary/ApiResBoundary";
import { useOpenModal } from "../../../../../../../hooks/modal/useOpenModal";
import MarketItemHolderListModal from "./MarketItemHolderListModal";
import { useIsMe } from "../../../../../../../hooks/common/useIsMe";
import { useDeleteItemMutation } from "../../../../../../../query/item/useDeleteItemMutation";
import { useOpenCommonConfirmModal } from "../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { queryClient } from "../../../../../../../App";
import { Query } from "react-query";
import ThreeDotButton from "../../../../../../common/commonUnitComponent/threeDotButton/ThreeDotButton";
import MarketProjectTitleBadges from "../../../../../marketProject/components/MarketProjectTitle/components/marketProjectTitleBadges/MarketProjectTitleBadges";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";
import CssFilterConverter from "css-filter-converter";

export const MarketItemTitleAreaContainer = styled.div<{ type: string }>`
    display: flex;
    flex-direction: column;

    @media ${(props) => props.theme.PCM} {
        display: ${({ type }) => (type === "scrolled" ? "none" : "block")};
    }
`;

export const MarketItemTitleAreaMoreThreeDotContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const MarketItemTitleAreaItemName = styled.div`
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    display: inline-flex;
    align-items: center;
    color: #000000;
    width: 100%;

    @media ${(props) => props.theme.mobileL} {
        font-size: 24px;
    }
`;

export const MarketItemTitleAreaSecondRowContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 16px;

    @media ${(props) => props.theme.PCM} {
        margin-top: 14px;
    }
`;

export const MarketItemTitleAreaOwnedBadge = styled.div`
    color: #fa54fa;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 100% */
    border-radius: 8px;
    background: rgba(250, 84, 250, 0.1);
    display: flex;
    height: 32px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

export const MarketItemTitleAreaOwnedBadgeCheckedIcon = styled.img``;

export const MarketItemTitleAreaItemHolderContainer = styled.div``;

export const MarketItemTitleAreaItemHolder = styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #555555;
    padding: 0 10px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    cursor: pointer;
    height: 32px;
`;

export const MarketItemTitleBadgeContainer = styled.div`
    margin-top: 32px;

    @media ${(props) => props.theme.PCM} {
        margin-top: 14px;
    }
`;
type Props = {
    type: "scrolled" | "fixed";
};

const MarketItemTitleArea = (props: Props): ReactElement | null => {
    const params = useParams();
    const projectIdx = params.project_id ? params.project_id : "0";
    const itemIdx = params.item_id ? params.item_id : "0";
    const navigate = useNavigate();

    const {
        data: projectData,
        error: projectDataError,
        isLoading: isProjectDataLoading,
    } = useProjectDataQuery(projectIdx);

    const {
        data: rawItemData,
        error: itemDataError,
        isLoading: isItemDataLoading,
    } = useItemDataQuery(itemIdx);

    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { openModalWithReturnValue } = useOpenModal();
    const openHolderList = async () => {
        return await openModalWithReturnValue(
            "market-item-holder-list",
            "bottomSheet",
            (resolve) => {
                return (
                    <MarketItemHolderListModal
                        resolve={resolve}
                        itemIdx={itemIdx}
                    />
                );
            },
        );
    };

    const { mutateAsync: deleteItem } = useDeleteItemMutation(itemIdx);

    const isMe = useIsMe();
    const moreThreeDotConfig =
        isMe(rawItemData.creatorInfo.creator_idx) &&
        (rawItemData.itemInfo.item_status === "3" ||
            rawItemData.itemInfo.item_status === "4" ||
            rawItemData.itemInfo.item_status === "5")
            ? [
                  {
                      title: "반려 사유 보기",
                      clickHandler: () => {
                          navigate(
                              `/market/adjust-item/${params.project_id}/${params.item_id}`,
                          );
                      },
                      isGoOutIcon: false,
                      isShow: rawItemData.itemInfo.item_status === "5",
                  },
                  {
                      title: "아이템 출시",
                      clickHandler: () => {
                          navigate(
                              `/market/examine-item/${params.project_id}/${params.item_id}`,
                          );
                      },
                      isGoOutIcon: false,
                      isShow: rawItemData.itemInfo.item_status === "3",
                  },
                  {
                      title: "아이템 수정",
                      clickHandler: () => {
                          navigate(
                              `/market/adjust-item/${params.project_id}/${params.item_id}`,
                          );
                      },
                      isGoOutIcon: false,
                      isShow:
                          rawItemData.itemInfo.item_status === "3" ||
                          rawItemData.itemInfo.item_status === "5",
                  },
                  {
                      title: "아이템 삭제",
                      clickHandler: async () => {
                          const confirmResult = await openCommonConfirmModal({
                              title: "등록 아이템 삭제",
                              desc: "등록한 아이템을 삭제합니다. 삭제된 아이템은 복구 할 수 없습니다.",
                          });
                          if (confirmResult) {
                              await deleteItem();
                              navigate(`/market/${params.project_id}`);
                          }
                      },
                      isGoOutIcon: false,
                      isShow:
                          rawItemData.itemInfo.item_status === "3" ||
                          rawItemData.itemInfo.item_status === "4" ||
                          rawItemData.itemInfo.item_status === "5",
                  },
              ]
            : [
                  {
                      title: "새로고침",
                      clickHandler: async () => {
                          await queryClient.resetQueries({
                              predicate: (query: Query) =>
                                  query.queryKey.includes(
                                      "item-info-refresh-target-" + itemIdx,
                                  ) ||
                                  query.queryKey.includes(
                                      `market-item-average-price-${itemIdx}`,
                                  ),
                          });
                      },
                      isGoOutIcon: false,
                  },
                  {
                      title: "신고하기",
                      clickHandler: () => {
                          window.location.href =
                              "mailto:help.saybuzz@sooplive.com";
                      },
                      isShow: !isMe(rawItemData.creatorInfo.creator_idx),
                      isGoOutIcon: false,
                  },
              ];

    return (
        <ApiResBoundary
            error={itemDataError || projectDataError}
            isLoading={isItemDataLoading || isProjectDataLoading}
            isNoData={false}
            isLoadingSpinnerShow={false}
        >
            <MarketItemTitleAreaContainer type={props.type}>
                {/*쓰리닷*/}
                <MarketItemTitleAreaMoreThreeDotContainer>
                    <ThreeDotButton config={moreThreeDotConfig} />
                </MarketItemTitleAreaMoreThreeDotContainer>

                {/*아이템명*/}
                <MarketItemTitleAreaItemName>
                    {rawItemData.itemInfo.item_name}
                </MarketItemTitleAreaItemName>

                <MarketItemTitleAreaSecondRowContainer>
                    {rawItemData.itemInfo.item_owned_bool && (
                        <MarketItemTitleAreaOwnedBadge>
                            <MarketItemTitleAreaOwnedBadgeCheckedIcon
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-small.svg`}
                                style={{
                                    filter: `${
                                        CssFilterConverter.hexToFilter(
                                            "#FA54FA",
                                        ).color
                                    }`,
                                }}
                            />
                            보유중
                        </MarketItemTitleAreaOwnedBadge>
                    )}
                    {/*보유자*/}
                    <MarketItemTitleAreaItemHolderContainer>
                        <MarketItemTitleAreaItemHolder
                            onClick={async () => {
                                await openHolderList();
                            }}
                        >
                            보유자 :{" "}
                            {numberToStringWithComma(
                                rawItemData.itemInfo.item_owner_cnt,
                            )}
                            명
                        </MarketItemTitleAreaItemHolder>
                    </MarketItemTitleAreaItemHolderContainer>
                </MarketItemTitleAreaSecondRowContainer>

                {/*뱃지*/}
                <MarketItemTitleBadgeContainer>
                    <MarketProjectTitleBadges data={projectData} />
                </MarketItemTitleBadgeContainer>
            </MarketItemTitleAreaContainer>
        </ApiResBoundary>
    );
};

export default MarketItemTitleArea;
