import { ReactElement } from "react";
import styled from "styled-components";
import RightSideBarCommunity from "../../../../../common/commonUiComponent/doubleSideBar/rightSideBar/rightSideBarCommunity/RightSideBarCommunity";
import NoLoginHideInfoLayer from "../../../../../common/commonUnitComponent/noLoginHideInfoLayer/NoLoginHideInfoLayer";
import SubTab, {
    SubTabType,
} from "../../../../../common/commonUiComponent/subTab/SubTab";
import { getSelectedSubTabKey } from "../../../../../../utils/Utils";
import {
    ITEM_COMMUNITY_TAB,
    ITEM_CONCLUSION_TAB,
    ITEM_INFO_TAB,
    ITEM_ORDER_TAB,
} from "../../../MarketItemContents";
import { useElementSize } from "usehooks-ts";
import MarketItemTitleArea from "./components/MarketItemTitleArea";
import MarketItemSalesBox from "./components/marketItemOrderArea/components/MarketItemSalesBox";
import MarketItemOrderBox from "./components/marketItemOrderArea/components/marketItemOrderBox/MarketItemOrderBox";
import MarketTargetProductGetMethod from "./components/MarketTargetProductGetMethod";
import MarketItemTrendChartBox from "./components/marketItemConclusionArea/components/MarketItemTrendChartBox";
import MarketItemRealtimeConclusionBox from "./components/marketItemConclusionArea/components/MarketItemRealtimeConclusionBox";
import MarketItemIntroduceBox from "./components/MarketItemIntroduceBox";
import MarketItemWarningBox from "./components/MarketItemWarningBox";
import MarketItemMobileCreatorInfo from "./components/MarketItemMobileCreatorInfo";

export const MarketItemScrollableContentsWrapper = styled.div``;

export const MarketItemScrollableContentSubTabContainer = styled.div`
    display: none;

    @media ${(props) => props.theme.PCM} {
        display: block;
    }
`;

export const MarketItemScrollableContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;

    @media ${(props) => props.theme.PCM} {
        margin-top: 20px;
    }
`;

export const MarketItemScrollableContentsTitleContainer = styled.div`
    display: block;

    @media ${(props) => props.theme.PCM} {
        display: none;
    }
`;

export const MarketItemScrollableContentsBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
`;

export const MarketItemScrollableContentsMethodContainer = styled.div<{
    containerWidth: number;
}>`
    width: 100%;
    display: flex;
    gap: 12px;

    flex-wrap: ${({ containerWidth }) =>
        containerWidth < 520 ? "wrap" : "nowrap"};
`;

type Props = {
    subTabList: SubTabType[];
    setSubTabList: (value: SubTabType[]) => void;
};

const MarketItemScrollableContents = (props: Props): ReactElement | null => {
    const selectedSubTabKey = getSelectedSubTabKey(props.subTabList);
    const [methodContainerRef, { width: methodContainerWidth }] =
        useElementSize();

    return (
        <MarketItemScrollableContentsWrapper>
            <MarketItemScrollableContentSubTabContainer>
                <SubTab
                    subTabList={props.subTabList}
                    setSubTabList={props.setSubTabList}
                />
            </MarketItemScrollableContentSubTabContainer>
            {selectedSubTabKey === ITEM_ORDER_TAB && (
                <MarketItemScrollableContentsContainer>
                    {/*gap 때문에 margin-top 처럼 생겨서 없애기 위해서 밖에서 display:none 한번 더 감싼다.*/}
                    <MarketItemScrollableContentsTitleContainer>
                        <MarketItemTitleArea type={"scrolled"} />
                    </MarketItemScrollableContentsTitleContainer>

                    <MarketItemScrollableContentsBoxContainer>
                        <NoLoginHideInfoLayer />

                        {/*PC - item - mobile - subTab - 0 - 판매호가*/}
                        <MarketItemSalesBox />

                        {/*PC - item - mobile - subTab - 0 - 주문박스*/}
                        <MarketItemOrderBox />

                        <MarketItemScrollableContentsMethodContainer
                            containerWidth={methodContainerWidth || 0}
                            ref={methodContainerRef}
                        >
                            <MarketTargetProductGetMethod type={"point"} />
                            <MarketTargetProductGetMethod type={"token"} />
                        </MarketItemScrollableContentsMethodContainer>

                        {/*PC - item - mobile - subTab - 0 - 가격 추이*/}
                        <MarketItemTrendChartBox inSubTab={false} />

                        {/*PC - item - mobile - subTab - 0 - 체결 내역*/}
                        <MarketItemRealtimeConclusionBox inSubTab={false} />

                        {/*PC - item - mobile - subTab - 0 - 아이템 소개*/}
                        <MarketItemIntroduceBox />

                        {/*PC - item - mobile - subTab - 0 - 유의 사항*/}
                        <MarketItemWarningBox />
                    </MarketItemScrollableContentsBoxContainer>
                </MarketItemScrollableContentsContainer>
            )}

            {selectedSubTabKey === ITEM_CONCLUSION_TAB && (
                <MarketItemScrollableContentsContainer>
                    <NoLoginHideInfoLayer />

                    {/*모바일 - item - subTab - 1 - 가격 추이*/}
                    <MarketItemTrendChartBox inSubTab={true} />

                    {/*모바일 - item - subTab - 1 - 체결 내역*/}
                    <MarketItemRealtimeConclusionBox inSubTab={true} />
                </MarketItemScrollableContentsContainer>
            )}

            {selectedSubTabKey === ITEM_INFO_TAB && (
                <MarketItemScrollableContentsContainer>
                    <NoLoginHideInfoLayer />

                    {/*모바일 - item - subTab - 2 - 크리에이터 소개*/}
                    <MarketItemMobileCreatorInfo />
                </MarketItemScrollableContentsContainer>
            )}

            {selectedSubTabKey === ITEM_COMMUNITY_TAB && (
                <MarketItemScrollableContentsContainer>
                    <NoLoginHideInfoLayer />

                    {/*모바일 - item - subTab - 3 - 커뮤니티*/}
                    <RightSideBarCommunity />
                </MarketItemScrollableContentsContainer>
            )}
        </MarketItemScrollableContentsWrapper>
    );
};

export default MarketItemScrollableContents;
