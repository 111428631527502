import { ReactElement, RefObject, useEffect, useState } from "react";
import styled from "styled-components";
import Scrollbar from "../../../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import DndListContainer from "../../../../../../../../common/commonUnitComponent/dndList/DndListContainer";
import WalletCollectionGroupEditListItem from "./WalletCollectionGroupEditListItem";
import { arrayMoveImmutable } from "../../../../../../../../../utils/Utils";
import { ItemInventoryGroupListItemType } from "../../../../../../../../../types/itemInventory/ItemInventoryGroupListItemType";
import { useOrderChangeItemInventoryGroupMutation } from "../../../../../../../../../query/itemInventory/useOrderChangeItemInventoryGroupMutation";
import { ItemInventoryGroupListResultType } from "../../../../../../../../../query/itemInventory/useGetItemInventoryGroupList";

export const WalletCollectionGroupEditListContainer = styled.div`
    position: relative;
    height: 330px;
`;

type Props = {
    scrollEditListRef: RefObject<HTMLDivElement>;
    rawItemInventoryGroupListPages: ItemInventoryGroupListResultType[];
    itemInventoryGroupList: ItemInventoryGroupListItemType[];
    setItemInventoryGroupList: (
        value: ItemInventoryGroupListItemType[],
    ) => void;
};
export const scrollbarId = "wallet-collection-group-edit-modal-scrollbar";

const WalletCollectionGroupEditList = (props: Props): ReactElement | null => {
    const [isItemMoving, setIsItemMoving] = useState(false);
    const [movingItemIdx, setMovingItemIdx] = useState(0);
    const [
        changeOrderTargetItemInventoryGroupList,
        setChangeOrderTargetItemInventoryGroupList,
    ] = useState<ItemInventoryGroupListItemType[]>([]);
    const onSortEnd = async ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        const changedList = arrayMoveImmutable(
            props.itemInventoryGroupList,
            oldIndex,
            newIndex,
        ).map((itemInventoryGroupListItem, idx) => {
            return {
                ...itemInventoryGroupListItem,
                order_no: idx + 1,
            };
        });

        props.setItemInventoryGroupList(changedList);
        setChangeOrderTargetItemInventoryGroupList(changedList);
        setIsItemMoving(false);
    };

    const { mutateAsync: orderChangeItemInventoryGroup } =
        useOrderChangeItemInventoryGroupMutation({
            group_order: props.itemInventoryGroupList.map((item) => {
                return {
                    item_inventory_group_idx: item.item_inventory_group_idx,
                    order_no: item.order_no,
                };
            }),
        });

    useEffect(() => {
        if (changeOrderTargetItemInventoryGroupList.length !== 0) {
            orderChangeItemInventoryGroup();
            setChangeOrderTargetItemInventoryGroupList([]);
        }
    }, [changeOrderTargetItemInventoryGroupList]);

    return (
        <WalletCollectionGroupEditListContainer>
            <Scrollbar id={scrollbarId} type={"normal"} isThin={true}>
                <DndListContainer
                    exceptionalItemStyle={{
                        zIndex: 10001,
                    }}
                    dndList={props.itemInventoryGroupList}
                    axis={"y"}
                    hideSortableGhost={true}
                    lockToContainerEdges={false}
                    useDragHandle
                    onSortEnd={onSortEnd}
                    updateBeforeSortStart={(e) => {
                        setMovingItemIdx(e.index);
                        setIsItemMoving(true);
                    }}
                    renderListItem={({ dndListItemInfo, index }) => {
                        return (
                            <WalletCollectionGroupEditListItem
                                key={`${dndListItemInfo.item_inventory_group_idx}-${index}`}
                                groupInfo={dndListItemInfo}
                                isItemMoving={
                                    movingItemIdx === index && isItemMoving
                                }
                            />
                        );
                    }}
                    id={"wallet-collection-group-edit-modal"}
                    itemPerRow={1}
                    columnGap={12}
                    rowGap={0}
                    useWindowAsScrollContainer={false}
                    getContainer={() => {
                        return (
                            document.getElementById(
                                `${scrollbarId}__custom-scrollbar-window`,
                            ) ||
                            document.getElementById(
                                `${scrollbarId}__no-custom-scrollbar-container`,
                            ) ||
                            (document.scrollingElement as HTMLElement) ||
                            document.getElementById("root") ||
                            document.getElementsByTagName("body")[0]
                        );
                    }}
                />
            </Scrollbar>
        </WalletCollectionGroupEditListContainer>
    );
};

export default WalletCollectionGroupEditList;
