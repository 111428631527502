import { CarouselDefaultArrowButton } from "../../CarouselStyle";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import styled from "styled-components";

export const AdBidBottomContentsCarouselArrowButton = styled(
    CarouselDefaultArrowButton,
)<{
    direction: string;
    adType: string;
}>`
    width: 52px;
    height: 52px;
    top: ${({ adType }) =>
        adType === "personal" ? "calc(50% - 53px)" : "calc(50% - 50px)"};
    ${({ direction, adType }) => {
        let size = adType === "personal" ? 15 : 18;
        return direction === "left"
            ? { left: -size + "px" }
            : { right: -size + "px" };
    }}
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 60px;
    background-image: ${({ direction }) => {
        return (
            "url(" +
            URL_CONSTANTS.ASSET_URL +
            "/icon/icon-carousel-arrow-" +
            direction +
            ".png)"
        );
    }};
    background-size: 10px 18px;
    background-position: center;

    @media ${(props) => props.theme.tabletL} {
        top: ${({ adType }) =>
            adType === "personal" ? "calc(50% - 59px)" : "calc(50% - 47px)"};
        ${({ direction, adType }) => {
            let size = adType === "personal" ? 7 : 7;
            return direction === "left"
                ? { left: -size + "px" }
                : { right: -size + "px" };
        }}
    }

    @media ${(props) => props.theme.tabletM} {
        width: 45.07px;
        height: 45.07px;
        top: ${({ adType }) =>
            adType === "personal" ? "calc(50% - 46px)" : "calc(50% - 42px)"};

        background-size: 6.93px 13.87px;
    }

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const BannerArrowButton = styled(CarouselDefaultArrowButton)<{
    direction: string;
}>`
    width: 40px;
    height: 40px;
    top: calc(50% - 40px);
    ${({ direction }) => {
        let size = -40;
        return direction === "left"
            ? { left: -size + "px" }
            : { right: -size + "px" };
    }}

    border: none;
    background-image: ${({ direction }) => {
        return (
            "url('" +
            URL_CONSTANTS.ASSET_URL +
            "/icon/icon-arrow-" +
            direction +
            "-banner.svg')"
        );
    }};

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const AdCarouselArrowButton = styled(CarouselDefaultArrowButton)<{
    direction: string;
}>`
    width: 32px;
    height: 32px;
    top: calc(50% - 16px);
    ${({ direction }) => {
        let size = -4;
        return direction === "left"
            ? { left: -size + "px" }
            : { right: -size + "px" };
    }}
    border: none;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    background-image: ${({ direction }) => {
        return `url(${URL_CONSTANTS.ASSET_URL}/icon/icon-carousel-arrow-${direction}.png)`;
    }};
    background-size: 8px 14px;
    background-position: center;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;
