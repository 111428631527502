import { atom } from "jotai";
import { ReactElement, RefObject } from "react";

export type ModalComponentTypeEnum =
    | "modal"
    | "bottomSheet"
    | "onlyHistory"
    | "self-transition";

export type ModalComponentInfoListAtomType = {
    id: string;
    type: ModalComponentTypeEnum;
    renderComponent: (value?: any) => ReactElement;
    resolve: (value: any) => void;
    transitionClassName?: string;
    nodeRef: RefObject<HTMLDivElement>;
    isShowAnimated: boolean;
};

const ModalComponentInfoListAtom = atom<ModalComponentInfoListAtomType[]>([]);

export default ModalComponentInfoListAtom;

export const asyncPushModalComponentInfo = atom(
    null,
    async (get, set, newValue: ModalComponentInfoListAtomType) => {
        window.history.pushState(
            {
                key: newValue.id,
            },
            document.title,
        );

        set(ModalComponentInfoListAtom, [
            ...get(ModalComponentInfoListAtom),
            newValue,
        ]);

        setTimeout(() => {
            set(
                ModalComponentInfoListAtom,
                get(ModalComponentInfoListAtom).map((info) => {
                    return {
                        ...info,
                        isShowAnimated:
                            newValue.id === info.id
                                ? true
                                : info.isShowAnimated,
                    };
                }),
            );
        }, 50);
    },
);

export const asyncRemoveModalComponentInfo = atom(
    null,
    async (get, set, id: string) => {
        set(
            ModalComponentInfoListAtom,
            get(ModalComponentInfoListAtom).map((info) => {
                return {
                    ...info,
                    isShowAnimated:
                        id === info.id ? false : info.isShowAnimated,
                };
            }),
        );

        setTimeout(() => {
            set(
                ModalComponentInfoListAtom,
                get(ModalComponentInfoListAtom)
                    .reverse()
                    .filter((t) => t.id !== id)
                    .reverse(),
            );
        }, 260);
    },
);
