import { useQuery } from "react-query";
import { ExternalAdApi } from "../../apis/ExternalAdApi";
import { getCookie } from "../../utils/Utils";

export type ExternalAdSpaceInfoType = {
    log: {
        click_url: string;
    };
    space_element: ExternalAdSpaceType[];
};

export type ExternalAdSpaceType = {
    background_color: string;
    image_url: string;
    space_element_name: string;
    space_element_type: string;
    text: string;
};

export type ExternalAdSpaceInfoQueryParamsType = {
    brandId: string;
    campaignId: string;
    spaceId: string;
    userId?: string;
    impression_count_bool?: number;
};

// 쿼리 옵션 파라미터
export type ExternalAdSpaceInfoQueryOptionParamsType = {
    enabled?: boolean;
    suspense?: boolean;
};

// API 호출부 파라미터
export type ExternalAdGetSpaceInfoApiParamsType = {
    user?: string;
    user_id?: string;
};

export const useGetExternalAdSpaceInfoQuery = (
    apiParams: ExternalAdSpaceInfoQueryParamsType,
    queryParams?: ExternalAdSpaceInfoQueryOptionParamsType,
) => {
    const queryResult = useQuery<ExternalAdSpaceInfoType, Error>({
        queryKey: [
            `get-external-ad-space-info-${apiParams.brandId}-${
                apiParams.campaignId
            }-${apiParams.spaceId}${apiParams.userId ? apiParams.userId : ""}`,
        ],
        refetchOnWindowFocus: false,
        queryFn: () =>
            ExternalAdApi.getSpaceInfo(
                apiParams.brandId,
                apiParams.campaignId,
                apiParams.spaceId,
                {
                    user: getCookie("user"),
                    ...("userId" in apiParams && {
                        user_id: apiParams.userId,
                    }),
                },
            ),
        suspense: false,
        onSuccess: () => {},
        onError: () => {
            return [];
        },
        ...queryParams,
    });

    return {
        ...queryResult,
        data: queryResult.data || {
            log: {
                click_url: "",
            },
            space_element: [],
        },
    };
};
