import React, { ReactElement } from "react";
import {
    OrderTabButton,
    OrderTabContainer,
    OrderTabRowList,
    OrderTabSubRowList,
    OrderTabUnderLine,
    OrderTabWarningRow,
} from "../components/OrderTabStyle";
import OrderCountInput from "../components/OrderCountInput";
import { numberToStringWithComma } from "../../../../../utils/Utils";
import OrderTabRow from "../components/OrderTabRow";
import OrderTabSubRow from "../components/OrderTabSubRow";
import { useParams } from "react-router-dom";
import { useProjectDataQuery } from "../../../../../query/project/useProjectDataQuery";
import { useItemDataQuery } from "../../../../../query/item/useItemDataQuery";
import { useOpenModal } from "../../../../../hooks/modal/useOpenModal";
import ItemBuyModal from "./itemOrderModal/ItemBuyModal";
import { useOpenLoginConfirmModal } from "../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { useOpenCommonErrorModal } from "../../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import ApiResBoundary from "../../../apiResBoundary/ApiResBoundary";
import { DefaultLoadingFallbackContainer } from "../../../apiResBoundary/DefaultLoadingComponent";
import Spinner from "../../../commonUnitComponent/spinner/Spinner";
import { useSetAveragePrice } from "../../../../../hooks/common/useSetAveragePrice";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";

type Props = {};

const ItemOrderBuyTab = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const projectIdx = params.project_id ? params.project_id : "0";
    const isLogin = getIsLogin();

    const { data: rawProjectData } = useProjectDataQuery(projectIdx);
    const { data: rawItemData } = useItemDataQuery(itemIdx);

    const { openModal } = useOpenModal();
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const openItemBuyModal = async () => {
        return await openModal("item-buy-modal", "bottomSheet", (resolve) => {
            return (
                <ItemBuyModal
                    resolve={resolve}
                    count={count}
                    setCount={setCount}
                    itemIdx={itemIdx}
                    projectIdx={projectIdx}
                />
            );
        });
    };

    const {
        count,
        debouncedCount,
        setCount,
        maxCount,
        rawAveragePrice,
        isAveragePriceLoading,
        errorAveragePrice,
        remainingToken,
        warningMsg,
        isWarning,
    } = useSetAveragePrice("item", itemIdx, "st");

    return (
        <OrderTabContainer>
            <OrderTabRow
                rowKey={"구매 수량"}
                rowValue={
                    <OrderCountInput
                        count={count}
                        setCount={setCount}
                        maxCount={maxCount}
                        isWarning={isWarning}
                    />
                }
            />
            <OrderTabUnderLine />

            <ApiResBoundary
                isLoading={isAveragePriceLoading}
                error={errorAveragePrice}
                isNoData={false}
                loadingFallback={
                    <DefaultLoadingFallbackContainer
                        containerHeight={"inherit"}
                    >
                        <Spinner borderSize={6} height={24} width={24} />
                    </DefaultLoadingFallbackContainer>
                }
            >
                <OrderTabRowList>
                    <OrderTabRow
                        rowKey={"사용가능 " + rawProjectData.project_currency}
                        rowValue={numberToStringWithComma(remainingToken)}
                        rowValueUnit={rawProjectData.project_currency}
                    />

                    <OrderTabRow
                        rowKey={"구매 단가"}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.averagePrice,
                        )}
                        rowValueUnit={rawProjectData.project_currency}
                    />

                    <OrderTabRow
                        rowKey={"구매 금액"}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.totalAmount,
                        )}
                        rowValueUnit={rawProjectData.project_currency}
                    />

                    <OrderTabRow
                        rowKey={
                            "구매 로열티 " +
                            `${(
                                rawItemData.itemInfo.brand_royalty +
                                rawItemData.itemInfo.creator_royalty
                            ).toFixed(1)}%`
                        }
                        tooltipMsg={"로열티는 브랜드별로 상이합니다."}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.royalty.brandRoyalty +
                                rawAveragePrice.royalty.creatorRoyalty,
                        )}
                        rowValueUnit={"RP"}
                    />

                    <OrderTabSubRowList>
                        <OrderTabSubRow
                            rowKey={`${rawProjectData.brand_name} ${rawProjectData.brand_royalty}%`}
                            rowValue={`${numberToStringWithComma(
                                rawAveragePrice.royalty.brandRoyalty,
                            )} RP`}
                        />
                        <OrderTabSubRow
                            rowKey={`크리에이터 ${rawProjectData.creator_royalty}%`}
                            rowValue={`${numberToStringWithComma(
                                rawAveragePrice.royalty.creatorRoyalty,
                            )} RP`}
                        />
                    </OrderTabSubRowList>
                </OrderTabRowList>
            </ApiResBoundary>

            <OrderTabUnderLine />

            <ApiResBoundary
                isLoading={isAveragePriceLoading}
                error={errorAveragePrice}
                isNoData={false}
                loadingFallback={
                    <DefaultLoadingFallbackContainer
                        containerHeight={"inherit"}
                    >
                        <Spinner borderSize={6} height={24} width={24} />
                    </DefaultLoadingFallbackContainer>
                }
            >
                <OrderTabRowList>
                    <OrderTabRow
                        rowKey={"구매 로열티"}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.royalty.brandRoyalty +
                                rawAveragePrice.royalty.creatorRoyalty,
                        )}
                        rowValueUnit={"RP"}
                        isPrice={true}
                    />
                    <OrderTabRow
                        rowKey={"결제금액"}
                        rowValue={numberToStringWithComma(
                            rawAveragePrice.totalAmount,
                        )}
                        rowValueUnit={rawProjectData.project_currency}
                        isPrice={true}
                    />

                    {isWarning && (
                        <OrderTabWarningRow>{warningMsg}</OrderTabWarningRow>
                    )}
                </OrderTabRowList>
            </ApiResBoundary>
            <OrderTabButton
                isDisabled={
                    rawItemData.itemInfo.item_status !== "1" || isWarning
                }
                onClick={async () => {
                    if (rawItemData.itemInfo.item_status !== "1" || isWarning) {
                        return;
                    }
                    if (isLogin) {
                        if (debouncedCount === 0) {
                            await openCommonErrorModal({
                                title: "주문 불가능한 수량 입니다.",
                            });
                            return;
                        }
                        await openItemBuyModal();
                    } else {
                        await openLoginConfirmModal();
                    }
                }}
            >
                {rawItemData.itemInfo.item_status === "0" &&
                    "판매 대기중인 아이템입니다."}
                {rawItemData.itemInfo.item_status === "1" && "최저가 구매"}
                {rawItemData.itemInfo.item_status === "2" &&
                    "이미 귀속된 아이템입니다."}
            </OrderTabButton>
        </OrderTabContainer>
    );
};

export default ItemOrderBuyTab;
