import { ReactElement } from "react";
import styled from "styled-components";
import {
    convertAdDateToString,
    onErrorImg,
} from "../../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { GlobalSearchResultDataType } from "../../../../../../types/GlobalSearchResultDataType";
import { GlobalSearchResultTabIDsType } from "../../../../../../constants/menus/headerMenus";
import { useNavigate } from "react-router-dom";
import { useGlobalSearchRecentStore } from "../../../../../../hooks/globalSearch/useGlobalSearchRecentStore";
import Nickname from "../../../../commonUnitComponent/nickname/Nickname";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";

export const SearchResultContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SearchResultContentsMenuTitle = styled.div`
    padding: 0 6px;
    font-size: 14px;
    color: #888888;
    height: 30px;
    display: flex;
    align-items: center;
`;

export const SearchResultContents = styled.div`
    padding: 10px 6px;
    display: flex;
    background: white;
    cursor: pointer;
    overflow: visible;

    transition: all ease-in-out 0.3s;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f1f2;
            border-radius: 6px;
        }
    }
`;

export const SearchResultContentsThumbnailContainer = styled.picture`
    border: none;
    height: 44px;
`;

export const SearchResultContentsThumbnail = styled.img`
    width: 44px;
    height: 44px;
    border-radius: 50px;
`;

export const SearchResultTextContainer = styled.div`
    height: 44px;
    margin-left: 13px;

    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const SearchResultContentsTitle = styled.div`
    font-weight: 500;
    font-size: 15px;
`;

export const SearchResultContentsAuthorContainer = styled.div`
    margin-top: 2px;
    font-size: 12px;
    line-height: 18px;
    color: #888888;
    display: flex;
`;
export const SearchResultContentsAuthor = styled.div`
    margin-right: 6px;
`;

export const SearchResultContentsType = styled.div`
    margin-left: 6px;
`;

export const SearchResultMoreBtnContainer = styled.div`
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 6px;
    align-self: stretch;
    border-radius: 6px;
    background: transparent;
    transition: 300ms ease all;

    color: #777;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;

    &:hover {
        background: #f0f0f2;
    }
`;

export const SearchResultMoreBtnImg = styled.img`
    width: 5.5px;
    height: 9.5px;
`;
type Props = {
    searchResults: GlobalSearchResultDataType[];
    selectedSearchMenuId: string;
    setSelectedSearchMenuId: (value: GlobalSearchResultTabIDsType) => void;
    searchListItemId: GlobalSearchResultTabIDsType;
    searchListItemTitle: string;
    resolve: (value: boolean | number | PathAfterResolveType) => void;
};

// 내용 상단에 표시되는 분류 타이틀 때문에 묶어서 렌더링
// 더 나누기엔 너무 작을 것 같아 안나눔.
const SearchResultListItems = (props: Props): ReactElement | null => {
    const isSelectedSearchMenuAll = props.selectedSearchMenuId === "total";
    const navigate = useNavigate();
    const today = new Date();
    const selectedDate = convertAdDateToString(today);

    const { set: setRecentSearch } = useGlobalSearchRecentStore();
    return (
        <>
            {props.searchResults.length > 0 ? (
                <SearchResultContentsContainer>
                    {isSelectedSearchMenuAll && (
                        <SearchResultContentsMenuTitle>
                            {props.searchListItemTitle}
                        </SearchResultContentsMenuTitle>
                    )}

                    {props.searchResults.map((data, idx) => {
                        return (
                            <SearchResultContents
                                key={idx}
                                onClick={() => {
                                    setRecentSearch(data?.title);
                                    props.resolve({
                                        pathAfterResolve:
                                            data?.linkURL +
                                            (props.searchListItemId === "ad"
                                                ? `/date/${selectedDate}`
                                                : ""),
                                    });
                                }}
                            >
                                <SearchResultContentsThumbnailContainer>
                                    <SearchResultContentsThumbnail
                                        onError={(e) => {
                                            onErrorImg(e);
                                        }}
                                        src={data.thumbnail}
                                    ></SearchResultContentsThumbnail>
                                </SearchResultContentsThumbnailContainer>

                                <SearchResultTextContainer>
                                    <Nickname
                                        userIdx={"0"}
                                        nickname={data.title}
                                        isVerified={data.verified}
                                        isOpenMiniProfile={false}
                                        nicknameStyledComponent={
                                            SearchResultContentsTitle
                                        }
                                        tooltipPosition={
                                            idx === 0 ? "bottom" : "top"
                                        }
                                    />
                                    <SearchResultContentsAuthorContainer>
                                        <SearchResultContentsAuthor>
                                            {data.author}
                                        </SearchResultContentsAuthor>
                                        {data.type && (
                                            <>
                                                ·
                                                <SearchResultContentsType>
                                                    {data.type}
                                                </SearchResultContentsType>
                                            </>
                                        )}
                                    </SearchResultContentsAuthorContainer>
                                </SearchResultTextContainer>
                            </SearchResultContents>
                        );
                    })}
                    {isSelectedSearchMenuAll && (
                        <SearchResultMoreBtnContainer
                            onClick={() => {
                                props.setSelectedSearchMenuId(
                                    props.searchListItemId,
                                );
                            }}
                        >
                            더 보기
                            <SearchResultMoreBtnImg
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-right.svg`}
                            />
                        </SearchResultMoreBtnContainer>
                    )}
                </SearchResultContentsContainer>
            ) : null}
        </>
    );
};

export default SearchResultListItems;
