import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAtom, useSetAtom } from "jotai";
import { getIsLogin } from "../../../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import {
    AD_BID_PAGE_REG_EXP,
    AD_HOME_PAGE_PATHNAME,
    AD_MANAGE_BUY_ANALYSIS,
    AD_MANAGE_BUY_HISTORY,
    AD_MANAGE_BUY_TEMPLATES,
    AD_MANAGE_SALE_ANALYSIS,
    AD_MANAGE_SALE_HISTORY,
    AD_MANAGE_SALE_SCHEDULE,
    ITEM_PAGE_REG_EXP,
    MARKET_HOME_PAGE_PATHNAME,
    MARKET_ITEM_MANAGE_PAGE_PATHNAME,
    MARKET_POINT_MANAGE_PAGE_PATHNAME,
    MY_NOTIFICATION_SETTING_PAGE_PATHNAME,
    MY_ORDER_HISTORY_PAGE_PATHNAME,
    MY_PAYMENTS_PAGE_PATHNAME,
    MY_PROFILE_PAGE_PATHNAME,
    POINT_PAGE_REG_EXP,
    PROJECT_PAGE_REG_EXP,
    WALLET_MERGE_PAGE_PATHNAME,
    WALLET_PAGE_REG_EXP,
    WALLET_POINT_AND_TOKEN_PAGE_PATHNAME,
} from "../../../../constants/PageRegExp";
import {
    ADVERTISEMENT_SUB_HEADER_MENUS,
    MARKET_SUB_HEADER_MENUS,
    MY_SUB_HEADER_MENUS,
    WALLET_SUB_HEADER_MENUS,
} from "../../../../constants/menus/subHeaderMenus";
import hasSubHeaderAtom from "../../../../store/ui/hasSubHeaderAtom";
import { LinkMenuType } from "../header/components/HeaderMenu/HeaderMenu";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";
import { useGetIsShowHeaderGroup } from "../../../../hooks/ui/useGetIsShowHeaderGroup";
import { MOBILE_SIDE_BAR_ID } from "../doubleSideBar/leftSideBar/components/MobileLeftSideBar";
import SubHeaderItem from "./SubHeaderItem";

export const SubHeaderContainer = styled.div<{
    zIndex: number;
    isShow: boolean;
    isShowMaxWidthTabletM: boolean;
}>`
    position: fixed;
    z-index: ${({ zIndex }) => zIndex};
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(250, 250, 250, 0.9);
    backdrop-filter: blur(20px);
    overflow: scroll;
    // LNB 가 있으면 firstShow false
    // LNB 메뉴가 있으면 진입시에 서브메뉴 비노출 /  본문 스크롤 0 ~ subheader 높이 일때 부터 비노출 /
    // LNB 메뉴가 없으면 진입부터 서브메뉴 노출 / 본문 스크롤 subheader 높이 일때 부터 비노출  // 0 ~ 36 일때 노출  스크롤 내릴때 비노출 적용x
    // 36보다 클 때  => 스크롤 내릴때 비노출 / 스크롤 올릴 때 노출
    // firstShow false && tabletM 초과 => opacity 0
    // firstShow false && tabletM 이하 => opacity 1
    // firstShow true => opacity 1
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    top: ${({ isShow }) => (isShow ? "80px" : "34px")};
    height: ${({ isShow }) => (isShow ? "46px" : "0")};

    @media ${(props) => props.theme.tabletL} {
        top: ${({ isShow }) => (isShow ? "60px" : "34px")};
    }

    @media ${(props) => props.theme.tabletM} {
        opacity: ${({ isShowMaxWidthTabletM }) =>
            isShowMaxWidthTabletM ? 1 : 0};
        top: ${({ isShowMaxWidthTabletM }) =>
            isShowMaxWidthTabletM ? "60px" : "14px"};
        height: ${({ isShowMaxWidthTabletM }) =>
            isShowMaxWidthTabletM ? "46px" : "0"};
        justify-content: flex-start;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
    transition: all 200ms linear;
`;

export const SubHeaderItemContainer = styled(Link)`
    cursor: pointer;
    flex-shrink: 0;
`;

type Props = {
    isFirstShow?: boolean;
    subHeaderMenus?: LinkMenuType[];
};

const SubHeader = (props: Props): ReactElement | null => {
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const setHasSubHeader = useSetAtom(hasSubHeaderAtom);
    const [subHeaderMenus, setSubHeaderMenus] = useState<LinkMenuType[]>([]);
    const [isMountedShow, setIsMountedShow] = useState(true);
    const [subHeaderZIndex, setSubHeaderZIndex] = useState(2);

    const { getIsShow, getIsShowMaxWidthTabletM } =
        useGetIsShowHeaderGroup(isMountedShow);
    const isShow = getIsShow;
    const isShowMaxWidthTabletM =
        getIsShowMaxWidthTabletM && subHeaderZIndex !== 1;

    useEffect(() => {
        setSubHeaderZIndex(
            modalComponentInfoList.length !== 0 &&
                modalComponentInfoList[0].id === MOBILE_SIDE_BAR_ID
                ? 1
                : 2,
        );
    }, [modalComponentInfoList]);

    useEffect(() => {
        const path = location.pathname;
        if (
            path === AD_HOME_PAGE_PATHNAME ||
            AD_BID_PAGE_REG_EXP.test(path) ||
            // AD_BIDING_COMPLETE_PAGE_REG_EXP.test(path) ||
            path === AD_MANAGE_SALE_SCHEDULE ||
            path === AD_MANAGE_SALE_HISTORY ||
            path === AD_MANAGE_SALE_ANALYSIS ||
            path === AD_MANAGE_BUY_TEMPLATES ||
            path === AD_MANAGE_BUY_HISTORY ||
            path === AD_MANAGE_BUY_ANALYSIS
        ) {
            setSubHeaderMenus(ADVERTISEMENT_SUB_HEADER_MENUS || []);
            setIsMountedShow(!AD_BID_PAGE_REG_EXP.test(path));
        } else if (
            path === MARKET_HOME_PAGE_PATHNAME ||
            PROJECT_PAGE_REG_EXP.test(path) ||
            ITEM_PAGE_REG_EXP.test(path) ||
            POINT_PAGE_REG_EXP.test(path) ||
            path === MARKET_ITEM_MANAGE_PAGE_PATHNAME ||
            path === MARKET_POINT_MANAGE_PAGE_PATHNAME
        ) {
            setSubHeaderMenus(MARKET_SUB_HEADER_MENUS || []);
            setIsMountedShow(
                !(
                    PROJECT_PAGE_REG_EXP.test(path) ||
                    ITEM_PAGE_REG_EXP.test(path) ||
                    POINT_PAGE_REG_EXP.test(path)
                ),
            );
        } else if (
            path === WALLET_POINT_AND_TOKEN_PAGE_PATHNAME ||
            path === WALLET_MERGE_PAGE_PATHNAME ||
            WALLET_PAGE_REG_EXP.test(path)
        ) {
            setSubHeaderMenus(WALLET_SUB_HEADER_MENUS || []);
            setIsMountedShow(true);
        } else if (
            path === MY_NOTIFICATION_SETTING_PAGE_PATHNAME ||
            path === MY_PROFILE_PAGE_PATHNAME ||
            path === MY_PAYMENTS_PAGE_PATHNAME ||
            path === MY_ORDER_HISTORY_PAGE_PATHNAME
        ) {
            setSubHeaderMenus(MY_SUB_HEADER_MENUS || []);
            setIsMountedShow(true);
        } else {
            setSubHeaderMenus([]);
            setIsMountedShow(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        setHasSubHeader(isShow);
    }, [isShow]);

    return subHeaderMenus.length !== 0 ? (
        <SubHeaderContainer
            zIndex={subHeaderZIndex}
            isShow={isShow}
            isShowMaxWidthTabletM={isShowMaxWidthTabletM}
        >
            {subHeaderMenus.map((menu) => {
                return (
                    <SubHeaderItemContainer
                        to={
                            (menu.isNeedLogin && getIsLogin()) ||
                            !menu.isNeedLogin
                                ? menu.path
                                : {}
                        }
                        key={menu.id}
                        onClick={async () => {
                            if (menu.isNeedLogin && !getIsLogin()) {
                                await openLoginConfirmModal(menu.path);
                            }
                        }}
                    >
                        <SubHeaderItem
                            targetMenuPath={menu.path}
                            targetMenuName={menu.name}
                        />
                    </SubHeaderItemContainer>
                );
            })}
        </SubHeaderContainer>
    ) : null;
};

export default SubHeader;
