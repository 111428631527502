import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import IncludeIconButton from "../../../commonUnitComponent/includeIconButton/IncludeIconButton";
import {
    addPostfixOnImageUrl,
    getPageType,
    onErrorImg,
    onErrorResizingImg,
    shortenNumberToStringWithComma,
} from "../../../../../utils/Utils";
import { useShare } from "../../../../../hooks/common/useShare";
import Carousel, {
    RenderComponentDataType,
} from "../../../commonUnitComponent/carousel/Carousel";
import { CarouselDefaultArrowButton } from "../../../commonUnitComponent/carousel/CarouselStyle";
import { UrlMetaDataType } from "../../../../../query/item/useExtractUrlMetaQuery";
import { Link } from "react-router-dom";
import { NormalCarouselIndicator } from "../../../commonUnitComponent/carousel/components/carouselPageIndicator/CarouselPageIndicatorStyle";
import { useGetIsImageLoadComplete } from "../../../../../hooks/ui/useGetIsImageLoadComplete";
import Spinner from "../../../commonUnitComponent/spinner/Spinner";
import isLeftSideBarRenderAtom from "../../../../../store/ui/isLeftSideBarRenderAtom";
import { useAtom } from "jotai";

export const StickyContentsContainer = styled.div`
    z-index: auto;
`;

export const StickyContentsTitleAreaContainer = styled.div`
    display: none;
    @media ${(props) => props.theme.PCM} {
        display: block;
    }

    @media ${(props) => props.theme.mobileL} {
        margin-top: 8px;
    }
`;

export const StickyContentsImgWrapper = styled.div<{
    pageType: string;
}>`
    width: 100%;
    padding-top: 100%;
    position: relative;
    border-radius: 20px;
    background: #f0f1fd;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: none;

    @media ${(props) => props.theme.PCM} {
        ${({ pageType }) => {
            if (pageType === "ad") {
                return `padding-top: 100%;`;
            } else {
                return `margin-top: 24px;
                        padding-top: 320px;
                `;
            }
        }}
    }

    @media ${(props) => props.theme.tabletL} {
        ${({ pageType }) => {
            if (pageType === "ad") {
                return `padding-top: 420px;`;
            }
        }}
    }

    @media ${(props) => props.theme.mobileL} {
        ${({ pageType }) => {
            if (pageType === "ad") {
                return `padding-top: 320px;`;
            }
        }}
    }

    @media ${(props) => props.theme.mobileM} {
        padding-top: 100%;
    }
`;

export const StickyContentsImgContainer = styled.div<{ isCarousel: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: none;
    border: #f0f1fd 1px solid;
    border-radius: 20px;
    box-sizing: border-box;

    ${({ isCarousel }) => {
        if (!isCarousel) {
            return `
                display: flex;
                justify-content: center;
                align-items: center;
            `;
        }
    }}
`;

export const StickyContentsImg = styled.img<{
    isImageLoadComplete: boolean;
    pageType: string;
}>`
    height: auto;
    max-height: 100%;
    width: ${({ isImageLoadComplete }) => (isImageLoadComplete ? "100%" : "0")};
    object-fit: contain;
    border-radius: 20px;
    display: block;

    opacity: ${({ isImageLoadComplete }) => (isImageLoadComplete ? 1 : 0)};
    transition: opacity 500ms ease-in-out;

    @media ${(props) => props.theme.PCM} {
        height: ${({ pageType }) => (pageType === "ad" ? "100%" : "320px")};
        object-fit: contain;
    }

    @media ${(props) => props.theme.tabletL} {
        ${({ pageType }) => (pageType === "ad" ? "height: 420px;" : "")};
    }

    @media ${(props) => props.theme.mobileL} {
        ${({ pageType }) => (pageType === "ad" ? "height: 320px;" : "")};
    }

    @media ${(props) => props.theme.mobileM} {
        height: 100%;
    }
`;

export const StickyContentsButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 8px;
`;

export const StickyContentsCarouselArrowButton = styled(
    CarouselDefaultArrowButton,
)<{
    direction: string;
    adType: string;
}>`
    width: 32px;
    height: 32px;
    top: calc(100% - 32px);
    ${({ direction }) => {
        let size = -15;
        return direction === "left"
            ? { left: -size + "px" }
            : { right: -size + "px" };
    }}
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 60px;
    background-image: ${({ direction }) => {
        return `url(${URL_CONSTANTS.ASSET_URL}/icon/icon-carousel-arrow-${direction}.png)`;
    }};
    background-size: 5px 10px;
    background-position: center;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const StickyContentsCarouselPaginationContainer = styled(
    NormalCarouselIndicator,
)`
    right: 16px;
    bottom: 16px;
`;

export const StickyContentsIncludedButtonLink = styled(Link)`
    border-radius: 8px;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f5f5f5;
        }
    }

    transition: all 300ms ease;
`;

type Props = {
    shareDataTitle: string;
    shareDataDesc: string;
    isLike: boolean;
    likeCount: number;
    likeUnLike: () => Promise<void> | void;
    renderTitleAreaComponent?: () => ReactElement;
    thumbnail: string | string[];
    titleUrl?: string;
    titleUrlMetaData?: UrlMetaDataType;
    carouselKeyIdx?: string;
};

const StickyContents = (props: Props): ReactElement | null => {
    const share = useShare();
    const pageType = getPageType(location.pathname);
    const [isFaviconError, setIsFaviconError] = useState(false);
    const { imgRef: stickyImgRef, isImageLoadComplete } =
        useGetIsImageLoadComplete(
            typeof props.thumbnail === "string"
                ? props.thumbnail
                : props.thumbnail[0],
        );

    return (
        <StickyContentsContainer>
            {props.renderTitleAreaComponent && (
                <StickyContentsTitleAreaContainer>
                    {props.renderTitleAreaComponent()}
                </StickyContentsTitleAreaContainer>
            )}
            {typeof props.thumbnail === "string" ? (
                <StickyContentsImgWrapper pageType={pageType}>
                    <StickyContentsImgContainer isCarousel={false}>
                        <StickyContentsImg
                            key={props.thumbnail}
                            src={props.thumbnail}
                            onError={onErrorImg}
                            fetchPriority={"high"}
                            ref={stickyImgRef}
                            isImageLoadComplete={isImageLoadComplete}
                            pageType={pageType}
                        />

                        {!isImageLoadComplete && <Spinner />}
                    </StickyContentsImgContainer>
                </StickyContentsImgWrapper>
            ) : (
                <StickyContentsImgWrapper pageType={pageType}>
                    <StickyContentsImgContainer isCarousel={true}>
                        <Carousel
                            key={props.carouselKeyIdx}
                            isAuto={false}
                            isPathnameChangeInitIndex={true}
                            classNames={"no-inline-block"}
                            isShowPagination={true}
                            responsiveDisplayLength={[1, 1, 1, 1, 1, 1]}
                            displayLength={1}
                            dataList={props.thumbnail}
                            renderComponent={(
                                data: RenderComponentDataType,
                            ) => (
                                <div
                                    key={data.idx}
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                    }}
                                >
                                    <StickyContentsImg
                                        key={data.idx}
                                        src={data.listItem}
                                        onError={onErrorImg}
                                        isImageLoadComplete={true}
                                        pageType={pageType}
                                    />
                                </div>
                            )}
                            renderArrow={(data) => (
                                <StickyContentsCarouselArrowButton
                                    key={data.idx}
                                    direction={data.type}
                                    adType={"platform"}
                                />
                            )}
                            IndicatorBottomPositionList={[
                                "20px",
                                "10px",
                                "10px",
                                "10px",
                                "10px",
                                "10px",
                            ]}
                            renderPagination={(data) => (
                                <StickyContentsCarouselPaginationContainer>
                                    {data.currentIndex + 1}/{data.length}
                                </StickyContentsCarouselPaginationContainer>
                            )}
                        />
                    </StickyContentsImgContainer>
                </StickyContentsImgWrapper>
            )}
            <StickyContentsButtonContainer>
                <IncludeIconButton
                    isActive={props.isLike}
                    clickEventHandler={async (e) => {
                        await props.likeUnLike();
                    }}
                    activeIconColor={"#FF4671"}
                    icon={`${URL_CONSTANTS.ASSET_URL}/icon/icon-heart.svg`}
                    title={shortenNumberToStringWithComma(props.likeCount)}
                />

                <IncludeIconButton
                    isActive={false}
                    clickEventHandler={(e) => {
                        share({
                            title: props.shareDataTitle,
                            text: props.shareDataDesc,
                            url: location.href,
                        });
                    }}
                    activeIconColor={"#000000"}
                    icon={URL_CONSTANTS.ASSET_URL + "/icon/icon-share.svg"}
                    title={"공유하기"}
                    marginRight={"2px"}
                />

                {pageType === "item" &&
                    props.titleUrl !==
                        "https://beta.freeblox.net/play/worlds/" &&
                    props.titleUrl !== "https://" &&
                    props.titleUrl &&
                    props.titleUrlMetaData && (
                        <StickyContentsIncludedButtonLink
                            to={props.titleUrl}
                            target={"_blank"}
                            rel="noopener noreferrer"
                        >
                            <IncludeIconButton
                                isActive={false}
                                isBlockEventBubble={false}
                                activeIconColor={"#000000"}
                                icon={
                                    props.titleUrlMetaData.extract_bool &&
                                    props.titleUrlMetaData.favicon_url !== "" &&
                                    !isFaviconError
                                        ? props.titleUrlMetaData.favicon_url
                                        : `${URL_CONSTANTS.ASSET_URL}/icon/icon-world.svg`
                                }
                                title={
                                    props.titleUrlMetaData.extract_bool
                                        ? props.titleUrlMetaData.title
                                        : "더 보기"
                                }
                                marginRight={"6px"}
                                onErrorHandler={() => {
                                    setIsFaviconError(true);
                                }}
                            />
                        </StickyContentsIncludedButtonLink>
                    )}
            </StickyContentsButtonContainer>
        </StickyContentsContainer>
    );
};

export default StickyContents;

StickyContents.defaultProps = {
    titleUrlMetaData: {
        extract_bool: false,
        favicon_url: "",
        title: "",
    },
    titleUrl: "",
};
