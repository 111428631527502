import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DEFAULT_AD_MIN_PRICE } from "../useGetAdMinPriceState";
import { numberToStringWithComma } from "../../../../../../utils/Utils";

export const AdRegisterAdjustPriceInputDescListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
`;

export const AdRegisterAdjustPriceInputDescMsg = styled.div`
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`;

export const AdRegisterAdjustPriceInputDescInfluencerRequest = styled(Link)`
    color: #888;
    margin-left: 7px;
    margin-top: -4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    text-decoration: underline;

    &:focus,
    &:visited,
    &:link,
    &:active {
        text-decoration: underline;
    }
`;

type Props = {};

const AdRegisterAdjustPriceInputDesc = (props: Props): ReactElement | null => {
    const DescList = [
        {
            text: `입찰가는 ${numberToStringWithComma(
                DEFAULT_AD_MIN_PRICE,
            )} KRW 부터 1,000 KRW 단위로 입력이 가능합니다.`,
            isInfluenceLink: false,
        },
        {
            text: "인플루언서로 인증된 유저는 최대 10,000,000 KRW, 일반 유저는 최대 3,000,000 KRW 까지 판매가 가능합니다.",
            isInfluenceLink: false,
        },
        {
            text: "인플루언서 인증 신청하기",
            isInfluenceLink: true,
        },
    ];
    return (
        <AdRegisterAdjustPriceInputDescListContainer>
            {DescList.map((desc, idx) =>
                !desc.isInfluenceLink ? (
                    <AdRegisterAdjustPriceInputDescMsg key={idx}>
                        · {desc.text}
                    </AdRegisterAdjustPriceInputDescMsg>
                ) : (
                    <AdRegisterAdjustPriceInputDescInfluencerRequest
                        key={idx}
                        to={"https://about.saybuzz.io/ko/notice/1"}
                        target={"_blank"}
                    >
                        인플루언서 인증 신청하기
                    </AdRegisterAdjustPriceInputDescInfluencerRequest>
                ),
            )}
        </AdRegisterAdjustPriceInputDescListContainer>
    );
};

export default AdRegisterAdjustPriceInputDesc;
