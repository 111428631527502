import { setCookie } from "../../utils/Utils";

export function getAuthKey() {
    const b = document.cookie.match(
        "(^|;)\\s*" + "authKey" + "\\s*=\\s*([^;]+)",
    );
    return typeof b !== "undefined" && b !== null ? b.pop() + "" : "";
}

export function resetAuthKey() {
    setCookie("authKey", "", -1, "/", ".saybuzz.io");
}

export function getIsLogin() {
    return getAuthKey() !== "";
}
