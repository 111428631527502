import { ReactElement } from "react";
import styled from "styled-components";

export const AdManageCommonMobileTableContainer = styled.div`
    flex-direction: column;
`;

export const AdManageCommonMobileTableNoDataContainer = styled.div`
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    color: #555;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

type Props = {
    isNoData: boolean;
    children: ReactElement;
};

const AdManageCommonMobileTableLayout = (props: Props): ReactElement | null => {
    return (
        <AdManageCommonMobileTableContainer>
            {props.isNoData ? (
                <AdManageCommonMobileTableNoDataContainer>
                    입찰내역이 없습니다.
                </AdManageCommonMobileTableNoDataContainer>
            ) : (
                props.children
            )}
        </AdManageCommonMobileTableContainer>
    );
};

export default AdManageCommonMobileTableLayout;
