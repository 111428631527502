import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import axios from "axios";
import {
    AllAdMyOfferListApiResultType,
    AllAdMyOfferListItemParams,
} from "../../query/my/myAd/useGetAllAdMyBuyOfferListQuery";
import {
    MyAdByDateOfferListApiResultType,
    MyAdByDateOfferListParams,
} from "../../query/my/myAd/useGetMyAdByDateOfferListQuery";
import { MyOfferTemplateApiResultType } from "../../query/my/myAd/useGetMyAdOfferInfo";
import {
    MyAllAdDailyListApiResultType,
    MyAllAdDailyListParams,
} from "../../query/my/myAd/useGetMyAllAdDailyListQuery";
import {
    MyAdAnalysisListApiResultType,
    MyAdSellAnalysisListParams,
} from "../../query/my/myAd/useGetMyAdAnalysisListQuery";
import {
    MyAdDailyListApiResultType,
    MyAdDailyListParams,
} from "../../query/my/myAd/useGetMyAdDailyListQuery";

export const AdMyApi = {
    getMyAdDailyList: async (
        adIdx: string,
        params: MyAdDailyListParams,
    ): Promise<MyAdDailyListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/my/${adIdx}/daily/list`,
                    // `${URL_CONSTANTS.API_URL}/my/ad/list`,
                    {
                        params: params,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    // API : 광고 판매 > 판매 목록
    getMyAllAdDailyList: async (
        params: MyAllAdDailyListParams,
    ): Promise<MyAllAdDailyListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/my/daily/list`,
                    // `${URL_CONSTANTS.API_URL}/my/ad/list`,
                    {
                        params: params,
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // API : 광고 판매 > 판매 목록 > 입찰 이력
    getMyAdByDateOfferList: async (
        adIdx: string,
        params: MyAdByDateOfferListParams,
    ): Promise<MyAdByDateOfferListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/my/${adIdx}/offer/list`, {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // API : 광고 구매 > 입찰 목록
    getAllAdMyBuyOfferList: async (
        params: AllAdMyOfferListItemParams,
    ): Promise<AllAdMyOfferListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/my/offer/list`, {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    // 판매자가 입찰자의 템플릿 접근시 사용하는 API
    getMyAdOfferInfo: async (
        adIdx: string,
        offerIdx: string,
    ): Promise<MyOfferTemplateApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/my/${adIdx}/offer/${offerIdx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMyAdAnalysisList: async (
        type: string,
        params: MyAdSellAnalysisListParams,
    ): Promise<MyAdAnalysisListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/v2/ad/my/${type}/analysis`, {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
