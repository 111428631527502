import React, { ReactElement } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useItemDataQuery } from "../../../../../../../../../../query/item/useItemDataQuery";
import MarketItemOrderAbleBox from "./components/MarketItemOrderAbleBox";
import MarketItemOrderExceptionBox from "./components/MarketItemOrderExceptionBox";
import { useIsMe } from "../../../../../../../../../../hooks/common/useIsMe";

export const MarketItemOrderBoxContainer = styled.div``;

export const MarketItemOrderExceptionBoxBold = styled.span`
    color: #fa54fa;
    font-weight: 700;
`;

export const MarketItemOrderExceptionBoxWarning = styled.span`
    color: #ff4671;
`;

export const getItemDisplayType = (
    isTransaction: boolean,
    isCountable: boolean,
) => {
    if (!isTransaction) {
        return !isCountable ? "1" : "2";
    } else {
        return "3";
    }
};

type Props = {};

const MarketItemOrderBox = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const { data: rawItemData } = useItemDataQuery(itemIdx);
    const isMe = useIsMe();
    const isCreatorIsMe = isMe(rawItemData.creatorInfo.creator_idx);

    return (
        <MarketItemOrderBoxContainer>
            {(rawItemData.itemInfo.item_status === "0" ||
                rawItemData.itemInfo.item_status === "1" ||
                rawItemData.itemInfo.item_status === "2" ||
                rawItemData.itemInfo.item_status === "7") && (
                <MarketItemOrderAbleBox />
            )}

            {/*출시 대기*/}
            {rawItemData.itemInfo.item_status === "3" &&
                (isCreatorIsMe ? (
                    <MarketItemOrderExceptionBox
                        title={
                            <>
                                출시 전 아이템입니다.
                                <br /> 아이템 거래를 위해서는 출시 신청이
                                필요합니다.
                            </>
                        }
                        btnLinkPath={`/market/examine-item/${params.project_id}/${params.item_id}`}
                        btnTitle={"아이템 출시하기"}
                    />
                ) : (
                    <MarketItemOrderExceptionBox
                        title={<>출시 전 아이템입니다.</>}
                    />
                ))}
            {/*검토중*/}
            {rawItemData.itemInfo.item_status === "4" && (
                <MarketItemOrderExceptionBox
                    title={
                        <>
                            현재 아이템은{" "}
                            <MarketItemOrderExceptionBoxBold>
                                검토중
                            </MarketItemOrderExceptionBoxBold>
                            입니다.
                        </>
                    }
                />
            )}
            {/*출시 반려*/}
            {rawItemData.itemInfo.item_status === "5" &&
                (isCreatorIsMe ? (
                    <MarketItemOrderExceptionBox
                        title={
                            <>
                                해당 아이템의 출시 신청이{" "}
                                <MarketItemOrderExceptionBoxWarning>
                                    반려
                                </MarketItemOrderExceptionBoxWarning>
                                되었습니다. <br />
                                반려 사유를 확인하시어 다시 신청 부탁드립니다.
                            </>
                        }
                        btnTitle={"반려 사유 확인 및 재신청하기"}
                        btnLinkPath={`/market/adjust-item/${params.project_id}/${params.item_id}`}
                    />
                ) : (
                    <MarketItemOrderExceptionBox
                        title={<>출시 전 아이템입니다.</>}
                    />
                ))}
        </MarketItemOrderBoxContainer>
    );
};

export default MarketItemOrderBox;
