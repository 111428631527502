import { ReactElement, Suspense } from "react";
import styled from "styled-components";
import { useMobileTopnavHide } from "../../../hooks/ui/useMobileTopnavHide";
import Footer from "../../common/commonUiComponent/footer/Footer";

const calcPaddingTop = function (
    hasHeaderPadding: boolean,
    hasSubHeaderPadding: boolean,
    isMobileTopnavShow: boolean,
    headerHeight: number,
) {
    let paddingTop = 0;
    paddingTop += hasHeaderPadding && isMobileTopnavShow ? headerHeight : 0;
    paddingTop += hasSubHeaderPadding ? 46 : 0;

    return paddingTop + "px";
};

export const NormalPageLayoutContainer = styled.div<{
    isMobileTopnavShow: boolean;
    hasHeaderPadding: boolean;
    hasSubHeaderPadding: boolean;
}>`
    width: 100%;
    height: 100%;
    padding-top: ${({ hasHeaderPadding, hasSubHeaderPadding }) =>
        calcPaddingTop(hasHeaderPadding, hasSubHeaderPadding, true, 80)};

    @media ${(props) => props.theme.tabletL} {
        padding-top: ${({ hasHeaderPadding, hasSubHeaderPadding }) =>
            calcPaddingTop(hasHeaderPadding, hasSubHeaderPadding, true, 60)};
    }

    @media ${(props) => props.theme.mobileL} {
        padding-top: ${({
            hasHeaderPadding,
            hasSubHeaderPadding,
            isMobileTopnavShow,
        }) =>
            calcPaddingTop(
                hasHeaderPadding,
                hasSubHeaderPadding,
                isMobileTopnavShow,
                60,
            )};
    }
`;

type Props = {
    hasHeaderPadding: boolean;
    hasSubHeaderPadding: boolean;
    hasFooter: boolean;

    isMobileTopnavShow: boolean;
    fallbackComponent: ReactElement;
    children: ReactElement | boolean;
};

const NormalRootPageLayout = (props: Props): ReactElement | null => {
    useMobileTopnavHide(props.isMobileTopnavShow);

    return (
        <NormalPageLayoutContainer
            isMobileTopnavShow={props.isMobileTopnavShow}
            hasHeaderPadding={props.hasHeaderPadding}
            hasSubHeaderPadding={props.hasSubHeaderPadding}
        >
            <Suspense fallback={props.fallbackComponent}>
                {props.children}
                {props.hasFooter && <Footer />}
            </Suspense>
        </NormalPageLayoutContainer>
    );
};

export default NormalRootPageLayout;

NormalRootPageLayout.defaultProps = {
    hasFooter: true,
    fallbackComponent: <div>fallback</div>,
};
