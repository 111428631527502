import { useRef, useState } from "react";
import { InputTextDataType } from "../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import {
    DefaultExceptionUiHandlerType,
    ExceptionUiHandlerType,
} from "../../../../../apis/ErrorHandler/callApiExcpetionHandler";

export const DEFAULT_AD_PRICE = "5,000";

export function useGetAdPriceState(initInputData?: InputTextDataType) {
    const [adPrice, setAdPrice] = useState<InputTextDataType>(
        initInputData || {
            value: DEFAULT_AD_PRICE,
            isWarning: false,
            warningMsg: "",
        },
    );

    const adPriceContainerRef = useRef<HTMLDivElement>(null);

    const exceptionUiHandler: ExceptionUiHandlerType = {
        ...DefaultExceptionUiHandlerType,
        inputText: adPrice,
        setInputText: setAdPrice,
        errorTarget: {
            targetName: "AdPrice",
        },
        targetContainerRef: adPriceContainerRef,
        isDuplicateCheck: false,
    };

    return {
        adPriceState: {
            adPrice: adPrice,
            setAdPrice: setAdPrice,
            adPriceContainerRef: adPriceContainerRef,
        },
        adPriceExceptionUiHandler: exceptionUiHandler,
    };
}
