import { ReactElement } from "react";
import styled from "styled-components";
import Nickname from "../../../../../../commonUnitComponent/nickname/Nickname";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";
import userInfoAtom from "../../../../../../../../store/userInfo/userInfoAtom";
import { useGetUserProfileQuery } from "../../../../../../../../query/user/useGetUserProfileQuery";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";

export const UserProfileModalUserLoginInfoContainer = styled(Link)`
    padding: 10px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 16px);

    @media ${(props) => props.theme.mobileL} {
        padding: 0 8px;
    }
`;

export const UserProfileModalUserLoginInfoLeftContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const UserProfileModalUserLoginInfoLeftUserThumbnail = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
`;

export const UserProfileModalUserLoginInfoLeftUserTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const UserProfileModalUserLoginInfoLeftUserTitleNickname = styled.div`
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
`;

export const UserProfileModalUserLoginInfoLeftUserTitleUserId = styled.div`
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`;

export const UserProfileModalUserLoginInfoRightContainer = styled.div``;

export const UserProfileModalUserLoginInfoRightArrowIcon = styled.img`
    width: 8px;
    height: 16px;
`;

type Props = {
    resolve: (value: boolean | number) => void;
};

const UserProfileModalUserLoginInfo = (props: Props): ReactElement | null => {
    const [userInfo] = useAtom(userInfoAtom);

    const { data: rawUserProfileData, refetch: refetchUserProfileMenu } =
        useGetUserProfileQuery(userInfo.user_idx ? userInfo.user_idx : "0", {
            enabled: userInfo.user_idx !== "0",
            refetchOnWindowFocus: false,
        });

    return (
        <UserProfileModalUserLoginInfoContainer
            to={"/my"}
            onClick={() => {
                props.resolve(-1);
            }}
        >
            <UserProfileModalUserLoginInfoLeftContainer>
                <UserProfileModalUserLoginInfoLeftUserThumbnail
                    src={rawUserProfileData.userInfo.user_thumbnail_path}
                />
                <UserProfileModalUserLoginInfoLeftUserTitleContainer>
                    <UserProfileModalUserLoginInfoLeftUserTitleNickname>
                        <Nickname
                            userIdx={userInfo.user_idx}
                            nickname={userInfo.user_nick}
                            isOpenMiniProfile={false}
                            isShowVerifiedIcon={true}
                            isVerified={
                                rawUserProfileData.userInfo.user_influencer_bool
                            }
                            tooltipPosition={"bottom"}
                            isForceNicknameCursorPointer={true}
                        />
                    </UserProfileModalUserLoginInfoLeftUserTitleNickname>
                    <UserProfileModalUserLoginInfoLeftUserTitleUserId>
                        {userInfo.user_id}
                    </UserProfileModalUserLoginInfoLeftUserTitleUserId>
                </UserProfileModalUserLoginInfoLeftUserTitleContainer>
            </UserProfileModalUserLoginInfoLeftContainer>
            <UserProfileModalUserLoginInfoRightContainer>
                <UserProfileModalUserLoginInfoRightArrowIcon
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-right-arrow.svg`}
                />
            </UserProfileModalUserLoginInfoRightContainer>
        </UserProfileModalUserLoginInfoContainer>
    );
};

export default UserProfileModalUserLoginInfo;
