import { useInfiniteQuery } from "react-query";
import { SearchApi } from "../../apis/SearchApi";

export type userSearchApiResultType = {
    dataList: userSearchApiResultDataType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type userSearchApiResultDataType = {
    user_idx: string;
    user_id: string;
    user_nick: string;
    user_thumbnail_path: string;
};

export type userSearchParamsType = {
    keyword: string;
    limit: number;
    page_no: number;
};
export function getUserSearchQuery(
    params: userSearchParamsType,
    queryParams = {
        enabled: true,
        cacheTime: 0,
        staleTime: 0,
    },
) {
    const queryResult = useInfiniteQuery<userSearchApiResultType, Error>({
        ...queryParams,
        queryKey: ["search-user-" + JSON.stringify(params)],
        queryFn: ({ pageParam = 1 }) =>
            SearchApi.searchUser({
                ...params,
                page_no: pageParam,
            }),
        suspense: false,

        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: userSearchApiResultType[] = [
        {
            dataList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
}

export function spreadListUserSearchResult(pages: userSearchApiResultType[]) {
    return pages
        .map((result) => result.dataList)
        .reduce((prev, next) => prev.concat(next));
}
