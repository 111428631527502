import { ReactElement, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useAtom, useSetAtom } from "jotai";
import SnackBarListAtom, {
    asyncPopSnackBarListItem,
} from "../../../../store/ui/snackBarListAtom";
import { useTimeout } from "usehooks-ts";

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;
export const SnackBarContainer = styled.div<{ animationState: string }>`
    position: relative;
    z-index: 1l;
    background: #282828;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    transition: all 300ms;
    animation ${({ animationState }) => {
        if (animationState === "in") {
            return fadeIn;
        } else if (animationState === "out") {
            return fadeOut;
        } else return "none";
    }} 400ms ease;
`;

export const SnackBarInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SnackBarInfoTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
`;

export const SnackBarInfoDesc = styled.div<{ hasTitle: boolean }>`
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: ${({ hasTitle }) => (hasTitle ? "#999999" : "#FFFFFF")};
    display: flex;
    gap: 4px;
    align-items: center;
`;

export const SnackBarInfoIcon = styled.img`
    width: 32px;
    height: 32px;
`;
export const SnackBarInfoButton = styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    color: #fa54fa;
    cursor: pointer;
`;

type Props = {
    title: string;
    desc: string;
    iconUrl: string;
    confirmBtnTitle: string;
    isInfinite?: boolean;
    confirmEventHandler: (value?: any) => Promise<void>;
};

const SnackBar = (props: Props): ReactElement | null => {
    const [snackBarList, setSnackBarList] = useAtom(SnackBarListAtom);
    const [animationState, setAnimationState] = useState("in");
    const popSnackBar = useSetAtom(asyncPopSnackBarListItem);

    const hasTitle = props.title !== "";
    const hasIcon = props.iconUrl !== "";
    const hasBtn = props.confirmBtnTitle !== "";
    const isInfinite =
        typeof props.isInfinite !== "undefined" && props.isInfinite;
    const containerPadding = hasTitle
        ? "14px 20px"
        : hasIcon
        ? "10px 20px"
        : "15px 20px";

    const [isShow, setIsShow] = useState(true);
    useTimeout(() => {
        if (!isInfinite) {
            setAnimationState("out");
        }
    }, 2600);

    useTimeout(() => {
        if (!isInfinite) {
            setIsShow(false);
        }
    }, 3000);

    return isShow ? (
        <SnackBarContainer
            id={"snackbar--container"}
            animationState={animationState}
            style={{
                padding: containerPadding,
            }}
            onClick={() => {
                popSnackBar();
            }}
        >
            <SnackBarInfoContainer id={"snackbar--info__container"}>
                {hasTitle && (
                    <SnackBarInfoTitle id={"snackbar--info__title"}>
                        {props.title}
                    </SnackBarInfoTitle>
                )}

                {props.desc !== "" && (
                    <SnackBarInfoDesc
                        id={"snackbar--info__desc"}
                        hasTitle={hasTitle}
                    >
                        {hasIcon && <SnackBarInfoIcon src={props.iconUrl} />}
                        {props.desc}
                    </SnackBarInfoDesc>
                )}
            </SnackBarInfoContainer>
            {hasBtn && (
                <SnackBarInfoButton
                    id={"snackbar--info__btn"}
                    onClick={async () => {
                        if (props.confirmEventHandler) {
                            await props.confirmEventHandler();
                        }

                        if (isInfinite) {
                            setSnackBarList(
                                snackBarList.filter(
                                    (snackbar) =>
                                        !(
                                            snackbar.title === props.title &&
                                            snackbar.desc === props.desc
                                        ),
                                ),
                            );
                        }
                    }}
                >
                    {props.confirmBtnTitle}
                </SnackBarInfoButton>
            )}
        </SnackBarContainer>
    ) : null;
};

export default SnackBar;

SnackBar.defaultProps = {
    title: "",
    desc: "",
    iconUrl: "",
    confirmBtnTitle: "",
    confirmEventHandler: async () => {},
};
