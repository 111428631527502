import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { onErrorImg } from "../../../../../utils/Utils";
import { CommonTCEllipsis } from "../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";

export const MarketHistoryTableBodyColumnTitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const MarketHistoryTableBodyColumnTitleLink = styled(Link)``;

export const MarketHistoryTableBodyColumnTitleThumbnail = styled.img`
    height: 40px;
    width: 40px;
    margin-right: 10px;
    border-radius: 8px;
`;

export const MarketHistoryTableBodyColumnTitleInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const MarketHistoryTableBodyColumnTitleInfoTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #333333;
    text-align: left;

    ${CommonTCEllipsis}
`;

export const MarketHistoryTableBodyColumnTitleInfoDesc = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    color: #888888;
    margin-top: 8px;
    ${CommonTCEllipsis}
`;

type Props = {
    imgSrc: string;
    title: string;
    desc: string;
    targetType: string;
    targetIdx: string;
    subTargetType?: string;
    subTargetIdx?: string;
};

const MarketHistoryTableBodyColumnTitle = (
    props: Props,
): ReactElement | null => {
    const getLink = (type: string, targetIdx: string) => {
        switch (type) {
            case "item":
                return "/market/" + targetIdx;

            case "ad":
                return "/ad/" + targetIdx;

            case "point":
                return "/market/point/" + targetIdx;

            case "project":
                return "/market/" + targetIdx;

            default:
                return "";
        }
    };
    return (
        <MarketHistoryTableBodyColumnTitleContainer>
            <MarketHistoryTableBodyColumnTitleLink
                to={getLink(props.targetType, props.targetIdx)}
            >
                <MarketHistoryTableBodyColumnTitleThumbnail
                    src={props.imgSrc}
                    onError={onErrorImg}
                />
            </MarketHistoryTableBodyColumnTitleLink>

            <MarketHistoryTableBodyColumnTitleInfoContainer>
                <MarketHistoryTableBodyColumnTitleLink
                    to={getLink(props.targetType, props.targetIdx)}
                >
                    <MarketHistoryTableBodyColumnTitleInfoTitle>
                        {props.title}
                    </MarketHistoryTableBodyColumnTitleInfoTitle>
                </MarketHistoryTableBodyColumnTitleLink>
                {props.desc !== "" &&
                    props.subTargetIdx &&
                    props.subTargetType && (
                        <MarketHistoryTableBodyColumnTitleLink
                            to={getLink(
                                props.subTargetType,
                                props.subTargetIdx,
                            )}
                        >
                            <MarketHistoryTableBodyColumnTitleInfoDesc>
                                {props.desc}
                            </MarketHistoryTableBodyColumnTitleInfoDesc>
                        </MarketHistoryTableBodyColumnTitleLink>
                    )}
            </MarketHistoryTableBodyColumnTitleInfoContainer>
        </MarketHistoryTableBodyColumnTitleContainer>
    );
};

export default MarketHistoryTableBodyColumnTitle;
