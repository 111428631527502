import { RefObject } from "react";
import styled from "styled-components";
import WhiteBox from "../../../../common/commonUnitComponent/box/WhiteBox";
import {
    AdRegisterAdjustHeaderTitle,
    AdRegisterAdjustHeaderTitleDesc,
} from "../../AdRegisterContents";
import ToggleSwitch from "../../../../common/commonUnitComponent/toggleSwitch/ToggleSwitch";
import { AdRegisterAdjustCommonSwitchTitleContainer } from "../AdRegisterAdjustAutoAcceptSwitch";
import { InputTextDataType } from "../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import AdRegisterAdjustDaySalePriceResultBox from "./components/AdRegisterAdjustDaySalePriceResultBox";
import { CSSTransition } from "react-transition-group";
import "../../../../common/modal/modalProvider/transition/max-height-transition.css";
import AdRegisterAdjustDaySalePriceStartTimeRow from "./components/AdRegisterAdjustDaySalePriceStartTimeRow";
import AdRegisterAdjustDaySalePriceRow from "./components/AdRegisterAdjustDaySalePriceRow";
import { useOpenCommonAlertModal } from "../../../../../hooks/modal/openModal/useOpenCommonAlertModal";

export const AdRegisterAdjustDaySaleSwitchContainer = styled.div``;

export const AdRegisterAdjustDaySaleInfoContainer = styled.div<{
    isOpen: boolean;
}>`
    max-height: ${({ isOpen }) => (isOpen ? "320px" : 0)};
    opacity: ${({ isOpen }) => (isOpen ? "1" : 0)};
    margin-top: ${({ isOpen }) => (isOpen ? "24px" : 0)};
    overflow: hidden;
    transition: all 300ms ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
export const AdRegisterAdjustDaySaleInfoRow = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

export const AdRegisterAdjustDaySaleInfoKey = styled.div`
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustDaySaleInfoValueContainer = styled.div`
    display: flex;
    align-items: baseline;
    gap: 6px;
    color: #888;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustDaySaleInfoValueStartTimeInputContainer = styled.div`
    width: 60px;
`;

export const AdRegisterAdjustDaySaleInfoValuePriceInputContainer = styled.div`
    width: 260px;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100vw - 194px);
    }
`;

export function getRateTimeSalePrice(
    timeSalePrice: number,
    reservePrice: number,
    isNoPercent = false,
) {
    const rate = Math.floor(
        100 - (Math.round((timeSalePrice / reservePrice) * 100) / 100) * 100,
    );
    return rate !== 0 ? rate + (isNoPercent ? "" : "%") : "";
}

type Props = {
    isAdBuyNowAvailable: boolean;
    adPrice: number;
    isAdDaySale: boolean;
    setIsAdDaySale: (value: boolean) => void;

    adDaySaleStartTime: InputTextDataType;
    setAdDaySaleStartTime: (value: InputTextDataType) => void;
    adDaySaleStartTimeContainerRef: RefObject<HTMLDivElement>;

    adDaySalePrice: InputTextDataType;
    setAdDaySalePrice: (value: InputTextDataType) => void;
    adDaySalePriceContainerRef: RefObject<HTMLDivElement>;
};

const AdRegisterAdjustDaySaleSwitch = (props: Props): JSX.Element => {
    const maxPrice = props.adPrice;
    const price = parseInt(props.adDaySalePrice.value.replace(/,/g, ""));
    const { openCommonAlertModal } = useOpenCommonAlertModal();

    return (
        <CSSTransition
            in={props.isAdBuyNowAvailable}
            classNames={"max-height"}
            timeout={250}
            mountOnEnter={true}
            unmountOnExit={true}
        >
            <WhiteBox>
                <AdRegisterAdjustDaySaleSwitchContainer>
                    <AdRegisterAdjustCommonSwitchTitleContainer>
                        <AdRegisterAdjustHeaderTitle>
                            게재 당일 광고 가격 할인
                        </AdRegisterAdjustHeaderTitle>
                        <ToggleSwitch
                            id={"ad-buy-now-isAdDaySale-switch"}
                            isChecked={props.isAdDaySale}
                            onChangeFunction={() => {
                                if (props.adPrice === 1000) {
                                    openCommonAlertModal({
                                        hasCloseBtn: false,
                                        title: "즉시 구매 가격 변경 필요",
                                        btnType: "filled-secondary",
                                        desc: "즉시구매가가 1,000원일 때에는 '게재 당일 광고 가격 할인'이 제한됩니다.",
                                    });
                                } else {
                                    props.setIsAdDaySale(!props.isAdDaySale);
                                }
                            }}
                            isDisabled={false}
                        />
                    </AdRegisterAdjustCommonSwitchTitleContainer>

                    <AdRegisterAdjustHeaderTitleDesc>
                        게재 당일 광고를 판매할 때 남은 시간을 고려해 광고
                        가격을 할인해 판매할 수 있습니다.
                    </AdRegisterAdjustHeaderTitleDesc>

                    <AdRegisterAdjustDaySaleInfoContainer
                        isOpen={props.isAdDaySale}
                    >
                        <AdRegisterAdjustDaySalePriceStartTimeRow
                            adDaySaleStartTime={props.adDaySaleStartTime}
                            setAdDaySaleStartTime={props.setAdDaySaleStartTime}
                            adDaySaleStartTimeContainerRef={
                                props.adDaySaleStartTimeContainerRef
                            }
                        />

                        <AdRegisterAdjustDaySalePriceRow
                            adPrice={props.adPrice}
                            adDaySalePrice={props.adDaySalePrice}
                            setAdDaySalePrice={props.setAdDaySalePrice}
                            adDaySalePriceContainerRef={
                                props.adDaySalePriceContainerRef
                            }
                            isAdDaySale={props.isAdDaySale}
                        />

                        <AdRegisterAdjustDaySalePriceResultBox
                            price={price}
                            rate={Number.parseInt(
                                getRateTimeSalePrice(price, maxPrice, true),
                            )}
                        />
                    </AdRegisterAdjustDaySaleInfoContainer>
                </AdRegisterAdjustDaySaleSwitchContainer>
            </WhiteBox>
        </CSSTransition>
    );
};

export default AdRegisterAdjustDaySaleSwitch;
