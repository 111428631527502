import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../../../../../../constants/UrlConstants";
import { shortenNumberToStringWithComma } from "../../../../../../../../../../../../utils/Utils";
import IncludeIconButton from "../../../../../../../../../../../common/commonUnitComponent/includeIconButton/IncludeIconButton";
import { useLikeUnLike } from "../../../../../../../../../../../../hooks/common/useLikeUnLike";
import { queryClient } from "../../../../../../../../../../../../App";
import CollectionCommonStateAtom from "../../../../../../../../../../../../store/commonState/CollectionCommonStateAtom";
import { useAtom } from "jotai";

export const WalletCollectionHubListTypeItemLikeBtnContainer = styled.div``;

type Props = {
    itemIdx: string;
    isLike: boolean;
    likeCount: number;
    userIdx: string;
};

const WalletCollectionHubListTypeItemLikeBtn = (
    props: Props,
): ReactElement | null => {
    const [collectionCommonState] = useAtom(CollectionCommonStateAtom);
    // collection 은 따로 refetch
    const refetchItemListTitleItemListQuery = async () => {
        await queryClient.refetchQueries({
            queryKey: collectionCommonState.collectionItemListQueryKey,
            refetchPage: () => true,
        });
        await queryClient.refetchQueries({
            queryKey: "title-item-list-" + props.userIdx,
            refetchPage: () => true,
        });
    };

    const { likeUnLike } = useLikeUnLike("item", props.itemIdx, props.isLike);

    return (
        <IncludeIconButton
            isActive={props.isLike}
            clickEventHandler={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                await likeUnLike();
                await refetchItemListTitleItemListQuery();
            }}
            hasBorder={true}
            bgColor={"rgba(13, 13, 26, 0.04)"}
            activeIconColor={"#FF4671"}
            hoverColor={"rgba(13, 13, 26, 0.08)"}
            icon={`${URL_CONSTANTS.ASSET_URL}/icon/icon-heart.svg`}
            title={shortenNumberToStringWithComma(props.likeCount)}
        />
    );
};

export default WalletCollectionHubListTypeItemLikeBtn;
