import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import { HiddenKeyManage } from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarCellMobileBodyBidExpiredContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarCellMobileBodyBidExpired = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const { targetDateInfo: cellInfo, isTodaySale } =
        useGetAdMyDailyDateData(props);

    const opacity = 0.3;

    return isTodaySale ? (
        typeof cellInfo.offerSuccessInfo === "undefined" ? (
            <>
                <CalendarCellRow
                    msg={"유찰"}
                    type={"normal"}
                    opacity={opacity}
                />
                <HiddenKeyManage
                    id={"AD_EXPIRED_dailySale__offerSuccessInfo__mobile"}
                />
            </>
        ) : (
            <>
                <CalendarCellRow
                    msg={"게재완료"}
                    type={"normal"}
                    opacity={opacity}
                />
                <HiddenKeyManage id={"AD_EXPIRED_dailySale__noOffer__mobile"} />
            </>
        )
    ) : (
        <>
            <CalendarCellRow
                msg={"판매중단"}
                type={"normal"}
                opacity={opacity}
            />
            <HiddenKeyManage id={"AD_EXPIRED_notAdDailySale__mobile"} />
        </>
    );
};

export default AdSaleManageCalendarCellMobileBodyBidExpired;
