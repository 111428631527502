import { SortingItemType } from "../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { FilterType } from "../../../../../common/commonUiComponent/searchFilter/SearchFilter";
import { ItemCategory } from "../../../../../../constants/category/ItemCategory";
import { WalletCollectionSearchFilterExceptionOnChangeHandlers } from "./WalletCollectionSearchFilterExceptionOnChangeHandlers";
import { SubTabType } from "../../../../../common/commonUiComponent/subTab/SubTab";
import {
    COLLECTION_COMMUNITY_TAB,
    COLLECTION_CREATED_TAB,
    COLLECTION_HISTORY_TAB,
    COLLECTION_LIKED_TAB,
    COLLECTION_OWNED_TAB,
    COLLECTION_PRIVATE_TAB,
} from "../WalletCollectionHub";
import { UserProfileType } from "../../../../../../query/user/useGetUserProfileQuery";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import {
    BRAND_ID_SOOP,
    BRAND_NAME_SOOP,
} from "../../../../../../constants/brand/Brand";

export const COLLECTION_DEFAULT_VIEW_TYPE = "default";
export const COLLECTION_LIST_VIEW_TYPE = "list";

export const walletCollectionOwnedItemSortingList: SortingItemType[] = [
    {
        title: "최신 획득 순",
        key: "owned_at_desc",
        isSelected: true,
    },
    {
        title: "오래된 획득 순",
        key: "owned_at_asc",
        isSelected: false,
    },
    {
        title: "좋아요 많은 순",
        key: "like_cnt_desc",
        isSelected: false,
    },
    {
        title: "좋아요 적은 순",
        key: "like_cnt_asc",
        isSelected: false,
    },
];

export const walletCollectionLikedItemSortingList: SortingItemType[] = [
    {
        title: "최신 좋아요 순",
        key: "liked_at_desc",
        isSelected: true,
    },
    {
        title: "오래된 좋아요 순",
        key: "liked_at_asc",
        isSelected: false,
    },
    {
        title: "좋아요 많은 순",
        key: "like_cnt_desc",
        isSelected: false,
    },
    {
        title: "좋아요 적은 순",
        key: "like_cnt_asc",
        isSelected: false,
    },
];

export const walletCollectionCreatedItemSortingList: SortingItemType[] = [
    {
        title: "최신 등록 순",
        key: "created_at_desc",
        isSelected: true,
    },
    {
        title: "오래된 등록 순",
        key: "created_at_asc",
        isSelected: false,
    },
    {
        title: "좋아요 많은 순",
        key: "like_cnt_desc",
        isSelected: false,
    },
    {
        title: "좋아요 적은 순",
        key: "like_cnt_asc",
        isSelected: false,
    },
];

export const walletCollectionGroupItemSortingList: SortingItemType[] = [
    {
        title: "그룹 등록 순",
        key: "order_desc",
        isSelected: true,
    },
    {
        title: "그룹 등록 역순",
        key: "order_asc",
        isSelected: false,
    },
    {
        title: "최신 획득 순",
        key: "owned_at_desc",
        isSelected: false,
    },
    {
        title: "오래된 획득 순",
        key: "owned_at_asc",
        isSelected: false,
    },
    {
        title: "좋아요 많은 순",
        key: "like_cnt_desc",
        isSelected: false,
    },
    {
        title: "좋아요 적은 순",
        key: "like_cnt_asc",
        isSelected: false,
    },
];

export const WalletCollectionFilters: FilterType[] = [
    {
        title: "브랜드",
        key: "brand",
        isCheckbox: false,
        totalAdditionalExceptionOnChangeHandler: (
            isSelected: boolean,
            tempFilters: FilterType[],
        ) => {
            return WalletCollectionSearchFilterExceptionOnChangeHandlers.brandFilterHandler(
                false,
                tempFilters,
            );
        },
        filterList: [
            {
                title: BRAND_NAME_SOOP,
                key: BRAND_ID_SOOP,
                isSelected: true,
                additionalExceptionOnChangeHandler:
                    WalletCollectionSearchFilterExceptionOnChangeHandlers.brandFilterHandler,
            },
        ],
    },
    ItemCategory,
    {
        title: "네트워크",
        key: "network",
        isCheckbox: true,
        totalAdditionalExceptionOnChangeHandler: (isSelected, tempFilters) => {
            return WalletCollectionSearchFilterExceptionOnChangeHandlers.networkFilterHandler(
                true,
                true,
                tempFilters,
            );
        },
        filterList: [
            {
                title: "폴리곤",
                key: "Polygon",
                isSelected: false,
                additionalExceptionOnChangeHandler: (
                    isSelected,
                    tempFilters,
                ) => {
                    let isSelectedPolygon = isSelected;
                    let isSelectedInApp = false;
                    tempFilters.forEach((filter) => {
                        if (filter.key === "network") {
                            filter.filterList.filter((filterListItem) => {
                                if (
                                    filterListItem.key === "In-App" &&
                                    filterListItem.isSelected
                                ) {
                                    isSelectedInApp = true;
                                }
                            });
                        }
                    });
                    return WalletCollectionSearchFilterExceptionOnChangeHandlers.networkFilterHandler(
                        isSelectedPolygon,
                        isSelectedInApp,
                        tempFilters,
                    );
                },
            },
            {
                title: "In-App",
                key: "In-App",
                isSelected: false,
                additionalExceptionOnChangeHandler: (
                    isSelected,
                    tempFilters,
                ) => {
                    let isSelectedPolygon = false;
                    let isSelectedInApp = isSelected;
                    tempFilters.forEach((filter) => {
                        if (filter.key === "network") {
                            filter.filterList.filter((filterListItem) => {
                                if (
                                    filterListItem.key === "Polygon" &&
                                    filterListItem.isSelected
                                ) {
                                    isSelectedPolygon = true;
                                }
                            });
                        }
                    });

                    return WalletCollectionSearchFilterExceptionOnChangeHandlers.networkFilterHandler(
                        isSelectedPolygon,
                        isSelectedInApp,
                        tempFilters,
                    );
                },
            },
        ],
    },
    {
        title: "토큰 표준",
        isCheckbox: true,
        key: "erc",
        filterList: [
            {
                title: "ERC-721",
                key: "721",
                isSelected: false,
            },
            {
                title: "ERC-1155",
                key: "1155",
                isSelected: false,
            },
            {
                title: "기타",
                key: "0",
                isSelected: false,
            },
        ],
    },
    {
        title: "재거래 가능 여부",
        isCheckbox: true,
        key: "transaction_bool",
        filterList: [
            {
                title: "재거래 가능",
                key: "1",
                isSelected: false,
            },
            {
                title: "귀속",
                key: "0",
                isSelected: false,
            },
        ],
    },
    {
        title: "상태",
        isCheckbox: true,
        key: "status",
        filterList: [
            {
                title: "판매중",
                key: "1",
                isSelected: false,
            },
            {
                title: "판매 대기",
                key: "0",
                isSelected: false,
            },
            {
                title: "귀속됨",
                key: "2",
                isSelected: false,
            },
            {
                title: "출시 대기",
                key: "3",
                isSelected: false,
            },
            {
                title: "검토중",
                key: "4",
                isSelected: false,
            },
            {
                title: "반려",
                key: "5",
                isSelected: false,
            },
        ],
    },
];

export const WalletCollectionHistoryFilters: FilterType[] = [
    {
        title: "내역",
        key: "brand",
        isCheckbox: false,
        subTitle: "입고",
        filterList: [
            {
                title: "구매",
                key: "3001_buy",
                isSelected: false,
            },
            {
                title: "등록",
                key: "?",
                isSelected: false,
            },
            {
                title: "수신",
                key: "??",
                isSelected: false,
            },
            {
                title: "판매",
                key: "3001_sell",
                isSelected: false,
            },
            {
                title: "사용",
                key: "??",
                isSelected: false,
            },
            {
                title: "삭제",
                key: "??",
                isSelected: false,
            },
        ],
    },
];

export const getWalletCollectionSubTab: (
    value: UserProfileType,
    isMe: boolean,
) => SubTabType[] = (rawUserProfileData: UserProfileType, isMe: boolean) => {
    return [
        {
            value:
                "보유 아이템(" +
                (rawUserProfileData.userInfo.own_item_cnt
                    ? rawUserProfileData.userInfo.own_item_cnt
                    : 0) +
                ")",
            key: COLLECTION_OWNED_TAB,
            isSelected: true,
        },
        {
            value:
                "관심 아이템(" +
                (rawUserProfileData.userInfo.liked_item_cnt
                    ? rawUserProfileData.userInfo.liked_item_cnt
                    : 0) +
                ")",
            key: COLLECTION_LIKED_TAB,
            isSelected: false,
        },
        {
            value:
                "제작 아이템(" +
                (rawUserProfileData.userInfo.created_item_cnt
                    ? rawUserProfileData.userInfo.created_item_cnt
                    : 0) +
                ")",
            key: COLLECTION_CREATED_TAB,
            isSelected: false,
        },
        {
            value:
                "비공개 아이템(" +
                (rawUserProfileData.userInfo.private_item_cnt
                    ? rawUserProfileData.userInfo.private_item_cnt
                    : 0) +
                ")",
            key: COLLECTION_PRIVATE_TAB,
            isSelected: false,
            isHide: !isMe,
        },
        {
            value: "커뮤니티 모음",
            key: COLLECTION_COMMUNITY_TAB,
            isSelected: false,
        },
        {
            value: "입출고 내역",
            key: COLLECTION_HISTORY_TAB,
            isSelected: false,
        },
    ];
};

export const getViewTypeSwitchTabList = (itemListViewType: string) => {
    return [
        {
            key: COLLECTION_DEFAULT_VIEW_TYPE,
            title: "",
            iconPath: URL_CONSTANTS.ASSET_URL + "/icon/icon-photo-frames.svg",
            isSelected: itemListViewType === COLLECTION_DEFAULT_VIEW_TYPE,
        },
        {
            key: COLLECTION_LIST_VIEW_TYPE,
            title: "",
            iconPath:
                URL_CONSTANTS.ASSET_URL + "/icon/icon-collection-list.svg",
            isSelected: itemListViewType === COLLECTION_LIST_VIEW_TYPE,
        },
    ];
};
