import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";

export const CommonDropdownContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
`;

export const CommonDropdownButtonContainer = styled.div<{
    isOnlyOne: boolean;
    hasBorder?: boolean;
    btnType?: string;
}>`
    display: flex;
    width: 100%;
    height: 40px;
    gap: 16px;
    padding: ${({ isOnlyOne }) => (isOnlyOne ? "0" : "0 12px")};
    justify-content: space-between;
    align-items: center;

    border-radius: 8px;
    background: ${({ isOnlyOne, btnType }) =>
        isOnlyOne
            ? "transparent"
            : btnType === "filled-secondary"
            ? "rgba(13, 13, 26, 0.06)"
            : "#f5f5f7"};
    box-sizing: border-box;
    cursor: ${({ isOnlyOne }) => (isOnlyOne ? "auto" : "pointer")};
    border: ${({ hasBorder }) => (hasBorder ? "1px solid #D9D9D9" : "none")};
`;

export const CommonDropdownButton = styled.div<{
    btnType?: string;
}>`
    color: ${({ btnType }) =>
        btnType === "filled-secondary" ? "#333" : "#000"};
    font-size: ${({ btnType }) =>
        btnType === "filled-secondary" ? "15px" : "14px"};
    font-style: normal;
    font-weight: ${({ btnType }) =>
        btnType === "filled-secondary" ? "500" : "400"};
    line-height: 1;
`;

export const CircleDropdownButton = styled.div`
    position: relative;
    padding: 9px 14px;
    width: calc(100% - 28px);

    border: 1px solid #d9d9d9;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    color: #333333;
    cursor: pointer;
`;

export const CircleDropdownButtonArrowIcon = styled.img`
    position: absolute;
    right: 15px;
    width: 9px;
    height: 11.5px;
`;

export const CommonDropdownButtonIcon = styled.img<{
    btnType?: string;
}>`
    width: ${({ btnType }) =>
        btnType === "filled-secondary" ? "12px" : "7.5px"};
    height: ${({ btnType }) =>
        btnType === "filled-secondary" ? "7px" : "13.5px"};
`;

export const CommonDropdownLayer = styled.div<{
    isOpen: boolean;
    itemCount: number;
}>`
    position: absolute;
    z-index: 2;

    height: ${({ isOpen, itemCount }) =>
        isOpen ? `${itemCount * 44 + 20}px` : 0};
    transition: height 300ms ease;

    overflow: hidden;
    right: 0;
    top: calc(100% + 4px);
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
`;
export const CommonDropdownLayerList = styled.div`
    padding: 10px;
`;
export const CommonDropdownLayerListItem = styled.div<{
    isSelectAble: boolean;
    isSelected: boolean;
}>`
    position: relative;
    height: 44px;
    padding: 0 10px;
    width: auto;
    white-space: nowrap;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#222")};
    opacity: ${({ isSelectAble }) => (isSelectAble ? 1 : 0.2)};
    border-radius: 6px;
    transition: 300ms ease background;
    cursor: ${({ isSelectAble }) => (isSelectAble ? "pointer" : "not-allowed")};

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isSelectAble }) =>
                isSelectAble ? "#f0f0f2" : "transparent"};
        }
    }
`;

export type DropdownListItemType = {
    key: string;
    value: string;
    isSelected: boolean;
    additionalClickHandler?: (idx: number) => void;
    isSelectAble?: boolean;
};

type Props = {
    dropdownList: DropdownListItemType[];
    setDropdownList: (value: DropdownListItemType[]) => void;
    isCircleType: boolean;
    hasBorder?: boolean;
    disabled?: boolean;
    btnType?: string;
};

const CommonDropdownBtn = (props: Props): ReactElement | null => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    useOnClickOutside(containerRef, () => {
        setIsOpen(false);
    });

    const isListLengthOne = props.dropdownList.length === 1;

    const openDropdown = () => {
        if (!isListLengthOne && !props.disabled) setIsOpen(!isOpen);
    };
    const selectedValue = props.dropdownList.find(
        (dropdownListItem) => dropdownListItem.isSelected,
    )?.value;

    const getIsSelectAble = (isSelectAble?: boolean) => {
        return typeof isSelectAble === "undefined" ? true : isSelectAble;
    };

    return (
        <CommonDropdownContainer ref={containerRef}>
            {!props.isCircleType ? (
                // 박스타입 dropdown btn
                <CommonDropdownButtonContainer
                    hasBorder={props.hasBorder}
                    onClick={openDropdown}
                    isOnlyOne={isListLengthOne || !!props.disabled}
                    btnType={props.btnType}
                >
                    <CommonDropdownButton btnType={props.btnType}>
                        {selectedValue}
                    </CommonDropdownButton>

                    {!isListLengthOne && !props.disabled && (
                        <CommonDropdownButtonIcon
                            btnType={props.btnType}
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-bottom.svg`}
                        />
                    )}
                </CommonDropdownButtonContainer>
            ) : (
                // 동그란타입 dropdown btn
                <CircleDropdownButton onClick={openDropdown}>
                    {selectedValue}
                    <CircleDropdownButtonArrowIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-more.svg`}
                    />
                </CircleDropdownButton>
            )}

            {!props.disabled && (
                <CommonDropdownLayer
                    isOpen={isOpen}
                    itemCount={props.dropdownList.length}
                >
                    <CommonDropdownLayerList>
                        {props.dropdownList.map((item, idx) => {
                            return (
                                <CommonDropdownLayerListItem
                                    key={idx}
                                    isSelected={item.isSelected}
                                    isSelectAble={getIsSelectAble(
                                        item.isSelectAble,
                                    )}
                                    onClick={() => {
                                        if (
                                            getIsSelectAble(item.isSelectAble)
                                        ) {
                                            props.setDropdownList(
                                                props.dropdownList.map(
                                                    (
                                                        dropdownListItem,
                                                        dropdownListIdx,
                                                    ) => {
                                                        if (
                                                            idx ===
                                                            dropdownListIdx
                                                        ) {
                                                            return {
                                                                ...dropdownListItem,
                                                                isSelected:
                                                                    true,
                                                            };
                                                        } else {
                                                            return {
                                                                ...dropdownListItem,
                                                                isSelected:
                                                                    false,
                                                            };
                                                        }
                                                    },
                                                ),
                                            );

                                            if (item.additionalClickHandler) {
                                                item.additionalClickHandler(
                                                    idx,
                                                );
                                            }
                                            setIsOpen(false);
                                        }
                                    }}
                                >
                                    {item.value}
                                </CommonDropdownLayerListItem>
                            );
                        })}
                    </CommonDropdownLayerList>
                </CommonDropdownLayer>
            )}
        </CommonDropdownContainer>
    );
};

export default CommonDropdownBtn;
CommonDropdownBtn.defaultProps = {
    isCircleType: false,
};
