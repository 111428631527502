import { MarketProjectItemListSearchFilterStateIconImg } from "../MarketProjectItemList";
import { SortingItemType } from "../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { FilterType } from "../../../../../common/commonUiComponent/searchFilter/SearchFilter";
import { ItemStatusInfoParams } from "../../../../../../hooks/modal/openModal/useOpenCommonStatusInfoModal";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";

export const MarketProjectItemListSortingList: () => SortingItemType[] = () => [
    {
        title: "최신 등록 순",
        key: "created_at_desc",
        isSelected: true,
    },
    {
        title: "좋아요 합 높은 순",
        key: "like_cnt_desc",
        isSelected: false,
    },
    {
        title: "좋아요 합 낮은 순",
        key: "like_cnt_asc",
        isSelected: false,
    },
    {
        title: "가격 높은 순",
        key: "price_desc",
        isSelected: false,
    },
    {
        title: "가격 낮은 순",
        key: "price_asc",
        isSelected: false,
    },
];

export const getMarketProjectItemListFilters = (
    openCommonStatusInfoModal: (value: any) => Promise<boolean>,
) => {
    const filter: FilterType[] = [
        {
            title: "판매가격",
            key: "price",
            subTitle: "ST",
            isCheckbox: true,
            filterList: [
                {
                    title: "100 미만",
                    key: "0",
                    isSelected: false,
                },
                {
                    title: "100~999",
                    key: "100",
                    isSelected: false,
                },
                {
                    title: "1,000~9,999",
                    key: "1000",
                    isSelected: false,
                },
                {
                    title: "10,000~99,999",
                    key: "10000",
                    isSelected: false,
                },
                {
                    title: "100,000 이상",
                    key: "100000",
                    isSelected: false,
                },
            ],
        },
        {
            title: "상태",
            key: "status",
            isCheckbox: true,
            subTitle: (
                <MarketProjectItemListSearchFilterStateIconImg
                    onClick={async () => {
                        await openCommonStatusInfoModal(ItemStatusInfoParams);
                    }}
                    src={URL_CONSTANTS.ASSET_URL + "/icon/icon-info.svg"}
                />
            ),
            filterList: [
                {
                    title: "판매중",
                    key: "1",
                    isSelected: false,
                },
                {
                    title: "판매 대기",
                    key: "0",
                    isSelected: false,
                },
                {
                    title: "귀속됨",
                    key: "2",
                    isSelected: false,
                },
                {
                    title: "출시 대기",
                    key: "3",
                    isSelected: false,
                },
                {
                    title: "검토중",
                    key: "4",
                    isSelected: false,
                },
                {
                    title: "반려",
                    key: "5",
                    isSelected: false,
                },
            ],
        },
    ];
    return filter;
};
