import React, { ReactElement } from "react";
import styled from "styled-components";

export const AdManageCommonHistoryColumnNameContainer = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const AdManageCommonHistoryColumnNameThumbnail = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
`;

export const AdManageCommonHistoryColumnNameInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`;

export const AdManageCommonHistoryColumnNameInfoTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #333333;
    text-align: left;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;

export const AdManageCommonHistoryColumnNameInfoPlatformTypeTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #333333;
    text-align: left;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;

export const AdManageCommonHistoryColumnNameInfoDesc = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: #fa54fa;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;
type Props = {
    isOnlyProductName: boolean;
    adProductSubName: string;
    adProductName: string;
    adThumbnail: string;
};

const AdManageCommonHistoryColumnName = (props: Props): ReactElement | null => {
    return !props.isOnlyProductName ? (
        <AdManageCommonHistoryColumnNameContainer>
            <AdManageCommonHistoryColumnNameThumbnail src={props.adThumbnail} />
            <AdManageCommonHistoryColumnNameInfoContainer>
                <AdManageCommonHistoryColumnNameInfoTitle>
                    {props.adProductName}
                </AdManageCommonHistoryColumnNameInfoTitle>
                {props.adProductSubName !== "" && (
                    <AdManageCommonHistoryColumnNameInfoDesc>
                        {props.adProductSubName}
                    </AdManageCommonHistoryColumnNameInfoDesc>
                )}
            </AdManageCommonHistoryColumnNameInfoContainer>
        </AdManageCommonHistoryColumnNameContainer>
    ) : (
        <AdManageCommonHistoryColumnNameContainer>
            <AdManageCommonHistoryColumnNameInfoTitle>
                {props.adProductName}
            </AdManageCommonHistoryColumnNameInfoTitle>
        </AdManageCommonHistoryColumnNameContainer>
    );
};

export default AdManageCommonHistoryColumnName;

AdManageCommonHistoryColumnName.defaultProps = {
    isOnlyProductName: false,
    adProductSubName: "",
    adProductName: "",
    adThumbnail: "",
};
