import { useSetAtom } from "jotai";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useMutation } from "react-query";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { ItemInventoryApi } from "../../apis/ItemInventoryApi";
import { queryClient } from "../../App";

export type OrderChangeItemInventoryGroupParams = {
    group_order: {
        item_inventory_group_idx: string;
        order_no: number;
    }[];
};

export const useOrderChangeItemInventoryGroupMutation = (
    params: OrderChangeItemInventoryGroupParams,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const pushSnackBarListItem = useSetAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => ItemInventoryApi.orderChangeGroup(params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                await queryClient.refetchQueries({
                    predicate: (query) =>
                        query.queryKey.includes("item-inventory-group-list"),
                });

                await queryClient.resetQueries({
                    predicate: (query) =>
                        query.queryKey.includes(
                            "item-inventory-belonging-group-list",
                        ),
                });
            },
        },
    );
};
