import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { extractNumbers } from "../../../../../../../../../../../utils/Utils";
import CalendarCellRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";

export const AdBidCalendarCellMobileBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarCellMobileBodyOnBid = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const adSalesInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;
    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdDailyDateData(props);

    return !isAdStop ? (
        isTodaySale ? (
            myOfferInfo ? (
                <>
                    <CalendarCellRow
                        msg={`${extractNumbers(myOfferInfo.offer_price + "")}`}
                        type={"primary-weak"}
                    />
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__dailySale__myOfferInfo__mobile"
                        }
                    />
                </>
            ) : otherOfferInfo ? (
                <>
                    <CalendarCellRow
                        msg={`${extractNumbers(
                            cellInfo.available_offer_price + "",
                        )}`}
                        type={"green"}
                    />
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__dailySale__otherOfferInfo__mobile"
                        }
                    />
                </>
            ) : myOfferSuccessInfo ? (
                <>
                    <CalendarCellRow
                        msg={`${extractNumbers(
                            myOfferSuccessInfo.offer_price + "",
                        )}`}
                        type={"primary-weak"}
                    />
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__dailySale__myOfferSuccessInfo__mobile"
                        }
                    />
                </>
            ) : otherOfferSuccessInfo ? (
                <>
                    <CalendarCellRow
                        msg={`${extractNumbers(
                            otherOfferSuccessInfo.offer_price + "",
                        )}`}
                        type={"normal"}
                    />
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__dailySale__otherOfferSuccessInfo__mobile"
                        }
                    />
                </>
            ) : (
                <>
                    <CalendarCellRow
                        msg={`${extractNumbers(
                            Math.round(adSalesInfo.ad_minimum_bid_price * 1.1) +
                                "",
                        )}`}
                        type={"green"}
                    />
                    <HiddenKey
                        id={
                            "AD_ON_BID__notAdStop__notAdDailySale__else__mobile"
                        }
                    />
                </>
            )
        ) : (
            <>
                <CalendarCellRow msg={"판매중단"} type={"danger"} />
                <HiddenKey
                    id={"AD_ON_BID__notAdStop__notAdDailySale__mobile"}
                />
            </>
        )
    ) : (
        <>
            <HiddenKey id={"AD_ON_BID__AdStop__notAdDailySale__mobile"} />
        </>
    );
};

export default AdBidCalendarCellMobileBodyOnBid;
