import { ReactElement } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../common/commonStyle/CommonButtonStyle";
import {
    AD_BUY_ANALYSIS,
    AD_BUY_HISTORY,
    AD_SALE_ANALYSIS,
    AD_SALE_HISTORY,
} from "../../../_adManageLayout/AdManageTableContentsLayout";

export const AdManageCommonHistoryAdBtnContainer = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    font-size: 14px;
    line-height: 1;
    border-radius: 8px;
    padding: 10px 0;
    width: 104px;
    height: auto;
    display: flex;
    justify-content: center;
`;

type Props = {
    type: string;
    adRoundStatus?: string;
    adRoundInvalidStatus?: string;
    clickHandler?: () => Promise<boolean> | void;
};

export const getButtonTitle = (type: string) => {
    switch (type) {
        case AD_BUY_HISTORY:
            return "입찰 템플릿";

        case AD_BUY_ANALYSIS:
            return "상세 보기";

        case AD_SALE_HISTORY:
            return "입찰 이력";

        case AD_SALE_ANALYSIS:
            return "상세 보기";

        default:
            return "";
    }
};

const AdManageCommonHistoryColumnAdBtn = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryAdBtnContainer
            btnType={
                props.type === AD_SALE_HISTORY &&
                props.adRoundStatus &&
                props.adRoundStatus === "2" &&
                props.adRoundInvalidStatus &&
                props.adRoundInvalidStatus === "0"
                    ? "filled-primary"
                    : "filled-secondary"
            }
            onClick={async () => {
                if (props.clickHandler) await props.clickHandler();
            }}
        >
            {getButtonTitle(props.type)}
        </AdManageCommonHistoryAdBtnContainer>
    );
};

export default AdManageCommonHistoryColumnAdBtn;
