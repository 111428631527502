import {atom} from "jotai";

export type CollectionItemListQueryKeyType = {
    collectionItemListQueryKey: string;
};

const CollectionCommonStateAtom = atom<CollectionItemListQueryKeyType>({
    collectionItemListQueryKey: "",
});

export default CollectionCommonStateAtom;
