import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CommonTabBox from "../../../../../../common/commonUnitComponent/commonTabBox/CommonTabBox";

export const MarketItemWarningBoxContainer = styled.div``;

export const MarketItemWarningList = styled.div``;

export const MarketItemWarningListItem = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #555555;
    margin-bottom: 10px;
`;

export const MarketItemWarningListItemLink = styled(Link)`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #555555;
    margin-bottom: 10px;

    &:focus,
    &:visited,
    &:link,
    &:active {
        color: #555555;
        text-decoration: underline;
    }
`;

type Props = {};

const MarketItemWarningBox = (props: Props): ReactElement | null => {
    const warningList = [
        "아이템 구매 전 판매자 정보, 가격, 수량, 로열티 등 등 거래 정보를 철저히 확인해야 합니다.",
        "아이템 거래 대금은 해당 브랜드의 토큰(BT)이, 거래 시 발생하는 로열티는 RP가 사용됩니다.",
        "거래가 진행중(미체결)인 경우 체결시 발생예정인 로열티가 미체결 잔액으로 변경됩니다. 미체결 잔액은 ‘플레마켓 > 거래내역’ 탭에서 거래 진행중인 상태를 확인해보세요.",
        <>
            <MarketItemWarningListItemLink
                to={"https://about.saybuzz.io/ko/policy/service"}
                target={"_blank"}
            >
                서비스 이용약관
            </MarketItemWarningListItemLink>
            에 위반되는 이용이 식별될 경우 서비스 이용에 제한이 있을 수 있으며,
            이에 따른 손실은 세이버즈가 책임지지 않습니다.
        </>,
        "세이버즈 아이템 서비스는 실시간 거래 시스템으로, 시점에 따른 거래 정보 변경이 있을 수 있습니다.",
        "브랜드 혹은 크리에이터의 사정에 따른 아이템 가치의 변동에 유의해주세요.",
        "알림 설정을 통해 더 원활한 서비스를 이용할 수 있습니다.",
        "자세한 안내는 서비스 이용약관 및 고객센터를 통하여 확인하세요.",
    ];

    return (
        <MarketItemWarningBoxContainer>
            <CommonTabBox
                iconUrl={"/icon/icon-warning.svg"}
                isDefaultOpen={true}
                isOpenSupport={false}
                tabList={[
                    {
                        title: "유의 사항",
                    },
                ]}
            >
                <MarketItemWarningList>
                    {warningList.map((warning, idx) => {
                        return (
                            <MarketItemWarningListItem key={idx}>
                                · {warning}
                            </MarketItemWarningListItem>
                        );
                    })}
                </MarketItemWarningList>
            </CommonTabBox>
        </MarketItemWarningBoxContainer>
    );
};

export default MarketItemWarningBox;
