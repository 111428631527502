import { ReactElement, useRef } from "react";
import styled from "styled-components";
import { useScrollXSlideHooks } from "../../../../hooks/ui/scroll/xScroll/useScrollXSlideHooks";

export const SubTabContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
`;

export const SubTabList = styled.div`
    display: flex;
    height: 45px;
    align-items: center;
    gap: 10px;
`;

export const SubTabListItem = styled.div<{ isSelected: boolean }>`
    position: relative;
    font-size: 15px;
    line-height: 15px;
    padding: 6px;
    font-weight: ${({ isSelected }) => (isSelected ? "700" : "400")};
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#000000")};
    cursor: pointer;
    flex-shrink: 0;
`;

export const SubTabListItemUnderLine = styled.div<{ isSelected: boolean }>`
    position: absolute;
    bottom: -9px;
    left: 0;
    width: 100%;
    border-bottom: ${({ isSelected }) =>
        isSelected ? "3px solid #FA54FA" : "none"};
`;

export const initSubTabList = (
    subTabList: SubTabType[],
    setSubTabList: (value: SubTabType[]) => void,
) => {
    setSubTabList(
        subTabList.map((subTab, idx) => {
            return {
                ...subTab,
                isSelected: idx === 0,
            };
        }),
    );
};

export type SubTabType = {
    key: string;
    value: string;
    isSelected: boolean;
    additionalClickHandler?: (value?: number) => void;
    isHide?: boolean;
};

type Props = {
    subTabList: SubTabType[];
    setSubTabList: (value: SubTabType[]) => void;
};

const SubTab = (props: Props): ReactElement | null => {
    const subTabListContainerRef = useRef<HTMLDivElement>(null);
    const { startEvent } = useScrollXSlideHooks(subTabListContainerRef);

    return (
        <SubTabContainer
            ref={subTabListContainerRef}
            onMouseDown={(e) => {
                startEvent(e.clientX);
            }}
            onTouchStart={(e) => {
                startEvent(e.touches[0].clientX);
            }}
        >
            <SubTabList>
                {props.subTabList.map((subTab, subTabIdx) => {
                    if (!subTab.isHide) {
                        return (
                            <SubTabListItem
                                onClick={() => {
                                    props.setSubTabList(
                                        props.subTabList.map(
                                            (
                                                originSubTab: SubTabType,
                                                originSubTabIdx: number,
                                            ) => {
                                                return {
                                                    ...originSubTab,
                                                    isSelected:
                                                        originSubTabIdx ===
                                                        subTabIdx,
                                                };
                                            },
                                        ),
                                    );
                                    if (subTab.additionalClickHandler) {
                                        subTab.additionalClickHandler(
                                            subTabIdx,
                                        );
                                    }
                                }}
                                isSelected={subTab.isSelected}
                                key={subTabIdx}
                            >
                                {subTab.value}
                                <SubTabListItemUnderLine
                                    isSelected={subTab.isSelected}
                                />
                            </SubTabListItem>
                        );
                    }
                })}
            </SubTabList>
        </SubTabContainer>
    );
};

export default SubTab;
