import { format } from "date-fns";
import { ReactElement, useEffect, useState } from "react";
import {
    OrderReceiptModalContainer,
    OrderReceiptModalDesc,
} from "../OrderReceiptCommonStyle";
import ModalCloseIcon from "../../../modal/modalCloseIcon/ModalCloseIcon";
import { ModalTitle } from "../../../commonUnitComponent/modal/ModalStyle";
import OrderItemReceiptBuyCompleteInfo from "./components/OrderItemReceiptBuyCompleteInfo";
import OrderItemReceiptSellCancelInfo from "./components/OrderItemReceiptSellCancelInfo";
import OrderItemReceiptSellCancelCompleteInfo from "./components/OrderItemReceiptSellCancelCompleteInfo";
import OrderItemReceiptSellCompleteInfo from "./components/OrderItemReceiptSellCompleteInfo";
import { formatMilliseconds } from "../../../../../utils/Utils";
import { PathAfterResolveType } from "../../../../../hooks/modal/useOpenModal";

export type OrderItemReceiptInfoType = {
    order_idx: string;
    product_idx: string;
    settled_quantity_sum: number;
    order_unit_price: number;
    order_quantity: number;
    order_type: string;
    order_status: string;
    ordered_at: string;
    created_at: string;
    updated_at: string;
    order_valid_until: string;
    latest_settlement_at: string;
    // 추가
    project_idx: string;
    unit_creator_royalty: number;
    unit_brand_royalty: number;
    item_brand_name: string;
    currency_point_name: string;
    cancelled_at: string;
};

type Props = {
    receiptInfo: OrderItemReceiptInfoType;
    resolve: (value: PathAfterResolveType | boolean) => void;
};

const OrderItemReceiptModal = (props: Props): ReactElement | null => {
    const [isPending, setIsPending] = useState(false);
    useEffect(() => {
        setIsPending(false);
    }, []);

    const orderType = props.receiptInfo.order_type === "3001" ? "구매" : "판매";
    const orderStatus =
        props.receiptInfo.order_status === "1"
            ? "취소"
            : props.receiptInfo.order_status === "2"
            ? "완료"
            : "취소 완료";

    const title = `아이템 ${orderType} ${orderStatus}`;
    const date = format(
        new Date(props.receiptInfo.ordered_at),
        "yyyy.MM.dd HH:mm:ss",
    );

    const setData = () => {
        // 구매 완료
        if (
            props.receiptInfo.order_type === "3001" &&
            props.receiptInfo.order_status === "2"
        ) {
            return {
                date: format(
                    new Date(props.receiptInfo.ordered_at),
                    "yyyy.MM.dd HH:mm:ss",
                ),
                component: <OrderItemReceiptBuyCompleteInfo {...props} />,
            };
        }
        // 판매중
        if (
            props.receiptInfo.order_type === "3002" &&
            props.receiptInfo.order_status === "1"
        ) {
            return {
                date:
                    "잔여 판매기간 " +
                    formatMilliseconds(
                        new Date(
                            props.receiptInfo.order_valid_until,
                        ).getTime() - new Date().getTime(),
                    ),
                component: <OrderItemReceiptSellCancelInfo {...props} />,
            };
        }

        // 판매 완료
        if (
            props.receiptInfo.order_type === "3002" &&
            props.receiptInfo.order_status === "2"
        ) {
            return {
                date: format(
                    new Date(props.receiptInfo.latest_settlement_at),
                    "yyyy.MM.dd HH:mm:ss",
                ),
                component: <OrderItemReceiptSellCompleteInfo {...props} />,
            };
        }

        // 판매 취소 완료
        if (
            props.receiptInfo.order_type === "3002" &&
            props.receiptInfo.order_status === "3"
        ) {
            return {
                date:
                    // cancelled_at이 없던 시점의 order 들은 미노출
                    new Date(props.receiptInfo.cancelled_at).getTime() -
                        new Date(props.receiptInfo.created_at).getTime() >=
                    0
                        ? "판매기간 " +
                          (Math.floor(
                              (new Date(
                                  props.receiptInfo.cancelled_at,
                              ).getTime() -
                                  new Date(
                                      props.receiptInfo.created_at,
                                  ).getTime()) /
                                  (1000 * 60 * 60 * 24),
                          ) +
                              1) +
                          "일"
                        : "",
                component: (
                    <OrderItemReceiptSellCancelCompleteInfo {...props} />
                ),
            };
        }

        return {
            date: "",
            component: <div></div>,
        };
    };
    const data = setData();

    return (
        <OrderReceiptModalContainer>
            <ModalCloseIcon resolve={props.resolve} />
            <ModalTitle>{title}</ModalTitle>
            <OrderReceiptModalDesc>{data.date}</OrderReceiptModalDesc>
            {data.component}
        </OrderReceiptModalContainer>
    );
};

export default OrderItemReceiptModal;
