import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useOnClickOutside } from "usehooks-ts";

export const ThreeDotButtonContainer = styled.div`
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ThreeDotButtonIcon = styled.img<{
    hasBorder: boolean;
    hasHoverEvent: boolean;
    size: string;
}>`
    border: ${({ hasBorder }) => (hasBorder ? "1px solid #ebebeb" : "none")};
    border-radius: 8px;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    cursor: pointer;
    transition: 300ms background ease;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ hasHoverEvent }) =>
                hasHoverEvent ? "#f5f5f5" : "transparent"};
        }
    }
`;

export const ThreeDotButtonLayer = styled.div<{
    isOpen: boolean;
    itemCount: number;
}>`
    position: absolute;
    z-index: 2;
    width: 180px;
    height: ${({ isOpen, itemCount }) =>
        isOpen ? `${itemCount * 44 + 20}px` : 0};
    transition: height 300ms ease;

    overflow: hidden;
    right: 0;
    top: calc(100% + 4px);
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
`;
export const ThreeDotButtonLayerList = styled.div`
    padding: 10px;
`;
export const ThreeDotButtonLayerListItem = styled.div`
    position: relative;
    height: 44px;
    font-weight: 500;
    padding-left: 10px;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #222222;
    border-radius: 6px;
    transition: 300ms ease background;
    cursor: pointer;

    &:hover {
        background: #f0f0f2;
    }
`;
export const ThreeDotButtonLayerGoOutIcon = styled.img`
    position: absolute;
    top: 11px;
    right: 2px;
`;

export type ThreeDotConfigType = {
    title: string;
    clickHandler: (value?: any) => any;
    isGoOutIcon: boolean;
    isShow?: boolean;
};

type Props = {
    config: ThreeDotConfigType[];
    hasBorder?: boolean;
    hasHoverEvent?: boolean;
    size?: string;
};

const ThreeDotButton = (props: Props): ReactElement | null => {
    const [isOpenThreeDotLayer, setIsOpenThreeDotLayer] = useState(false);
    const scrolledContentsThreeDotContainerRef = useRef(null);

    useOnClickOutside(scrolledContentsThreeDotContainerRef, () => {
        setIsOpenThreeDotLayer(false);
    });

    return (
        <ThreeDotButtonContainer ref={scrolledContentsThreeDotContainerRef}>
            <ThreeDotButtonIcon
                hasBorder={props.hasBorder ? props.hasBorder : false}
                hasHoverEvent={
                    props.hasHoverEvent ? props.hasHoverEvent : false
                }
                size={props.size ? props.size : "32px"}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpenThreeDotLayer(!isOpenThreeDotLayer);
                }}
                src={URL_CONSTANTS.ASSET_URL + "/icon/icon-three-dot.svg"}
            />
            <ThreeDotButtonLayer
                isOpen={isOpenThreeDotLayer}
                itemCount={
                    props.config.filter(
                        (configItem) =>
                            configItem.isShow ||
                            typeof configItem.isShow === "undefined",
                    ).length
                }
            >
                <ThreeDotButtonLayerList>
                    {props.config.map((configItem, idx) => {
                        if (
                            configItem.isShow ||
                            typeof configItem.isShow === "undefined"
                        ) {
                            return (
                                <ThreeDotButtonLayerListItem
                                    key={idx}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        configItem.clickHandler();
                                        setIsOpenThreeDotLayer(false);
                                    }}
                                >
                                    {configItem.title}
                                    {configItem.isGoOutIcon && (
                                        <ThreeDotButtonLayerGoOutIcon
                                            src={
                                                URL_CONSTANTS.ASSET_URL +
                                                "/icon/icon-go-out.svg"
                                            }
                                        />
                                    )}
                                </ThreeDotButtonLayerListItem>
                            );
                        }
                    })}
                </ThreeDotButtonLayerList>
            </ThreeDotButtonLayer>
        </ThreeDotButtonContainer>
    );
};

export default ThreeDotButton;

ThreeDotButton.defaultProps = {
    hasBorder: true,
    hasHoverEvent: true,
    size: "40px",
};
