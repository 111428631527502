import {
    AdTemplateListApiParamsType,
    AdTemplateListApiResultType,
} from "../../query/ad/template/useAdTemplateListQuery";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import { AdTemplateInfoApiResultType } from "../../query/ad/template/useAdTemplateInfoQuery";
import { AdTemplateOrderChangeParamsType } from "../../query/ad/template/useAdTemplateOrderChangeMutation";
import { AdRegisterTemplateParams } from "../../query/ad/template/useAdTemplateRegisterMutation";
import { AdAdjustTemplateParams } from "../../query/ad/template/useAdTemplateAdjustMutation";
import { TemplateNameDuplicateCheckParams } from "../../query/ad/template/useAdTemplateNameDuplicateCheckMutation";
import axios from "axios";

export const AdTemplateApi = {
    getTemplateList: async (
        params: AdTemplateListApiParamsType,
    ): Promise<AdTemplateListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/v2/ad/template/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    const data = res.data.data;
                    resolve({
                        ...data,
                        nextPageNumber:
                            params.limit &&
                            params.page_no &&
                            res.data.data.totalCount >
                                params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getTemplateInfo: async (
        template_idx: string,
    ): Promise<AdTemplateInfoApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/template/${template_idx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    changeTemplateOrder: async (
        params: AdTemplateOrderChangeParamsType,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .put(URL_CONSTANTS.API_URL + "/v2/ad/template/order", params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    registerTemplate: async (
        params: AdRegisterTemplateParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(URL_CONSTANTS.API_URL + "/v2/ad/template", params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    adjustTemplate: async (
        templateIdx: string,
        params: AdAdjustTemplateParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    URL_CONSTANTS.API_URL + "/v2/ad/template/" + templateIdx,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteTemplate: async (templateIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    URL_CONSTANTS.API_URL + "/v2/ad/template/" + templateIdx,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    checkDuplicateTemplateValue: async (
        columnKey: string,
        value: string,
        params: TemplateNameDuplicateCheckParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/template/${columnKey}/check`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                        params: {
                            ...params,
                            [columnKey]: value,
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
