import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../../common/commonStyle/CommonButtonStyle";
import { useOpenAdTemplateRegisterConfirmModal } from "../../../../../../_adTemplate/adTemplateRegisterStep/components/adTemplateRegisterStepConfirmModal/useOpenAdTemplateRegisterConfirmModal";
import { AdBidingTemplateSelectResolveType } from "../AdBidingSelectTemplateModal";

export const AdBidingSelectTemplateModalNoDataContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
`;

export const AdBidingSelectTemplateModalNoDataIcon = styled.img`
    width: 76px;
    height: 84px;
`;

export const AdBidingSelectTemplateModalNoDataTitle = styled.div`
    color: #333;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    margin-top: 24px;
`;

export const AdBidingSelectTemplateModalNoDataDesc = styled.div`
    color: #999;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px; /* 146.154% */
    margin-top: 10px;
`;

export const AdBidingSelectTemplateModalNoDataDescBold = styled.span`
    color: #666;
    font-weight: 500;
`;

export const AdBidingSelectTemplateModalNoDataBtnContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const AdBidingSelectTemplateModalNoDataBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    padding: 13px 20px;
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 76px;
`;
type Props = {
    resolve: (value: AdBidingTemplateSelectResolveType) => void;
    campaignIdx: string;
};

const AdBidingSelectTemplateModalNoData = (
    props: Props,
): ReactElement | null => {
    const location = useLocation();
    const { openAdTemplateRegisterConfirmModal, navigateTemplateRegisterPage } =
        useOpenAdTemplateRegisterConfirmModal();

    return (
        <AdBidingSelectTemplateModalNoDataContainer>
            <AdBidingSelectTemplateModalNoDataIcon
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-empty-item.svg`}
            />
            <AdBidingSelectTemplateModalNoDataTitle>
                광고 템플릿이 없습니다
            </AdBidingSelectTemplateModalNoDataTitle>
            <AdBidingSelectTemplateModalNoDataDesc>
                해당 광고 캠페인에 사용 가능한 광고 템플릿이 없습니다. <br />
                광고 이미지와 주소는{" "}
                <AdBidingSelectTemplateModalNoDataDescBold>
                    사전에 검토완료된 템플릿만
                </AdBidingSelectTemplateModalNoDataDescBold>{" "}
                사용가능합니다.
            </AdBidingSelectTemplateModalNoDataDesc>
            <AdBidingSelectTemplateModalNoDataBtnContainer>
                <AdBidingSelectTemplateModalNoDataBtn
                    btnType={"filled-primary"}
                    onClick={async () => {
                        const modalResult =
                            await openAdTemplateRegisterConfirmModal();

                        if (!modalResult.isOnlyClose) {
                            props.resolve({
                                isOnlyClose: false,
                                isAddTemplate: true,
                                isAddTemplateHasGuide: modalResult.hasGuide,
                                isTemplateSelect: false,
                            });
                        }
                    }}
                >
                    템플릿 등록 바로가기
                </AdBidingSelectTemplateModalNoDataBtn>
            </AdBidingSelectTemplateModalNoDataBtnContainer>
        </AdBidingSelectTemplateModalNoDataContainer>
    );
};

export default AdBidingSelectTemplateModalNoData;
