import React, { ReactElement } from "react";
import { useLocalStorage } from "usehooks-ts";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalDesc,
    ModalTitle,
} from "../../commonUnitComponent/modal/ModalStyle";
import ModalCloseIcon from "../modalCloseIcon/ModalCloseIcon";
import { CommonBtnStringType } from "../../commonStyle/CommonButtonStyle";

type Props = {
    resolve: (value: boolean) => void;
    title?: string;
    desc: string | ReactElement;
    buttonTitle?: string;
    buttonType?: CommonBtnStringType;
    closeBtnTitle?: string;
    isTwoBtn?: boolean;
};

const CommonConfirmModal = (props: Props): ReactElement | null => {
    const [, setPathAfterLogin] = useLocalStorage("pathAfterLogin", "/");

    const hasTitle = typeof props.title !== "undefined" && props.title !== "";
    const hasButtonTitle =
        typeof props.buttonTitle !== "undefined" && props.buttonTitle !== "";

    return (
        <ModalContainer isNoPadding={false}>
            <ModalCloseIcon resolve={props.resolve} />

            {hasTitle && <ModalTitle>{props.title}</ModalTitle>}
            <ModalDesc
                style={{
                    marginTop: hasTitle ? "16px" : "8px",
                }}
            >
                {props.desc}
            </ModalDesc>
            <ModalButtonContainer>
                {props.isTwoBtn && (
                    <ModalButton
                        btnType={"filled-secondary"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setPathAfterLogin("/");
                            props.resolve(false);
                        }}
                    >
                        {props.closeBtnTitle ? props.closeBtnTitle : "취소"}
                    </ModalButton>
                )}

                <ModalButton
                    btnType={
                        props.buttonType ? props.buttonType : "filled-primary"
                    }
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.resolve(true);
                    }}
                >
                    {hasButtonTitle ? props.buttonTitle : "확인"}
                </ModalButton>
            </ModalButtonContainer>
        </ModalContainer>
    );
};

export default CommonConfirmModal;
CommonConfirmModal.defaultProps = {
    isTwoBtn: true,
};
