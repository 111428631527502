import { useAtom } from "jotai";
import { Query, useMutation } from "react-query";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { AdApi } from "../../../apis/adApi/AdApi";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { queryClient } from "../../../App";

export type SetAdDailyStatusParamsType = {
    ad_sales_date: string;
    ad_sales_daily_status: string;
};

export const useSetAdDailyStatusMutation = (
    adIdx: string,
    params: SetAdDailyStatusParamsType,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(() => AdApi.setAdDailyState(adIdx, params), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
        onSuccess: async () => {
            pushSnackBarListItem({
                title: "",
                desc:
                    params.ad_sales_daily_status !== "0"
                        ? "판매 중단이 되었습니다."
                        : "판매가 재개되었습니다.",
                iconUrl:
                    URL_CONSTANTS.ASSET_URL +
                    "/icon/icon-checked-in-circle.svg",
                confirmBtnTitle: "",
            });
            await queryClient.refetchQueries({
                predicate: (query: Query) =>
                    query.queryKey.includes(`my-ad-${adIdx}-daily-list`),
            });
        },
    });
};
