import { ReactElement } from "react";
import styled, { css } from "styled-components";
import { ItemListItemType } from "../../../../../../../../../../../query/item/useItemListQuery";
import { CommonTC } from "../../../../../../../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../../../../../../../common/commonUnitComponent/commonTable/CommonTable";
import WalletCollectionHubListTypeItemListTitle from "./components/WalletCollectionHubListTypeItemListTitle";
import {
    getItemPrice,
    getItemStatusKR,
    getItemType,
} from "../../../../../../../walletCollectionItemListItem/WalletCollectionItem";
import WalletCollectionHubListTypeItemLikeBtn from "./components/WalletCollectionHubListTypeItemLikeBtn";
import WalletCollectionHubListTypeItemRepBtn from "./components/WalletCollectionHubListTypeItemRepBtn";
import WalletCollectionHubListTypeItemThreeDotBtn from "./components/WalletCollectionHubListTypeItemThreeDotBtn";
import WalletCollectionHubListTypeMobileItemList from "./components/WalletCollectionHubListTypeMobileItemList";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { COLLECTION_PRIVATE_TAB } from "../../../../../../WalletCollectionHub";
import { useIsMe } from "../../../../../../../../../../../hooks/common/useIsMe";

export const WalletCollectionHubListTypeItemListContainer = styled.div`
    margin-top: 24px;
    overflow: visible;

    @media ${(props) => props.theme.tabletM} {
        margin-top: 32px;
    }

    @media ${(props) => props.theme.mobileL} {
        margin-top: 24px;
    }
`;

export const WalletCollectionHubListTypeItemListPcContainer = styled.div`
    display: block;

    @media ${(props) => props.theme.tabletM} {
        display: none;
    }
`;

export const WalletCollectionHubListTypeItemListMobileContainer = styled.div`
    display: none;

    @media ${(props) => props.theme.tabletM} {
        display: block;
    }
`;

const ItemNameColumnWidth = css`
    width: 240px;
    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

const ItemCountColumnWidth = css`
    width: 64px;
    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

const PriceColumnWidth = css`
    width: 110px;
    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

const ItemStatusColumnWidth = css`
    width: 72px;
    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

const ItemTypeColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

const TokenColumnWidth = css`
    width: 100px;
    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

const LikeCountColumnWidth = css`
    width: 120px;
    @media ${(props) => props.theme.tabletM} {
        width: auto;
    }
`;

const TitleItemColumnWidth = css`
    width: 110px;
    @media ${(props) => props.theme.tabletM} {
        width: auto;
    }
`;

const ThreeDotColumnWidth = css`
    width: 40px;
    @media ${(props) => props.theme.tabletM} {
        width: auto;
    }
`;

export const WalletCollectionHubListTypeItemListItemNameContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${ItemNameColumnWidth};
`;

export const WalletCollectionHubListTypeItemListItemCountContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${ItemCountColumnWidth};
`;

export const WalletCollectionHubListTypeItemListPriceColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${PriceColumnWidth};
`;

export const WalletCollectionHubListTypeItemListItemStatusContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${ItemStatusColumnWidth};
`;

export const WalletCollectionHubListTypeItemListItemTypeContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${ItemTypeColumnWidth};
`;

export const WalletCollectionHubListTypeItemListTokenContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${TokenColumnWidth};
`;

export const WalletCollectionHubListTypeItemListLikeCountContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${LikeCountColumnWidth};
`;

export const WalletCollectionHubListTypeItemListTitleItemContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${TitleItemColumnWidth};
`;
export const WalletCollectionHubListTypeItemListThreeDotContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${ThreeDotColumnWidth};
`;

export const WalletCollectionHubListTypeItemListHeaderColumn = styled.div`
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
`;

export const WalletCollectionHubListTypeItemListBodyColumn = styled.div<{
    isBold?: boolean;
}>`
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    color: ${({ isBold }) => (isBold ? "#000" : "#555")};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: ${({ isBold }) => (isBold ? 500 : 400)};
    line-height: 100%; /* 14px */

    @media ${(props) => props.theme.tabletM} {
        height: auto;
    }
`;

type Props = {
    userIdx: string;
    selectedSubKey: string;
    collectionItemList: ItemListItemType[];
};

const WalletCollectionHubListTypeItemList = (
    props: Props,
): ReactElement | null => {
    const isMe = useIsMe();

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "itemNameColumn",
            component: (
                <WalletCollectionHubListTypeItemListItemNameContainer
                    textAlign={"center"}
                    key={"itemNameColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        아이템
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListItemNameContainer>
            ),
        },
        {
            key: "itemCountColumn",
            component: (
                <WalletCollectionHubListTypeItemListItemCountContainer
                    textAlign={"center"}
                    key={"itemCountColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        수량
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListItemCountContainer>
            ),
        },
        {
            key: "priceColumn",
            component: (
                <WalletCollectionHubListTypeItemListPriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        가격 (ST)
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListPriceColumnContainer>
            ),
        },
        {
            key: "itemStatusColumn",
            component: (
                <WalletCollectionHubListTypeItemListItemStatusContainer
                    textAlign={"center"}
                    key={"itemStatusColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        판매 상태
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListItemStatusContainer>
            ),
        },
        {
            key: "itemTypeColumn",
            component: (
                <WalletCollectionHubListTypeItemListItemTypeContainer
                    textAlign={"center"}
                    key={"itemTypeColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        아이템 종류
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListItemTypeContainer>
            ),
        },
        {
            key: "itemTokenColumn",
            component: (
                <WalletCollectionHubListTypeItemListTokenContainer
                    textAlign={"center"}
                    key={"itemTokenColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        토큰 종류
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListTokenContainer>
            ),
        },
        {
            key: "likeCountColumn",
            component: (
                <WalletCollectionHubListTypeItemListLikeCountContainer
                    textAlign={"center"}
                    key={"likeCountColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        좋아요
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListLikeCountContainer>
            ),
        },
        {
            key: "titleItemColumn",
            component: (
                <WalletCollectionHubListTypeItemListTitleItemContainer
                    textAlign={"center"}
                    key={"titleItemColumn"}
                >
                    <WalletCollectionHubListTypeItemListHeaderColumn>
                        대표 아이템
                    </WalletCollectionHubListTypeItemListHeaderColumn>
                </WalletCollectionHubListTypeItemListTitleItemContainer>
            ),
            isHide:
                props.selectedSubKey === COLLECTION_PRIVATE_TAB ||
                !isMe(props.userIdx),
        },
        {
            key: "threeDotColumn",
            component: (
                <WalletCollectionHubListTypeItemListThreeDotContainer
                    textAlign={"center"}
                    key={"threeDotColumn"}
                ></WalletCollectionHubListTypeItemListThreeDotContainer>
            ),
            isHide: !isMe(props.userIdx),
        },
    ];

    const data = props.collectionItemList.map((item) => {
        return {
            itemNameColumn: (
                <WalletCollectionHubListTypeItemListItemNameContainer
                    textAlign={"left"}
                    key={"itemNameColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        <WalletCollectionHubListTypeItemListTitle
                            itemName={item.item_name}
                            itemThumbnailPath={item.item_thumbnail_full_path}
                            projectName={item.project_name}
                        />
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListItemNameContainer>
            ),
            itemCountColumn: (
                <WalletCollectionHubListTypeItemListItemCountContainer
                    textAlign={"center"}
                    key={"itemCountColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        {numberToStringWithComma(item.own_cnt || 0)}
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListItemCountContainer>
            ),
            priceColumn: (
                <WalletCollectionHubListTypeItemListPriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn
                        isBold={true}
                    >
                        {getItemPrice(
                            item.item_status,
                            item.low_price,
                            item.last_transaction_price,
                            item.project_currency,
                        )}
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListPriceColumnContainer>
            ),
            itemStatusColumn: (
                <WalletCollectionHubListTypeItemListItemStatusContainer
                    textAlign={"center"}
                    key={"itemStatusColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        {getItemStatusKR(item.item_status)}
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListItemStatusContainer>
            ),
            itemTypeColumn: (
                <WalletCollectionHubListTypeItemListItemTypeContainer
                    textAlign={"center"}
                    key={"itemTypeColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        {getItemType(item.inventory_type)}
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListItemTypeContainer>
            ),
            itemTokenColumn: (
                <WalletCollectionHubListTypeItemListTokenContainer
                    textAlign={"center"}
                    key={"itemTokenColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        {item.project_network}
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListTokenContainer>
            ),
            likeCountColumn: (
                <WalletCollectionHubListTypeItemListLikeCountContainer
                    textAlign={"center"}
                    key={"likeCountColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        <WalletCollectionHubListTypeItemLikeBtn
                            userIdx={props.userIdx}
                            itemIdx={item.item_idx}
                            isLike={item.like_bool}
                            likeCount={item.like_cnt}
                        />
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListLikeCountContainer>
            ),
            titleItemColumn: (
                <WalletCollectionHubListTypeItemListTitleItemContainer
                    textAlign={"center"}
                    key={"titleItemColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        <WalletCollectionHubListTypeItemRepBtn
                            userIdx={props.userIdx}
                            itemIdx={item.item_idx}
                            isTitleItem={item.title_bool}
                        />
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListTitleItemContainer>
            ),
            threeDotColumn: (
                <WalletCollectionHubListTypeItemListThreeDotContainer
                    textAlign={"center"}
                    key={"threeDotColumn"}
                >
                    <WalletCollectionHubListTypeItemListBodyColumn>
                        <WalletCollectionHubListTypeItemThreeDotBtn
                            userIdx={props.userIdx}
                            selectedSubKey={props.selectedSubKey}
                            item={item}
                        />
                    </WalletCollectionHubListTypeItemListBodyColumn>
                </WalletCollectionHubListTypeItemListThreeDotContainer>
            ),
        };
    });

    const navigate = useNavigate();

    return (
        <WalletCollectionHubListTypeItemListContainer>
            <WalletCollectionHubListTypeItemListPcContainer>
                <CommonTable
                    id={"wallet-collection-item-list-list-type"}
                    tableHeaderData={tableHeaderData}
                    tableBodyData={data}
                    tableBodyHeight={"100%"}
                    rowHoverColor={"#F5F5F7"}
                    onClickHandler={(idx: number) => {
                        navigate(
                            `/market/${props.collectionItemList[idx].project_idx}/${props.collectionItemList[idx].item_idx}`,
                        );
                    }}
                    hasScrollY={false}
                />
            </WalletCollectionHubListTypeItemListPcContainer>
            <WalletCollectionHubListTypeItemListMobileContainer>
                <WalletCollectionHubListTypeMobileItemList
                    userIdx={props.userIdx}
                    selectedSubKey={props.selectedSubKey}
                    tableHeaderData={tableHeaderData}
                    tableBodyData={data}
                    collectionItemList={props.collectionItemList}
                />
            </WalletCollectionHubListTypeItemListMobileContainer>
        </WalletCollectionHubListTypeItemListContainer>
    );
};

export default WalletCollectionHubListTypeItemList;
