import {ReactElement, useRef} from "react";
import styled from "styled-components";
import {onErrorImg} from "../../../../../utils/Utils";
import {TitleItemListItemType} from "../../../../../query/item/titleItem/useTitleItemListQuery";

export const MiniProfileModalItemListItemContainer = styled.div`
    width: calc(100% - 8px);
    height: inherit;
    margin-right: 8px;
`;
export const MiniProfileModalItemListItemDummyArea = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #f5f5f7;

    ::after {
        display: block;
        content: "";
        padding-bottom: 100%;
    }
`;

export const MiniProfileModalItemListItemThumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: all 300ms ease-in-out;
`;

type Props = {
    data: TitleItemListItemType;
};

const MiniProfileModalItemListItem = (props: Props): ReactElement | null => {
    const dummyAreaRef = useRef<HTMLDivElement>(null);
    return (
        <MiniProfileModalItemListItemContainer>
            {props.data.isDummy ? (
                <MiniProfileModalItemListItemDummyArea ref={dummyAreaRef} />
            ) : (
                <MiniProfileModalItemListItemThumbnail
                    src={props.data.item_thumbnail_full_path}
                    onError={onErrorImg}
                />
            )}
        </MiniProfileModalItemListItemContainer>
    );
};

export default MiniProfileModalItemListItem;
