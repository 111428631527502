import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SubSearch from "../subSearch/SubSearch";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import Scrollbar from "../scrollbar/Scrollbar";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";
import ApiResBoundary from "../../apiResBoundary/ApiResBoundary";
import HolderListItem from "./HolderListItem";
import Spinner from "../../commonUnitComponent/spinner/Spinner";
import ModalCloseIcon from "../../modal/modalCloseIcon/ModalCloseIcon";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";
import { SCROLL_BAR_NORMAL_TYPE } from "../scrollbar/ScrollbarType";

export const HolderListWrapper = styled.div`
    width: 432px;
    padding: 24px 24px 0 24px;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 32px);
        padding: 0 16px 0 16px;
        height: 100%;
    }
`;

export const HolderListTitleContainer = styled.div<{ isOneTab: boolean }>`
    height: ${({ isOneTab }) => (isOneTab ? "32px" : "50px")};
    display: flex;
    align-items: flex-start;
`;

export const HolderListTitleSolo = styled.div`
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    color: #000000;
    margin-bottom: 12px;
`;

export const HolderListMultipleTitleContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const HolderListMultipleTitle = styled.div<{ isSelected: boolean }>`
    height: ${({ isSelected }) => (isSelected ? "49px" : "50px")};
    width: 100%;
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
    font-size: 18px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#888888")};
    border-bottom: ${({ isSelected }) =>
            isSelected ? "3px #FA54FA" : "1px #e5e5e5"}
        solid;

    cursor: pointer;
    @media ${(props) => props.theme.mobileL} {
        font-size: 16px;
    }
`;

export const HolderListSearchContainer = styled.div`
    margin-top: 12px;
`;

export const HolderListInfoIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 2px;
`;
export const HolderListInfoMsg = styled.div`
    margin-top: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    display: flex;
    align-items: center;

    color: #777777;
`;

export const HolderListContainer = styled.div<{ vh: number }>`
    margin-top: 10px;
    position: relative;
    height: ${({ vh }) => `calc(${100 * vh}px - 372px)`};
    max-height: 408px;

    @media ${(props) => props.theme.mobileL} {
        max-height: none;
        height: 408px;
    }
`;

export const HolderNoListContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 403px;

    color: #555;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
`;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    titleList: string[];
    infoMsg?: string[];
    noDataMsg?: string[];
    selectedTabIdx: number;
    searchInput?: string;
    setSearchInput?: (value: string) => void;
    getDataQueryResult: any[];
    followAfterAction?: () => Promise<void>;
    validationCheck: {
        isPassed: boolean;
        id: string;
        msg: string;
    };
};

const HolderList = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const isOneTab = props.titleList.length <= 1;

    function spreadListPages(pages: any[]) {
        return pages
            .map((result: any) => result.ownerList || result.followList)
            .reduce((prev: any, next: any) => prev.concat(next));
    }

    const [selectedTabIdx, setSelectedTabIdx] = useState(props.selectedTabIdx);

    const selectedGetDataQueryResult = props.getDataQueryResult[selectedTabIdx];
    useEffect(() => {
        selectedGetDataQueryResult.refetch();
    }, [selectedTabIdx]);

    const hasNextPageRef = useRef(selectedGetDataQueryResult.hasNextPage);

    useEffect(() => {
        hasNextPageRef.current = selectedGetDataQueryResult.hasNextPage;
    }, [selectedGetDataQueryResult.hasNextPage]);

    return (
        <HolderListWrapper>
            <ModalCloseIcon resolve={props.resolve} />
            <HolderListTitleContainer isOneTab={isOneTab}>
                {props.titleList.length === 1 && (
                    <HolderListTitleSolo>
                        {props.titleList[0]}
                    </HolderListTitleSolo>
                )}
                {props.titleList.length === 2 && (
                    <HolderListMultipleTitleContainer>
                        {props.titleList.map((title, idx) => {
                            return (
                                <HolderListMultipleTitle
                                    key={idx}
                                    isSelected={selectedTabIdx === idx}
                                    onClick={() => {
                                        setSelectedTabIdx(idx);
                                    }}
                                >
                                    {title}
                                </HolderListMultipleTitle>
                            );
                        })}
                    </HolderListMultipleTitleContainer>
                )}
            </HolderListTitleContainer>
            <HolderListSearchContainer>
                <SubSearch
                    searchInput={props.searchInput ? props.searchInput : ""}
                    setSearchInput={
                        props.setSearchInput ? props.setSearchInput : () => {}
                    }
                    placeholder={"닉네임 및 아이디를 입력해주세요."}
                    validationCheck={props.validationCheck}
                />
                {props.infoMsg ? (
                    <HolderListInfoMsg>
                        <HolderListInfoIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-info.svg`}
                        />
                        {props.infoMsg[selectedTabIdx]}
                    </HolderListInfoMsg>
                ) : (
                    ""
                )}
            </HolderListSearchContainer>
            <HolderListContainer vh={vh}>
                <Scrollbar
                    id={"holder-list"}
                    type={SCROLL_BAR_NORMAL_TYPE}
                    infiniteScrollCallback={async (showSpinner: () => void) => {
                        if (hasNextPageRef.current) {
                            showSpinner();
                            await selectedGetDataQueryResult.fetchNextPage();
                        }
                    }}
                >
                    <ApiResBoundary
                        isNoData={
                            selectedGetDataQueryResult.data.pages[0]
                                .totalCount === 0
                        }
                        noDataFallback={
                            props.searchInput === "" ? (
                                <HolderNoListContainer>
                                    {props.noDataMsg
                                        ? props.noDataMsg[selectedTabIdx]
                                        : "보유한 유저가 없습니다."}
                                </HolderNoListContainer>
                            ) : (
                                <HolderNoListContainer>
                                    검색 결과가 없습니다.
                                </HolderNoListContainer>
                            )
                        }
                        isLoading={
                            selectedGetDataQueryResult.isLoading
                                ? selectedGetDataQueryResult.isLoading
                                : false
                        }
                        loadingFallback={
                            <HolderNoListContainer>
                                <Spinner
                                    borderSize={7}
                                    height={30}
                                    width={30}
                                    color={"black"}
                                />
                            </HolderNoListContainer>
                        }
                        error={selectedGetDataQueryResult.error}
                    >
                        <>
                            {spreadListPages(
                                selectedGetDataQueryResult.data.pages,
                            ).map((item: any, idx: number) => {
                                return (
                                    <HolderListItem
                                        key={idx}
                                        idx={idx}
                                        data={item}
                                        refetchHolderList={
                                            selectedGetDataQueryResult.refetch
                                        }
                                        followAfterAction={
                                            props.followAfterAction
                                                ? props.followAfterAction
                                                : undefined
                                        }
                                        resolve={props.resolve}
                                    />
                                );
                            })}
                        </>
                    </ApiResBoundary>
                </Scrollbar>
            </HolderListContainer>
        </HolderListWrapper>
    );
};

export default HolderList;

HolderList.defaultProps = {
    validationCheck: {
        isPassed: true,
        id: "",
        msg: "",
    },
};
