import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
    ColorType,
    createChart,
    CrosshairMode,
    MouseEventParams,
} from "lightweight-charts";
import { format } from "date-fns";
import { numberToStringWithComma } from "../../../../utils/Utils";

export const ChartTradingViewWrapper = styled.div`
    position: relative;
    width: 100%;
    height: inherit;
    min-height: inherit;
    overflow: visible;
`;

export const ChartTradingViewContainer = styled.div`
    width: 100%;
    height: inherit;
    min-height: inherit;
    overflow: visible;
`;

export const ChartTradingViewTooltip = styled.div<{ isShow: boolean }>`
    display: ${({ isShow }) => (isShow ? "block" : "none")};
    position: absolute;

    box-sizing: border-box;
    fontsize: 12px;
    text-align: left;
    z-index: 1000;
    pointer-events: none;
    border: none;

    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    padding: 16px 20px;

    font-family: "Noto Sans KR", serif;
`;
export const ChartTradingViewTooltipDate = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #777777;
`;

export const ChartTradingViewTooltipValueLegend = styled.div`
    width: 10px;
    height: 10px;
    margin-right: 6px;
`;

export const ChartTradingViewTooltipValue = styled.div`
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #333333;
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
`;

export type ChartTradingViewDataType = {
    time: string | number;
    value: number;
};
type Props = {
    data: ChartTradingViewDataType[];
    unit?: string;
    lineColor?: string;
    backgroundColor?: string;
    title: string;
    xAxisTimeFormat: string;
    isXAxisFix: boolean;
};

const ChartTradingView = (props: Props): ReactElement | null => {
    const colors = {
        backgroundColor: props.backgroundColor,
        lineColor: props.lineColor,
        textColor: "#888888",
        areaTopColor: props.lineColor + "20",
        areaBottomColor: "rgba(250, 84, 250, 0)",
    };
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartTooltipRef = useRef<HTMLDivElement>(null);

    const [isShowTooltip, setIsShowTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState({
        date: "",
        value: "",
    });
    const [tooltipPosition, setTooltipPosition] = useState({
        top: 0,
        left: 0,
    });

    useEffect(() => {
        let chart: any = null;
        if (chartContainerRef.current) {
            chart = createChart(chartContainerRef.current, {
                layout: {
                    background: {
                        type: ColorType.Solid,
                        color: colors.backgroundColor,
                    },
                    fontFamily: "Noto Sans KR",
                    textColor: "#888888",
                    fontSize: 11,
                },
                rightPriceScale: {
                    visible: true,
                    alignLabels: false,
                    mode: 0,
                    borderVisible: false,
                    textColor: colors.textColor,
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.1,
                    },
                    autoScale: false,
                },
                timeScale: {
                    borderVisible: false,
                    rightOffset: 0,
                    fixRightEdge: true,
                    fixLeftEdge: true,
                    tickMarkFormatter: (time: any) => {
                        if (typeof time === "string") {
                            return time;
                        } else {
                            return format(
                                new Date(time),
                                props.xAxisTimeFormat,
                            );
                        }
                    },
                },
                handleScale: {
                    axisDoubleClickReset: false,
                },
                overlayPriceScales: {
                    borderColor: "red",
                },
                grid: {
                    vertLines: {
                        visible: false,
                        color: "#DBDBDB",
                    },
                },
                width: chartContainerRef.current?.clientWidth,
                height: chartContainerRef.current?.clientHeight,
                crosshair: {
                    // Change mode from default 'magnet' to 'normal'.
                    // Allows the crosshair to move freely without snapping to datapoints
                    mode: CrosshairMode.Magnet,

                    // Vertical crosshair line (showing Date in Label)
                    vertLine: {
                        visible: false,
                        labelVisible: false,
                    },
                },
            });

            chart.timeScale().applyOptions({
                borderColor: "#71649C",
                color: "red",
            });

            const series = chart.addAreaSeries({
                topColor: colors.areaTopColor,
                bottomColor: colors.areaBottomColor,
                lineColor: colors.lineColor,
                lineWidth: 2,
            });
            try {
                series.setData(
                    props.data.filter(
                        (arr, index, callback) =>
                            index ===
                            callback.findIndex((t) => t.time === arr.time),
                    ),
                );
            } catch (e) {
                console.info(e);
                series.setData([]);
            }
            chart.timeScale().fitContent();
            const container = document.getElementById("container");

            const toolTipMargin = 15;

            // Create and style the tooltip html element
            chart.subscribeCrosshairMove((param: MouseEventParams) => {
                const chartTooltipWidth = chartTooltipRef.current?.clientWidth
                    ? chartTooltipRef.current?.clientWidth
                    : 0;
                const chartTooltipHeight = chartTooltipRef.current?.clientHeight
                    ? chartTooltipRef.current?.clientHeight
                    : 0;
                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.y < 0
                ) {
                    setIsShowTooltip(false);
                } else {
                    if (chartContainerRef.current) {
                        setIsShowTooltip(true);

                        // const dateStr = format(param.time, "yyyy.MM.dd");
                        const data: any = param.seriesData.get(series);
                        if (!data) {
                            return;
                        }
                        const date = data.time ? data.time : 0;
                        const value = data.value ? data.value : 0;
                        const coordinate = series.priceToCoordinate(value);
                        let shiftedCoordinate = param.point.x - 50;

                        if (coordinate === null) {
                            return;
                        }
                        shiftedCoordinate = Math.max(
                            0,
                            Math.min(
                                chartContainerRef.current?.clientWidth -
                                    chartTooltipWidth,
                                shiftedCoordinate,
                            ),
                        );
                        const coordinateY: number =
                            coordinate - chartTooltipHeight - toolTipMargin > 0
                                ? coordinate -
                                  chartTooltipHeight -
                                  toolTipMargin
                                : Math.max(
                                      0,
                                      Math.min(
                                          chartContainerRef.current
                                              ?.clientHeight -
                                              chartTooltipHeight -
                                              toolTipMargin,
                                          coordinate + toolTipMargin,
                                      ),
                                  );
                        setTooltipText({
                            date: format(new Date(date), "yyyy.MM.dd HH:mm:ss"),
                            value: `${numberToStringWithComma(value)}${
                                props.unit ? " " + props.unit : ""
                            }`,
                        });
                        setTooltipPosition({
                            top: coordinateY,
                            left: shiftedCoordinate,
                        });
                    }
                }
            });
        }
        const handleResize = () => {
            chart.applyOptions({
                width: chartContainerRef.current?.clientWidth,
                height: chartContainerRef.current?.clientHeight,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);

            if (chart !== null) chart.remove();
        };
    }, [props.data]);

    return (
        <ChartTradingViewWrapper>
            <ChartTradingViewContainer ref={chartContainerRef} />
            <ChartTradingViewTooltip
                ref={chartTooltipRef}
                isShow={isShowTooltip}
                style={{
                    top: tooltipPosition.top,
                    left: tooltipPosition.left,
                }}
            >
                <ChartTradingViewTooltipDate>
                    {tooltipText.date}
                </ChartTradingViewTooltipDate>
                <ChartTradingViewTooltipValue>
                    <ChartTradingViewTooltipValueLegend
                        style={{
                            background: props.lineColor,
                        }}
                    />
                    {props.title} : {tooltipText.value}
                </ChartTradingViewTooltipValue>
            </ChartTradingViewTooltip>
        </ChartTradingViewWrapper>
    );
};

export default ChartTradingView;

ChartTradingView.defaultProps = {
    lineColor: "#FA54FA",
    backgroundColor: "transparent",
    xAxisTimeFormat: "MM.dd",
    isXAxisFix: false,
};
