import { ReactElement, useEffect } from "react";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";

type Props = {};

const ViewHeight = (props: Props): ReactElement | null => {
    const [, setVh] = useAtom(viewHeightAtom);
    const handleResize = () => {
        const tempVh = window.innerHeight * 0.01;
        setVh(tempVh);
        document.documentElement.style.setProperty("--vh", `${tempVh}px`);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return null;
};

export default ViewHeight;
