import { ReactElement } from "react";
import styled from "styled-components";

export const AdManageCommonHistoryColumnAdRoundStatusContainer = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #333333;
`;

type Props = {
    adDailyStatusKR: string;
};

const AdManageCommonHistoryColumnAdStatus = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnAdRoundStatusContainer>
            {props.adDailyStatusKR}
        </AdManageCommonHistoryColumnAdRoundStatusContainer>
    );
};

export default AdManageCommonHistoryColumnAdStatus;
