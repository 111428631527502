import React, { ReactElement } from "react";
import styled from "styled-components";
import Nickname from "../../../../../common/commonUnitComponent/nickname/Nickname";

export const AdManageCommonHistoryColumnBidderContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #555555;
`;

type Props = {
    nickname: string;
    userIdx: string;
    isVerified: boolean;
};

const AdManageCommonHistoryColumnBidder = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnBidderContainer>
            <Nickname
                userIdx={props.userIdx}
                nickname={props.nickname}
                isShowVerifiedIcon={true}
                isVerified={props.isVerified}
            />
        </AdManageCommonHistoryColumnBidderContainer>
    );
};

export default AdManageCommonHistoryColumnBidder;
