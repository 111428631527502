import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../common/commonUnitComponent/commonTable/CommonTable";
import { CommonTC } from "../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import MarketHistoryTableBodyColumnTitle from "../../../../market/marketHistory/components/marketHistoryTableComponent/MarketHistoryTableBodyColumnTitle";
import { format } from "date-fns";
import WalletCollectionMobileHistoryItem from "./components/WalletCollectionMobileHistoryItem";
import MoreButton from "../../../../common/commonUnitComponent/moreButton/MoreButton";
import {
    CollectionExchangeListItemType,
    spreadCollectionExchangeList,
    useGetCollectionExchangeListQuery,
} from "../../../../../query/market/item/useGetCollectionExchangeListQuery";
import { DateRange } from "react-day-picker";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";

export const WalletCollectionHubHistoryContainer = styled.div`
    margin-top: 40px;
`;

export const WalletCollectionHubHistoryPcContainer = styled.div`
    display: block;
    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const WalletCollectionHubHistoryMobileContainer = styled.div`
    display: none;
    @media ${(props) => props.theme.mobileL} {
        display: block;
    }
`;

export const WalletCollectionHubHistoryMoreBtnWrapper = styled.div`
    margin-top: 24px;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const WalletCollectionHubHistoryMoreBtnContainer = styled.div`
    width: 160px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const WalletCollectionHubHistoryTableHeaderColumn = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 10px 0;
    @media ${(props) => props.theme.mobileL} {
        color: #777;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        padding: 0;
    }
`;

export const WalletCollectionHubHistoryTableBodyColumn = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #555555;
    padding: 13px 0;
    gap: 3px;

    @media ${(props) => props.theme.mobileL} {
        color: #555;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        padding: 0;
        flex-direction: row;
    }
`;

const ItemColumnWidth = css`
    width: 320px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;
const TypeColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;
const HistoryColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;
const CountColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;
const DateColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;
const OrderIdColumnWidth = css`
    width: 80px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const WalletCollectionHubHistoryItemColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${ItemColumnWidth};
`;
export const WalletCollectionHubHistoryTypeColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${TypeColumnWidth};
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
        margin-top: 10px;
    }
`;
export const WalletCollectionHubHistoryHistoryColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${HistoryColumnWidth};
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
    }
`;
export const WalletCollectionHubHistoryCountColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${CountColumnWidth};
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
    }
`;
export const WalletCollectionHubHistoryDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${DateColumnWidth};
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
    }
`;
export const WalletCollectionHubHistoryOrderIdColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    word-break: break-all;
    ${OrderIdColumnWidth};
    @media ${(props) => props.theme.mobileL} {
        justify-content: left;
    }
`;

type Props = {
    userIdx: string;
    selectedDateRange: DateRange;
};

const WalletCollectionHubHistory = (props: Props): ReactElement | null => {
    const {
        data: rawCollectionExchangeList,
        isLoading: isCollectionExchangeListLoading,
        error: errorCollectionExchangeList,
        hasNextPage: hasCollectionExchangeListNextPage,
        fetchNextPage: fetchCollectionExchangeListNextPage,
    } = useGetCollectionExchangeListQuery(props.userIdx, {
        limit: 20,
        page_no: 1,
        filter_type: "all",
        sort_type: "created_at_desc",
        exchanged_at_from: format(
            props.selectedDateRange.from ?? new Date(),
            "yyyy-MM-dd",
        ),
        exchanged_at_to: format(
            props.selectedDateRange.to ?? new Date(),
            "yyyy-MM-dd",
        ),
    });
    const collectionExchangeList = spreadCollectionExchangeList(
        rawCollectionExchangeList.pages,
    );

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "itemColumn",
            component: (
                <WalletCollectionHubHistoryItemColumnContainer
                    textAlign={"center"}
                    key={"itemColumn"}
                >
                    <WalletCollectionHubHistoryTableHeaderColumn>
                        아이템
                    </WalletCollectionHubHistoryTableHeaderColumn>
                </WalletCollectionHubHistoryItemColumnContainer>
            ),
        },
        {
            key: "typeColumn",
            component: (
                <WalletCollectionHubHistoryTypeColumnContainer
                    textAlign={"center"}
                    key={"typeColumn"}
                >
                    <WalletCollectionHubHistoryTableHeaderColumn>
                        입출고 구분
                    </WalletCollectionHubHistoryTableHeaderColumn>
                </WalletCollectionHubHistoryTypeColumnContainer>
            ),
        },
        {
            key: "historyColumn",
            component: (
                <WalletCollectionHubHistoryHistoryColumnContainer
                    textAlign={"center"}
                    key={"historyColumn"}
                >
                    <WalletCollectionHubHistoryTableHeaderColumn>
                        내역
                    </WalletCollectionHubHistoryTableHeaderColumn>
                </WalletCollectionHubHistoryHistoryColumnContainer>
            ),
        },
        {
            key: "countColumn",
            component: (
                <WalletCollectionHubHistoryCountColumnContainer
                    textAlign={"center"}
                    key={"countColumn"}
                >
                    <WalletCollectionHubHistoryTableHeaderColumn>
                        수량
                    </WalletCollectionHubHistoryTableHeaderColumn>
                </WalletCollectionHubHistoryCountColumnContainer>
            ),
        },
        {
            key: "dateColumn",
            component: (
                <WalletCollectionHubHistoryDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    <WalletCollectionHubHistoryTableHeaderColumn>
                        일시
                    </WalletCollectionHubHistoryTableHeaderColumn>
                </WalletCollectionHubHistoryDateColumnContainer>
            ),
        },
        {
            key: "orderIdColumn",
            component: (
                <WalletCollectionHubHistoryOrderIdColumnContainer
                    textAlign={"center"}
                    key={"orderIdColumn"}
                >
                    <WalletCollectionHubHistoryTableHeaderColumn>
                        체결번호
                    </WalletCollectionHubHistoryTableHeaderColumn>
                </WalletCollectionHubHistoryOrderIdColumnContainer>
            ),
        },
    ];
    const data: any[] = collectionExchangeList.map(
        (item: CollectionExchangeListItemType) => {
            const isBuying = item.transaction_type === "inbound";
            return {
                itemColumn: (
                    <WalletCollectionHubHistoryItemColumnContainer
                        textAlign={"left"}
                        key={"itemColumn"}
                    >
                        <WalletCollectionHubHistoryTableBodyColumn>
                            <MarketHistoryTableBodyColumnTitle
                                imgSrc={item.thumbnail_full_path}
                                title={item.item_name}
                                desc={item.project_name}
                                targetType={"item"}
                                targetIdx={`${item.project_idx}/${item.item_idx}`}
                                subTargetType={"project"}
                                subTargetIdx={item.project_idx}
                            />
                        </WalletCollectionHubHistoryTableBodyColumn>
                    </WalletCollectionHubHistoryItemColumnContainer>
                ),
                typeColumn: (
                    <WalletCollectionHubHistoryTypeColumnContainer
                        textAlign={"center"}
                        key={"typeColumn"}
                    >
                        <WalletCollectionHubHistoryTableBodyColumn
                            style={{
                                color: isBuying ? "#FF4671" : "#2E8EFF",
                            }}
                        >
                            {isBuying ? "입고" : "출고"}
                        </WalletCollectionHubHistoryTableBodyColumn>
                    </WalletCollectionHubHistoryTypeColumnContainer>
                ),
                historyColumn: (
                    <WalletCollectionHubHistoryCountColumnContainer
                        textAlign={"center"}
                        key={"historyColumn"}
                    >
                        <WalletCollectionHubHistoryTableBodyColumn>
                            {item.exchange_type_detail}
                        </WalletCollectionHubHistoryTableBodyColumn>
                    </WalletCollectionHubHistoryCountColumnContainer>
                ),
                countColumn: (
                    <WalletCollectionHubHistoryCountColumnContainer
                        textAlign={"center"}
                        key={"countColumn"}
                    >
                        <WalletCollectionHubHistoryTableBodyColumn>
                            {item.settled_quantity}
                        </WalletCollectionHubHistoryTableBodyColumn>
                    </WalletCollectionHubHistoryCountColumnContainer>
                ),
                dateColumn: (
                    <WalletCollectionHubHistoryDateColumnContainer
                        textAlign={"center"}
                        key={"dateColumn"}
                    >
                        <WalletCollectionHubHistoryTableBodyColumn>
                            <span>
                                {format(
                                    new Date(item.created_at),
                                    "yyyy.MM.dd",
                                )}
                            </span>
                            <span>
                                {format(new Date(item.created_at), "HH:mm:ss")}
                            </span>
                        </WalletCollectionHubHistoryTableBodyColumn>
                    </WalletCollectionHubHistoryDateColumnContainer>
                ),
                orderIdColumn: (
                    <WalletCollectionHubHistoryOrderIdColumnContainer
                        textAlign={"center"}
                        key={"orderIdColumn"}
                    >
                        <WalletCollectionHubHistoryTableBodyColumn>
                            {item.settlement_idx}
                        </WalletCollectionHubHistoryTableBodyColumn>
                    </WalletCollectionHubHistoryOrderIdColumnContainer>
                ),
            };
        },
    );

    return (
        <ApiResBoundary
            isLoading={isCollectionExchangeListLoading}
            error={errorCollectionExchangeList}
            isNoData={false}
            defaultLoadingFallbackContainerHeight={"500px"}
        >
            <WalletCollectionHubHistoryContainer>
                <WalletCollectionHubHistoryPcContainer>
                    <CommonTable
                        id={"wallet-collection-history"}
                        tableHeaderData={tableHeaderData}
                        tableBodyData={data}
                        tableBodyHeight={"100%"}
                    />
                </WalletCollectionHubHistoryPcContainer>
                <WalletCollectionHubHistoryMobileContainer>
                    {data.map((item, idx) => {
                        return (
                            <WalletCollectionMobileHistoryItem
                                tableHeaderData={tableHeaderData}
                                key={idx}
                                item={item}
                            />
                        );
                    })}
                </WalletCollectionHubHistoryMobileContainer>
                {hasCollectionExchangeListNextPage && (
                    <WalletCollectionHubHistoryMoreBtnWrapper>
                        <WalletCollectionHubHistoryMoreBtnContainer>
                            <MoreButton
                                onClick={async () => {
                                    await fetchCollectionExchangeListNextPage();
                                }}
                            />
                        </WalletCollectionHubHistoryMoreBtnContainer>
                    </WalletCollectionHubHistoryMoreBtnWrapper>
                )}
            </WalletCollectionHubHistoryContainer>
        </ApiResBoundary>
    );
};

export default WalletCollectionHubHistory;
