import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { extractNumbers } from "../../../../../../../../../../../utils/Utils";
import CalendarCellRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";

export const AdBidCalendarCellMobileBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarCellMobileBodyOnSchedule = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const adSalesInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;

    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdDailyDateData(props);

    return isTodaySale ? (
        myOfferSuccessInfo ? (
            // 당일 판매중 - 전체 판매 중단 상관X - 낙찰 표기
            <>
                <CalendarCellRow
                    msg={`${extractNumbers(
                        myOfferSuccessInfo.offer_price + "",
                    )}`}
                    type={"primary-weak"}
                />
                <HiddenKey
                    id={"AD_ON_SCHEDULE__dailySale__myOfferSuccessInfo__mobile"}
                />
            </>
        ) : otherOfferSuccessInfo ? (
            <>
                <CalendarCellRow
                    msg={`${extractNumbers(
                        otherOfferSuccessInfo.offer_price + "",
                    )}`}
                    type={"normal"}
                />
                <HiddenKey
                    id={
                        "AD_ON_SCHEDULE__dailySale__otherOfferSuccessInfo__mobile"
                    }
                />
            </>
        ) : !isAdStop ? (
            // 당일 판매중 - 전체 판매중 - 낙찰X
            <>
                <CalendarCellRow
                    msg={`${extractNumbers(
                        Math.round(
                            (cellInfo.dailyClosedTimeSaleInfo &&
                            cellInfo.dailyClosedTimeSaleInfo
                                .buy_now_closetime_sale_bool
                                ? cellInfo.dailyClosedTimeSaleInfo
                                      .buy_now_closetime_sale_price
                                : adSalesInfo.ad_reserve_price) * 1.1,
                        ) + "",
                    )}`}
                    type={"blue"}
                />
                <HiddenKey
                    id={
                        "AD_ON_SCHEDULE__notAdStop__dailySale__noOfferSuccess__mobile"
                    }
                />
            </>
        ) : (
            // 당일 판매중 - 전체 판매 중단 - 낙찰X
            <>
                <HiddenKey
                    id={
                        "AD_ON_SCHEDULE__AdStop__dailySale__noOfferSuccess__mobile"
                    }
                />
            </>
        )
    ) : !isAdStop ? (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <CalendarCellRow msg={"판매중단"} type={"red"} />
            <HiddenKey
                id={
                    "AD_ON_SCHEDULE__notAdStop__notDailySale__noOfferSuccess__mobile"
                }
            />
        </>
    ) : (
        // 당일 판매 중단 - 전체 판매 중단 - 낙찰X
        <>
            <HiddenKey
                id={
                    "AD_ON_SCHEDULE__AdStop__notDailySale__noOfferSuccess__mobile"
                }
            />
        </>
    );
};

export default AdBidCalendarCellMobileBodyOnSchedule;
