import { ReactElement, RefObject } from "react";
import styled from "styled-components";
import { InputTextDataType } from "../../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import {
    numberToStringWithComma,
    removeComma,
} from "../../../../../../utils/Utils";
import CommonInputTextNumber from "../../../../../common/commonUnitComponent/commonInputText/CommonInputTextNumber";

export const AdRegisterAdjustPriceInputWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #999;
`;

export const AdRegisterAdjustPriceInputTitle = styled.div`
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
`;

export const AdRegisterAdjustPriceInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const AdRegisterAdjustPriceInputRow = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`;

export const AdRegisterAdjustPriceInputTextRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const AdRegisterAdjustPriceInputTextKey = styled.div`
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustPriceInputTextValue = styled.div`
    display: flex;
    align-items: baseline;
    gap: 6px;

    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustPriceInputTextBoldValue = styled.div`
    display: flex;
    gap: 6px;
    align-items: baseline;

    color: #fa54fa;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
`;

export const AdRegisterAdjustPriceInputTextValueUnit = styled.div`
    color: #888;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

export const AdRegisterAdjustPriceInputUnderLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
`;

type Props = {
    inputPrice: InputTextDataType;
    setInputPrice: (value: InputTextDataType) => void;
    focusOutEventHandler?: () => Promise<void>;

    maxPrice: number;
    minPrice: number;

    title: string;
    postPositionalTitle: string;
    containerRef: RefObject<HTMLDivElement>;
};

const AdRegisterAdjustPriceInput = (props: Props): ReactElement | null => {
    const inputPriceNumber = removeComma(props.inputPrice.value) || 0;
    const vat = inputPriceNumber / 10;

    return (
        <AdRegisterAdjustPriceInputWrapper>
            <AdRegisterAdjustPriceInputTitle>
                {props.title}
            </AdRegisterAdjustPriceInputTitle>
            <AdRegisterAdjustPriceInputContainer ref={props.containerRef}>
                <AdRegisterAdjustPriceInputRow>
                    <CommonInputTextNumber
                        inputNumber={props.inputPrice}
                        setInputNumber={props.setInputPrice}
                        focusOutEventHandler={props.focusOutEventHandler}
                        max={props.maxPrice}
                        min={props.minPrice}
                        title={props.title}
                        postPositionalTitle={props.postPositionalTitle}
                        isThousandCheck={true}
                        unit={"KRW"}
                    />

                    <AdRegisterAdjustPriceInputTextValueUnit>
                        KRW
                    </AdRegisterAdjustPriceInputTextValueUnit>
                </AdRegisterAdjustPriceInputRow>
                <AdRegisterAdjustPriceInputTextRow>
                    <AdRegisterAdjustPriceInputTextKey>
                        VAT
                    </AdRegisterAdjustPriceInputTextKey>
                    <AdRegisterAdjustPriceInputTextValue>
                        {numberToStringWithComma(vat)}
                        <AdRegisterAdjustPriceInputTextValueUnit>
                            KRW
                        </AdRegisterAdjustPriceInputTextValueUnit>
                    </AdRegisterAdjustPriceInputTextValue>
                </AdRegisterAdjustPriceInputTextRow>
                <AdRegisterAdjustPriceInputUnderLine />
                <AdRegisterAdjustPriceInputTextRow>
                    <AdRegisterAdjustPriceInputTextKey>
                        VAT 포함
                    </AdRegisterAdjustPriceInputTextKey>
                    <AdRegisterAdjustPriceInputTextBoldValue>
                        {numberToStringWithComma(vat + inputPriceNumber)}
                        <AdRegisterAdjustPriceInputTextValueUnit>
                            KRW
                        </AdRegisterAdjustPriceInputTextValueUnit>
                    </AdRegisterAdjustPriceInputTextBoldValue>
                </AdRegisterAdjustPriceInputTextRow>
            </AdRegisterAdjustPriceInputContainer>
        </AdRegisterAdjustPriceInputWrapper>
    );
};

export default AdRegisterAdjustPriceInput;
