import { useInfiniteQuery } from "react-query";
import { MarketCommonApi } from "../../../apis/market/MarketCommonApi";

export type MarketSettlementListApiResultType = {
    settlementSubList: MarketSettlementListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type MarketSettlementListItemType = {
    sell_user_nick: string;
    sell_user_idx: string;
    buy_user_nick: string;
    buy_user_idx: string;
    settlement_sub_idx: string;
    settlement_idx: string;
    order_idx_sell: string;
    order_idx_buy: string;
    currency_idx: string;
    settled_quantity: string;
    settled_unit_price: number;
    creator_royalty: number;
    brand_royalty: number;
    created_at: string;
    updated_at: string;
    settlement: {
        settlement_idx: string;
        settlement_no: string;
        settled_at: string;
        brand_idx: string;
        settlement_type: string;
        product_type: string;
        product_idx: string;
        project_idx: string;
        settled_quantity_sum: string;
        creator_idx: string;
        creator_royalty_sum: number;
        brand_royalty_sum: number;
        created_at: string;
        updated_at: string;
    };
};

export type MarketSettlementListParamsType = {
    limit: number;
    page_no: number;
};

export const useGetMarketSettlementListQuery = (
    pageType: string,
    targetIdx: string,
    params: MarketSettlementListParamsType,
) => {
    const queryResult = useInfiniteQuery<
        MarketSettlementListApiResultType,
        Error
    >({
        queryKey: [
            `market-${pageType}-settlement-list-${targetIdx}${JSON.stringify(
                params,
            )}`,
            `${pageType}-info-refresh-target-${targetIdx}`,
        ],
        queryFn: ({ pageParam = 1 }) =>
            MarketCommonApi.getSettlementList(pageType, targetIdx, {
                ...params,
                page_no: pageParam,
            }),
        refetchOnWindowFocus: false,
        suspense: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: MarketSettlementListApiResultType[] = [
        {
            settlementSubList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadSettlementListPages(
    pages: MarketSettlementListApiResultType[],
) {
    return pages
        .map((result) => result.settlementSubList)
        .reduce((prev, next) => prev.concat(next));
}
