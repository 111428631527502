import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { extractNumbers } from "../../../../../../../../../../../utils/Utils";
import CalendarCellRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";

export const AdBidCalendarCellMobileBodyBidExpiredContainer = styled.div``;

type Props = {};

const AdBidCalendarCellMobileBodyBidExpired = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
    } = useGetAdDailyDateData(props);
    const opacity = 0.3;

    return myOfferInfo ? (
        <>
            <CalendarCellRow msg={"유찰"} type={"normal"} opacity={opacity} />
            <HiddenKey
                id={"AD_EXPIRED__notAdStop__dailySale__myOfferInfo__mobile"}
            />
        </>
    ) : otherOfferInfo ? (
        <>
            <CalendarCellRow msg={"유찰"} type={"normal"} opacity={opacity} />
            <HiddenKey
                id={"AD_EXPIRED__notAdStop__dailySale__otherOfferInfo__mobile"}
            />
        </>
    ) : myOfferSuccessInfo ? (
        <>
            <CalendarCellRow
                msg={`${extractNumbers(myOfferSuccessInfo.offer_price + "")}`}
                type={"primary-weak"}
                opacity={opacity}
            />
            <HiddenKey
                id={
                    "AD_EXPIRED__notAdStop__dailySale__myOfferSuccessInfo__mobile"
                }
            />
        </>
    ) : otherOfferSuccessInfo ? (
        <>
            <CalendarCellRow
                msg={`${extractNumbers(
                    otherOfferSuccessInfo.offer_price + "",
                )}`}
                type={"normal"}
                opacity={opacity}
            />
            <HiddenKey
                id={
                    "AD_EXPIRED__notAdStop__dailySale__otherOfferSuccessInfo__mobile"
                }
            />
        </>
    ) : (
        <>
            <CalendarCellRow msg={"유찰"} type={"normal"} opacity={opacity} />
            <HiddenKey
                id={"AD_EXPIRED__notAdStop__notAdDailySale__else__mobile"}
            />
        </>
    );
};

export default AdBidCalendarCellMobileBodyBidExpired;
