export type MyAdByDateOfferListItemOfferInfoType = {
    endPoint: string;
    created_at: string;
    offer_idx: string;
    offer_invalid_status: string;
    offer_price: number;
    offer_status: string;
    template_idx: string;
    updated_at: string;
    user_id: string;
    user_idx: string;
    user_nick: string;
    user_success_count: string;
};

export const DEFAULT_MY_AD_BY_DATE_OFFER_LIST_ITEM_OFFER_INFO_TYPE_DATA: MyAdByDateOfferListItemOfferInfoType =
    {
        endPoint: "",
        created_at: "",
        offer_idx: "",
        offer_invalid_status: "",
        offer_price: 0,
        offer_status: "",
        template_idx: "",
        updated_at: "",
        user_id: "",
        user_idx: "",
        user_nick: "",
        user_success_count: "",
    };
