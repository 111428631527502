import { getIsLogin } from "../../store/auth/authKeyStore";
import { useUserFollowMutation } from "../../query/user/follow/useUserFollowMutation";
import { useUserUnFollowMutation } from "../../query/user/follow/useUserUnFollowMutation";
import { useBrandFollowMutation } from "../../query/brand/useBrandFollowMutation";
import { useBrandUnFollowMutation } from "../../query/brand/useBrandUnFollowMutation";
import { useAtom } from "jotai";
import { useOpenLoginConfirmModal } from "../modal/openModal/useOpenLoginConfirmModal";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";

export function useFollowUnFollow(
    idx: string,
    isFollow: boolean,
    refetch: () => Promise<any> | void,
    followAfterAction?: () => Promise<void>,
) {
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const { mutateAsync: userFollow, isLoading: isUserFollowing } =
        useUserFollowMutation(idx);
    const { mutateAsync: userUnFollow, isLoading: isUserUnFollowing } =
        useUserUnFollowMutation(idx);

    const { mutateAsync: brandFollow, isLoading: isBrandFollowing } =
        useBrandFollowMutation(idx);
    const { mutateAsync: brandUnFollow, isLoading: isBrandUnFollowing } =
        useBrandUnFollowMutation(idx);
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    const showSnackbar = (isFollow: boolean) => {
        pushSnackBarListItem({
            title: "",
            desc: isFollow ? "언팔로우 처리중입니다" : "팔로우 처리중입니다",
            iconUrl: "",
            confirmBtnTitle: "",
        });
    };
    const followUnFollow = async (isUser = true) => {
        if (getIsLogin()) {
            try {
                if (isFollow) {
                    if (isUser) {
                        if (!isUserUnFollowing) {
                            await userUnFollow();
                        } else {
                            showSnackbar(isFollow);
                        }
                    } else {
                        if (!isBrandUnFollowing) {
                            await brandUnFollow();
                        } else {
                            showSnackbar(isFollow);
                        }
                    }
                } else {
                    if (isUser) {
                        if (!isUserFollowing) {
                            await userFollow();
                        } else {
                            showSnackbar(isFollow);
                        }
                    } else {
                        if (!isBrandFollowing) {
                            await brandFollow();
                        } else {
                            showSnackbar(isFollow);
                        }
                    }
                }
            } catch (e) {
                console.info(e);
            }
            await refetch();

            if (followAfterAction) {
                await followAfterAction();
            }
        } else {
            await openLoginConfirmModal();
        }
    };

    return {
        followUnFollow,
    };
}
