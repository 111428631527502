import { useInfiniteQuery } from "react-query";
import { AdMyApi } from "../../../apis/adApi/AdMyApi";
import { AllAdMyBuyOfferListItemType } from "../../../types/ad/adMy/allAdMyBuyOffer/AllAdMyBuyOfferListItemType";

export type AllAdMyOfferListApiResultType = {
    offerList: AllAdMyBuyOfferListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type AllAdMyOfferListItemParams = {
    page_no: number;
    limit: number;
    // ad_sales_date_from: string;
    ad_sales_date_from: string;
    // ad_sales_date_to: string;
    ad_sales_date_to: string;
    sort_type: string;
};

export const useGetAllAdMyBuyOfferListQuery = (
    params: AllAdMyOfferListItemParams,
) => {
    const queryResult = useInfiniteQuery<AllAdMyOfferListApiResultType, Error>({
        queryKey: [
            "all-ad-my-buy-offer-list-" + JSON.stringify(params),
            "all-ad-my-buy-offer-list",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return AdMyApi.getAllAdMyBuyOfferList({
                ...params,
                page_no: pageParam,
            });
        },
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        cacheTime: 0,
        staleTime: 0,
        onError: (err) => {},
    });
    const defaultData: AllAdMyOfferListApiResultType[] = [
        { offerList: [], totalCount: 0 },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadAllAdMyOfferListPages(
    pages: AllAdMyOfferListApiResultType[],
) {
    return pages
        .map((result) => result.offerList)
        .reduce((prev, next) => prev.concat(next));
}
