import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { numberToStringWithComma } from "../../../../../../../../../../utils/Utils";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellBackgroundRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import CalendarSelectedMobileCellNormalRow from "../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellNormalRow";
import { HiddenKeyManage } from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarSelectedMobileCellBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarSelectedMobileCellBidExpired = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const { targetDateInfo: cellInfo, isTodaySale } =
        useGetAdMyDailyDateData(props);

    return isTodaySale ? (
        typeof cellInfo.offerSuccessInfo === "undefined" ? (
            <>
                <CalendarCellBackgroundRow msg={"유찰"} type={"normal"} />
                <HiddenKeyManage
                    id={"AD_EXPIRED_dailySale__offerSuccessInfo__ms"}
                />
            </>
        ) : (
            <>
                <CalendarCellBackgroundRow
                    msg={"게재 완료"}
                    type={"secondary"}
                />
                <CalendarSelectedMobileCellNormalRow
                    keyMsg={`${cellInfo.offerSuccessInfo.offer_success_user_nick}`}
                    valueMsg={`${numberToStringWithComma(
                        cellInfo.offerSuccessInfo.offer_price,
                    )} KRW`}
                />
                <HiddenKeyManage id={"AD_EXPIRED_dailySale__noOffer__ms"} />
            </>
        )
    ) : (
        <>
            <CalendarCellBackgroundRow msg={"판매 중단"} type={"danger"} />
            <HiddenKeyManage id={"AD_EXPIRED_notAdDailySale__ms"} />
        </>
    );
};

export default AdSaleManageCalendarSelectedMobileCellBidExpired;
