import { ReactElement } from "react";
import styled from "styled-components";
import {
    HEADER_MENUS,
    HeaderMoreMenus,
} from "../../../../../../constants/menus/headerMenus";
import FooterPathListItem from "./FooterPathListItem";
import { ResponsiveWidth } from "../../../../../../styles/Theme";

export const FooterPathListContainer = styled.div<{ wrapperWidth: number }>`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    gap: 40px 20px;
    width: 100%;
    ${({ wrapperWidth }) => {
        let style = "";

        // double side bar layout 에서 border 때문에 1px이 씹힘. 어쩔수 없이 +1 부여
        if (wrapperWidth <= ResponsiveWidth.tabletL + 1) {
            style += `gap: 10px;`;
        }
        if (wrapperWidth <= ResponsiveWidth.tabletM) {
            style += `gap: 20px 0;`;
        }

        if (wrapperWidth <= ResponsiveWidth.mobileL) {
            style += `
            gap: 20px 0;`;
        }

        return style;
    }};
`;

export const FooterPathListColumnContainer = styled.div<{
    wrapperWidth: number;
    index: number;
}>`
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 140px;

    ${({ wrapperWidth, index }) => {
        let style = "";

        // double side bar layout 에서 border 때문에 1px이 씹힘. 어쩔수 없이 +1 부여
        if (wrapperWidth <= ResponsiveWidth.tabletL + 1) {
            style += `width: 104px;`;
        }

        if (wrapperWidth <= ResponsiveWidth.tabletM) {
            style += `width: 20%;`;
        }

        if (wrapperWidth <= ResponsiveWidth.mobileM) {
            style += `width: 33.33%;`;
        }
        return style;
    }};
`;

type Props = {
    footerWrapperWidth: number;
    type: string;
};

const FooterPathList = (props: Props): ReactElement | null => {
    const fullMenus = [
        ...HEADER_MENUS,
        {
            id: -1,
            name: "더 보기",
            path: "",
            isNewTab: false,
            isNeedLogin: false,
            children: HeaderMoreMenus,
        },
    ];
    return (
        <FooterPathListContainer wrapperWidth={props.footerWrapperWidth}>
            {fullMenus.map((headerMenu, idx) => (
                <FooterPathListColumnContainer
                    key={headerMenu.id}
                    wrapperWidth={props.footerWrapperWidth}
                    index={idx}
                >
                    <FooterPathListItem
                        key={headerMenu.id}
                        wrapperWidth={props.footerWrapperWidth}
                        type={"main"}
                        footerData={headerMenu}
                        isMoreParentMenu={headerMenu.id === -1}
                    />

                    {headerMenu.children &&
                        headerMenu.children.map((childrenData) => (
                            <FooterPathListItem
                                key={childrenData.id}
                                wrapperWidth={props.footerWrapperWidth}
                                type={"sub"}
                                footerData={childrenData}
                            />
                        ))}
                </FooterPathListColumnContainer>
            ))}
        </FooterPathListContainer>
    );
};

export default FooterPathList;
