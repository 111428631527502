import { RefObject, useCallback, useEffect } from "react";
import { setSessionStorageItem } from "../../../../../../utils/Utils";
import { useAtom, useSetAtom } from "jotai/index";
import fullScrollTopAtom from "../../../../../../store/scroll/fullScrollTopAtom";
import IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM from "../../../../../../store/scroll/IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM";
import fullScrollTopPxAtom from "../../../../../../store/scroll/fullScrollTopPxAtom";
import isFullScrollOverHasLnbInSubHeaderAtom from "../../../../../../store/scroll/isFullScrollOverHasLnbInSubHeaderAtom";
import isFullScrollOverNoLnbInSubHeaderAtom from "../../../../../../store/scroll/isFullScrollOverNoLnbInSubHeaderAtom";
import isScrollUpAtom from "../../../../../../store/scroll/isScrollUpAtom";

export const useSetNoCustomScrollEvent = (
    id: string,
    isRoot: boolean,
    scrollTopRef: RefObject<number>,
    setScrollTop: (value: number) => void,
    init: () => void,
) => {
    const setFullScrollTop = useSetAtom(fullScrollTopAtom);
    const setIsFullScrollOver = useSetAtom(
        IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM,
    );
    const [fullScrollTopPx, setFullScrollTopPx] = useAtom(fullScrollTopPxAtom);
    const setIsFullScrollOverHasLnbInSubHeader = useSetAtom(
        isFullScrollOverHasLnbInSubHeaderAtom,
    );
    const setIsFullScrollOverNoLnbInSubHeaderAtom = useSetAtom(
        isFullScrollOverNoLnbInSubHeaderAtom,
    );
    const setIsScrollUp = useSetAtom(isScrollUpAtom);

    const setScrollTopInSessionStorage = useCallback(
        (scrollTopPx: number) => {
            setSessionStorageItem(
                `path-${window.location.pathname}-id-${id}`,
                scrollTopPx,
            );
        },
        [location, id],
    );

    const handleScroll = (e: any) => {
        setScrollTop(document.documentElement.scrollTop);
        setScrollTopInSessionStorage(document.documentElement.scrollTop);
    };

    // handleScroll 에 걸면 state set 이 동작하지 않는다.
    useEffect(() => {
        setFullScrollTop(
            (document.documentElement.scrollTop /
                document.documentElement.offsetHeight) *
                100,
        );

        if (scrollTopRef.current) {
            setIsFullScrollOver(
                location.pathname === "/"
                    ? scrollTopRef.current > 1
                    : (scrollTopRef.current /
                          document.documentElement.offsetHeight) *
                          100 >
                          0.05,
            );
        }

        // 같을 때는 스크롤 업으로 간주 - up 동작일 때 헤더들이 나오므로.
        if (document.documentElement.scrollTop > fullScrollTopPx) {
            setIsScrollUp(false);
        } else {
            setIsScrollUp(true);
        }
        setFullScrollTopPx(document.documentElement.scrollTop);
        setIsFullScrollOverHasLnbInSubHeader(
            document.documentElement.scrollTop < 46,
        );
        setIsFullScrollOverNoLnbInSubHeaderAtom(
            document.documentElement.scrollTop > 240,
        );
    }, [scrollTopRef.current]);

    useEffect(() => {
        init();
        // 모바일용 isScroll
        if (isRoot) {
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);
};
