import { ReactElement } from "react";
import styled from "styled-components";
import FooterPathList from "./components/footerPathList/FooterPathList";
import { FooterLogo } from "./Footer";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import FooterAppDownloadBtn from "./components/FooterAppDownloadBtn";
import FooterCompanyInfoList from "./components/FooterCompanyInfoList";
import FooterCopyRight from "./components/FooterCopyRight";
import FooterPolicyList from "./components/FooterPolicyList";
import { ResponsiveWidth } from "../../../../styles/Theme";

export const FooterPcTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const FooterPcTypeUpperContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 32px;
    border-bottom: 1px #e5e5e5 solid;
`;

export const FooterPcTypeUpperLeftContainer = styled.div`
    width: 387px;
    display: flex;
    flex-direction: column;
`;

export const FooterPcTypeAppDownloadContainer = styled.div`
    margin-top: 24px;
`;

export const FooterPcTypeAppCompanyInfoListContainer = styled.div`
    margin-top: 20px;
`;

export const FooterPcTypeUpperRightContainer = styled.div<{
    wrapperWidth: number;
}>`
    flex-shrink: 0;

    ${({ wrapperWidth }) => {
        let style = "";

        if (wrapperWidth <= ResponsiveWidth.tabletL) {
            // style += `min-width: 556px;`;
        }
        return style;
    }};
`;

export const FooterPcTypeLowerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const FooterPcTypePolicyListContainer = styled.div`
    margin-top: 40px;
`;

export const FooterPcTypeCopyRightContainer = styled.div`
    margin-top: 16px;
`;

type Props = {
    footerWrapperWidth: number;
};

const FooterPcType = (props: Props): ReactElement | null => {
    return (
        <FooterPcTypeContainer>
            <FooterPcTypeUpperContainer>
                <FooterPcTypeUpperLeftContainer>
                    <FooterLogo
                        src={
                            URL_CONSTANTS.ASSET_URL +
                            "/logo/logo-saybuzz-black.svg"
                        }
                    />
                    <FooterPcTypeAppDownloadContainer>
                        <FooterAppDownloadBtn />
                    </FooterPcTypeAppDownloadContainer>

                    <FooterPcTypeAppCompanyInfoListContainer>
                        <FooterCompanyInfoList />
                    </FooterPcTypeAppCompanyInfoListContainer>
                </FooterPcTypeUpperLeftContainer>

                <FooterPcTypeUpperRightContainer
                    wrapperWidth={props.footerWrapperWidth}
                >
                    <FooterPathList
                        footerWrapperWidth={props.footerWrapperWidth}
                        type={"pc"}
                    />
                </FooterPcTypeUpperRightContainer>
            </FooterPcTypeUpperContainer>
            <FooterPcTypeLowerContainer>
                <FooterPcTypePolicyListContainer>
                    <FooterPolicyList />
                </FooterPcTypePolicyListContainer>

                <FooterPcTypeCopyRightContainer>
                    <FooterCopyRight />
                </FooterPcTypeCopyRightContainer>
            </FooterPcTypeLowerContainer>
        </FooterPcTypeContainer>
    );
};

export default FooterPcType;
