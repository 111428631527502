import { useGetAdDailyDateData } from "../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import {
    AD_BID_CLOSED,
    AD_EXPIRED,
    AD_IN_REVIEW,
    AD_ON_BID,
    AD_ON_SCHEDULE,
    AD_PREPARING,
    AD_UNKNOWN,
    AdDailyListItemType,
} from "../../../../../../../../../types/ad/adDaily/AdDailyListItemType";
import { ReactElement } from "react";
import CalendarCellBackgroundRow from "../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import AdBidCalendarCellPcBodyOnBid from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenOnBid/AdBidCalendarCellPcBodyOnBid";
import AdBidCalendarCellMobileBodyOnBid from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenOnBid/AdBidCalendarCellMobileBodyOnBid";
import AdBidCalendarSelectedMobileCellOnBid from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenOnBid/AdBidCalendarSelectedMobileCellOnBid";
import AdBidCalendarCellPcBodyInReview from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenInReview/AdBidCalendarCellPcBodyInReview";
import AdBidCalendarCellMobileBodyInReview from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenInReview/AdBidCalendarCellMobileBodyInReview";
import AdBidCalendarSelectedMobileCellInReview from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenInReview/AdBidCalendarSelectedMobileCellInReview";
import AdBidCalendarCellPcBodyBidClosedAndPreparing from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenBidClosedAndPreparing/AdBidCalendarCellPcBodyBidClosedAndPreparing";
import AdBidCalendarCellMobileBodyBidClosedAndPreparing from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenBidClosedAndPreparing/AdBidCalendarCellMobileBodyBidClosedAndPreparing";
import AdBidCalendarSelectedMobileCellBidClosedAndPreparing from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenBidClosedAndPreparing/AdBidCalendarSelectedMobileCellBidClosedAndPreparing";
import AdBidCalendarCellPcBodyOnSchedule from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenOnSchedule/AdBidCalendarCellPcBodyOnSchedule";
import AdBidCalendarCellMobileBodyOnSchedule from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenOnSchedule/AdBidCalendarCellMobileBodyOnSchedule";
import AdBidCalendarSelectedMobileCellOnSchedule from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenOnSchedule/AdBidCalendarSelectedMobileCellOnSchedule";
import AdBidCalendarCellPcBodyBidExpired from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenBidExpired/AdBidCalendarCellPcBodyBidExpired";
import AdBidCalendarCellMobileBodyBidExpired from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenBidExpired/AdBidCalendarCellMobileBodyBidExpired";
import AdBidCalendarSelectedMobileCellBidExpired from "./adBidCalendarCellChildrenByCode/adBidCalendarCellChildrenBidExpired/AdBidCalendarSelectedMobileCellBidExpired";

export type AdBidCalendarCellCommonProps = {
    cellDate: Date;
    adDailyList: AdDailyListItemType[];
    adIdx: string;
};

export function useGetAdBidCalendarCellComponentByCode(
    params: AdBidCalendarCellCommonProps,
) {
    const { targetDateInfo: cellInfo } = useGetAdDailyDateData(params);

    let cellPcBody: ReactElement = <></>;
    let cellMobileBody: ReactElement = <></>;
    let cellMobileSelectedCell: ReactElement = <></>;

    switch (cellInfo.ad_daily_status_code) {
        case AD_ON_BID:
            cellPcBody = <AdBidCalendarCellPcBodyOnBid {...params} />;
            cellMobileBody = <AdBidCalendarCellMobileBodyOnBid {...params} />;
            cellMobileSelectedCell = (
                <AdBidCalendarSelectedMobileCellOnBid {...params} />
            );
            break;

        case AD_IN_REVIEW:
            cellPcBody = <AdBidCalendarCellPcBodyInReview {...params} />;
            cellMobileBody = (
                <AdBidCalendarCellMobileBodyInReview {...params} />
            );
            cellMobileSelectedCell = (
                <AdBidCalendarSelectedMobileCellInReview {...params} />
            );
            break;

        case AD_PREPARING:
        case AD_BID_CLOSED:
            cellPcBody = (
                <AdBidCalendarCellPcBodyBidClosedAndPreparing {...params} />
            );
            cellMobileBody = (
                <AdBidCalendarCellMobileBodyBidClosedAndPreparing {...params} />
            );
            cellMobileSelectedCell = (
                <AdBidCalendarSelectedMobileCellBidClosedAndPreparing
                    {...params}
                />
            );
            break;

        case AD_ON_SCHEDULE:
            cellPcBody = <AdBidCalendarCellPcBodyOnSchedule {...params} />;
            cellMobileBody = (
                <AdBidCalendarCellMobileBodyOnSchedule {...params} />
            );
            cellMobileSelectedCell = (
                <AdBidCalendarSelectedMobileCellOnSchedule {...params} />
            );
            break;

        case AD_EXPIRED:
            cellPcBody = <AdBidCalendarCellPcBodyBidExpired {...params} />;
            cellMobileBody = (
                <AdBidCalendarCellMobileBodyBidExpired {...params} />
            );
            cellMobileSelectedCell = (
                <AdBidCalendarSelectedMobileCellBidExpired {...params} />
            );
            break;

        case AD_UNKNOWN:
            cellPcBody = (
                <CalendarCellBackgroundRow type={"danger"} msg={"판매 중단"} />
            );
            cellMobileBody = <></>;
            cellMobileSelectedCell = <></>;
            break;

        default:
            break;
    }

    return {
        cellPcBody,
        cellMobileBody,
        cellMobileSelectedCell,
        adDailyStatusCode: cellInfo.ad_daily_status_code,
    };
}
