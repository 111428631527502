import { useAtom } from "jotai";
import { useMutation } from "react-query";

import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { ItemApi } from "../../../apis/ItemApi";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";

export type UseItemParams = {
    count: number;
};

export const useUseItemMutation = (itemIdx: string, params: UseItemParams) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(() => ItemApi.useItem(itemIdx, params), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
        onSuccess: () => {
            pushSnackBarListItem({
                title: "",
                desc: "아이템이 성공적으로 사용되었습니다.",
                iconUrl:
                    URL_CONSTANTS.ASSET_URL +
                    "/icon/icon-checked-in-circle.svg",
                confirmBtnTitle: "",
            });
        },
    });
};
