import React, { ReactElement, RefObject } from "react";
import styled from "styled-components";
import { TableHeaderDataType } from "../../CommonTable";
import { infiniteScrollCallbackType } from "../../../../commonUiComponent/scrollbar/components/customScrollbar/CustomScrollbar";
import TableBodyLayout from "./TableBodyLayout";

export const TableBodyWrapper = styled.div<{ bodyHeight: string }>`
    position: relative;
    height: ${({ bodyHeight }) => bodyHeight};

    transition: height 200ms ease;
`;

export const TableBodyContainer = styled.div`
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
    -ms-overflow-style: none;
`;
export const TableBodyRowContainer = styled.div`
    position: relative;
`;

export const TableBodyRow = styled.div<{
    hasScrollX: boolean;
    hasClickHandler?: boolean;
    rowHoverColor?: string;
}>`
    color: #555555;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: auto;
    border-bottom: 1px solid #e8e8e8;
    ${({ hasClickHandler }) => hasClickHandler && `cursor: pointer;`};

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ rowHoverColor }) =>
                rowHoverColor ? rowHoverColor : "transparent"};
        }
    }
    transition: all 300ms ease;

    @media ${(props) => props.theme.mobileL} {
        ${({ hasScrollX }) => {
            if (hasScrollX)
                return `clear: both;
                        float: left;`;
        }}
    }
`;

export const TableBodyLoadingContainer = styled.div<{ bodyHeight: string }>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ bodyHeight }) => bodyHeight};
`;

type Props = {
    id: string;
    tableHeaderData: TableHeaderDataType[];
    tableBodyData: any[];
    tableBodyHeight: string;
    tableHeaderRef: RefObject<HTMLDivElement>;
    hasScrollX: boolean;
    hasScrollY?: boolean;
    tableBodyRowPadding: string;
    infiniteScrollCallback?: infiniteScrollCallbackType;
    hasParentPaddingBottom: boolean;
    rowHoverColor?: string;
    onClickHandler?: (idx: number) => void;
    error?: any;
    isLoading?: boolean;
};

const TableBody = (props: Props): ReactElement | null => {
    return (
        <TableBodyLayout
            id={props.id}
            infiniteScrollCallback={props.infiniteScrollCallback}
            tableBodyHeight={props.tableBodyHeight}
            hasScrollY={props.hasScrollY}
        >
            <TableBodyContainer
                style={
                    props.hasScrollX
                        ? {
                              overflowX: "auto",
                          }
                        : props.hasScrollY
                        ? { overflowX: "hidden" }
                        : {}
                }
                // css 로 custom scrollbar 와 함께 스크롤 합치는 법을 모르겠다..
                onScroll={(e: any) => {
                    if (props.tableHeaderRef.current)
                        props.tableHeaderRef.current.scrollLeft =
                            e.target.scrollLeft;
                }}
            >
                {props.tableBodyData.map((tableRowData, tableRowIdx) => (
                    <TableBodyRowContainer key={tableRowIdx}>
                        <TableBodyRow
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (props.onClickHandler)
                                    props.onClickHandler(tableRowIdx);
                            }}
                            rowHoverColor={props.rowHoverColor}
                            hasClickHandler={
                                typeof props.onClickHandler !== "undefined"
                            }
                            key={tableRowIdx}
                            style={{
                                padding: props.tableBodyRowPadding,
                            }}
                            hasScrollX={props.hasScrollX}
                        >
                            {props.tableHeaderData.map(
                                (tableHeaderItem: any) =>
                                    (typeof tableHeaderItem === "undefined" ||
                                        !tableHeaderItem.isHide) &&
                                    tableRowData[tableHeaderItem.key],
                            )}
                        </TableBodyRow>
                    </TableBodyRowContainer>
                ))}
            </TableBodyContainer>
        </TableBodyLayout>
    );
};

export default TableBody;
