import { useOpenModal } from "../useOpenModal";
import React from "react";
import WalletCollectionGroupMoveModal from "../../../components/wallet/walletCollection/components/walletCollectionHub/components/walletCollectionGroupModal/walletCollectionGroupMoveModal/WalletCollectionGroupMoveModal";
import WalletCollectionGroupTitleAdjustModal from "../../../components/wallet/walletCollection/components/walletCollectionHub/components/walletCollectionGroupModal/WalletCollectionGroupTitleAdjustModal";
import { ItemInventoryGroupListItemType } from "../../../types/itemInventory/ItemInventoryGroupListItemType";

export function useOpenCollectionGroupTitleAdjustModal(
    groupInfo: ItemInventoryGroupListItemType,
) {
    const { openModal } = useOpenModal();

    return {
        openCollectionGroupTitleAdjustModal: async (): Promise<boolean> => {
            return await openModal(
                "wallet-collection-group-title-adjust-modal",
                "modal",
                (resolve) => {
                    return (
                        <WalletCollectionGroupTitleAdjustModal
                            resolve={resolve}
                            groupInfo={groupInfo}
                        />
                    );
                },
            );
        },
    };
}
