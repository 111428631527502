import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { ClassNames, DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { ko } from "date-fns/locale";
import styles from "react-day-picker/dist/style.module.css";
import { useOnClickOutside } from "usehooks-ts";
import { format } from "date-fns";

export const DatePickerContainer = styled.div<{ isShow: boolean }>`
    position: relative;
    font-size: 14px;
    line-height: 100%;
    display: ${({ isShow }) => (isShow ? "flex" : "none")};
    color: #999999;
    align-items: center;
    width: inherit;
    cursor: pointer;
`;

export const DayPickerTitle = styled.div<{ isMobileTitleHide?: boolean }>`
    margin-right: 10px;
    flex-shrink: 0;
    @media ${(props) => props.theme.mobileL} {
        display: ${({ isMobileTitleHide }) =>
            isMobileTitleHide ? "none" : "block"};
    }
`;

export const DatePickerDateContainer = styled.div`
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const DatePickerLayerContainer = styled.div<{ isOpen: boolean }>`
    position: absolute;
    top: 50px;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    z-index: 2;
    max-height: ${({ isOpen }) => (isOpen ? "400px" : 0)};
    overflow: hidden;
    transition: max-height 300ms ease;
`;

const css = `
  .custom-selected:not([disabled]) { 
    font-weight: bold; 
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #FFFFFF;
    background: #FA54FA;
  }
  .custom-selected:hover:not([disabled]) { 
    background: #FA54FA !important;
  }
  .custom-today { 
    font-weight: bold;
    font-size: 100%; 
    color: black;
  }
  .custom-head {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #888888;
  }
  .custom-button {
    border: none;
    
  }
  .custom-button:hover {
    background-color: #F0F0F2;
    border: none;
  }
  .rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: #0000ff;
    --rdp-background-color: #F0F0F2;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
  }  
`;

export const DayPickerDate = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 16px;
    gap: 10px;

    background: #f5f5f7;
    border-radius: 10px;

    font-weight: 400;
    font-size: 14px;
    line-height: 100%;

    color: #222222;
    position: relative;
`;

type Props = {
    selectedDateRange: DateRange;
    setSelectedDateRange: (value: DateRange) => void;
    renderDate?: ReactElement;
    title?: string;
    isMobileTitleHide?: boolean;
    isShow: boolean;
};

const DayPickerLayer = (props: Props): ReactElement | null => {
    const customClassName: ClassNames = {
        ...styles,
        head: "custom-head",
        button: "custom-button",
    };
    const dayPickerContainerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    useOnClickOutside(dayPickerContainerRef, () => {
        setIsOpen(false);
    });

    return (
        <DatePickerContainer ref={dayPickerContainerRef} isShow={props.isShow}>
            <DayPickerTitle isMobileTitleHide={props.isMobileTitleHide}>
                {props.title}
            </DayPickerTitle>

            {props.renderDate ? (
                <div
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    {props.renderDate}
                </div>
            ) : (
                <DatePickerDateContainer
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <DayPickerDate>
                        {props.selectedDateRange.from
                            ? format(props.selectedDateRange.from, "yyyy.MM.dd")
                            : ""}{" "}
                        ~{" "}
                        {props.selectedDateRange.to
                            ? format(props.selectedDateRange.to, "yyyy.MM.dd")
                            : ""}
                    </DayPickerDate>
                </DatePickerDateContainer>
            )}

            <DatePickerLayerContainer isOpen={isOpen}>
                <style>{css}</style>
                <DayPicker
                    mode="range"
                    selected={props.selectedDateRange}
                    onSelect={(value) => {
                        if (typeof value !== "undefined") {
                            props.setSelectedDateRange(value);
                        } else {
                            setIsOpen(false);
                        }
                    }}
                    classNames={customClassName}
                    modifiersClassNames={{
                        selected: "custom-selected",
                        today: "custom-today",
                    }}
                    locale={ko}
                    // 선택불가능한 셀은 color: rgba(0, 0, 0, 0.3);
                    styles={{
                        cell: { color: "#000000" },
                        head_cell: { fontWeight: 500 },
                        caption: { color: "#000000" },
                    }}
                />
            </DatePickerLayerContainer>
        </DatePickerContainer>
    );
};

export default DayPickerLayer;

DayPickerLayer.defaultProps = {
    title: "기간 설정",
    isMobileTitleHide: true,
    isShow: true,
};
