import { useInfiniteQuery } from "react-query";
import { AdMyApi } from "../../../apis/adApi/AdMyApi";
import { MyAdByDateOfferListItemType } from "../../../types/ad/adMy/myAd__sale/myAdByDateOfferList/MyAdByDateOfferListItemType";

export type MyAdByDateOfferListApiResultType = {
    offerList: MyAdByDateOfferListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type MyAdByDateOfferListParams = {
    ad_sales_date_from: string;
    ad_sales_date_to: string;
    page_no: number;
    limit: number;
    sort_type?: string;
    offer_status?: string;
    offer_invalid_status?: string;
};

export const useGetMyAdByDateOfferListQuery = (
    adIdx: string,
    params: MyAdByDateOfferListParams,
) => {
    const queryResult = useInfiniteQuery<
        MyAdByDateOfferListApiResultType,
        Error
    >({
        queryKey: [
            "my-ad-sale-date-offer-list-" + adIdx + JSON.stringify(params),
            "my-ad-sale-date-offer-list-" + adIdx,
            "ad-sale-manage-refresh-target",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return AdMyApi.getMyAdByDateOfferList(adIdx, {
                ...params,
                page_no: pageParam,
            });
        },
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        cacheTime: 0,
        staleTime: 0,
        onError: (err) => {},
    });
    const defaultData: MyAdByDateOfferListApiResultType[] = [
        { offerList: [], totalCount: 0 },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function MyAdByDateOfferListPages(
    pages: MyAdByDateOfferListApiResultType[],
) {
    return pages
        .map((result) => result.offerList)
        .reduce((prev, next) => prev.concat(next));
}
