import { ReactElement } from "react";
import styled from "styled-components";

export const WalletCollectionHubListTypeItemListTitleWrapper = styled.div`
    display: flex;
    gap: 14px;
`;

export const WalletCollectionHubListTypeItemListTitleThumbnail = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 8px;
`;

export const WalletCollectionHubListTypeItemListTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: flex-start;
`;

export const WalletCollectionHubListTypeItemListTitleItemName = styled.div`
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
`;

export const WalletCollectionHubListTypeItemListTitleProjectName = styled.div`
    overflow: hidden;
    color: #888;
    text-overflow: ellipsis;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
`;

type Props = {
    itemThumbnailPath: string;
    itemName: string;
    projectName: string;
};

const WalletCollectionHubListTypeItemListTitle = (
    props: Props,
): ReactElement | null => {
    return (
        <WalletCollectionHubListTypeItemListTitleWrapper>
            <WalletCollectionHubListTypeItemListTitleThumbnail
                src={props.itemThumbnailPath}
            />
            <WalletCollectionHubListTypeItemListTitleContainer>
                <WalletCollectionHubListTypeItemListTitleItemName>
                    {props.itemName}
                </WalletCollectionHubListTypeItemListTitleItemName>
                <WalletCollectionHubListTypeItemListTitleProjectName>
                    {props.projectName}
                </WalletCollectionHubListTypeItemListTitleProjectName>
            </WalletCollectionHubListTypeItemListTitleContainer>
        </WalletCollectionHubListTypeItemListTitleWrapper>
    );
};

export default WalletCollectionHubListTypeItemListTitle;
