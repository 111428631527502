import { ReactElement, RefObject } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useHover } from "usehooks-ts";
import imageFullSizeViewInfoAtom from "../../../../store/ui/imageFullSizeViewInfoAtom";
import { useAtom } from "jotai";

export const ImageFullSizeViewButtonIconContainer = styled.div<{
    isShow: boolean;
}>`
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    bottom: 8px;
    right: 8px;
    cursor: pointer;
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 1;
        }
    }

    transition: all 300ms ease;

    @media ${(props) => props.theme.mobileL} {
        opacity: 1;
    }
`;

export const ImageFullSizeViewButtonIcon = styled.img``;

type Props = {
    src: string;
    info: string[];
    thumbnailRef: RefObject<HTMLImageElement>;
};

const ImageFullSizeViewButton = (props: Props): ReactElement | null => {
    const isParentHover = useHover(props.thumbnailRef);
    const [, setImageFullSizeViewInfo] = useAtom(imageFullSizeViewInfoAtom);

    return (
        <ImageFullSizeViewButtonIconContainer
            isShow={isParentHover}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setImageFullSizeViewInfo({
                    src: props.src,
                    info: props.info,
                });
            }}
        >
            <ImageFullSizeViewButtonIcon
                src={URL_CONSTANTS.ASSET_URL + "/icon/icon-full-size-view.svg"}
            />
        </ImageFullSizeViewButtonIconContainer>
    );
};

export default ImageFullSizeViewButton;

ImageFullSizeViewButton.defaultProps = {};
