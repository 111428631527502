import { useAtom } from "jotai";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";

type ShareDataType = {
    title: string;
    text: string;
    url: string;
};

export function useShare() {
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return function (data: ShareDataType) {
        function useClipboard(
            data: ShareDataType,
            resolve: (value?: any) => any,
            reject: (value?: any) => any,
        ) {
            try {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(data.url).then(() => {
                        resolve(data);
                    });
                } else {
                    throw "no clipboard api";
                }
            } catch (e) {
                try {
                    document.execCommand(data.url);
                    resolve(data);
                } catch (e) {
                    reject("공유하기 실패");
                }
            }
        }

        new Promise((resolve, reject) => {
            if (navigator.share) {
                navigator
                    .share(data)
                    .then(() => {
                        resolve("navigatorShareDone");
                    })
                    .catch((err) => {
                        if (err.code !== 20) {
                            useClipboard(data, resolve, reject);
                        } else {
                            resolve("cancel");
                        }
                    });
            } else {
                useClipboard(data, resolve, reject);
            }
        })
            .then((data: any) => {
                if (data !== "navigatorShareDone" && data !== "cancel") {
                    pushSnackBarListItem({
                        title: "",
                        desc: "해당 페이지의 링크가 복사되었습니다.",
                        iconUrl: "",
                        confirmBtnTitle: "",
                    });
                }
            })
            .catch((data) => {
                pushSnackBarListItem({
                    title: "",
                    desc: "공유하기에 실패하였습니다.",
                    iconUrl: "",
                    confirmBtnTitle: "",
                });
            });
    };
}
