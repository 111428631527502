import { Query, useMutation } from "react-query";
import { CommentApi } from "../../apis/CommentApi";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useAtom } from "jotai";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";
import { queryClient } from "../../App";

export type CommentAddApiParamsType = {
    comment: string;
};

export type CommentAddApiResType = {
    code: string;
    banwordList: BanwordType[];
};

export type BanwordType = {
    banword: string;
};

export const useCommentAddMutation = (
    commentType: string,
    targetId: number,
    params: CommentAddApiParamsType,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<null | CommentAddApiResType, Error>(
        () => CommentApi.addComment(commentType, targetId, params),
        {
            onSuccess: async () => {
                await queryClient.resetQueries({
                    predicate: (query: Query) =>
                        query.queryKey.includes(
                            "like-refetch-list-" + commentType + "/comment",
                        ),
                });

                pushSnackBarListItem({
                    title: "",
                    desc: "댓글이 정상적으로 등록되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
        },
    );
};
