import { ReactElement } from "react";
import styled from "styled-components";

export const CalendarBoldRowContainer = styled.div<{
    isMobileHide?: boolean;
    padding?: string;
    mt?: string;
}>`
    display: flex;
    align-items: center;
    gap: 5px;
    padding: ${({ padding }) => padding || "0 4px"};
    margin-top: ${({ mt }) => mt || "-6px"};
    line-height: 140%;

    @media ${(props) => props.theme.mobileL} {
        display: ${({ isMobileHide }) =>
            isMobileHide ? "none" : "inline-flex"};
        font-size: 10px;
    }
`;

export const CalendarBoldRowRate = styled.div`
    color: #ff4671;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 16.8px */
`;

export const CalendarBoldRowPrice = styled.div`
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 16.8px */
`;

type Props = {
    isMobileHide?: boolean;
    padding?: string;
    mt?: string;
    rate?: string;
    msg: string;
};

const CalendarBoldRow = (props: Props): ReactElement | null => {
    return (
        <CalendarBoldRowContainer
            isMobileHide={props.isMobileHide}
            mt={props.mt}
            padding={props.padding}
        >
            {props.rate && (
                <CalendarBoldRowRate>{props.rate}</CalendarBoldRowRate>
            )}
            <CalendarBoldRowPrice>{props.msg}</CalendarBoldRowPrice>
        </CalendarBoldRowContainer>
    );
};

export default CalendarBoldRow;
