import { useMutation } from "react-query";
import { CommentApi } from "../../apis/CommentApi";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useAtom } from "jotai";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { asyncPushSnackBarListItemAtom } from "../../store/ui/snackBarListAtom";

export type CommentAdjustApiParamsType = {
    comment: string;
};

export const useCommentAdjustMutation = (
    commentType: string,
    commentIdx: string,
    params: CommentAdjustApiParamsType,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<null, Error>(
        () => CommentApi.adjustComment(commentType, commentIdx, params),

        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "댓글이 정상적으로 수정되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
