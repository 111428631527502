import { Link } from "react-router-dom";
import styled from "styled-components";

export const InfoTableRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
`;

export const InfoTableMajorRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #555555;
`;

export const InfoTableSubRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: #888888;
    margin-top: -8px;
`;

export const InfoTableColumnValue = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: #222222;
    display: flex;
    align-items: flex-end;
`;

export const InfoTableColumnBoldValue = styled(InfoTableColumnValue)`
    color: #fa54fa;
    font-weight: 700;
`;

export const InfoTableSubColumnValue = styled(InfoTableColumnValue)`
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
`;

export const InfoTableColumnUnit = styled.span`
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: #888888;
    margin-left: 2px;
`;

export const InfoTableColumnWarningValue = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: #888888;
`;

export const InfoTableUnderLine = styled.div`
    border-bottom: 1px solid #dbdbdb;
    margin: 16px 0;
`;

export const InfoTableTermContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 6px;
    height: 80px;
    overflow-y: scroll;

    border: 1px solid #e5e5e5;
    border-radius: 10px;
    width: calc(100% - 32px);
`;

export const InfoTableTerm = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #666666;
    word-break: break-all;
    flex-wrap: wrap;
`;

export const InfoTableTermSpan = styled.span``;

export const InfoTableTermLink = styled(Link)`
    display: inline-flex;
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 0 3px;

    &:focus,
    &:visited,
    &:link,
    &:active {
        text-decoration: underline;
    }
`;

export const InfoTableTermCheckboxContainer = styled.div`
    margin-top: 10px;
`;

export const InfoTableTermAgreeLabel = styled.div`
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height, or 120% */
    margin-left: 12px;
    display: flex;
    align-items: center;

    color: #333333;
`;
