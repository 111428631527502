import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";
import CalendarCellBackgroundRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import CalendarCellRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellRow";
import CalendarCellBackgroundRowTwoColumn from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRowTwoColumn";
import { URL_CONSTANTS } from "../../../../../../../../../../../constants/UrlConstants";
import CalendarCellBackgroundSeveralRow from "../../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundSeveralRow";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";

export const AdBidCalendarCellPcBodyBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarCellPcBodyBidClosedAndPreparing = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
        isAdStop,
        isTodaySale,
    } = useGetAdDailyDateData(props);

    return !isAdStop ? (
        isTodaySale ? (
            myOfferInfo ? (
                <>
                    <>
                        <CalendarCellBackgroundRowTwoColumn
                            msg={"시스템 준비중"}
                            msgType={"secondary"}
                            iconPath={`${URL_CONSTANTS.ASSET_URL}/icon/icon-thunder-blue.svg`}
                            iconType={"blue"}
                            isShowIcon={myOfferInfo.buy_now_available_bool}
                        />
                        <CalendarCellBackgroundSeveralRow
                            firstRowMsg={"내 입찰"}
                            secondRowMsg={`${numberToStringWithComma(
                                myOfferInfo.offer_price,
                            )} KRW`}
                            type={"primary-weak"}
                            badgeText={
                                myOfferInfo.highest_price_bool
                                    ? "최고가"
                                    : undefined
                            }
                            badgeType={"primary"}
                        />
                        <HiddenKey
                            id={
                                "AD_BID_CLOSED_AD_PREPARING__notAdStop__dailySale__myOfferInfo"
                            }
                        />
                    </>
                </>
            ) : otherOfferInfo ? (
                <>
                    <>
                        <CalendarCellBackgroundRowTwoColumn
                            msg={"시스템 준비중"}
                            msgType={"secondary"}
                            iconPath={`${URL_CONSTANTS.ASSET_URL}/icon/icon-thunder-blue.svg`}
                            iconType={"blue"}
                            isShowIcon={otherOfferInfo.buy_now_available_bool}
                        />
                        <CalendarCellRow
                            msg={`${numberToStringWithComma(
                                otherOfferInfo.offer_price,
                            )} KRW`}
                            type={"normal"}
                        />
                        <HiddenKey
                            id={
                                "AD_BID_CLOSED_AD_PREPARING__notAdStop__dailySale__otherOfferInfo"
                            }
                        />
                    </>
                </>
            ) : myOfferSuccessInfo ? (
                <>
                    <CalendarCellBackgroundRow
                        type={"primary"}
                        msg={"내 광고 낙찰"}
                    />
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            myOfferSuccessInfo.offer_price,
                        )} KRW`}
                        type={"normal"}
                    />
                    <HiddenKey
                        id={
                            "AD_BID_CLOSED_AD_PREPARING__notAdStop__dailySale__myOfferSuccessInfo"
                        }
                    />
                </>
            ) : otherOfferSuccessInfo ? (
                <>
                    <CalendarCellBackgroundRow
                        type={"secondary"}
                        msg={"낙찰 완료"}
                    />
                    <CalendarCellRow
                        msg={`${numberToStringWithComma(
                            otherOfferSuccessInfo.offer_price,
                        )} KRW`}
                        type={"normal"}
                    />
                    <HiddenKey
                        id={
                            "AD_BID_CLOSED_AD_PREPARING__notAdStop__dailySale__otherOfferSuccessInfo"
                        }
                    />
                </>
            ) : (
                <>
                    <CalendarCellBackgroundRowTwoColumn
                        msg={"시스템 준비중"}
                        msgType={"secondary"}
                        iconPath={`${URL_CONSTANTS.ASSET_URL}/icon/icon-thunder-blue.svg`}
                        iconType={"blue"}
                        isShowIcon={true}
                    />
                    <HiddenKey
                        id={
                            "AD_BID_CLOSED_AD_PREPARING__notAdStop__notAdDailySale__else"
                        }
                    />
                </>
            )
        ) : (
            <>
                <CalendarCellBackgroundRow msg={"판매 중단"} type={"danger"} />
                <HiddenKey
                    id={"AD_BID_CLOSED_AD_PREPARING__notAdStop__notAdDailySale"}
                />
            </>
        )
    ) : (
        <>
            <HiddenKey
                id={"AD_BID_CLOSED_AD_PREPARING__AdStop__notAdDailySale"}
            />
        </>
    );
};

export default AdBidCalendarCellPcBodyBidClosedAndPreparing;
