import { ReactElement, useMemo } from "react";
import styled from "styled-components";
import { isMenuSelected } from "../../../../utils/Utils";
import { useLocation } from "react-router-dom";

export const SubHeaderItemContainer = styled.div<{ isSelected: boolean }>`
    position: relative;
    margin: auto 20px;
    padding: 13px 4px;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 1;
    cursor: pointer;
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#333333")};

    @media ${(props) => props.theme.mobileL} {
        margin: auto 8px;
    }
`;

export const SubHeaderItemUnderLine = styled.div<{ isSelected: boolean }>`
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    border-bottom: ${({ isSelected }) =>
        isSelected ? "2px solid #FA54FA" : "none"};
`;

type Props = {
    targetMenuPath: string;
    targetMenuName: string;
};

const SubHeaderItem = (props: Props): ReactElement | null => {
    const location = useLocation();

    const isSelected = useMemo(() => {
        return isMenuSelected(props.targetMenuPath);
    }, [location.pathname]);

    return (
        <SubHeaderItemContainer isSelected={isSelected}>
            {props.targetMenuName}
            <SubHeaderItemUnderLine isSelected={isSelected} />
        </SubHeaderItemContainer>
    );
};

export default SubHeaderItem;
