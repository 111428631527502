import React, { ReactElement } from "react";
import styled from "styled-components";
import { OrderTabRowValueUnit } from "./OrderTabRow";

export const OrderTabSubRowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 100%;
    color: #888888;
`;

export const OrderTabSubRowKey = styled.div`
    padding-left: 10px;
    display: flex;
    align-items: center;
`;

export const OrderTabSubRowValue = styled.div`
    display: flex;
`;

type Props = {
    rowKey: string | ReactElement;
    rowValue: string | ReactElement | ReactElement[];
    rowValueUnit?: string;
};

const OrderTabSubRow = (props: Props): ReactElement | null => {
    return (
        <OrderTabSubRowContainer>
            <OrderTabSubRowKey>{props.rowKey}</OrderTabSubRowKey>
            <OrderTabSubRowValue>
                {props.rowValue}
                {props.rowValueUnit && (
                    <OrderTabRowValueUnit>
                        {props.rowValueUnit}
                    </OrderTabRowValueUnit>
                )}
            </OrderTabSubRowValue>
        </OrderTabSubRowContainer>
    );
};

export default OrderTabSubRow;
