import { useOpenModal } from "../useOpenModal";
import ModalComponentInfoListAtom from "../../../store/ui/ModalComponentInfoListAtom";
import { useAtom } from "jotai";
import NotiListModal from "../../../components/common/modal/notiListModal/NotiListModal";

export const GLOBAL_NOTI_LIST_MODAL = "noti-list-modal";

export function useOpenNotiListModal() {
    const { openModalWithReturnValue } = useOpenModal();
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const searchResultModalInfo = modalComponentInfoList.find(
        (info) => info.id === GLOBAL_NOTI_LIST_MODAL,
    );

    return {
        openNotiListModal: async () => {
            return await openModalWithReturnValue(
                GLOBAL_NOTI_LIST_MODAL,
                "self-transition",
                (resolve) => {
                    return (
                        <NotiListModal
                            resolve={(value: any) => {
                                resolve(value);
                            }}
                        />
                    );
                },
                "noti-list",
            );
        },
        isOpenSearchResultModal: !!searchResultModalInfo,
    };
}
