import { CSSProperties, ReactElement } from "react";
import { SortableElement } from "react-sortable-hoc";
import styled from "styled-components";

export const DndListContainerItemContainer = styled.div<{}>`
    width: 100%;
    // z-index: 1; // z-index 주게되면 내 광고입찰 템플릿에서 드롭다운이 가려짐 - 다시 삭제해봄
`;

type Props = {
    dndItem: any;
    renderListItem: (value?: any) => ReactElement;
    itemWidth: number;
    itemPerRow: number;
    columnGap: number;
    rowGap: number;
    idx: number;
    dndListLength: number;
    exceptionalItemStyle?: CSSProperties;
};

const DndListItem = SortableElement<any>((props: Props) => {
    return (
        <DndListContainerItemContainer style={props.exceptionalItemStyle}>
            {props.renderListItem({
                dndListItemInfo: props.dndItem,
                index: props.idx,
            })}
        </DndListContainerItemContainer>
    );
});

export default DndListItem;
