import React, { ReactElement, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useWindowSize } from "usehooks-ts";
import { useAtom, useSetAtom } from "jotai";
import viewHeightAtom from "../../../../../../store/scroll/viewHeightAtom";
import { useLocation } from "react-router-dom";
import hasHeaderAtom from "../../../../../../store/ui/hasHeaderAtom";
import isScrollLockAtom from "../../../../../../store/scroll/isScrollLockAtom";
import Spinner from "../../../../commonUnitComponent/spinner/Spinner";
import {
    SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS,
    SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_LEFT_SIDE_BAR,
    SCROLL_BAR_ROOT_TYPE,
} from "../../ScrollbarType";
import { useSetCustomScrollMouseEvent } from "./useSetCustomScrollMouseEvent";
import { useSetCustomScrollEvent } from "./useSetCustomScrollEvent";
import { useSetLocationChangeHook } from "./useSetLocationChangeHook";
import isRootScrollingAtom from "../../../../../../store/scroll/isRootScrollingAtom";

const CustomScrollWindow = styled.div<{
    isRootType: boolean;
    isScrollLock: boolean;
    vh: number;
    fullPagePositionTop: number;
}>`
    z-index: 1;
    top: ${({ fullPagePositionTop, isRootType }) =>
        isRootType ? `${fullPagePositionTop}px` : 0};
    width: 100%;
    height: ${({ isRootType, vh, fullPagePositionTop }) =>
        isRootType ? `${100 * vh - fullPagePositionTop}px` : "inherit"};

    max-height: ${({ isRootType, vh, fullPagePositionTop }) =>
        isRootType ? `${100 * vh - fullPagePositionTop}px` : "inherit"};

    position: ${({ isRootType }) => (isRootType ? "fixed" : "static")};

    overflow-y: ${({ isRootType, isScrollLock }) =>
        isRootType && isScrollLock ? "hidden" : "scroll"};

    &::-webkit-scrollbar {
        display: none;
    }

    transition: all 200ms ease;
    scrollbar-width: none;
    -ms-overflow-style: none;
`;

const CustomScrollbarArea = styled.div<{
    isHide: boolean;
    isScrolling: boolean;
    isThinType: boolean;
    isRootType: boolean;
    vh: number;
    fullPagePositionTop: number;
}>`
    position: ${({ isRootType }) => (isRootType ? "fixed" : "absolute")};
    top: ${({ isRootType, fullPagePositionTop }) =>
        isRootType ? fullPagePositionTop + "px" : 0};
    right: ${({ isThinType }) => (!isThinType ? "0" : "-12px")};
    width: ${({ isThinType }) => (!isThinType ? "12px" : "6px")};
    height: ${({ isRootType, fullPagePositionTop, vh }) =>
        isRootType ? vh * 100 - fullPagePositionTop + "px" : "100%"};
    z-index: 2;

    background: transparent;
    opacity: ${({ isScrolling }) => (isScrolling ? 1 : 0)};
    display: ${({ isHide }) => (isHide ? "none" : "block")};
    transition: opacity 1000ms ease-in;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 1;
        }
    }
`;

const CustomScrollbarContainer = styled.div<{
    isScrolling: boolean;
    isThinType: boolean;
}>`
    position: absolute;
    right: ${({ isThinType }) => (!isThinType ? "4px" : "1px")};
    width: ${({ isThinType }) => (!isThinType ? "8px" : "4px")};
    ${(props) => props.theme.zIndexScrollBar};

    background: ${({ isScrolling }) => (isScrolling ? "#999999" : "#d9d9d9")};

    border-radius: 4px;
    transition: 300ms ease background;

    &:hover {
        background: #999999;
    }
`;

const CustomScrollChildrenWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

const CustomScrollbarSpinnerContainer = styled.div<{ isShow: boolean }>`
    width: 52px;
    height: ${({ isShow }) => (isShow ? "52px" : "0")};
    position: absolute;
    bottom: ${({ isShow }) => (isShow ? "26px" : "20px")};
    opacity: ${({ isShow }) => (isShow ? "1" : "0")};
    left: 50%;
    transform: translate(-50%, 0);
    background: white;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ${({ isShow }) =>
        isShow
            ? "all 300ms ease"
            : "bottom 300ms ease, opacity 300ms ease, height 10ms 300ms ease"};
    z-index: 100;
    filter: drop-shadow(0px 8px 30px rgba(0, 0, 0, 0.15))
        drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
`;

export const getFullPagePositionTop = function (
    width: number,
    isRootType: boolean,
    hasHeader: boolean,
    pathname: string,
) {
    return 0;
};

export type infiniteScrollCallbackType = (
    showSpinner: () => void,
) => Promise<void>;

type Props = {
    children: ReactElement;
    type: string;
    infiniteScrollCallback?: infiniteScrollCallbackType;
    isHide: boolean;
    isThin: boolean;
    isSaveScrollTop?: boolean;
    id: string;
};

const CustomScrollbar = (props: Props): ReactElement | null => {
    const location = useLocation();

    const [isScrollLock] = useAtom(isScrollLockAtom);
    const [hasHeader] = useAtom(hasHeaderAtom);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const windowRef = useRef<HTMLDivElement>(null);

    // root type 이 스크롤되는지 여부가 modalProvider 에서 쓰임.
    const setIsRootScrolling = useSetAtom(isRootScrollingAtom);
    const [isScrolling, setIsScrolling] = useState(false);

    const [vh] = useAtom(viewHeightAtom);

    const [, _setIsContentsScrollEnd] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const { width } = useWindowSize();
    const isRootType = props.type === SCROLL_BAR_ROOT_TYPE;
    const isDoubleSideBarLayoutLeftSideBarType =
        props.type === SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_LEFT_SIDE_BAR;
    const isDoubleSideBarLayoutContentsType =
        props.type === SCROLL_BAR_DOUBLE_SIDE_BAR_LAYOUT_CONTENTS;

    const [isLoadingInfiniteCallback, setIsLoadingInfiniteCallback] =
        useState(false);

    // full 타입(전체 스크롤)을 관리하는 Ref 는 window 에 따로 빼둔다.
    if (isRootType) {
        window.fullWindowRef = windowRef;
    }

    const fullPagePositionTop = getFullPagePositionTop(
        width,
        isRootType,
        hasHeader,
        location.pathname,
    );

    // mounted hook 에서
    // 스크롤을 위한 마우스 이벤트 등록
    const { handleMouseDown } = useSetCustomScrollMouseEvent(
        wrapperRef,
        windowRef,
        fullPagePositionTop,
    );

    // 스크롤, 스크롤종료 이벤트 등록
    useSetCustomScrollEvent(
        wrapperRef,
        windowRef,
        setIsRootScrolling,
        setIsScrolling,
        setScrollTop,
        isRootType,
        isDoubleSideBarLayoutContentsType,
        setIsLoadingInfiniteCallback,
        props.id,
        props.infiniteScrollCallback,
        props.isSaveScrollTop,
    );

    // location.pathname 변환 훅
    const { isHideByPathname } = useSetLocationChangeHook(
        windowRef,
        wrapperRef,
        setScrollTop,
        props.isHide,
        isRootType,
        isDoubleSideBarLayoutContentsType,
        isDoubleSideBarLayoutLeftSideBarType,
        props.id,
        props.isSaveScrollTop,
    );

    const scrollHeight =
        Math.round(
            ((windowRef.current?.clientHeight || 0) /
                (wrapperRef.current?.clientHeight || 0)) *
                1000000,
        ) / 10000;

    const memoizedChildren = useMemo(() => {
        return props.children;
    }, [props.children]);

    return (
        <CustomScrollWindow
            id={`${props.id}__custom-scrollbar-window`}
            vh={vh}
            isRootType={isRootType}
            isScrollLock={isScrollLock}
            ref={windowRef}
            fullPagePositionTop={fullPagePositionTop}
        >
            <CustomScrollbarArea
                isHide={isHideByPathname}
                vh={vh}
                isScrolling={isScrolling}
                isRootType={isRootType}
                isThinType={props.isThin}
                fullPagePositionTop={fullPagePositionTop}
            >
                <CustomScrollbarContainer
                    id={`${props.id}__custom-scrollbar-scrollbar`}
                    isScrolling={isScrolling}
                    onMouseDown={(e) => {
                        handleMouseDown(e);
                    }}
                    onTouchStart={(e) => {
                        handleMouseDown(e);
                    }}
                    style={{
                        height: scrollHeight > 100 ? 100 : scrollHeight + "%",
                        top: scrollTop + "%",
                    }}
                    isThinType={props.isThin}
                />
            </CustomScrollbarArea>

            <CustomScrollChildrenWrapper
                ref={wrapperRef}
                id={`${props.id}__custom-scrollbar-children__wrapper`}
            >
                {memoizedChildren}
            </CustomScrollChildrenWrapper>
            <CustomScrollbarSpinnerContainer isShow={isLoadingInfiniteCallback}>
                <Spinner width={28} height={28} />
            </CustomScrollbarSpinnerContainer>
        </CustomScrollWindow>
    );
};

export default CustomScrollbar;
