import { ReactElement } from "react";
import styled from "styled-components";

export const AdManageCommonHistoryColumnPaymentAdjustStatusContainer = styled.div`
    width: 108px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    padding: 10px 0;
    height: auto;
`;

export const convertPaymentAdjustStatus = (paymentAdjustStatus: string) => {
    switch (paymentAdjustStatus) {
        case "0":
            return "결제완료";
        case "1":
            return "광고집행완료";
        case "2":
            return "정산완료";
        case "5":
            return "분쟁발생";
        case "6":
            return "환불완료";
        case "7":
            return "일부환불완료";
        default:
            return "-";
    }
};

type Props = {
    paymentAdjustStatus: string;
    adRoundStatus: string;
    onClickHandler?: () => void;
};

const AdManageCommonHistoryColumnPaymentAdjustStatus = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnPaymentAdjustStatusContainer
            onClick={() => {
                if (props.onClickHandler) {
                    props.onClickHandler();
                }
            }}
        >
            {convertPaymentAdjustStatus(props.paymentAdjustStatus)}
        </AdManageCommonHistoryColumnPaymentAdjustStatusContainer>
    );
};

export default AdManageCommonHistoryColumnPaymentAdjustStatus;
