import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import HolderList from "../../../../../../common/commonUiComponent/holderList/HolderList";
import { useItemOwnerListQuery } from "../../../../../../../query/item/ownerItemList/useItemOwnerListQuery";
import { useDebounce } from "usehooks-ts";
import { PathAfterResolveType } from "../../../../../../../hooks/modal/useOpenModal";

export const MarketItemHolderListModalContainer = styled.div``;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    itemIdx: string;
};

const MarketItemHolderListModal = (props: Props): ReactElement | null => {
    const [searchInput, setSearchInput] = useState("");

    // 디바운싱 0.5s
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const itemOwnerListQueryResult = useItemOwnerListQuery(
        props.itemIdx,
        {
            page_no: 1,
            keyword: debouncedSearchInput,
            limit: 20,
        },
        true,
    );

    return (
        <HolderList
            resolve={props.resolve}
            titleList={["보유자 리스트"]}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            selectedTabIdx={0}
            getDataQueryResult={[itemOwnerListQueryResult]}
        />
    );
};

export default MarketItemHolderListModal;
