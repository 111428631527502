import CssFilterConverter from "css-filter-converter";
import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";

export const DefaultErrorFallbackContainer = styled.div<{
    minHeight?: string;
}>`
    display: flex;
    width: inherit;
    min-width: 100%;
    min-height: ${({ minHeight }) => (minHeight ? minHeight : "300px")};
    height: inherit;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    color: #666;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 16.9px */
`;

export const DefaultErrorIcon = styled.img`
    width: 32px;
    height: 32px;
`;

type Props = {
    error: any;
    minHeight?: string;
};

const DefaultErrorComponent = (props: Props): ReactElement | null => {
    return (
        <DefaultErrorFallbackContainer minHeight={props.minHeight}>
            <DefaultErrorIcon
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-warning.svg`}
                style={{
                    filter:
                        CssFilterConverter.hexToFilter("#888888").color + "",
                }}
            />
            Error:{" "}
            {"message" in props.error ? props.error.message : "Internal Error"}
        </DefaultErrorFallbackContainer>
    );
};

export default DefaultErrorComponent;
