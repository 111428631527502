export type BrandInfoType = {
    brand_idx: string;
    brand_id: string;
    brand_name: string;
    brand_description?: string;
    brand_bi_full_path: string;
};

export const DEFAULT_BRAND_INFO_TYPE_DATA: BrandInfoType = {
    brand_idx: "0",
    brand_id: "",
    brand_name: "",
    brand_description: "",
    brand_bi_full_path: "",
};
