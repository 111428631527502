import { useInfiniteQuery } from "react-query";
import { NotificationApi } from "../../apis/NotificationApi";

export type GetNotificationApiResultType = {
    notificationList: NotificationListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type NotificationListItemType = {
    created_at: string;
    endPoint: string;
    notification_idx: string;
    notification_type: string;
    read_bool: boolean;
    title: string;
    sub_title: string;
    thumbnail_full_path: string;
};

export type GetNotificationParamsType = {
    list_type: string;
    only_not_read_bool: number; // 1: true 0: false
    limit: number;
    page_no: number;
};
export const useGetNotificationListQuery = (
    params: GetNotificationParamsType,
    isEnabled: boolean,
) => {
    const queryResult = useInfiniteQuery<GetNotificationApiResultType, Error>({
        queryKey: [
            "get-notification-list" + JSON.stringify(params),
            "get-notification-list",
        ],
        queryFn: ({ pageParam = 1 }) =>
            NotificationApi.getList({
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        enabled: isEnabled,
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: GetNotificationApiResultType[] = [
        {
            notificationList: [],
            totalCount: 0,
        },
    ];

    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadNotificationListPages(
    pages: GetNotificationApiResultType[],
) {
    return pages
        .map((result) => result.notificationList)
        .reduce((prev, next) => prev.concat(next));
}
