import React, {
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";
import { FilterItemType } from "../doubleSideBar/leftSideBar/LeftSideBar";
import { ResponsiveWidth } from "../../../../styles/Theme";
import SearchFilterButton from "./components/SearchFilterButton/SearchFilterButton";
import LayerTypeSearchFilter from "./components/LayerTypeSearchFilter";
import styled from "styled-components";
import FullModalTypeSearchFilter from "./components/FullModalTypeSearchFilter";
import ModalTypeSearchFilter from "./components/ModalTypeSearchFilter";
import { useOpenModal } from "../../../../hooks/modal/useOpenModal";
import { useAtom } from "jotai";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";

export const SearchFilterContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export type FilterType = {
    title: string;
    key: string;
    isCheckbox: boolean;
    filterList: FilterItemType[];
    subTitle?: string | ReactElement;
    totalAdditionalExceptionOnChangeHandler?: (
        value: boolean,
        tempFilters: FilterType[],
    ) => FilterType[];
};

type Props = {
    filters: FilterType[];
    setFilters: (value: FilterType[]) => void;
    filterItemWidth: string;
    layerPositionStyleString: string;
    title: string;
    id: string;
    isForceLayerType: boolean;
};

const SearchFilter = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();
    const [isSearchFilterOpen, setIsSearchFilterOpen] = useState(false);
    const filterContainerRef = useRef(null);
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);

    useOnClickOutside(filterContainerRef, () => {
        if (modalComponentInfoList.length === 0) setIsSearchFilterOpen(false);
    });

    const isModal = useMemo(() => {
        return (
            !props.isForceLayerType &&
            width !== 0 &&
            width <= ResponsiveWidth.tabletM
        );
    }, [width]);

    const isFullModal =
        !props.isForceLayerType &&
        !isModal &&
        (props.id === "left-side-header" ||
            props.id === "wallet-collection-item");

    const { openModal } = useOpenModal();

    const openModalTypeSearchFilter = async () => {
        return await openModal(
            "modal-type-search-filter",
            "bottomSheet",
            (resolve) => {
                return (
                    <ModalTypeSearchFilter
                        {...props}
                        resolve={resolve}
                        isSearchFilterOpen={isSearchFilterOpen}
                        setIsSearchFilterOpen={setIsSearchFilterOpen}
                        setFilters={props.setFilters}
                    />
                );
            },
        );
    };

    const openFullModalTypeSearchFilter = async () => {
        return await openModal(
            "full-modal-type-search-filter",
            "modal",
            (resolve) => {
                return (
                    <FullModalTypeSearchFilter
                        resolve={resolve}
                        isSearchFilterOpen={isSearchFilterOpen}
                        setIsSearchFilterOpen={setIsSearchFilterOpen}
                        filterItemWidth={props.filterItemWidth}
                        title={props.title}
                        id={props.id}
                        filters={props.filters}
                        setFilters={props.setFilters}
                    />
                );
            },
        );
    };

    useEffect(() => {
        if (isSearchFilterOpen) {
            if (isFullModal) {
                openFullModalTypeSearchFilter().then(() => {
                    setIsSearchFilterOpen(false);
                });
            } else if (isModal) {
                openModalTypeSearchFilter().then(() => {
                    setIsSearchFilterOpen(false);
                });
            }
        }
    }, [isSearchFilterOpen]);

    return (
        <SearchFilterContainer ref={filterContainerRef}>
            <SearchFilterButton
                id={props.id}
                isSearchFilterOpen={isSearchFilterOpen}
                setIsSearchFilterOpen={setIsSearchFilterOpen}
            />

            {!isModal && !isFullModal && (
                <LayerTypeSearchFilter
                    isSearchFilterOpen={isSearchFilterOpen}
                    setIsSearchFilterOpen={setIsSearchFilterOpen}
                    filterItemWidth={props.filterItemWidth}
                    id={props.id}
                    layerPositionStyleString={props.layerPositionStyleString}
                    filters={props.filters}
                    setFilters={props.setFilters}
                />
            )}
        </SearchFilterContainer>
    );
};

export default SearchFilter;

SearchFilter.defaultProps = {
    isForceLayerType: false,
};
