import { ReactElement } from "react";
import { DateRange } from "react-day-picker";
import styled from "styled-components";
import AdManageCommonMobileTableLayout from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonMobileTableLayout";
import { TableHeaderDataType } from "../../../../../common/commonUnitComponent/commonTable/CommonTable";
import {
    AdManageCommonMobileTableActionBtn,
    AdManageCommonMobileTableActionBtnContainer,
    AdManageCommonMobileTableContainer,
} from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";
import AdManageCommonMobileTableCell from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonMobileTableCell";
import AdManageCommonHistoryColumnName from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnName";
import AdManageCommonHistoryColumnDate from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnDate";
import AdManageCommonHistoryColumnOfferStatus from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnOfferStatus";
import {
    getAdV2OfferReceiptInfo,
    useOpenAdBidReceiptModal,
} from "../../../../../../hooks/modal/openModal/useOpenAdBidReceiptModal";
import AdManageCommonHistoryColumnNormal from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnNormal";
import AdManageCommonHistoryColumnBrand from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBrand";
import AdManageCommonHistoryColumnPrice from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPrice";
import {
    MyAdBuyOfferStatusInfoParams,
    MyAdBuyRoundStatusInfoParams,
    useOpenCommonStatusInfoModal,
} from "../../../../../../hooks/modal/openModal/useOpenCommonStatusInfoModal";
import { AllAdMyBuyOfferListItemType } from "../../../../../../types/ad/adMy/allAdMyBuyOffer/AllAdMyBuyOfferListItemType";
import {
    getIsTodaySale,
    staticConvertAdStatusKR,
} from "../../../../../../hooks/ad/staticConvertAdStatusKR";
import { convertSafariAdSalesDate } from "../../../../../../utils/Utils";

export const AdBuyManageAllOfferListMobileTableContainer = styled.div``;

type Props = {
    selectedDateRange: DateRange;
    allAdMyOfferList: AllAdMyBuyOfferListItemType[];
};

const AdBuyManageAllOfferListMobileTable = (
    props: Props,
): ReactElement | null => {
    const { openCommonStatusInfoModal } = useOpenCommonStatusInfoModal(
        "mobile-my-ad-status-info",
    );

    const TABLE_KEY = "ad_all_offer_list_mobile_table_";
    const { openAdBidReceiptModal } = useOpenAdBidReceiptModal();
    const convertMobileRowValue = (
        rawRowKey: TableHeaderDataType,
        rawRowValue: AllAdMyBuyOfferListItemType,
    ) => {
        switch (rawRowKey.key) {
            // 구매 입찰 내역 - mobileTable : 입찰일시
            case TABLE_KEY + "date":
                return (
                    <AdManageCommonHistoryColumnDate
                        date={new Date(rawRowValue.offerInfo.created_at)}
                        format={"yyyy. MM. dd. HH:mm:ss"}
                    />
                );

            // 구매 입찰 내역 - mobileTable : 입찰 상태
            case TABLE_KEY + "ad_offer_status":
                return (
                    <AdManageCommonHistoryColumnOfferStatus
                        offerStatus={rawRowValue.offerInfo.offer_status}
                        offerInvalidStatus={
                            rawRowValue.offerInfo.offer_invalid_status
                        }
                        onClickHandler={async () => {
                            await openAdBidReceiptModal(
                                getAdV2OfferReceiptInfo(rawRowValue),
                            );
                        }}
                    />
                );

            // 구매 입찰 내역 - mobileTable : 광고 상태
            case TABLE_KEY + "ad_status":
                return (
                    <AdManageCommonHistoryColumnNormal
                        msg={staticConvertAdStatusKR(
                            rawRowValue.advertisementDailyInfo
                                .ad_daily_status_code,
                            getIsTodaySale(
                                rawRowValue.advertisementDailyInfo
                                    .ad_sales_daily_status,
                            ),
                            rawRowValue.advertisementInfo.ad_stop_switch_bool,
                        )}
                    />
                );

            // 구매 입찰 내역 - mobileTable : 브랜드
            case TABLE_KEY + "brand":
                return (
                    <AdManageCommonHistoryColumnBrand
                        brandId={rawRowValue.advertisementInfo.brand_id}
                        brandName={rawRowValue.advertisementInfo.brand_id}
                    />
                );

            // 구매 입찰 내역 - mobileTable : 입찰가
            case TABLE_KEY + "offer_price":
                return (
                    <AdManageCommonHistoryColumnPrice
                        offerPrice={rawRowValue.offerInfo.offer_price}
                    />
                );

            // 구매 입찰 내역 - mobileTable : 광고 시작일
            case TABLE_KEY + "ad_started_at":
                return (
                    <AdManageCommonHistoryColumnDate
                        date={
                            new Date(
                                convertSafariAdSalesDate(
                                    rawRowValue.advertisementDailyInfo
                                        .ad_sales_date,
                                ),
                            )
                        }
                        format={"yyyy.MM.dd"}
                    />
                );

            default:
                return <span>{rawRowKey.key} is not found</span>;
        }
    };

    const mobileHeaderData: TableHeaderDataType[] = [
        {
            key: TABLE_KEY + "date",
            component: "입찰일시",
        },
        {
            key: TABLE_KEY + "ad_offer_status",
            component: "입찰 상태",
            questionMarkHandler: async () => {
                await openCommonStatusInfoModal(MyAdBuyOfferStatusInfoParams);
            },
        },
        {
            key: TABLE_KEY + "ad_status",
            component: "광고 상태",
            questionMarkHandler: async () => {
                await openCommonStatusInfoModal(MyAdBuyRoundStatusInfoParams);
            },
        },
        {
            key: TABLE_KEY + "brand",
            component: "브랜드",
        },
        {
            key: TABLE_KEY + "offer_price",
            component: "입찰가(KRW)",
        },
        {
            key: TABLE_KEY + "ad_started_at",
            component: "광고 시작일",
        },
    ];

    return (
        <AdManageCommonMobileTableLayout
            isNoData={props.allAdMyOfferList.length === 0}
        >
            <AdManageCommonMobileTableContainer>
                {props.allAdMyOfferList.map(
                    (offer: AllAdMyBuyOfferListItemType, offerIdx: number) => {
                        return (
                            <AdManageCommonMobileTableCell
                                key={offerIdx}
                                mobileHeaderDataList={mobileHeaderData}
                                rawRowValue={offer}
                                titleComponent={
                                    <AdManageCommonHistoryColumnName
                                        adProductName={
                                            offer.advertisementInfo
                                                .ad_product_name
                                        }
                                        adProductSubName={
                                            offer.advertisementInfo
                                                .ad_product_sub_name
                                        }
                                        adThumbnail={
                                            offer.advertisementInfo
                                                .ad_thumbnail_path
                                        }
                                    />
                                }
                                convertRowValue={convertMobileRowValue}
                                buttonComponent={
                                    <AdManageCommonMobileTableActionBtnContainer>
                                        <AdManageCommonMobileTableActionBtn
                                            onClick={() => {
                                                window.open(
                                                    `/ad/${offer.advertisementInfo.ad_idx}/offer/${offer.offerInfo.offer_idx}/template`,
                                                );
                                            }}
                                        >
                                            입찰 템플릿
                                        </AdManageCommonMobileTableActionBtn>
                                    </AdManageCommonMobileTableActionBtnContainer>
                                }
                            />
                        );
                    },
                )}
            </AdManageCommonMobileTableContainer>
        </AdManageCommonMobileTableLayout>
    );
};

export default AdBuyManageAllOfferListMobileTable;
