import { useMutation } from "react-query";
import { BrandApi } from "../../apis/BrandApi";
import { getApiErrorMessage } from "../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";

export const useBrandFollowMutation = (brandIdx: string) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    return useMutation<null, Error>(() => BrandApi.follow(brandIdx), {
        onError: async (error) => {
            const errorResult = getApiErrorMessage(error);
            await openCommonErrorModal(errorResult);
        },
    });
};
