import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../../common/commonUnitComponent/commonTable/CommonTable";
import {
    AdHistoryTableBodyColumn,
    AdHistoryTableHeaderColumn,
    AdHistoryTableHeaderQuestionMark,
} from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";
import { CommonTC } from "../../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import AdManageCommonHistoryColumnDate from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnDate";
import AdManageCommonHistoryColumnName from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnName";
import AdManageCommonHistoryColumnBrand from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBrand";
import AdManageCommonHistoryColumnPrice from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPrice";
import AdManageCommonHistoryColumnAdBtn from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnAdBtn";
import AdManageCommonHistoryColumnBidder from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBidder";
import { DateRange } from "react-day-picker";
import AdManageCommonHistoryColumnAdStatus from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnAdStatus";
import AdHistoryColumnPaymentAdjustStatus from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPaymentAdjustColumn";
import {
    MyAdBuyRoundStatusInfoParams,
    MyAdSellPaymentAdjustStatusInfoParams,
    useOpenCommonStatusInfoModal,
} from "../../../../../../hooks/modal/openModal/useOpenCommonStatusInfoModal";
import { AD_SALE_HISTORY } from "../../../_adManageLayout/AdManageTableContentsLayout";
import { MyAdDailyListItemType } from "../../../../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";
import { useOpenAdSaleManageOfferListModal } from "../../../../../../hooks/modal/openModal/useOpenAdSaleManageOfferListModal";
import {
    getIsTodaySale,
    staticConvertAdStatusKR,
} from "../../../../../../hooks/ad/staticConvertAdStatusKR";
import { convertSafariAdSalesDate } from "../../../../../../utils/Utils";

const DateColumnWidth = css`
    width: 80px;
`;

const StateColumnWidth = css`
    width: 108px;
`;

const TitleColumnWidth = css`
    width: 316px;
`;
const BrandColumnWidth = css`
    width: 120px;
`;

const StartPriceColumnWidth = css`
    width: 92px;
`;

const PriceColumnWidth = css`
    width: 92px;
`;

const BidderColumnWidth = css`
    width: 80px;
`;
const ButtonColumnWidth = css`
    width: 104px;
`;

export const AdSaleManageAdListTableDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${DateColumnWidth};
`;

export const AdSaleManageAdListTableStateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${StateColumnWidth};
`;

export const AdSaleManageAdListTableTitleColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${TitleColumnWidth};
`;

export const AdSaleManageAdListTableBrandColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${BrandColumnWidth};
`;

export const AdSaleManageAdListTablePriceColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${PriceColumnWidth};
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const AdSaleManageAdListTableBidderColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${BidderColumnWidth};
`;

export const AdSaleManageAdListTableButtonColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${ButtonColumnWidth};
`;

export const AdSaleManageAdListTableContainer = styled.div``;

export const MyAdSaleListPCTableContainer = styled.div`
    @media ${(props) => props.theme.tabletM} {
        display: none;
    }
`;

export const MyAdSaleListMobileTableContainer = styled.div`
    display: none;

    @media ${(props) => props.theme.tabletM} {
        display: block;
    }
`;

export const AdSaleManageAdListTablePriceTableBodyColumnContainer = styled(
    AdHistoryTableBodyColumn,
)`
    gap: 8px;
`;

type Props = {
    selectedDateRange: DateRange;
    myAdDailyList: MyAdDailyListItemType[];
};

const AdSaleManageAdListPcTable = (props: Props): ReactElement | null => {
    const type = AD_SALE_HISTORY;

    const { openCommonStatusInfoModal } =
        useOpenCommonStatusInfoModal("my-ad-sell-offer");

    const { openAdSaleManageOfferListModal } =
        useOpenAdSaleManageOfferListModal();

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "dateColumn",
            component: (
                <AdSaleManageAdListTableDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고 적용일
                    </AdHistoryTableHeaderColumn>
                </AdSaleManageAdListTableDateColumnContainer>
            ),
        },
        {
            key: "roundStatusColumn",
            component: (
                <AdSaleManageAdListTableStateColumnContainer
                    textAlign={"center"}
                    key={"roundStatusColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고 상태
                        <AdHistoryTableHeaderQuestionMark
                            onClick={async () => {
                                await openCommonStatusInfoModal(
                                    MyAdBuyRoundStatusInfoParams,
                                );
                            }}
                        >
                            ?
                        </AdHistoryTableHeaderQuestionMark>
                    </AdHistoryTableHeaderColumn>
                </AdSaleManageAdListTableStateColumnContainer>
            ),
        },
        {
            key: "paymentAdjustStatusColumn",
            component: (
                <AdSaleManageAdListTableStateColumnContainer
                    textAlign={"center"}
                    key={"paymentAdjustStatusColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        정산 상태
                        <AdHistoryTableHeaderQuestionMark
                            onClick={async () => {
                                await openCommonStatusInfoModal(
                                    MyAdSellPaymentAdjustStatusInfoParams,
                                );
                            }}
                        >
                            ?
                        </AdHistoryTableHeaderQuestionMark>
                    </AdHistoryTableHeaderColumn>
                </AdSaleManageAdListTableStateColumnContainer>
            ),
        },
        {
            key: "titleColumn",
            component: (
                <AdSaleManageAdListTableTitleColumnContainer
                    textAlign={"center"}
                    key={"titleColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고 상품명
                    </AdHistoryTableHeaderColumn>
                </AdSaleManageAdListTableTitleColumnContainer>
            ),
        },
        {
            key: "brandColumn",
            component: (
                <AdSaleManageAdListTableBrandColumnContainer
                    textAlign={"center"}
                    key={"brandColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        브랜드
                    </AdHistoryTableHeaderColumn>
                </AdSaleManageAdListTableBrandColumnContainer>
            ),
        },
        {
            key: "priceColumn",
            component: (
                <AdSaleManageAdListTablePriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        희망가 (KRW)
                    </AdHistoryTableHeaderColumn>
                    <AdHistoryTableHeaderColumn>
                        낙찰가 (KRW)
                    </AdHistoryTableHeaderColumn>
                </AdSaleManageAdListTablePriceColumnContainer>
            ),
        },
        {
            key: "bidderColumn",
            component: (
                <AdSaleManageAdListTableBidderColumnContainer
                    textAlign={"center"}
                    key={"bidderColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        낙찰자
                    </AdHistoryTableHeaderColumn>
                </AdSaleManageAdListTableBidderColumnContainer>
            ),
        },
        {
            key: "buttonColumn",
            component: (
                <AdSaleManageAdListTableButtonColumnContainer
                    textAlign={"center"}
                    key={"buttonColumn"}
                />
            ),
        },
    ];

    const data = props.myAdDailyList.map((item: MyAdDailyListItemType) => {
        return {
            // 판매 목록 - mobileTable : 광고 게재일
            dateColumn: (
                <AdSaleManageAdListTableDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnDate
                            date={
                                new Date(
                                    convertSafariAdSalesDate(
                                        item.ad_sales_date,
                                    ),
                                )
                            }
                            format={"yyyy.MM.dd."}
                        />
                    </AdHistoryTableBodyColumn>
                </AdSaleManageAdListTableDateColumnContainer>
            ),

            // 판매 목록 - mobileTable : 광고 상태
            roundStatusColumn: (
                <AdSaleManageAdListTableStateColumnContainer
                    textAlign={"center"}
                    key={"roundStatusColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnAdStatus
                            adDailyStatusKR={staticConvertAdStatusKR(
                                item.ad_daily_status_code,
                                getIsTodaySale(
                                    item.adSaleInfo?.ad_sales_daily_status,
                                ),
                                item.ad_stop_switch_bool,
                            )}
                        />
                    </AdHistoryTableBodyColumn>
                </AdSaleManageAdListTableStateColumnContainer>
            ),

            // 판매 목록 - mobileTable : 정산 상태
            paymentAdjustStatusColumn: (
                <AdSaleManageAdListTableStateColumnContainer
                    textAlign={"center"}
                    key={"paymentAdjustStatusColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdHistoryColumnPaymentAdjustStatus
                            adRoundStatus={""}
                            paymentAdjustStatus={
                                item.payment_adjust_status
                                    ? item.payment_adjust_status
                                    : ""
                            }
                        />
                    </AdHistoryTableBodyColumn>
                </AdSaleManageAdListTableStateColumnContainer>
            ),
            titleColumn: (
                <AdSaleManageAdListTableTitleColumnContainer
                    textAlign={"left"}
                    key={"titleColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnName
                            adProductName={item.campaign_name}
                            isOnlyProductName={true}
                        />
                    </AdHistoryTableBodyColumn>
                </AdSaleManageAdListTableTitleColumnContainer>
            ),

            // 판매 목록 - mobileTable : 브랜드
            brandColumn: (
                <AdSaleManageAdListTableBrandColumnContainer
                    textAlign={"center"}
                    key={"brandColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnBrand
                            brandId={item.brand_id}
                            brandName={item.brand_name}
                        />
                    </AdHistoryTableBodyColumn>
                </AdSaleManageAdListTableBrandColumnContainer>
            ),

            // 판매 목록 - mobileTable : 판매 희망가 / 낙찰가
            priceColumn: (
                <AdSaleManageAdListTablePriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <AdSaleManageAdListTablePriceTableBodyColumnContainer>
                        <AdManageCommonHistoryColumnPrice
                            offerPrice={Math.round(
                                // 판매가
                                item.adSaleInfo.ad_reserve_price * 1.1 || -1,
                            )}
                        />
                        <AdManageCommonHistoryColumnPrice
                            fontColor={
                                item.offerSuccessInfo ? "#FA54FA" : "#555"
                            }
                            fontWeight={500}
                            offerPrice={
                                item.offerSuccessInfo
                                    ? item.offerSuccessInfo.offer_price
                                    : "-"
                            }
                        />
                    </AdSaleManageAdListTablePriceTableBodyColumnContainer>
                </AdSaleManageAdListTablePriceColumnContainer>
            ),

            // 판매 목록 - mobileTable : 낙찰자
            bidderColumn: (
                <AdSaleManageAdListTableBidderColumnContainer
                    textAlign={"center"}
                    key={"adStartDateColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        {item.offerSuccessInfo ? (
                            <AdManageCommonHistoryColumnBidder
                                userIdx={
                                    item.offerSuccessInfo.offer_success_user_idx
                                }
                                nickname={
                                    item.offerSuccessInfo
                                        .offer_success_user_nick
                                }
                                isVerified={
                                    item.offerSuccessInfo
                                        .offer_success_user_influencer_bool
                                }
                            />
                        ) : (
                            "-"
                        )}
                    </AdHistoryTableBodyColumn>
                </AdSaleManageAdListTableBidderColumnContainer>
            ),
            buttonColumn: (
                <AdSaleManageAdListTableButtonColumnContainer
                    textAlign={"center"}
                    key={"buttonColumn"}
                >
                    <AdHistoryTableBodyColumn>
                        <AdManageCommonHistoryColumnAdBtn
                            type={type}
                            adRoundInvalidStatus={""}
                            adRoundStatus={""}
                            clickHandler={async () => {
                                return await openAdSaleManageOfferListModal(
                                    item.ad_idx || "1000",
                                    new Date(
                                        convertSafariAdSalesDate(
                                            item.ad_sales_date,
                                        ),
                                    ),
                                    item.ad_daily_status_code,
                                    typeof item.offerSuccessInfo !==
                                        "undefined",
                                );
                            }}
                        />
                    </AdHistoryTableBodyColumn>
                </AdSaleManageAdListTableButtonColumnContainer>
            ),
        };
    });

    return (
        <CommonTable
            id={type}
            tableHeaderData={tableHeaderData}
            tableBodyData={data}
            tableBodyHeight={"100%"}
        />
    );
};

export default AdSaleManageAdListPcTable;
