import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { getIsLogin } from "../../../../store/auth/authKeyStore";
import { useAtom } from "jotai";
import userInfoAtom from "../../../../store/userInfo/userInfoAtom";

type PrivateRoutePropsType = {
    component: any;
};

const PrivateRoute = (props: PrivateRoutePropsType) => {
    const isLogin = getIsLogin();
    const navigate = useNavigate();
    const [, setPathAfterLogin] = useLocalStorage("pathAfterLogin", "/");
    const [userInfo] = useAtom(userInfoAtom);
    const isMountedDone = useRef(false);

    // 자신 + 부모 컴포넌트 리렌더링 될때마다 실행
    // 부모가 App 이므로 자기자신 리렌더링 될때마다 체크
    useEffect(() => {
        if (!isLogin) {
            setPathAfterLogin(location.pathname);
            navigate("/", {
                state: {
                    isOpenLoginLayer: true,
                },
            });
        }
    });

    useEffect(() => {
        // 최초 page render 시에 호출 하면 안댐
        if (isMountedDone.current) {
            if (!isLogin) {
                setPathAfterLogin(location.pathname);
                navigate("/", {
                    state: {
                        isOpenLoginLayer: true,
                    },
                });
            }
        }
        // 최초 page render 시에도 effect hook 이 도니깐, 막아주고 그 다음부터 실행
        isMountedDone.current = true;
    }, [userInfo]);

    return isLogin ? props.component : null;
};

export default PrivateRoute;
