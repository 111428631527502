import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import viewHeightAtom from "../../../../../store/scroll/viewHeightAtom";
import SearchFilterContents, {
    SearchFilterLayerMobileGoBackButton,
    SearchFilterLayerMobileTitle,
} from "./SearchFilterContents/SearchFilterContents";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { FilterType } from "../SearchFilter";
import { useWindowSize } from "usehooks-ts";
import { ResponsiveWidth } from "../../../../../styles/Theme";

export const FullModalTypeSearchFilterContainer = styled.div`
    width: 732px;
    margin-top: 28px;
    padding-bottom: 8px; // buttonContainer 16px + 8px 총 24px
    z-index: 2;
`;

export const FullModalTypeSearchFilterHeader = styled.div`
    padding: 0 0 24px 0;
    display: flex;
    justify-content: center;
`;

export const FullModalTypeSearchFilterLayer = styled.div<{
    vh: number;
}>`
    @media ${(props) => props.theme.tabletM} {
        position: relative;
        z-index: auto;
        overflow: scroll;
        max-height: ${({ vh }) => "calc(" + 100 * vh + "px - 280px)"};
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
        border: none;
        padding-bottom: 84px;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;
    }
`;

type Props = {
    resolve: (value: boolean) => void;
    filters: FilterType[];
    setFilters: (value: FilterType[]) => void;
    filterItemWidth: string;
    title: string;
    id: string;
    isSearchFilterOpen: boolean;
    setIsSearchFilterOpen: (value: boolean) => void;
};

const FullModalTypeSearchFilter = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const id = props.id + "-full-modal";
    const { width } = useWindowSize();

    useEffect(() => {
        if (width !== 0 && width <= ResponsiveWidth.tabletM) {
            props.resolve(false);
            props.setIsSearchFilterOpen(false);
        }
    }, [width]);

    return (
        <FullModalTypeSearchFilterContainer>
            <FullModalTypeSearchFilterHeader>
                <SearchFilterLayerMobileGoBackButton
                    onClick={() => {
                        props.resolve(false);
                    }}
                    src={URL_CONSTANTS.ASSET_URL + "/icon/icon-left-arrow.svg"}
                />
                <SearchFilterLayerMobileTitle>
                    {props.title}
                </SearchFilterLayerMobileTitle>
            </FullModalTypeSearchFilterHeader>

            <FullModalTypeSearchFilterLayer vh={vh}>
                <SearchFilterContents
                    id={id}
                    isFullModal={true}
                    filterItemWidth={props.filterItemWidth}
                    isSearchFilterOpen={true}
                    filters={props.filters}
                    setFilters={props.setFilters}
                    close={() => {
                        props.resolve(false);
                        props.setIsSearchFilterOpen(false);
                    }}
                />
            </FullModalTypeSearchFilterLayer>
        </FullModalTypeSearchFilterContainer>
    );
};

export default FullModalTypeSearchFilter;
