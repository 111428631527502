import React, { ReactElement, useEffect, useRef, useState } from "react";
import * as CarouselStyle from "./CarouselStyle";
import { CarouselSlider } from "./CarouselStyle";
import { useWindowSize } from "usehooks-ts";
import { ResponsiveWidth } from "../../../../styles/Theme";
import CarouselArrowButton from "./components/carouselArrowButton/CarouselArrowButton";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";
import CarouselPagination from "./components/CarouselPagination";
import CarouselPageIndicator from "./components/carouselPageIndicator/CarouselPageIndicator";
import { useLocation } from "react-router-dom";

export type RenderComponentDataType = {
    listItem: any | any[];
    idx: number;
    currentIndex: number;
    isDragging: boolean;
    slider: any;
};
type Props = {
    dataList: any[];
    isCenterMode: boolean;

    isInfinite: boolean;
    isShowIndicator: boolean;
    isShowPagination: boolean;
    isAuto: boolean;
    isWindowTransparent: boolean;
    isArrowHoverEvent: boolean;
    isPathnameChangeInitIndex: boolean;

    renderComponent: (data: any) => ReactElement;
    renderArrow: (data: any) => ReactElement;
    renderPagination?: (data: any) => ReactElement;
    renderIndicator?: (data: any) => ReactElement;
    IndicatorBottomPositionList?: string[];
    getterCurrentIndex?: (data: number) => void;

    displayLength: number;
    responsiveDisplayLength: number[] | null;

    initialSlide: number;
    autoDelayTime: number;
    debug?: boolean;
    type: string;
    classNames: string;
};

const Carousel = (props: Props): ReactElement | null => {
    const location = useLocation();
    const { width } = useWindowSize();
    const [slider, setSlider] = useState<any>(null);
    const [displayLength, setDisplayLength] = useState(props.displayLength);
    const [currentIndex, setCurrentIndex] = useState(props.initialSlide);

    const [isDragging, _setIsDragging] = useState(false);
    const isDraggingRef = useRef<Boolean>(isDragging);
    const setIsDragging = function (value: boolean) {
        _setIsDragging(value);
        isDraggingRef.current = value;
    };

    useEffect(() => {
        if (typeof props.getterCurrentIndex !== "undefined") {
            props.getterCurrentIndex(currentIndex);
        }
    }, [currentIndex]);

    useEffect(() => {
        // width 따라 노출되는 갯수 수정해줌
        // props.responsiveDisplayLength === null 인 경우 displayLength 고정으로 사이즈만 줄어듦
        if (width !== 0 && props.responsiveDisplayLength !== null) {
            const responsiveWidthArray = [
                ResponsiveWidth.tabletL,
                ResponsiveWidth.tabletM,
                ResponsiveWidth.mobileL,
                ResponsiveWidth.mobileM,
            ];
            // 제일 큰게 레벨 0 제일 작은게 레벨 4
            const level = responsiveWidthArray.filter(
                (w) => w - width > 0,
            ).length;
            const tempDisplayLength = props.responsiveDisplayLength[level];
            setDisplayLength(tempDisplayLength);
        }
    }, [width]);

    useEffect(() => {
        if (slider) {
            slider.slickGoTo(Number.isInteger(displayLength) ? 0 : -0.5, true);
        }
    }, [displayLength]);

    const settings = {
        slidesToShow: displayLength, // displayLength 그 자체
        slidesToScroll: 1,
        centerMode: props.isCenterMode,
        centerPadding: "0%",
        slidesPerRow: 1,
        infinite:
            props.dataList.length < displayLength ? false : props.isInfinite,
        verticalSwiping: false,
        speed: 300,
        touchThreshold: 100000,
        initialSlide: props.initialSlide,
        swipeToSlide: true,
        className: props.classNames,
        autoplay: props.isAuto,
        autoplaySpeed: props.autoDelayTime,
        // beforeChange event 는 mount 시 호출되어 onSwipe 로 변경
        onSwipe: () => {
            setIsDragging(true);
        },
        afterChange: (current: number) => {
            setIsDragging(false);
            setCurrentIndex(current);
        },
        onInit: () => {
            setIsDragging(false);
        },
        dots: props.isShowIndicator,
        customPaging: (i: number) => {
            return (
                <CarouselPageIndicator
                    clickEvent={(idx: number) => {
                        slider.slickGoTo(idx);
                    }}
                    dataLength={props.dataList.length}
                    indicatorIndex={i}
                    currentIndex={currentIndex}
                    isShowIndicator={props.isShowIndicator}
                    renderIndicator={props.renderIndicator}
                />
            );
        },
        nextArrow: (
            <CarouselArrowButton
                renderArrow={props.renderArrow}
                isDragging={isDragging}
                direction={"right"}
                isArrowHoverEvent={props.isArrowHoverEvent}
            />
        ),
        prevArrow: (
            <CarouselArrowButton
                renderArrow={props.renderArrow}
                isDragging={isDragging}
                direction={"left"}
                isArrowHoverEvent={props.isArrowHoverEvent}
            />
        ),
    };

    useEffect(() => {
        if (
            props.isPathnameChangeInitIndex &&
            slider !== null &&
            "slickGoTo" in slider
        ) {
            slider.slickGoTo(0);
            setCurrentIndex(0);
        }
    }, [location.pathname]);
    return (
        <CarouselStyle.CarouselContainer>
            <CarouselSlider
                IndicatorBottomPositionList={props.IndicatorBottomPositionList}
                isWindowTransparent={props.isWindowTransparent}
                ref={(slider) => setSlider(slider)}
                {...settings}
            >
                {props.dataList.map((data, idx) => {
                    return props.renderComponent(
                        typeof data === "object" &&
                            typeof data.length === "undefined"
                            ? {
                                  listItem: data,
                                  currentIndex,
                                  idx,
                                  isDragging: isDraggingRef?.current,
                                  slider,
                              }
                            : {
                                  listItem: data,
                                  currentIndex,
                                  idx,
                                  isDragging: isDraggingRef?.current,
                                  slider,
                              },
                    );
                })}
            </CarouselSlider>
            {props.isShowPagination && (
                <CarouselPagination
                    isShowPagination={props.isShowPagination}
                    renderPagination={props.renderPagination}
                    dataLength={props.dataList.length}
                    currentIndex={currentIndex}
                />
            )}
        </CarouselStyle.CarouselContainer>
    );
};

export default Carousel;

Carousel.defaultProps = {
    dataList: [],

    isInfinite: true,
    isShowPagination: false,
    isShowIndicator: false,
    isAuto: false,
    isScroll: true,
    isWindowTransparent: true,
    isArrowHoverEvent: true,
    isCenterMode: false,
    isPathnameChangeInitIndex: false,

    renderComponent: () => <div>test</div>,
    renderArrow: () => <div>test</div>,

    displayLength: 5,
    responsiveDisplayLength: null,

    initialSlide: 0,
    autoDelayTime: 3000,
    debug: false,
    type: "normal",
    classNames: "",
};
