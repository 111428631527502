import { useOpenModal } from "../useOpenModal";
import React from "react";
import AppInstallGuideModal from "../../../components/common/commonUiComponent/header/components/HeaderUserProfileArea/components/headerUserProfileLayer/components/AppInstallGuideModal";

export function useOpenAppInstallModal() {
    const { openModal } = useOpenModal();

    return {
        openAppInstallModal: async (): Promise<boolean> => {
            return await openModal(
                "app-install-guide",
                "bottomSheet",
                (resolve) => {
                    return <AppInstallGuideModal resolve={resolve} />;
                },
            );
        },
    };
}
