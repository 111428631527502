import React, { ReactElement } from "react";
import styled from "styled-components";
import Carousel, {
    RenderComponentDataType,
} from "../../../commonUnitComponent/carousel/Carousel";
import MiniProfileModalBrandListItem from "./MiniProfileModalBrandListItem";

import { ResponsiveWidth } from "../../../../../styles/Theme";
import { useWindowSize } from "usehooks-ts";
import { UserProfileType } from "../../../../../query/user/useGetUserProfileQuery";
import {
    MiniProfileModalBrandIdsContainer,
    MiniProfileModalBrandListDoubleItem,
    MiniProfileModalBrandListOneItemContainer,
    MiniProfileModalUserItemCarouselArrowButton,
} from "../MiniProfileModal";

export const MiniProfileModalBrandListContainer = styled.div``;

type Props = { data: UserProfileType };

const MiniProfileModalBrandList = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();
    const is2dBrandShow = () => {
        return (
            props.data.userInterWorkList.length > 2 &&
            width > ResponsiveWidth.mobileL
        );
    };
    const get2dBrandIds = (originalArr: any[]) => {
        let resArr = [];
        let tempArr = [];
        for (let i = 0; i < originalArr.length; i++) {
            tempArr.push(originalArr[i]);
            if (i % 2 === 1) {
                resArr.push(tempArr);
                tempArr = [];
            } else {
                if (i === originalArr.length - 1) {
                    resArr.push(tempArr);
                }
            }
        }
        return resArr;
    };

    return (
        <MiniProfileModalBrandListContainer>
            {props.data.userInterWorkList.length !== 0 && (
                <MiniProfileModalBrandIdsContainer>
                    <Carousel
                        classNames={"overflow-visible"}
                        dataList={
                            is2dBrandShow()
                                ? get2dBrandIds(props.data.userInterWorkList)
                                : props.data.userInterWorkList
                        }
                        responsiveDisplayLength={
                            props.data.userInterWorkList.length === 1
                                ? [1, 1, 1, 1, 1, 1]
                                : [2, 2, 2, 2, 2.5, 2.5]
                        }
                        isCenterMode={false}
                        isInfinite={is2dBrandShow()}
                        isArrowHoverEvent={true}
                        isAuto={false}
                        isWindowTransparent={false}
                        renderComponent={(data: RenderComponentDataType) => {
                            if (is2dBrandShow()) {
                                return (
                                    <MiniProfileModalBrandListDoubleItem
                                        key={data.idx}
                                    >
                                        <MiniProfileModalBrandListItem
                                            data={data.listItem[0]}
                                        />
                                        {data.listItem.length > 1 && (
                                            <MiniProfileModalBrandListItem
                                                data={data.listItem[1]}
                                            />
                                        )}
                                    </MiniProfileModalBrandListDoubleItem>
                                );
                            } else {
                                if (props.data.userInterWorkList.length === 1) {
                                    return (
                                        <MiniProfileModalBrandListOneItemContainer
                                            key={data.idx}
                                        >
                                            <MiniProfileModalBrandListItem
                                                data={data.listItem}
                                            />
                                        </MiniProfileModalBrandListOneItemContainer>
                                    );
                                } else {
                                    return (
                                        <MiniProfileModalBrandListItem
                                            key={data.idx}
                                            data={data.listItem}
                                        />
                                    );
                                }
                            }
                        }}
                        renderArrow={(data) => {
                            return (
                                <MiniProfileModalUserItemCarouselArrowButton
                                    direction={data.type}
                                />
                            );
                        }}
                    />
                </MiniProfileModalBrandIdsContainer>
            )}
        </MiniProfileModalBrandListContainer>
    );
};

export default MiniProfileModalBrandList;
