import { useInfiniteQuery } from "react-query";
import { ProjectApi } from "../../apis/ProjectApi";
import { COMMON_QUERY_PARAMS } from "../../types/BasicType";
import { jsonToString } from "../../utils/Utils";

export type ProjectListDataType = {
    [key: string]: string | number | boolean;
    project_idx: string;
    project_banner_full_path: string;
    project_thumbnail_full_path: string;
    project_name: string;
    project_network: string;
    project_erc: string;
    project_currency: string;
    project_currency_local: string;
    brand_id: string;
    brand_name: string;
    brand_royalty: number;
    creator_royalty: number;
    token_name: string;
    token_symbol: string;
    transaction_bool: boolean;
    like_cnt: number;
    item_cnt: number; // 아이템 갯수가 아니라 아이템 종류 수임
};

export type ProjectListApiResultType = {
    projectList: ProjectListDataType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type ProjectListApiParamsType = {
    [key: string]: any;
    list_type: string;
    sort_type: string;
    page_no: number;
    limit: number;
    project_erc?: string;
    project_network?: string;
    release_bool?: string;
    transaction_bool?: string;
    category_no?: string;
    brand_id?: string;
    brand_idx?: string;
    user_idx?: string;
    recent_array?: string[];
    project_name?: string;
    first_fixed_bool?: number; // 갯수 늘어날지도 몰라서 number 로 협의
};

export const useProjectListQuery = (
    params: ProjectListApiParamsType,
    queryParams: COMMON_QUERY_PARAMS = {
        enabled: true,
        cacheTime: 60000,
        staleTime: 5000,
    },
) => {
    if (params.list_type === "recent" && !("recent_array" in params)) {
        console.error("no recent_array in params");
    }
    const queryResult = useInfiniteQuery<ProjectListApiResultType, Error>({
        queryKey: [
            `project-list-${jsonToString(params)}`,
            `project-list-${params.list_type}`,
            "project-list",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return ProjectApi.getList({
                ...params,
                page_no: pageParam,
            });
        },
        suspense: false,
        enabled: queryParams.enabled,
        refetchOnWindowFocus: false,
        cacheTime: queryParams.cacheTime,
        staleTime: params.list_type === "like" ? 0 : queryParams.staleTime,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        onError: (err) => {},
    });
    const defaultData: ProjectListApiResultType[] = [
        { projectList: [], totalCount: 0 },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadProjectListPages(pages: ProjectListApiResultType[]) {
    return pages
        .map((result) => result.projectList)
        .reduce((prev, next) => prev.concat(next));
}
