import { HEADER_MENUS } from "./headerMenus";
import { LinkMenuType } from "../../components/common/commonUiComponent/header/components/HeaderMenu/HeaderMenu";

export const MY_SUB_HEADER_MENUS: LinkMenuType[] = [
    {
        id: 1,
        name: "계정 정보",
        path: "/my",
        isNewTab: false,
    },
    {
        id: 2,
        name: "결제수단 관리",
        path: "/my/payments",
        isNewTab: false,
    },
    {
        id: 3,
        name: "알림 설정",
        path: "/my/notification",
        isNewTab: false,
    },
    {
        id: 4,
        name: "유료 결제 내역",
        path: "/my/order-history",
        isNewTab: false,
    },
];

export const ADVERTISEMENT_SUB_HEADER_MENUS = HEADER_MENUS.find(
    (menus) => menus.path === "/ad",
)?.children;

export const MARKET_SUB_HEADER_MENUS = HEADER_MENUS.find(
    (menus) => menus.path === "/market",
)?.children;

export const WALLET_SUB_HEADER_MENUS = HEADER_MENUS.find(
    (menus) => menus.path === "/wallet",
)?.children;
