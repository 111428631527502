import { useEffect, useState } from "react";
import { InputTextDataType } from "../../components/common/commonUnitComponent/commonInputText/CommonInputText";

export function useStringLengthCheck(
    minLength: number,
    maxLength: number,
    inputData: InputTextDataType,
    setInputData: (value: InputTextDataType) => void,
    checkValidation?: (value: string) => boolean,
    isStringLengthByteCheck?: boolean,
) {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        // 최초 mount 할 때는 warning 안준다
        if (isMounted) {
            if (minLength !== -1) {
                if (minLength !== 0) {
                    if (inputData.value.length < minLength) {
                        setInputData({
                            ...inputData,
                            isWarning: true,
                            warningMsg: `최소 ${minLength}자는 입력하셔야 합니다.`,
                        });
                        return;
                    }
                } else {
                    if (inputData.value === "") {
                        setInputData({
                            ...inputData,
                            isWarning: true,
                            warningMsg: `반드시 작성되어야 하는 영역입니다.`,
                        });
                        return;
                    }
                }
            }

            if (maxLength !== -1) {
                if (inputData.value.length > maxLength) {
                    setInputData({
                        ...inputData,
                        isWarning: true,
                        warningMsg: `최대 ${maxLength}자까지 작성이 가능합니다.`,
                    });
                    return;
                }
            }

            if (checkValidation && !checkValidation(inputData.value)) {
                return;
            }

            if (inputData.isWarning || inputData.warningMsg !== "") {
                setInputData({
                    ...inputData,
                    isWarning: false,
                    warningMsg: "",
                });
            }
        }
    }, [inputData.value]);
}
