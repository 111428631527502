import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageOfferListModalPreviewBtn } from "../AdSaleManageOfferListModalPreview";
import { useCancelAdOfferSuccessBySellerMutation } from "../../../../../../../../query/ad/adBidOffer/useCancelAdOfferSuccessBySellerMutation";
import { PathAfterResolveType } from "../../../../../../../../hooks/modal/useOpenModal";
import {
    AD_BID_CLOSED,
    AD_IN_REVIEW,
    AD_ON_BID,
    AD_ON_SCHEDULE,
    AD_PREPARING,
    AdDailyListItemStatusCodeType,
} from "../../../../../../../../types/ad/adDaily/AdDailyListItemType";
import { format } from "date-fns";
import { useOpenCommonConfirmModal } from "../../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import {
    AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER,
    AD_OFFER_STATUS_SUCCESS_OFFER,
} from "../../../../../../../../hooks/ad/staticConvertAdOfferStatus";

export const AdSaleManageOfferListModalPreviewCancelOfferSuccessBtnContainer = styled.div``;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    adIdx: string;
    selectedOfferIdx: string;
    adSalesDate: Date;
    offerStatus: string;
    adDailyStatus: AdDailyListItemStatusCodeType;
    refreshCache: () => Promise<void>;
};

const AdSaleManageOfferListModalPreviewCancelOfferSuccessBtn = (
    props: Props,
): ReactElement | null => {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();

    const { mutateAsync: cancelOfferSuccessBySeller } =
        useCancelAdOfferSuccessBySellerMutation(props.adIdx, {
            ad_sales_date: format(props.adSalesDate, "yyyy-MM-dd"),
        });

    return (props.offerStatus === AD_OFFER_STATUS_SUCCESS_OFFER ||
        props.offerStatus === AD_OFFER_STATUS_SUCCESS_BUY_NOW_OFFER) &&
        (props.adDailyStatus === AD_ON_BID ||
            props.adDailyStatus === AD_IN_REVIEW ||
            props.adDailyStatus === AD_BID_CLOSED ||
            props.adDailyStatus === AD_PREPARING ||
            props.adDailyStatus === AD_ON_SCHEDULE) ? (
        <AdSaleManageOfferListModalPreviewBtn
            isOnlyMobileShow={false}
            btnType={"filled-danger"}
            onClick={async () => {
                try {
                    const confirmResult = await openCommonConfirmModal({
                        title: "낙찰취소",
                        desc: "해당 일자에 낙찰된 내역을 취소합니다. 판매자가 직접 낙찰건을 취소할 경우 낙찰가의 100%가 환불조치됩니다.",
                        buttonTitle: "취소",
                        closeBtnTitle: "닫기",
                    });

                    if (confirmResult) {
                        await cancelOfferSuccessBySeller();
                        await props.refreshCache();
                        props.resolve(false);
                    }
                } catch (e) {
                    console.info(e);
                }
            }}
        >
            낙찰취소
        </AdSaleManageOfferListModalPreviewBtn>
    ) : null;
};

export default AdSaleManageOfferListModalPreviewCancelOfferSuccessBtn;
