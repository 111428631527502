import React, { ReactElement, RefObject } from "react";
import WhiteBox from "../../../../common/commonUnitComponent/box/WhiteBox";
import styled from "styled-components";
import Carousel, {
    RenderComponentDataType,
} from "../../../../common/commonUnitComponent/carousel/Carousel";
import WhiteBoxTitleLayout from "../../../../common/commonUnitComponent/box/WhiteBoxTitleLayout";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../common/commonStyle/CommonButtonStyle";
import { useParams } from "react-router-dom";
import TemplatePreview from "../../../../common/commonUiComponent/templatePreview/TemplatePreview";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { AdCarouselArrowButton } from "../../../../common/commonUnitComponent/carousel/components/carouselArrowButton/CarouselArrowButtonStyle";
import { NormalCarouselIndicator } from "../../../../common/commonUnitComponent/carousel/components/carouselPageIndicator/CarouselPageIndicatorStyle";
import { useOpenAdBidingSelectTemplateModal } from "../../../../../hooks/modal/openModal/useOpenAdBidingSelectTemplateModal";
import { useOpenAdTemplateRegisterConfirmModal } from "../../../_adTemplate/adTemplateRegisterStep/components/adTemplateRegisterStepConfirmModal/useOpenAdTemplateRegisterConfirmModal";
import { useAdTemplateInfoQuery } from "../../../../../query/ad/template/useAdTemplateInfoQuery";
import { useAdInfoQuery } from "../../../../../query/ad/ad/useAdInfoQuery";

export const AdBidingSelectTemplateContainer = styled.div``;

export const AdBidingSelectTemplateCarouselContainer = styled.div`
    margin-top: 20px;
`;

export const AdBidingSelectTemplatePreviewContainer = styled.div`
    width: 100%;
    height: 240px;
    border-radius: 8px;
    background: #f5f5f7;
`;

export const AdBidingSelectTemplateCarouselButton = styled(
    AdCarouselArrowButton,
)<{
    direction: string;
}>``;

export const AdBidingSelectTemplateCarouselIndicator = styled(
    NormalCarouselIndicator,
)``;

export const AdBidingSelectTemplateNoTemplateContainer = styled.div`
    width: 100%;
    display: flex;
    height: 240px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #e5e5e5;

    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
`;

export const AdBidingSelectTemplateNoTemplateImg = styled.img`
    width: 52px;
    height: 52px;
`;

export const AdBidingConfirmImageChangeButtonContainer = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const AdBidingConfirmImageChangeButton = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    width: auto;
    height: auto;
    padding: 13px 20px;
    font-size: 14px;
    line-height: 1;
`;

export const AD_BIDING_SELECT_IMAGE_MODAL_ID = "ad-biding-select-image-modal";

type Props = {
    selectedTemplateIdx: string;
    setSelectedTemplateIdx: (value: string) => void;
    setTemplateType?: (value: string) => void;
    selectTemplateContainerRef: RefObject<HTMLDivElement>;
};

function AdBidingSelectTemplate(props: Props): ReactElement | null {
    const params = useParams();
    const adIdx = params.ad_id ? params.ad_id : "0";

    const { data: rawAdData } = useAdInfoQuery(adIdx);
    const campaignInfo = rawAdData.campaignInfo;

    const isNoSelect = props.selectedTemplateIdx === "-1";

    const {
        data: rawTemplateData,
        isSuccess: isTemplateDataSuccess,
        isLoading: isTemplateDataLoading,
        error: errorTemplateData,
    } = useAdTemplateInfoQuery(props.selectedTemplateIdx, !isNoSelect);

    const { openAdBidingSelectTemplateModal } =
        useOpenAdBidingSelectTemplateModal();

    const { navigateTemplateRegisterPage } =
        useOpenAdTemplateRegisterConfirmModal();

    const spaceList = rawTemplateData.templateInfo.template_space
        .map((space) => {
            return space.template_space_element.filter(
                (ele) => ele.presv_campaign_space_element_type === "1",
            );
        })
        .flat();

    return (
        <WhiteBox>
            <WhiteBoxTitleLayout title={"광고 템플릿 선택"} bodyMt={"24px"}>
                <AdBidingSelectTemplateContainer
                    ref={props.selectTemplateContainerRef}
                >
                    {!isNoSelect && (
                        <AdBidingSelectTemplateCarouselContainer>
                            <Carousel
                                isShowPagination={true}
                                responsiveDisplayLength={[1, 1, 1, 1, 1, 1]}
                                dataList={spaceList}
                                displayLength={1}
                                renderComponent={(
                                    data: RenderComponentDataType,
                                ) => {
                                    const spaceElementImg =
                                        data.listItem
                                            .template_space_element_img_path;

                                    return (
                                        <AdBidingSelectTemplatePreviewContainer
                                            key={data.idx}
                                        >
                                            <TemplatePreview
                                                imgPath={spaceElementImg}
                                            />
                                        </AdBidingSelectTemplatePreviewContainer>
                                    );
                                }}
                                renderArrow={(data) => (
                                    <AdBidingSelectTemplateCarouselButton
                                        direction={data.type}
                                    />
                                )}
                                renderPagination={(data) => (
                                    <AdBidingSelectTemplateCarouselIndicator>
                                        {data.currentIndex + 1}/{data.length}
                                    </AdBidingSelectTemplateCarouselIndicator>
                                )}
                            />
                        </AdBidingSelectTemplateCarouselContainer>
                    )}
                    {isNoSelect && (
                        <AdBidingSelectTemplateNoTemplateContainer>
                            <AdBidingSelectTemplateNoTemplateImg
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-default-img.svg`}
                            />
                            광고 템플릿을 선택해주세요
                        </AdBidingSelectTemplateNoTemplateContainer>
                    )}
                    <AdBidingConfirmImageChangeButtonContainer>
                        <AdBidingConfirmImageChangeButton
                            btnType={"filled-primary"}
                            onClick={async () => {
                                const campaignIdx = campaignInfo.campaign_idx;
                                const modalResult =
                                    await openAdBidingSelectTemplateModal(
                                        campaignIdx,
                                        props.setSelectedTemplateIdx,
                                        props.setTemplateType,
                                    );

                                if (modalResult.isAddTemplate) {
                                    navigateTemplateRegisterPage(
                                        campaignIdx,
                                        modalResult.isAddTemplateHasGuide,
                                        location.pathname,
                                    );
                                }
                            }}
                        >
                            {isNoSelect ? "템플릿 선택하기" : "템플릿 변경하기"}
                        </AdBidingConfirmImageChangeButton>
                    </AdBidingConfirmImageChangeButtonContainer>
                </AdBidingSelectTemplateContainer>
            </WhiteBoxTitleLayout>
        </WhiteBox>
    );
}

export default AdBidingSelectTemplate;
