import { hasPathAfterResolve, useOpenModal } from "../useOpenModal";
import ModalComponentInfoListAtom from "../../../store/ui/ModalComponentInfoListAtom";
import { useAtom } from "jotai";
import UserProfileModal from "../../../components/common/modal/userProfileModal/UserProfileModal";
import { useNavigate } from "react-router-dom";

export const GLOBAL_USER_PROFILE_MODAL = "user-profile-modal";

export function useOpenUserProfileModal() {
    const { openModalWithReturnValue } = useOpenModal();
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const searchResultModalInfo = modalComponentInfoList.find(
        (info) => info.id === GLOBAL_USER_PROFILE_MODAL,
    );
    const navigate = useNavigate();

    return {
        openUserProfileModal: async () => {
            const result = await openModalWithReturnValue(
                GLOBAL_USER_PROFILE_MODAL,
                "self-transition",
                (resolve) => {
                    return (
                        <UserProfileModal
                            resolve={(value: any) => {
                                resolve(value);
                            }}
                        />
                    );
                },
                "user-profile",
            );

            return result;
        },
        isOpenSearchResultModal: !!searchResultModalInfo,
    };
}
