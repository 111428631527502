import { ReactElement } from "react";
import styled from "styled-components";
import { getStyle } from "./CalendarCellBackgroundRow";
import { CalendarCellMobileRow, CalendarCellPcRow } from "./CalendarCellRow";
import { extractNumbers } from "../../../../../utils/Utils";

export const CalendarCellBackgroundTwoRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 4px 6px;
    width: calc(100% - 8px);
    border-radius: 4px;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        background-color: transparent !important;
    }
`;

export const CalendarCellBackgroundTwoRowFirstRowContainer = styled.div`
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 18.2px */

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const CalendarCellBackgroundTwoRowSecondRowContainer = styled.div`
    color: #fa54fa;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 10px;
        text-align: center;
    }
`;

export const CalendarCellBackgroundTwoRowBadgeContainer = styled.div`
    display: flex;
    padding: 3px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: #fa54fa;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    position: absolute;
    top: 4px;
    right: 6px;
    line-height: 1;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

type Props = {
    firstRowMsg: string;
    secondRowMsg: string;
    type: string;
    badgeText?: string;
    badgeType?: string;
};

const CalendarCellBackgroundSeveralRow = (
    props: Props,
): ReactElement | null => {
    const style = getStyle(props.type);

    return (
        <CalendarCellBackgroundTwoRowWrapper style={style}>
            {props.badgeText && props.badgeType && (
                <CalendarCellBackgroundTwoRowBadgeContainer
                    style={getStyle(props.badgeType)}
                >
                    {props.badgeText}
                </CalendarCellBackgroundTwoRowBadgeContainer>
            )}
            <CalendarCellBackgroundTwoRowFirstRowContainer>
                {props.firstRowMsg}
            </CalendarCellBackgroundTwoRowFirstRowContainer>
            <CalendarCellBackgroundTwoRowSecondRowContainer>
                <CalendarCellPcRow>{props.secondRowMsg}</CalendarCellPcRow>
                <CalendarCellMobileRow>
                    {extractNumbers(props.secondRowMsg)}
                </CalendarCellMobileRow>
            </CalendarCellBackgroundTwoRowSecondRowContainer>
        </CalendarCellBackgroundTwoRowWrapper>
    );
};

export default CalendarCellBackgroundSeveralRow;
