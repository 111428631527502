import { useItemHideUnHideMutation } from "../../query/item/hide/useItemHideUnHideMutation";

export function useItemHideUnHide(
    itemIdx: string,
    isPrivate: boolean,
    refetch: () => Promise<any>,
) {
    const { mutateAsync: hideUnHide } = useItemHideUnHideMutation(itemIdx, {
        private_bool: isPrivate ? 0 : 1,
    });

    return {
        hideUnHide: async () => {
            try {
                await hideUnHide();
                await refetch();
            } catch (err: any) {
                //
            }
        },
    };
}
