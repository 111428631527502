import { useOpenModal } from "../useOpenModal";
import React, { ReactElement } from "react";
import CommonAlertModal from "../../../components/common/modal/commonAlertModal/CommonAlertModal";
import { CommonBtnStringType } from "../../../components/common/commonStyle/CommonButtonStyle";

export type CommonAlertModalInfoType = {
    title?: string;
    desc: string | ReactElement;
    buttonTitle?: string;
    btnType?: CommonBtnStringType;
    hasCloseBtn?: boolean;
};

export function useOpenCommonAlertModal() {
    const { openModal } = useOpenModal();

    return {
        openCommonAlertModal: async (
            params: CommonAlertModalInfoType,
        ): Promise<boolean> => {
            return openModal(
                "common-alert-modal-" +
                    encodeURIComponent(params.desc.toString()),
                "modal",
                (resolve: (value: boolean) => void) => {
                    return <CommonAlertModal resolve={resolve} {...params} />;
                },
            );
        },
    };
}
