import React from "react";
import WalletCollectionItemSendModal from "../../components/wallet/walletCollection/components/walletCollectionItemListItem/components/WalletCollectionItemSendModal";
import { ItemListItemType } from "../../query/item/useItemListQuery";
import { TitleItemListItemType } from "../../query/item/titleItem/useTitleItemListQuery";
import { useOpenModal } from "../modal/useOpenModal";

export function useItemTransferModal(
    item: ItemListItemType | TitleItemListItemType | any,
) {
    const { openModal } = useOpenModal();
    const openItemTransferModal = async () => {
        return await openModal(
            "item-transfer-modal",
            "bottomSheet",
            (resolve) => {
                return (
                    <WalletCollectionItemSendModal
                        resolve={resolve}
                        item={item}
                    />
                );
            },
        );
    };

    return {
        openItemTransferModal: openItemTransferModal,
    };
}
