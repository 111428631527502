import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { useGetMarketAveragePrice } from "../../query/market/common/useGetMarketAveragePrice";
import { getIsLogin } from "../../store/auth/authKeyStore";
import { useGetMyRemainingPointListQuery } from "../../query/my/myPoint/useGetMyRemainingPointListQuery";

export function useSetAveragePrice(
    targetType: string,
    targetIdx: string,
    remainingPointType: string,
) {
    const isLogin = getIsLogin();
    const [count, setCount] = useState(0);
    const debouncedCount = useDebounce<number>(count, 500);
    const [maxCount, setMaxCount] = useState(0);
    const { myPoint, isSuccess: isMyPointSuccess } =
        useGetMyRemainingPointListQuery(isLogin);
    const [warningMsg, setWarningMsg] = useState("");

    const {
        data: rawAveragePrice,
        isSuccess: isAveragePriceSuccess,
        isLoading: isAveragePriceLoading,
        error: errorAveragePrice,
    } = useGetMarketAveragePrice(
        targetType,
        targetIdx,
        {
            quantity: debouncedCount,
        },
        isLogin,
    );

    const remainingToken = myPoint[remainingPointType].remaining; // 비로그인시 default 0

    useEffect(() => {
        if (isAveragePriceSuccess && isMyPointSuccess) {
            setMaxCount(
                rawAveragePrice.isInfiniteBool
                    ? 999
                    : rawAveragePrice.availableQuantity,
            );

            let insufficientTarget = "";
            if (rawAveragePrice.totalAmount > remainingToken) {
                insufficientTarget += remainingPointType.toUpperCase();
            }

            if (
                rawAveragePrice.royalty.brandRoyalty +
                    rawAveragePrice.royalty.creatorRoyalty >
                    myPoint.rp.remaining &&
                insufficientTarget !== "RP"
            ) {
                insufficientTarget += insufficientTarget === "" ? "RP" : ", RP";
            }

            if (insufficientTarget !== "") {
                setWarningMsg(`보유한 ${insufficientTarget}가 부족합니다.`);
            } else {
                setWarningMsg("");
            }
        }
    }, [rawAveragePrice, isAveragePriceSuccess, myPoint, isMyPointSuccess]);

    return {
        count,
        debouncedCount,
        setCount,
        maxCount,
        rawAveragePrice,
        isAveragePriceLoading,
        errorAveragePrice,
        remainingToken,
        warningMsg,
        isWarning: warningMsg !== "",
    };
}
