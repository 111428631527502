import { ReactElement } from "react";
import styled from "styled-components";
import { equalDate } from "../../../../../../../../../utils/Utils";
import AdSaleManageScheduleCalendarCellBodyBtns from "./components/adSaleManageScheduleCalendarCellBodyBtns/AdSaleManageScheduleCalendarCellBodyBtns";
import { useGetAdSaleManageCalendarCellByCode } from "../useGetAdSaleManageCalendarCellByCode";
import { MyAdDailyListItemType } from "../../../../../../../../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";

export const AdSaleManageScheduleCalendarCellBodyContainer = styled.div<{
    isSelected: boolean;
}>`
    min-height: 121px;
    height: 100%;
    @media ${(props) => props.theme.mobileL} {
        min-height: unset;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyPcWrapper = styled.div<{
    isSelected: boolean;
}>`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${(props) => props.theme.mobileL} {
        height: 0;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyPcContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyPcBtnsContainer = styled.div`
    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyMobileContainer = styled.div`
    display: none;
    @media ${(props) => props.theme.mobileL} {
        display: flex;
        justify-content: center;
    }
`;

export const AdSaleManageScheduleCalendarCellBodyBlankContainer = styled.div`
    height: 24px;
    width: 100%;
`;

export const HiddenKeyManage = styled.div``;

type Props = {
    selectedDate: Date;
    adIdx: string;
    cellDate: Date;
    myAdDailyList: MyAdDailyListItemType[];
};

const AdSaleManageScheduleCalendarCellBody = (
    props: Props,
): ReactElement | null => {
    const isSelected = equalDate(props.selectedDate, props.cellDate);

    const { cellPcBody, cellMobileBody } = useGetAdSaleManageCalendarCellByCode(
        {
            cellDate: props.cellDate,
            adIdx: props.adIdx,
            myAdDailyList: props.myAdDailyList,
        },
    );

    return (
        <AdSaleManageScheduleCalendarCellBodyContainer isSelected={isSelected}>
            <AdSaleManageScheduleCalendarCellBodyPcWrapper
                isSelected={isSelected}
            >
                <AdSaleManageScheduleCalendarCellBodyPcContainer>
                    {cellPcBody}
                </AdSaleManageScheduleCalendarCellBodyPcContainer>
                <AdSaleManageScheduleCalendarCellBodyPcBtnsContainer>
                    {isSelected && (
                        <AdSaleManageScheduleCalendarCellBodyBtns
                            adIdx={props.adIdx}
                            cellDate={props.cellDate}
                            myAdDailyList={props.myAdDailyList}
                        />
                    )}
                </AdSaleManageScheduleCalendarCellBodyPcBtnsContainer>
            </AdSaleManageScheduleCalendarCellBodyPcWrapper>
            <AdSaleManageScheduleCalendarCellBodyMobileContainer>
                {cellMobileBody}
            </AdSaleManageScheduleCalendarCellBodyMobileContainer>
        </AdSaleManageScheduleCalendarCellBodyContainer>
    );
};

export default AdSaleManageScheduleCalendarCellBody;
