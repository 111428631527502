import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import {
    MyItemsOwnerListApiResultType,
    MyItemsOwnerListParamsType,
} from "../query/item/ownerItemList/useMyItemsOwnerListQuery";
import { getAuthKey } from "../store/auth/authKeyStore";
import {
    ItemOwnerListApiParamsType,
    ItemOwnerListApiResultType,
} from "../query/item/ownerItemList/useItemOwnerListQuery";

export const ItemOwnerApi = {
    getMyItemsOwnerList: async (
        params: MyItemsOwnerListParamsType,
    ): Promise<MyItemsOwnerListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/owner/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getItemOwnerList: async (
        itemIdx: string,
        params: ItemOwnerListApiParamsType,
    ): Promise<ItemOwnerListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/item/${itemIdx}/owner/list`, {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve({
                        ...res.data.data,
                        nextPageNumber:
                            res.data.data.totalCount >
                            params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
