import { useQuery } from "react-query";
import { PointApi } from "../../apis/PointApi";

export type PointDetailDataType = {
    [key: string]: string | number | any;
    pointInfo: PointDetailPointDataType;
    analysisInfo: PointDetailAnalysisInfoType;
    creatorInfo: PointDetailCreatorInfoType;
    brandInfo: PointDetailBrandInfoType;
};

export type PointDetailPointDataType = {
    point_idx: string;
    point_name: string;
    point_symbol: string;
    brand_idx: string;
    brand_id: string;
    like_cnt: number;
    created_at: string;
    updated_at: string;
    like_bool: boolean;
    cost: number;
    delta_cost: number;
};

export type PointDetailAnalysisInfoType = {
    lowest_price_52weeks: number;
    highest_price_52weeks: number;
};

export type PointDetailCreatorInfoType = {
    creator_idx: string;
    creator_name: string;
    creator_id: string;
    creator_img_full_path: string;
    creator_follow_cnt: number;
    creator_description: string;
    user_influencer_bool: boolean;
    follow_bool: false;
    creator_type: string;
};

export type PointDetailBrandInfoType = {
    brand_id: string;
    brand_name: string;
    brand_thumbnail_full_path: string;
    brand_description: string;
    brand_bi_full_path: string;
};

export const usePointDataQuery = (pointIdx: string) => {
    const queryResult = useQuery<PointDetailDataType, Error>({
        queryKey: ["point-data-" + pointIdx, "like-refetch-list-item"],
        queryFn: () => PointApi.getDetailData(pointIdx),
        suspense: false,
        onError: () => {
            return [];
        },
    });

    const defaultData: PointDetailDataType = {
        pointInfo: {
            point_idx: "0",
            point_name: "",
            point_symbol: "",
            brand_idx: "0",
            brand_id: "",
            like_cnt: 0,
            created_at: "",
            updated_at: "",
            like_bool: false,
            cost: 0,
            delta_cost: 0,
        },
        brandInfo: {
            brand_id: "",
            brand_name: "",
            brand_thumbnail_full_path: "",
            brand_description: "",
            brand_bi_full_path: "",
        },
        creatorInfo: {
            creator_idx: "0",
            creator_name: "",
            creator_id: "",
            creator_img_full_path: "",
            creator_follow_cnt: 0,
            creator_description: "",
            user_influencer_bool: false,
            follow_bool: false,
            creator_type: "",
        },
        analysisInfo: {
            lowest_price_52weeks: 0,
            highest_price_52weeks: 0,
        },
    };

    return {
        ...queryResult,
        data: queryResult.data || defaultData,
    };
};
