import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useGoLoginPage } from "../../../../../../hooks/common/useGoLoginPage";
import { BRAND_ID_SOOP } from "../../../../../../constants/brand/Brand";

export const UserProfileModalLoginWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    background: white;
    padding: 0 20px;
    cursor: pointer;
`;

export const UserProfileModalLoginContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    background: white;
`;

export const UserProfileModalLoginLogoImg = styled.img`
    height: 24px;
    margin-right: 10px;
`;

export const UserProfileModalLoginText = styled.div`
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
`;

export const UserProfileModalGoIconImg = styled.img`
    width: 10px;
    height: 16px;
    margin-left: 10px;
`;

type Props = {};

const UserProfileModalLoginBtn = (props: Props): ReactElement | null => {
    const { goLogin } = useGoLoginPage();

    return (
        <UserProfileModalLoginWrapper
            onClick={() => {
                goLogin();
            }}
        >
            <UserProfileModalLoginContainer>
                <UserProfileModalLoginLogoImg
                    src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${BRAND_ID_SOOP}-only-icon.svg`}
                />
                <UserProfileModalLoginText>
                    SOOP으로 로그인
                </UserProfileModalLoginText>
            </UserProfileModalLoginContainer>
            <UserProfileModalGoIconImg
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-menu-earth-arrow.png`}
            />
        </UserProfileModalLoginWrapper>
    );
};

export default UserProfileModalLoginBtn;
