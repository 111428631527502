import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { getAuthKey } from "../store/auth/authKeyStore";
import {
    TitleItemListApiParamsType,
    TitleItemListApiResultType,
} from "../query/item/titleItem/useTitleItemListQuery";
import { TitleItemListOrderType } from "../query/item/titleItem/useTitleItemListOrderChangeMutation";

export const TitleItemApi = {
    getList: async (
        params: TitleItemListApiParamsType,
    ): Promise<TitleItemListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/item/title/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    registerTitleItem: async (itemIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/item/" + itemIdx + "/title",
                    {},
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    changeTitleItemOrder: async (
        params: TitleItemListOrderType,
    ): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .put(URL_CONSTANTS.API_URL + "/item/title/order", params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteTitleItem: async (itemIdx: string): Promise<null> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(URL_CONSTANTS.API_URL + "/item/" + itemIdx + "/title", {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
