import { ReactElement } from "react";
import styled from "styled-components";
import { MyAdOfferInfoOfferSpaceElementType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoOfferSpaceElementType";
import { MyAdOfferInfoOfferSpaceType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoOfferSpaceType";

export const AdSaleManageOfferListModalPreviewCarouselItemWrapper = styled.div``;

export const AdSaleManageOfferListModalPreviewCarouselItemContainer = styled.div`
    width: 320px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    border-radius: 8px;
    background: #f5f5f7;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        height: 240px;
    }
`;

export const AdSaleManageOfferListModalPreviewCarouselItemImg = styled.img`
    max-width: 288px;

    @media ${(props) => props.theme.mobileL} {
        max-height: 216px;
    }
`;

export const AdSaleManageOfferListModalPreviewCarouselItemTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

export const AdSaleManageOfferListModalPreviewCarouselItemTextTitle = styled.div`
    color: #888;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`;

export const AdSaleManageOfferListModalPreviewCarouselItemTextValue = styled.div`
    color: #222;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

type Props = {
    offerSpace: MyAdOfferInfoOfferSpaceType;
};

const AdSaleManageOfferListModalPreviewCarouselItem = (
    props: Props,
): ReactElement | null => {
    return (
        <AdSaleManageOfferListModalPreviewCarouselItemWrapper>
            <AdSaleManageOfferListModalPreviewCarouselItemContainer>
                {props.offerSpace.offer_space_element.map(
                    (ele: MyAdOfferInfoOfferSpaceElementType, eleIdx: number) =>
                        ele.presv_campaign_space_element_type === "1" ? (
                            <AdSaleManageOfferListModalPreviewCarouselItemImg
                                key={eleIdx}
                                src={ele.presv_template_space_element_img_path}
                            />
                        ) : (
                            <AdSaleManageOfferListModalPreviewCarouselItemTextContainer
                                key={eleIdx}
                            >
                                <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                    {ele.presv_campaign_space_element_name}
                                </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                    {ele.presv_template_space_element_text}
                                </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                            </AdSaleManageOfferListModalPreviewCarouselItemTextContainer>
                        ),
                )}
            </AdSaleManageOfferListModalPreviewCarouselItemContainer>
        </AdSaleManageOfferListModalPreviewCarouselItemWrapper>
    );
};

export default AdSaleManageOfferListModalPreviewCarouselItem;
