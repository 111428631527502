import { ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import userInfoAtom from "../../../store/userInfo/userInfoAtom";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { useGetUserProfileQuery } from "../../../query/user/useGetUserProfileQuery";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";
import { ResponsiveWidth } from "../../../styles/Theme";
import hasChannelTalkAccessAtom from "../../../store/ui/hasChannelTalkAccessAtom";

export const ChannelTalkContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2000;

    display: flex;
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 26px;
    background: #fa54fa;
    box-shadow: 0 4px 10px 0 rgba(250, 84, 250, 0.3);
    color: white;
    cursor: pointer;
`;

export const ChannelTalkIcon = styled.img``;

type Props = {};

const ChannelTalk = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();
    const [userInfo] = useAtom(userInfoAtom);
    const { data: rawUserProfileData, isSuccess: isProfileDataSuccess } =
        useGetUserProfileQuery(userInfo.user_idx, {
            enabled: userInfo.user_idx !== "0",
        });

    const [hasChannelTalkAccess, setHasChannelTalkAccess] = useAtom(
        hasChannelTalkAccessAtom,
    );

    const channelTalkRef = useRef(document.getElementById("ch-plugin"));

    useOnClickOutside(channelTalkRef, (e: any) => {
        ChannelService.hideMessenger();
    });

    const location = useLocation();

    useEffect(() => {
        ChannelService.loadScript();
    }, []);

    useEffect(() => {
        ChannelService.setPage(location.pathname);
        ChannelService.track("PageView");
    }, [location]);

    useEffect(() => {
        if (
            userInfo.user_idx !== "0" &&
            isProfileDataSuccess &&
            (rawUserProfileData.userInfo.user_influencer_bool ||
                rawUserProfileData.userInfo.favorite_cnt >= 50)
        ) {
            ChannelService.shutdown();
            ChannelService.boot({
                pluginKey: "8a1ae895-f0a9-43e5-be7b-6d92a59ef830", // fill your plugin key
                memberId: userInfo.user_id, // fill user's member id
                customLauncherSelector: "", //"#custom-channel-talk-btn",
                hideChannelButtonOnBoot: true,
                profile: {
                    user_idx: userInfo.user_idx,
                    name: userInfo.user_nick + "__" + userInfo.user_idx,
                    mobileNumber: "USER_MOBILE_NUMBER",
                    landlineNumber: "USER_LANDLINE_NUMBER",
                    customField2: "VALUE_2",
                },
            });
            setHasChannelTalkAccess(true);
            if (width !== 0 && width > ResponsiveWidth.tabletL) {
                ChannelService.showChannelButton();
            }
        } else {
            ChannelService.hideChannelButton();
            setHasChannelTalkAccess(false);
        }
    }, [isProfileDataSuccess, rawUserProfileData]);

    useEffect(() => {
        if (
            width !== 0 &&
            width > ResponsiveWidth.tabletL &&
            hasChannelTalkAccess
        ) {
            ChannelService.showChannelButton();
        }
    }, [width]);

    useEffect(() => {
        if (width !== 0) {
            if (
                userInfo.user_idx !== "0" &&
                isProfileDataSuccess &&
                (rawUserProfileData.userInfo.user_influencer_bool ||
                    rawUserProfileData.userInfo.favorite_cnt >= 50)
            ) {
                if (width > ResponsiveWidth.tabletL) {
                    ChannelService.showChannelButton();
                } else {
                    ChannelService.hideChannelButton();
                }
            }
        }
    }, [width]);

    useEffect(() => {
        channelTalkRef.current = document.getElementById("ch-plugin");
    }, [document.getElementById("ch-plugin")]);

    return (
        <ChannelTalkContainer
            style={{
                display: "none",
            }}
            id={"custom-channel-talk-btn"}
            onClick={() => {
                // ChannelService.showMessenger();
            }}
        ></ChannelTalkContainer>
    );
};

export default ChannelTalk;
