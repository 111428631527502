import { useAtom } from "jotai";
import {
    asyncPushModalComponentInfo,
    asyncRemoveModalComponentInfo,
    ModalComponentTypeEnum,
} from "../../store/ui/ModalComponentInfoListAtom";
import { createRef, ReactElement } from "react";
import { windowHistoryBack } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";

export const hasPathAfterResolve = (value: any) => {
    try {
        return typeof value !== "boolean" && "pathAfterResolve" in value;
    } catch (e) {
        return false;
    }
};

export const hasPathAfterNestedResolve = (value: any) => {
    try {
        return typeof value !== "boolean" && "pathAfterNestedResolve" in value;
    } catch (e) {
        return false;
    }
};

export type PathAfterResolveType = {
    pathAfterResolve?: string;
    pathAfterNestedResolve?: string;
    state?: any;
};

export function useOpenModal() {
    const [, pushModalComponentInfo] = useAtom(asyncPushModalComponentInfo);
    const [, removeModalComponentInfo] = useAtom(asyncRemoveModalComponentInfo);
    const navigate = useNavigate();

    const executeAfterInResolve = async (resolvedValue: any, id: string) => {
        // 정상적으로 된 경우
        // resolve(true or false)
        if (resolvedValue !== -1 && !hasPathAfterResolve(resolvedValue)) {
            await windowHistoryBack();
        }
        // 앞으로가기 삭제를 위한 pushState
        // 정상적으로 닫힌 경우 위에서 실행한 뒤로가기가 완료된 뒤에 실행되어야함.
        // 근데 push 하면 뒤로가기가 결국 한번 더 걸려야해서 앞으로가기 삭제가 불가능함..
        // window.history.pushState(
        //     {
        //         key: "test1",
        //     },
        //     document.title,
        //     location.pathname,
        // );
        await removeModalComponentInfo(id);
    };

    return {
        openModal: async (
            id: string,
            type: ModalComponentTypeEnum,
            renderModalComponent: (
                resolve: (value: boolean) => void,
            ) => ReactElement,
            transitionClassName?: string,
        ): Promise<any> => {
            return new Promise((outResolve: (value: boolean) => void) => {
                new Promise((resolve: (value: boolean) => void) => {
                    pushModalComponentInfo({
                        id: id,
                        type: type,
                        renderComponent: () => renderModalComponent(resolve),
                        resolve: resolve,
                        transitionClassName: transitionClassName,
                        nodeRef: createRef<HTMLDivElement>(),
                        isShowAnimated: false,
                    });
                }).then(async (resolvedValue: boolean | number) => {
                    // 정상적으로 된 경우
                    // resolve(true or false)
                    await executeAfterInResolve(resolvedValue, id);
                    outResolve(resolvedValue === -1 ? false : !!resolvedValue);
                });
            });
        },
        // modal 에서 유저 선택에 따라, true / false 외의값을 리턴 해줘야하는 경우 사용
        // 1차 래핑하는 useOpenSomethingModal 에서 반드시 분기처리하여 return true/false 를 맞춰야함.
        openModalWithReturnValue: async (
            id: string,
            type: ModalComponentTypeEnum,
            renderModalComponent: (
                resolve: (value: any) => void,
            ) => ReactElement,
            transitionClassName?: string,
        ): Promise<any> => {
            return new Promise((outResolve: (value: any) => void) => {
                new Promise((resolve: (value: any) => void) => {
                    pushModalComponentInfo({
                        id: id,
                        type: type,
                        renderComponent: () => renderModalComponent(resolve),
                        resolve: resolve,
                        transitionClassName: transitionClassName,
                        nodeRef: createRef<HTMLDivElement>(),
                        isShowAnimated: false,
                    });
                }).then(async (resolvedValue: any) => {
                    await executeAfterInResolve(resolvedValue, id);

                    // out resolve 하기전에 value 에 path 있으면 navigate
                    if (hasPathAfterResolve(resolvedValue)) {
                        setTimeout(() => {
                            // timeout 안주면 lazy load 때 exit class 적용전 node 들이 남아서
                            // 모달이 있는 채로 화면 전환됨.
                            navigate(resolvedValue.pathAfterResolve, {
                                state: resolvedValue.state,
                            });
                        }, 100);
                    }
                    outResolve(resolvedValue);
                });
            });
        },
    };
}
