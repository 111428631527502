import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";
import { useOpenAdTemplateRegisterConfirmModal } from "../../../../../../_adTemplate/adTemplateRegisterStep/components/adTemplateRegisterStepConfirmModal/useOpenAdTemplateRegisterConfirmModal";
import { AdBidingTemplateSelectResolveType } from "../AdBidingSelectTemplateModal";

export const AdBidingSelectTemplateModalHeaderContainer = styled.div`
    position: relative;
`;

export const AdBidingSelectTemplateModalCloseIcon = styled.img`
    position: absolute;
    top: -6px;
    right: -2px;
    cursor: pointer;

    @media ${(props) => props.theme.mobileL} {
        width: 40px;
        height: 40px;
        display: none;
    }
`;

export const AdBidingSelectTemplateModalHeaderTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */

    text-align: center;

    color: #000000;

    @media ${(props) => props.theme.mobileL} {
        text-align: left;
        font-size: 20px;
        line-height: 100%;
    }
`;

export const AdBidingSelectTemplateModalHeaderWarningRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    background: #f9f2ff;
    border-radius: 12px;
    margin-top: 26px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 24px;
    }
`;

export const AdBidingSelectTemplateModalHeaderWarningLeftContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;

    color: #555555;
    @media ${(props) => props.theme.mobileL} {
        font-size: 13px;
        line-height: 18px;
    }
`;

export const AdBidingSelectTemplateModalHeaderWarningIcon = styled.img`
    margin-left: 16px;
    margin-right: 4px;
`;

export const AdBidingSelectTemplateModalHeaderWarningRightButton = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    padding: 16px;
    cursor: pointer;
    color: #fa54fa;
    flex-shrink: 0;
`;

type Props = {
    resolve: (value: AdBidingTemplateSelectResolveType) => void;
    isNoData: boolean;
    campaignIdx: string;
};

const AdBidingSelectTemplateModalHeader = (
    props: Props,
): ReactElement | null => {
    const location = useLocation();
    const { openAdTemplateRegisterConfirmModal } =
        useOpenAdTemplateRegisterConfirmModal();

    return (
        <AdBidingSelectTemplateModalHeaderContainer>
            <AdBidingSelectTemplateModalHeaderTitle>
                내 광고 템플릿
            </AdBidingSelectTemplateModalHeaderTitle>
            {!props.isNoData && (
                <AdBidingSelectTemplateModalHeaderWarningRow>
                    <AdBidingSelectTemplateModalHeaderWarningLeftContainer>
                        <AdBidingSelectTemplateModalHeaderWarningIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-info.svg`}
                        />
                        광고 입찰은 검토완료된 템플릿만 사용 가능합니다.
                    </AdBidingSelectTemplateModalHeaderWarningLeftContainer>
                    <AdBidingSelectTemplateModalHeaderWarningRightButton
                        onClick={async () => {
                            const modalResult =
                                await openAdTemplateRegisterConfirmModal();

                            if (!modalResult.isOnlyClose) {
                                props.resolve({
                                    isOnlyClose: false,
                                    isAddTemplate: true,
                                    isAddTemplateHasGuide: modalResult.hasGuide,
                                    isTemplateSelect: false,
                                });
                            }
                        }}
                    >
                        템플릿 만들기
                    </AdBidingSelectTemplateModalHeaderWarningRightButton>
                </AdBidingSelectTemplateModalHeaderWarningRow>
            )}
            <AdBidingSelectTemplateModalCloseIcon
                onClick={() => {
                    props.resolve({
                        isOnlyClose: true,
                        isAddTemplate: false,
                        isAddTemplateHasGuide: false,
                        isTemplateSelect: false,
                    });
                }}
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-close.svg`}
            />
        </AdBidingSelectTemplateModalHeaderContainer>
    );
};

export default AdBidingSelectTemplateModalHeader;
