import { ReactElement, useState } from "react";
import styled from "styled-components";
import {
    RightSideBarCommentInput,
    RightSideBarCommentSendButton,
} from "../RightSideBarCommunity";
import { asyncPushSnackBarListItemAtom } from "../../../../../../../store/ui/snackBarListAtom";
import { useSetAtom } from "jotai";
import { getIsLogin } from "../../../../../../../store/auth/authKeyStore";
import { useOpenCommonErrorModal } from "../../../../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useCommentAddMutation } from "../../../../../../../query/comment/useCommentAddMutation";
import { useOpenLoginConfirmModal } from "../../../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { getPageType } from "../../../../../../../utils/Utils";
import { useParams } from "react-router-dom";

export const RightSideBarCommunityInputContainer = styled.div`
    display: flex;
    height: 48px;
    background: #f5f5f7;
    border-radius: 8px;
    margin-top: 24px;

    @media ${(props) => props.theme.PCM} {
        margin-top: 0;
    }
`;

type Props = {
    refetchCommentList: (value?: any) => Promise<any>;
};

const RightSideBarCommunityWriteArea = (props: Props): ReactElement | null => {
    const pageType = getPageType(location.pathname);
    const params = useParams();
    const [commentInput, setCommentInput] = useState("");
    const pushSnackBarListItem = useSetAtom(asyncPushSnackBarListItemAtom);
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const { mutateAsync: registerComment, isLoading: isRegisteringComment } =
        useCommentAddMutation(
            pageType,
            Number(params.item_id || params.ad_id || params.point_id),
            {
                comment: commentInput,
            },
        );

    return (
        <RightSideBarCommunityInputContainer>
            <RightSideBarCommentInput
                rows={2}
                value={commentInput}
                placeholder={"내용을 입력해 주세요."}
                onChange={(e) => {
                    if (e.target.value.length <= 250) {
                        setCommentInput(e.target.value);
                    } else if (e.target.value.length !== 0) {
                        pushSnackBarListItem({
                            title: "",
                            desc: "250자를 초과하여 입력하실 수 없습니다.",
                            iconUrl: "",
                            confirmBtnTitle: "",
                        });
                    }
                }}
            />
            <RightSideBarCommentSendButton
                onClick={async () => {
                    if (commentInput.replace(/ /g, "") === "") {
                        await openCommonErrorModal({
                            title: "빈값은 등록하실 수 없습니다.",
                        });
                        return;
                    }

                    if (getIsLogin() && !isRegisteringComment) {
                        try {
                            await registerComment();
                        } catch (e) {
                            //
                        }
                        await props.refetchCommentList();
                        setCommentInput("");
                    } else {
                        await openLoginConfirmModal();
                    }
                }}
            >
                등록
            </RightSideBarCommentSendButton>
        </RightSideBarCommunityInputContainer>
    );
};

export default RightSideBarCommunityWriteArea;
