import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import {
    MarketProjectBadgeContainer,
    MarketProjectTitleHeartContainer,
    MarketProjectTitleHeartIcon,
    MarketProjectTitleLogo,
    MarketProjectTitleText,
    MarketProjectTitleThumbnailContainer,
} from "./MarketProjectTitle";
import CssFilterConverter from "css-filter-converter";
import MarketProjectTitleThreeDotBtn from "./components/MarketProjectTitleThreeDotBtn";
import MarketProjectTitleSubInfo from "./components/MarketProjectTitleSubInfo";
import MarketProjectTitleBadges from "./components/marketProjectTitleBadges/MarketProjectTitleBadges";
import MarketProjectTitleDetailInfo from "./components/MarketProjectTitleDetailInfo";
import MarketProjectTitleDesc from "./components/MarketProjectTitleDesc";
import { ProjectDetailDataType } from "../../../../../query/project/useProjectDataQuery";
import { useShare } from "../../../../../hooks/common/useShare";
import Shimmer from "../../../../common/commonUiComponent/shimmer/Shimmer";
import SquareImg from "../../../../common/commonUiComponent/squqreImg/SquareImg";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { onErrorImg } from "../../../../../utils/Utils";

export const MarketProjectTitleMobileContainer = styled.div`
    display: none;

    @media ${(props) => props.theme.mobileL} {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
`;
export const MarketProjectMobileThumbnailRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const MarketProjectMobileThumbnailTitleContainer = styled.div`
    display: flex;
`;

type Props = {
    projectData: ProjectDetailDataType;
};

const MarketProjectTitleMobile = (props: Props): ReactElement | null => {
    const share = useShare();
    const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);
    return (
        <MarketProjectTitleMobileContainer>
            {/*쓰리닷*/}
            <MarketProjectTitleThreeDotBtn projectData={props.projectData} />

            <MarketProjectMobileThumbnailTitleContainer>
                {/*썸네일*/}
                <MarketProjectTitleThumbnailContainer>
                    <Shimmer isLoading={isThumbnailLoading}>
                        <SquareImg
                            src={props.projectData.project_thumbnail_full_path}
                            setIsImageLoading={setIsThumbnailLoading}
                        />
                    </Shimmer>
                </MarketProjectTitleThumbnailContainer>

                {/*썸네일* 우측 영역 */}
                <MarketProjectMobileThumbnailRightContainer>
                    {/*로고*/}
                    <MarketProjectTitleLogo
                        src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${props.projectData.brand_id}.svg`}
                        onError={onErrorImg}
                    />

                    {/*프로젝트명*/}
                    <MarketProjectTitleText>
                        {props.projectData.project_name}
                    </MarketProjectTitleText>

                    {/*좋아요 수*/}
                    <MarketProjectTitleHeartContainer>
                        <MarketProjectTitleHeartIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-heart.svg`}
                            style={{
                                filter:
                                    CssFilterConverter.hexToFilter("#FF4671")
                                        .color + "",
                            }}
                        />
                        총 {props.projectData.like_cnt}
                    </MarketProjectTitleHeartContainer>
                </MarketProjectMobileThumbnailRightContainer>
            </MarketProjectMobileThumbnailTitleContainer>

            {/*subInfo - 크리에이터명 / 링크복사 / 좋아요 수 */}
            <MarketProjectTitleSubInfo
                isMobile={true}
                projectData={props.projectData}
            />

            {/*뱃지 계열*/}
            <MarketProjectBadgeContainer>
                <MarketProjectTitleBadges data={props.projectData} />
            </MarketProjectBadgeContainer>

            {/*detailInfo - 아이템 / 카테고리 / 보유자 / 누적 거래량 */}
            <MarketProjectTitleDetailInfo projectData={props.projectData} />

            {/* 소개(더보기) */}
            <MarketProjectTitleDesc projectData={props.projectData} />
        </MarketProjectTitleMobileContainer>
    );
};

export default MarketProjectTitleMobile;
