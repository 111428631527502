import { ReactElement, useState } from "react";
import styled from "styled-components";
import Calendar from "../../../../../../common/commonUiComponent/calendar/Calendar";
import { RenderCalendarCellParamType } from "../../../../../../common/commonUiComponent/calendar/components/CalendarDateBody";
import CalendarCell from "../../../../../../common/commonUiComponent/calendar/components/CalendarCell";
import {
    AdBidCalendarBodyContainerStyle,
    AdCalendarContainerStyle,
    AdCalendarHeaderContainerStyle,
} from "../../../../../adBid/components/adBidMainContents/adBidTopContents/components/adCalendar/AdBidCalendar";
import AdSaleManageScheduleCalendarCellBody from "./components/adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";
import AdSaleManageCalendarSelectedMobileCell from "./components/adSaleManageCalendarSelectedMobileCell/AdSaleManageCalendarSelectedMobileCell";
import {
    spreadMyAdDailyListPages,
    useGetMyAdDailyListQuery,
} from "../../../../../../../query/my/myAd/useGetMyAdDailyListQuery";
import { getMonthStartEnd } from "../../../../../../../utils/Utils";
import { format } from "date-fns";

export const AdSaleManageScheduleCalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
`;

export const AdSaleManagerScheduleCalendarCellWrapper = styled.div<{
    calendarLength: number;
    isCellFold: boolean;
}>`
    min-height: 100%;

    @media ${(props) => props.theme.mobileL} {
        min-height: 55px;
    }
`;

type Props = {
    adIdx: string;
};

const AdSaleManageScheduleCalendar = (props: Props): ReactElement | null => {
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(today);
    const [displayingDate, setDisplayingDate] = useState(today);

    const monthStartEnd = getMonthStartEnd(displayingDate);

    const { data: rawMyAdDailyList } = useGetMyAdDailyListQuery(props.adIdx, {
        ad_sales_date_from: format(monthStartEnd.startDate, "yyyy-MM-dd"),
        ad_sales_date_to: format(monthStartEnd.endDate, "yyyy-MM-dd"),
        limit: 100,
        page_no: 1,
    });
    const myAdDailyList = spreadMyAdDailyListPages(rawMyAdDailyList.pages);

    return (
        <AdSaleManageScheduleCalendarContainer>
            <Calendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                displayingDate={displayingDate}
                setDisplayingDate={setDisplayingDate}
                initDate={today}
                calendarContainerStyle={AdCalendarContainerStyle}
                headerContainerStyle={AdCalendarHeaderContainerStyle}
                bodyContainerStyle={AdBidCalendarBodyContainerStyle}
                isShowOnlyWeek={false}
                renderCellComponent={(params: RenderCalendarCellParamType) => (
                    <CalendarCell
                        key={params.cellDate.getTime()}
                        {...params}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        displayingDate={displayingDate}
                    >
                        <AdSaleManageScheduleCalendarCellBody
                            adIdx={props.adIdx}
                            cellDate={params.cellDate}
                            selectedDate={selectedDate}
                            myAdDailyList={myAdDailyList}
                        />
                    </CalendarCell>
                )}
                renderMobileSelectedCellComponent={() => (
                    <AdSaleManageCalendarSelectedMobileCell
                        adIdx={props.adIdx}
                        cellDate={selectedDate}
                        displayingDate={displayingDate}
                        myAdDailyList={myAdDailyList}
                        key={"today-info-container"}
                    />
                )}
            />
        </AdSaleManageScheduleCalendarContainer>
    );
};

export default AdSaleManageScheduleCalendar;
