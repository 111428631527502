import { ReactElement, useRef } from "react";
import styled from "styled-components";
import { useHover } from "usehooks-ts";
import Tooltip from "../../../../../../common/commonUnitComponent/tooltip/Tooltip";

export const MarketProjectTitleBadgeContainer = styled.div<{
    backgroundColor?: string;
    isHover?: boolean;
}>`
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    display: flex;
    align-items: center;
    color: ${({ backgroundColor }) =>
        backgroundColor ? "#FFFFFF" : "#333333"};
    background: ${({ backgroundColor, isHover }) => {
        let color = backgroundColor ? backgroundColor : "#f0f0f2";
        color += isHover ? "70" : "";
        return color;
    }};
    border-radius: 20px;
    padding: 9px 14px;
    position: relative;
    transition: all 300ms ease;
`;
type Props = {
    info: string;
    tooltip: string;
    backgroundColor?: string;
};

const MarketProjectTitleBadge = (props: Props): ReactElement | null => {
    const badgeRef = useRef<HTMLDivElement>(null);
    const isBadgeHover = useHover(badgeRef);
    return (
        <MarketProjectTitleBadgeContainer
            ref={badgeRef}
            backgroundColor={props.backgroundColor}
            isHover={isBadgeHover}
        >
            <Tooltip isHover={isBadgeHover}>
                <>{props.tooltip}</>
            </Tooltip>
            {props.info}
        </MarketProjectTitleBadgeContainer>
    );
};

export default MarketProjectTitleBadge;
