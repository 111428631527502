import { ReactElement, useEffect, useMemo, useState } from "react";
import {
    HEADER_MENUS,
    HeaderMoreMenus,
} from "../../../../constants/menus/headerMenus";

import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import HeaderSearchArea from "./components/HeaderSearchArea/HeaderSearchArea";
import { useLocation } from "react-router-dom";

import { useAtom } from "jotai";
import hasHeaderAtom from "../../../../store/ui/hasHeaderAtom";
import IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM from "../../../../store/scroll/IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM";
import HeaderNotiArea from "./components/HeaderNotiArea/HeaderNotiArea";
import HeaderUserProfileArea from "./components/HeaderUserProfileArea/HeaderUserProfileArea";
import HeaderLogoArea from "./components/HeaderLogoArea";
import styled from "styled-components";
import isDarkModeAtom from "../../../../store/ui/isDarkModeAtom";
import {
    AD_BID_PAGE_REG_EXP,
    INTRO_PAGE_REG_EXP,
    ITEM_PAGE_REG_EXP,
    POINT_PAGE_REG_EXP,
    PROJECT_PAGE_REG_EXP,
} from "../../../../constants/PageRegExp";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";
import { useGetIsShowHeaderGroup } from "../../../../hooks/ui/useGetIsShowHeaderGroup";
import { MOBILE_SIDE_BAR_ID } from "../doubleSideBar/leftSideBar/components/MobileLeftSideBar";

type Props = {};

export const HeaderContainer = styled.div<{
    zIndex: number;
    hasHeader: boolean;
    isShow: boolean;
    isShowMaxWidthTabletM: boolean;
    headerTheme: string;
}>`
    position: fixed;
    top: 0;
    z-index: ${({ zIndex }) => zIndex};
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    transition: background-color ease-in-out 200ms, top 200ms linear,
        opacity 200ms linear;

    @media ${(props) => props.theme.tabletL} {
        height: 60px;
        top: ${({ isShow }) => (isShow ? "0" : "-60px")};
        opacity: ${({ isShow }) => (isShow ? "1" : "0")};
        line-height: 60px;
    }

    @media ${(props) => props.theme.tabletM} {
        top: ${({ isShowMaxWidthTabletM }) =>
            isShowMaxWidthTabletM ? "0" : "-60px"};
    }

    ${({ hasHeader }) => {
        if (!hasHeader) return "display: none;";
    }};

    ${({ headerTheme }) => {
        switch (headerTheme) {
            case "white":
                return `
                    background: white;
                    color: black;
                     border-bottom: 1px #ebebeb solid;
                `;

            case "white-scrolled":
                return `
                    background: white;
                    color: black;
                     border-bottom: 1px #ebebeb solid;
                `;

            case "white-transparent":
                return `
                    background: transparent;
                    color: black;
                    border-bottom: none;
                `;

            case "black":
                return `
                    background: transparent;
                    color: white;
                    border-bottom: none;
                `;

            case "black-scrolled":
                return `
                    background: rgba(255, 255, 255, 0.85);
                    backdrop-filter: blur(20px);
                    color: white;
                    border-bottom: none;
                `;

            case "black-transparent":
                return `
                    background: transparent;
                    color: white;
                    border-bottom: none;
                `;
        }
    }};

    @media ${(props) => props.theme.mobileL} {
        border-bottom: none;
    }
`;

export const HeaderContentsContainer = styled.div`
    width: calc(100% - 56px);
    height: inherit;
    padding: 0 24px 0 32px;
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    @media ${(props) => props.theme.tabletL} {
        width: calc(100% - 22px);
        padding: 0 6px 0 16px;
    }

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 20px);
        padding: 0 6px 0 14px;
    } ;
`;

export const HeaderPcMenuContainer = styled.div`
    display: flex;

    align-content: center;
    align-items: center;
    margin-right: 15.5px;

    @media ${(props) => props.theme.tabletL} {
        display: none;
    } ;
`;

export const getHeaderTheme = (
    isDarkMode: boolean,
    isScroll: boolean,
    isTransparent: boolean,
) => {
    let theme = "";
    if (isDarkMode) {
        if (isScroll) {
            theme = "dark-scrolled";
        } else {
            theme = isTransparent ? "dark-transparent" : "dark";
        }
    } else {
        if (isScroll) {
            theme = "white-scrolled";
        } else {
            theme = isTransparent ? "white-transparent" : "white";
        }
    }
    return theme;
};

const Header = (props: Props): ReactElement | null => {
    // const { t } = useTranslation();
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const [hasHeader] = useAtom(hasHeaderAtom);
    const [isFullScrollOver] = useAtom(
        IS_FULL_SCROLL_OVER_5PERCENT_IN_PAGE_OR_001_PERCENT_IN_HOME_PAGE_ATOM,
    );
    const [isDarkMode] = useAtom(isDarkModeAtom);

    const location = useLocation();
    const isHome = location.pathname === "/";
    const isIntroPage = INTRO_PAGE_REG_EXP.test(location.pathname);
    const isTransparent = isHome || isIntroPage;
    const headerTheme = getHeaderTheme(
        isDarkMode,
        isFullScrollOver,
        isTransparent,
    );

    const fullHeaderMenus = HEADER_MENUS.concat([
        {
            id: 990,
            name: "더 보기",
            path: "",
            children: HeaderMoreMenus,
            isNewTab: false,
            isNoNavigate: true,
            isNeedLogin: false,
        },
    ]);

    const MemoizedHeaderLogoArea = useMemo(() => <HeaderLogoArea />, []);
    const MemoizedHeaderSearchArea = useMemo(() => <HeaderSearchArea />, []);
    const MemoizedHeaderMenus = useMemo(
        () => (
            <HeaderPcMenuContainer>
                {fullHeaderMenus.map((topnavMenu, idx) => (
                    <HeaderMenu
                        key={idx}
                        headerMenu={topnavMenu}
                        isMoreMenu={idx === fullHeaderMenus.length - 1}
                    />
                ))}
            </HeaderPcMenuContainer>
        ),
        [],
    );
    const MemoizedHeaderNotiArea = useMemo(() => <HeaderNotiArea />, []);
    const MemoizedHeaderUserProfileArea = useMemo(
        () => <HeaderUserProfileArea />,
        [],
    );
    const [headerZIndex, setHeaderZIndex] = useState(3);

    useEffect(() => {
        setHeaderZIndex(
            modalComponentInfoList.length !== 0 &&
                modalComponentInfoList[0].id === MOBILE_SIDE_BAR_ID
                ? 1
                : 4,
        );
    }, [modalComponentInfoList]);

    const { getIsShow, getIsShowMaxWidthTabletM } =
        useGetIsShowHeaderGroup(true);

    const isDoubleSideBarLayoutPage =
        AD_BID_PAGE_REG_EXP.test(location.pathname) ||
        PROJECT_PAGE_REG_EXP.test(location.pathname) ||
        ITEM_PAGE_REG_EXP.test(location.pathname) ||
        POINT_PAGE_REG_EXP.test(location.pathname);

    const isShow = getIsShow || isDoubleSideBarLayoutPage || isIntroPage;
    const isShowMaxWidthTabletM =
        getIsShowMaxWidthTabletM || isDoubleSideBarLayoutPage || isIntroPage;

    return (
        <HeaderContainer
            zIndex={headerZIndex}
            hasHeader={hasHeader}
            isShow={isShow}
            isShowMaxWidthTabletM={isShowMaxWidthTabletM}
            headerTheme={headerTheme}
        >
            <HeaderContentsContainer>
                {MemoizedHeaderLogoArea}

                {MemoizedHeaderSearchArea}

                {MemoizedHeaderMenus}

                {MemoizedHeaderNotiArea}

                {MemoizedHeaderUserProfileArea}
            </HeaderContentsContainer>
        </HeaderContainer>
    );
};

export default Header;
