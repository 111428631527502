import { ReactElement, RefObject, useEffect, useState } from "react";
import styled, { FlattenInterpolation, ThemeProps } from "styled-components";
import { TableHeaderDataType } from "../../CommonTable";

export const TableHeaderContainer = styled.div`
    position: relative;
`;

export const TableHeaderRow = styled.div<{
    mb: string;
    hasScrollX: boolean;
    headerStyle?: FlattenInterpolation<ThemeProps<any>>;
    isScrollZero: boolean;
    isScrollFull: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ mb }) => mb};
    color: #888888;
    width: 100%;
    position: relative;

    ${({ hasScrollX }) =>
        hasScrollX
            ? `overflow-x: hidden;
                overflow-y: auto; padding-bottom: 4px;` // 글자체 때문에 (아래로 더 나오는 문제) padding-bottom 줘야 스크롤 안생김..
            : ""};

    ${({ headerStyle }) => (headerStyle ? headerStyle : "")};
`;

type Props = {
    tableHeaderData: TableHeaderDataType[];
    tableHeaderMarginBottom: string;
    hasScrollX: boolean;
    tableHeaderRef: RefObject<HTMLDivElement>;
    headerStyle?: FlattenInterpolation<ThemeProps<any>>;
};

const TableHeader = (props: Props): ReactElement | null => {
    const scrollLeft = props.hasScrollX
        ? props.tableHeaderRef.current?.scrollLeft || 0
        : 0;

    const [isScrollZero, setIsScrollZero] = useState(false);
    const [isScrollFull, setIsScrollFull] = useState(false);

    useEffect(() => {
        setIsScrollZero(scrollLeft === 0);
        setIsScrollFull(
            props.tableHeaderRef.current
                ? scrollLeft + props.tableHeaderRef.current?.clientWidth >=
                      props.tableHeaderRef.current?.scrollWidth
                : false,
        );
    }, [props.tableHeaderRef.current]);

    return (
        <TableHeaderContainer>
            <TableHeaderRow
                hasScrollX={props.hasScrollX}
                mb={props.tableHeaderMarginBottom}
                ref={props.tableHeaderRef}
                headerStyle={props.headerStyle}
                isScrollZero={isScrollZero}
                isScrollFull={isScrollFull}
            >
                {props.tableHeaderData.map(
                    (tableHeaderItem) =>
                        (typeof tableHeaderItem === "undefined" ||
                            !tableHeaderItem.isHide) &&
                        tableHeaderItem.component,
                )}
            </TableHeaderRow>
        </TableHeaderContainer>
    );
};

export default TableHeader;
