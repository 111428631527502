import { ReactElement } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../../../common/commonStyle/CommonButtonStyle";
import { useOpenCollectionGroupAddModal } from "../../../../../../../../../hooks/modal/openModal/useOpenCollectionGroupAddModal";

export const WalletCollectionGroupEditNoGroupContainer = styled.div`
    height: 355px;
    width: 352px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const WalletCollectionGroupEditNoGroupText = styled.div`
    color: #555;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
`;

export const WalletCollectionGroupEditNoGroupBtnContainer = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    height: 40px;
`;

type Props = {};

const WalletCollectionGroupEditNoGroup = (
    props: Props,
): ReactElement | null => {
    const { openCollectionGroupAddModal } = useOpenCollectionGroupAddModal();

    return (
        <WalletCollectionGroupEditNoGroupContainer>
            <WalletCollectionGroupEditNoGroupText>
                그룹이 없습니다.
                <br />
                그룹을 생성해주세요.
            </WalletCollectionGroupEditNoGroupText>
            <WalletCollectionGroupEditNoGroupBtnContainer
                btnType={"filled-primary"}
                onClick={async () => {
                    await openCollectionGroupAddModal();
                }}
            >
                그룹 추가
            </WalletCollectionGroupEditNoGroupBtnContainer>
        </WalletCollectionGroupEditNoGroupContainer>
    );
};

export default WalletCollectionGroupEditNoGroup;
