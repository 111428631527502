import { useInfiniteQuery } from "react-query";
import { MarketItemApi } from "../../../apis/market/MarketItemApi";

export type CollectionExchangeListApiResultType = {
    exchangeList: CollectionExchangeListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type CollectionExchangeListItemType = {
    item_idx: string;
    item_name: string;
    token_id: number;
    thumbnail_full_path: string;
    exchange_type: string;
    exchange_type_description: string;
    exchange_type_detail: string;
    transaction_type: string;
    from_user_idx: string;
    to_user_idx: string;
    settled_unit_price: number;
    settled_quantity: string;
    created_at: string;
    settlement_idx: string;
    project_name: string;
    project_idx: string;
};

export type CollectionExchangeListParamsType = {
    limit: number;
    page_no: number;
    filter_type: string;
    sort_type: string;
    exchanged_at_to: string;
    exchanged_at_from: string;
};
export const useGetCollectionExchangeListQuery = (
    userIdx: string,
    params: CollectionExchangeListParamsType,
) => {
    const queryResult = useInfiniteQuery<
        CollectionExchangeListApiResultType,
        Error
    >({
        queryKey: [
            "collection-exchange-list-" + userIdx + JSON.stringify(params),
        ],
        queryFn: ({ pageParam = 1 }) =>
            MarketItemApi.getWalletExchangeList(userIdx, {
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        refetchOnWindowFocus: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: CollectionExchangeListApiResultType[] = [
        {
            exchangeList: [],
            totalCount: 0,
        },
    ];

    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadCollectionExchangeList(
    pages: CollectionExchangeListApiResultType[],
) {
    return pages
        .map((result) => result.exchangeList)
        .reduce((prev, next) => prev.concat(next));
}
