import { FilterType } from "../../components/common/commonUiComponent/searchFilter/SearchFilter";

const historyInboundFilter = [
    {
        title: "거래",
        key: "1",
        isSelected: false,
    },
    {
        title: "생성",
        key: "2",
        isSelected: false,
    },
    {
        title: "전송",
        key: "3",
        isSelected: false,
    },
];

const historyOutboundFilter = [
    {
        title: "거래",
        key: "1",
        isSelected: false,
    },
    {
        title: "생성",
        key: "2",
        isSelected: false,
    },
    {
        title: "전송",
        key: "3",
        isSelected: false,
    },
    {
        title: "사용",
        key: "4",
        isSelected: false,
    },
];
export const ExchangeListFilters: FilterType[] = [
    {
        title: "전체",
        key: "transaction_type",
        isCheckbox: true,
        totalAdditionalExceptionOnChangeHandler: (value, tempFilters) => {
            return tempFilters.map((filters) => {
                if (filters.key === "history") {
                    return {
                        ...filters,
                        filterList: value
                            ? historyInboundFilter
                            : historyInboundFilter,
                    };
                }
                return filters;
            });
        },
        filterList: [
            {
                title: "입고",
                key: "inbound",
                isSelected: false,
                additionalExceptionOnChangeHandler: (value, tempFilters) => {
                    const isOutBoundCheck = tempFilters
                        .find((filters) => filters.key === "type")
                        ?.filterList.find(
                            (filter) => filter.key === "outbound",
                        )?.isSelected;

                    return tempFilters.map((filters) => {
                        if (filters.key === "history") {
                            return {
                                ...filters,
                                filterList: isOutBoundCheck
                                    ? historyInboundFilter
                                    : historyInboundFilter,
                            };
                        }
                        return filters;
                    });
                },
            },
            {
                title: "출고",
                key: "outbound",
                isSelected: false,
                additionalExceptionOnChangeHandler: (value, tempFilters) => {
                    return tempFilters.map((filters) => {
                        if (filters.key === "history") {
                            return {
                                ...filters,
                                filterList: value
                                    ? historyInboundFilter
                                    : historyInboundFilter,
                            };
                        }
                        return filters;
                    });
                },
            },
        ],
    },
    {
        title: "내역",
        key: "exchange_type_detail",
        isCheckbox: true,
        filterList: historyOutboundFilter,
    },
];
