import { ReactElement } from "react";
import styled from "styled-components";

export const AdManageCommonHistoryColumnSellNameContainer = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;

    color: #333333;
`;

type Props = {
    name: string;
};

const AdManageCommonHistoryColumnSellName = (
    props: Props,
): ReactElement | null => {
    return (
        <AdManageCommonHistoryColumnSellNameContainer>
            {props.name}
        </AdManageCommonHistoryColumnSellNameContainer>
    );
};

export default AdManageCommonHistoryColumnSellName;
