import { ReactElement } from "react";
import styled from "styled-components";
import Spinner from "../commonUnitComponent/spinner/Spinner";

export const DefaultLoadingFallbackContainer = styled.div<{
    containerHeight: string;
}>`
    display: flex;
    width: inherit;
    min-width: 100%;
    min-height: 100%;
    height: ${({ containerHeight }) => containerHeight};
    justify-content: center;
    align-items: center;
`;

type Props = {
    containerHeight: string;
};

const DefaultLoadingComponent = (props: Props): ReactElement | null => {
    return (
        <DefaultLoadingFallbackContainer
            containerHeight={props.containerHeight}
        >
            <Spinner borderSize={7} height={30} width={30} color={"black"} />
        </DefaultLoadingFallbackContainer>
    );
};

export default DefaultLoadingComponent;

DefaultLoadingComponent.defaultProps = {
    containerHeight: "inherit",
};
