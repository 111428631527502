import React, { ReactElement } from "react";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import styled from "styled-components";

export const ModalCloseIconImg = styled.img<{
    isShow: boolean;
    isMobileHide: boolean;
}>`
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    display: ${({ isShow }) => (isShow ? "block" : "none")};

    @media ${(props) => props.theme.mobileL} {
        display: ${({ isMobileHide }) => (isMobileHide ? "none" : "block")};
        width: 24px;
        height: 24px;
    }
`;

type Props = {
    isShow?: boolean;
    isMobileHide?: boolean;
    resolve: (value: boolean) => void;
};

const ModalCloseIcon = (props: Props): ReactElement | null => {
    return (
        <ModalCloseIconImg
            isShow={props.isShow ? props.isShow : true}
            isMobileHide={props.isMobileHide ? props.isMobileHide : false}
            onClick={() => {
                props.resolve(false);
            }}
            src={URL_CONSTANTS.ASSET_URL + "/icon/icon-close.svg"}
        />
    );
};

export default ModalCloseIcon;

ModalCloseIcon.defaultProps = {
    isShow: true,
    isMobileHide: true,
};
