import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { useAtom } from "jotai/index";
import userInfoAtom from "../../../../store/userInfo/userInfoAtom";
import { getIsLogin } from "../../../../store/auth/authKeyStore";
import {
    NOTI_PERMISSION_DEFAULT,
    NOTI_PERMISSION_GRANTED,
    useGetPushInfo,
} from "../../../../hooks/common/useGetPushInfo";
import { useOpenWebPushInduceModal } from "./modal/webPushInduceModal/useOpenWebPushInduceModal";
import { useLocalStorage } from "usehooks-ts";
import { useOpenWebPushDeniedConfirmModal } from "./modal/webPushDeniedConfirmModal/useOpenWebPushDeniedConfirmModal";
import { useLocation, useNavigate } from "react-router-dom";
import { WebPushApi } from "../../../../apis/WebPushApi";

export const WebPushSubscriberContainer = styled.div``;

type Props = {};

const WebPushSubscriber = (props: Props): ReactElement | null => {
    const [userInfo] = useAtom(userInfoAtom);
    const isLogin = getIsLogin();
    const navigate = useNavigate();
    const location = useLocation();

    const { openWebPushInduceModal } = useOpenWebPushInduceModal();
    const { openWebPushDeniedConfirmModal } =
        useOpenWebPushDeniedConfirmModal();
    const [isNotiInduceModalHiddenForWeekExpiredTime] = useLocalStorage(
        "isNotiInduceModalHiddenForWeekExpiredTime",
        0,
    );
    const [isNotiDeniedConfirmModalHiddenForWeekExpiredTime] = useLocalStorage(
        "isNotiDeniedConfirmModalHiddenForWeekExpiredTime",
        0,
    );

    const callbackAfterPathCheck = (callback: () => void) => {
        // userInfo 가 변경되는 페이지가 /auth/login/success 이라 여기서 모달 생성을 하면
        // 뒤로가기 path 가 꼬인다 강제로 조금 기다렸다가 실행하는 로직 넣기
        let cnt = 0;
        const interval = setInterval(() => {
            cnt = cnt + 1;
            if (window.location.pathname !== "/auth/login/success") {
                callback();
                clearInterval(interval);
            }

            // 최대 10 회까지만 try
            if (cnt === 10) {
                clearInterval(interval);
            }
        }, 300);
    };

    const {
        isWebPushSupport,
        webPushPermissionState,
        subscribeWebPushInClient,
        unsubscribeWebPushInClient,
        registerWebPushSubscription,
        updateWebPushSubscription,
        requestWebPushPermission,
    } = useGetPushInfo();

    const initWebPushSubscriber = async function () {
        if (isLogin && userInfo.user_idx !== "0") {
            if (isWebPushSupport) {
                // 푸시 허용이 이미 되있는 경우
                if (webPushPermissionState === NOTI_PERMISSION_GRANTED) {
                    const webPushSubscriptionInClient =
                        await subscribeWebPushInClient();

                    // service-worker 에서 webPushSubscriptionInClient 을 정상적으로 가져 온 경우
                    if (webPushSubscriptionInClient) {
                        // 위 subscription 으로 다른 계정으로 로그인할 수 도 있음. 그 경우 초기화를 위해 api 호출
                        await WebPushApi.reInitWebPushSubscription(
                            webPushSubscriptionInClient,
                        );

                        const isDeviceWebPushSubscribed =
                            await WebPushApi.checkWebPushSubscription(
                                webPushSubscriptionInClient,
                            );

                        // 이미 해당 기기로 push subscribe 를 하고 있었던 경우
                        if (isDeviceWebPushSubscribed) {
                            await updateWebPushSubscription(
                                webPushSubscriptionInClient,
                            );
                        }

                        // 이 기기로 push subscribe 를 안 하고 있었던 경우
                        else {
                            const today = new Date();
                            // 7 일간 보지 않기를 누르지 않았거나 7일을 초과한 경우
                            if (
                                today.getTime() >
                                isNotiInduceModalHiddenForWeekExpiredTime
                            ) {
                                callbackAfterPathCheck(async () => {
                                    const induceResult =
                                        await openWebPushInduceModal();

                                    // 푸시 허용 모달 오픈
                                    if (induceResult) {
                                        await registerWebPushSubscription(
                                            webPushSubscriptionInClient,
                                        );
                                    }
                                    // 푸시 허용 팝업에서 허용을 안 한경우 no action
                                });
                            }
                        }
                    }
                    // service-worker 에서 pushSubscription 을 정상적으로 못 가져 온 경우
                    else {
                        console.info("no-push-subscription");
                    }
                }
                // 푸시 허용팝업을 띄우는 경우
                else if (webPushPermissionState === NOTI_PERMISSION_DEFAULT) {
                    const today = new Date();

                    if (
                        today.getTime() >
                        isNotiInduceModalHiddenForWeekExpiredTime
                    ) {
                        callbackAfterPathCheck(async () => {
                            const induceResult = await openWebPushInduceModal();
                            // 푸시 허용 모달 오픈
                            if (induceResult) {
                                // 1. request permission system popup
                                // 2. 허용인 경우 > subscribe + register
                                await requestWebPushPermission();
                            }
                            // 푸시 허용 팝업에서 허용을 안 한경우 no action
                        });
                    }
                }
                // 푸시 허용이 이미 막혀있는 경우
                else {
                    const today = new Date();
                    if (
                        today.getTime() >
                        isNotiDeniedConfirmModalHiddenForWeekExpiredTime
                    ) {
                        callbackAfterPathCheck(async () => {
                            const deniedConfirmResult =
                                await openWebPushDeniedConfirmModal();

                            if (deniedConfirmResult) {
                                navigate("/my/notification");
                            }
                        });
                    }
                }
            }
        }
    };

    useEffect(() => {
        initWebPushSubscriber();
    }, [userInfo]);

    return null;
};

export default WebPushSubscriber;
