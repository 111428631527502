import { ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import { ChipType, SortingItemType } from "../LeftSideBar";
import SearchFilter, { FilterType } from "../../../searchFilter/SearchFilter";
import SortingButton from "../../../../commonUnitComponent/sortingButton/SortingButton";
import Chips from "../../../../commonUnitComponent/chips/Chips";
import { Link } from "react-router-dom";
import { getPageType } from "../../../../../../utils/Utils";
import { useWindowSize } from "usehooks-ts";
import { ResponsiveWidth } from "../../../../../../styles/Theme";

export const LeftSideBarHeaderContainer = styled.div`
    position: relative;
    padding: 24px 0 16px 0;

    @media ${(props) => props.theme.mobileL} {
        padding: 0 0 4px 0;
        height: auto;
    }
`;

export const LeftSideBarTitle = styled.div`
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    margin-left: 16px;
    color: #000000;
`;

export const LeftSideBarHeaderMenuWindow = styled.div`
    position: relative;
    margin-top: 18px;

    height: 44px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
`;

export const LeftSideBarOrderContainer = styled.div`
    margin-top: 4px;
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 5px);
    position: relative;
    padding-right: 5px;
    gap: 4px;
`;

export const LeftSideBarOrderSearchFilterContainer = styled.div`
    display: inline-block;
`;

export const LeftSideBarAddButtonContainer = styled.div`
    margin-top: 10px;
    padding: 0 9px;
    height: 40px;
`;

export const LeftSideBarAddButtonLink = styled(Link)`
    width: 100%;
    display: flex;
    height: 38px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #fa54fa;
    color: #fa54fa;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: rgba(250, 84, 250, 0.1);
        }
    }

    transition: all 300ms ease;
`;

type Props = {
    title: string;
    setHeaderHeight: (value: number) => void;

    headerChips: ChipType[];
    setHeaderChips: (value: ChipType[]) => void;

    sortingList: SortingItemType[];
    setSortingList: (value: SortingItemType[]) => void;

    headerFilters?: FilterType[];
    setHeaderFilters?: (value: FilterType[]) => void;
};
const LeftSideBarHeader = (props: Props): ReactElement | null => {
    const leftSidebarHeaderContainerRef = useRef<HTMLDivElement>(null);
    const pageType = getPageType(location.pathname);

    useEffect(() => {
        if (leftSidebarHeaderContainerRef.current) {
            props.setHeaderHeight(
                leftSidebarHeaderContainerRef.current.offsetHeight,
            );
        }
    }, [props.headerChips]);

    const { width } = useWindowSize();

    return (
        <LeftSideBarHeaderContainer ref={leftSidebarHeaderContainerRef}>
            <LeftSideBarTitle>{props.title}</LeftSideBarTitle>

            <LeftSideBarHeaderMenuWindow>
                <Chips
                    isOnlyOneSelected={true}
                    chips={props.headerChips}
                    setChips={props.setHeaderChips}
                />
            </LeftSideBarHeaderMenuWindow>

            <LeftSideBarOrderContainer>
                {props.headerFilters && props.setHeaderFilters && (
                    <LeftSideBarOrderSearchFilterContainer>
                        <SearchFilter
                            id={"left-side-header"}
                            layerPositionStyleString={"left: 10px"}
                            filters={props.headerFilters}
                            setFilters={props.setHeaderFilters}
                            filterItemWidth={
                                pageType === "ad" ? "200px" : "160px"
                            }
                            title={
                                pageType === "ad"
                                    ? "광고 상품 검색 필터"
                                    : "프로젝트 검색 필터"
                            } // 아이템 부스 > 프로젝트 리스트, 아이템 리스트
                            isForceLayerType={
                                pageType === "ad" &&
                                width > ResponsiveWidth.tabletM
                            }
                        />
                    </LeftSideBarOrderSearchFilterContainer>
                )}
                {!props.headerChips.find((chip) => chip.key === "recent")
                    ?.isSelected && (
                    <SortingButton
                        sortingList={props.sortingList}
                        setSortingList={props.setSortingList}
                    />
                )}
            </LeftSideBarOrderContainer>
            {props.headerChips.find((chip) => chip.key === "my")
                ?.isSelected && (
                <LeftSideBarAddButtonContainer>
                    <LeftSideBarAddButtonLink to={"/market/add-project"}>
                        새 프로젝트 추가
                    </LeftSideBarAddButtonLink>
                </LeftSideBarAddButtonContainer>
            )}
        </LeftSideBarHeaderContainer>
    );
};

export default LeftSideBarHeader;
