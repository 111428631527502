import { ReactElement } from "react";
import styled from "styled-components";
import StickyContents from "../../../../../common/commonUiComponent/doubleSideBar/stickyContents/StickyContents";
import { useItemDataQuery } from "../../../../../../query/item/useItemDataQuery";
import { useExtractUrlMetaQuery } from "../../../../../../query/item/useExtractUrlMetaQuery";
import { useLikeUnLike } from "../../../../../../hooks/common/useLikeUnLike";
import MarketItemTitleArea from "../marketItemScrollableContents/components/MarketItemTitleArea";

export const MarketItemStickyContentsContainer = styled.div``;

type Props = {
    itemIdx: string;
};

const MarketItemStickyContents = (props: Props): ReactElement | null => {
    const {
        data: rawItemData,
        error: itemDataError,
        isSuccess: isItemDataSuccess,
        isLoading: isItemDataLoading,
        isFetching: isItemDataFetching,
        refetch: refetchItemData,
    } = useItemDataQuery(props.itemIdx);

    const { data: rawProjectMetaData, isSuccess: isProjectMetaDataSuccess } =
        useExtractUrlMetaQuery(rawItemData.itemInfo.project_title_url);

    const { likeUnLike } = useLikeUnLike(
        "item",
        rawItemData.itemInfo.item_idx,
        rawItemData.itemInfo.like_bool,
    );

    return (
        <StickyContents
            shareDataTitle={rawItemData.itemInfo.item_name}
            shareDataDesc={rawItemData.itemInfo.item_description}
            thumbnail={rawItemData.itemInfo.origin_full_path}
            isLike={rawItemData.itemInfo.like_bool}
            likeCount={rawItemData.itemInfo.like_cnt}
            likeUnLike={async () => {
                await likeUnLike();
                await refetchItemData();
            }}
            titleUrl={rawItemData.itemInfo.project_title_url}
            titleUrlMetaData={rawProjectMetaData}
            renderTitleAreaComponent={() => (
                <MarketItemTitleArea type={"fixed"} />
            )}
        />
    );
};

export default MarketItemStickyContents;
