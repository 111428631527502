import { ReactElement } from "react";
import styled from "styled-components";
import {
    AdManageCommonMobileTableActionBtn,
    AdManageCommonMobileTableActionBtnContainer,
    AdManageCommonMobileTableContainer,
} from "../../adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";
import AdManageCommonMobileTableCell from "../../adManageCommonHistoryTable/AdManageCommonMobileTableCell";
import AdManageCommonHistoryColumnName from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnName";
import AdManageCommonMobileTableLayout from "../../adManageCommonHistoryTable/AdManageCommonMobileTableLayout";
import { MyAdAnalysisListItemType } from "../../../../../../query/my/myAd/useGetMyAdAnalysisListQuery";
import { TableHeaderDataType } from "../../../../../common/commonUnitComponent/commonTable/CommonTable";
import AdManageCommonHistoryColumnDate from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnDate";
import AdManageCommonHistoryColumnNormal from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnNormal";
import AdManageCommonHistoryColumnBrand from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBrand";
import AdManageCommonHistoryColumnPrice from "../../adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPrice";
import {
    convertSafariAdSalesDate,
    numberToStringWithComma,
} from "../../../../../../utils/Utils";
import { getMyAnalysisModalInfo } from "./adManageCommonAnalysisTableModal/AdManageCommonAnalysisTableModal";
import { useOpenAdManageCommonAnalysisTableModal } from "../../../../../../hooks/modal/openModal/useOpenAdManageCommonAnalysisTableModal";
import { AD_SALE_ANALYSIS } from "../../../_adManageLayout/AdManageTableContentsLayout";

export const AdManageCommonAnalysisMobileTableContainer = styled.div``;

type Props = {
    adAnalysisList: MyAdAnalysisListItemType[];
    type: string;
};

const AdManageCommonAnalysisMobileTable = (
    props: Props,
): ReactElement | null => {
    const TABLE_KEY = "ad_analysis_table_";
    const { openAdHistoryAnalysisModal } =
        useOpenAdManageCommonAnalysisTableModal(props.type);

    const convertMobileRowValue = (
        rawRowKey: TableHeaderDataType,
        rawRowValue: MyAdAnalysisListItemType,
    ) => {
        switch (rawRowKey.key) {
            // 성과 분석 - mobileTable : 광고 적용일
            case TABLE_KEY + "ad_started_at":
                return (
                    <AdManageCommonHistoryColumnDate
                        date={
                            new Date(
                                convertSafariAdSalesDate(
                                    rawRowValue.advertisementDailyInfo
                                        .ad_sales_date,
                                ),
                            )
                        }
                        format={"yyyy.MM.dd"}
                    />
                );

            // 성과 분석 - mobileTable : 브랜드
            case TABLE_KEY + "brand":
                return (
                    <AdManageCommonHistoryColumnBrand
                        brandId={rawRowValue.brandInfo.brand_id}
                        brandName={rawRowValue.brandInfo.brand_name}
                    />
                );

            // 성과 분석 - mobileTable : 낙찰가
            case TABLE_KEY + "offer_success_price":
                return (
                    <AdManageCommonHistoryColumnPrice
                        offerPrice={
                            rawRowValue.advertisementDailyInfo
                                .otherOfferSuccessInfo?.offer_price || "-"
                        }
                    />
                );

            // 성과 분석 - mobileTable : 클릭수/노출수(비율)
            case TABLE_KEY + "click_expose_ratio":
                return (
                    <AdManageCommonHistoryColumnNormal
                        msg={`${numberToStringWithComma(
                            rawRowValue.total_click_count,
                        )}/${numberToStringWithComma(
                            rawRowValue.total_impression_count,
                        )} (${
                            rawRowValue.total_impression_count !== 0 &&
                            rawRowValue.total_click_count !== 0
                                ? Math.round(
                                      (rawRowValue.total_click_count /
                                          rawRowValue.total_impression_count) *
                                          10000,
                                  ) /
                                      100 +
                                  "%"
                                : " - "
                        })`}
                    />
                );

            default:
                return <span>{rawRowKey.key} is not found</span>;
        }
    };

    const mobileHeaderData: TableHeaderDataType[] = [
        {
            key: TABLE_KEY + "ad_started_at",
            component: "광고적용일",
        },
        {
            key: TABLE_KEY + "brand",
            component: "브랜드",
        },
        {
            key: TABLE_KEY + "offer_success_price",
            component: "낙찰가(KRW)",
        },
        {
            key: TABLE_KEY + "click_expose_ratio",
            component: "클릭수/노출수(비율)",
        },
    ];

    return (
        <AdManageCommonMobileTableLayout
            isNoData={props.adAnalysisList.length === 0}
        >
            <AdManageCommonMobileTableContainer>
                {props.adAnalysisList.map(
                    (
                        analysisInfo: MyAdAnalysisListItemType,
                        analysisInfoIdx: number,
                    ) => {
                        return (
                            <AdManageCommonMobileTableCell
                                key={analysisInfoIdx}
                                mobileHeaderDataList={mobileHeaderData}
                                rawRowValue={analysisInfo}
                                titleComponent={
                                    <AdManageCommonHistoryColumnName
                                        isOnlyProductName={
                                            props.type === AD_SALE_ANALYSIS
                                        }
                                        adProductName={
                                            props.type === AD_SALE_ANALYSIS
                                                ? analysisInfo.campaignInfo
                                                      .campaign_name
                                                : analysisInfo.advertisementInfo
                                                      .ad_product_name
                                        }
                                        adProductSubName={
                                            analysisInfo.advertisementInfo
                                                .ad_product_sub_name
                                        }
                                        adThumbnail={
                                            analysisInfo.advertisementInfo
                                                .ad_thumbnail_path
                                        }
                                    />
                                }
                                convertRowValue={convertMobileRowValue}
                                buttonComponent={
                                    <AdManageCommonMobileTableActionBtnContainer>
                                        <AdManageCommonMobileTableActionBtn
                                            onClick={() => {
                                                return openAdHistoryAnalysisModal(
                                                    getMyAnalysisModalInfo(
                                                        analysisInfo,
                                                    ),
                                                );
                                            }}
                                        >
                                            상세 보기
                                        </AdManageCommonMobileTableActionBtn>
                                    </AdManageCommonMobileTableActionBtnContainer>
                                }
                            />
                        );
                    },
                )}
            </AdManageCommonMobileTableContainer>
        </AdManageCommonMobileTableLayout>
    );
};

export default AdManageCommonAnalysisMobileTable;
