export const ItemCategory = {
    title: "카테고리",
    key: "category",
    isCheckbox: true,
    filterList: [
        {
            title: "쿠폰",
            key: "0001",
            isSelected: false,
        },
        {
            title: "포토카드",
            key: "0002",
            isSelected: false,
        },
        {
            title: "스테츄",
            key: "0003",
            isSelected: false,
        },
        {
            title: "하이라이트",
            key: "0004",
            isSelected: false,
        },
        {
            title: "인정서",
            key: "0005",
            isSelected: false,
        },
    ],
};
