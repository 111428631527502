import React, { ReactElement, useRef } from "react";
import styled from "styled-components";
import { ItemListItemType } from "../../../../../../query/item/useItemListQuery";
import { TitleItemListItemType } from "../../../../../../query/item/titleItem/useTitleItemListQuery";
import { useIsMe } from "../../../../../../hooks/common/useIsMe";
import {
    checkUserAgent,
    numberToStringWithComma,
    onErrorImg,
    vibrate,
} from "../../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import DndListItemTrigger from "../../../../../common/commonUnitComponent/dndList/DndListItemTrigger";
import ImageFullSizeViewButton from "../../../../../common/commonUnitComponent/imageFullSizeView/ImageFullSizeViewButton";
import { useItemTitleRegisterDelete } from "../../../../../../hooks/item/useItemTitleRegisterDelete";
import { useDeviceWidth } from "../../../../../../hooks/ui/useDeviceWidth";
import ImageWithSkeleton from "../../../../../common/commonUiComponent/skeletonImage/ImageWithSkeleton";
import Shimmer from "../../../../../common/commonUiComponent/shimmer/Shimmer";
import SquareImg from "../../../../../common/commonUiComponent/squqreImg/SquareImg";

export const WalletCollectionItemThumbnailContainer = styled.div`
    position: relative;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    background: #f5f5f7;

    @media ${(props) => props.theme.mobileL} {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;
    }
`;

export const WalletCollectionItemThumbnailImgContainer = styled.div`
    width: 100%;
    height: inherit;
    border-radius: 16px;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const WalletCollectionItemThumbnailImg = styled.img`
    width: 100%;
    height: 100%;
`;

export const WalletCollectionItemThumbnailPrivateDimContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const WalletCollectionItemThumbnailPrivateDimIconImg = styled.img`
    width: 72px;
    height: 72px;
`;

export const WalletCollectionItemThumbnailMoveIcon = styled.img<{
    isMobileDevice: boolean;
    isMobileOrderChanging: boolean;
    isContainerHover: boolean;
}>`
    position: absolute;
    top: 8px;
    left: 8px;
    width: 40px;
    height: 40px;

    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    transition: 300ms opacity ease;

    visibility: hidden;
    ${({ isMobileDevice, isContainerHover }) => {
        if (!isMobileDevice) {
            if (isContainerHover) {
                return `
                    opacity: 0;
                    z-index: 2200;            
                    visibility: visible;
                    opacity: 1;
                `;
            } else {
                return `
                    opacity: 0;
                    z-index: 2200;
                `;
            }
        }
    }}

    ${({ isMobileDevice, isMobileOrderChanging }) => {
        if (isMobileDevice) {
            return `
                
                visibility: ${
                    isMobileDevice && isMobileOrderChanging
                        ? "visible"
                        : "hidden"
                };
                display: ${
                    isMobileDevice && isMobileOrderChanging ? "block" : "none"
                };
            `;
        }
    }}
`;

export const WalletCollectionItemThumbnailRepresentativeIconContainer = styled.div<{
    isContainerHover: boolean;
    isRepresentative: boolean;
}>`
    position: absolute;
    top: -14px;
    left: 14px;
    width: 40px;
    height: 52.001px;
    opacity: ${({ isContainerHover, isRepresentative }) =>
        isContainerHover || isRepresentative ? 1 : 0};
    transition: all 300ms ease;

    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));

    @media ${(props) => props.theme.mobileL} {
        top: 0;
        display: none;
    }
`;

export const WalletCollectionItemThumbnailRepresentativeIconUpperBox = styled.div<{
    isRepresentative: boolean;
}>`
    width: 40px;
    height: 12px;
    background: ${({ isRepresentative }) =>
        isRepresentative ? "#fc0" : "#C8C8C8"};
    transition: all 300ms ease;

    ${WalletCollectionItemThumbnailRepresentativeIconContainer}:hover & {
        background: ${({ isRepresentative }) =>
            isRepresentative ? "#FFD942" : "#D9D9D9"};
    }
`;

export const WalletCollectionItemThumbnailRepresentativeIconImgContainer = styled.div<{
    isRepresentative: boolean;
}>`
    width: 40px;
    height: 40px;
    background: ${({ isRepresentative }) =>
        isRepresentative ? "#fc0" : "#C8C8C8"};
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    transition: all 300ms ease;

    ${WalletCollectionItemThumbnailRepresentativeIconContainer}:hover & {
        background: ${({ isRepresentative }) =>
            isRepresentative ? "#FFD942" : "#D9D9D9"};
    }
`;

export const WalletCollectionItemThumbnailRepresentativeIconImg = styled.img``;

export const WalletCollectionItemThumbnailCountContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 6px 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
`;

type Props = {
    userIdx: string;
    item: ItemListItemType | TitleItemListItemType | any; // 앞에 두개인데 value 가 달라서 any 로 열어놓긴한다
    selectedSubKey: string;
    isMobileOrderChanging?: boolean;
    bind?: any;
    renderDndTrigger?: (children: ReactElement) => ReactElement;
    collectionItemListType?: string;
    isContainerHover: boolean;
    refetchItemListTitleItemListQuery?: () => Promise<void>;
    isImageLoading: boolean;
    setIsImageLoading: (value: boolean) => void;
};

const WalletCollectionItemThumbnail = (props: Props): ReactElement | null => {
    const isRepresentativeItem = props.selectedSubKey === "representative";
    const isMe = useIsMe();
    const thumbnailRef = useRef<HTMLImageElement>(null);
    const { itemTitleRegisterDelete } = useItemTitleRegisterDelete(
        props.item.item_idx,
        props.item.title_bool,
        async () => {
            if (props.refetchItemListTitleItemListQuery)
                await props.refetchItemListTitleItemListQuery();
        },
    );
    const { isMobileWidth } = useDeviceWidth();

    return (
        <WalletCollectionItemThumbnailContainer>
            <WalletCollectionItemThumbnailImgContainer>
                <Shimmer isLoading={props.isImageLoading}>
                    <SquareImg
                        imgRef={thumbnailRef}
                        src={props.item.item_thumbnail_full_path}
                        setIsImageLoading={props.setIsImageLoading}
                        imageResizingPostfix={"_250x250.webp"}
                    />
                </Shimmer>
            </WalletCollectionItemThumbnailImgContainer>

            {isRepresentativeItem && isMe(props.userIdx) && (
                <DndListItemTrigger
                    renderTrigger={() => (
                        <WalletCollectionItemThumbnailMoveIcon
                            isContainerHover={props.isContainerHover}
                            onClick={() => {
                                vibrate(100);
                            }}
                            isMobileOrderChanging={
                                props.isMobileOrderChanging
                                    ? props.isMobileOrderChanging
                                    : false
                            }
                            isMobileDevice={checkUserAgent.isMobileDevice()}
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-move.svg`}
                        />
                    )}
                />
            )}
            {!isRepresentativeItem &&
                isMe(props.userIdx) &&
                (props.selectedSubKey === "owned" ||
                    props.selectedSubKey === "representative") && (
                    <WalletCollectionItemThumbnailRepresentativeIconContainer
                        isContainerHover={props.isContainerHover}
                        isRepresentative={props.item.title_bool}
                        onClick={async (e) => {
                            if (!isMobileWidth) {
                                e.preventDefault();
                                e.stopPropagation();
                                await itemTitleRegisterDelete();
                            }
                        }}
                    >
                        <WalletCollectionItemThumbnailRepresentativeIconUpperBox
                            isRepresentative={props.item.title_bool}
                        />
                        <WalletCollectionItemThumbnailRepresentativeIconImgContainer
                            isRepresentative={props.item.title_bool}
                        >
                            <WalletCollectionItemThumbnailRepresentativeIconImg
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-white-fav.svg`}
                            />
                        </WalletCollectionItemThumbnailRepresentativeIconImgContainer>
                    </WalletCollectionItemThumbnailRepresentativeIconContainer>
                )}
            {props.item.own_cnt >= 2 && (
                <WalletCollectionItemThumbnailCountContainer>
                    {numberToStringWithComma(props.item.own_cnt)}개
                </WalletCollectionItemThumbnailCountContainer>
            )}

            {!isRepresentativeItem && (
                <ImageFullSizeViewButton
                    src={props.item.item_thumbnail_full_path}
                    info={[
                        "아이템명 : " + props.item.item_name,
                        "프로젝트명 : " + props.item.project_name,
                    ]}
                    thumbnailRef={thumbnailRef}
                />
            )}
        </WalletCollectionItemThumbnailContainer>
    );
};

export default WalletCollectionItemThumbnail;
