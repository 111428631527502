import { ReactElement } from "react";
import styled from "styled-components";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import {
    AdRegisterAdjustBodyContainer,
    AdRegisterAdjustHeaderTitle,
} from "../AdRegisterContents";

export const AdRegisterAdjustIntroduceContainer = styled.div``;

export const AdRegisterAdjustIntroduceDescContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #757575;
`;

export const AdRegisterAdjustIntroduceDesc = styled.div``;
type Props = {
    campaignName: string;
    campaignDesc: string;
};
const AdRegisterAdjustIntroduce = (props: Props): ReactElement | null => {
    // 등록하기일때는 campaign idx 상세조회로 데이터 가져오고.
    // 수정하기일때는 ad_idx 상세조회로 데이터 가져온다.
    const splitDescription = props.campaignDesc.split("\r\n");
    return (
        <WhiteBox>
            <AdRegisterAdjustIntroduceContainer>
                <AdRegisterAdjustHeaderTitle>
                    {props.campaignName}
                </AdRegisterAdjustHeaderTitle>
                <AdRegisterAdjustBodyContainer>
                    <AdRegisterAdjustIntroduceDescContainer>
                        {splitDescription.map((desc: string, idx: number) => (
                            <AdRegisterAdjustIntroduceDesc key={idx}>
                                {desc === "" ? <>&nbsp;</> : desc}
                            </AdRegisterAdjustIntroduceDesc>
                        ))}
                    </AdRegisterAdjustIntroduceDescContainer>
                </AdRegisterAdjustBodyContainer>
            </AdRegisterAdjustIntroduceContainer>
        </WhiteBox>
    );
};

export default AdRegisterAdjustIntroduce;
