import {
    ExceptionUiHandlerType,
    NEED_TO_DUPLICATE_CHECK_MSG,
} from "./callApiExcpetionHandler";
import { useOpenCommonErrorModal } from "../../hooks/modal/openModal/useOpenCommonErrorModal";

export function useExceptionUiHandlerBeforeCallApi(
    exceptionUiHandlers: ExceptionUiHandlerType[],
) {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const exceptionUiHandleBeforeCallApi = async function (
        isSetWarningMsg = true,
        checkExceptionLength?: number,
    ) {
        const moveScroll = (handler: ExceptionUiHandlerType) => {
            if (handler.targetContainerRef) {
                handler.targetContainerRef.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        };
        let breakFlag = false;

        const checkExceptionUiHandlerLength = checkExceptionLength
            ? checkExceptionLength
            : exceptionUiHandlers.length;

        for (let i = 0; i < checkExceptionUiHandlerLength; i++) {
            const handler = exceptionUiHandlers[i];
            // inputText 계통
            if (!!handler.inputText && !!handler.setInputText) {
                if (handler.isBlankCheck) {
                    if (handler.inputText.value === "") {
                        if (isSetWarningMsg) {
                            handler.setInputText({
                                ...handler.inputText,
                                isWarning: true,
                                warningMsg:
                                    "반드시 작성되어야 하는 영역입니다.",
                            });
                            moveScroll(handler);
                        }

                        breakFlag = true;
                        break;
                    }
                }

                if (handler.isWarningCheck) {
                    if (handler.inputText.isWarning) {
                        if (isSetWarningMsg) {
                            moveScroll(handler);
                        }

                        breakFlag = true;
                        break;
                    }
                }

                if (handler.isDuplicateCheck) {
                    if (typeof handler.isDuplicatePass === "undefined") {
                        console.error("need to set isDuplicatePass");
                    }

                    if (!handler.isDuplicatePass) {
                        if (isSetWarningMsg) {
                            handler.setInputText({
                                ...handler.inputText,
                                isWarning: true,
                                warningMsg: NEED_TO_DUPLICATE_CHECK_MSG,
                            });
                            moveScroll(handler);
                        }

                        breakFlag = true;
                        break;
                    }
                }
            }

            // file 계통
            if (handler.file) {
                if (handler.isFileMinSizeCheck) {
                    if (handler.file.size === 0) {
                        if (isSetWarningMsg) {
                            moveScroll(handler);
                            setTimeout(() => {
                                openCommonErrorModal({
                                    title: `파일업로드가 필요합니다.`,
                                });
                            }, 200);
                        }

                        breakFlag = true;
                        break;
                    }
                }
            }

            // selectedIdx 계통
            if (handler.selectedItemIdx) {
                if (Number(handler.selectedItemIdx) < 0) {
                    if (isSetWarningMsg) {
                        moveScroll(handler);
                        setTimeout(() => {
                            openCommonErrorModal({
                                title: `반드시 선택해야하는 영역입니다.`,
                            });
                        }, 200);
                    }

                    breakFlag = true;
                    break;
                }
            }

            // path 계통
            if (handler.filePath) {
                if (handler.isBlankCheck) {
                    if (handler.filePath === "") {
                        moveScroll(handler);
                        setTimeout(() => {
                            openCommonErrorModal({
                                title: `파일업로드가 필요합니다.`,
                            });
                        }, 200);
                        breakFlag = true;
                        break;
                    }
                }
            }
        }

        return !breakFlag;
    };

    return { exceptionUiHandleBeforeCallApi };
}
