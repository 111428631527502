import { ReactElement } from "react";
import styled from "styled-components";
import CommonTabBox from "../../../../../../common/commonUnitComponent/commonTabBox/CommonTabBox";
import { useItemDataQuery } from "../../../../../../../query/item/useItemDataQuery";
import { useParams } from "react-router-dom";

export const MarketItemIntroduceBoxContainer = styled.div``;

export const MarketItemIntroduceContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #555555;
    display: flex;
    flex-direction: column;
`;

export const MarketItemIntroduce = styled.div`
    word-break: break-all;
`;

type Props = {};

const MarketItemIntroduceBox = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const { data: rawItemData } = useItemDataQuery(itemIdx);

    const splitDescription =
        rawItemData.itemInfo.item_description.split("\r\n");

    return (
        <CommonTabBox
            iconUrl={"/icon/icon-info-black.svg"}
            isDefaultOpen={true}
            tabList={[
                {
                    title: "아이템 소개",
                },
            ]}
        >
            <MarketItemIntroduceContainer>
                {splitDescription.map((desc, idx) => (
                    <MarketItemIntroduce key={idx}>
                        {desc === "" ? <>&nbsp;</> : desc}
                    </MarketItemIntroduce>
                ))}
            </MarketItemIntroduceContainer>
        </CommonTabBox>
    );
};

export default MarketItemIntroduceBox;
