import { ReactElement, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { getIsLogin } from "../../../../../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import styled from "styled-components";
import HeaderMenuDropdownListItem from "./HeaderMenuDropdownListItem";
import isDarkModeAtom from "../../../../../../store/ui/isDarkModeAtom";
import { useAtom } from "jotai/react";
import { INTRO_PAGE_REG_EXP } from "../../../../../../constants/PageRegExp";

export const HeaderMenuLinkContainer = styled.div<{
    isDarkMode: boolean;
    isSelected: boolean;
}>`
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    border-radius: 10px;
    transition: all 300ms ease;
    color: ${({ isDarkMode, isSelected }) => {
        if (isSelected) {
            return isDarkMode ? "#FFFFFF" : "#FA54FA";
        } else {
            return isDarkMode ? "#FFFFFF" : "#000000";
        }
    }};
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isDarkMode }) =>
                isDarkMode
                    ? "rgba(255, 255, 255, 0.12)"
                    : "rgba(13, 13, 26, 0.06)"};
        }
    }

    position: relative;
`;
export const HeaderMenuLink = styled(Link)`
    padding: 10px 28px;
    display: inline-block;
    float: left;

    cursor: pointer;
    font-size: 16px;
    line-height: 100%;
    color: inherit;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const DropdownMoreIcon = styled.img`
    margin-left: 7px;
    margin-bottom: 3px;
    margin-right: 4px;
    width: 12px;
    height: 7px;
`;

export const HeaderDropdownMenuContainer = styled.div<{
    liLength: number;
    isOpen: boolean;
}>`
    position: absolute;
    top: 38px;
    width: 180px;

    height: ${({ isOpen, liLength }) =>
        isOpen ? liLength * 44 + 20 + "px" : "0"};
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    right: calc(50% - 90px);
    color: black;
    z-index: 22;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    background: white;

    transition: all 300ms ease-in-out;

    overflow: hidden;
`;

export const HeaderDropdownMenuList = styled.div`
    position: absolute;
    width: calc(100% - 20px);
    padding: 10px;
`;

export type LinkMenuType = {
    id: number;
    name: string;
    path: string;
    children?: LinkMenuType[];
    icon?: string;
    isNewTab?: boolean;
    isNoNavigate?: boolean;
    isNeedLogin?: boolean;
    imgPath?: string;
};

type Props = {
    headerMenu: LinkMenuType;
    isMoreMenu: boolean;
};

const HeaderMenu = (props: Props): ReactElement | null => {
    const location = useLocation();

    const [isHeaderMenuDropdownOpen, setIsHeaderMenuDropdownOpen] =
        useState(false);

    const hasChildren = typeof props.headerMenu.children !== "undefined";
    const [isDarkMode] = useAtom(isDarkModeAtom);

    const { openLoginConfirmModal } = useOpenLoginConfirmModal();

    function isParentMenuSelected() {
        if (props.headerMenu.path === "/my-wallet") {
            return /\/wallet\/[a-zA-Z0-9]+/.test(location.pathname);
        } else if (props.headerMenu.path === "/") {
            return getIsLogin()
                ? location.pathname === "/"
                : INTRO_PAGE_REG_EXP.test(location.pathname);
        } else {
            return (
                location.pathname.split("/")[1] ===
                props.headerMenu.path.split("/")[1]
            );
        }
    }

    return (
        <HeaderMenuLinkContainer
            key={props.headerMenu.id}
            isDarkMode={isDarkMode}
            isSelected={isParentMenuSelected()}
            onMouseOver={() => {
                if (hasChildren) {
                    setIsHeaderMenuDropdownOpen(true);
                }
            }}
            onMouseOut={() => {
                setIsHeaderMenuDropdownOpen(false);
            }}
        >
            <HeaderMenuLink
                onClick={async (e) => {
                    if (props.isMoreMenu) e.preventDefault();
                    if (props.headerMenu.isNeedLogin && !getIsLogin()) {
                        await openLoginConfirmModal(props.headerMenu.path);
                    }
                }}
                to={
                    props.headerMenu.isNeedLogin && !getIsLogin()
                        ? {}
                        : props.headerMenu.path === "/"
                        ? getIsLogin()
                            ? "/"
                            : "/intro"
                        : props.headerMenu.path
                }
            >
                {props.headerMenu.name}
                {props.isMoreMenu && (
                    <DropdownMoreIcon
                        src={
                            isDarkMode
                                ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-more.png`
                                : `${URL_CONSTANTS.ASSET_URL}/icon/icon-more-black.png`
                        }
                    />
                )}
            </HeaderMenuLink>
            <HeaderDropdownMenuContainer
                className="menu"
                isOpen={isHeaderMenuDropdownOpen}
                liLength={
                    props.headerMenu.children
                        ? props.headerMenu.children?.length
                        : 0
                }
            >
                <HeaderDropdownMenuList>
                    {hasChildren &&
                        props.headerMenu.children?.map((menu, idx) => (
                            <HeaderMenuDropdownListItem
                                key={idx}
                                setIsHeaderMenuDropdownOpen={
                                    setIsHeaderMenuDropdownOpen
                                }
                                isMoreMenu={props.isMoreMenu}
                                menu={menu}
                            />
                        ))}
                </HeaderDropdownMenuList>
            </HeaderDropdownMenuContainer>
        </HeaderMenuLinkContainer>
    );
};

export default HeaderMenu;
