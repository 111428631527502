import { ReactElement } from "react";
import styled from "styled-components";
import {
    AD_IN_REVIEW,
    AD_ON_BID,
    AdDailyListItemStatusCodeType,
} from "../../../../../../../../types/ad/adDaily/AdDailyListItemType";
import { AdSaleManageOfferListModalPreviewBtn } from "../AdSaleManageOfferListModalPreview";
import { useOpenCommonConfirmModal } from "../../../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { useAdCancelOfferMutation } from "../../../../../../../../query/ad/adBidOffer/useAdCancelOfferMutation";
import { PathAfterResolveType } from "../../../../../../../../hooks/modal/useOpenModal";
import { AD_OFFER_STATUS_OFFERED } from "../../../../../../../../hooks/ad/staticConvertAdOfferStatus";

export const AdSaleManageOfferListModalPreviewCancelOfferBtnContainer = styled.div``;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    adIdx: string;
    selectedOfferIdx: string;
    adSalesDate: Date;
    offerStatus: string;
    adDailyStatus: AdDailyListItemStatusCodeType;
    refreshCache: () => Promise<void>;
};

const AdSaleManageOfferListModalPreviewCancelOfferBtn = (
    props: Props,
): ReactElement | null => {
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();

    const { mutateAsync: cancelOffer } = useAdCancelOfferMutation(
        props.adIdx,
        props.selectedOfferIdx,
    );

    return (props.adDailyStatus === AD_ON_BID ||
        props.adDailyStatus === AD_IN_REVIEW) &&
        props.offerStatus === AD_OFFER_STATUS_OFFERED ? (
        <AdSaleManageOfferListModalPreviewBtn
            isOnlyMobileShow={false}
            btnType={"filled-danger"}
            onClick={async () => {
                const confirmResult = await openCommonConfirmModal({
                    title: "템플릿 반려",
                    desc: "선택한 템플릿을 반려합니다. 반려한 템플릿은 낙찰할 수 없습니다.",
                    buttonTitle: "템플릿 반려",
                    buttonType: "filled-danger",
                });

                try {
                    if (confirmResult) {
                        await cancelOffer();
                        await props.refreshCache();
                    }
                } catch (e) {
                    console.info(e);
                }
            }}
        >
            템플릿 반려
        </AdSaleManageOfferListModalPreviewBtn>
    ) : null;
};

export default AdSaleManageOfferListModalPreviewCancelOfferBtn;
