import { useAtom } from "jotai";
import { Query, useMutation } from "react-query";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { queryClient } from "../../../App";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { AdTemplateApi } from "../../../apis/adApi/AdTemplateApi";

export const useAdTemplateDeleteMutation = (templateIdx: string) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => AdTemplateApi.deleteTemplate(templateIdx),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                useAdTemplateDeleteMutation;
                pushSnackBarListItem({
                    title: "",
                    desc: "템플릿이 정상적으로 삭제되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });

                await queryClient.resetQueries({
                    predicate: (query: Query) =>
                        query.queryKey.includes("ad-template-list") ||
                        query.queryKey.includes("ad-template-" + templateIdx),
                });
            },
        },
    );
};
