import { ReactElement, useEffect, useRef, useState } from "react";

import { getIsLogin } from "../../../../store/auth/authKeyStore";
import NotiListSubHeader from "./components/notiListSubHeader/NotiListSubHeader";
import NotiListBody from "./components/notiListBody/NotiListBody";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import { ChipType } from "../../commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import styled from "styled-components";
import { useLocalStorage, useOnClickOutside } from "usehooks-ts";
import { checkUserAgent, getSelectedChip } from "../../../../utils/Utils";
import {
    spreadNotificationListPages,
    useGetNotificationListQuery,
} from "../../../../query/notification/useGetNotificationListQuery";
import NotiListHeader from "./components/NotiListHeader";
import ApiResBoundary from "../../apiResBoundary/ApiResBoundary";
import { queryClient } from "../../../../App";
import { Query } from "react-query";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";
import { GLOBAL_NOTI_LIST_MODAL } from "../../../../hooks/modal/openModal/useOpenNotiListModal";

export const NotiListWrapper = styled.div<{
    vh: number;
    isLoading: boolean;
}>`
    position: fixed;
    width: 380px;
    top: 72px;
    right: 16px;

    background: white;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 12px;
    z-index: 20;
    transition: all 300ms ease;
    overflow: hidden;

    @media ${(props) => props.theme.tabletL} {
        top: 0;
        left: 0;
        width: 100%;
        height: ${({ vh }) => 100 * vh + "px"};

        box-shadow: none;
        line-height: 1;
        border-radius: 0;
    }
`;

export const NotiListContainer = styled.div`
    padding: 10px 0 0 0;

    @media ${(props) => props.theme.tabletL} {
        padding: 0;
    }
`;

export const NotiKeywordChips: ChipType[] = [
    {
        title: "공지",
        key: "ntc",
        isSelected: false,
    },
    {
        title: "광고",
        key: "ad",
        isSelected: false,
    },
    {
        title: "플리마켓",
        key: "market",
        isSelected: false,
    },
    {
        title: "지갑",
        key: "wallet",
        isSelected: false,
    },
];

export const getNotificationListParams = function (
    notiKeywordChips: ChipType[],
    isOnlyNotRead: boolean,
) {
    return {
        list_type: getSelectedChip(notiKeywordChips),
        only_not_read_bool: isOnlyNotRead ? 1 : 0,
        limit: 20,
        page_no: 1,
    };
};

type Props = {
    resolve: (value: number | boolean) => void;
};

const NotiListModal = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const [isOnlyNotRead, setIsOnlyNotRead] = useState(false);
    const [notiKeywordChips, setNotiKeywordChips] = useState(NotiKeywordChips);
    const notiListWrapperRef = useRef<HTMLDivElement>(null);
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const userProfileModalComponentInfo = modalComponentInfoList.find(
        (info) => info.id === GLOBAL_NOTI_LIST_MODAL,
    );
    const wrapperRef =
        userProfileModalComponentInfo?.nodeRef || notiListWrapperRef;

    const [isBackBtnClicked] = useLocalStorage("isBackBtnClicked", false);

    useOnClickOutside(wrapperRef, (e: any) => {
        if (
            e.target.id !== "header-noti-icon" &&
            e.target.id !== "noti-all-delete-cancel-btn" &&
            e.target.id !== "noti-all-delete-btn"
        ) {
            props.resolve(-1);
        }
    });

    const {
        data: rawNotificationList,
        isSuccess: isNotificationListSuccess,
        isLoading: isNotificationListLoading,
        error: errorNotificationList,
    } = useGetNotificationListQuery(
        getNotificationListParams(notiKeywordChips, isOnlyNotRead),
        true,
    );

    const notificationList = spreadNotificationListPages(
        rawNotificationList.pages,
    );

    // list call 이 끝난 후 notification/status > view_at 갱신 후 재 호출
    useEffect(() => {
        if (isNotificationListSuccess) {
            queryClient.resetQueries({
                predicate: (query: Query) =>
                    query.queryKey.includes("notification-status"),
            });
        }
    }, [rawNotificationList, isNotificationListSuccess]);

    return (
        <NotiListWrapper
            vh={vh}
            ref={wrapperRef}
            isLoading={isNotificationListLoading}
            style={
                checkUserAgent.isIOS() && isBackBtnClicked
                    ? { transition: "none" }
                    : {}
            }
        >
            <ApiResBoundary
                isLoading={false}
                error={errorNotificationList}
                isNoData={false}
            >
                <NotiListContainer>
                    <NotiListHeader
                        resolve={props.resolve}
                        notificationList={notificationList}
                        notiKeywordChips={notiKeywordChips}
                        setNotiKeywordChips={setNotiKeywordChips}
                    />

                    {getIsLogin() && (
                        <NotiListSubHeader
                            notiKeywordChips={notiKeywordChips}
                            isOnlyNotRead={isOnlyNotRead}
                            setIsOnlyNotRead={setIsOnlyNotRead}
                        />
                    )}

                    <NotiListBody
                        resolve={props.resolve}
                        notificationList={notificationList}
                    />
                </NotiListContainer>
            </ApiResBoundary>
        </NotiListWrapper>
    );
};

export default NotiListModal;
