import { ReactElement } from "react";
import styled from "styled-components";
import { onErrorImg } from "../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { Link, useLocation } from "react-router-dom";
import isDarkModeAtom from "../../../../../store/ui/isDarkModeAtom";
import { useAtom } from "jotai";
import { INTRO_PAGE_REG_EXP } from "../../../../../constants/PageRegExp";
import { getIsLogin } from "../../../../../store/auth/authKeyStore";

export const HeaderLogoLink = styled(Link)`
    display: flex;
    align-items: center;
`;

export const HeaderLogo = styled.img`
    width: auto;
    height: 32px;
    cursor: pointer;

    @media ${(props) => props.theme.tabletL} {
        height: 29px;
    } ;
`;

type Props = {};

const HeaderLogoArea = (props: Props): ReactElement | null => {
    const [isDarkMode] = useAtom(isDarkModeAtom);
    const isLogin = getIsLogin();
    const location = useLocation();
    const isBlackLogo = INTRO_PAGE_REG_EXP.test(location.pathname);

    return (
        <HeaderLogoLink to={isLogin ? "/" : "/intro"}>
            {location.pathname !== "/my/menu" && (
                <HeaderLogo
                    onError={onErrorImg}
                    onClick={() => {
                        if (location.pathname === "/") {
                            // console.info("force rerender 준비");
                        }
                    }}
                    src={
                        isDarkMode
                            ? `${URL_CONSTANTS.ASSET_URL}/logo/logo-saybuzz-white2.svg`
                            : isBlackLogo
                            ? `${URL_CONSTANTS.ASSET_URL}/logo/logo-saybuzz-black2.svg`
                            : `${URL_CONSTANTS.ASSET_URL}/logo/logo-saybuzz.svg`
                    }
                />
            )}
        </HeaderLogoLink>
    );
};

export default HeaderLogoArea;
