import { format } from "date-fns";
import {
    AD_BID_CLOSED,
    AD_IN_REVIEW,
    AD_ON_BID,
    AD_ON_SCHEDULE,
    AdDailyListItemType,
    DEFAULT_ADVERTISEMENT_DAILY_LIST_ITEM_TYPE_DATA,
} from "../../types/ad/adDaily/AdDailyListItemType";
import { useAdInfoQuery } from "../../query/ad/ad/useAdInfoQuery";
import { AdBidCalendarCellCommonProps } from "../../components/ad/adBid/components/adBidMainContents/adBidTopContents/components/adCalendar/components/useGetAdBidCalendarCellComponentByCode";
import { getIsTodaySale } from "./staticConvertAdStatusKR";

// type AdBidCalendarCellCommonProps = {
//     cellDate: Date;
//     adDailyList: AdDailyListItemType[];
//     adIdx: string;
// };

export function useGetAdDailyDateData(params: AdBidCalendarCellCommonProps) {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(params.adIdx);

    const adInfo = rawAdData.advertisementInfo;

    const formattedTargetDate = format(params.cellDate, "yyyy-MM-dd");
    const targetDateInfo =
        params.adDailyList.find(
            (data) => data.ad_sales_date === formattedTargetDate,
        ) || DEFAULT_ADVERTISEMENT_DAILY_LIST_ITEM_TYPE_DATA;

    return {
        targetDateInfo: targetDateInfo,
        myOfferInfo: targetDateInfo.myOfferInfo,
        myOfferSuccessInfo: targetDateInfo.myOfferSuccessInfo,
        otherOfferInfo: targetDateInfo.otherOfferInfo,
        otherOfferSuccessInfo: targetDateInfo.otherOfferSuccessInfo,
        highestPriceOfferType: getHighestPriceOfferType(targetDateInfo),
        highestPriceOfferSuccessType:
            getHighestPriceOfferSuccessType(targetDateInfo),
        isAdStop: adInfo.ad_stop_switch_bool,
        isTodaySale: getIsTodaySale(targetDateInfo.ad_sales_daily_status),

        isOfferAvailable: getIsOfferAvailable(targetDateInfo),
        isBuyNowAvailable: getIsBuyNowAvailable(targetDateInfo),
        hasOffer: getHasOffer(targetDateInfo),
        hasOfferSuccess: getHasOfferSuccess(targetDateInfo),
    };
}

export const getIsOfferAvailable = function (data: AdDailyListItemType) {
    return (
        data.ad_daily_status_code === AD_ON_BID &&
        typeof data.myOfferSuccessInfo === "undefined" &&
        typeof data.otherOfferSuccessInfo === "undefined"
    );
};

export const getIsBuyNowAvailable = function (data: AdDailyListItemType) {
    if (
        data.ad_daily_status_code === AD_ON_BID ||
        data.ad_daily_status_code === AD_BID_CLOSED
    ) {
        if (data.myOfferInfo) {
            return data.myOfferInfo.buy_now_available_bool;
        } else if (data.otherOfferInfo) {
            return data.otherOfferInfo.buy_now_available_bool;
        } else if (data.myOfferSuccessInfo || data.otherOfferSuccessInfo) {
            return false;
        } else {
            return true;
        }
    } else if (
        data.ad_daily_status_code === AD_ON_SCHEDULE ||
        data.ad_daily_status_code === AD_IN_REVIEW
    ) {
        return !getHasOfferSuccess(data);
    }
    return false;
};

export const getHasOffer = function (data: AdDailyListItemType) {
    return (
        (typeof data.myOfferInfo !== "undefined" ||
            typeof data.otherOfferInfo !== "undefined") &&
        typeof data.myOfferSuccessInfo === "undefined" &&
        typeof data.otherOfferSuccessInfo === "undefined"
    );
};

export const getHasOfferSuccess = function (data: AdDailyListItemType) {
    return (
        typeof data.otherOfferSuccessInfo !== "undefined" ||
        typeof data.myOfferSuccessInfo !== "undefined"
    );
};

export const getHighestPriceOfferType = function (data: AdDailyListItemType) {
    const myOfferInfo = data.myOfferInfo;
    const otherOfferInfo = data.otherOfferInfo;

    if (
        typeof myOfferInfo === "undefined" &&
        typeof otherOfferInfo === "undefined"
    ) {
        return "no";
    } else {
        const myOfferPrice = data.myOfferInfo?.offer_price || -1;
        const otherOfferPrice = data.otherOfferInfo?.offer_price || -1;

        if (myOfferPrice > otherOfferPrice) {
            return "my";
        } else {
            return "other";
        }
    }
};

export const getHighestPriceOfferSuccessType = function (
    data: AdDailyListItemType,
) {
    const myOfferSuccessInfo = data.myOfferSuccessInfo;
    const otherOfferSuccessInfo = data.otherOfferSuccessInfo;

    if (
        typeof myOfferSuccessInfo === "undefined" &&
        typeof otherOfferSuccessInfo === "undefined"
    ) {
        return "no";
    } else {
        const myOfferSuccessPrice = data.myOfferSuccessInfo?.offer_price || -1;
        const otherOfferSuccessPrice =
            data.otherOfferSuccessInfo?.offer_price || -1;

        if (myOfferSuccessPrice > otherOfferSuccessPrice) {
            return "my";
        } else {
            return "other";
        }
    }
};
