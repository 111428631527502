import { useEffect, useRef } from "react";

export const usePrevState = function (state: any) {
    const ref = useRef<any>(state);
    useEffect(() => {
        ref.current = state;
    }, [state]);

    return {
        prevState: ref.current,
    };
};
