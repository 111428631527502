import { FilterType } from "../../../../../common/commonUiComponent/searchFilter/SearchFilter";
import { WalletCollectionFilters } from "./WalletCollectionItemListConsntants";

export const WalletCollectionSearchFilterExceptionOnChangeHandlers = {
    brandFilterHandler: (isSelected: boolean, tempFilters: FilterType[]) => {
        if (isSelected) {
            return WalletCollectionFilters;
        } else {
            return tempFilters.filter((filter) => filter.key !== "category");
        }
    },

    networkFilterHandler: (
        isSelectedPolygon: boolean,
        isSelectedInApp: boolean,
        tempFilters: FilterType[],
    ) => {
        return tempFilters.map((filter) => {
            if (filter.key === "erc") {
                if (isSelectedInApp && !isSelectedPolygon) {
                    return {
                        ...filter,
                        filterList: WalletCollectionFilters.filter(
                            (originalFilter) => originalFilter.key === "erc",
                        )[0].filterList.filter(
                            (filterListItem) => filterListItem.key === "0",
                        ),
                    };
                } else if (!isSelectedInApp && isSelectedPolygon) {
                    return {
                        ...filter,
                        filterList: WalletCollectionFilters.filter(
                            (originalFilter) => originalFilter.key === "erc",
                        )[0].filterList.filter(
                            (filterListItem) => filterListItem.key !== "0",
                        ),
                    };
                } else {
                    return {
                        ...filter,
                        filterList: WalletCollectionFilters.filter(
                            (originalFilter) => originalFilter.key === "erc",
                        )[0].filterList,
                    };
                }
            }
            return filter;
        });
    },
};
