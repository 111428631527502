import { useQuery } from "react-query";
import { UserApi } from "../../apis/UserApi";

export type UserProfileType = {
    userInfo: UserInfoType;
    titleList: {
        itemList: UserTitleItemType[];
        totalCount: number;
    };
    userInterWorkList: InterworkType[];
    userSNSList: SNSType[];
};

export type UserInfoType = {
    best_collector_nick: string;
    best_creator_nick: string;
    created_item_cnt: number;
    private_item_cnt: string;
    follow_bool: boolean;
    follow_cnt: number;
    following_cnt: number;
    own_item_cnt: number;
    liked_item_cnt: number;
    user_banner_path: string;
    user_description: string;
    user_id: string;
    user_idx: string;
    user_nick: string;
    user_thumbnail_path: string;
    user_influencer_bool: boolean;
    user_nation_cd: number;
    favorite_cnt: number;
};

export type UserTitleItemType = {
    testS: string;
};

export type InterworkType = {
    user_interwork_email: string;
    user_interwork_id: string;
    user_interwork_idx: string;
    user_interwork_nick: number;
    user_interwork_no: number;
    user_interwork_service: string;
    user_interwork_temp1: string;
    user_interwork_thumbnail_path: string;
};

export type SNSType = {
    full_sns_url: string;
    order_no: number;
    user_sns_platform: string;
    user_sns_url: string;
    isMustHave?: boolean; // 프로필 영역, SOOP는 고정으로 넣어주기 위해 줌
};

export type SNSOrderType = {
    order_no: number;
    user_sns_platform: string;
};

export type useGetProfileQueryOptionType = {
    enabled?: boolean;
    refetchOnWindowFocus?: boolean;
};

const InitialUserProfileInfo = {
    userInfo: {
        best_collector_nick: "",
        best_creator_nick: "",
        created_item_cnt: 0,
        private_item_cnt: "",
        follow_bool: false,
        follow_cnt: 0,
        following_cnt: 0,
        own_item_cnt: 0,
        liked_item_cnt: 0,
        user_banner_path: "",
        user_description: "",
        user_id: "",
        user_idx: "0",
        user_nick: "",
        user_thumbnail_path: "",
        user_influencer_bool: false,
        user_nation_cd: 410,
        favorite_cnt: 0,
    },
    titleList: {
        itemList: [],
        totalCount: 0,
    },
    userInterWorkList: [],
    userSNSList: [],
};
export const useGetUserProfileQuery = (
    userIdx: string,
    options: useGetProfileQueryOptionType = {
        enabled: true,
    },
) => {
    const queryResult = useQuery<UserProfileType, Error>({
        queryKey: [`get-user-profile-${userIdx}`],
        queryFn: () => UserApi.getUserProfile(userIdx),
        suspense: false,
        cacheTime: 500,
        staleTime: 500,
        ...options,
        refetchOnWindowFocus: false,
        onSuccess: () => {},
        onError: () => {
            return [];
        },
    });

    return {
        ...queryResult,
        data: queryResult.data || InitialUserProfileInfo,
    };
};
