import { hasPathAfterResolve, useOpenModal } from "../useOpenModal";
import React from "react";
import SearchResultModal from "../../../components/common/modal/searchResultModal/SearchResultModal";
import ModalComponentInfoListAtom from "../../../store/ui/ModalComponentInfoListAtom";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

export const GLOBAL_SEARCH_RESULT_MODAL = "search-result-list";

export function useOpenSearchResultModal() {
    const { openModalWithReturnValue } = useOpenModal();
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const searchResultModalInfo = modalComponentInfoList.find(
        (info) => info.id === GLOBAL_SEARCH_RESULT_MODAL,
    );
    const navigate = useNavigate();

    return {
        openSearchResultModal: async () => {
            return await openModalWithReturnValue(
                GLOBAL_SEARCH_RESULT_MODAL,
                "self-transition",
                (resolve) => {
                    return (
                        <SearchResultModal
                            resolve={(value: any) => {
                                resolve(value);
                            }}
                        />
                    );
                },
                "search-result",
            );
        },
        isOpenSearchResultModal: !!searchResultModalInfo,
    };
}
