import axios from "axios";
import { URL_CONSTANTS } from "../constants/UrlConstants";
import { SignUpParams } from "../query/auth/useSignUpMutation";
import { getAuthKey } from "../store/auth/authKeyStore";
import {
    IdentityVerifyFormMtApiResultType,
    IdentityVerifyFormParams,
} from "../query/auth/useGetIdentityVerifyFormDataMutation";
import { SHA256 } from "crypto-js";

export const AuthApi = {
    login: null,
    logout: async (authKey: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (authKey === "") resolve("no authKey force logout");
            else {
                axios
                    .get(URL_CONSTANTS.API_URL + "/auth/logout", {
                        headers: { Authorization: "Bearer " + authKey },
                    })
                    .then((res: any) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    },
    signUp: async (params: SignUpParams): Promise<any> => {
        return new Promise<any[]>((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/auth/privacy-agree/sign-up",
                    params,
                )
                .then((res: any) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getNiceEncryptFormData: async (
        params: IdentityVerifyFormParams,
    ): Promise<IdentityVerifyFormMtApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${URL_CONSTANTS.API_URL}/auth/identity-verify/form`, {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    checkNiceIdentityVerify: async (niceAuthKey: string): Promise<any> => {
        return new Promise<any[]>((resolve, reject) => {
            axios
                .post(
                    URL_CONSTANTS.API_URL + "/auth/identity-verify/check",
                    {},
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                            "NICE-AUTH-KEY": SHA256(niceAuthKey).toString(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
