import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import NormalRootPageLayout from "../../components/layout/pageLayout/NormalRootPageLayout";
import DoubleSideBarLayout from "../../components/layout/doubleSideBarLayout/DoubleSideBarLayout";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { getPageType, getSelectedChip } from "../../utils/Utils";
import LeftSideBar, {
    SortingItemType,
} from "../../components/common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import MarketProjectLeftSideBarListItem from "../../components/market/marketProject/components/MarketProjectLeftSideBarListItem";
import {
    spreadProjectListPages,
    useProjectListQuery,
} from "../../query/project/useProjectListQuery";
import { getSNBProjectListApiParams } from "../../components/market/marketProject/MarketProjectContents";
import {
    MarketProjectLeftSideBarHeaderFilters,
    MarketProjectLeftSideBarHeaderSortingList,
} from "../../components/market/marketProject/etc/MarketProejctSideBarHeaderMenus";
import { useInitProjectItemHeaderChips } from "../../components/market/marketProject/etc/useInitProjectItemHeaderChips";
import ApiResBoundary from "../../components/common/apiResBoundary/ApiResBoundary";
import MarketItemRightSideBar from "../../components/market/marketItem/components/MarketItemRightSideBar";
import { FilterType } from "../../components/common/commonUiComponent/searchFilter/SearchFilter";

export const MarketProjectLeftSideBarLayoutContainer = styled.div``;

type Props = {};

const MarketProjectLeftSideBarLayout = (props: Props): ReactElement | null => {
    const leftSideBarTitle = "프로젝트 리스트";
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const projectId = params.project_id ? params.project_id : "1";
    const pageType = getPageType(location.pathname);
    const isProjectPage = pageType === "project";

    const [headerSortingList, setHeaderSortingList] = useState<
        SortingItemType[]
    >(MarketProjectLeftSideBarHeaderSortingList);

    const [headerFilters, setHeaderFilters] = useState<FilterType[]>(
        MarketProjectLeftSideBarHeaderFilters,
    );

    // chips init
    // login 유무 chips 세팅
    // chips 로 인한 filter 변경 effect
    // 프로젝트 추가/수정 으로 리스트 들어온 경우 my set effect
    const { headerChips, setHeaderChips } =
        useInitProjectItemHeaderChips(setHeaderFilters);

    const {
        data: rawProjectList,
        isSuccess: isSuccessProjectList,
        // isLoading: isProjectListLoading,
        isError: isProjectListError,
        error: errorProjectList,
    } = useProjectListQuery(
        getSNBProjectListApiParams(
            headerSortingList,
            headerFilters,
            headerChips,
        ),
    );
    const projectList = spreadProjectListPages(rawProjectList.pages);

    useEffect(() => {
        if (projectId === "root") {
            if (isSuccessProjectList) {
                if (projectList.length !== 0) {
                    navigate("/market/" + projectList[0].project_idx, {
                        replace: true,
                    });
                } else {
                    navigate("/market/1", {
                        replace: true,
                    });
                }
            } else if (isProjectListError) {
                navigate("/market/1", {
                    replace: true,
                });
            }
        }
    }, [
        rawProjectList,
        isSuccessProjectList,
        isProjectListError,
        location.pathname,
    ]);

    return (
        <NormalRootPageLayout
            fallbackComponent={<div></div>}
            hasFooter={false}
            isMobileTopnavShow={true}
            hasHeaderPadding={true}
            hasSubHeaderPadding={false} // 노출되지만 노패딩
        >
            <ApiResBoundary
                isLoading={false}
                isNoData={false}
                error={errorProjectList}
                isLoadingSpinnerShow={false}
                errorFallback={(err) => {
                    throw new Error(err);
                }}
            >
                <>
                    {projectId !== "root" && (
                        <MarketProjectLeftSideBarLayoutContainer>
                            <DoubleSideBarLayout
                                sideBarTitle={leftSideBarTitle}
                                type={
                                    isProjectPage
                                        ? "main-normal-only-left"
                                        : "main-normal"
                                }
                                renderLeftSideBarComponent={() => (
                                    <LeftSideBar
                                        title={leftSideBarTitle}
                                        renderLeftSideBarListItem={(data) => (
                                            <MarketProjectLeftSideBarListItem
                                                isRecent={
                                                    getSelectedChip(
                                                        headerChips,
                                                    ) === "recent"
                                                }
                                                data={data.data}
                                            />
                                        )}
                                        headerChips={headerChips}
                                        setHeaderChips={setHeaderChips}
                                        headerSortingList={headerSortingList}
                                        setHeaderSortingList={
                                            setHeaderSortingList
                                        }
                                        headerFilters={headerFilters}
                                        setHeaderFilters={setHeaderFilters}
                                        getLeftSideBarListQuery={
                                            useProjectListQuery
                                        }
                                        leftSideBarListQueryParam={getSNBProjectListApiParams(
                                            headerSortingList,
                                            headerFilters,
                                            headerChips,
                                        )}
                                        recentListKey={"recentProjectList"}
                                    />
                                )}
                                renderMainNormalContentsComponent={() => (
                                    <Outlet />
                                )}
                                renderRightSideBarComponent={
                                    isProjectPage
                                        ? undefined
                                        : () => <MarketItemRightSideBar />
                                }
                                isRenderMobileBottomFixedLeftSideBar={true}
                            />
                        </MarketProjectLeftSideBarLayoutContainer>
                    )}
                </>
            </ApiResBoundary>
        </NormalRootPageLayout>
    );
};

export default MarketProjectLeftSideBarLayout;
