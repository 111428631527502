import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import deferredPromptAtom from "../../../../store/commonState/deferredPromptAtom";
import { sendLog } from "../../../../utils/Utils";
import { useSetAtom } from "jotai";

export const A2HsCheckerContainer = styled.div``;

type Props = {};

const A2HsChecker = (props: Props): ReactElement | null => {
    const setDeferredPrompt = useSetAtom(deferredPromptAtom);
    useEffect(() => {
        const handler = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };
        const appInstalledHandler = (e: any) => {
            setDeferredPrompt(null);
            sendLog({ event: "pwaInstalled" });
        };
        // beforeinstallprompt 에 이벤트 핸들러를 등록합니다.
        window.addEventListener("beforeinstallprompt", handler);
        window.addEventListener("appinstalled", appInstalledHandler);

        return () => {
            window.removeEventListener("beforeinstallprompt", handler);
            window.removeEventListener("appinstalled", appInstalledHandler);
        };
    }, []);

    return null;
};

export default A2HsChecker;
