import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../../common/modal/modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalButtonContainer,
    ModalTitle,
} from "../../common/commonUnitComponent/modal/ModalStyle";

export const AdHistoryAddBlacklistModalContainer = styled.div`
    width: 420px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;
export const AdHistoryAddBlacklistModalSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
`;

export const AdHistoryAddBlacklistModalSection = styled.div``;

export const AdHistoryAddBlacklistModalSectionTitle = styled.div`
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
`;

export const AdHistoryAddBlacklistModalSectionBody = styled.div`
    margin-top: 12px;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
`;

export const AdHistoryAddBlacklistModalUserId = styled.div<{
    isDuplicated: boolean;
}>`
    display: flex;
    padding: 16px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #f5f5f7;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    ${({ isDuplicated }) => {
        if (isDuplicated) {
            return `
                border-radius: 8px;
                border: 1px solid #FF4671;
                background: #F5F5F7;
                `;
        }
    }};
`;

export const AdHistoryAddBlacklistModalUserIdMsg = styled.div`
    color: #ff4671;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-top: 8px;
    margin-left: 8px;
`;

export const AdHistoryAddBlacklistModalMemoTextarea = styled.textarea`
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: #f5f5f7;
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    font-family: Noto Sans KR, serif;
    border: none;
    resize: none;
    width: calc(100% - 20px);

    :focus {
        outline: none;
    }
`;

export const AdHistoryAddBlacklistModalButtonContainer = styled.div`
    margin-top: 40px;
`;
type Props = {
    resolve: (value: boolean) => void;
};

const MyAdBlackListAddModal = (props: Props): ReactElement | null => {
    const isDuplicated = true;
    const [inputMemo, setInputMemo] = useState("");
    return (
        <AdHistoryAddBlacklistModalContainer>
            <ModalCloseIcon resolve={props.resolve} />
            <ModalTitle>블랙 리스트 추가</ModalTitle>
            <AdHistoryAddBlacklistModalSectionContainer>
                <AdHistoryAddBlacklistModalSection>
                    <AdHistoryAddBlacklistModalSectionTitle>
                        대상 유저
                    </AdHistoryAddBlacklistModalSectionTitle>
                    <AdHistoryAddBlacklistModalSectionBody>
                        <AdHistoryAddBlacklistModalUserId
                            isDuplicated={isDuplicated}
                        >
                            test
                        </AdHistoryAddBlacklistModalUserId>
                        {isDuplicated && (
                            <AdHistoryAddBlacklistModalUserIdMsg>
                                이미 등록된 아이디입니다.
                            </AdHistoryAddBlacklistModalUserIdMsg>
                        )}
                    </AdHistoryAddBlacklistModalSectionBody>
                </AdHistoryAddBlacklistModalSection>
                <AdHistoryAddBlacklistModalSection>
                    <AdHistoryAddBlacklistModalSectionTitle>
                        메모
                    </AdHistoryAddBlacklistModalSectionTitle>
                    <AdHistoryAddBlacklistModalSectionBody>
                        <AdHistoryAddBlacklistModalMemoTextarea
                            value={inputMemo}
                            onChange={(e) => {
                                setInputMemo(e.target.value);
                            }}
                        />
                    </AdHistoryAddBlacklistModalSectionBody>
                </AdHistoryAddBlacklistModalSection>
                <AdHistoryAddBlacklistModalSection>
                    <AdHistoryAddBlacklistModalSectionTitle>
                        유의사항
                    </AdHistoryAddBlacklistModalSectionTitle>
                    <AdHistoryAddBlacklistModalSectionBody>
                        블랙리스트에 추가된 대상의 입찰 및 낙찰건은 모두 ‘취소’
                        처리되 며, 해당 건은 복구가 불가능 합니다.
                    </AdHistoryAddBlacklistModalSectionBody>
                </AdHistoryAddBlacklistModalSection>
            </AdHistoryAddBlacklistModalSectionContainer>
            <AdHistoryAddBlacklistModalButtonContainer>
                <ModalButtonContainer>
                    <ModalButton
                        btnType={"filled-secondary"}
                        onClick={() => {
                            props.resolve(false);
                        }}
                    >
                        취소
                    </ModalButton>
                    <ModalButton
                        btnType={
                            !isDuplicated
                                ? "filled-primary"
                                : "filled-primary-disabled"
                        }
                        onClick={() => {
                            if (!isDuplicated) {
                                alert("준비중 입니다.");
                                props.resolve(true);
                            }
                        }}
                    >
                        추가
                    </ModalButton>
                </ModalButtonContainer>
            </AdHistoryAddBlacklistModalButtonContainer>
        </AdHistoryAddBlacklistModalContainer>
    );
};

export default MyAdBlackListAddModal;
