import { ReactElement } from "react";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import {
    AdRegisterAdjustHeaderTitle,
    AdRegisterAdjustHeaderTitleDesc,
} from "../AdRegisterContents";
import ToggleSwitch from "../../../common/commonUnitComponent/toggleSwitch/ToggleSwitch";
import {
    AdRegisterAdjustAutoAcceptSwitchContainer,
    AdRegisterAdjustCommonSwitchTitleContainer,
} from "./AdRegisterAdjustAutoAcceptSwitch";

type Props = {
    isAdBuyNowAvailable: boolean;
    setIsAdBuyNowAvailable: (value: boolean) => void;
};

const AdRegisterAdjustBuyNowAvailableSwitch = (
    props: Props,
): ReactElement | null => {
    return (
        <WhiteBox>
            <AdRegisterAdjustAutoAcceptSwitchContainer>
                <AdRegisterAdjustCommonSwitchTitleContainer>
                    <AdRegisterAdjustHeaderTitle>
                        게재 당일 즉시 구매 설정
                    </AdRegisterAdjustHeaderTitle>
                    <ToggleSwitch
                        id={"ad-buy-now-toggle-switch"}
                        isChecked={props.isAdBuyNowAvailable}
                        onChangeFunction={() => {
                            props.setIsAdBuyNowAvailable(
                                !props.isAdBuyNowAvailable,
                            );
                        }}
                        isDisabled={false}
                    />
                </AdRegisterAdjustCommonSwitchTitleContainer>

                <AdRegisterAdjustHeaderTitleDesc>
                    광고 게재기간동안 게재되고 있는 광고가 없을 시 구매자가 즉시
                    구매해 광고를 바로 게재할 수 있습니다. (즉시 구매가는 판매자
                    지정가로 책정됩니다.)
                </AdRegisterAdjustHeaderTitleDesc>
            </AdRegisterAdjustAutoAcceptSwitchContainer>
        </WhiteBox>
    );
};

export default AdRegisterAdjustBuyNowAvailableSwitch;
