import { atomWithReset } from "jotai/utils";

export type HelmetInfoAtomType = {
    adName: string;
    projectName: string;
    itemName: string;
    pointName: string;
    userNickname: string;
};

const HelmetInfoAtom = atomWithReset<HelmetInfoAtomType>({
    adName: "",
    projectName: "",
    itemName: "",
    pointName: "",
    userNickname: "",
});

export default HelmetInfoAtom;
