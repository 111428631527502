import { ReactElement } from "react";
import styled from "styled-components";
import {
    TutorialInfoListType,
    TutorialSubCategoryInfoType,
} from "../../TutorialModal";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../commonStyle/CommonButtonStyle";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { LinkInTerm } from "../../../../commonUnitComponent/terms/Terms";
import { Link, useNavigate } from "react-router-dom";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";
import { useOpenAdManageUrlModal } from "../../../../../../hooks/modal/openModal/useOpenAdManageUrlModal";

export const TutorialContentsContainer = styled.div`
    width: 440px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
        width: 100%;
    }
`;

export const TutorialContentsMobileHeader = styled.div`
    display: flex;
    align-items: center;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
`;

export const TutorialContentsImg = styled.img`
    width: 100%;
    height: auto;
    align-self: stretch;
    border-radius: 16px;

    @media ${(props) => props.theme.mobileL} {
        max-height: 300px;
    }
`;

export const TutorialContentsInfoContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${(props) => props.theme.mobileL} {
        align-items: center;
    }
`;

export const TutorialContentsInfoTitle = styled.div`
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 28px */
`;

export const TutorialContentsInfoDesc = styled.div`
    margin-top: 10px;
    color: #333;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 24px */
`;

export const TutorialContentsInfoDescAnnotation = styled.div`
    margin-top: 4px;
    color: #777;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
`;

export const TutorialContentsInfoBtnContainer = styled.div`
    margin-top: 32px;
    display: flex;
    gap: 10px;

    @media ${(props) => props.theme.mobileL} {
        margin-bottom: 24px;
    }
`;

export const TutorialContentsInfoBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    width: auto;
    height: 40px;
    padding: 0 16px;
    font-size: 15px;
`;

type Props = {
    tutorialInfoList: TutorialInfoListType[];
    setTutorialInfoList: (value: TutorialInfoListType[]) => void;
    setIsSelectOnMobile: (value: boolean) => void;
    resolve: (value: boolean | PathAfterResolveType) => void;
};

const TutorialContents = (props: Props): ReactElement | null => {
    const selectedInfo =
        props.tutorialInfoList
            .find((upperCategory) => {
                return upperCategory.subCategoryList.find(
                    (subCategory) => subCategory.isSelected,
                );
            })
            ?.subCategoryList.find((subCategory) => subCategory.isSelected) ||
        props.tutorialInfoList[0].subCategoryList[0];

    return (
        <TutorialContentsContainer>
            <TutorialContentsImg src={selectedInfo.imgSrc} />
            <TutorialContentsInfoContainer>
                <TutorialContentsInfoTitle>
                    {selectedInfo.title}
                </TutorialContentsInfoTitle>
                <TutorialContentsInfoDesc>
                    {selectedInfo.desc}
                </TutorialContentsInfoDesc>
                {selectedInfo.descAnnotation && (
                    <TutorialContentsInfoDescAnnotation>
                        {selectedInfo.descAnnotation}
                    </TutorialContentsInfoDescAnnotation>
                )}
                <TutorialContentsInfoBtnContainer>
                    {selectedInfo.actionLink && (
                        <TutorialContentsInfoBtn
                            btnType={"filled-primary"}
                            onClick={() => {
                                if (selectedInfo.actionLink?.btnLink !== "") {
                                    if (
                                        selectedInfo.actionLink?.btnLink.indexOf(
                                            "https://",
                                        ) !== -1
                                    ) {
                                        window.open(
                                            selectedInfo.actionLink?.btnLink,
                                        );
                                    } else {
                                        props.resolve({
                                            pathAfterResolve:
                                                selectedInfo.actionLink
                                                    ?.btnLink,
                                            ...(selectedInfo.actionLink
                                                ?.btnLinkState && {
                                                state: selectedInfo.actionLink
                                                    ?.btnLinkState,
                                            }),
                                        });
                                    }
                                } else {
                                    let upperCategoryKey = "";
                                    let subCategoryKey = "";
                                    switch (selectedInfo.key) {
                                        case "saybuzz-ad":
                                            upperCategoryKey = "ad";
                                            subCategoryKey = "ad-sell";
                                            break;
                                        case "saybuzz-point":
                                            upperCategoryKey = "point";
                                            subCategoryKey = "point-rp";
                                            break;
                                        case "saybuzz-item":
                                            upperCategoryKey = "item";
                                            subCategoryKey = "item-make";
                                            break;
                                    }

                                    props.setTutorialInfoList(
                                        props.tutorialInfoList.map(
                                            (upperCategory) => {
                                                return {
                                                    ...upperCategory,
                                                    isOpen:
                                                        upperCategory.upperCategoryKey ===
                                                        upperCategoryKey,
                                                    subCategoryList:
                                                        upperCategory.subCategoryList.map(
                                                            (subCategory) => {
                                                                return {
                                                                    ...subCategory,
                                                                    isSelected:
                                                                        subCategory.key ===
                                                                        subCategoryKey,
                                                                };
                                                            },
                                                        ),
                                                };
                                            },
                                        ),
                                    );
                                }
                            }}
                        >
                            {selectedInfo.actionLink?.btnTitle}
                        </TutorialContentsInfoBtn>
                    )}
                    {selectedInfo.guideLink !== "" && (
                        <Link
                            to={selectedInfo.guideLink || ""}
                            target={"_blank"}
                        >
                            <TutorialContentsInfoBtn
                                btnType={"filled-secondary"}
                            >
                                상세 가이드
                            </TutorialContentsInfoBtn>
                        </Link>
                    )}
                </TutorialContentsInfoBtnContainer>
            </TutorialContentsInfoContainer>
        </TutorialContentsContainer>
    );
};

export default TutorialContents;
