import { useInfiniteQuery } from "react-query";
import { MyMarketItemApi } from "../../../apis/myApi/MyMarketItemApi";

export type MyItemOrderListApiResultType = {
    orderList: MyItemOrderListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type MyItemOrderListItemType = {
    created_at: string;
    currencyInfo: {
        brand_id: string;
        brand_idx: string;
        created_at: string;
        like_cnt: number;
        point_description: string;
        point_idx: string;
        point_name: string;
        point_symbol: string;
        point_type: string;
        point_type_description: string;
        updated_at: string;
    };
    brandInfo: {
        brand_id: string;
        brand_name: string;
        brand_thumbnail_path: string;
    };
    currency_idx: string;
    infinite_bool: boolean;
    itemInfo: {
        brand_id: string;
        brand_idx: string;
        brand_name: string;
        category_no: string;
        created_at: string;
        created_idx: string;
        item_count: number;
        item_description: string;
        item_idx: string;
        item_name: string;
        item_origin_path: string;
        item_status: string;
        item_thumbnail_path: string;
        last_transaction_price: number;
        like_cnt: number;
        low_price: number;
        origin_file_type: string;
        project_idx: string;
        reject_message: string;
        token_id: number;
        txid: string;
        updated_at: string;
        project_name: string;
    };
    latest_settlement_at: string;
    order: MyPointItemOrderType;
    order_sub_idx: string;
    order_sub_quantity: number;
    order_sub_type: string;
    order_sub_unit_price: number;
    order_valid_until: string;
    product_idx: string;
    product_type: string;
    settled_quantity_sum: number;
    updated_at: string;
    user_idx: string;
};

export type MyPointItemOrderType = {
    created_at: string;
    order_idx: string;
    order_no: string;
    order_status: string;
    order_type: string;
    ordered_at: string;
    updated_at: string;
    unit_creator_royalty: number;
    unit_brand_royalty: number;
    cancelled_at: string;
};

export type MyItemOrderListApiParams = {
    limit: number;
    page_no: number;
    ordered_at_from?: string;
    ordered_at_to?: string;
    brand_id?: string;
    my_order_status?: string;
    order_no?: string;
    item_idx?: string;
    order_idx?: string;
};

export const useGetMyItemOrderListQuery = (
    params: MyItemOrderListApiParams,
) => {
    const queryResult = useInfiniteQuery<MyItemOrderListApiResultType, Error>({
        queryKey: [
            "my-item-order-list-" + JSON.stringify(params),
            "item-info-refresh-target-" + (params.item_idx || "history"),
        ],
        queryFn: ({ pageParam = 1 }) =>
            MyMarketItemApi.getMyItemOrderList({
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        cacheTime: 0,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: MyItemOrderListApiResultType[] = [
        {
            orderList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadMyItemOrderListPages(
    pages: MyItemOrderListApiResultType[],
) {
    return pages
        .map((result) => result.orderList)
        .reduce((prev, next) => prev.concat(next));
}
