import React, { ReactElement } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../../../../modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalSmallDesc,
    ModalTitle,
} from "../../../../../commonUnitComponent/modal/ModalStyle";

export const NotiListAllDeleteModalContainer = styled.div``;

type Props = {
    resolve: (value: boolean) => void;
};

const NotiListAllDeleteModal = (props: Props): ReactElement | null => {
    return (
        <ModalContainer isNoPadding={false}>
            <ModalCloseIcon resolve={props.resolve} />
            <ModalTitle>알림을 모두 삭제하시겠습니까?</ModalTitle>
            <ModalSmallDesc>삭제한 알림은 복구할 수 없습니다.</ModalSmallDesc>
            <ModalButtonContainer>
                <ModalButton
                    id={"noti-all-delete-cancel-btn"}
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </ModalButton>
                <ModalButton
                    id={"noti-all-delete-btn"}
                    btnType={"filled-danger"}
                    onClick={() => {
                        props.resolve(true);
                    }}
                >
                    삭제
                </ModalButton>
            </ModalButtonContainer>
        </ModalContainer>
    );
};

export default NotiListAllDeleteModal;
