import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalTitle,
} from "../../../../../../common/commonUnitComponent/modal/ModalStyle";
import CommonInputText, {
    firstEndBlankCheckValidation,
    initialInputTextData,
    specialCharCheckValidationIncludeUnderBar,
} from "../../../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import ModalCloseIcon from "../../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import { useAddItemInventoryGroupMutation } from "../../../../../../../query/itemInventory/useAddItemInventoryGroupMutation";

export const WalletCollectionGroupAddModalWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const WalletCollectionGroupAddModalContainer = styled.div`
    margin-top: 24px;
    width: 100%;
`;

export const WalletCollectionGroupAddModalDescContainer = styled.div`
    width: 100%;
    align-items: flex-start;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const WalletCollectionGroupAddModalDesc = styled.div`
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
`;

type Props = {
    resolve: (value: boolean) => void;
};

const WalletCollectionGroupAddModal = (props: Props): ReactElement | null => {
    const [inputText, setInputText] = useState(initialInputTextData);
    const descList = [
        "공백포함 최대 12자까지 작성가능",
        "‘-’, ‘_’ 외 특수문자 불가",
        "그룹은 최대 20개까지 생성이 가능합니다.",
    ];
    const [isPending, setIsPending] = useState(false);
    const { mutateAsync: addGroup, isLoading: isAddGroupLoading } =
        useAddItemInventoryGroupMutation({
            item_inventory_group_name: inputText.value,
        });

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <ModalContainer isNoPadding={false}>
            <WalletCollectionGroupAddModalWrapper>
                <ModalCloseIcon resolve={props.resolve} isMobileHide={false} />
                <ModalTitle>그룹 추가</ModalTitle>
                <WalletCollectionGroupAddModalContainer>
                    <CommonInputText
                        ref={inputRef}
                        inputText={inputText}
                        setInputText={setInputText}
                        placeholder={"그룹 이름을 입력해주세요."}
                        minLength={0}
                        maxLength={12}
                        checkAdditionalValidation={() => {
                            return (
                                firstEndBlankCheckValidation(
                                    inputText,
                                    setInputText,
                                ) &&
                                specialCharCheckValidationIncludeUnderBar(
                                    inputText,
                                    setInputText,
                                )
                            );
                        }}
                    />
                    <WalletCollectionGroupAddModalDescContainer>
                        {descList.map((desc, idx) => {
                            return (
                                <WalletCollectionGroupAddModalDesc key={idx}>
                                    · {desc}
                                </WalletCollectionGroupAddModalDesc>
                            );
                        })}
                    </WalletCollectionGroupAddModalDescContainer>
                </WalletCollectionGroupAddModalContainer>
                <ModalButtonContainer>
                    <ModalButton
                        btnType={"filled-secondary"}
                        onClick={() => {
                            props.resolve(false);
                        }}
                    >
                        취소
                    </ModalButton>
                    <ModalButton
                        btnType={
                            inputText.value === "" || inputText.isWarning
                                ? "filled-primary-disabled"
                                : !isPending && !isAddGroupLoading
                                ? "filled-primary"
                                : "filled-primary-pending"
                        }
                        onClick={async () => {
                            if (
                                !isPending &&
                                !isAddGroupLoading &&
                                !inputText.isWarning &&
                                inputText.value !== ""
                            ) {
                                try {
                                    setIsPending(true);
                                    await addGroup();
                                    props.resolve(true);
                                } catch (e) {
                                    console.info(e);
                                    // 실패시에만 pending false // 열린 모달 안 닫기
                                    setIsPending(false);
                                }
                            }
                        }}
                    >
                        추가하기
                    </ModalButton>
                </ModalButtonContainer>
            </WalletCollectionGroupAddModalWrapper>
        </ModalContainer>
    );
};

export default WalletCollectionGroupAddModal;
