import React, { ReactElement } from "react";
import styled from "styled-components";
import ThreeDotButton, {
    ThreeDotConfigType,
} from "../../../../../common/commonUnitComponent/threeDotButton/ThreeDotButton";
import { ItemListItemType } from "../../../../../../query/item/useItemListQuery";
import { TitleItemListItemType } from "../../../../../../query/item/titleItem/useTitleItemListQuery";
import { useItemTitleRegisterDelete } from "../../../../../../hooks/item/useItemTitleRegisterDelete";
import { useItemHideUnHide } from "../../../../../../hooks/item/useItemHideUnHide";
import { queryClient } from "../../../../../../App";
import { useAtom } from "jotai";
import CollectionCommonStateAtom from "../../../../../../store/commonState/CollectionCommonStateAtom";
import { useNavigate } from "react-router-dom";
import { useDeleteItemMutation } from "../../../../../../query/item/useDeleteItemMutation";
import { useCancelExamineItemMutation } from "../../../../../../query/item/examine/useCancelExamineItemMutation";
import { useItemTransferModal } from "../../../../../../hooks/item/useItemTransferModal";
import { useOpenCommonConfirmModal } from "../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { useItemUseModal } from "../../../../../../hooks/item/useItemUseModal";
import { useOpenCollectionGroupMoveModal } from "../../../../../../hooks/modal/openModal/useOpenCollectionGroupMoveModal";

export const WalletCollectionItemInfoThreeDotContainer = styled.div<{
    isPositionStatic?: boolean;
}>`
    ${({ isPositionStatic }) => {
        if (isPositionStatic) {
            return ``;
        } else {
            return `
                    position: absolute;
                    z-index: 1;
                    top: -6px;
                    right: -10px;
            `;
        }
    }};
    @media ${(props) => props.theme.mobileL} {
        ${({ isPositionStatic }) => {
            if (isPositionStatic) {
                return ``;
            } else {
                return `
                    top: -10px;
                    right: 0;
            `;
            }
        }}
    }
`;

type Props = {
    userIdx: string;
    item: ItemListItemType | TitleItemListItemType | any; // 앞에 두개인데 value 가 달라서 any 로 열어놓긴한다
    refetchItemListTitleItemListQuery: () => Promise<void>;
    type: string;
    isPositionStatic?: boolean;
};

const WalletCollectionItemThreeDot = (props: Props): ReactElement | null => {
    const navigate = useNavigate();
    const [collectionCommonState] = useAtom(CollectionCommonStateAtom);
    const isNormalType = props.type !== "representative";
    const { itemTitleRegisterDelete } = useItemTitleRegisterDelete(
        props.item.item_idx,
        props.item.title_bool || !isNormalType,
        async () => {
            await props.refetchItemListTitleItemListQuery();
        },
    );

    const { hideUnHide } = useItemHideUnHide(
        props.item.item_idx,
        props.item.private_bool,
        async () => {
            await queryClient.refetchQueries({
                queryKey: collectionCommonState.collectionItemListQueryKey,
                refetchPage: () => true,
            });
            await queryClient.refetchQueries({
                queryKey: `get-user-profile-${props.userIdx}`,
                refetchPage: () => true,
            });
        },
    );
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { openItemTransferModal } = useItemTransferModal(props.item);
    const { openItemUseModal } = useItemUseModal(props.item);
    const { openCollectionGroupMoveModal } = useOpenCollectionGroupMoveModal();

    const { mutateAsync: deleteItem } = useDeleteItemMutation(
        props.item.item_idx,
    );

    const { mutateAsync: cancelExamineItem } = useCancelExamineItemMutation(
        props.item.item_idx,
    );

    const ownedMoreThreeDotConfig = [
        {
            title:
                isNormalType && !props.item.title_bool
                    ? "대표 아이템 등록"
                    : "대표 아이템 해제",
            clickHandler: async () => {
                await itemTitleRegisterDelete();
            },
            isGoOutIcon: false,
            isShow: (isNormalType && !props.item.private_bool) || !isNormalType,
        },
        {
            title:
                isNormalType && props.item.private_bool
                    ? "비공개 해제"
                    : "비공개로 설정",
            clickHandler: async () => {
                if (isNormalType) {
                    await hideUnHide();
                }
            },
            isGoOutIcon: false,
            isShow: !props.item.title_bool,
        },
        {
            title: "아이템 전송",
            clickHandler: async () => {
                if (await openItemTransferModal()) {
                    await props.refetchItemListTitleItemListQuery();
                }
            },
            isGoOutIcon: false,
            isShow: props.item.inventory_type === "2",
        },
        {
            title: "아이템 사용",
            clickHandler: async () => {
                if (await openItemUseModal()) {
                    await props.refetchItemListTitleItemListQuery();
                }
            },
            isGoOutIcon: false,
            isShow: !props.item.title_bool && props.item.inventory_type === "1",
        },
        {
            title: "그룹으로 이동",
            clickHandler: async () => {
                await openCollectionGroupMoveModal(
                    props.userIdx,
                    props.item.item_idx,
                );
            },
            isGoOutIcon: false,
            isShow: true,
        },
    ];

    const createdMoreThreeDotConfig = [
        {
            title: "아이템 출시",
            clickHandler: async () => {
                navigate(
                    `/market/examine-item/${props.item.project_idx}/${props.item.item_idx}`,
                );
            },
            isGoOutIcon: false,
            isShow: props.item.item_status === "3",
        },
        {
            title: "아이템 수정",
            clickHandler: async () => {
                navigate(
                    `/market/adjust-item/${props.item.project_idx}/${props.item.item_idx}`,
                );
            },
            isGoOutIcon: false,
            isShow: props.item.item_status === "3", // 출시대기
        },
        {
            title: "출시 신청 취소",
            clickHandler: async () => {
                await cancelExamineItem();
            },
            isGoOutIcon: false,
            isShow: props.item.item_status === "4", // 심사중
        },
        {
            title: "출시 재신청",
            clickHandler: async () => {
                navigate(
                    `/market/adjust-item/${props.item.project_idx}/${props.item.item_idx}`,
                );
            },
            isGoOutIcon: false,
            isShow: props.item.item_status === "5", // 반려
        },
        {
            title: "아이템 삭제",
            clickHandler: async () => {
                const confirmResult = await openCommonConfirmModal({
                    title: "등록 아이템 삭제",
                    desc: "등록한 아이템을 삭제합니다. 삭제된 아이템은 복구 할 수 없습니다.",
                });
                if (confirmResult) {
                    await deleteItem();
                }
            },
            isGoOutIcon: false,
            isShow:
                props.item.item_status === "3" || // 출시 대기
                props.item.item_status === "5", // 반려
        },
    ];

    const privateMoreThreeDotConfig = [
        {
            title:
                isNormalType && props.item.private_bool
                    ? "비공개 해제"
                    : "비공개로 설정",
            clickHandler: async () => {
                if (isNormalType) {
                    await hideUnHide();
                }
            },
            isGoOutIcon: false,
            isShow: !props.item.title_bool,
        },
        {
            title: "아이템 전송",
            clickHandler: async () => {
                if (await openItemTransferModal()) {
                    await props.refetchItemListTitleItemListQuery();
                }
            },
            isGoOutIcon: false,
            isShow: props.item.inventory_type === "2",
        },
        {
            title: "아이템 사용",
            clickHandler: async () => {
                if (await openItemUseModal()) {
                    await props.refetchItemListTitleItemListQuery();
                }
            },
            isGoOutIcon: false,
            isShow: props.item.inventory_type === "1",
        },
    ];

    const moreThreeDotConfig: {
        [key: string]: any;
        owned: ThreeDotConfigType[];
        liked: ThreeDotConfigType[];
        created: ThreeDotConfigType[];
        representative: ThreeDotConfigType[];
    } = {
        owned: ownedMoreThreeDotConfig,
        liked: [],
        created: createdMoreThreeDotConfig,
        representative: [
            {
                title: "대표 아이템 해제",
                clickHandler: async () => {
                    await itemTitleRegisterDelete();
                },
                isGoOutIcon: false,
                isShow: true,
            },
            {
                title: "아이템 전송",
                clickHandler: async () => {
                    if (await openItemTransferModal()) {
                        await props.refetchItemListTitleItemListQuery();
                    }
                },
                isGoOutIcon: false,
                isShow:
                    !props.item.title_bool && props.item.inventory_type === "2",
            },
        ],
        private: privateMoreThreeDotConfig,
    };

    return (
        <WalletCollectionItemInfoThreeDotContainer
            isPositionStatic={props.isPositionStatic}
        >
            {moreThreeDotConfig[props.type].filter(
                (config: ThreeDotConfigType) => config.isShow,
            ).length !== 0 && (
                <ThreeDotButton
                    config={moreThreeDotConfig[props.type]}
                    hasBorder={false}
                />
            )}
        </WalletCollectionItemInfoThreeDotContainer>
    );
};

export default WalletCollectionItemThreeDot;
