import { ReactElement, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { numberToStringWithComma } from "../../../../../../../../../utils/Utils";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../../../../../common/commonUnitComponent/commonTable/CommonTable";
import {
    CommonTC,
    CommonTCBold,
    CommonTCContainer,
    CommonTCSmall,
} from "../../../../../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import { format } from "date-fns";
import { useDeviceWidth } from "../../../../../../../../../hooks/ui/useDeviceWidth";
import { useParams } from "react-router-dom";
import ApiResBoundary from "../../../../../../../../common/apiResBoundary/ApiResBoundary";
import {
    MarketSettlementListItemType,
    spreadSettlementListPages,
    useGetMarketSettlementListQuery,
} from "../../../../../../../../../query/market/common/useGetMarketSettlementListQuery";
import CommonTabBox, {
    CommonTabBoxLoadingContainerHasPB,
} from "../../../../../../../../common/commonUnitComponent/commonTabBox/CommonTabBox";
import { getIsTradeAvailableItem } from "../../../../../../../../wallet/walletCollection/components/walletCollectionItemListItem/WalletCollectionItem";
import { useItemDataQuery } from "../../../../../../../../../query/item/useItemDataQuery";
import Nickname from "../../../../../../../../common/commonUnitComponent/nickname/Nickname";
import Spinner from "../../../../../../../../common/commonUnitComponent/spinner/Spinner";

const ConclusionDateColumnWidth = css`
    width: 68px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;
const ConclusionSellerColumnWidth = css`
    width: 80px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 2;
    }
`;

const ConclusionCountColumnWidth = css`
    width: 40px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 2;
    }
`;

const ConclusionPriceColumnWidth = css`
    width: 84px;

    @media ${(props) => props.theme.mobileL} {
        flex-shrink: 0;
    }
`;

export const MarketItemConclusionDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${ConclusionDateColumnWidth};
`;

export const MarketItemConclusionSellerColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    white-space: nowrap;
    ${ConclusionSellerColumnWidth};
`;

export const MarketItemConclusionCountColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${ConclusionCountColumnWidth};
`;

export const MarketItemConclusionPriceColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${ConclusionPriceColumnWidth};
`;

export const MarketItemRealtimeConclusionBoxContainer = styled.div<{
    inSubTab: boolean;
}>`
    display: block;

    @media ${(props) => props.theme.PCM} {
        display: ${({ inSubTab }) => (inSubTab ? "block" : "none")};
        margin-top: 0;
    }
`;

type Props = {
    inSubTab: boolean;
};

const MarketItemRealtimeConclusionBox = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";
    const { data: rawItemData } = useItemDataQuery(itemIdx);
    const {
        data: rawMarketItemSettlementList,
        isLoading: isMarketItemSettlementListLoading,
        error: errorMarketItemSettlementList,
        hasNextPage: _hasMarketItemSettlementListNextPage,
        fetchNextPage: fetchMarketItemSettlementListNextPage,
    } = useGetMarketSettlementListQuery("item", itemIdx, {
        limit: 20,
        page_no: 1,
    });

    const hasMarketItemSettlementListNextPageRef = useRef(
        _hasMarketItemSettlementListNextPage,
    );
    useEffect(() => {
        hasMarketItemSettlementListNextPageRef.current =
            _hasMarketItemSettlementListNextPage;
    }, [_hasMarketItemSettlementListNextPage]);

    const marketItemSettlementList = spreadSettlementListPages(
        rawMarketItemSettlementList.pages,
    );

    const conclusionTableHeaderData: TableHeaderDataType[] = [
        {
            key: "dateColumn",
            component: (
                <MarketItemConclusionDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    일시
                </MarketItemConclusionDateColumnContainer>
            ),
        },
        {
            key: "sellerColumn",
            component: (
                <MarketItemConclusionSellerColumnContainer
                    textAlign={"left"}
                    key={"sellerColumn"}
                >
                    <CommonTCContainer>
                        <CommonTC textAlign={"left"}>판매자</CommonTC>
                        <CommonTC textAlign={"left"}>구매자</CommonTC>
                    </CommonTCContainer>
                </MarketItemConclusionSellerColumnContainer>
            ),
        },
        {
            key: "countColumn",
            component: (
                <MarketItemConclusionCountColumnContainer
                    textAlign={"center"}
                    key={"countColumn"}
                >
                    수량
                </MarketItemConclusionCountColumnContainer>
            ),
        },
        {
            key: "priceColumn",
            component: (
                <MarketItemConclusionPriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    가격(ST)
                </MarketItemConclusionPriceColumnContainer>
            ),
        },
    ];

    const conclusionTableBodyData = marketItemSettlementList.map(
        (data: MarketSettlementListItemType) => {
            return {
                dateColumn: (
                    <MarketItemConclusionDateColumnContainer
                        textAlign={"center"}
                        key={"dateColumn"}
                    >
                        <CommonTCContainer>
                            <CommonTCBold textAlign={"center"}>
                                {format(
                                    new Date(data.settlement.settled_at),
                                    "HH:mm:ss",
                                )}
                            </CommonTCBold>
                            <CommonTCSmall textAlign={"center"}>
                                {format(
                                    new Date(data.settlement.settled_at),
                                    "yyyy-MM-dd",
                                )}
                            </CommonTCSmall>
                        </CommonTCContainer>
                    </MarketItemConclusionDateColumnContainer>
                ),
                sellerColumn: (
                    <MarketItemConclusionSellerColumnContainer
                        textAlign={"left"}
                        key={"sellerColumn"}
                    >
                        <CommonTCContainer>
                            <CommonTC textAlign={"left"}>
                                <Nickname
                                    userIdx={
                                        data.sell_user_idx
                                            ? data.sell_user_idx
                                            : "0"
                                    }
                                    nickname={
                                        data.sell_user_nick
                                            ? data.sell_user_nick
                                            : "-"
                                    }
                                    isShowVerifiedIcon={false}
                                    isVerified={false}
                                />
                            </CommonTC>
                            <CommonTC textAlign={"left"}>
                                <Nickname
                                    userIdx={
                                        data.buy_user_idx
                                            ? data.buy_user_idx
                                            : "0"
                                    }
                                    nickname={
                                        data.buy_user_nick
                                            ? data.buy_user_nick
                                            : "-"
                                    }
                                    isShowVerifiedIcon={false}
                                    isVerified={false}
                                />
                            </CommonTC>
                        </CommonTCContainer>
                    </MarketItemConclusionSellerColumnContainer>
                ),
                countColumn: (
                    <MarketItemConclusionCountColumnContainer
                        textAlign={"center"}
                        key={"countColumn"}
                    >
                        {numberToStringWithComma(data.settled_quantity)}
                    </MarketItemConclusionCountColumnContainer>
                ),
                priceColumn: (
                    <MarketItemConclusionPriceColumnContainer
                        textAlign={"right"}
                        key={"priceColumn"}
                    >
                        <CommonTCBold textAlign={"right"}>
                            {numberToStringWithComma(
                                data.settled_unit_price *
                                    Number(data.settled_quantity),
                            )}
                        </CommonTCBold>
                    </MarketItemConclusionPriceColumnContainer>
                ),
            };
        },
    );
    const { isMobileWidth } = useDeviceWidth();
    const isTradAble = getIsTradeAvailableItem(
        rawItemData.itemInfo.item_status,
    );

    return (
        <MarketItemRealtimeConclusionBoxContainer inSubTab={props.inSubTab}>
            <CommonTabBox
                iconUrl={"/icon/icon-sign.svg"}
                tabList={[
                    {
                        title: "체결 내역",
                    },
                ]}
                isOpenSupport={false}
                hasPaddingBottom={false}
                isGuestAddOpacityEffect={true}
            >
                <ApiResBoundary
                    isLoading={isMarketItemSettlementListLoading}
                    isNoData={false}
                    error={errorMarketItemSettlementList}
                    loadingFallback={
                        <CommonTabBoxLoadingContainerHasPB>
                            <Spinner />
                        </CommonTabBoxLoadingContainerHasPB>
                    }
                >
                    <CommonTable
                        id="market-point-conclusion-table"
                        tableHeaderData={conclusionTableHeaderData}
                        tableBodyData={conclusionTableBodyData}
                        tableBodyHeight={isMobileWidth ? "256px" : "264px"}
                        noDataMsg={"체결 내역이 없습니다."}
                        hasParentPaddingBottom={false}
                        infiniteScrollCallback={async (
                            showSpinner: () => void,
                        ) => {
                            if (
                                hasMarketItemSettlementListNextPageRef.current
                            ) {
                                showSpinner();
                                await fetchMarketItemSettlementListNextPage();
                            }
                        }}
                    />
                </ApiResBoundary>
            </CommonTabBox>
        </MarketItemRealtimeConclusionBoxContainer>
    );
};

export default MarketItemRealtimeConclusionBox;
