import { ReactElement } from "react";
import styled from "styled-components";
import ApiResBoundary from "../../../common/apiResBoundary/ApiResBoundary";
import { useItemDataQuery } from "../../../../query/item/useItemDataQuery";
import RightSideBar from "../../../common/commonUiComponent/doubleSideBar/rightSideBar/RightSideBar";
import { useParams } from "react-router-dom";
import { ItemAnalysisList } from "../MarketItemContents";

export const MarketItemRightSideBarContainer = styled.div``;

type Props = {};

const MarketItemRightSideBar = (props: Props): ReactElement | null => {
    const params = useParams();
    const itemIdx = params.item_id ? params.item_id : "0";

    const {
        data: rawItemData,
        error: itemDataError,
        isLoading: isItemDataLoading,
        refetch: refetchItemData,
    } = useItemDataQuery(itemIdx);

    return (
        <ApiResBoundary
            isNoData={false}
            isLoading={isItemDataLoading}
            error={itemDataError}
            errorFallback={() => {
                return <div></div>;
            }}
            isLoadingSpinnerShow={false}
        >
            <RightSideBar
                data={rawItemData}
                analysisInfoKeyList={ItemAnalysisList}
                refetch={refetchItemData}
            />
        </ApiResBoundary>
    );
};

export default MarketItemRightSideBar;
