import { ReactElement } from "react";
import styled from "styled-components";
import CommentListItem from "../../../../commonUnitComponent/commentListItem/CommentListItem";
import { useParams } from "react-router-dom";
import {
    getCommentListParam,
    spreadCommentListPages,
    useCommentListQuery,
} from "../../../../../../query/comment/useCommentListQuery";
import MoreButton from "../../../../commonUnitComponent/moreButton/MoreButton";
import ApiResBoundary from "../../../../apiResBoundary/ApiResBoundary";
import { getPageType } from "../../../../../../utils/Utils";
import RightSideBarCommunityWriteArea from "./components/RightSideBarCommunityWriteArea";

export const RightSideBarCommunityContainer = styled.div`
    margin-top: 48px;

    @media ${(props) => props.theme.PCM} {
        margin-top: 4px;
    }
`;

export const RightSideBarCommunityTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #222222;

    @media ${(props) => props.theme.PCM} {
        display: none;
    }
`;

export const RightSideBarCommentInputContainer = styled.div`
    display: flex;
    height: 48px;
    background: #f5f5f7;
    border-radius: 8px;
    margin-top: 24px;

    @media ${(props) => props.theme.PCM} {
        margin-top: 0px;
    }
`;

export const RightSideBarCommentInput = styled.textarea`
    padding: 10px;
    width: calc(100% - 58px);
    height: 24px;
    background: #f5f5f7;
    border: none;
    resize: none;
    border-radius: 8px;
    font-family: "Noto Sans KR", serif;

    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    :focus {
        outline: none;
    }

    &::placeholder {
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
`;

export const RightSideBarCommentSendButton = styled.div`
    width: 28px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #fa54fa;
    cursor: pointer;
`;

export const RightSideBarCommentListContainer = styled.div`
    margin-top: 24px;

    @media ${(props) => props.theme.PCM} {
        margin-top: 32px;
    }
`;

export const RightSideBarCommentList = styled.div`
    margin-top: 24px;
    position: relative;
`;

export const RightSideBarMoreButtonContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const RightSideBarNoCommentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;
type Props = {};

const RightSideBarCommunity = (props: Props): ReactElement | null => {
    const pageType = getPageType(location.pathname);
    const params = useParams();

    const {
        data: rawCommentListData,
        isLoading: isCommentListDataLoading,
        error: commentListDataError,
        fetchNextPage,
        hasNextPage,
        refetch: refetchCommentList,
    } = useCommentListQuery(pageType, getCommentListParam(params));

    return (
        <RightSideBarCommunityContainer>
            <RightSideBarCommunityTitle>커뮤니티</RightSideBarCommunityTitle>

            {/*쓰기 영역*/}
            <RightSideBarCommunityWriteArea
                refetchCommentList={refetchCommentList}
            />

            <RightSideBarCommentListContainer>
                <ApiResBoundary
                    error={commentListDataError}
                    isLoading={isCommentListDataLoading}
                    isNoData={
                        rawCommentListData.pages
                            ? rawCommentListData.pages[0].totalCount === 0
                            : false
                    }
                    noDataFallback={
                        <RightSideBarNoCommentContainer>
                            등록된 댓글이 없습니다.
                        </RightSideBarNoCommentContainer>
                    }
                    defaultLoadingFallbackContainerHeight={"300px"}
                >
                    <>
                        <RightSideBarCommentList>
                            {spreadCommentListPages(
                                rawCommentListData.pages,
                            ).map((comment, idx) => (
                                <CommentListItem
                                    commentUserInfo={comment.userInfo}
                                    commentInfo={comment.commentInfo}
                                    reFetchCommentListData={refetchCommentList}
                                    commentDisplayRow={99999}
                                    key={idx}
                                />
                            ))}
                        </RightSideBarCommentList>
                        <RightSideBarMoreButtonContainer>
                            <MoreButton
                                isShow={hasNextPage}
                                onClick={() => {
                                    fetchNextPage().then(() => {});
                                }}
                            />
                        </RightSideBarMoreButtonContainer>
                    </>
                </ApiResBoundary>
            </RightSideBarCommentListContainer>
        </RightSideBarCommunityContainer>
    );
};

export default RightSideBarCommunity;
