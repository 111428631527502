import { ReactElement, useState } from "react";
import styled from "styled-components";
import SwitchTab, {
    SwitchTabType,
} from "../../../common/commonUiComponent/switchTab/SwitchTab";
import DayPickerLayer from "../../../common/commonUnitComponent/dayPickerLayer/DayPickerLayer";
import { DateRange } from "react-day-picker";
import { useLocation, useNavigate } from "react-router-dom";
import {
    AD_MANAGE_BUY_ANALYSIS,
    AD_MANAGE_BUY_HISTORY,
    AD_MANAGE_SALE_ANALYSIS,
    AD_MANAGE_SALE_HISTORY,
} from "../../../../constants/PageRegExp";

export const AdManageTableContentsContainer = styled.div`
    padding-top: 40px;

    @media ${(props) => props.theme.mobileL} {
        padding-top: 24px;
    }
`;

export const AdManageTableContentsSwitchTabBoxWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const AdManageTableContentsSwitchTabBoxContainer = styled.div`
    width: 320px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const AdManageTableContentsDayPickerLayerContainer = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

export const AdManageTableContentsTableContainer = styled.div`
    margin-top: 40px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 8px;
    }
`;

export const AdManageTableContentsMoreButtonContainer = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: center;
`;

export const AdManageTableContentsMoreButton = styled.div`
    width: 152px;
`;

type Props = {
    type: string;
    children: ReactElement;
    selectedDateRange: DateRange;
    setSelectedDateRange: (value: DateRange) => void;
};

export const AD_BUY_HISTORY = "ad-buy-history";
export const AD_BUY_ANALYSIS = "ad-buy-analysis";
export const AD_SALE_HISTORY = "ad-sale-history";
export const AD_SALE_ANALYSIS = "ad-sale-analysis";
export const AD_BLACKLIST = "ad-blacklist";

const AdManageTableContentsLayout = (props: Props): ReactElement | null => {
    const location = useLocation();
    const navigate = useNavigate();
    const defaultSwitchTabList: SwitchTabType[] = props.type.includes("sale")
        ? [
              {
                  title: "판매 목록",
                  key: AD_SALE_HISTORY,
                  isSelected: location.pathname === AD_MANAGE_SALE_HISTORY,
                  additionalClickHandler: () => {
                      navigate(AD_MANAGE_SALE_HISTORY);
                  },
              },
              {
                  title: "성과 분석",
                  key: AD_SALE_ANALYSIS,
                  isSelected: location.pathname === AD_MANAGE_SALE_ANALYSIS,
                  additionalClickHandler: () => {
                      navigate(AD_MANAGE_SALE_ANALYSIS);
                  },
              },
          ]
        : [
              {
                  title: "입찰 내역",
                  key: AD_BUY_HISTORY,
                  isSelected: location.pathname === AD_MANAGE_BUY_HISTORY,
                  additionalClickHandler: () => {
                      navigate(AD_MANAGE_BUY_HISTORY);
                  },
              },
              {
                  title: "성과 분석",
                  key: AD_BUY_ANALYSIS,
                  isSelected: location.pathname === AD_MANAGE_BUY_ANALYSIS,
                  additionalClickHandler: () => {
                      navigate(AD_MANAGE_BUY_ANALYSIS);
                  },
              },
          ];
    const [switchTabList, setSwitchTabList] = useState(defaultSwitchTabList);

    return (
        <AdManageTableContentsContainer>
            <AdManageTableContentsSwitchTabBoxWrapper>
                <AdManageTableContentsSwitchTabBoxContainer>
                    <SwitchTab
                        switchTabList={switchTabList}
                        setSwitchTabList={setSwitchTabList}
                    />
                </AdManageTableContentsSwitchTabBoxContainer>
            </AdManageTableContentsSwitchTabBoxWrapper>
            <AdManageTableContentsDayPickerLayerContainer>
                <DayPickerLayer
                    isMobileTitleHide={false}
                    title={"광고 입찰기간"}
                    selectedDateRange={props.selectedDateRange}
                    setSelectedDateRange={props.setSelectedDateRange}
                />
            </AdManageTableContentsDayPickerLayerContainer>
            <AdManageTableContentsTableContainer>
                {props.children}
            </AdManageTableContentsTableContainer>
        </AdManageTableContentsContainer>
    );
};

export default AdManageTableContentsLayout;
