import { useAtom } from "jotai";
import userInfoAtom from "../../store/userInfo/userInfoAtom";

export const useIsMe = function () {
    const [userInfo] = useAtom(userInfoAtom);

    return function (inputUserIdx: string) {
        return userInfo.user_idx === inputUserIdx;
    };
};
