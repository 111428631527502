import { ReactElement } from "react";
import styled from "styled-components";
import WhiteBox from "../../../common/commonUnitComponent/box/WhiteBox";
import WhiteBoxTitleLayout from "../../../common/commonUnitComponent/box/WhiteBoxTitleLayout";

export const AdBidingRefundInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const AdBidingRefundText = styled.div`
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
`;

export const REFUND_INFO_TEXTS = [
    "· '광고 판매자'가 결제 취소할 경우 : 100% 결제취소",
    "· '광고 구매자'가 광고 게재일 D-1일에 취소할 경우 : 0% 결제취소",
    "· '광고 구매자'가 광고 게재일 D-2일에 취소할 경우 : 30% 결제취소",
    "· '광고 구매자'가 광고 게재일 D-3일에 취소할 경우 : 50% 결제취소",
    "· '광고 구매자'가 광고 게재일 D-3일 이전에 취소할 경우 : 100% 결제취소",
];

type Props = {};

const AdBidingRefundInfo = (props: Props): ReactElement | null => {
    return (
        <WhiteBox>
            <WhiteBoxTitleLayout title={"취소 및 환불 규정"} bodyMt={"24px"}>
                <AdBidingRefundInfoContainer>
                    {REFUND_INFO_TEXTS.map((text, idx) => (
                        <AdBidingRefundText key={idx}>
                            {text}
                        </AdBidingRefundText>
                    ))}
                </AdBidingRefundInfoContainer>
            </WhiteBoxTitleLayout>
        </WhiteBox>
    );
};

export default AdBidingRefundInfo;
