import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";
import { useLocalStorage } from "usehooks-ts";
import { getIsLogin } from "../../../../../../../../store/auth/authKeyStore";
import { useOpenLoginConfirmModal } from "../../../../../../../../hooks/modal/openModal/useOpenLoginConfirmModal";
import { LinkMenuType } from "../../../../../../commonUiComponent/header/components/HeaderMenu/HeaderMenu";
import { useOpenTutorialModal } from "../../../../../../../../hooks/modal/openModal/useOpenTutorialModal";

export const UserProfileModalPageMenuContainer = styled.div<{
    isOpenMenuContainer: boolean;
    depth: number;
    hasChildren: boolean;
}>`
    background: ${({ hasChildren }) => (hasChildren ? "#f7f7f7" : "white")};

    max-height: ${({ hasChildren, isOpenMenuContainer }) => {
        if (hasChildren) {
            return isOpenMenuContainer ? "1000px" : "52px";
        } else {
            return "1000px";
        }
    }};

    transition: all 300ms ease;
    overflow: hidden;
`;

export const UserProfileModalPageMenuItemContainer = styled.div<{
    depth: number;
    hasChildren: boolean;
}>`
    padding-left: ${({ depth }) => 24 + (depth - 1) * 8 + "px"};
    line-height: 52px;
    height: 52px;

    transition: all ease-in-out 0.3s;

    font-weight: ${({ depth }) => (depth <= 1 ? "500" : "400")};
    font-size: ${({ depth }) => (depth <= 1 ? "16px" : "14px")};
    background: ${({ depth }) => (depth <= 1 ? "white" : "#F5F5F7")};
    color: ${({ depth }) => (depth <= 1 ? "#222222" : "#444444")};

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f1f2;
            border-radius: 6px;
        }
    }

    position: relative;
`;

export const UserProfileModalPageMenuItemArrowIconImg = styled.img`
    width: 14px;
    height: 8px;
    position: absolute;
    right: 24px;
    top: 20px;
`;

export const UserProfileModalPageMenuItemGoOutIcon = styled.img`
    position: absolute;
    right: 20px;
    top: 16px;
`;

const hasChildren = (category: LinkMenuType) => {
    return typeof category.children !== "undefined";
};

type Props = {
    menu: LinkMenuType;
    depth: number;
    resolve: (value: boolean | number) => void;
};

const UserProfileModalPageMenuItem = (props: Props): ReactElement | null => {
    const navigate = useNavigate();
    const [, setPathAfterLogin] = useLocalStorage("pathAfterLogin", "/");
    const [isOpenMenuContainer, setIsOpenMenuContainer] = useState(false);
    const { openTutorialModal } = useOpenTutorialModal();
    const { openLoginConfirmModal } = useOpenLoginConfirmModal();

    const goMenu = (path: string, isNewTab: boolean) => {
        if (isNewTab) {
            window.open(path);
        } else {
            navigate(
                path,
                path === "/"
                    ? {
                          state: {
                              isIntro: false,
                          },
                      }
                    : {},
            );
            props.resolve(-1);
        }
    };

    return (
        <UserProfileModalPageMenuContainer
            depth={props.depth + 1}
            isOpenMenuContainer={isOpenMenuContainer}
            hasChildren={hasChildren(props.menu)}
        >
            <UserProfileModalPageMenuItemContainer
                hasChildren={hasChildren(props.menu)}
                depth={props.depth}
                onClick={async () => {
                    if (hasChildren(props.menu)) {
                        setIsOpenMenuContainer(!isOpenMenuContainer);
                    }
                    //
                    else {
                        if (props.menu.path !== "") {
                            goMenu(
                                props.menu.path ? props.menu.path : "/",
                                props.menu.isNewTab || false,
                            );
                        } else {
                            // 가이드 버튼
                            if (props.menu.id === 993) {
                                props.resolve(false);

                                // 프로필 메뉴 닫히고 모달 연다. 뒤로가기 동작에 딜레이 필요함.
                                setTimeout(async () => {
                                    await openTutorialModal();
                                }, 100);
                            }
                        }
                    }
                }}
            >
                {props.menu.name}
                {hasChildren(props.menu) && (
                    <UserProfileModalPageMenuItemArrowIconImg
                        src={
                            isOpenMenuContainer
                                ? `${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-menu-north-arrow.svg`
                                : `${URL_CONSTANTS.ASSET_URL}/icon/icon-mobile-menu-south-arrow.svg`
                        }
                    />
                )}
                {props.menu.isNewTab && (
                    <UserProfileModalPageMenuItemGoOutIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-go-out.svg`}
                    />
                )}
            </UserProfileModalPageMenuItemContainer>

            {hasChildren(props.menu) &&
                props.menu.children?.map((menu: LinkMenuType) => {
                    if (hasChildren(menu)) {
                        return (
                            <UserProfileModalPageMenuItem
                                resolve={props.resolve}
                                key={menu.id}
                                menu={menu}
                                depth={props.depth + 1}
                            />
                        );
                    } else {
                        return (
                            <UserProfileModalPageMenuItemContainer
                                key={menu.id}
                                hasChildren={hasChildren(menu)}
                                depth={props.depth + 1}
                                onClick={async () => {
                                    if (menu.isNeedLogin && !getIsLogin()) {
                                        await openLoginConfirmModal(menu.path);
                                    } else {
                                        goMenu(
                                            menu.path,
                                            menu.isNewTab || false,
                                        );
                                    }
                                }}
                            >
                                {menu.name}
                            </UserProfileModalPageMenuItemContainer>
                        );
                    }
                })}
        </UserProfileModalPageMenuContainer>
    );
};

export default UserProfileModalPageMenuItem;
